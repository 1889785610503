/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@icm/core-web";

export const styles = createStyles({
  root: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme => theme.spacing(0.25, 0, 0.5),
  },
  changesContainer: {
    flexGrow: 1,
    marginLeft: theme => theme.spacing(1),
  },
  noEntries: {
    padding: theme => theme.spacing(2, 3),
  },
  loadingBar: {
    marginLeft: theme => theme.spacing(1.5),
    marginRight: theme => theme.spacing(1.5),
  },
  loadingBarBottom: {
    marginBottom: theme => theme.spacing(1),
  },
});
