/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import type {Geometry, LineString, Point, Polygon} from "geojson";

import {dateService} from "./DateService";
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";
import {messages} from "./MessageService";
import {numberService} from "./NumberService";

type GetFormatterOptions = {
  locale?: string,
  type?: string,
  format?: string
}

/**
 * Simple MessageFormat like in Java, but with named parameters.
 * Currently "choice" is NOT supported.
 */
class MessageFormat {

  private readonly formatter: (obj: object) => string;

  private readonly params: {[name: string]: (obj: any) => string} = {};

  constructor(format: string, locale?: string) {
    const re = /{([^,}]+)(?:,([^,}]+)(?:,([^}]*))?)?}/g;
    const partFormatters: ((obj: object) => string)[] = [];
    let start = 0;
    let match;
    while ((match = re.exec(format)) !== null) {
      const end = re.lastIndex - match[0].length;
      const [, name, type, partFormat] = match;
      const text = format.substring(start, end);
      partFormatters.push(_ => text);
      const partFormatter = this.getFormatter({locale, type, format: partFormat});
      this.params[name] = partFormatter;
      partFormatters.push(obj => partFormatter(obj[name]));
      start = re.lastIndex;
    }
    const text = format.substring(start);
    partFormatters.push(_ => text);
    this.formatter = obj => partFormatters.map(formatter => formatter(obj)).join("");
  }

  public format(context: object): string {
    return this.formatter(context);
  }

  public parameters(): {[name: string]: (obj: any) => string} {
    return this.params;
  }

  private getFormatter({locale, type, format}: GetFormatterOptions): (obj: any) => string {
    if (type === "number") {
      const service = locale ? numberService.withLocale(locale) : numberService;
      return obj => service.format(typeof obj === "number" ? obj : Number.NaN, format);
    } else if (type === "date") {
      if (!format || format === "SHORT" || format === "LONG") {
        return obj => dateService.formatDate(obj, format as "SHORT" | "LONG" | undefined);
      } else {
        return obj => dateService.format(obj, format);
      }
    } else if (type === "time") {
      if (!format || format === "SHORT" || format === "MEDIUM") {
        return obj => dateService.formatTime(obj, format as "SHORT" | "MEDIUM" | undefined);
      } else {
        return obj => dateService.format(obj, format);
      }
    } else if (type === "datetime") {
      if (!format) {
        return obj => dateService.formatDateTime(obj);
      } else {
        return obj => dateService.format(obj, format);
      }
    } else {
      return obj => "" + obj;
    }
  }
}

type CoordinateContext = {
  lat?: number;
  degLat?: number;
  minLat?: number;
  secLat?: number;
  dirLat?: string;
  lon?: number;
  degLon?: number;
  minLon?: number;
  secLon?: number;
  dirLon?: string;
  degSym?: string;
  minSym?: string;
  secSym?: string;
}

type CoordinateSymbols = "north" | "south" | "west" | "east" | "degree" | "minute" | "second";

type CoordinateConfig = {
  [key in CoordinateSymbols]: string;
} & {
  [key in `${CoordinateSymbols}Pattern`]: string;
};

/** longitude degree/minute/seconds, longitude negative, latitude degree/minute/seconds, latitude negative */
type CoordinateParts = [string, string, string, boolean, string, string, string, boolean];

/** extractor for extracting the coordinate parts from the matcher groups */
type CoordinatePartsExtractor = (groups: string[]) => CoordinateParts;

const defaultCoordinateConfig: {[key in CoordinateSymbols]: string} = {
  north: "N",
  south: "S",
  west: "W",
  east: "E",
  degree: "°",
  minute: "'",
  second: "\"",
};

const serviceByLocale: {[key: string]: CoordinateService} = {};

class CoordinateService {

  private readonly formats = {
    dmsLatLon: "{degLat,number,0}{degSym}{minLat,number,0}{minSym}{secLat,number,0.##}{secSym}{dirLat} {degLon,number,0}{degSym}{minLon,number,0}{minSym}{secLon,number,0.##}{secSym}{dirLon}",
    dmsLonLat: "{degLon,number,0}{degSym}{minLon,number,0}{minSym}{secLon,number,0.##}{secSym}{dirLon} {degLat,number,0}{degSym}{minLat,number,0}{minSym}{secLat,number,0.##}{secSym}{dirLat}",
    dmsLat: "{degLat,number,0}{degSym}{minLat,number,0}{minSym}{secLat,number,0.##}{secSym}{dirLat}",
    dmsLon: "{degLon,number,0}{degSym}{minLon,number,0}{minSym}{secLon,number,0.##}{secSym}{dirLon}",
    dmLatLon: "{degLat,number,0}{degSym}{minLat,number,0.##}{minSym}{dirLat} {degLon,number,0}{degSym}{minLon,number,0.##}{minSym}{dirLon}",
    dmLonLat: "{degLon,number,0}{degSym}{minLon,number,0.##}{minSym}{dirLon} {degLat,number,0}{degSym}{minLat,number,0.##}{minSym}{dirLat}",
    dmLat: "{degLat,number,0}{degSym}{minLat,number,0.##}{minSym}{dirLat}",
    dmLon: "{degLon,number,0}{degSym}{minLon,number,0.##}{minSym}{dirLon}",
    dLatLon: "{degLat,number,0.####}{degSym}{dirLat} {degLon,number,0.####}{degSym}{dirLon}",
    dLonLat: "{degLon,number,0.####}{degSym}{dirLon} {degLat,number,0.####}{degSym}{dirLat}",
    dLat: "{degLat,number,0.####}{degSym}{dirLat}",
    dLon: "{degLon,number,0.####}{degSym}{dirLon}",
    sdmsLatLon: "{degLat,number,00}{minLat,number,00}{secLat,number,00}{dirLat} {degLon,number,000}{minLon,number,00}{secLon,number,00}{dirLon}",
    sdmsLonLat: "{degLon,number,000}{minLon,number,00}{secLon,number,00}{dirLon} {degLat,number,00}{minLat,number,00}{secLat,number,00}{dirLat}",
    sdmsLat: "{degLat,number,00}{minLat,number,00}{secLat,number,00}{dirLat}",
    sdmsLon: "{degLon,number,000}{minLon,number,00}{secLon,number,00}{dirLon}",
    sdmLatLon: "{degLat,number,00}{minLat,number,00}{dirLat} {degLon,number,000}{minLon,number,00}{dirLon}",
    sdmLonLat: "{degLon,number,000}{minLon,number,00}{dirLon} {degLat,number,00}{minLat,number,00}{dirLat}",
    sdmLat: "{degLat,number,00}{minLat,number,00}{dirLat}",
    sdmLon: "{degLon,number,000}{minLon,number,00}{dirLon}",
    decimalLatLon: "{lat,number,0.####} {lon,number,0.####}",
    decimalLonLat: "{lon,number,0.####} {lat,number,0.####}",
    decimalLat: "{lat,number,0.####}",
    decimalLon: "{lon,number,0.####}",
  };

  private config: CoordinateConfig;

  private locale: string;

  private parsers: {type: "lat" | "lon" | "both", pattern: string, extractor: CoordinatePartsExtractor}[];

  private numberParser: (s: string) => number | null;

  constructor(locale?: string, config?: Partial<CoordinateConfig>) {
    this.locale = locale || "";
    if (!this.locale) {
      // only for default service
      ExpressionEvaluationService.registerHelper("coordinate", "format",
        (c, format, lscNumberFormat) => this.format(CoordinateService.toCoordinate(c), format, lscNumberFormat));
      ExpressionEvaluationService.registerHelper("coordinate", "formatLongitude",
        (c, format, lscNumberFormat) => this.formatLongitude(CoordinateService.toLongitude(c), format, lscNumberFormat));
      ExpressionEvaluationService.registerHelper("coordinate", "formatLatitude",
        (c, format, lscNumberFormat) => this.formatLatitude(CoordinateService.toLatitude(c), format, lscNumberFormat));
      ExpressionEvaluationService.registerHelper("coordinate", "formatPoint",
        (c, format, lscNumberFormat) => this.format(c, format, lscNumberFormat));
      ExpressionEvaluationService.registerHelper("coordinate", "formatLineString",
        (c, format, lscNumberFormat) => this.formatLineString(c, format, lscNumberFormat));
      ExpressionEvaluationService.registerHelper("coordinate", "formatPolygon",
        (c, format, lscNumberFormat) => this.formatPolygon(c, format, lscNumberFormat));
      ExpressionEvaluationService.registerHelper("coordinate", "formatGeometry",
        (c, format, lscNumberFormat) => this.formatGeometry(c, format, lscNumberFormat));

      ExpressionEvaluationService.registerHelper("coordinate", "parse", (n) => this.parse(n));
      ExpressionEvaluationService.registerHelper("coordinate", "parseLongitude", (n) => this.parseLongitude(n));
      ExpressionEvaluationService.registerHelper("coordinate", "parseLatitude", (n) => this.parseLatitude(n));
      ExpressionEvaluationService.registerHelper("coordinate", "parsePoint", (n) => this.parsePoint(n));
      ExpressionEvaluationService.registerHelper("coordinate", "parseLineString", (n) => this.parseLineString(n));
      ExpressionEvaluationService.registerHelper("coordinate", "parsePolygon", (n) => this.parsePolygon(n));
      ExpressionEvaluationService.registerHelper("coordinate", "parseGeometry", (n) => this.parseGeometry(n));
    }
    const partialConfig = config ? {...defaultCoordinateConfig, ...config} : {...defaultCoordinateConfig};
    Object.keys(defaultCoordinateConfig)
      .filter(name => !partialConfig[`${name}Pattern`])
      .forEach(name => partialConfig[`${name}Pattern`] = partialConfig[name]);
    this.config = partialConfig as CoordinateConfig;
  }

  public withLocale(locale: string, config?: Partial<CoordinateConfig>) {
    if (config) {
      return new CoordinateService(locale, config);
    }
    if (locale === this.locale) {
      return this;
    } else if (!serviceByLocale[locale]) {
      serviceByLocale[locale] = new CoordinateService(locale);
    }
    return serviceByLocale[locale];
  }

  private init() {
    if (messages.isReady()) {
      this.locale = this.locale || (messages.has("core.language") ? messages.get("core.language") : null) || "en-US";
      const partialConfig = {...defaultCoordinateConfig};
      Object.keys(defaultCoordinateConfig).forEach(name => {
        if (messages.has(`core.format.coordinate.${name}`)) {
          partialConfig[name] = messages.get(`core.format.coordinate.${name}`);
        }
        if (messages.has(`core.format.coordinate.${name}Pattern`)) {
          partialConfig[`${name}Pattern`] =  messages.get(`core.format.coordinate.${name}Pattern`);
        }
      });
      Object.keys(defaultCoordinateConfig)
        .filter(name => !partialConfig[`${name}Pattern`])
        .forEach(name => partialConfig[`${name}Pattern`] = partialConfig[name]);
      this.config = partialConfig as CoordinateConfig;
      this.init = () => null;
    }
  }

  /**
   * Format a coordinate.
   *
   * @param coordinateOrPoint the coordinate as [longitude, latitude] or the GeoJSON point
   * @param nameOrFormat the name of the format or the format itself (optional, default: "dmsLatLon")
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   *        (in order for it to work, the least significant components must have a format with decimal point and the other components must not have one)
   * @return the formatted coordinate
   */
  public format(coordinateOrPoint: number[] | Point | null, nameOrFormat?: string, lscNumberFormat?: string): string | null {
    if (!coordinateOrPoint) {
      return null;
    }
    this.init();
    const coordinate = coordinateOrPoint && "type" in coordinateOrPoint ? coordinateOrPoint.coordinates : coordinateOrPoint;
    const lat = coordinate[1];
    const lon = coordinate[0];
    const origFormat = nameOrFormat ? this.namedFormat(nameOrFormat) || nameOrFormat : this.namedFormat("default") || this.formats.dmsLatLon;
    const format = lscNumberFormat ? origFormat.replace(/0\.#*/g, lscNumberFormat) : origFormat;
    const msgFormat = new MessageFormat(format, this.locale);
    const params = msgFormat.parameters();
    const context: CoordinateContext = {};
    if (params.secLat) {
      let sec = Math.abs(lat * 3600) % 60;
      let min = Math.floor(Math.abs(lat * 60) % 60);
      let deg = Math.floor(Math.abs(lat));
      if (params.secLat(sec).match("^0*60.*")) {
        sec = 0;
        min++;
        if (min >= 60) {
          min = 0;
          deg++;
        }
      }
      Object.assign(context, {secLat: sec, minLat: min, degLat: deg});
    } else if (params.minLat) {
      let min = Math.abs(lat * 60) % 60;
      let deg = Math.floor(Math.abs(lat));
      if (params.minLat(min).match("^0*60.*")) {
        min = 0;
        deg++;
      }
      Object.assign(context, {minLat: min, degLat: deg});
    } else if (params["degLat"]) {
      const deg = Math.abs(lat);
      Object.assign(context, {degLat: deg});
    } else if (params["lat"]) {
      Object.assign(context, {lat});
    }
    if (params["secLon"]) {
      let sec = Math.abs(lon * 3600) % 60;
      let min = Math.floor(Math.abs(lon * 60) % 60);
      let deg = Math.floor(Math.abs(lon));
      if (params.secLon(sec).match("^0*60.*")) {
        sec = 0;
        min++;
        if (min >= 60) {
          min = 0;
          deg++;
        }
      }
      Object.assign(context, {secLon: sec, minLon: min, degLon: deg});
    } else if (params.minLon) {
      let min = Math.abs(lon * 60) % 60;
      let deg = Math.floor(Math.abs(lon));
      if (params.minLon(min).match("^0*60.*")) {
        min = 0;
        deg++;
      }
      Object.assign(context, {minLon: min, degLon: deg});
    } else if (params["degLon"]) {
      const deg = Math.abs(lon);
      Object.assign(context, {degLon: deg});
    } else if (params["lon"]) {
      Object.assign(context, {lon});
    }
    if (params["secSym"]) {
      context.secSym = this.config.second;
    }
    if (params["minSym"]) {
      context.minSym = this.config.minute;
    }
    if (params["degSym"]) {
      context.degSym = this.config.degree;
    }
    if (params["dirLat"]) {
      context.dirLat = lat < 0 ? this.config.south : this.config.north;
    }
    if (params["dirLon"]) {
      context.dirLon = lon < 0 ? this.config.west : this.config.east;
    }
    return msgFormat.format(context);
  }

  /**
   * Format a longitude.
   *
   * @param longitudeOrCoordinateOrPoint the longitude or coordinate (as [longitude, latitude] or point
   * @param nameOrFormat the name of the format or the format itself (optional, default: "dmsLon")
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   * @return the formatted longitude
   */
  public formatLongitude(longitudeOrCoordinateOrPoint: Point | number[] | number | null, nameOrFormat?: string, lscNumberFormat?: string): string | null {
    const coordinate = typeof longitudeOrCoordinateOrPoint === "number" ? [longitudeOrCoordinateOrPoint, 0] : longitudeOrCoordinateOrPoint;
    return this.format(coordinate, nameOrFormat || this.formats.dmsLon, lscNumberFormat);
  }

  /**
   * Format a latitude.
   *
   * @param latitudeOrCoordinateOrPoint the latitude or coordinate (as [longitude, latitude] or point
   * @param nameOrFormat the name of the format or the format itself (optional, default: "dmsLat")
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   * @return the formatted latitude
   */
  public formatLatitude(latitudeOrCoordinateOrPoint: Point | number[] | number | null, nameOrFormat?: string, lscNumberFormat?: string): string | null {
    const coordinate = typeof latitudeOrCoordinateOrPoint === "number" ? [0, latitudeOrCoordinateOrPoint] : latitudeOrCoordinateOrPoint;
    return this.format(coordinate, nameOrFormat || this.formats.dmsLat, lscNumberFormat);
  }

  /**
   * Format a line as multiple coordinates separated by newlines.
   *
   * @param coordinatesOrLineString the coordinates or linestring geometry
   * @param nameOrFormat the name of the format or the format itself (optional)
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   * @return the formatted line coordinates
   */
  public formatLineString(coordinatesOrLineString: LineString | number[][] | null, nameOrFormat?: string, lscNumberFormat?: string) {
    const coordinates = coordinatesOrLineString && "type" in coordinatesOrLineString ? coordinatesOrLineString.coordinates : coordinatesOrLineString;
    if (!coordinates || coordinates.length === 0) {
      return null;
    }
    return coordinates.map(coordinate => this.format(coordinate, nameOrFormat, lscNumberFormat)).join("\r\n");
  }

  /**
   * Format a line as multiple coordinates separated by newlines.
   * Multiple rings are separated by double newlines.
   *
   * @param linearRingsOrPolygon the coordinates array or polygon geometry
   * @param nameOrFormat the name of the format or the format itself (optional)
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   * @return the formatted polygon coordinates
   */
  public formatPolygon(linearRingsOrPolygon: Polygon | number[][][] | null, nameOrFormat?: string, lscNumberFormat?: string) {
    const linearRings = linearRingsOrPolygon && "type" in linearRingsOrPolygon ? linearRingsOrPolygon.coordinates : linearRingsOrPolygon;
    if (!linearRings || linearRings.length === 0) {
      return null;
    }
    return linearRings.map(coordinates => this.formatLineString(coordinates, nameOrFormat, lscNumberFormat) || "").join("\r\n\r\n");
  }

  /**
   * Format a point, line or polygon.
   *
   * @param geometry the geometry
   * @param nameOrFormat the name of the format or the format itself (optional)
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   * @return the formatted geometry coordinates
   */
  public formatGeometry(geometry: Geometry | null, nameOrFormat?: string, lscNumberFormat?: string) {
    switch (geometry?.type) {
      case "Point": return this.format(geometry, nameOrFormat, lscNumberFormat);
      case "LineString": return this.formatLineString(geometry, nameOrFormat, lscNumberFormat);
      case "Polygon": return this.formatPolygon(geometry, nameOrFormat, lscNumberFormat);
      default: return null;
    }
  }

  /**
   * Format the first coordinate of a point, line or polygon or the coordinate [0, 0] for use in a label.
   * @param geometry the geometry
   * @param nameOrFormat the name of the format or the format itself (optional)
   * @param lscNumberFormat the number format to use for the least significant components (optional)
   * @return the formatted geometry first coordinate
   */
  public formatGeometryForLabel(geometry: Geometry | null, nameOrFormat?: string, lscNumberFormat?: string) {
    switch (geometry?.type) {
      case "Point": return this.format(geometry.coordinates || [0, 0], nameOrFormat, lscNumberFormat);
      case "LineString": return this.format(geometry.coordinates?.[0] || [0, 0], nameOrFormat, lscNumberFormat);
      case "Polygon": return this.format(geometry.coordinates?.[0]?.[0] || [0, 0], nameOrFormat, lscNumberFormat);
      default: return this.format([0, 0], nameOrFormat, lscNumberFormat);
    }
  }

  private createNumberFromParts(degrees: string, minutes: string, seconds: string, negative: boolean): number | null {
    const parse = this.numberParser;
    if (minutes || seconds) {
      const deg = parse(degrees);
      const min = minutes ? parse(minutes) : 0;
      const sec = seconds ? parse(seconds) : 0;
      return deg != null ? (deg + (min ?? 0) / 60.0 + (sec ?? 0) / 3600.0) * (negative ? -1 : 1) : null;
    } else if (degrees.match(/^\d{6,7}$/)) {
      // (d)ddmmss
      const len = degrees.length;
      const deg = parse(degrees.substring(0, len - 4));
      const min = parse(degrees.substring(len - 4, len - 2));
      const sec = parse(degrees.substring(len - 2));
      return deg != null ? (deg + (min ?? 0) / 60.0 + (sec ?? 0) / 3600.0) * (negative ? -1 : 1) : null;
    } else if (degrees.match(/^\d{4,5}$/)) {
      // (d)ddmm
      const len = degrees.length;
      const deg = parse(degrees.substring(0, len - 2));
      const min = parse(degrees.substring(len - 2));
      return deg != null ? (deg + (min ?? 0) / 60.0) * (negative ? -1 : 1) : null;
    } else {
      const deg = parse(degrees);
      return deg != null ? deg * (negative ? -1 : 1) : null;
    }
  }

  private initParsers() {
    if (!this.parsers) {
      const latDirPattern = `(${this.config.northPattern}|${this.config.southPattern})`;
      const lonDirPattern = `(${this.config.eastPattern}|${this.config.westPattern})`;
      const degPattern = `(?:${this.config.degreePattern})`;
      const minPattern = `(?:${this.config.minutePattern})`;
      const secPattern = `(?:${this.config.secondPattern})`;
      const numPattern = "([^\\s]+?)";
      const dmsPattern = `${numPattern}\\s*${degPattern}(?:\\s*${numPattern}\\s*${minPattern}(?:\\s*${numPattern}\\s*${secPattern})?)?`;
      const isWest = (s: string) => s.match(this.config.westPattern) !== null;
      const isSouth = (s: string) => s.match(this.config.southPattern) !== null;
      this.parsers = [
        {type: "both", pattern: `${dmsPattern}\\s*${latDirPattern}\\s*${dmsPattern}\\s*${lonDirPattern}`, extractor: g => [g[5], g[6], g[7], isWest(g[8]), g[1], g[2], g[3], isSouth(g[4])]},
        {type: "both", pattern: `${dmsPattern}\\s*${lonDirPattern}\\s*${dmsPattern}\\s*${latDirPattern}`, extractor: g => [g[1], g[2], g[3], isWest(g[4]), g[5], g[6], g[7], isSouth(g[8])]},
        {type: "both", pattern: `${latDirPattern}\\s*${dmsPattern}\\s*${lonDirPattern}\\s*${dmsPattern}`, extractor: g => [g[6], g[7], g[8], isWest(g[5]), g[2], g[3], g[4], isSouth(g[1])]},
        {type: "both", pattern: `${lonDirPattern}\\s*${dmsPattern}\\s*${latDirPattern}\\s*${dmsPattern}`, extractor: g => [g[2], g[3], g[4], isWest(g[1]), g[6], g[7], g[8], isSouth(g[5])]},
        {type: "both", pattern: `${numPattern}\\s*${latDirPattern}\\s*${numPattern}\\s*${lonDirPattern}`, extractor: g => [g[3], "", "", isWest(g[4]), g[1], "", "", isSouth(g[2])]},
        {type: "both", pattern: `${numPattern}\\s*${lonDirPattern}\\s*${numPattern}\\s*${latDirPattern}`, extractor: g => [g[1], "", "", isWest(g[2]), g[3], "", "", isSouth(g[4])]},
        {type: "both", pattern: `${latDirPattern}\\s*${numPattern}\\s*${lonDirPattern}\\s*${numPattern}`, extractor: g => [g[4], "", "", isWest(g[3]), g[2], "", "", isSouth(g[1])]},
        {type: "both", pattern: `${lonDirPattern}\\s*${numPattern}\\s*${latDirPattern}\\s*${numPattern}`, extractor: g => [g[2], "", "", isWest(g[1]), g[4], "", "", isSouth(g[3])]},
        {type: "lat", pattern: `${dmsPattern}\\s*${latDirPattern}`, extractor: g => ["", "", "", false, g[1], g[2], g[3], isSouth(g[4])]},
        {type: "lat", pattern: `${latDirPattern}\\s*${dmsPattern}`, extractor: g => ["", "", "", false, g[2], g[3], g[4], isSouth(g[1])]},
        {type: "lat", pattern: `${numPattern}\\s*${latDirPattern}`, extractor: g => ["", "", "", false, g[1], "", "", isSouth(g[2])]},
        {type: "lat", pattern: `${latDirPattern}\\s*${numPattern}`, extractor: g => ["", "", "", false, g[2], "", "", isSouth(g[1])]},
        {type: "lat", pattern: `${dmsPattern}`, extractor: g => ["", "", "", false, g[1], g[2], g[3], false]},
        {type: "lat", pattern: `${numPattern}(?:\\s+${numPattern}(?:\\s+${numPattern})?)?`, extractor: g => ["", "", "", false, g[1], g[2], g[3], false]},
        {type: "lon", pattern: `${dmsPattern}\\s*${lonDirPattern}`, extractor: g => [g[1], g[2], g[3], isWest(g[4]), "", "", "", false]},
        {type: "lon", pattern: `${lonDirPattern}\\s*${dmsPattern}`, extractor: g => [g[2], g[3], g[4], isWest(g[1]), "", "", "", false]},
        {type: "lon", pattern: `${numPattern}\\s*${lonDirPattern}`, extractor: g => [g[1], "", "", isWest(g[2]), "", "", "", false]},
        {type: "lon", pattern: `${lonDirPattern}\\s*${numPattern}`, extractor: g => [g[2], "", "", isWest(g[1]), "", "", "", false]},
        {type: "lon", pattern: `${dmsPattern}`, extractor: g => [g[1], g[2], g[3], false, "", "", "", false]},
        {type: "lon", pattern: `${numPattern}(?:\\s+${numPattern}(?:\\s+${numPattern})?)?`, extractor: g => [g[1], g[2], g[3], false, "", "", "", false]},
      ];
      const numService = this.locale ? numberService.withLocale(this.locale) : numberService;
      this.numberParser = (s: string) => {
        try {
          const num = numService.parse(s);
          if (!Number.isNaN(num)) {
            return num;
          }
        } catch (e) {
          // continue
        }
        const num = Number.parseFloat(s);
        return Number.isNaN(num) ? null : num;
      };
    }
  }

  /**
   * Parse a coordinate.
   *
   * @param s the coordinate as string
   * @return the coordinate or null
   */
  public parse(s: string | null | undefined): number[] | null {
    if (!s) {
      return null;
    }
    this.init();
    this.initParsers();
    const parsers = this.parsers.filter(p => p.type === "both");
    for (const parser of parsers) {
      const match = s.trim().match(`^${parser.pattern}$`);
      if (match != null) {
        const parts = parser.extractor(match);
        const lon = this.createNumberFromParts(parts[0], parts[1], parts[2], parts[3]);
        const lat = this.createNumberFromParts(parts[4], parts[5], parts[6], parts[7]);
        if (lon != null && lat != null) {
          return [lon, lat];
        }
      }
    }
    return null;
  }

  /**
   * Parse a coordinate to a point.
   *
   * @param s the coordinate as string
   * @return the GeoJSON point or null
   */
  public parsePoint(s: string | null | undefined): Point | null {
    const coordinate = this.parse(s);
    return coordinate ? {type: "Point", coordinates: coordinate} : null;
  }

  /**
   * Parse multiple coordinates to a line string.
   *
   * @param s the coordinates separated by newlines
   * @return the GeoJSON line string or null
   */
  public parseLineString(s: string | null | undefined): LineString | null {
    const coordinates: number[][] | undefined = s?.split(/(\r?\n)+/)
      .map(line => this.parse(line))
      .filter(c => c)
      .map(c => c!);
    return coordinates && coordinates.length >= 2 ? {type: "LineString", coordinates} : null;
  }

  /**
   * Parse multiple coordinates to a polygon.
   *
   * @param s the coordinates separated by newlines, rings separated by multiple newlines
   * @return the GeoJSON polygon or null
   */
  public parsePolygon(s: string | null | undefined): Polygon | null {
    const linearRings: number[][][] | undefined = s?.split(/\r?\n(\r?\n)+/)
      .map(lines => this.parseLineString(lines))
      .filter(c => c)
      .map(c => c!.coordinates);
    if (linearRings && linearRings.length >= 1 && linearRings?.every(ring => ring.length >= 4 && this.sameCoords(ring[0], ring[ring.length - 1]))) {
      return {type: "Polygon", coordinates: linearRings};
    }
    return null;
  }

  /**
   * Parse coordinates to a geometry.
   *
   * @param s the coordinates, possibly separated by newlines, rings separated by multiple newlines
   * @return the GeoJSON point, line, polygon or null
   */
  public parseGeometry(s: string | null | undefined): Geometry | null {
    const linearRingStrings = s?.split(/\r?\n(\r?\n)+/);
    if (linearRingStrings?.length === 1) {
      const coordinateStrings = linearRingStrings[0].split(/(\r?\n)+/).filter(c => c.trim() !== "");
      if (coordinateStrings.length === 1) {
        // there is only one coordinate, thus it is a point
        return this.parsePoint(coordinateStrings[0]);
      }
    }
    const linearRings: number[][][] | undefined = linearRingStrings
      ?.map(lines => this.parseLineString(lines))
      .filter(c => c)
      .map(c => c!.coordinates);
    if (linearRings && linearRings.length >= 1 && linearRings?.every(ring => ring.length >= 4 && this.sameCoords(ring[0], ring[ring.length - 1]))) {
      // if all rings start/end with the same point, assume that it is a polygon
      return {type: "Polygon", coordinates: linearRings};
    } else if (linearRings?.length === 1) {
      return {type: "LineString", coordinates: linearRings[0]};
    }
    return null;
  }

  private sameCoords(c1: number[], c2: number[]) {
    return c1.every((n, i) => n === c2[i]);
  }

  /**
   * Parse a longitude.
   *
   * @param s the longitude or coordinate as string
   * @return the longitude as number or null
   */
  public parseLongitude(s: string | null | undefined): number | null {
    if (!s) {
      return null;
    }
    this.init();
    this.initParsers();
    const parsers = this.parsers.filter(p => p.type === "lon" || p.type === "both");
    for (const parser of parsers) {
      const match = s.trim().match(`^${parser.pattern}$`);
      if (match != null) {
        const parts = parser.extractor(match);
        const lon = this.createNumberFromParts(parts[0], parts[1], parts[2], parts[3]);
        if (lon != null) {
          return lon;
        }
      }
    }
    return null;
  }

  /**
   * Parse a latitude.
   *
   * @param s the latitude or coordinate as string
   * @return the latitude as number or null
   */
  public parseLatitude(s: string | null | undefined): number | null {
    if (!s) {
      return null;
    }
    this.init();
    this.initParsers();
    const parsers = this.parsers.filter(p => p.type === "lat" || p.type === "both");
    for (const parser of parsers) {
      const match = s.trim().match(`^${parser.pattern}$`);
      if (match != null) {
        const parts = parser.extractor(match);
        const lat = this.createNumberFromParts(parts[4], parts[5], parts[6], parts[7]);
        if (lat != null) {
          return lat;
        }
      }
    }
    return null;
  }

  private namedFormat(name: string): string | null {
    return messages.isReady() && messages.has(`core.format.coordinate.${name}`) ? messages.get(`core.format.coordinate.${name}`) : this.formats[name] || null;
  }

  private static toCoordinate(c: any): number[] | null {
    if (Array.isArray(c) && c.length >= 2 && typeof c[0] === "number" && typeof c[1] === "number") {
      return c;
    } else if (c && ("type" in c) && ("coordinates" in c) && c.type === "Point") {
      // it's a point geometry
      return CoordinateService.toCoordinate(c.coordinates);
    }
    return null;
  }

  private static toLongitude(c: any): number | null {
    if (typeof c === "number") {
      return c;
    } else if (typeof c === "string") {
      return Number.parseFloat(c);
    } else if (c && Array.isArray(c) && c.length >= 2 && typeof c[0] === "number" && typeof c[1] === "number") {
      return c[0];
    } else if (c && ("type" in c) && ("coordinates" in c) && c.type === "Point") {
      // it's a point geometry
      return CoordinateService.toLongitude(c.coordinates);
    }
    return null;
  }

  private static toLatitude(c: any): number | null {
    if (typeof c === "number") {
      return c;
    } else if (typeof c === "string") {
      return Number.parseFloat(c);
    } else if (c && Array.isArray(c) && c.length >= 2 && typeof c[0] === "number" && typeof c[1] === "number") {
      return c[1];
    } else if (c && ("type" in c) && ("coordinates" in c) && c.type === "Point") {
      // it's a point geometry
      return CoordinateService.toLatitude(c.coordinates);
    }
    return null;
  }
}

const coordinateService = new CoordinateService();

export {coordinateService, MessageFormat};
