/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export  const convertBytesToMbsOrKbs = (filesize: number) => {
  let size: string;

  if (filesize >= 1048576) {
    size = (filesize / 1048576).toFixed(2) + " MB";
  } else if (filesize >= 1024) {
    size = (filesize / 1024).toFixed(2) + " KB";
  } else {
    size = filesize + " B";
  }

  return size;
};
