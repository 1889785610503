/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {useMemo} from "react";

import {CoreApi} from "../api";
import {BaseViewModelData, ViewModel} from "../store";
import {ViewActionHandlersByActionId} from "./ViewActions";
import {ViewDescriptor} from "./ViewDescriptor";

export type SetViewActionHandlers = (viewActionHandlers: ViewActionHandlersByActionId) => void;

export interface IViewProps<ViewModelData extends BaseViewModelData> {
  // @deprecated
  viewParameters?: CoreApi.ParameterList
  // should be called as soon as the view is rendered
  onFinishedRenderView?: () => void
  // alternatively use the useViewModel<ViewModelData>() hook (also to update the viewModel)
  viewModel: ViewModel<ViewModelData>
  setViewActionHandlers: SetViewActionHandlers
}

export type View<ViewModelData extends BaseViewModelData> =
  | React.ComponentClass<IViewProps<ViewModelData>>
  | React.FC<IViewProps<ViewModelData>>;


export type ViewDescriptorMap = Partial<Record<string, ViewDescriptor<BaseViewModelData>>>;

export const ViewDescriptorRegistryContext = React.createContext<ViewDescriptorMap>({});

type ViewDescriptorRegistryProviderProps = {
  availableViewDescriptors: ViewDescriptor<BaseViewModelData>[]
}

export const ViewDescriptorRegistryProvider = (props: React.PropsWithChildren<ViewDescriptorRegistryProviderProps>) => {
  const availableViews = useMemo(
    () => props.availableViewDescriptors.reduce((map: ViewDescriptorMap, vd) => {
      if (process.env.NODE_ENV === "development" && map[vd.viewType]) {
        console.error("duplicate registration of", vd.viewType);
      }
      map[vd.viewType] = vd;
      return map;
    }, {}), [props.availableViewDescriptors],
  );

  return (
    <ViewDescriptorRegistryContext.Provider value={availableViews}>
      {props.children}
    </ViewDescriptorRegistryContext.Provider>
  );
};
