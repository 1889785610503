/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CircularProgress, Paper} from "@mui/material";
import * as React from "react";

import styles from "./LoadingSpinnerStyle";

export const LoadingSpinner = React.memo(() => {
  return (
    <Paper sx={styles.root} elevation={0}>
      <CircularProgress sx={styles.ring} />
    </Paper>
  );
});
