/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, useFeedbackBanners, useMessages} from "@icm/core-common";
import {useCallback} from "react";

/**
 * Usage: `clipboard.writeText("text");
 */
export const useClipboard = () => {
  const {openFeedbackBanner} = useFeedbackBanners();
  const {getMessage} = useMessages();

  const hasClipboard = !!navigator.clipboard;
  const writeText = useCallback((text: string, feedbackText?: string) => {
    const openFallback = () => {
      openFeedbackBanner({
        key: "clipboard",
        title: text,
        duration: "LONG",
        variant: "INFO",
      });
    };
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => {
          openFeedbackBanner({
            key: "clipboard",
            title: feedbackText ?? getMessage(MessageKey.CORE.COPIED_TEXT_TO_CLIP_BOARD),
            duration: "SHORT",
            variant: "INFO",
          });
        })
        .catch(() => {
          console.warn("write to clipboard failed, showing text in feedback banner instead");
          openFallback();
        });
    } else {
      openFallback();
    }
  }, [openFeedbackBanner, getMessage]);
  return {
    hasClipboard,
    writeText,
  };
};
