/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataUpdate} from "../generated/api";

class DataUpdateService {
  private static readonly INSTANCE = new DataUpdateService();

  public static getInstance() {
    return DataUpdateService.INSTANCE;
  }

  private readonly listeners: Map<string, Array<(update: DataUpdate) => any>>;

  private constructor() {
    this.listeners = new Map<string, Array<(update: DataUpdate) => any>>();
  }

  public registerListener(entityType: string, listener: (update: DataUpdate) => any) {
    if (!this.listeners.has(entityType)) {
      this.listeners.set(entityType, []);
    }
    this.listeners.get(entityType)!.push(listener);
  }

  public notify(update: DataUpdate) {
    console.debug("Received data update", update);
    if (update.entityType && this.listeners.has(update.entityType)) {
      this.listeners.get(update.entityType)!.forEach(f => f(update));
    }
  }
}

const dataUpdateService = DataUpdateService.getInstance();
export {dataUpdateService};
