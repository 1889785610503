/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {makeStyles} from "@mui/styles";
import {useEffect} from "react";
import * as React from "react";

type BreakPoints = {
  SM?: number;
  MD?: number;
  LG?: number;
  XL?: number;
}

function generateGridContainerQueryClasses(breakpoint: string) {
  const styles = {};
  const GRID_SIZES = ["auto", true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  for (const size of GRID_SIZES) {
    const key = `&>.MuiGrid-grid-${breakpoint}-${size}`;
    if (size === true) {
      styles[key] = {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: "100%",
      };
    } else if (size === "auto") {
      styles[key] = {
        flexBasis: "auto",
        flexGrow: 0,
        maxWidth: "none",
      };
    } else if (typeof size === "number") {
      // Keep 7 significant numbers.
      const width = `${Math.round((size / 12) * 10e7) / 10e5}%`;
      styles[key] = {
        flexBasis: width,
        flexGrow: 1,
        maxWidth: "100%",
      };
    }
  }
  // the classes hide-xx, hide-xx-up, hide-xx-down can be used instead of the <Hidden> component, which does not work here
  const breakpoints = ["xs", "sm", "md", "lg", "xl"];
  const i = breakpoints.indexOf(breakpoint);
  if (i >= 0) {
    styles[`&>.hide-${breakpoint}`] = {display: "none"};
    breakpoints.slice(0, i).forEach(bp => styles[`&>.hide-${bp}`] = {display: "block"});
    breakpoints.slice(i).forEach(bp => styles[`&>.hide-${bp}-down`] = {display: "none"});
    breakpoints.slice(0, i).forEach(bp => styles[`&>.hide-${bp}-down`] = {display: "block"});
    styles[`&>.hide-${breakpoint}-up`] = {display: "none"};
  }
  return styles;
}

const useStyles = makeStyles(() => ({
  "@global": {
    ".XS": generateGridContainerQueryClasses("xs"),
    ".SM": generateGridContainerQueryClasses("sm"),
    ".MD": generateGridContainerQueryClasses("md"),
    ".LG": generateGridContainerQueryClasses("lg"),
    ".XL": generateGridContainerQueryClasses("xl"),
  },
}));

type ContainerGridProperties = {
  className?: string;
  enabled?: boolean;
} & BreakPoints;

/**
 * This component watches the DOM for added/removed grid containers (class MuiGrd-container) and adds resize observers for them.
 * The containers receive classes XS, SM, MD, LG, XL depending on their width.
 * By defining CSS rules like .XS > .MuiGrid-grid-xs-12 these rules will override the standard media-query rules, which depend on the viewport size.
 */
export const ContainerGrid: React.FC<ContainerGridProperties> = (props) => {
  const className = props.className || "MuiGrid-container";
  useStyles();
  useEffect(() => {
    const breakpoints: BreakPoints = {
      SM: props.SM || 600,
      MD: props.MD || 960,
      LG: props.LG || 1280,
      XL: props.XL || 1920,
    };
    if (window.MutationObserver) {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => onResize(entries, breakpoints));
      const mutationObserver = new MutationObserver(mutations => onMutation(mutations, className, resizeObserver));
      // we assume that the container class name is set before the node is added and it is never changed:
      mutationObserver.observe(document, {childList: true, subtree: true});
      for (const element of document.querySelectorAll(`.${className}`)) {
        resizeObserver.observe(element);
      }
      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
      };
    }
    return () => {};
  }, [className, props.LG, props.MD, props.SM, props.XL]);

  return null;
};

function onMutation(mutations: MutationRecord[], className: string, resizeObserver: ResizeObserver) {
  for (const mutation of mutations) {
    // the removed and added nodes can (and will) be whole sub trees!
    for (const node of mutation.removedNodes) {
      removed(node, className, resizeObserver);
    }
    for (const node of mutation.addedNodes) {
      added(node, className, resizeObserver);
    }
  }
}

function added(node: Node, className: string, resizeObserver: ResizeObserver) {
  if (node instanceof Element && node.classList.contains(className)) {
    resizeObserver.observe(node);
  }
  for (const child of node.childNodes) {
    added(child, className, resizeObserver);
  }
}

function removed(node: Node, className: string, resizeObserver: ResizeObserver) {
  if (node instanceof Element && node.classList.contains(className)) {
    resizeObserver.unobserve(node);
  }
  for (const child of node.childNodes) {
    removed(child, className, resizeObserver);
  }
}

function onResize(entries: ResizeObserverEntry[], defaultBreakPoints: BreakPoints) {
  for (const entry of entries) {
    // we need to explicitly set class XS, so that e.g. this container based class "XS" overrides a window based "sm"
    entry.target.classList.add("XS");
    const breakpoints = getBreakPoints(entry.target, defaultBreakPoints);
    // Update the matching breakpoints on the observed element.
    Object.entries(breakpoints).forEach(bp => {
      const minWidth = bp[1] || 0;
      if (entry.contentRect.width >= minWidth) {
        entry.target.classList.add(bp[0]);
      } else {
        entry.target.classList.remove(bp[0]);
      }
    });
  }
}

function getBreakPoints(target: Element, defaultBreakpoints: BreakPoints): BreakPoints {
  if (target["dataset"] && target["dataset"].breakpoints) {
    const breakpoints = JSON.parse(target["dataset"].breakpoints) as BreakPoints;
    return {...defaultBreakpoints, ...breakpoints};
  }
  return defaultBreakpoints;
}
