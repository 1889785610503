/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ConfiguredColorScheme, PaletteColor} from "@icm/core-common";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import * as React from "react";

import {IcmMuiTheme} from "../../theme";

type FilledTextChipProps = React.PropsWithChildren<{
  // @default true
  visible?: boolean
  // @default primary
  color?: PaletteColor
  // @default textPrimary
  textColor?: keyof ConfiguredColorScheme
  // @default middle
  size?: Size
  className?: string
}>

type Size = "small" | "middle";

const sizes: Record<Size, [number, number]> = {
  small: [3, 2],
  middle: [5, 3],
};

type StyleProps = Required<Omit<FilledTextChipProps, "children" | "className">>;


const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles(({
  root: ({visible, color, size, textColor}: StyleProps) => (visible ? {
    backgroundColor: theme.icm.palette[color].main,
    // the second part (same, but with inset is a hack to avoid a border artefact)
    boxShadow: `${theme.icm.palette[color].main} 0 0 0 ${sizes[size][0]}px, ${theme.icm.palette[color].main} 0 0 0 ${sizes[size][0] * 2}px inset`,
    color: theme.icm.palette[color][textColor],
    borderRadius: sizes[size][1],
  } : {}),
})));

/**
 * Contains its children in a filled chip with a padding of 5px.
 */
export const FilledTextChip = React.memo((
  {visible = true, color = "primary", textColor = "textPrimary", size = "middle", className, children}: FilledTextChipProps
) => {
  const classes = useStyles({visible, color, textColor, size});
  return (
    <span className={clsx(classes.root, className)}>
      {children}
    </span>
  );
});
