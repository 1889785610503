/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {Actor, ObjectChange} from "../generated/api";
import {ActivityStreamState, ObjectUpdateInformation} from "./types";


const initialActivityStreamState: ActivityStreamState = {
  objectUpdatesByType: {},
};

const {reducer, actions} = createSlice({
  name: "@@activitystream",
  initialState: initialActivityStreamState,
  reducers: {
    /**
     * Remove all entity update information for a given entity type.
     *
     * @param state
     * @param action
     */
    clearEntityUpdates: (state, action: PayloadAction<{type: string}>) => {
      const {type} = action.payload;
      const objectUpdatesByType = state.objectUpdatesByType[type];
      if (objectUpdatesByType) {
        const ids = Object.values(objectUpdatesByType as Record<string, ObjectUpdateInformation<unknown>>).map(u => u.id);
        console.log("Clearing all update information for entity of type:", type);
        for (const id of ids) {
          delete objectUpdatesByType[id];
        }
      }
    },
    setEntityUpdateAsSeen: (state, action: PayloadAction<{type: string, idOrIds: string | string[], seenUntil?: Date}>) => {
      const {type, idOrIds, seenUntil} = action.payload;
      const ids = typeof idOrIds === "string" ? [idOrIds] : idOrIds;
      const objectUpdatesByType = state.objectUpdatesByType[type];
      if (objectUpdatesByType) {
        for (const id of ids) {
          const objectUpdate = objectUpdatesByType[id];
          if (objectUpdate) {
            const lastUpdatedAt = objectUpdate.lastUpdatedAt;
            objectUpdate.showUpdatesSince = seenUntil ?? lastUpdatedAt;
            console.log("Entity has been set as seen", type, id);
          }
        }
      }
    },
    notifyNewObjectUpdate: (state, action: PayloadAction<{type: string, id: string, lastUpdatedAt: Date, objectChange?: ObjectChange, actor?: Actor}>) => {
      const {type, id, lastUpdatedAt, objectChange, actor} = action.payload;

      if (!state.objectUpdatesByType[type]) {
        state.objectUpdatesByType[type] = {};
      }

      if (!state.objectUpdatesByType[type]![id]) {
        state.objectUpdatesByType[type]![id] = {
          id,
          type,
          lastUpdatedAt,
          objectUpdates: [],
        };
      }
      const objectUpdate = state.objectUpdatesByType[type]![id]!;

      objectUpdate.lastUpdatedAt = lastUpdatedAt;
      if (objectChange?.type) {
        objectUpdate.objectUpdates.push({
          date: lastUpdatedAt,
          type: objectChange.type,
          actor,
          object: objectChange.objectReference?.object,
          propertyUpdates: objectChange.propertyUpdates,
        });
      }
    },
  },
});

export const {notifyNewObjectUpdate, setEntityUpdateAsSeen, clearEntityUpdates} = actions;

export {reducer as activityStreamReducer};
