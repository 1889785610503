/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  TabGroupTab,
  useExecuteViewAction,
  useViewActionState,
  useConnectionState,
} from "@icm/core-common";
import * as React from "react";

import {IconButtonComponent} from "../form";
import {useTabContentActions} from "./useTabContentActions";

export type TabContentActionBarProps = {
  tab: TabGroupTab
}

export const TabContentActionBar = ({tab}: TabContentActionBarProps) => {
  const viewModel = tab.viewModel;
  const tabContentActions = useTabContentActions(viewModel);
  const executeViewAction = useExecuteViewAction(viewModel.id);
  const viewActionState = useViewActionState(viewModel.id);
  const connected: boolean = useConnectionState();
  if (tabContentActions) {
    return (
      <>
        {tabContentActions.map(entry => {
          const key = entry.actionType;
          const action = entry.action;
          const enabled = connected && ((action.enabled ?? true) && viewActionState[key]);
          if (action.componentFactory) {
            return action.componentFactory(key, action, executeViewAction, !enabled, false, false);
          } else {
            return (
              <IconButtonComponent key={key}
                                   tooltip={action.title}
                                   icon={action.icon}
                                   handleClick={() => executeViewAction(key)}
                                   disabled={!enabled}
              />
            );
          }
        })}
      </>
    );
  }
  return null;
};
