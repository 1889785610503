/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:18.

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ActivityStreamEntryDto
 */
export class ActivityStreamEntry {
    id?: string;
    createdAt?: Date;
    timestamp?: Date;
    actor?: Actor;
    accessRestriction?: RoleAccessRestriction;
    changes?: ChangeUnion[];

    static fromData(data: any): ActivityStreamEntry {
        if (!data) {
            return data;
        }
        const instance: ActivityStreamEntry = { id: data.id && __verifyIsString(data.id), createdAt: data.createdAt && new Date(data.createdAt), timestamp: data.timestamp && new Date(data.timestamp), actor: data.actor && Actor.fromData(data.actor), accessRestriction: data.accessRestriction && RoleAccessRestriction.fromData(data.accessRestriction), changes: data.changes && __getCopyArrayFn(Change.fromDataUnion)(data.changes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','createdAt','timestamp','actor','accessRestriction','changes'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ActivityStreamEntryListPortionDto
 */
export class ActivityStreamEntryListPortion {
    sublist?: ActivityStreamEntry[];
    havingMore?: boolean;

    static fromData(data: any): ActivityStreamEntryListPortion {
        if (!data) {
            return data;
        }
        const instance: ActivityStreamEntryListPortion = { sublist: data.sublist && __getCopyArrayFn(ActivityStreamEntry.fromData)(data.sublist), havingMore: data.havingMore && __verifyIsBoolean(data.havingMore) };
        return instance;
    }

    static getProperties(): string[] {
        return ['sublist','havingMore'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ActorDto
 */
export class Actor {
    displayName?: string;
    roleDisplayName?: string;
    userName?: string;
    roleName?: string;

    static fromData(data: any): Actor {
        if (!data) {
            return data;
        }
        const instance: Actor = { displayName: data.displayName && __verifyIsString(data.displayName), roleDisplayName: data.roleDisplayName && __verifyIsString(data.roleDisplayName), userName: data.userName && __verifyIsString(data.userName), roleName: data.roleName && __verifyIsString(data.roleName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['displayName','roleDisplayName','userName','roleName'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ChangeDto
 */
export class Change {
    changeType: "COMMUNICATION" | "OBJECT" | "OTHER";
    eventType?: string;
    eventDetails?: { [index: string]: any };

    static fromData(data: any): Change {
        if (!data) {
            return data;
        }
        const instance: Change = { changeType: data.changeType, eventType: data.eventType && __verifyIsString(data.eventType), eventDetails: data.eventDetails && __getCopyObjectFn(__identity<any>())(data.eventDetails) };
        return instance;
    }

    static fromDataUnion(data: ChangeUnion): ChangeUnion {
        if (!data) {
            return data;
        }
        switch (data.changeType) {
            case "COMMUNICATION":
                return CommunicationChange.fromData(data);
            case "OBJECT":
                return ObjectChange.fromData(data);
            case "OTHER":
                return OtherChange.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['changeType','eventType','eventDetails'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.PropertyUpdateDto
 */
export class PropertyUpdate {
    updateType: "COLLECTION" | "OTHER" | "VALUE";
    accessRestriction?: RoleAccessRestriction;

    static fromData(data: any): PropertyUpdate {
        if (!data) {
            return data;
        }
        const instance: PropertyUpdate = { updateType: data.updateType, accessRestriction: data.accessRestriction && RoleAccessRestriction.fromData(data.accessRestriction) };
        return instance;
    }

    static fromDataUnion(data: PropertyUpdateUnion): PropertyUpdateUnion {
        if (!data) {
            return data;
        }
        switch (data.updateType) {
            case "COLLECTION":
                return CollectionPropertyUpdate.fromData(data);
            case "OTHER":
                return OtherPropertyUpdate.fromData(data);
            case "VALUE":
                return ValuePropertyUpdate.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['updateType','accessRestriction'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.CollectionPropertyUpdateDto
 */
export class CollectionPropertyUpdate extends PropertyUpdate {
    updateType: "COLLECTION";
    fieldReference?: FieldReference;
    addedItems?: any[];
    removedItems?: any[];
    format?: string;

    static fromData(data: any): CollectionPropertyUpdate {
        if (!data) {
            return data;
        }
        const instance: CollectionPropertyUpdate = { ...(super.fromData(data) as CollectionPropertyUpdate), fieldReference: data.fieldReference && FieldReference.fromData(data.fieldReference), addedItems: data.addedItems && __getCopyArrayFn(__identity<any>())(data.addedItems), removedItems: data.removedItems && __getCopyArrayFn(__identity<any>())(data.removedItems), format: data.format && __verifyIsString(data.format) };
        return instance;
    }

    static getProperties(): string[] {
        return ['updateType','fieldReference','addedItems','removedItems','format'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.CommunicationChangeDetailsDto
 */
export class CommunicationChangeDetails {
    channel?: string;
    sender?: string;
    receiver?: string;
    type?: string;
    status?: string;

    static fromData(data: any): CommunicationChangeDetails {
        if (!data) {
            return data;
        }
        const instance: CommunicationChangeDetails = { channel: data.channel && __verifyIsString(data.channel), sender: data.sender && __verifyIsString(data.sender), receiver: data.receiver && __verifyIsString(data.receiver), type: data.type && __verifyIsString(data.type), status: data.status && __verifyIsString(data.status) };
        return instance;
    }

    static getProperties(): string[] {
        return ['channel','sender','receiver','type','status'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.CommunicationChangeDto
 */
export class CommunicationChange extends Change {
    changeType: "COMMUNICATION";
    objectReference?: ObjectReference;
    type?: ObjectChangeType;
    communicationDetails?: CommunicationChangeDetails;

    static fromData(data: any): CommunicationChange {
        if (!data) {
            return data;
        }
        const instance: CommunicationChange = { ...(super.fromData(data) as CommunicationChange), objectReference: data.objectReference && ObjectReference.fromData(data.objectReference), type: data.type && data.type, communicationDetails: data.communicationDetails && CommunicationChangeDetails.fromData(data.communicationDetails) };
        return instance;
    }

    static getProperties(): string[] {
        return ['changeType','objectReference','type','communicationDetails'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.FieldReferenceDto
 */
export class FieldReference {
    displayName?: string;
    valueBinding?: string;

    static fromData(data: any): FieldReference {
        if (!data) {
            return data;
        }
        const instance: FieldReference = { displayName: data.displayName && __verifyIsString(data.displayName), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['displayName','valueBinding'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ObjectChangeDto
 */
export class ObjectChange extends Change {
    changeType: "OBJECT";
    objectReference?: ObjectReference;
    type?: ObjectChangeType;
    propertyUpdates?: PropertyUpdateUnion[];
    firstChangeNotMatchingFilter?: boolean;

    static fromData(data: any): ObjectChange {
        if (!data) {
            return data;
        }
        const instance: ObjectChange = { ...(super.fromData(data) as ObjectChange), objectReference: data.objectReference && ObjectReference.fromData(data.objectReference), type: data.type && data.type, propertyUpdates: data.propertyUpdates && __getCopyArrayFn(PropertyUpdate.fromDataUnion)(data.propertyUpdates), firstChangeNotMatchingFilter: data.firstChangeNotMatchingFilter && __verifyIsBoolean(data.firstChangeNotMatchingFilter) };
        return instance;
    }

    static getProperties(): string[] {
        return ['changeType','objectReference','type','propertyUpdates','firstChangeNotMatchingFilter'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ObjectReferenceDto
 */
export class ObjectReference {
    object?: any;
    id?: string;
    type?: string;
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };
    metadata?: { [index: string]: any };

    static fromData(data: any): ObjectReference {
        if (!data) {
            return data;
        }
        const instance: ObjectReference = { object: data.object && data.object, id: data.id && __verifyIsString(data.id), type: data.type && __verifyIsString(data.type), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['object','id','type','dynamicAttributes','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.OtherChangeDto
 */
export class OtherChange extends Change {
    changeType: "OTHER";
    description?: string;
    relatedObjects?: ObjectReference[];

    static fromData(data: any): OtherChange {
        if (!data) {
            return data;
        }
        const instance: OtherChange = { ...(super.fromData(data) as OtherChange), description: data.description && __verifyIsString(data.description), relatedObjects: data.relatedObjects && __getCopyArrayFn(ObjectReference.fromData)(data.relatedObjects) };
        return instance;
    }

    static getProperties(): string[] {
        return ['changeType','description','relatedObjects'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.OtherPropertyUpdateDto
 */
export class OtherPropertyUpdate extends PropertyUpdate {
    updateType: "OTHER";
    description?: string;

    static fromData(data: any): OtherPropertyUpdate {
        if (!data) {
            return data;
        }
        const instance: OtherPropertyUpdate = { ...(super.fromData(data) as OtherPropertyUpdate), description: data.description && __verifyIsString(data.description) };
        return instance;
    }

    static getProperties(): string[] {
        return ['updateType','description'];
    }
}

/**
 * Generated from: at.cns.icm.activitystream.backend.api.data.ValuePropertyUpdateDto
 */
export class ValuePropertyUpdate extends PropertyUpdate {
    updateType: "VALUE";
    fieldReference?: FieldReference;
    oldValue?: any;
    newValue?: any;
    format?: string;

    static fromData(data: any): ValuePropertyUpdate {
        if (!data) {
            return data;
        }
        const instance: ValuePropertyUpdate = { ...(super.fromData(data) as ValuePropertyUpdate), fieldReference: data.fieldReference && FieldReference.fromData(data.fieldReference), oldValue: data.oldValue && data.oldValue, newValue: data.newValue && data.newValue, format: data.format && __verifyIsString(data.format) };
        return instance;
    }

    static getProperties(): string[] {
        return ['updateType','fieldReference','oldValue','newValue','format'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.RoleAccessRestrictionDto
 */
export class RoleAccessRestriction {
    allowedRoles?: string[];

    static fromData(data: any): RoleAccessRestriction {
        if (!data) {
            return data;
        }
        const instance: RoleAccessRestriction = { allowedRoles: data.allowedRoles && __getCopyArrayFn(__identity<string>())(data.allowedRoles) };
        return instance;
    }

    static getProperties(): string[] {
        return ['allowedRoles'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.DynamicAttributeValueDto
 */
export class DynamicAttributeValue {
    type?: any;
    id?: any;
    version?: any;
    value?: any;
    valueDisplay?: any;

    static fromData(data: any): DynamicAttributeValue {
        if (!data) {
            return data;
        }
        const instance: DynamicAttributeValue = { type: data.type && data.type, id: data.id && data.id, version: data.version && data.version, value: data.value && data.value, valueDisplay: data.valueDisplay && data.valueDisplay };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','version','value','valueDisplay'];
    }
}

export type ObjectChangeType = "CREATE" | "UPDATE" | "ARCHIVE" | "DELETE" | "COMMUNICATION";

export type ChangeUnion = CommunicationChange | ObjectChange | OtherChange;

export type PropertyUpdateUnion = CollectionPropertyUpdate | OtherPropertyUpdate | ValuePropertyUpdate;

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
