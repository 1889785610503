/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {OptionsObject, useSnackbar, VariantType} from "notistack";
import * as React from "react";
import {PropsWithChildren, useCallback, useEffect} from "react";
import {useStore} from "react-redux";

import {FeedbackBanner, FeedbackContext, feedbackService, FeedbackVariant} from "../../service";
import {ICoreApplicationState} from "../../store";
import {styles} from "./FeedbackBanner.style";

export const FeedbackContextProvider = ({children}: PropsWithChildren<{}>) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const store = useStore<ICoreApplicationState>();

  const closeFeedbackBanner = useCallback((key: string): void => {
    closeSnackbar(key);
  }, [closeSnackbar]);

  const openFeedbackBanner = useCallback((feedbackBanner: FeedbackBanner): void => {
    if (feedbackBanner.omitCondition && feedbackBanner.omitCondition(store.getState())) {
      console.log("Not showing feedback banner", feedbackBanner.key, "; omitCondition was true");
      return;
    }

    const options: OptionsObject = {
      key: feedbackBanner.key,
      variant: getNotistackVariant(feedbackBanner.variant),
      onClose: (event, reason) => {
        if (reason === "clickaway") {
          if (options.persist) {
            console.log("Not closing persisted feedback banner on clickaway", feedbackBanner.key);
          } else {
            console.log("Closing feedback banner on clickaway", feedbackBanner.key);
            closeSnackbar(feedbackBanner.key);
          }
          return;
        }
        console.log("triggering feedback banner's onClose action", feedbackBanner.key);
        feedbackBanner.onClose?.();
      },
      preventDuplicate: true,
    };

    switch (feedbackBanner.duration) {
      case "INDEFINITE":
        options.persist = true;
        break;
      case "LONG":
        options.autoHideDuration = 10000;
        break;
      case "MEDIUM":
        options.autoHideDuration = 6000;
        break;
      case "SHORT":
        options.autoHideDuration = 3000;
        break;
    }

    if (feedbackBanner.closeActions && feedbackBanner.closeActions.length > 0) {
      options.action = (
        <>
          {feedbackBanner.closeActions.map((closeAction, idx) => (
            <div role="button"
                 key={idx}
                 onClick={closeAction.action}
                 className={styles.closeButton}
            >{closeAction.label}
            </div>
          ))}
        </>
      );
    }

    enqueueSnackbar(feedbackBanner.title, options);
  }, [closeSnackbar, enqueueSnackbar, store]);

  useEffect(() => {
    feedbackService.registerUiContext({openFeedbackBanner, closeFeedbackBanner});
  }, [closeFeedbackBanner, openFeedbackBanner]);

  return (
    <FeedbackContext.Provider value={{openFeedbackBanner, closeFeedbackBanner}}>
      {children}
    </FeedbackContext.Provider>
  );
};


const getNotistackVariant = (variant: FeedbackVariant): VariantType => {
  switch (variant) {
    case "DEFAULT":
      return "default";
    case "ERROR":
      return "error";
    case "INFO":
      return "info";
    case "SUCCESS":
      return "success";
    case "WARNING":
      return "warning";
  }
  throw new Error("Invalid feedback banner variant: " + variant);
};
