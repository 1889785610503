/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {IcmServiceRegistry} from "./IcmServiceRegistry";
import {IcmServiceKey, IcmServices} from "./IcmServices";


type ServiceDescriptionAndInformation<T extends IcmServiceKey> = {
  key: IcmServiceKey
  instance: IcmServices[T]
}

type IcmServiceContainer = Partial<Record<IcmServiceKey, ServiceDescriptionAndInformation<IcmServiceKey>>>;

export class DefaultIcmServiceRegistry implements IcmServiceRegistry {

  private readonly services: IcmServiceContainer;

  constructor() {
    this.services = {};
    this.get = this.get.bind(this);
    this.getOptional = this.getOptional.bind(this);
    this.register = this.register.bind(this);
    this.unregister = this.unregister.bind(this);
  }

  get<T extends IcmServiceKey>(key: IcmServiceKey): IcmServices[T] {
    const instance = this.services[key]?.instance;
    if (!instance) {
      // some services do not exist, e.g. notification service, so returning undefined is ok.
      // thus we must not throw an exception
      console.warn(`No service registered for ${key}`);
    }
    return instance as IcmServices[T];
  }

  getOptional<T extends IcmServiceKey>(key: T): IcmServices[T] | undefined {
    const instance = this.services[key]?.instance;
    if (!instance) {
      return undefined;
    }
    return instance as IcmServices[T];
  }

  register<T extends IcmServiceKey>(key: T, instance: IcmServices[T]): void {
    if (this.services[key]) {
      console.warn(`Service with key ${key} already registered. Ignoring override attempt.`);
      return;
    }
    this.services[key] = {
      key,
      instance,
    };
  }

  unregister<T extends IcmServiceKey>(key: T): void {
    this.services[key] = undefined;
  }

}
