/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {useState} from "react";

import {Message} from "./Message";
import {MessageEditController} from "./MessageEditController";
import {MessageTemplateProvider} from "./MessageTemplateProvider";


export type MessageEditControllerProps = {

  /**
   * If the message has a subject.
   */
  hasSubject?: boolean;

  /**
   * Flag to control if the message can be edited (subject and content).
   */
  editable?: boolean

  /**
   * Returns the message that should be used to initialize default values.
   */
  templateProvider: MessageTemplateProvider,

}

export const useMessageEditController = (props: MessageEditControllerProps): MessageEditController => {
  const {
    hasSubject = false,
    editable = true,
    templateProvider,
  } = props;

  const template = templateProvider.getTemplate();

  const [message, setMessage] = useState(template);

  return {
    hasSubject(): boolean {
      return hasSubject;
    },
    isContentEditable(): boolean {
      return editable;
    },
    getMessage(): Message {
      return message;
    },
    setSubject(subject: string) {
      if (hasSubject) {
        setMessage(oldMessage => ({
          ...oldMessage,
          subject,
        }));
      }
    },
    setContent(content: string) {
      if (editable) {
        setMessage(oldMessage => ({
          ...oldMessage,
          content,
        }));
      }
    },
  };
};
