/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";

import {TEXT_AREA, TEXT_FIELD} from "../../../constant";
import {ComponentType, PossibleValue} from "../../../generated/api";
import {formatService} from "../../../service";
import {getNamedComponentFactory} from "../../../ui";
import {arePropsEqual} from "../../../util";
import {IFormComponentProps} from "../AbstractFormComponent";

export type FormTextComponentProps = IFormComponentProps<any> & {
  originalValue?: any;
  formatter?: (val?: any, fmt?: string, as?: string) => string;
  parser?: (text?: string, fmt?: string) => any;
  format?: string;
  possibleValuesList?: PossibleValue[];
  component: ComponentType;
  validate?: (text?: string, fmt?: string) => boolean;
  formatDisplay?:  (value: string, fmt?: string, as?:  "LABEL" | "INPUT" | "FULL") => string;
  formats?: PossibleValue[]
}

type Props = Omit<FormTextComponentProps, "formatDisplay" | "formats">;
export const FormTextBoxComponent = React.memo((props: Props) => {
  const {parser, formatter, format, possibleValuesList} = props;

  const formats = React.useMemo(() => {
    return possibleValuesList || (format ? formatService.getDetailFormats(format) : undefined);
  }, [possibleValuesList, format]);

  const hasFormats = formats != null;
  const formatDisplay = React.useMemo(() => {
    return hasFormats && formatter ? (value: string, fmt?: string, as?: "LABEL" | "INPUT" | "FULL") => {
      const baseFormat = formats[0].value;
      if (as === "LABEL") {
        try {
          const parsedValue = parser?.(value, baseFormat);
          const formatterInput = parsedValue || value || undefined;
          return formatter(formatterInput, fmt, as);
        } catch (e) {
          console.debug(`Failed to format: ${fmt}, value: ${value} as a LABEL, formatting a default value...`);
          return formatter(undefined, fmt, as);
        }
      }

      try {
        const parsedValue = parser?.(value, baseFormat);
        const formatted = formatter(parsedValue || value, fmt, as);
        return formatted || value;
      } catch (e) {
        console.debug(`Failed to format with ${fmt}, value: ${value}, as ${as}, falling back to value...`);
        return value;
      }
    } : undefined;
  }, [formatter, hasFormats, parser, formats]);

  const Component = getNamedComponentFactory(TEXT_FIELD)
    || getNamedComponentFactory(TEXT_AREA)
    || (() => <span>Text field/area component is not supported.</span>);

  return (
    <Component {...props} formatDisplay={formatDisplay} formats={formats} />
  );
}, arePropsEqual);
