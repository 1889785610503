/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {dateService, JournalEntry} from "@icm/core-common";
import {ListItem, ListItemText, Typography} from "@mui/material";
import React from "react";

import {useWebJournalClasses} from "./JournalStyle";

export type JournalEntryRowProps = {
  /** The journal whose properties (mostly text) should be rendered */
  entry: JournalEntry
}

/**
 * Component to render a single row item in a Journal.
 * @param props
 * @constructor
 */
export const JournalEntryRow = (props: JournalEntryRowProps) => {
  const {entry} = props;
  const timeStamp = dateService.reformatDateTime(entry.logTime);
  const classes = useWebJournalClasses();
  return (
    <div>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={(
            <>
              <Typography
                sx={{display: "inline"}}
                component="span"
                variant="body2"
                className={classes.textSecondary}
              >
                {timeStamp} - {entry.user}/{entry.role}
              </Typography>
            </>
          )}
          secondary={(
            <>
              <Typography
                sx={{display: "inline"}}
                component="span"
                variant="body1"
                className={classes.textPrimary}
              >
                {entry.text}
              </Typography>
            </>
          )}
        />
      </ListItem>
    </div>
  );
};
