/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {UiConfigurationApi} from "../api";
import {NotificationConfiguration, NotificationDisplay, NotificationTrigger} from "../generated/api";
import {addNewNotification, StoreService} from "../store";
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";
import {SecurityService} from "./security";

export type NotificationPropertyUpdate = {
  valueBinding: string
  oldValue?: any
  newValue?: any
}

export type NotificationObjectChange = {
  id: string
  objectType: string
  change: unknown
  changedObject: unknown
  timestamp?: Date
  propertyUpdates?: NotificationPropertyUpdate[]
}

export type NotificationService = {

  /**
   * Call this function to fire a change
   * @param change the change
   */
  fireObjectChange: (objectChange: NotificationObjectChange) => void
}

export const createCoreNotificationService = (securityService: SecurityService): Promise<NotificationService | undefined> => {
  return UiConfigurationApi.getNotificationConfiguration()
    .then(configuration => configuration ? new CoreNotificationService(configuration, securityService) : undefined);
};

export class CoreNotificationService implements NotificationService {
  private readonly configuration: NotificationConfiguration;

  private readonly securityService: SecurityService;

  constructor(configuration: NotificationConfiguration, securityService: SecurityService) {
    this.configuration = configuration;
    this.securityService = securityService;
  }

  fireObjectChange(change: NotificationObjectChange) {
    this.configuration.globalNotificationConfigurations?.forEach(gnc => {
      if (this.isFiringTrigger(change, gnc.notificationTrigger!)) {
        const display: NotificationDisplay = gnc.notificationDisplay!;
        // join id with object type and field value bindings to get a unique key
        const key = `Notification@@@${change.id}@@@${gnc.notificationTrigger?.objectPropertyUpdates?.map(o => `${o.objectType}@${o.fieldValueBinding}`).join("@@@")}`;
        const title = ExpressionEvaluationService.evaluate(display.title ?? "", change);
        const message = display.message ? ExpressionEvaluationService.evaluate(display.message, change) : undefined;
        const timestamp = change.timestamp ?? new Date();

        StoreService.store.dispatch(addNewNotification({
          key,
          title,
          message,
          timestamp,
          actions: display.actions,
          actionContext: change,
        }));
      }
    });
  }

  isFiringTrigger(change: NotificationObjectChange, notificationTrigger: NotificationTrigger): boolean {
    const firingUpdates = (notificationTrigger.objectPropertyUpdates ?? []);
    for (const firingUpdate of firingUpdates) {
      const matchesObjectType = !firingUpdate.objectType || firingUpdate.objectType === change.objectType;
      if (matchesObjectType && firingUpdate.fieldValueBinding) {
        const matchingPropertyChange = this.getPropertyUpdateForValueBinding(firingUpdate.fieldValueBinding, change.propertyUpdates ?? []);
        if (matchingPropertyChange) {
          return this.isPassingValueMatcher(change, firingUpdate.valueMatcher, matchingPropertyChange);
        }
      } else if (matchesObjectType) {
        return this.isPassingValueMatcher(change, firingUpdate.valueMatcher);
      }
    }
    return false;
  }

  isPassingValueMatcher(change: NotificationObjectChange, valueMatcher?: string, propertyUpdate?: NotificationPropertyUpdate): boolean {
    if (valueMatcher) {
      const context = this.securityService.getVisibilityExpressionContext();
      if (propertyUpdate) {
        const value = propertyUpdate.newValue;
        return ExpressionEvaluationService.evaluateRule(valueMatcher, value, change, context);
      } else {
        return ExpressionEvaluationService.evaluateRule(valueMatcher, undefined, change, context);
      }
    } else {
      return true;
    }
  }

  getPropertyUpdateForValueBinding(fieldValueBinding: string, propertyUpdates: NotificationPropertyUpdate[]): NotificationPropertyUpdate | undefined {
    return propertyUpdates.find(u => u.valueBinding === fieldValueBinding);
  }
}
