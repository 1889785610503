/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Location} from "../generated/api";
import {FetchService} from "../service";
import {ApiUtil} from "./ApiUtil";

export class LocationApi {
  public static getAllLocations(type?: string, subType?: string): Promise<Location[]> {
    const url = "core/locations?" + ApiUtil.convertObjectToGetParams({type, subType});
    return FetchService.performGet(url)
      .then(json => ApiUtil.convertArray(json, Location.fromData));
  }
}
