/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {QueryClient} from "react-query";

import {UiConfigurationApi} from "../api";
import {Condition} from "../util";
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";

class PropertyService {
  static readonly PUBLIC_PROPERTIES_QUERY_KEY = ["public", "core", "properties"];

  readonly queryClient: QueryClient;

  private readonly initCompleted: Condition;


  constructor(qc: QueryClient) {
    this.queryClient = qc;
    this.getValue = this.getValue.bind(this);
    ExpressionEvaluationService.resetHelpers("properties");
    ExpressionEvaluationService.registerHelper("properties", "getValue", (key) => {
      return this.getValue(key);
    });
    this.initCompleted = new Condition("PROPERTY_SERVICE_INITIALIZED");
  }

  getValue(key: string, defaultValue?: string): string | undefined {
    const properties = this.queryClient.getQueryData<Record<string, string>>(PropertyService.PUBLIC_PROPERTIES_QUERY_KEY);
    if (properties && !!properties[key]) {
      return properties[key];
    }
    return defaultValue;
  }

  async init(): Promise<boolean> {
    const result = await this.initWithRetriever(UiConfigurationApi.getPublicProperties);
    this.initCompleted.complete();
    return result;
  }

  async initWithRetriever(propertiesRetriever: () => Promise<Record<string, string>>): Promise<boolean> {
    const properties = await propertiesRetriever();
    this.queryClient.setQueryData<Record<string, string>>(PropertyService.PUBLIC_PROPERTIES_QUERY_KEY, properties, {});
    return true;
  }

  async whenInitialized(): Promise<void> {
    return this.initCompleted.applies();
  }
}

export {PropertyService};
