/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IconButtonComponent} from "@icm/core-web";
import * as React from "react";

import {CompleteProcessTaskCommonsProps} from "./types";
import {useCompleteProcessTaskCommons} from "./useCompleteProcessTaskCommons";


export const CompleteProcessTaskIconButton = (props: CompleteProcessTaskCommonsProps) => {
  const {visible, handleClick, menu} = useCompleteProcessTaskCommons(props);

  if (visible) {
    return (
      <span>
        <IconButtonComponent {...props} tooltip={props.text} IconButtonProps={{id: "basic-button"}} handleClick={handleClick} />
        {menu}
      </span>
    );
  } else {
    return (<span />);
  }
};

export const completeProcessTaskIconButtonFactory = (props: CompleteProcessTaskCommonsProps) => <CompleteProcessTaskIconButton {...props} />;
