/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ExpressionEvaluationService} from "@icm/core-common";
import {TaskInstance} from "@icm/workflow-common";

import {Entity} from "../generated/api";

const METADATA_ACTIVE_TASKS = "ACTIVE_TASKS";

/**
 * This service provides various methods that allow querying the state of entity workflows. The constructor of the service
 * registers the respective helper methods to the expression evaluation context.
 */
class EntityWorkflowService {
  constructor() {
    ExpressionEvaluationService.registerHelper("entityWorkflow", "isActiveTask", (entity: Entity, taskName: string) => this.isActiveTask(entity, taskName));
  }

  public isActiveTask(entity: Entity, taskName: string): boolean {
    if (entity && entity.metadata && entity.metadata[METADATA_ACTIVE_TASKS]) {
      const activeTasks: TaskInstance[] = entity.metadata[METADATA_ACTIVE_TASKS];
      const matchingTask: TaskInstance | undefined = activeTasks.find(ti => taskName.toLowerCase() === ti.taskDefinition?.name?.toLowerCase());
      return matchingTask !== undefined;
    } else {
      return false;
    }
  }
}

export {EntityWorkflowService};
