/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ActivityStreamEntry, ObjectReference} from "@icm/activitystream-common";
import {
  MessageKey,
  ObjectLinkingParameter,
  useMessages,
  useService,
  IdGenerator,
} from "@icm/core-common";
import {LinkedObjectSelection} from "@icm/core-web";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Button, Box,
} from "@mui/material";
import {LinkVariant} from "mdi-material-ui";
import {useCallback, useMemo, useState} from "react";
import * as React from "react";

import {ActivityStreamMessageEditor} from "./ActivityStreamMessageEditor";

type ActivityStreamEditorProps = {
  onSubmit: (entry: ActivityStreamEntry) => void
  entity?: any
  objectLinking?: ObjectLinkingParameter
}

export const ActivityStreamEditor = ({onSubmit, entity, objectLinking}: ActivityStreamEditorProps) => {
  const [linkedObjects, setLinkedObjects] = useState<ObjectReference[]>(entity?.id ? [mapEntityToObjectReference(entity)] : []);
  const [dialogOpen, setDialogOpen] = useState(false);

  const {getMessage} = useMessages();
  const securityService = useService("SECURITY");

  const handleSubmit = useCallback((message: string) => {
    const currentUser = securityService.getCurrentUserDetails();
    const activeRoleNames = securityService.getActiveRolesNameString();
    const activeRoleDisplayNames = securityService.getActiveRolesDisplayNameString();
    onSubmit({
      id: IdGenerator.randomUUID(),
      actor: {
        userName: currentUser?.userName,
        displayName: securityService.getCurrentUserFullName(),
        roleName: activeRoleNames,
        roleDisplayName: activeRoleDisplayNames,
      },
      timestamp: new Date(),
      changes: [{
        changeType: "OTHER",
        description: message,
        relatedObjects: linkedObjects,
      }],
    });
    setLinkedObjects([]);
  }, [securityService, onSubmit, linkedObjects]);

  return (
    <>
      <ActivityStreamMessageEditor placeholder={getMessage(MessageKey.ACTIVITYSTREAM.EDITOR.CREATE_NEW_ENTRY)}
                                   submitLabel={getMessage(MessageKey.ACTIVITYSTREAM.EDITOR.SEND)}
                                   submitOnEnter={true}
                                   onSubmit={handleSubmit}
                                   additionalButtons={objectLinking ? [{
                                     label: getMessage(MessageKey.ACTIVITYSTREAM.EDITOR.LINK_ENTITIES),
                                     icon: <LinkVariant />,
                                     onClick: () => setDialogOpen(true),
                                     badgeContent: linkedObjects.length,
                                   }] : undefined}
      />
      {dialogOpen && objectLinking && (
        <ObjectLinkingDialog objectLinking={objectLinking}
                             linkedObjects={linkedObjects}
                             onLinkEntities={linkedEntities => {
                               setLinkedObjects(linkedEntities);
                               setDialogOpen(false);
                             }}
                             onCancel={() => setDialogOpen(false)}
        />
      )}
    </>
  );
};

type ObjectLinkingDialogProps = {
  linkedObjects: ObjectReference[]
  onLinkEntities: (linkedObjects: ObjectReference[]) => void
  onCancel: () => void
  objectLinking: ObjectLinkingParameter
}

const mapEntityToObjectReference = (e: any): ObjectReference => ({
  id: e.id,
  type: e.type,
  dynamicAttributes: e.dynamicAttributes,
  object: e,
});

function entitiesToObjectReferences(entities: any[]): ObjectReference[] {
  return entities.map(mapEntityToObjectReference);
}

function objectReferencesToEntities(objectReferences: ObjectReference[]): any[] {
  return objectReferences.map(or => or.object);
}

const ObjectLinkingDialog = ({onLinkEntities, onCancel, linkedObjects: initialLinkedObjects, objectLinking}: ObjectLinkingDialogProps) => {
  const {getMessage} = useMessages();
  const [linkedObjects, setLinkedObjects] = useState(initialLinkedObjects);

  const asEntities = useMemo(() => objectReferencesToEntities(linkedObjects), [linkedObjects]);

  return (
    <Dialog open={true}>
      <DialogTitle>{getMessage(MessageKey.ACTIVITYSTREAM.EDITOR.LINK_ENTITIES)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getMessage(MessageKey.ACTIVITYSTREAM.EDITOR.LINK_ENTITY_DESCRIPTION)}
        </DialogContentText>
        <Box paddingTop={1}>
          <LinkedObjectSelection objectLinking={objectLinking}
                                 onChange={l => setLinkedObjects(entitiesToObjectReferences(l))}
                                 value={asEntities}
                                 required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}
                variant="outlined"
        >
          {getMessage(MessageKey.CORE.CANCEL)}
        </Button>
        <Button onClick={() => onLinkEntities(linkedObjects)}
                disabled={initialLinkedObjects === linkedObjects}
                variant="contained"
        >
          {getMessage(MessageKey.ACTIVITYSTREAM.EDITOR.LINK_ENTITIES)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
