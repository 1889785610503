/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {intersection} from "lodash-es";
import {useMemo} from "react";

import {GenericFormGroup, GenericFormField} from "../../generated/api";
import {FieldHighlightMap} from "./FormGeneratorTypes";
import {getBadgeColor} from "./FormGeneratorUtils";

export const useFormGroup = (
  evaluateRule: (rule: string | undefined, dflt: boolean, fieldValue?: any) => boolean,
  group: GenericFormGroup,
  formHighlightedFields?: FieldHighlightMap
) => {
  let highlightedFields: string[] = [];
  let outdated = false;
  if (group.widgets && formHighlightedFields) {
    highlightedFields = intersection(group.widgets
      .filter(widget => widget.widgetType === "GENERIC_FIELD")
      .map(widget => widget as GenericFormField)
      .map(field => field.valueBinding!), Object.keys(formHighlightedFields));
    outdated = !!highlightedFields.find(field => formHighlightedFields![field]!.outdated);
  }
  const badgeColor = useMemo(() => getBadgeColor(highlightedFields.length > 0, outdated), [highlightedFields.length, outdated]);
  const collapsable = useMemo(() => evaluateRule(group.collapsable, true), [evaluateRule, group.collapsable]);
  const collapsedByDefault = useMemo(() => evaluateRule(group.collapsedByDefault, true), [evaluateRule, group.collapsedByDefault]);
  const showHeader = useMemo(() => evaluateRule(group.showHeader, true), [evaluateRule, group.showHeader]);
  const expanded = !collapsedByDefault ? true : undefined;

  return {
    badgeColor,
    highlightedFields,
    showHeader,
    expanded,
    collapsable,
  };
};
