/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ConsolidatedChange, ConsolidatedCommunicationChange, ValidCommunicationChangeDetails} from "./ChangeComponent";

export function isObjectLifecycleChange(change: any): change is ConsolidatedChange {
  return change && (change.type === "DELETE" || change.type === "ARCHIVE" || change.type === "CREATE");
}

export function isProcessChange(change: any): change is ConsolidatedChange {
  return change && (change.eventType === "PROCESS_STARTED" ||  change.eventType === "PROCESS_ENDED" || change.eventType === "TASK_COMPLETED");
}

export function isCommunicationChange(change: any): change is ConsolidatedChange {
  return change && change.type === "COMMUNICATION";
}

export function isValidCommunicationChangeDetails(value: any): value is ValidCommunicationChangeDetails {
  return value && typeof value.channel === "string"
    && typeof value.status === "string"
    && typeof value.sender === "string"
    && typeof value.receiver === "string"
    && typeof value.type === "string";
}

export function isCommunicationChangeWithStatus(change: any): change is ConsolidatedCommunicationChange {
  return change && change.type === "COMMUNICATION" && change.communicationDetails && isValidCommunicationChangeDetails(change.communicationDetails);
}
