/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";
import {ReactElement, ReactNode, useCallback, useMemo} from "react";

import {useViewModel} from "../../ui";
import {CompositeSubViewProps} from "./CompositeSubView";

export type CompositeViewDataModelExtension = {
  /** Id of the sub tab to be selected when opening the view. */
  selectedTabId?: string,
  printRequested: boolean,
}

export const useCompositeView = (children?: ReactNode) => {
  const validChildren: React.ReactElement<CompositeSubViewProps>[] = useMemo(() => React.Children.toArray(children)
    .filter(isCompositeSubViewElement), [children]);
  const labelsAndIds = useMemo(() => validChildren.map((child) => ({
    id: child.props.id,
    label: child.props.label,
  })), [validChildren]);

  const {selectedTabId = labelsAndIds[0]?.id, setSelectedTabId} = useCompositeViewTab();

  return {
    validChildren,
    labelsAndIds,
    selectedTabId,
    setSelectedTabId,
  };
};

// hook to get and set the selected tab within a CompositeView
const useCompositeViewTab = () => {
  const {setViewModelData, viewModelData} = useViewModel<CompositeViewDataModelExtension>();
  const selectedTabId = viewModelData.selectedTabId;

  const setSelectedTabId = useCallback((newTabId: string) => setViewModelData(prevData => ({
    ...prevData,
    selectedTabId: newTabId,
  })), [setViewModelData]);

  return {
    selectedTabId,
    setSelectedTabId,
  };
};

function isCompositeSubViewElement(object: React.ReactNode): object is ReactElement<CompositeSubViewProps> {
  return React.isValidElement<unknown>(object)
    && typeof object.props.id === "string"
    && typeof object.props.label === "string"
    && object.props.children !== undefined;
}
