/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export const COMPLETE_PROCESS_TASK_ICON_BUTTON = "COMPLETE_PROCESS_TASK_ICON_BUTTON";
export const COMPLETE_PROCESS_TASK_MENU_ITEM = "COMPLETE_PROCESS_TASK_MENU_ITEM";
export const PROCESS_MODEL = "PROCESS_MODEL";
