/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState} from "../types";


/**
 * Action that is used to open the sidebar with the given id.
 *
 * @param state
 * @param action
 * @constructor
 */
export const OpenSideBar = (state: IUiState, action: PayloadAction<{sideBarId: string}>) => {
  const perspective = state.perspective;
  if (perspective?.perspectiveType === "TABBED") {
    perspective.sideBarId = action.payload.sideBarId;
  } else {
    console.warn("Can't open side bar in non-tabbed perspective");
  }
};
