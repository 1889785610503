/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";


const styles = (theme: IcmMuiTheme) => createStyles({
  actionName: {
    float: "left",
  },
  nextTaskNamesContainer: {
    float: "right",
  },
  nextTaskNameBox: {
    float: "right",
    scale: "0.75",
    transformOrigin: "center center",
    borderRadius: 5,
    paddingTop: 0,
    paddingBottom: 2,
    paddingRight: 8,
    fontSize: "1em",
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

export default styles;
