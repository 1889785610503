/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/** Name of the incident entity in rem products */
export const REM_INCIDENT_TYPE = "REM_WEB_VIEWER_INCIDENT";

/** Name of the operational consequences entity in rem products */
export const REM_OPERATIONAL_CONSEQUENCE = "REM_WEB_VIEWER_OPERATIONAL_CONSEQUENCE";

/** Name of the attachment entity in rem products */
export const REM_ATTACHMENT = "REM_WEB_VIEWER_ATTACHMENT";

/** Name of the communication status report entity in rem products */
export const REM_COMM_STATUS_USER_REPORT = "REM_WEB_VIEWER_COMM_STATUS_USER_REPORT";
