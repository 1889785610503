/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {LoginConfiguration, SecurityConfiguration} from "../../generated/api";
import {StorageService} from "../index";
import {LoginStorageService} from "./LoginStorageService";
import {TimeoutService} from "./TimeoutService";

function getAutoLogoutMinutes(loginConfiguration: LoginConfiguration): number {
  return loginConfiguration.minutesOfInactivityTriggeringAutoLogout || 0;
}

function getLoginConfiguration(configuration: SecurityConfiguration): LoginConfiguration {
  return (configuration.supportedAuthentications?.loginConfiguration) || new LoginConfiguration();
}

export const createTimeoutService = (securityConfiguration: SecurityConfiguration,
                                     loginStorage: LoginStorageService,
                                     storageService: StorageService): TimeoutService => {
  const loginConfiguration = getLoginConfiguration(securityConfiguration);
  const minutesOfInactivityTriggeringAutoLogout = getAutoLogoutMinutes(loginConfiguration);
  return new TimeoutService(minutesOfInactivityTriggeringAutoLogout * 60 * 1000, loginStorage, storageService, loginConfiguration.rememberMeStorage ?? "COOKIE");
};
