/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataUpdate} from "@icm/core-common";

import {Structure} from "../../generated/api";

export enum SketchActionTypes {
  FETCH_LIST_REQUEST = "@@sketches/FETCH_LIST_REQUEST",
  FETCH_LIST_SUCCESS = "@@sketches/FETCH_LIST_SUCCESS",
  FETCH_LIST_ERROR = "@@sketches/FETCH_LIST_ERROR",
  NOTIFY_RESET = "@@sketches/NOTIFY_RESET",
}

export interface ISketchListState {
  readonly loading: boolean
  readonly sketchList: Structure[]
  readonly error?: Error
  readonly updatedSketches: {[sketchId: string]: DataUpdate}
}
