/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Layer, LayerBase, LayerGroup} from "../generated/api";

/**
 * type guard for logical layer
 */
export function isLogicalLayer(layer: LayerBase): layer is Layer {
  return layer.type === "logicalLayer";
}

/**
 * type guard for logical layer groups
 */
export function isLogicalGroup(layer: LayerBase): layer is LayerGroup {
  return layer.type === "logicalGroup";
}
