/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  GetMessage,
  MessageKey,
  TabGroup,
  TabGroupTab,
  useAvailableViewDescriptors,
  useMessages,
  ViewDescriptorMap,
} from "@icm/core-common";
import {Close, Print, VerticalSplit} from "@mui/icons-material";
import {Paper, Tabs} from "@mui/material";
import {Box} from "@mui/system";
import {Share} from "mdi-material-ui";
import * as React from "react";
import {useRef} from "react";

import {IconButtonComponent} from "../form";
import {PaperTab} from "../tabs/PaperTab";
import {useBrowserPrinterInTabComponent} from "../util";
import styles from "./TabbedPaneStyles";
import {TabContentActionBar} from "./TabContentActionBar";
import {TabContentComponent} from "./TabContentComponent";
import {TabContentViewer} from "./TabContentViewer";
import {TabGroupMenu} from "./TabGroupMenu";
import {useActivateTabGroup} from "./useActivateTabGroup";
import {useCloseTabStrategy} from "./useCloseTabStrategy";
import {MAXIMIZED, MINIMIZED, useHeaderMode} from "./useHeaderMode";
import {useSelectTab} from "./useSelectTab";
import {useShareLinkGenerator} from "./useShareLinkGenerator";
import {useTabGroupSplitter} from "./useSplitTabGroup";
import {TabGroupDescriptor, useTabGroupDescriptor} from "./useTabGroupDescriptor";

export type TabGroupComponentProps = {
  tabGroup: TabGroup,
};

/**
 * A TabGroupComponent renders a "Tabbed-Pane" which hosts a number of tab to select from.
 *
 * @param props
 * @constructor
 */
export const TabGroupComponent = React.memo((props: TabGroupComponentProps) => {
  const {tabGroup} = props;
  const groupDescriptor = useTabGroupDescriptor(tabGroup.id);

  if (!groupDescriptor) {
    return <EmptyTabGroupComponent />;
  } else {
    return <DefaultTabGroupComponent groupDescriptor={groupDescriptor} />;
  }
});


const EmptyTabGroupComponent = () => {
  return (
    <Box sx={styles.tabGroupContainer} />
  );
};


type DefaultTabGroupComponentProps = {
  groupDescriptor: TabGroupDescriptor,
}

const DefaultTabGroupComponent = ({groupDescriptor}: DefaultTabGroupComponentProps) => {
  const tabRef = useRef<HTMLElement>();
  const parentRef = useRef<HTMLElement>();
  const {selectedTab} = groupDescriptor;

  const {getMessage} = useMessages();
  const availableViewDescriptors = useAvailableViewDescriptors();
  const selectTab = useSelectTab();
  const tabGroupSplitter = useTabGroupSplitter(groupDescriptor);
  const runCloseTabStrategy = useCloseTabStrategy(groupDescriptor);
  const shareLinkGenerator = useShareLinkGenerator(groupDescriptor.selectedTab);
  const printer = useBrowserPrinterInTabComponent(groupDescriptor);
  const activateTabGroup = useActivateTabGroup(groupDescriptor.id);

  const [headerMode, maxWidth] = useHeaderMode(groupDescriptor, tabRef);
  const headerStyle = groupDescriptor.active ? styles.activeTabGroupContainerHeader : styles.tabGroupContainerHeader;

  const tabs = groupDescriptor.tabs;
  const showMaximized = headerMode === MAXIMIZED;
  const showMinimized = headerMode === MINIMIZED;
  const tabStyle = {...styles.tabGroupContainerTabs, maxWidth};

  return (
    <Box sx={styles.tabGroupContainer} id={groupDescriptor.id} ref={parentRef}>
      <Box onClick={activateTabGroup} sx={headerStyle} className="dontPrint">
        <Box ref={tabRef}>
          {/* Triggers a false error, see https://github.com/mui-org/material-ui/issues/29209 */}
          <Tabs value={selectedTab.id}
                onChange={(_event, selectedTabId) => selectTab(selectedTabId)}
                scrollButtons={true}
                sx={tabStyle}
          >
            {tabs.map((tab: TabGroupTab) => tabTitle(tab, availableViewDescriptors, getMessage))}
          </Tabs>
        </Box>
        <Box sx={styles.containerActions}>
          {showMinimized && <TabGroupMenu groupDescriptor={groupDescriptor} />}
          {showMaximized && (
            [
              <Box key="contentActions" sx={styles.contentActions}>
                <TabContentActionBar tab={groupDescriptor.selectedTab} />
              </Box>,
              printer && (
                <IconButtonComponent key="print"
                                     handleClick={() => {
                                       printer.print();
                                     }}
                                     icon={<Print />}
                                     disabled={false}
                                     tooltip={getMessage(MessageKey.CORE.PRINT)}
                />
              ),
              <IconButtonComponent key="shareLink"
                                   handleClick={shareLinkGenerator.generate}
                                   icon={<Share />}
                                   disabled={!shareLinkGenerator.enabled}
                                   tooltip={getMessage(MessageKey.CORE.TAB_GROUP.SHARE_LINK)}
              />,
              <IconButtonComponent key="splitView"
                                   handleClick={tabGroupSplitter.split}
                                   icon={<VerticalSplit />}
                                   disabled={!tabGroupSplitter.enabled}
                                   tooltip={getMessage(MessageKey.CORE.TAB_GROUP.SPLIT_VIEW)}
              />,

            ]
          )}
          <IconButtonComponent handleClick={runCloseTabStrategy}
                               icon={<Close />}
                               tooltip={getMessage(MessageKey.CORE.TAB_GROUP.CLOSE_TAB)}
          />


        </Box>
      </Box>
      <Paper square variant="outlined" sx={styles.tabGroupContainerBody} className="printNoBorders">
        {tabs.map((tabInstance: TabGroupTab) => {
          return (
            <TabContentComponent key={tabInstance.id}
                                 id={tabInstance.id}
                                 selected={selectedTab?.id === tabInstance.id}
            >
              <TabContentViewer tabGroupId={groupDescriptor.id}
                                tab={tabInstance}
                                selected={selectedTab?.id === tabInstance.id}
              />
            </TabContentComponent>
          );
        })}
      </Paper>
    </Box>
  );
};

function tabTitle(
  tab: TabGroupTab,
  viewDescriptors: ViewDescriptorMap,
  getMessage: GetMessage
) {
  const {viewType} = tab.viewModel;
  const viewDescriptor = viewDescriptors[viewType];
  if (!viewDescriptor) {
    throw Error(`Invalid view type ${viewType}`);
  }
  const title = viewDescriptor.getTitle(tab.viewModel, getMessage);
  return <PaperTab key={tab.id} label={title} value={tab.id} />;
}
