/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React from "react";

import {getNamedComponentFactory, registerNamedComponentFactory} from "../ui";

export type Widget<T extends object> = React.FC<T> | React.ComponentClass<T>;

export const registerWidgetFactory = (name: string, widgetFactory: Widget<any>) => {
  registerNamedComponentFactory(`${name}_WIDGET`, widgetFactory);
};

export const getWidgetFactory = (name: string): Widget<any> | undefined => {
  return getNamedComponentFactory(`${name}_WIDGET`);
};
