/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {intersection} from "lodash-es";
import React, {useCallback, useContext, useMemo, useRef} from "react";

import {CoreApi} from "../../api";
import {
  ParameterUtilities,
  ResolvedParameter,
} from "../../util";

export type ActionHandlerOptions = {
  // if given, these parameters will be used
  resolvedParameters: ResolvedParameter[]
  context?: never
} | {
  // if given, the context will be used to resolve the action's parameters
  context: unknown
  resolvedParameters?: never
};

/**
 * Handler that is called when invoking an action defined in ICM schema.
 */
export type ActionHandler = (action: CoreApi.Action, options?: ActionHandlerOptions) => void;

export type ActionRunner = (resolvedParameters: ResolvedParameter[]) => void

export type ActionRunnerFactoryHook = () => ActionRunner;

export type ActionRunners = {
  [actionType in CoreApi.ActionType]?: ActionRunner;
};


export type ActionHandlerFactoryHooks = {
  [actionType in CoreApi.ActionType]?: ActionRunnerFactoryHook
}


export const ActionRegistryContext = React.createContext<ActionHandlerFactoryHooks>({});

type ActionProviderProps = {
  actionHandlers: ActionHandlerFactoryHooks[]
}

export const ActionProvider = (props: React.PropsWithChildren<ActionProviderProps>) => {
  const actionHandlers = useMemo(
    () => props.actionHandlers.reduce((allHandlers: ActionHandlerFactoryHooks, nextHandlers) => {
      if (process.env.NODE_ENV === "development") {
        const duplicateActionHandlers = intersection(Object.keys(allHandlers), Object.keys(nextHandlers));
        if (duplicateActionHandlers.length > 0) {
          console.error("duplicate registration of action handler for type", duplicateActionHandlers);
        }
      }
      return {...allHandlers, ...nextHandlers};
    }, {}), [props.actionHandlers]
  );

  return (
    <ActionRegistryContext.Provider value={actionHandlers}>
      {props.children}
    </ActionRegistryContext.Provider>
  );
};

/**
 * Get all parameters of the action.
 *
 * @param action
 * @param options
 */
export function getResolvedParameters(action: CoreApi.Action, options?: ActionHandlerOptions): ResolvedParameter[] {
  if (options) {
    if (options.resolvedParameters) {
      return options.resolvedParameters;
    } else if (options.context) {
      return createResolvedActionParameters(action, options.context);
    } else {
      return createResolvedActionParameters(action, options);
    }
  } else {
    return createResolvedActionParameters(action);
  }
}

function createResolvedActionParameters(action: CoreApi.Action, ctx?: unknown): ResolvedParameter[] {
  let result: ResolvedParameter[] = [];
  const genericParameters = ParameterUtilities.resolveParameters(action.parameters, ctx);
  if (genericParameters) {
    result = result.concat(genericParameters);
  }
  const objectParameters = ParameterUtilities.resolveObjectParameters(action.objectParameters, ctx);
  if (objectParameters) {
    result = result.concat(objectParameters);
  }
  return result;
}


/**
 * @param additionalActionRunners additional ActionRunner. Will override default action runners if they have the same keys.
 */
export function useActionHandler(additionalActionRunners?: ActionRunners): ActionHandler {
  const defaultActionRunnerFactoryHooks = useContext(ActionRegistryContext);

  const actionRunners: React.MutableRefObject<ActionRunners> = useRef({});
  // call all the factory hooks
  for (const key of Object.keys(defaultActionRunnerFactoryHooks)) {
    actionRunners.current[key] = defaultActionRunnerFactoryHooks[key]();
  }

  if (additionalActionRunners) {
    for (const key of Object.keys(additionalActionRunners)) {
      actionRunners.current[key] = additionalActionRunners[key];
    }
  }

  /**
   * @param context the context to resolve the action parameters
   */
  return useCallback((action: CoreApi.Action, options: ActionHandlerOptions) => {
    const actionType = action.action;

    if (actionType && actionRunners.current[actionType]) {
      const resolvedParams = getResolvedParameters(action, options);
      actionRunners.current[actionType]!(resolvedParams);
    } else {
      console.warn(`Action handling for ${action.action} not implemented`, action);
      throw Error(`Cannot handle action having actionType:'${action.action}'.`);
    }
  }, [actionRunners]);
}
