/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CommonButtonProps, getNamedComponentFactory, ICON} from "@icm/core-common";
import {Button, ButtonProps, Grid} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import * as React from "react";

import {IcmMuiTheme} from "../../../theme";

export type IButtonProps = CommonButtonProps & {
  type?: ButtonProps["type"],
  variant?: ButtonProps["variant"],
  color?: ButtonProps["color"],
};

const styles = (theme: IcmMuiTheme) => createStyles({
  rightIcon: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
  },
});

const useStyles = makeStyles(styles);

export const ButtonComponent = ({disabled, variant, color, type, onClick, label, icon, iconLeft, style}: IButtonProps) => {
  const classes = useStyles();
  const Icon = getNamedComponentFactory(ICON);
  const resolvedIcon = typeof icon === "string" && Icon ? <Icon name={icon} /> : icon;
  return (
    <Button disabled={disabled}
            variant={variant || "contained"}
            color={color || "primary"}
            type={type}
            onClick={onClick}
            style={style}
            aria-label={label}
    >
      {resolvedIcon && iconLeft && (
        <Grid container alignItems="center" className={classes.leftIcon}>{resolvedIcon}</Grid>
      )}
      {label}
      {resolvedIcon && !iconLeft && (
        <Grid container alignItems="center" className={classes.rightIcon}>{resolvedIcon}</Grid>
      )}
    </Button>
  );
};
