/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {css} from "@emotion/css";

import {IcmTheme} from "../theme";

export const styles = (theme: IcmTheme) => {
  return {
    container: css({
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: theme.palette.background.main,
    }),
    objectContainer: css({
      flexBasis: "50%",
      width: "50%",
      textAlign: "center",
      marginTop: "0.8rem",
      marginBottom: "0.8rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5em",
    }),
    object: css({
      height: "4rem",
      fontSize: "4rem",
    }),
  };
};
