/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {StorageKey} from "../../constant";
import {StorageStrategy} from "../../data";
import {LoginConfiguration, LoginRequest, LoginStatus, Role, SecurityConfiguration} from "../../generated/api";
import {StorageService} from "../StorageService";

class LoginStorageService {
  private readonly configuration?: SecurityConfiguration;
  private readonly storage: StorageService;
  private readonly storageStrategy: StorageStrategy;

  constructor(configuration: SecurityConfiguration, storage: StorageService) {
    this.configuration = configuration;
    this.storage = storage;
    this.storageStrategy = this.createStorageStrategy();
  }

  public clearStorage(onlyRoles: boolean = false): void {
    // try catch is needed for the mobile hot module replacement to work properly
    try {
      this.storage.deleteData(StorageKey.LAST_ACTIVITY_TIMESTAMP);
      this.storage.deleteData(this.getStorageKey(StorageKey.ACTIVE_ROLES));
      if (onlyRoles) {
        console.log("Active roles have been cleared");
      } else {
        this.storage.deleteData(this.getStorageKey(StorageKey.CURRENT_LOGIN));
        this.storage.deleteData(this.getStorageKey(StorageKey.CURRENT_USER));
        console.log("Login, user & role has been cleared");
      }
    } catch (e) {
      console.error("Error while trying to clear login storage", e);
    }
  }

  public readActiveRoles(): Promise<Role[] | null> {
    if (this.configuration) {
      return this.storage.loadData<Role[]>(this.getStorageKey(StorageKey.ACTIVE_ROLES));
    }
    return Promise.resolve(null);
  }

  public readCurrentLogin(): Promise<LoginRequest | null> {
    if (this.configuration) {
      return this.storage.loadData<LoginRequest>(this.getStorageKey(StorageKey.CURRENT_LOGIN));
    }
    return Promise.resolve(null);
  }

  public readCurrentUser(): Promise<LoginStatus | null> {
    if (this.configuration) {
      return this.storage.loadData<LoginStatus>(this.getStorageKey(StorageKey.CURRENT_USER));
    }
    return Promise.resolve(null);
  }

  public writeActiveRoles(roles: Role[] | null): void {
    console.debug("Active roles have been written", roles);
    this.storage.saveData(this.getStorageKey(StorageKey.ACTIVE_ROLES), roles, this.storageStrategy);
  }

  public writeCurrentLogin(login: LoginRequest | null): void {
    console.debug("Current login has been written", login);
    this.storage.saveData(this.getStorageKey(StorageKey.CURRENT_LOGIN), login, this.storageStrategy);
  }

  public writeCurrentUser(user: LoginStatus | null): void {
    console.debug("Current user has been written", user);
    this.storage.saveData(this.getStorageKey(StorageKey.CURRENT_USER), user, this.storageStrategy);
  }

  public writeNotificationStatusOnce(): Promise<boolean> {
    const key = this.getStorageKey(StorageKey.LOGIN_NOTIFICATION_STATUS);
    const st = this.storage;
    if (st) {
      return st.loadData(key).then((existingData: any) => {
        if (!existingData) {
          console.log("Login notification status written");
          st.saveData(key, "SENT", new StorageStrategy("SESSION"));
          return true;
        } else {
          return false;
        }
      });
    }
    return Promise.resolve(false);
  }

  private createStorageStrategy(): StorageStrategy {
    const storageType = this.getLoginConfiguration().rememberMeStorage;
    return new StorageStrategy(storageType ?? "COOKIE");
  }

  private getLoginConfiguration(): LoginConfiguration {
    if (!this.configuration) {
      throw Error("Security configuration not yet available in LoginStorageService");
    } else {
      return this.configuration.supportedAuthentications?.loginConfiguration || new LoginConfiguration();
    }
  }

  private getStorageKey(key: string): string {
    let prefix = "";
    if (this.getLoginConfiguration().rememberMeStoragePrefix) {
      prefix = this.getLoginConfiguration().rememberMeStoragePrefix + "_";
    }
    return prefix + key;
  }
}

export {LoginStorageService};
