/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ContextMenu, ContextMenuActionItem, DataTableCellProps, isContextMenuActionItem, isMenuDividerItem} from "@icm/core-common";
import {MoreVertRounded} from "@mui/icons-material";
import {Divider, ListItemIcon, Menu, MenuItem, TableCell} from "@mui/material";
import {isString} from "lodash-es";
import * as React from "react";
import {useCallback, useMemo} from "react";

import {IconButtonComponent} from "../../../form";
import {WebSvgIcon} from "../../../util";
import {useDataTableContext} from "./DataTableContext";
import {useDataTableStyles} from "./DataTableStyle";
import {getComponent} from "./DataTableUtil";

type DataTableContextMenuActionCellProps<T extends object> = DataTableCellProps<T> & {
  contextMenuDefinition: ContextMenu
}
export const DataTableContextMenuActionCell = <T extends object>({rowValue, contextMenuDefinition}: DataTableContextMenuActionCellProps<T>) => {
  const classes = useDataTableStyles();
  const {variant} = useDataTableContext();

  const [anchorEl, setAnchorEl] = React.useState<undefined | null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = useCallback((item: ContextMenuActionItem) => {
    item.onClick(rowValue);
    closeMenu();
  }, [rowValue]);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const items = useMemo(() => {
    return contextMenuDefinition.items.map((item, index) => {
      const id = `_${index}`;
      if (isContextMenuActionItem(item)) {
        return (
          <MenuItem key={id} id={id} onClick={_ => handleItemClick(item)}>
            {item.icon && (
            <ListItemIcon>
              <WebSvgIcon name={item.icon} size="inherit" />
            </ListItemIcon>
            )}
            {item.label}
          </MenuItem>
        );
      } else if (isMenuDividerItem(item)) {
        return <Divider key={id} id={id} />;
      } else {
        return <></>;
      }
    });
  }, [contextMenuDefinition, handleItemClick]);


  function stopPropagation(ev: React.MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    if (open) {
      closeMenu();
    }
  }

  const rowId = rowValue["id"];
  if (isString(rowId)) {
    return (
      <TableCell component={getComponent(variant)}
                 className={classes.actionsColumn}
                 onClick={(ev) => stopPropagation(ev)}

      >

        <IconButtonComponent tooltip={contextMenuDefinition.label}
                             tooltipPlacement="top"
                             icon={contextMenuDefinition.icon ?? <MoreVertRounded />}
                             fontSize="small"
                             handleClick={handleClick}
                             padding="small"
        />

        <Menu open={open}
              anchorEl={anchorEl}
        >
          {items}
        </Menu>


      </TableCell>
    );
  } else {
    return (
      <TableCell component={getComponent(variant)}
                 className={classes.actionsColumn}
                 onClick={(ev) => stopPropagation(ev)}
      />
    );
  }
};
