/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isObjectLike} from "lodash-es";

import {Action} from "../../generated/api";
import {
  ExpressionEvaluationService,
  SecurityService,
} from "../../service";
import {ActionHandler} from "../../ui";

export class CommonQuickActionHelper {
  private readonly securityService: SecurityService;
  private readonly quickAction: Action;
  private readonly viewParams: Record<string, any>;
  private readonly contextEntity?: unknown;
  private readonly visibilityContext?: any;

  actionHandler: ActionHandler;
  isVisible: boolean;
  label: string;
  isValid: boolean;

  constructor(securityService: SecurityService,
              quickAction: Action,
              viewParams: Record<string, any>,
              actionHandler: ActionHandler,
              contextEntity?: unknown,
              visibilityContext?: any) {
    this.securityService = securityService;
    this.quickAction = quickAction;
    this.viewParams = viewParams;
    this.contextEntity = contextEntity;
    this.visibilityContext = visibilityContext;

    this.actionHandler = actionHandler;
    this.isVisible = this.checkQuickActionVisibility(quickAction, contextEntity, visibilityContext);
    this.label = this.getQuickActionLabel(quickAction, viewParams, contextEntity);
    this.isValid = !!this.actionHandler && this.isVisible;
  }

  public handleQuickAction = () => {
    this.actionHandler(this.quickAction, {context: this.contextEntity});
  };

  private readonly checkQuickActionVisibility = (
    quickAction: Action,
    contextEntity?: unknown,
    visibilityContext?: any,
  ) => {
    return quickAction.visible === undefined
      ? true // default
      : ExpressionEvaluationService.evaluate(quickAction.visible, this.getQuickActionExpressionContext(contextEntity, visibilityContext));
  };

  private readonly getQuickActionExpressionContext = (
    contextEntity?: unknown,
    visibilityContext?: any,
  ) => {
    const contextEntityAsObject = () => {
      if (isObjectLike(contextEntity)) {
        return (contextEntity as Object);
      }
      return {};
    };
    return {
      ...this.securityService.getVisibilityExpressionContext(),
      ...visibilityContext,
      ...contextEntityAsObject(),
    };
  };

  private readonly getQuickActionLabel = (
    quickAction: Action,
    viewParams: Record<string, any>,
    contextEntity?: unknown,
  ) => {
    return quickAction.label && ExpressionEvaluationService.evaluate(quickAction.label, contextEntity, viewParams);
  };
}
