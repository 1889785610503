/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {StorageKey} from "../../constant";
import {StorageStrategy} from "../../data";
import {BrowserStorageType} from "../../generated/api";
import {StorageService} from "../StorageService";
import {LoginStorageService} from "./LoginStorageService";

export type TimeoutListener = () => void;

const WATCHDOG_INTERVAL = 3000;

/**
 * A timeout service - this service is responsible for automatically notifying listeners of user inactivity.
 * It only runs while a user is logged in.
 *
 */
class TimeoutService {

  private readonly loginStorage: LoginStorageService;

  private readonly millisecondsBeforeTimeout: number;

  private readonly storage: StorageService;

  private storageStrategy: StorageStrategy;

  private readonly storageType: BrowserStorageType;

  private timeoutListeners: Array<TimeoutListener>;

  private timerId?: number;

  /**
   * The constructor of the service
   *
   * @param millisecondsBeforeTimeout the timeframe after which the timeout listeners should run in milliseconds
   * @param loginStorage the global login storage service
   * @param storage the global storage service
   * @param storageType the storage type define in which storage system internal data should be persisted
   */
  constructor(
    millisecondsBeforeTimeout: number,
    loginStorage: LoginStorageService,
    storage: StorageService,
    storageType: BrowserStorageType
  ) {
    this.loginStorage = loginStorage;
    this.millisecondsBeforeTimeout = millisecondsBeforeTimeout;
    this.storage = storage;
    this.storageType = storageType;
    this.timeoutListeners = [];
  }

  /**
   * Add a timeout listener
   *
   * @param timeoutListener the timeout listener to add
   */
  addTimeoutListener(timeoutListener: TimeoutListener): void {
    this.timeoutListeners.push(timeoutListener);
  }

  /**
   * Initialized the timeout service and runs a first timeout check
   */
  init() {
    this.storageStrategy = this.createStorageStrategy();
    this.registerToDocumentClickEvents();
    this.readLastActivityTimestamp().then(lastActivityTimestamp => {
      if (lastActivityTimestamp) {
        this.checkTimeout();
      }
    });
  }

  /**
   * Remove a timeout listener
   *
   * @param timeoutListener the timeout listener to remove
   */
  removeTimeoutListener(timeoutListener: TimeoutListener): void {
    this.timeoutListeners = this.timeoutListeners.filter(listener => listener !== timeoutListener);
  }

  /**
   * Signal user activity to the timeout service (resets the timeout)
   */
  signalUserActivity(): void {
    this.writeLastActivityTimestamp(Date.now());
  }

  /**
   * After calling startWatchdog(), the timeout service will periodically check for a timeout.
   * It will only start, if the user is not using single-sign-on (SSO).
   */
  async startWatchdog(): Promise<void> {
    this.stopWatchdog();
    const currentStatus = await this.loginStorage.readCurrentUser();
    const isSsoUser: boolean = (currentStatus && currentStatus.passwordState === "SSO") || false;
    if (this.millisecondsBeforeTimeout > 0 && !isSsoUser) {
      this.writeLastActivityTimestamp(Date.now());
      this.timerId = window.setInterval(this.checkTimeout.bind(this), WATCHDOG_INTERVAL);
    }
  }

  /**
   * Stops the previously started timeout service for periodic timeout checks.
   */
  stopWatchdog(): void {
    if (this.timerId) {
      window.clearInterval(this.timerId);
    }
  }

  private checkTimeout(): void {
    this.readLastActivityTimestamp().then(lastActivityTimestamp => {
      if (!lastActivityTimestamp) { // check this in order to be sure to handle automatic timeout is performed if timestamp was cleared
        this.runTimeoutListeners(false);
      } else if (this.millisecondsBeforeTimeout > 0) {
        const inactivityDuration = Date.now() - lastActivityTimestamp;
        const inactive = inactivityDuration > this.millisecondsBeforeTimeout;
        this.isLoggedIn().then(loggedIn => {
          // check this in order to be sure to handle manual timeout listeners are run on other tabs
          if (inactive || !loggedIn) {
            this.runTimeoutListeners(inactive);
          }
        });
      }
    });
  }

  private createStorageStrategy(): StorageStrategy {
    return new StorageStrategy(this.storageType ?? "COOKIE");
  }

  private async isLoggedIn(): Promise<boolean> {
    return !!(await this.loginStorage.readCurrentUser());
  }

  private readLastActivityTimestamp(): Promise<number | null> {
    return this.storage
      .loadData<number>(StorageKey.LAST_ACTIVITY_TIMESTAMP)
      .catch(e => {
        console.error("Could not read Last activity timestamp from Storage service.", e);
        return null;
      });
  }

  private registerToDocumentClickEvents() {
    document.addEventListener("click",  () => {
      this.signalUserActivity();
    }, true);
  }

  private runTimeoutListeners(timeout: boolean): void {
    console.log("Running timeout listeners due to " + (timeout ? "timeout" : "cleared timeout timestamp storage"));
    this.timeoutListeners.forEach(listener => listener());
  }

  private writeLastActivityTimestamp(timestamp: number): void {
    this.storage.saveData(StorageKey.LAST_ACTIVITY_TIMESTAMP, timestamp, this.storageStrategy);
  }

}

export {TimeoutService};
