/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CHECKBOX,
  COMPOSITE_VIEW,
  DATE_PICKER,
  DATETIME_PICKER,
  FetchService,
  FILTER,
  FORM_DIALOG,
  FORM_GENERATOR_LAYOUT,
  IFRAME,
  LIST,
  LOADER,
  MEDIA_STREAM_PLAYER,
  ModuleRegistrationOptions,
  queryClient,
  RADIOBUTTON,
  registerNamedComponentFactory,
  SELECT_MULTI,
  SELECT_SINGLE,
  TEXT,
  TIME_PICKER,
} from "@icm/core-common";
import {Typography} from "@mui/material";
import FileSaver from "file-saver";

import {
  CheckboxComponent,
  DateComponent,
  DateTimeComponent,
  ExpandableTextDetailPanelRendererFactory,
  FormDialogComponent,
  iconDemoViewDescriptor,
  iFrameViewDescriptor,
  KeyValueDetailPanelRendererFactory,
  ListComponent,
  LoadingSpinner,
  mediaStreamPlayerComponentViewDescriptor,
  MediaStreamPlayerFormComponent,
  mobileConfigurationViewDescriptor,
  registerDetailPanelRendererFactory,
  SingleSelectRadioComponent,
  TimeComponent,
  WebAutocompleteComponent,
  WebCompositeView,
  webFilterComponentFactory,
  WebPlatformService,
} from "./src";
import {IFrameWebComponent} from "./src/components/form/iframe/IFrameWebComponent";
import {WebFormGeneratorLayout} from "./src/components/form/layout/WebFormGeneratorLayout";
import packageInfo from "./src/generated/package.clone.json";
import {themePreviewViewDescriptor} from "./src/pages/theme/ThemePreviewViewDescriptor";
import {settingsViewDescriptor} from "./src/pages/uisettings";
import {systemMetricsViewDescriptor} from "./src/views/systemMetrics";

export * from "./src";

export const coreWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  registerServices: (serviceRegistry) => {
    console.log("Registering Core Web Modules");
    serviceRegistry.register("PLATFORM", new WebPlatformService());
    FetchService.setOpenBlobHandler((blob, fileName) => FileSaver.saveAs(blob, fileName));
  },
  runPostInitialization: (serviceRegistry) => {
    registerNamedComponentFactory(FILTER, webFilterComponentFactory);
    registerNamedComponentFactory(FORM_DIALOG, FormDialogComponent);
    registerNamedComponentFactory(CHECKBOX, CheckboxComponent);
    registerNamedComponentFactory(DATETIME_PICKER, DateTimeComponent);
    registerNamedComponentFactory(DATE_PICKER, DateComponent);
    registerNamedComponentFactory(IFRAME, IFrameWebComponent);
    registerNamedComponentFactory(LIST, ListComponent);
    registerNamedComponentFactory(MEDIA_STREAM_PLAYER, MediaStreamPlayerFormComponent);
    registerNamedComponentFactory(RADIOBUTTON, SingleSelectRadioComponent);
    registerNamedComponentFactory(SELECT_MULTI, WebAutocompleteComponent);
    registerNamedComponentFactory(SELECT_SINGLE, WebAutocompleteComponent);
    registerNamedComponentFactory(TIME_PICKER, TimeComponent);

    registerNamedComponentFactory(COMPOSITE_VIEW, WebCompositeView);
    registerNamedComponentFactory(FORM_GENERATOR_LAYOUT, WebFormGeneratorLayout);
    registerNamedComponentFactory(LOADER, LoadingSpinner);
    registerNamedComponentFactory(TEXT, Typography);

    registerDetailPanelRendererFactory("EXPANDABLE", ExpandableTextDetailPanelRendererFactory);
    registerDetailPanelRendererFactory("KEY_VALUE", KeyValueDetailPanelRendererFactory);
    const securityService = serviceRegistry.get("SECURITY");
    securityService.addLoginHandler(async () => {
      return () => queryClient.cancelMutations()
        .then(_ => queryClient.removeQueries("private", {exact: false}))
        .catch(_ => queryClient.clear());
    });
  },
  providedViews: [
    themePreviewViewDescriptor,
    mobileConfigurationViewDescriptor,
    mediaStreamPlayerComponentViewDescriptor,
    systemMetricsViewDescriptor,
    iFrameViewDescriptor,
    iconDemoViewDescriptor,
    settingsViewDescriptor,
  ],
};
