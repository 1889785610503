/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {TableCell, TableRow} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import React, {PropsWithChildren} from "react";

import {IcmMuiTheme} from "../../../../theme";
import {DataTableRowHeader} from "./DataTableRowHeader";
import {useDataTableContext} from "./index";

const useStyles = makeStyles((_theme: IcmMuiTheme) => createStyles({
  flexSingleCellRow: {
    display: "flex",
    flex: "1 0 auto",
  },
}));

export type SingleCellRowProps = PropsWithChildren<{ }>;

export const SingleCellRow = React.memo((props: SingleCellRowProps) => {
  const {variant, tableConfig} = useDataTableContext();
  const classes = useStyles();
  if (variant === "FLEX") {
    return (
      <TableRow {...props} component="div" className={classes.flexSingleCellRow}>
        <TableCell component={"div" as any}
                   className={classes.flexSingleCellRow}
        >
          {props.children}
        </TableCell>
      </TableRow>
    );
  } else {
    const hasActions = tableConfig.rowActions && tableConfig.rowActions.length > 0;
    return (
      <TableRow {...props}>
        <DataTableRowHeader />
        <TableCell align="center" colSpan={tableConfig.dataColumns.length + (hasActions ? 1 : 0)}>
          {props.children}
        </TableCell>
      </TableRow>
    );
  }
});
