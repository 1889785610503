/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {Message} from "./Message";

/**
 * An object which provides default contents for messages.
 */
export interface MessageTemplateProvider {
  /**
   * Get the subject of available.
   */
  getTemplate(): Readonly<Message>

}

/**
 * Returns a default template whose subject and content is emtpy.
 */
export const EmptyMessageTemplateProvider: MessageTemplateProvider = {
  getTemplate(): Readonly<Message> {
    return {
      subject: "",
      content: "",
    };
  },

};
