/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useMemo} from "react";
import {useQuery, useQueryClient} from "react-query";

import {UiConfigurationApi} from "../../api";
import {FilterConfiguration, FilterOption} from "../../generated/api";
import {BaseFilterData} from "./FilterData";

const BASE_CACHE_KEY = ["private", "core", "configuration", "filter"];

const WITH_EVENT_FILTER = "with-event-filter-options";

const WITHOUT_EVENT_FILTER = "without-event-filter-options";

/**
 * Type used to configure the parameters used in the filter cache key.
 *
 * - ON: include the WITH_EVENT_FILTER
 * - OFF: include the WITHOUT_EVENT_FILTER
 * - NONE: use the key that represents the mutual parent without any event filter option string.
 */
type CacheKeyEventFilterOptions = "ON" | "OFF" | "NONE";

export function useFilterConfigurationsCacheKey(): string[] {
  return BASE_CACHE_KEY.concat("all");
}

export function useFilterConfigurationCacheKey(filterConfigurationId: string | undefined, showEventFilterOptions: CacheKeyEventFilterOptions): string[] {
  const fc = useFilterConfiguration(filterConfigurationId);

  return useMemo(() => {
      const result = [...BASE_CACHE_KEY];

      if (fc?.filterConfiguration?.entityType) {
        result.push(fc.filterConfiguration.entityType);
      } else {
        result.push("unknown-entity-type");
      }

      // variant
      if (filterConfigurationId) {
        result.push(filterConfigurationId);
      } else {
        result.push("unknown_filter_variant");
      }

      switch (showEventFilterOptions) {
        case "ON":
          result.push(WITH_EVENT_FILTER);
          break;
        case "OFF":
          result.push(WITHOUT_EVENT_FILTER);
          break;
        case "NONE":
          break;
        default:
          break;
      }

      return result;
    },
    [filterConfigurationId, fc, showEventFilterOptions]);
}

export const useFilterConfigurations = (): FilterConfiguration[] | undefined => {
  const cacheKey = useFilterConfigurationsCacheKey();
  const {data: filterConfigurations} = useQuery(cacheKey, async () => {
    return UiConfigurationApi.getFilterConfigurations();
  });
  return filterConfigurations;
};

export const useFilterConfiguration = (filterConfigurationId?: string) => {
  const filterConfigurations = useFilterConfigurations();
  const {
    filterConfiguration,
    options,
  } = useMemo(() => {
    const fc = filterConfigurations?.find((config) => config.id === filterConfigurationId);
    const opts = fc ? mapJsonOptions(fc) : undefined;
    return {
      filterConfiguration: fc,
      options: opts,
    };
  }, [filterConfigurations, filterConfigurationId]);
  return {
    filterConfiguration,
    options,
  };
};

function mapJsonOptions(fc: FilterConfiguration): BaseFilterData["filterOptions"] | undefined {
  let opts = undefined;
  if (fc.filterOptions) {
    opts = [];
    for (const fo of fc.filterOptions) {
      try {
        opts.push(JSON.parse(fo) as FilterOption);
      } catch (e) {
        console.error("Invalid JSON as filter option: ", fo, e);
      }
    }
  }
  return opts;
}


/**
 * invalidate all cached filter configurations
 */
export function useInvalidateFilterConfiguration(filterConfigurationId: string | undefined): () => Promise<boolean> {
  const qc = useQueryClient();
  const filterDataParentCacheKey = useFilterConfigurationCacheKey(filterConfigurationId, "NONE");

  return useCallback(async () => {
    await qc.invalidateQueries(filterDataParentCacheKey, {exact: false});
    return true;
  }, [qc, filterDataParentCacheKey]);
}
