/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

type Module =
  | "core"
  | "chat"
  | "entity"
  | "communication"
  | "auditlog"
  | "activitystream"
  | "incident"
  | "map"
  | "rail"
  | "standby"
  | "topology"
  | "track"
  | "workflow";

export class IcmError extends Error {
  // cause of error (if no response)
  public cause?: Error;

  constructor(module: Module, message: string, cause?: Error | unknown) {
    super(`${module.toUpperCase()}: ${message}`);
    if (cause instanceof Error) {
      this.cause = cause;
    }
  }
}
