/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  AbstractFormConfiguration,
  ExpressionEvaluationService,
  GenericFormConfigurationAndVariantName,
  ICoreApplicationState,
  NormalizedData,
  queryClient,
  StoreService,
  useMessages,
} from "@icm/core-common";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {useDispatch, useSelector, useStore} from "react-redux";

import {entityEditViewDispatcher} from "../components/EntityEditViewDispatcher";
import {Entity, EntityConfiguration, EntityPropertyValue} from "../generated/api";
import {entityDataService, EntityEditService, entityReferenceValueService, entityUpdateService} from "../service";
import {EntityEditModel, ReloadEntityEditModel} from "../store";
import {convertToGenericFormConfiguration} from "../ui";

const DEFAULT_VARIANT = "";

const formConfigurationKey = (entityType: string, formVariant = DEFAULT_VARIANT, entityId?: string): string[] => {
  if (entityId) {
    return ["private", "entity", "formConfiguration", entityType, formVariant, entityId];
  }
  return ["private", "entity", "formConfiguration", entityType, formVariant];
};

/**
 * Invalidate the cached result.
 *
 * @param entityType
 * @param formVariant
 * @param entityId?
 */
export const invalidateFormConfiguration = (entityType: string, formVariant = DEFAULT_VARIANT, entityId?: string,): Promise<void> => {
  const key = formConfigurationKey(entityType, formVariant, entityId);
  return queryClient.invalidateQueries(key);
};

/**
 * Fetch the form configuration for the given entity type.
 * Call is backed by query client.
 *
 * @param entityType The type of the entity
 * @param formVariant The formVariant
 * @param entityId The id of the entity (in case the form configuration is dynamic, e.g. in REM clients)
 */
export const useFormConfiguration = (entityType: string, formVariant = DEFAULT_VARIANT, entityId?: string): NormalizedData<AbstractFormConfiguration> => {
  const key = formConfigurationKey(entityType, formVariant, entityId);
  const cacheTime = !!formVariant && formVariant.length > 0 ? Infinity : 0; // do not cache calls to dynamic default form variant, as this may change
  return useQuery<AbstractFormConfiguration>(key, {
    queryFn: async () => {
      return entityDataService.getFormConfiguration({entityType, entityId, formVariant});
    },
    cacheTime: cacheTime,
    staleTime: Infinity,
  });
};

export const useGenericFormConfiguration = (
  entity: Entity,
  formVariant = DEFAULT_VARIANT,
  entityId?: string,
  entityConfigurations?: Map<string, EntityConfiguration>
): NormalizedData<GenericFormConfigurationAndVariantName> => {
  const formConfiguration = useFormConfiguration(entity.type!, formVariant, entityId);
  return useMemo(() => {
    try {
      if (entity.type && formConfiguration.data && entityConfigurations?.has(entity.type)) {
        return {
          ...formConfiguration,
          data: convertToGenericFormConfiguration(entity.type, formConfiguration.data, entityConfigurations),
        };
      }
      return {
        ...formConfiguration,
        data: undefined,
      };
    } catch (e) {
      console.error("Error during generation of generic form configuration", e);
      return {
        ...formConfiguration,
        data: undefined,
        isError: true,
      };
    }
  }, [entity.type, formConfiguration, entityConfigurations]);
};

export const useFormEntity = (propertyChanges: EntityPropertyValue[], entity?: Entity, entityConfiguration?: EntityConfiguration) => {
  const formEntity = useMemo(() => {
    if (entity && entityConfiguration) {
      return entityReferenceValueService.getFormEntity(entity, entityConfiguration);
    }
    return undefined;
  }, [entity, entityConfiguration]);

  return useMemo(() => {
    if (entity && entityConfiguration && formEntity) {
      const formEntityPropertyChanges = entityReferenceValueService.getFormEntityPropertyChanges(propertyChanges, entityConfiguration, formEntity);
      // TODO: all changes are applied again, thus for single static selections the value is constructed/changed every time => component rendered again!
      return entityUpdateService.applyChanges(formEntity, formEntityPropertyChanges);
    }
    return undefined;
  }, [entity, formEntity, propertyChanges, entityConfiguration]);
};

export const useEntityEditViewDispatcher = (entity: Entity, entityConfigurations?: Map<string, EntityConfiguration>) => {
  return useMemo(() => entityEditViewDispatcher({
    entity,
    entityConfigurations,
  }),
  [entity, entityConfigurations]);
};

export const useEntityEditModelGetter = () => {
  const state = useStore<ICoreApplicationState>();
  return useCallback((entityId: string | undefined) => {
    return entityId ? state.getState().entityEditState.editModels[entityId] : undefined;
  }, [state]);
};

type UseEntityEditModelOptions = {
  entityType: string,
  initialEntityId?: string,
  initialEntityKey?: string,
  sourceEntity?: Entity
}

export const useEntityEditModel = ({entityType, initialEntityId, initialEntityKey, sourceEntity}: UseEntityEditModelOptions) => {
  const dispatch = useDispatch();
  const [entityId, setEntityId] = useState(initialEntityId);
  const model = useSelector((state: ICoreApplicationState) => entityId ? state.entityEditState.editModels[entityId] : undefined);
  const lastUpdatedAt = useLastActivityStreamUpdateTime(entityType, entityId);
  useEffect(() => {
    if (!model) {
      EntityEditService.initializeEntityEditModel(entityType, initialEntityKey ?? entityId, {}, sourceEntity)
        .then(options => {
          // this is necessary in two cases
          // case 1: we only have the entityKey and upon receiving the entity we set the id here
          // case 2: in case a new entity is created, use the created id to select the created model from the store
          if (entityId !== options.entity.id) {
            setEntityId(options.entity.id);
          }
        });
    } else if (lastUpdatedAt && model.entity.updatedAt && model.entity.updatedAt < lastUpdatedAt) {
      // TODO PICM-1501 trigger update
      // EntityEditService.updateEntityEditModel(...)
      console.warn(`entity ${model.entity.keys?.map(k => k.value)} was updated, but update not yet implemented`);
    }
  }, [model, dispatch, entityId, initialEntityKey, entityType, sourceEntity, lastUpdatedAt]);
  return model;
};

const useLastActivityStreamUpdateTime = (entityType: string, entityId?: string) => {
  return useSelector((state: ICoreApplicationState) => {
    if (entityId) {
      return state.activityStreamState.objectUpdatesByType[entityType]?.[entityId]?.lastUpdatedAt;
    }
    return undefined;
  });
};

export const useReloadEntityEditModel = (entity: Entity) => {
  return useCallback(() => {
    StoreService.store.dispatch(ReloadEntityEditModel({
      entityId: entity.id!,
      entityType: entity.type!,
      lock: false,
    }));
  }, [entity.type, entity.id]);
};


/**
 * Calculate viewModelData that is dependent on the current state of the entityEditModel such as the title, entityKey and the draft status.
 *
 * @param entityType
 * @param readOnly true, if the edit model should become write protected
 * @param entityConfiguration
 * @param editModel
 */
export const useUpdatedViewModelData = (entityType: string, readOnly: boolean, entityConfiguration?: EntityConfiguration, editModel?: EntityEditModel) => {
  const {getMessage} = useMessages();
  return useMemo(() => {
    if (!editModel) {
      return undefined;
    }

    const entity = editModel.currentEntity ?? editModel.entity;
    const entityId = entity.id;

    const hasPropertyChanges = (editModel.propertyChanges?.length ?? 0) > 0;
    const modified = !readOnly && !editModel.readOnly && hasPropertyChanges;
    const isDraft = editModel.draft;
    const entityKey = editModel?.entity.keys![0]?.value ?? entityId;

    const typeDisplay = entityConfiguration?.typeDisplay ?? "";
    const valueDisplay = entityConfiguration?.valueDisplay && entity ? ExpressionEvaluationService.evaluate(entityConfiguration.valueDisplay, entity) ?? "" : "";
    const hasTypeOrValueDisplay = !!(typeDisplay || valueDisplay);
    const title = getMessage(`${entityType.toLowerCase()}.editor.title`, {
      params: {
        typeDisplay,
        valueDisplay,
        entity: entity,
      },
      defaultMessage: hasTypeOrValueDisplay ? `${typeDisplay} ${valueDisplay}` : undefined,
      fallbackMessageCode: hasTypeOrValueDisplay ? undefined : `${entityType.toLowerCase()}.label.title`,
    });

    return {
      readOnly,
      modified,
      isDraft,
      entityKey,
      title,
    };
  }, [getMessage, readOnly, editModel, entityType, entityConfiguration]);
};
