/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableTextOrIconsColumn, NormalizedInfiniteData} from "@icm/core-common";
import {Table as MuiTable, TableCell, TableRow} from "@mui/material";
import clsx from "clsx";
import React, {useMemo, useState} from "react";
import {Virtuoso} from "react-virtuoso";

import {LoadingBar} from "../../../loading";
import {DataTableContext, DataTableHeadCellContent, DataTableProps, DataTableRowHeader, NoEntriesRow, useDataTableStyles} from "../common";
import {LoadingDataRow} from "./LoadingDataRow";
import {useColWidthsBasedOnContent} from "./useColumnWidthsBasedOnContent";
import {useFlexDataTableContext} from "./useFlexDataTableContext";
import {useRenderVirtualizedRow} from "./VirtualizedRow";
import {getCellStyle} from "./VirtualizedTableUtil";


export type VirtualizedDataTableProps<T> = DataTableProps<T> & {
  infiniteData: NormalizedInfiniteData<T>
};

export type ColumnWithWidth<T> = DataTableTextOrIconsColumn<T> & {
  width: number
};

export function VirtualizedInfiniteTable<T extends {}>({
  infiniteData, tableConfig,
}: VirtualizedDataTableProps<T>) {
  const [table, setTable] = useState<HTMLElement | null>(null);

  const flexContext = useFlexDataTableContext(tableConfig, table);

  const classes = useDataTableStyles();
  const data = useMemo(() => infiniteData.data ?? [], [infiniteData.data]);
  const widths = useColWidthsBasedOnContent(data, tableConfig.dataColumns);

  const columns: ColumnWithWidth<T>[] = useMemo(() => tableConfig.dataColumns.map((col, index) => ({
    ...col,
    width: widths?.columnWidths[index] ?? col.headerText?.length ?? 1,
  })), [tableConfig.dataColumns, widths]);
  const RenderVirtualizedRow = useRenderVirtualizedRow(columns);

  return (
    <DataTableContext.Provider value={flexContext}>
      <MuiTable component="div" size="small" className={classes.flexTable} style={{minWidth: `${widths?.minTableWidth ?? 0}ch`}}>
        <LoadingBar variant="query" visible={infiniteData.isFetching} />
        {tableConfig.showColumnHeaders && (
        <MuiVirtTableHead bodyHasVerticalScrollbars={flexContext.hasVerticalScrollbars}
                          columns={columns}
                          orderValid={infiniteData.orderValid}
        />
        )}
        <Virtuoso totalCount={data.length}
                  data={data}
                  itemContent={RenderVirtualizedRow}
                  overscan={{main: window.screen.height / 2, reverse: window.screen.height / 2}}
                  endReached={async () => {
                    if (infiniteData.hasNextPage) {
                      await infiniteData.fetchNextPage();
                    }
                  }}
                  scrollerRef={ref => ref && setTable(ref as HTMLElement)}
                  className={classes.tableBody}
                  components={{
                    Footer: () => infiniteData.isFetchingNextPage ? <LoadingDataRow columns={columns} /> : <div style={{minHeight: "1px"}} />,
                    EmptyPlaceholder: () => {
                      if (infiniteData.isIdle || infiniteData.isLoading || data.length > 0) {
                        return <LoadingDataRow columns={columns} />;
                      } else {
                        return <NoEntriesRow />;
                      }
                    },
                  }}
        />
      </MuiTable>
    </DataTableContext.Provider>
  );
}

type MuiVirtTableHeadProps<T extends {}> = {
  bodyHasVerticalScrollbars: boolean
  columns: ColumnWithWidth<T>[]
  orderValid: boolean
};

const MuiVirtTableHead = React.memo(<T extends {}>({bodyHasVerticalScrollbars, columns, orderValid}: MuiVirtTableHeadProps<T>) => {
  const classes = useDataTableStyles();
  return (
    <div role="rowgroup" className={classes.tableHead}>
      <TableRow component="div" className={clsx(classes.flexRow, bodyHasVerticalScrollbars && classes.hiddenScrollbars)}>
        <DataTableRowHeader />
        {columns.map((column) => {
          return (
            <TableCell key={column.name}
                       component={"div" as any}
                       classes={{
                         root: clsx(
                           ("sortOrder" in column && column.sortOrder !== undefined) && classes.sortedColumnHeaderCell,
                           classes.cell,
                           classes.columnHeaderCell,
                           column.sortable && classes.sortableColumnHeaderCell,
                         ),
                       }}
                       style={getCellStyle(column)}
            >
              <DataTableHeadCellContent col={column} orderValid={orderValid}>
                {column.headerText}
              </DataTableHeadCellContent>
            </TableCell>
          );
        })}
      </TableRow>
    </div>
  );
});
