/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FilterParameter, MessageKey, ParameterUtilities, ResolvedParameterList, ViewDescriptor} from "@icm/core-common";
import {Feature} from "geojson";
import {isString, isBoolean, isEqual} from "lodash-es";
import React from "react";

export type MapPosition = {
  x: number
  y: number
  z?: number
}

export type MapBounds = {
  // >= -180 and <= 180
  x1: number
  // >= -180 and <= 180
  x2: number
  // >= -90 and <= 90
  y1: number
  // >= -90 and <= 90
  y2: number
}

export type MapFeatures = {
  features: Feature[]
  padding?: number
  zoom?: number
}

export type SelectFeatures = {
  value: string
  source: string
  properties: string[]
  follow: boolean
}

export type MapViewModel = {
  mapType?: string
  mapVariant?: string
  mapPosition?: MapPosition
  mapBounds?: MapBounds
  mapFeatures?: MapFeatures
  selectFeatures?: SelectFeatures
  filterParameter?: FilterParameter
  mapTypeToggle?: boolean,
  printRequested: boolean,
}

export const mapViewDescriptor: ViewDescriptor<MapViewModel> = {
  viewType: "MAP_VIEW",
  view: React.lazy(() => import("./MapView")),
  createUniqueHash: (viewModel) => `${viewModel.mapType}/${viewModel.mapVariant}`,
  getTitle: (_viewModel, getMessage) => getMessage(MessageKey.MAP.VIEW),
  initializeViewModelData: (viewParameters) => {
    const mapType = ParameterUtilities.getResolvedParameterValue("mapType", viewParameters, isString);
    const mapTypeToggle = ParameterUtilities.getResolvedParameterValue("mapTypeToggle", viewParameters, isBoolean);
    const mapVariant = ParameterUtilities.getResolvedParameterValue("mapVariant", viewParameters, isString);
    const mapPosition = getMapPosition(viewParameters);
    const mapBounds = getMapBounds(viewParameters);
    const mapFeatures = getFeatures(viewParameters);
    const selectFeatures = getSelectFeatures(viewParameters);
    const filterParameter = viewParameters.filter;

    return {
      mapType,
      mapTypeToggle,
      mapVariant,
      mapPosition,
      mapBounds,
      mapFeatures,
      selectFeatures,
      filterParameter,
      printRequested: false,
    };
  },
  updateViewModelData: (viewModelData, viewParameters) => {
    const mapPosition = getMapPosition(viewParameters);
    const mapBounds = getMapBounds(viewParameters);
    const mapFeatures = getFeatures(viewParameters);
    const selectFeatures = getSelectFeatures(viewParameters);

    if (mapBounds && !isEqual(mapBounds, viewModelData.mapBounds)) {
      viewModelData.mapBounds = mapBounds;
    }
    if (mapPosition && !isEqual(mapPosition, viewModelData.mapPosition)) {
      viewModelData.mapPosition = mapPosition;
    }
    if (mapFeatures && !isEqual(mapFeatures, viewModelData.mapFeatures)) {
      viewModelData.mapFeatures = mapFeatures;
    }
    if (selectFeatures && !isEqual(mapFeatures, viewModelData.mapFeatures)) {
      viewModelData.selectFeatures = selectFeatures;
    }
  },
};

function getMapBounds(viewParameters: ResolvedParameterList): MapBounds | undefined {
  // min. longitude, min. latitude, max. longitude, max. latitude for fitting the map to these bounds
  const x1 = getFloat(viewParameters, "x1");
  const x2 = getFloat(viewParameters, "x2");
  const y1 = getFloat(viewParameters, "y1");
  const y2 = getFloat(viewParameters, "y2");

  let mapBounds = undefined;
  if (x1 && y1 && x2 && y2 && x1 >= -180 && x1 <= 180 && y1 >= -90 && y2 <= 90 && x2 >= -180 && x2 <= 180 && y2 >= -90 && y2 <= 90) {
    mapBounds = {
      x1,
      x2,
      y1,
      y2,
    };
  }
  return mapBounds;
}

function getMapPosition(viewParameters: ResolvedParameterList) {
  // longitude, latitude, zoom level for centering the map
  const x = getFloat(viewParameters, "x");
  const y = getFloat(viewParameters, "y");
  const z = getFloat(viewParameters, "z");
  const mapPosition = x && y ? {x, y, z} : undefined;
  return mapPosition;
}


function getFloat(viewParameters: ResolvedParameterList, parameterKey: string): number | undefined {
  const stringValue = ParameterUtilities.getResolvedParameterValue(parameterKey, viewParameters, isString);
  if (stringValue) {
    return Number.parseFloat(stringValue);
  } else {
    return undefined;
  }
}

/**
 * The following viewParameters will be parsed:
 * - features GeoJson features serialized as JSON
 * - geomType GeoJson geometry type
 * - geomCoords GeoJson coordinates
 * - geomColor GeoJson color
 * - geomText GeoJson text/color
 * - zoom zoom level for displaying a point feature
 * - padding padding as fraction of the map size for fitting features to the bounds
 */
function getFeatures(viewParameters: ResolvedParameterList): MapFeatures | undefined {
  const features = ParameterUtilities.getResolvedParameterValue("features", viewParameters, isString);
  const geomType = ParameterUtilities.getResolvedParameterValue("geomType", viewParameters, isString);
  const geomCoords = ParameterUtilities.getResolvedParameterValue("geomCoords", viewParameters, isString);
  const geomColor = ParameterUtilities.getResolvedParameterValue("geomColor", viewParameters, isString);
  const geomText = ParameterUtilities.getResolvedParameterValue("geomText", viewParameters, isString);
  const zoom = getFloat(viewParameters, "featureZoom");
  const padding = getFloat(viewParameters, "featurePadding");
  if (features) {
    // features might/will be serialized
    return {
      features: JSON.parse(features),
      zoom,
      padding,
    };
  } else if (geomType && geomCoords) { // check single feature
    const color = geomColor;
    const text = geomText;
    return {
      features: [{
        type: "Feature",
        geometry: {type: geomType, coordinates: JSON.parse(geomCoords)},
        properties: {color, text},
      } as Feature],
      zoom,
      padding,
    };
  } else {
    return undefined;
  }
}

/**
 * The following viewParameters will be parsed:
 * - select select features with this value
 * - selectProperties ... in one of these comma separated properties
 * - selectSource ... in these sources (regex pattern)
 * - follow ... and follow it (center single feature on map)
 */
function getSelectFeatures(viewParameters: ResolvedParameterList): SelectFeatures | undefined {
  const select = ParameterUtilities.getResolvedParameterValue("select", viewParameters, isString);
  const selectSource = ParameterUtilities.getResolvedParameterValue("selectSource", viewParameters, isString);
  const selectProperties = ParameterUtilities.getResolvedParameterValue("selectProperties", viewParameters, isString);
  const follow = ParameterUtilities.getResolvedParameterValue("follow", viewParameters, isString)?.toLowerCase() === "true";

  const value = select?.replaceAll("?", ".").replaceAll("*", ".*") ?? "";
  const source = selectSource?.replaceAll("?", ".")?.replaceAll("*", ".*") ?? ".*";
  const properties: string[] = selectProperties?.split(/\s*,\s*/) ?? [];
  if (source && properties && properties.length > 0) {
    return {
      value,
      source,
      properties,
      follow,
    };
  }
  return undefined;
}
