/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FilterSelection} from "../../generated/api";

/**
 * Describes a collection of filters as seen by the client.
 * Purpose of this type is to mark certain properties of FilterSelection mandatory.
 */
export type ClientFilterSelection = FilterSelection & Required<Pick<FilterSelection, "id" | "name" | "entityType" | "selectedFilters">>;

/**
 * Check if the given value is a ClientFilterSelection object
 * @param val
 */
export const isClientFilterSelection = (val: any): val is ClientFilterSelection => {
  return val !== null
    && typeof val === "object"
    && typeof val.id === "string"
    && typeof val.name === "string"
    && typeof val.entityType === "string";
};
