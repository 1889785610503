/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {useMemo} from "react";

import {SELECT_MULTI, SELECT_SINGLE} from "../../../constant";
import {AdditionalOptionsProvider} from "../../../dao";
import {getNamedComponentFactory} from "../../../ui";
import {IFormComponentProps} from "../AbstractFormComponent";
import {ReadOnlyAutoCompleteComponent} from "./ReadOnlyAutoCompleteComponent";
import {ValueWithVersion} from "./SelectComponent";

export type AutoCompleteFormComponentProps<T> = {
  // the value as bound by valueBinding, a ValueWithVersion if a versionBinding is given
  value?: Array<unknown | ValueWithVersion>
  /**
   * triggered on a change of value
   * @param newValue the value as bound by valueBinding, a ValueWithVersion if a versionBinding is given
   * @param reason reason what triggered the onChange
   */
  onChange: (newValue?: Array<unknown | ValueWithVersion>, originalValue?: Array<unknown>, reason?: (AutocompleteFormChangeReason | null)) => void

  // if true, only one value can be selected and handleChange will be called with a array of size 1
  multiple: boolean

  // if true: loading of possible values is debounced - still has some issues when multiple autoselects are on one page
  debounceLoadPossibleValues?: boolean
  // the values the user can select from
  providedOptions?: T[]
  // provider of additional options given static provided options: this can be used to extend available options as you type
  additionalOptionsProvider?: AdditionalOptionsProvider<T>

  // URL by which the available values should be loaded
  possibleValuesUrl?: string
  // URL from which the value will be loaded, if it's not in the availableValues
  possibleValuesByValuesUrl?: string

  valueBinding: string
  versionBinding?: string
  valueDisplay?: string

  hideClearButton?: boolean

  /**
   * If true, initially selected values can be selected (again), even if they are not in the provided options
   *
   * @default true
   */
  keepInitialValuesInOptions?: boolean
  /**
   * Override the loading state externally, e.g., when loading possible values
   * @default managed internally
   */
  loading?: boolean
} & Omit<IFormComponentProps<Array<any | ValueWithVersion>>, "handleChange">;

export type AutocompleteFormChangeReason =
  | "userAction";

export const AutoCompleteComponent = <T extends unknown>(props: AutoCompleteFormComponentProps<T>) => {
  const isBoundByValue = props.valueBinding === "_";
  const withVersion = !!props.versionBinding;
  const daoOptions = useMemo(() => props.possibleValuesUrl ? {
    valueBinding: props.valueBinding,
    displayBinding: props.valueDisplay ?? props.valueBinding,
    versionBinding: props.versionBinding,
    possibleValuesUrl: props.possibleValuesUrl,
    possibleValuesByValuesUrl: props.possibleValuesByValuesUrl,
  } : {
    valueBinding: props.valueBinding,
    displayBinding: props.valueDisplay ?? props.valueBinding,
    versionBinding: props.versionBinding,
    possibleValuesList: props.providedOptions!,
    additionalOptionsProvider: props.additionalOptionsProvider,
  }, [props.possibleValuesByValuesUrl, props.possibleValuesUrl, props.providedOptions, props.valueBinding,
    props.valueDisplay, props.versionBinding, props.additionalOptionsProvider]);

  if (props.readOnly) {
    return <ReadOnlyAutoCompleteComponent isBoundByValue={isBoundByValue} withVersion={withVersion} daoOptions={daoOptions} {...props} />;
  } else {
    const Component = getNamedComponentFactory(SELECT_SINGLE)
      || getNamedComponentFactory(SELECT_MULTI)
      || (() => <span>Autocomplete component has no implementation.</span>);
    return (
      <Component isBoundByValue={isBoundByValue} withVersion={withVersion} daoOptions={daoOptions} {...props} />
    );
  }
};
