/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CommunicationAddress, CommunicationChannel, isCommunicationChannel, isNonEmptyString} from "@icm/core-common";

import {Entity} from "../generated/api";


type HasAddressValue =  { addressValue: { value: CommunicationAddress["addressValue"]} }
type HasChannelValue =  { channel: { value: CommunicationChannel } }

/**
 * An Entity that has all dynamicAttributes required to
 * build create ContactAddress.
 */
export type CommunicationAddressEntity = {
  dynamicAttributes: Entity["dynamicAttributes"] & HasAddressValue & HasChannelValue
  id: string
} & Entity

/**
 * Check if a given entity can be viewed as a ContactAddress.
 * In this case, the asContactAddress will return a value.
 *
 * @param val
 * @see asCommunicationAddress
 */
export function isCommunicationAddressEntity(val: Entity): val is CommunicationAddressEntity {
  if (val.dynamicAttributes) {
    return (
      isNonEmptyString(val.dynamicAttributes.addressValue?.value)
      && isNonEmptyString(val.id)
      && isCommunicationChannel(val.dynamicAttributes.channel?.value)
    );
  }
  return false;
}


/**
 * Maps the given entity to a CommunicationAddress if possible.
 *
 * @param val an entity whose dynamicAttributes should be mapped
 * @see isCommunicationAddressEntity
 */
export function asCommunicationAddress(val: Entity): CommunicationAddress | undefined {
  if (isCommunicationAddressEntity(val)) {
    const label = val.dynamicAttributes.label.value;
    const addressValue = val.dynamicAttributes.addressValue.value;
    return {
      label: label ?? addressValue,
      addressValue,
      channel: val.dynamicAttributes.channel.value,
      id: val.id,
    };
  }
  return undefined;
}
