/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {getNamedComponentFactory} from "@icm/core-common";
import {REM_AUDIT_LOG} from "@icm/rem-rail-common";


export type AuditLogProps = {
  incidentNumber: string
  entityType: string
}

export const useAuditLogComponent = (props: AuditLogProps) => {
  const AuditLogComponent = getNamedComponentFactory(REM_AUDIT_LOG);

  if (!AuditLogComponent) {
    return undefined;
  }

  return <AuditLogComponent {...props} />;
};
