/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";
import {ErrorInfo, PropsWithChildren} from "react";

interface IErrorBoundaryState {
  hasError: boolean,
  error?: Error,
  errorInfo?: ErrorInfo
}

export class ErrorBoundary extends React.Component<PropsWithChildren<{}>, IErrorBoundaryState> {

  constructor(props: {}) {
    super(props);
    this.state = {hasError: false};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Caught error while rendering component", error, errorInfo);
    // Display fallback UI
    this.setState({hasError: true, error, errorInfo});
    // You can also log the error to an error reporting service
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (process.env.NODE_ENV === "development") {
        return (
          <div>
            <h1>Something went wrong.</h1>
            <h2>Error</h2>
            <pre style={{whiteSpace: "pre-wrap"}}>
              {JSON.stringify(this.state.error, null, "\t")}
            </pre>
            <h2>Error info</h2>
            <pre style={{whiteSpace: "pre-wrap"}}>
              {JSON.stringify(this.state.errorInfo, null, "\t")}
            </pre>
          </div>
        );
      } else {
        return (
          <p>Something went wrong. Try refreshing the browser. Please contact your administrator if the error appears again.</p>
        );
      }
    }
    return this.props.children;
  }
}
