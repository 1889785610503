/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {getNamedComponentFactory, ICON, IFormProps, useFormGeneratorContext} from "@icm/core-common";
import {FC} from "react";

import {ButtonComponent, IButtonProps} from "../button/ButtonComponent";

type Props = Pick<IFormProps<any>, "submitButtonConfiguration" | "renderSubmitButton">;
export const FormSubmitButton: FC<Props> = (props) => {
  const {submitButtonConfiguration, renderSubmitButton} = props;

  const {valid} = useFormGeneratorContext();
  if (submitButtonConfiguration?.submitLabel) {
    const Icon = getNamedComponentFactory(ICON) || (() => <span>Icon is not configured.</span>);
    const buttonProps: IButtonProps = {
      type: "submit",
      disabled: !valid,
      label: submitButtonConfiguration.submitLabel,
      icon: <Icon name={submitButtonConfiguration.submitIcon} />,
    };

    return renderSubmitButton
      ? <>{renderSubmitButton(buttonProps)}</>
      : <ButtonComponent {...buttonProps} />;
  }
  return <></>;
};
