/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {alpha, darken, lighten} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../../../theme";


const styles = (theme: IcmMuiTheme) => createStyles({
  flexTable: {
    display: "flex !important",
    flexFlow: "column",
    height: "100%",
    minWidth: theme.breakpoints.values["sm"],
    "& $tableHead": {
      flex: "0 1 auto",
      borderBottom: `1px solid ${theme.palette.mode === "light"
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)}`,
      "& $cell": {
        borderBottom: "none",
      },
      "& $columnHeaderCell": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        overflowWrap: "anywhere",
      },
      "& $columnHeaderCell:last-child $sortLabelRoot": {
        width: "100%",
      },
      "& $sortLabelRoot": {
        width: "calc(100% + 8px)",
        height: "100%",
        display: "inline-flex",
      },
      "& $sortLabelIcon": {
        marginRight: 0,
        verticalAlign: "text-top",
      },
      "& $sortLabelText": {
        flex: "0 1 auto",
        hyphens: "auto",
      },
      "@media not all and (hover: hover)": {
        paddingRight: theme.spacing(4),
      },
    },
    "& $tableBody": {
      overflowY: "scroll",
      overflowX: "hidden",
      flex: "1 1 auto",
    },
    "& $rowHeaderCell": {
      padding: "1px 2px",
      width: theme.spacing(4.25),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "&$rowHeaderStatusIndicator": {
        paddingLeft: theme.spacing(1.5),
      },
    },
    "& $dataCell": {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      flexDirection: "column",
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      overflowWrap: "anywhere",
      wordBreak: "break-word",
    },
    "& $cell": {
      boxSizing: "border-box",
      minWidth: "0px",
    },
    "& $actionsBox": {
      width: 0,
    },
  },
  classicTable: {
    // this doesn't actually do anything, but is important to use height:100% in elements within cells
    height: 1,
    "& $row,& $actionsColumn": {
      // this doesn't actually do anything, but is important to use height:100% in elements within cells
      height: "100%",
    },
    "& $noWordBreak": {
      wordBreak: "keep-all",
    },
    "& $actionsInnerBox": {
      "@media not all and (hover: hover)": {
        right: 0,
      },
    },
    "& $tableHead $sortableColumnHeaderCell": {
      // use less padding right, as sort icon makes cell wider
      paddingRight: theme.spacing(1),
    },
  },
  embeddedTable: {
    "& > $flexTable > $tableBody > div": {
      position: "relative !important",
    },
  },
  tableHead: {
    "& $cell$sortedColumnHeaderCell": {
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
      paddingBottom: 5, // offset border bottom (default: 6px)
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  tableBody: {
    "& $dataCell": {
      whiteSpace: "pre-line",
      overflowWrap: "break-word",
      wordBreak: "break-word",
      paddingRight: theme.spacing(2),
      fontSize: "1rem",
      "@media not all and (hover: hover)": {
        "&:last-child": {
          paddingRight: theme.spacing(6),
        },
      },
    },
  },
  row: {
    "@media (hover: hover)": {
      "&:hover $actionsInnerBox": {
        visibility: "visible !important",
      },
      "& $actionsInnerBox": {
        visibility: "hidden",
      },
    },
  },
  flexRow: {
    display: "flex !important",
    flex: "1 0 auto",
    minWidth: 0,
  },
  cell: {},
  dataCell: {},
  columnHeaderCell: {},
  // first (special) cell in a row
  rowHeaderCell: {
    "&$rowHeaderStatusIndicator": {
      borderLeft: `6px solid ${theme.palette.background.paper}`,
    },
  },
  rowHeaderStatusIndicator: {},
  sortableColumnHeaderCell: {},
  sortedColumnHeaderCell: {},
  sortLabelRoot: {},
  sortLabelIcon: {},
  sortLabelText: {},
  // misc classes
  hiddenScrollbars: {
    // adds hidden scrollbar to get same size calculation as an element with visible scrollbars
    overflowY: "scroll",
    overflowX: "hidden",
    // ff-only
    scrollbarColor: "transparent transparent",
    // webkit-only
    "&::-webkit-scrollbar": {
      color: "transparent",
    },
  },
  /* Styles applied to the root element if `expanded={true}`. */
  expanded: {},
  expandIcon: {
    transform: "translateX(4px) rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    "&$expanded": {
      transform: "translateX(4px) rotate(90deg)",
    },
  },
  removed: {
    color: theme.palette.text.disabled,
  },
  deleted: {
    textDecoration: "line-through",
  },
  actionsColumn: {
    padding: "0 !important",
    "@media not all and (hover: hover)": {
      width: theme.spacing(4),
    },
  },
  actionsBoxOpen: {
    "& $actionContainer": {
      display: "initial",
    },
    "& $actions": {
      "@media not all and (hover: hover)": {
        padding: theme.spacing(0, 1, 0, 2),
        // keep linear-gradient params in sync with actionsInnerBox's
        background: `linear-gradient(90deg, transparent 0, ${theme.palette.action.hover} ${theme.spacing(2)})`,
      },
    },
    "& $actionsInnerBox": {
      "@media not all and (hover: hover)": {
        // keep linear-gradient params in sync with actions'
        background: `linear-gradient(90deg, transparent 0, ${theme.palette.background.paper} ${theme.spacing(2)})`,
      },
    },
  },
  actionMoreContainer: {
    // show more container only on touch only devices
    "@media (hover: hover)": {
      display: "none",
    },
  },
  actionContainer: {
    "@media not all and (hover: hover)": {
      display: "none",
    },
  },
  actionsBox: {
    position: "relative",
    height: "100%",
  },
  actionsInnerBox: {
    position: "absolute",
    top: 0,
    right: "100%",
    width: "auto",
    height: "calc(100% - 1px)",
    overflow: "hidden",
    "@media (hover: hover)": {
      // keep linear-gradient params in sync with actions'
      background: `linear-gradient(90deg, transparent 0, ${theme.palette.background.paper} ${theme.spacing(2)})`,
    },
    "@media not all and (hover: hover)": {
      right: theme.spacing(-4),
    },
  },
  actions: {
    height: "100%",
    margin: 0,
    "@media (hover: hover)": {
      // keep linear-gradient params in sync with actionsInnerBox's
      background: `linear-gradient(90deg, transparent 0, ${theme.palette.action.hover} ${theme.spacing(2)})`,
      padding: theme.spacing(0, 2),
    },
    "@media not all and (hover: hover)": {
      padding: theme.spacing(0, 1, 0, 0),
    },
  },
  stickyColumnHeaderCell: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    borderBottomWidth: 2,
  },
  noWordBreak: {
    // overridden in classic table only
  },
  iconsCellContainer: {
    alignSelf: "center",
  },
});

export const useDataTableStyles = makeStyles(styles);
