/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ICoreApplicationState, TabbedPerspective, TabGroup, TabGroupTab} from "@icm/core-common";
import {useSelector} from "react-redux";

export type TabGroupDescriptor = {
  /** the current selected tab within the group */
  selectedTab: TabGroupTab,
  /** true, if this group is the active group within the perspective. The active group will be the target when opening new tabs. */
  active: boolean,
  /** width in px */
  size: number,
  /** true, if there are at least two tabs in the group being described. */
  canSplit: boolean,

} & TabGroup

/**
 * Get the tab group descriptor for a tab group with the given id
 * @param tabGroupId
 */
export const useTabGroupDescriptor = (tabGroupId: string): TabGroupDescriptor | undefined => {
  return useSelector((state: ICoreApplicationState) => {
    const perspective = state.uiState.perspective;
    if (perspective?.perspectiveType === "TABBED") {
      const tabGroup = perspective.tabGroups.find((g: TabGroup) => g.id === tabGroupId);
      if (!tabGroup || tabGroup.tabs.length === 0) {
        return undefined;
      }
      return createGroupDescriptor(perspective, tabGroup);
    }
    return undefined;
  });
};


function createGroupDescriptor(
  perspective: TabbedPerspective,
  tabGroup: TabGroup
): TabGroupDescriptor {
  const tgIndex = perspective.tabGroups.indexOf(tabGroup);
  // If there is a sidebar, the sidebar size will be part of the sizes array.
  // Thus, we need to add one to the index.
  const tgIndexOffset = perspective.sideBarId ? 1 : 0;
  const size = perspective.sizes?.[tgIndex + tgIndexOffset] ?? -1;
  const inActiveTabGroup = perspective.currentTabGroupId === tabGroup?.id;
  const canSplit: boolean = tabGroup ? tabGroup.tabs.length > 1 : false;
  return {
    ...tabGroup,
    selectedTab: tabGroup.tabs[tabGroup.selectedTabIndex],
    selectedTabIndex: tabGroup.selectedTabIndex,
    active: inActiveTabGroup,
    canSplit,
    size,
  };
}
