/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  IcmThemeState,
  MessageKey,
  ThemeContext, useConnectionState,
  useMessages,
  UserSettingsApi,
  useService,
} from "@icm/core-common";
import {PowerSettingsNew} from "@mui/icons-material";
import {AppBar, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import {Brightness4} from "mdi-material-ui";
import * as React from "react";
import {useContext, useRef} from "react";

import {IcmMuiIcon, IconButtonComponent} from "../../components";
import {withColorOverride} from "../../theme";
import {NotificationCenterIcon} from "../notification/NotificationCenterIcon";
import styles from "./ApplicationHeaderStyle";

const useStyles = makeStyles(styles);

const BareApplicationHeader = () => {
  const classes = useStyles();
  const {getMessage} = useMessages();

  const themeContext = useContext(ThemeContext);
  const availableThemes = themeContext ? Object.keys(themeContext.themeState.themes) : [];

  const toggleTheme = () => {
    const newTheme = nextTheme(availableThemes, themeContext!.themeState);
    themeContext!.setCurrentTheme(newTheme);
    UserSettingsApi.postUserSetting("theme", newTheme);
  };

  const notificationService = useService("NOTIFICATION");
  const notificationCenterAnchor = useRef<HTMLDivElement>(null);

  const securityService = useService("SECURITY");
  const connected = useConnectionState();

  return (
    <AppBar position="relative"
            elevation={1}
            className={`${classes.icmApplicationHeader} printNoBoxShadow`}
            ref={notificationCenterAnchor}
    >
      <div className={`${classes.frequentisLogo} dontPrint`} />

      <Grid item={true} xs={true} className="dontPrint" />

      <div className={classes.icmApplicationHeaderLogo} />

      <div className={`${classes.user} dontPrint`}>
        <Typography variant="subtitle1">
          {securityService.getCurrentUserFullNameWithRoles()}
        </Typography>
      </div>
      <Grid item container wrap="nowrap" sx={{flexBasis: "0"}} className="dontPrint">
        {!connected && (
          <Grid item className={clsx(classes.headerIcon, classes.ripple)}>
            <Typography className={classes.textSecondary}>
              <IcmMuiIcon name="cloud_disconnected" />
            </Typography>
          </Grid>
        )}
        {notificationService && (
          <Grid item>
            <NotificationCenterIcon anchor={notificationCenterAnchor} />
          </Grid>
        )}
        {availableThemes.length >= 2 && (
          <Grid item>
            <IconButtonComponent icon={<Brightness4 />}
                                 padding="large"
                                 tooltip={getMessage(MessageKey.CORE.THEME.TOGGLE)}
                                 handleClick={toggleTheme}
            />
          </Grid>
        )}
        <Grid item>
          <IconButtonComponent icon={<PowerSettingsNew />}
                               padding="large"
                               tooltip={getMessage(MessageKey.CORE.LOGOUT)}
                               handleClick={() => securityService.logout()}
          />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export const ApplicationHeader = React.memo(withColorOverride(BareApplicationHeader, "ApplicationHeader"));

function nextTheme(availableThemes: string[], themeState: IcmThemeState): string {
  const nextThemeIdx = (availableThemes.indexOf(themeState.selectedTheme) + 1) % availableThemes.length;
  return availableThemes[nextThemeIdx];
}
