/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ClientProfile} from "../generated/api";
import {FetchService, FetchServiceException} from "../service";

export class ClientProfileApi {
  public static postClientProfile(profile: ClientProfile): Promise<string> {
    return FetchService.performPost("core/clientProfiles", profile)
      .then(response => response.text())
      .catch(async (ex: FetchServiceException) => {
        throw await ex.response?.json();
      });
  }
}
