/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CollectionModifier, FilterConditionBase, ListFilter, Sorting} from "../../generated/api";
import {ExpressionEvaluationService} from "../ExpressionEvaluationService";
import {FilterPredicateFactory} from "./FilterPredicateFactory";

const NEVER_MATCH = (_: unknown) => false;
const ALWAYS_MATCH = (_: unknown) => true;

// Filter without paging and sorting
export type FilterAndModifier = Pick<ListFilter, "filter" | "collectionModifiers">;

export type FilterPredicate<T> = (o: T) => boolean;

// analogous to Java ListFilterService
export class FilterService {

  public static createPredicate<T>(filter?: FilterConditionBase): FilterPredicate<T> {
    if (filter) {
      return FilterPredicateFactory.get(filter) ?? NEVER_MATCH;
    } else {
      return ALWAYS_MATCH;
    }
  }

  public static applyFilter<T>(list: T[], filter?: ListFilter): T[] {
    if (filter) {
      return list
        .map(item => this.applyCollectionModifiers(item, filter))
        .filter(this.createPredicate(filter.filter))
        .sort(this.createComparator(filter))
        .slice(this.getOffset(filter), this.getOffset(filter) + this.getLimit(filter));
    } else {
      return list;
    }
  }

  /**
   * @return {true} if no filter is given or given filter matches, false otherwise
   */
  public static matchesFilter<T>(element: T, filter?: FilterAndModifier): boolean {
    if (filter) {
      const predicate = this.createPredicate(filter.filter);
      return predicate(element);
    }
    return true;
  }

  private static applyCollectionModifiers<T>(item: T, filter: ListFilter): T {
    const collectionModifiers = filter.collectionModifiers || [];
    const itemCopy = {...item};
    collectionModifiers.forEach(modifier => this.applyCollectionModifier(itemCopy, modifier));
    return itemCopy;
  }

  private static applyCollectionModifier<T>(item: T, modifier: CollectionModifier): void {
    const propertyName = modifier.propertyOfCollectionToFilterOn!;
    const collection = FilterPredicateFactory.getPropertyAccessor()(item, propertyName);
    if (collection && collection instanceof Array) {
      const itemsToRetain = this.applyFilter(collection, modifier.listFilter);
      FilterPredicateFactory.getPropertySetter()(item, propertyName, itemsToRetain);
    }
  }

  public static createComparator<T>(filter?: ListFilter) {
    if (filter?.sorting) {
      return (p1: T, p2: T) => this.compare(filter.sorting!, p1, p2);
    } else {
      return (_p1: T, _p2: T) => 0;
    }
  }

  public static compare<T>(sorting: Sorting, object1: T, object2: T): number {
    const propertyName = sorting.property!;
    const ascending = sorting.order === "ASC";
    const factor = ascending ? 1 : -1;
    const val1 = ExpressionEvaluationService.get(object1, propertyName);
    const val2 = ExpressionEvaluationService.get(object2, propertyName);
    if (val1 !== undefined && val2 !== undefined) {
      const inverseFactor = val1 < val2 ? -factor : 0;
      return val1 > val2 ? factor : inverseFactor;
    } else if (val1 !== undefined) {
      return factor * 1;
    } else if (val2 !== undefined) {
      return factor * -1;
    } else {
      return 0;
    }
  }

  private static getOffset(filter: ListFilter) {
    return filter.offset || 0;
  }

  private static getLimit(filter: ListFilter) {
    return filter.limit || Number.MAX_VALUE;
  }
}
