/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CompositeSubView,
  FormVariantWithTitle,
  getNamedComponentFactory,
  MessageKey,
  useMessages,
} from "@icm/core-common";
import * as React from "react";

import {ENTITY_HISTORY} from "../constants";
import {EntityEditModel} from "../store";
import {EntityHistoryViewComponentProps} from "./index";


/**
 * Properties for a component rendered as part of the EntityEditViewComposite
 */
type EntityHistorySubViewProps = {
  editModel?: EntityEditModel,
  entityId?: string,
  entityType?: string,
  showHistory: boolean
  mapViewId?: string
  formVariants?: FormVariantWithTitle[]
};

/**
 * Returns a EntityHistoryViewVomponent wrapped in a CompositeSubView
 * in case the showHistory flag is true, a mapViewI and a non draft editModel
 * is provided (this implies an entityType and entityId as well!).
 *
 * @param props
 */
export const EntityHistorySubView = (props: EntityHistorySubViewProps) => {
  const {showHistory, editModel, entityId, entityType, mapViewId, formVariants} = props;
  const {getMessage} = useMessages();
  if (showHistory && editModel && !editModel.draft && entityType && entityId && mapViewId) {
    const Component = getNamedComponentFactory(ENTITY_HISTORY);
    if (Component) {
      const safeProps: EntityHistoryViewComponentProps = {entityId: entityId, entityType: entityType, mapViewId: mapViewId, formVariants: formVariants};
      return (
        <CompositeSubView id="entityHistory" label={getMessage(MessageKey.ENTITY.HISTORY.TITLE)}>
          <Component {...safeProps} />
        </CompositeSubView>
      );
    }
  }
  return <></>;
};
