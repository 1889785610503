/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Dispatch, SetStateAction} from "react";

import {Dao, DaoOptions} from "./Dao";

export type AdditionalOptionsProvider<T> = {
  provide: (search: string) => Promise<T[]>,
  sort: (values: T[]) => void,
}

export type StaticListBasedDaoOptions<T> = DaoOptions & {
  // list to retrieve possible values from
  possibleValuesList: T[],
  additionalOptionsProvider?: AdditionalOptionsProvider<T>,
};

/**
 * provides possible values from a given static list
 */
export class StaticListBasedDao<T> extends Dao<T> {
  private readonly options: StaticListBasedDaoOptions<T>;

  private readonly possibleValues: T[] = [];

  private readonly setAdditionalOptions: Dispatch<SetStateAction<T[]>>;

  constructor(options: StaticListBasedDaoOptions<T>, additionalOptions: T[], setAdditionalOptions: Dispatch<SetStateAction<T[]>>) {
    super(options);
    this.options = options;
    this.possibleValues = [...options.possibleValuesList, ...additionalOptions];
    this.setAdditionalOptions = setAdditionalOptions;
    this.loadPossibleValuesByValues = this.loadPossibleValuesByValues.bind(this);
    this.loadPossibleValues = this.loadPossibleValues.bind(this);
  }

  loadPossibleValuesByValues(values: unknown[]) {
    return Promise.resolve(
      this.possibleValues.filter(pv => values.find(v => this.valueEqual(this.getValue(pv), v)))
    );
  }

  isMatchInKnownOptions(search: string) {
    return !!this.possibleValues.find(pv => this.getDisplay(pv).toLowerCase().includes(search.toLowerCase()));
  }

  loadPossibleValues(search?: string): Promise<T[]> {
    if (search && !this.isMatchInKnownOptions(search) && this.options.additionalOptionsProvider) {
      return this.options.additionalOptionsProvider.provide(search).then((o: T[]) => {
        this.possibleValues.push(...o);
        this.options.additionalOptionsProvider?.sort(this.possibleValues);
        this.setAdditionalOptions(previous => [...previous, ...o]);
        return [...this.possibleValues];
      });
    } else {
      return Promise.resolve([...this.possibleValues]);
    }
  }
}
