/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {useQuery} from "react-query";

import {Urls} from "../constant";
import {FileDetails} from "../generated/api";
import MessageKey from "../generated/MessageKey";
import {FetchService, messages} from "../service";


/**
 * @return Maximum accepted file size in bytes.
 */
export const DEFAULT_MAX_FILE_SIZE = 20_000_000;

/** Type that should be used in case the actual type cannot be determined. */
const FALLBACK_CONTENT_TYPE = "application/octet-stream";


/**
 * The data type of a file gives hint about the way an file should
 * be shown in the UI. An IMAGE can be rendered as <img> while a VIDEO
 * can be shown in a media player. The type APPLICATION serves as
 * a fallback and should be read as "external unknown application". Thus,
 * files having this type should be opened after download.
 */
export type  FileDataType = "IMAGE" | "VIDEO" | "AUDIO" | "APPLICATION";

/**
 * Describes a cached file.  The dataType is calculated
 * from the content type.
 */
export type FileData = {
  name: string,
  contentType: string,
  dataType: FileDataType
  blobUrl: string
}


/** The time file data is cached in the query client in ms. */
const DEFAULT_CACHE_TIME = (10 * 60 * 1000);


/**
 * Get the data for the file identified by the given id.
 *
 * @param fileId
 */
export const useFileData = (fileId: string) => {
  const queryKey = ["at.cns.file.data", fileId];
  const url = `${Urls.FILE_DATA}/${fileId}`;
  return useCachedFileData(fileId, queryKey, url);
};

/**
 * Download the file with the given id.
 * @param fileId
 */
export const downloadFile = (fileId: string) => {
  FetchService.performGet(`${Urls.FILE_DETAILS}/${fileId}`)
    .then((metaData: FileData) => {
      const url = `${Urls.FILE_DATA}/${fileId}`;
      FetchService.performDownload(url, metaData.name);
    });
};

export const DEFAULT_THUMBNAIL_SIZE = 256;

/**
 * Get the data for the thumbnail identified by the given id.
 *
 * @param fileId
 * @param size
 */
export const useThumbnailData = (fileId: string, size: number = DEFAULT_THUMBNAIL_SIZE) => {
  const queryKey = ["private", "data", "file", fileId, `${size}`];
  const url = `${Urls.FILE_THUMBNAIL}/${fileId}?pixelSize=${size}`;
  return useCachedFileData(fileId, queryKey, url);
};

const useCachedFileData = (fileId: string, queryKey: string[], url: string) => {
  const cacheTime = DEFAULT_CACHE_TIME;
  return useQuery(queryKey, async () => {
    const metaData: FileDetails = await FetchService.performGet(`${Urls.FILE_DETAILS}/${fileId}`, {catchableErrorCodes: [404]});
    const data = await FetchService.performGetBinary(url, {catchableErrorCodes: [404]});
    return asFileMetaData(metaData, data.blob);
  }, {
    cacheTime,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      console.log("Caching File data failed: ", error);
      return failureCount < 1;
    },
  });
};


const asFileMetaData = (metaData: FileDetails, blob: Blob): FileData => {
  const contentType = metaData.contentType?.toLocaleLowerCase() ?? FALLBACK_CONTENT_TYPE;
  const dataType = getDataType(contentType);
  return {
    contentType,
    dataType,
    name: metaData.name ?? messages.get(MessageKey.CORE.FILE.UNKNOWN)!,
    blobUrl: URL.createObjectURL(blob),
  };
};

const getDataType = (contentType: string): FileDataType => {
  if (contentType.startsWith("image/")) {
    return "IMAGE";
  } else if (contentType.startsWith("video/")) {
    return "VIDEO";
  } else if (contentType.startsWith("audio/")) {
    return "AUDIO";
  } else {
    return "APPLICATION";
  }
};
