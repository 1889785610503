/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import produce from "immer";
import {defaultsDeep, merge} from "lodash-es";
import {useCallback, useMemo, useState} from "react";

import {useFilterData} from "../../components";
import {
  CollectionModifier,
  FilterConditionBase,
  FilterConditionBaseUnion,
  FilterConditionGroup,
  FilterConditionItem,
  FilterConfiguration,
  FilterOperation,
  FilterOption,
  FilterParameter,
  FilterSelectionItem,
  ListConfiguration,
  ListFilter,
  Sorting,
} from "../../generated/api";
import {dateService} from "../DateService";
import {FilterAndModifier} from "./FilterService";

export type ListFilterModifier = (filter: ListFilter) => ListFilter;

export type UpdateFilterSorting = (sorting?: Sorting) => void;

export const useFilter = () => {
  const [listFilter, setListFilter] = useState<ListFilter>({});

  const updateFilter = useCallback((filter: FilterAndModifier) => {
    setListFilter(prevFilter => ({
      ...prevFilter,
      ...filter,
    }));
  }, [setListFilter]);

  const sort: UpdateFilterSorting = useCallback((sorting?: Sorting) => {
    setListFilter(prevFilter => {
      if (sorting?.order !== prevFilter?.sorting?.order || sorting?.property !== prevFilter?.sorting?.property) {
        return {
          ...prevFilter,
          sorting,
        };
      } else {
        return prevFilter;
      }
    });
  }, [setListFilter]);

  const page = useCallback((offset: number, limit: number) => {
    setListFilter(prevFilter => {
      if (offset !== prevFilter?.offset || limit !== prevFilter?.limit) {
        return {
          ...prevFilter,
          offset,
          limit,
        };
      } else {
        return prevFilter;
      }
    });
  }, [setListFilter]);
  return {
    listFilter,
    updateFilter,
    sort,
    page,
  };
};

export const createDefaultsFromListConfiguration = (configuration: ListConfiguration): ListFilter => {
  return {
    sorting: {
      property: configuration.defaultSortColumn,
      order: configuration.defaultSortOrder,
    },
  };
};

/**
 * @param listFilter the list filter to apply the defaults on
 * @param filterParameter the filter view param
 * @param viewListFilterDefaults filter defaults
 * filter configuration
 * (use setViewDefaults if not available at beginning)
 */
export const useDefaultsOnFilter = (
  listFilter: ListFilter,
  filterParameter: FilterParameter | undefined,
  viewListFilterDefaults: ListFilter,
) => {
  const filterConfigurationId = filterParameter?.id;
  const filterData = useFilterData(filterConfigurationId);
  const configDefaults = filterData?.filterConfiguration.listFilterDefaults;

  return useMemo(() => {
    if (configDefaults || viewListFilterDefaults) {
      // create object from prev and fill up missing values with configDefaults
      return produce(listFilter, next => merge({}, defaultsDeep(viewListFilterDefaults, configDefaults), next));
    }
    return listFilter;
  }, [configDefaults, listFilter, viewListFilterDefaults]);
};

export const useModifierOnFilter = (listFilter: ListFilter, filterModifier?: ListFilterModifier): ListFilter => {
  return filterModifier ? filterModifier(listFilter) : listFilter;
};

/**
 * @param listFilter the ListFilter
 */
export const isListFilterValid = (
  listFilter: ListFilter
): boolean => {
  return listFilter.sorting?.order != null && listFilter.sorting?.property != null;
};

export const getMatchingFilterOperation = (
  selectedFilter: FilterSelectionItem,
  filterOption: FilterOption | undefined
): FilterOperation | undefined => {
  return filterOption && [filterOption.defaultFilterOperation!, ...(filterOption.filterOperations ?? [])]
    .find(fop => fop.filterValueType === selectedFilter.filterValueType
      && (!selectedFilter.filterUserOperation || fop.filterUserOperation === selectedFilter.filterUserOperation));
};

export const createFilterFromFilterSelection = (
  selectedFilters: FilterSelectionItem[],
  filterOptions: FilterOption[],
  viewId: string | undefined,
  filterConfiguration: FilterConfiguration | undefined
): FilterAndModifier => {
  let rewriteCondition = (condition: FilterConditionItem) => condition;
  const viewSpecificFilterAdaptation = filterConfiguration?.viewSpecificFilterAdaptations?.find(vsfa => vsfa.viewId === viewId);

  if (viewSpecificFilterAdaptation?.filterOptionPropertyRewriteRules?.length) {
    const rewriteRules = viewSpecificFilterAdaptation.filterOptionPropertyRewriteRules;
    rewriteCondition = (condition: FilterConditionItem) => {
      const rule = rewriteRules.find(r => r.pattern && r.replacement && condition.property?.match(r.pattern));
      if (rule) {
        const property = condition.property!.replace(new RegExp(rule.pattern!), rule.replacement!);
        console.debug("applied rule", rule, "to", condition, "new property value", property);
        return {
          ...condition,
          property,
        };
      }
      return condition;
    };
  }

  const selectedFiltersAndOperations = selectedFilters
    .filter(sf => sf.values && sf.values.length !== 0)
    .map(sf => ({
      values: sf.values,
      filterOperation: getMatchingFilterOperation(sf, filterOptions.find(fo => fo.id === sf.filterOptionId)),
    }))
    .filter(sf => !!sf.filterOperation && !!sf.values);
  console.log("createFilter", selectedFiltersAndOperations);
  const activeFilter: FilterConditionGroup = {
    conditionType: "GROUP",
    logicalOperator: "AND",
    filterConditions: selectedFiltersAndOperations
      .filter(filter => filter.values && filter.filterOperation?.filterCondition)
      .map(f => setFilterConditionItemValues(f.filterOperation!.filterCondition!, f.values!, rewriteCondition))
      .map(f => f as FilterConditionBaseUnion),
  };

  const collectionModifiers = selectedFiltersAndOperations
    .filter(filter => filter.values && filter.filterOperation?.collectionModifiers)
    .flatMap(filter => filter.filterOperation!.collectionModifiers!.map(cfc => ({
      propertyOfCollectionToFilterOn: cfc.propertyOfCollectionToFilterOn,
      listFilter: cfc.listFilter,
      filter: setFilterConditionItemValues(cfc.listFilter!.filter!, filter.values!, rewriteCondition),
    })))
    .reduce((allModifiers, curr) => {
      let modifierForProperty = allModifiers
        .find(v => v.propertyOfCollectionToFilterOn === curr.propertyOfCollectionToFilterOn);
      if (!modifierForProperty) {
        modifierForProperty = {
          propertyOfCollectionToFilterOn: curr.propertyOfCollectionToFilterOn,
          listFilter: {
            ...curr.listFilter,
            filter: {
              conditionType: "GROUP",
              logicalOperator: "AND",
              filterConditions: [],
            },
          },
        };
        allModifiers.push(modifierForProperty);
      }
      (modifierForProperty.listFilter!.filter! as FilterConditionGroup).filterConditions!
        .push(curr.filter as FilterConditionBaseUnion);
      return allModifiers;
    }, [] as CollectionModifier[]);

  return {
    filter: activeFilter,
    collectionModifiers,
  };
};

function isFilterConditionItem(filterConditionBase: FilterConditionBase): filterConditionBase is FilterConditionItem {
  return filterConditionBase.conditionType === "ITEM";
}

function isFilterConditionGroup(filterConditionBase: FilterConditionBase): filterConditionBase is FilterConditionGroup {
  return filterConditionBase.conditionType === "GROUP";
}

function setFilterConditionItemValues(
  filterConditionBase: FilterConditionBase,
  values: string[],
  rewriteItem: (condition: FilterConditionItem) => FilterConditionItem
): FilterConditionBase {
  if (isFilterConditionItem(filterConditionBase)) {
    const {
      operator,
      valueType,
    } = filterConditionBase;

    if (values[0] && valueType === "DATE") {
      const value = dateService.parse(values[0])!;
      if (operator === "IS_LESS" || operator === "IS_LESS_EQUALS") {
        value.setHours(23, 59, 59, 999);
        values = [dateService.toISOString(value)];
      } else if (operator === "IS_GREATER" || operator === "IS_GREATER_EQUALS") {
        value.setHours(0, 0, 0, 0);
        values = [dateService.toISOString(value)];
      }
    }

    return rewriteItem({
      ...filterConditionBase,
      values,
    });
  } else if (isFilterConditionGroup(filterConditionBase)) {
    return {
      ...filterConditionBase,
      filterConditions: filterConditionBase.filterConditions!
        .map(condition => setFilterConditionItemValues(condition, values, rewriteItem)),
    } as FilterConditionGroup;
  } else {
    console.error("Invalid filter condition base", filterConditionBase);
    throw Error("Invalid filter condition base");
  }
}
