/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchService} from "@icm/core-common";

import {EntityEvent} from "../generated/api";

class EntityEventApi {
  public static getEntityEvents(entityType: string, id: string): Promise<EntityEvent[]> {
    return FetchService.performGet(`entity/entityEvents/${entityType}/${id}`);
  }
}

export {EntityEventApi};
