/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ModuleRegistrationOptions} from "@icm/core-common";

import packageInfo from "./src/generated/package.clone.json";
import {incidentViewerCompositeDescriptor} from "./src/pages/IncidentViewerComposite";

export * from "./src/api";

export const incidentCommonModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  providedViews: [incidentViewerCompositeDescriptor],
};
