/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {AbstractDateTimeComponent, dateService, ErrorBoundary, IDateTimeComponentProps, LabelWithFieldStateMarker} from "@icm/core-common";
import {TextField, TextFieldProps} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import * as React from "react";


export class DateComponent extends AbstractDateTimeComponent {

  constructor(props: IDateTimeComponentProps) {
    super(props, dateService.getMomentDateFormats().date["SHORT"]);
  }

  private static removeTime(date: Moment | null) {
    return date ? moment(dateService.removeTime(date.toDate())) : null;
  }

  protected renderDateTimeComponent(value: Date | Moment | null | undefined, format: string, placeholder: string) {
    return (
      <ErrorBoundary>
        <DatePicker<Moment> disabled={this.props.disabled}
                            minDate={this.props.minDate ? moment(this.props.minDate) : undefined}
                            maxDate={this.props.maxDate ? moment(this.props.maxDate) : undefined}
                            value={value ? moment(value) : null}
                            inputFormat={format}
                            onChange={(newValue: moment.Moment | null) => this.handleChange(DateComponent.removeTime(newValue))}
          // TODO PICM-1455 masked input doesn't work right now
                            disableMaskedInput
          // mask={this.createMaskFromFormat()}
          // acceptRegex={/[a-z0-9]/gi}
          // TODO PICM-1455 temporary workaround until problem is fixed by material-ui
                            InputAdornmentProps={{
                              sx: {padding: 2},
                            }}
                            ignoreInvalidInputs={true}
                            onOpen={this.props.onFocus}
                            renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
                              <TextField {...props}
                                         variant="standard"
                                         label={(
                                           <LabelWithFieldStateMarker label={this.props.label}
                                                                      badgeColor={this.props.badgeColor}
                                                                      required={this.props.required}
                                                                      error={this.props.error}
                                           />
                                         )}
                                         helperText={this.props.helperText}
                                         fullWidth
                                         error={!this.state.valid || this.props.error}
                                         onFocus={this.props.onFocus}
                                         onBlur={event => this.handleBlur(event, format)}
                                         InputProps={{
                                           ...props.InputProps,
                                           "aria-label": `${this.props.label}`,
                                         }}
                              />
                            )}

        />
      </ErrorBoundary>
    );
  }
}
