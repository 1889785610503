/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmIconType} from "@icm/core-common";
import {IconButtonComponent, OutlinedIconButton} from "@icm/core-web";
import {Box, Grid, InputAdornment, TextField} from "@mui/material";
import React, {useState} from "react";

type ActivityStreamMessageEditorButton = {
  label: string
  icon: IcmIconType | JSX.Element
  onClick: () => void
  badgeContent?: string | number
}

type ActivityStreamMessageEditorProps = {
  placeholder: string
  submitLabel: string
  // submit on enter, make line breaks using "shift+enter"
  // @default false
  submitOnEnter?: boolean
  additionalButtons?: ActivityStreamMessageEditorButton[]
  // if true, the buttons are rendered outside the textfield
  // @default false
  separateButtons?: boolean
  onSubmit: (message: string) => void

  // the editor is also disabled as long as no message is entered
  // @default false
  disabled?: boolean
}


export const ActivityStreamMessageEditor = (
  {placeholder, submitLabel, onSubmit, additionalButtons, submitOnEnter = false, separateButtons = false, disabled = false}: ActivityStreamMessageEditorProps
) => {
  const [messageBody, setMessageBody] = useState("");
  const submitDisabled = messageBody.trim().length === 0 || disabled;

  const submit = () => {
    if (!submitDisabled) {
      onSubmit(messageBody);
      setMessageBody("");
    }
  };
  const trySubmit = (event:  React.KeyboardEvent) => {
    const enter = event.code === "Enter" || event.code === "NumpadEnter";
    if (submitOnEnter && enter && !event.shiftKey) {
      submit();
      event.preventDefault();
    }
  };

  return (
    <Box>
      <Grid container
            justifyContent="space-between"
            direction="row"
            wrap="nowrap"
            alignItems="center"
            spacing={1}
      >
        <Grid item flex="1 auto">
          <TextField value={messageBody}
                     multiline
                     fullWidth
                     autoFocus
                     minRows={1}
                     maxRows={3}
                     variant="outlined"
                     onChange={event => setMessageBody(event.target.value)}
                     onKeyDown={event => trySubmit(event)}
                     placeholder={placeholder}
                     InputProps={{
                       endAdornment: additionalButtons && !separateButtons ? (
                         <InputAdornment position="end" sx={{gap: 1, paddingRight: 0.5}}>
                           {additionalButtons?.map((additionalButton, index) => (
                             <IconButtonComponent key={index}
                                                  tooltip={additionalButton.label}
                                                  tooltipPlacement="top"
                                                  padding="medium"
                                                  IconButtonProps={{edge: "end"}}
                                                  icon={additionalButton.icon}
                                                  handleClick={() => additionalButton.onClick()}
                                                  BadgeProps={additionalButton.badgeContent ? {
                                                    badgeContent: additionalButton.badgeContent,
                                                    max: 9,
                                                    color: "primary",
                                                  } : undefined}
                             />
                           ))}
                         </InputAdornment>
                       ) : undefined,
                     }}
          />
        </Grid>
        {separateButtons && additionalButtons?.map((additionalButton, index) => (
          <Grid key={index} item flex="0 1" sx={{paddingRight: 1}}>
            <OutlinedIconButton icon={additionalButton.icon}
                                tooltip={additionalButton.label}
                                tooltipPlacement="top"
                                onClick={() => additionalButton.onClick()}
            />
          </Grid>
        ))}
        <Grid item flex="0 1">
          <OutlinedIconButton icon="send"
                              disabled={submitDisabled}
                              tooltip={submitLabel}
                              tooltipPlacement="top"
                              onClick={submit}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
