/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import {useMemo} from "react";
import * as React from "react";

import {LoadingSpinner} from "../../../loading";
import {DataTableContext, DataTableContextDefinition, DataTableProps, NoEntriesRow, useDataTableStyles} from "../common";
import {ClassicTableHeaderRow} from "./ClassicTableHeaderRow";
import {ClassicTableRow} from "./ClassicTableRow";
import {Pagination, PaginationProps} from "./Pagination";

export type ClassicDataTableProps<T> = DataTableProps<T> & {
  currentPage?: T[]
  paginationProps?: PaginationProps
  isLoading: boolean
  // @default true
  showColumnHeaders?: boolean
  label?: string,
  readOnly?: boolean
}
export const ClassicDataTable = <T extends unknown>({
  currentPage, isLoading, paginationProps, showColumnHeaders = true, tableConfig, label, readOnly,
}: ClassicDataTableProps<T>) => {
  const classes = useDataTableStyles();

  const context: DataTableContextDefinition = useMemo(() => ({
    variant: "CLASSIC",
    tableConfig,
    actionBarOffset: 0,
  }), [tableConfig]);

  return (
    <DataTableContext.Provider value={context}>
      <Table size="small" className={classes.classicTable} aria-label={label}>
        {showColumnHeaders && (
          <TableHead className={classes.tableHead}>
            <ClassicTableHeaderRow columns={tableConfig.dataColumns} />
          </TableHead>
        )}
        <TableBody className={classes.tableBody}>
          {currentPage && (
            currentPage.length > 0 ? currentPage.map((row: T, rowIndex: number) => (
              <ClassicTableRow key={rowIndex}
                               row={row}
                               rowIndex={rowIndex}
                               dataColumns={tableConfig.dataColumns}
                               readOnly={readOnly}
              />
            )) : (
              <NoEntriesRow />
            )
          )}
        </TableBody>
      </Table>
      {paginationProps && <Pagination {...paginationProps} />}
      {isLoading && (
        <LoadingSpinner />
      )}
    </DataTableContext.Provider>
  );
};
