/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {EnhancedStore} from "@reduxjs/toolkit";

import {customConfigureStore, RESET_STORE_TYPE} from "./configureStore";

class StoreService {

  private static readonly INSTANCE = new StoreService();

  public static getInstance() {
    return this.INSTANCE;
  }

  // This type is wrong but difficult to define, see: https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
  private appStore: EnhancedStore<any, any, any>;

  public resetStore() {
    if (this.appStore) {
      this.appStore.dispatch({type: RESET_STORE_TYPE});
    }
  }

  public createStore() {
    this.appStore = customConfigureStore();
  }

  public get store(): typeof this.appStore {
    return this.appStore;
  }

}

const storeService = StoreService.getInstance();

export {storeService as StoreService};
