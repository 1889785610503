/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CHAT_VIEW_TYPE, INITIAL_ROOM} from "@icm/chat-common";
import {MessageKey, ParameterUtilities, ViewDescriptor} from "@icm/core-common";
import {isString} from "lodash-es";
import React from "react";

export type ChatViewModel = {
  roomJid?: string,
  printRequested: boolean,
}


export const webChatViewDescriptor: ViewDescriptor<ChatViewModel> = {
  viewType: CHAT_VIEW_TYPE,
  view: React.lazy(() => import("./ChatView")),
  initializeViewModelData: (viewParameters) => {
    const roomJid = ParameterUtilities.getResolvedParameterValue(INITIAL_ROOM, viewParameters, isString);
    return {
      roomJid,
      printRequested: false,
    };
  },
  getTitle: (_viewModel, getMessage) => getMessage(MessageKey.CHAT.TITLE),
  getViewActionDescriptors: (_viewModel, getMessage) => ({
    CREATE_ROOM: {
      icon: "create_chat",
      title: getMessage(MessageKey.CHAT.LABEL.CREATE_CHAT),
    },
    OPEN_CHATROOM: {
      icon: "find_chat",
      title: getMessage(MessageKey.CHAT.LABEL.AVAILABLE_ROOMS),
    },
  }),
};
