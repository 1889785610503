/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import produce from "immer";
import {
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";

import {ViewContext} from "../components";
import {
  BaseViewModelData,
  ICoreApplicationState,
  updateViewData,
  ViewModel,
} from "../store";
import {
  ResolvedParameterList,
  IdGenerator,
} from "../util";
import {ViewDescriptor} from "./ViewDescriptor";
import {useAvailableViewDescriptors} from "./ViewHooks";

const isUpdateFunction = <ViewModelData extends BaseViewModelData>(
  newData: ViewModelData | ((prevData: ViewModelData) => ViewModelData),
): newData is (prevData: ViewModelData
) => ViewModelData => typeof newData === "function";

const isViewModelObject = <ViewModelData extends BaseViewModelData>(
  newData: any,
): newData is ViewModelData => typeof newData === "object" && newData;

export function useViewModelForTab<ViewModelData extends BaseViewModelData>(
  tabGroupId: string | undefined,
  tabId: string | undefined
): {
  viewModel: ViewModel<ViewModelData>,
  viewModelData: ViewModelData,
  setViewModelData: (newData: (ViewModelData | ((prevData: ViewModelData) => ViewModelData))) => void
} {
  const dispatch = useDispatch();
  const availableViewDescriptors = useAvailableViewDescriptors();

  const viewModel = useSelector(
    ({uiState}: ICoreApplicationState) => {
      if (uiState.perspective?.perspectiveType === "TABBED") {
        if (tabGroupId && tabId) {
          return uiState.perspective
            ?.tabGroups.find(tg => tg.id === tabGroupId)
            ?.tabs.find(t => t.id === tabId)
            ?.viewModel;
        } else {
          throw new Error("provided no tabId/tabGroupId in a tabbed context");
        }
      } else if (uiState.perspective?.perspectiveType === "SINGLE_VIEW") {
        return uiState.perspective.view.viewModel;
      } else {
        throw new Error("Missing perspective.");
      }
    },
  ) as ViewModel<ViewModelData>;

  const dataRef = useRef(viewModel);
  dataRef.current = viewModel;

  const viewDescriptor = useMemo(() => {
    return viewModel ? availableViewDescriptors[viewModel.viewType] : undefined;
  }, [availableViewDescriptors, viewModel]);

  const setViewModelData = useCallback((newData: ViewModelData | ((prevData: ViewModelData) => ViewModelData)) => {
    let nextData: ViewModelData | undefined = undefined;
    if (isUpdateFunction(newData) && dataRef.current) {
      nextData = newData(dataRef.current.viewModelData);
    } else if (isViewModelObject(newData)) {
      nextData = newData;
    }
    if (viewDescriptor && dataRef.current && nextData) {
      const nextViewModel = updateViewModel(viewDescriptor, nextData, dataRef.current);
      dispatch(updateViewData({tabId, tabGroupId, newViewModel: nextViewModel}));
    }
  }, [dispatch, tabGroupId, tabId, viewDescriptor]);

  if (!viewModel) {
    throw new Error("The view model couldn't be found.");
  }

  return {
    viewModel,
    viewModelData: viewModel?.viewModelData,
    setViewModelData,
  };
}

export function useViewModel<ViewModelData extends BaseViewModelData>() {
  const viewContext = useContext(ViewContext);
  if (!viewContext) {
    throw Error("trying to use view model outside view context");
  }
  const {tabGroupId, tabId} = viewContext;

  return useViewModelForTab<ViewModelData>(tabGroupId, tabId);
}

export function createViewModel<T extends BaseViewModelData>(viewDescriptor: ViewDescriptor<T>, viewId: string,
                                                             parameters: ResolvedParameterList = {}, viewLabel?: string): ViewModel<T> {
  console.groupCollapsed(`Creating ViewModel for ${viewDescriptor.viewType}`);
  console.log("Initializing ViewModelData with parameters", parameters);
  const initialViewModelData = viewDescriptor.initializeViewModelData(parameters);
  console.log("Initialized ViewModelData", initialViewModelData);
  const uniqueHash = `${viewDescriptor.viewType}-${viewDescriptor.createUniqueHash?.(initialViewModelData) ?? ""}`;
  console.log("Calculated unique hash", uniqueHash);
  const editModelId = viewDescriptor.getEditModelId?.(initialViewModelData);
  console.groupEnd();
  return {
    id: IdGenerator.randomNanoId(),
    viewType: viewDescriptor.viewType,
    viewModelData: initialViewModelData,
    uniqueHash,
    viewId,
    viewLabel,
    editModelId,
  };
}

export function updateViewModelDataByViewParameters<ViewModelData extends BaseViewModelData>(
  viewDescriptor: ViewDescriptor<ViewModelData>, viewModelData: ViewModelData, parameters: ResolvedParameterList = {}
): ViewModelData {
  if (viewDescriptor.updateViewModelData) {
    // use immer produce to keep immutability of viewModelData
    return produce(viewModelData, (draft: ViewModelData) => viewDescriptor.updateViewModelData!(draft, parameters));
  } else {
    // no updateViewModelData function => leave it as-is
    return viewModelData;
  }
}

function updateViewModel<ViewModelData extends BaseViewModelData>(
  viewDescriptor: ViewDescriptor<ViewModelData>,
  newData: ViewModelData,
  existingViewModel: ViewModel<ViewModelData>
): ViewModel<ViewModelData> {
  const uniqueHash = `${viewDescriptor.viewType}-${viewDescriptor.createUniqueHash?.(newData) ?? ""}`;
  const editModelId = viewDescriptor.getEditModelId?.(newData);
  return {
    ...existingViewModel,
    viewModelData: newData,
    uniqueHash,
    editModelId,
  };
}

export const isViewingEditingView = <ViewModelData extends BaseViewModelData>(viewModel?: ViewModel<ViewModelData>): boolean => {
  const viewHasReadOnlyProperty = "readOnly" in (viewModel?.viewModelData ?? {});
  const readOnlyModeOff = viewModel?.viewModelData["readOnly"] === false;
  return !!viewModel?.editModelId
    && (!viewHasReadOnlyProperty || (viewHasReadOnlyProperty && readOnlyModeOff));
};
