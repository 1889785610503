/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  useOpenView,
  useService,
  IdGenerator,
} from "@icm/core-common";
import {
  Feature,
  Geometry,
} from "geojson";
import {useCallback} from "react";

import {ValueWithIndex} from "./types";


export const useShowFeatureOnMap = (mapViewId?: string) => {
  const dateService = useService("DATE");

  const openView = useOpenView();

  return useCallback((eventIndexValues: ValueWithIndex[], label?: string) => {
    if (mapViewId) {
      const num = eventIndexValues.length;
      const features: Feature[] = eventIndexValues.map((iv, i) => {
        const value = iv.value as any;
        const indexLabel = iv.indexLabel;
        const userLabel = iv.userLabel;
        const timeStampLabel = dateService.formatDateTime(iv.timeStampLabel!, "SHORT", "MEDIUM");
        const age = num > 1 ? (num - 1 - i) / (num - 1) : 0;
        if (typeof value === "object") {
          // we need to set a unique ID in the case that the user focuses on the same objects again
          if (value && "type" in value && "coordinates" in value) {
            // assume a geometry
            return {type: "Feature", geometry: value as Geometry, properties: {age, indexLabel, userLabel, timeStampLabel, label, _id: IdGenerator.randomUUID()}};
          } else if (value && "type" in value && "geometry" in value) {
            // assume a fullblown feature
            return {...value, properties: {...(value.properties || {}), age, indexLabel, userLabel, timeStampLabel, _id: IdGenerator.randomUUID()}};
          } else if (value && Array.isArray(value) && value.length > 0 && typeof value[0] === "number") {
            return {
              type: "Feature",
              geometry: {type: "Point", coordinates: value},
              properties: {age, indexLabel, userLabel, timeStampLabel, _id: IdGenerator.randomUUID()},
            };
          }
        }
        return undefined;
      }).filter(feature => feature);
      openView(mapViewId, {
        genericParameters: [{
          key: "features",
          value: JSON.stringify(features),
        }],
      });
    }
  }, [dateService, mapViewId, openView]);
};
