/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {isArray} from "lodash-es";

/**
 * Definition of a type guard. Use it in method signatures to make the code more readable.
 */
export type TypeGuard<T> = (value: any) => value is T;

/**
 * Check if the given value is a non empty string
 * @param value
 */
export const isNonEmptyString = (value: any): value is string => {
  return typeof value === "string" && value.length > 0;
};

/**
 * Check if the given value is an array of strings.
 * Note: if the string array contains undefined, use isLenientStringArray
 *
 * @param value
 */
export const isStringArray = (value: any): value is string[] => {
  return isArray(value) && !value.find(f => (typeof f !== "string"));
};
