/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ArrayUtilities, RowStatus, SecurityService} from "@icm/core-common";

import {isFromMe} from "../../store";
import {ObjectWithUpdateInformation} from "../../util";

export function getRowStatus<T extends object>(row: ObjectWithUpdateInformation<T>, securityService: SecurityService): RowStatus | undefined {
  if (row.listState === "FILTER_REMOVED") {
    return "FILTER_REMOVED";
  } else {
    const lastUpdate = ArrayUtilities.last(row.updateInfo?.objectUpdates);
    if (lastUpdate && lastUpdate.type !== "COMMUNICATION" && !isFromMe(lastUpdate, securityService)
      && (!row.updateInfo?.showUpdatesSince || lastUpdate.date > row.updateInfo.showUpdatesSince)) {
      return `${lastUpdate.type}D`;
    }
  }
  return undefined;
}

export function isVisibleRow<T extends object>(row: ObjectWithUpdateInformation<T>, securityService: SecurityService): boolean {
  const rowState = getRowStatus(row, securityService);
  return (!(rowState === "ARCHIVED" || rowState === "DELETED"));
}
