/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

export class FetchServiceException extends Error {
  // whether the fetch was aborted on the client side
  public aborted: boolean;
  // response, if there was one
  public response?: Response;
  // cause of error (if no response)
  public cause?: Error;

  constructor(message: string, aborted: boolean, response?: Response, cause?: Error) {
    super(message);
    this.aborted = aborted;
    this.response = response;
    this.cause = cause;
  }
}
