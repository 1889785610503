/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMarkObjectAsSeen} from "@icm/activitystream-common";
import {
  FieldHighlightMap,
  FormGenerator,
  getNamedComponentFactory,
  LOADER,
  MessageKey,
  TEXT,
  useFeedbackBanners,
  useMessages,
  useViewModel,
} from "@icm/core-common";
import {useBrowserPrinter} from "@icm/core-web";
import * as React from "react";
import {forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState} from "react";

import {Entity} from "../generated/api";
import {useEntityConfigurationAndRelated} from "../hooks";
import {EntityEditModel, SubmitStrategy} from "../store";
import {
  invalidateFormConfiguration,
  useEntityEditViewDispatcher,
  useFormEntity,
  useGenericFormConfiguration,
  useReloadEntityEditModel,
} from "../views";
import {useEntityFieldHighlights, useFieldHighlightBoxInHeader} from "./EntityFieldHighlights";

/** Use this policy to only refresh entity data. */
export const REFRESH_LAZY = "LAZY";

/** Use this policy to flush caches (e.g. FormConfiguration) */
export const REFRESH_EAGER = "EAGER";

export type RefreshPolicy = typeof REFRESH_LAZY | typeof REFRESH_EAGER;

export type EntityEditModelComponentProps = {
  editModel: EntityEditModel,
  formVariant: string,
  readOnly: boolean
  printRequested?: boolean,
  ruleContextAddition?: any,

  // all fields that were updated since the given date will be highlighted
  highlightUpdatesSince?: Date,
  onFormValidationChange?: (isValid: boolean) => void,

  /** callback indicating the actual variant name being used, for support of dynamic default form variant names */
  onFormVariantName?: (name: string) => void,

  /**
   * What should happen after save. Will be evaluated only if the form is writable.
   */
  submitStrategy: SubmitStrategy,

  refreshPolicy: RefreshPolicy
}

export interface EntitySaveParams {
  completeTaskId?: string,
  completeTaskUserActionId?: string,
}

/**
 * Ref that allows to control functions of the EntityEditModelComponent from outside
 */
export interface EntityEditModelComponentRef {
  save: (parameters?: EntitySaveParams) => Promise<void>

  refresh: () => void
}

export const EntityEditModelComponent = forwardRef((props: EntityEditModelComponentProps, ref: Ref<EntityEditModelComponentRef>) => {
  const {
    editModel,
    formVariant,
    highlightUpdatesSince,
    onFormValidationChange,
    onFormVariantName,
    printRequested,
    readOnly,
    refreshPolicy,
    ruleContextAddition,
    submitStrategy,

  } = props;
  const {
    entity,
    currentEntity,
    propertyChanges,
    processing,
  } = editModel;

  const {entityConfigurations, entityConfiguration} = useEntityConfigurationAndRelated(entity.type!);
  const {submitEntityEditModel, updateEntityEditModel} = useEntityEditViewDispatcher(entity, entityConfigurations);

  const reloadEntity = useReloadEntityEditModel(entity);

  const genericFormConfiguration = useGenericFormConfiguration(entity, formVariant, entity.id, entityConfigurations);
  const currentFormEntity = useFormEntity(propertyChanges, entity, entityConfiguration);

  const {fieldHighlights, markAllHighlightsAsSeen, unHighlightField} = useEntityFieldHighlights(entity, highlightUpdatesSince);
  const markAsSeen = useMarkObjectAsSeen();
  useFieldHighlightBoxInHeader(fieldHighlights);
  const {getMessage} = useMessages();

  useEffect(() => {
    // mark as seen, when there's field highlights, but no outdated ones
    const highlights = Object.values(fieldHighlights);
    if (entity.id && entity.type && highlights.length > 0 && highlights.filter(i => i?.outdated).length === 0) {
      markAsSeen(entity.type, entity.id);
    }
  }, [entity, fieldHighlights, markAsSeen]);

  useEffect(() => {
    if (genericFormConfiguration && genericFormConfiguration.data?.variantName) {
      onFormVariantName?.(genericFormConfiguration.data.variantName);
    }
  }, [genericFormConfiguration, onFormVariantName]);

  const updatedHighlightsAndReload = useCallback(()=> {
    if (refreshPolicy === REFRESH_EAGER) {
      invalidateFormConfiguration(entity.type!, formVariant, entity.id)
        .then(_r => reloadEntity())
        .finally(markAllHighlightsAsSeen);
    } else {
      reloadEntity();
      markAllHighlightsAsSeen();
    }
  }, [markAllHighlightsAsSeen, reloadEntity, refreshPolicy, entity.type, entity.id, formVariant]);

  useFeedbackBannerOnEntityUpdates(entity, fieldHighlights,  updatedHighlightsAndReload);

  useImperativeHandle(ref, () => ({
    save(parameters?: EntitySaveParams) {
      return submitEntityEditModel(submitStrategy, genericFormConfiguration.data, parameters);
    },
    refresh() {
      updatedHighlightsAndReload();
    },
  }));

  const printer = useBrowserPrinter();
  const {setViewModelData} = useViewModel();
  const [readyForPrint, setReadyForPrint] = useState<boolean>(false);

  useEffect(() => {
    if (genericFormConfiguration && currentFormEntity) {
      setReadyForPrint(true);
    }
  }, [genericFormConfiguration, currentFormEntity]);

  useEffect(() => {
    if (readyForPrint && printRequested) {
      printer.print();
    }
  }, [readyForPrint, printRequested, printer, setViewModelData]);

  const Loader = getNamedComponentFactory(LOADER) || ((loaderProps) => <span>{loaderProps.children || getMessage(MessageKey.CORE.LOADING)}</span>);

  if (!entity.type) {
    const Text = getNamedComponentFactory(TEXT) || ((spanProps) => <span>{spanProps.children}</span>);
    return <Text>No entity type specified.</Text>;
  } else if (genericFormConfiguration.data && currentFormEntity) {
    const submitButtonConfig = {
      onSubmit: () => submitEntityEditModel(submitStrategy),
      submitIcon: "save",
      submitLabel: getMessage(`${currentFormEntity.type?.toLowerCase()}.label.save`),
      iconOnly: true,
    };
    return (
      <>
        <FormGenerator formConfig={genericFormConfiguration.data}
                       entity={currentFormEntity}
                       persistedEntity={currentEntity}
                       onChange={updateEntityEditModel}
                       readOnly={readOnly}
                       useSimpleValues={false}
                       highlightedFields={fieldHighlights}
                       unHighlightField={unHighlightField}
                       ruleContextAddition={ruleContextAddition}
                       renderActionsInHeader={true}
                       submitButtonConfiguration={submitButtonConfig}
                       onFormValidationChange={onFormValidationChange}

        />
        {processing ? <Loader message={getMessage(MessageKey.CORE.PROCESSING)} /> : <></>}
      </>
    );
  } else if (genericFormConfiguration.isFetching) {
    return (
      <Loader />
    );
  } else {
    const Text = getNamedComponentFactory(TEXT) || (() => <></>);
    return <Text />;
  }
});


function useFeedbackBannerOnEntityUpdates(entity: Entity, fieldHighlights: FieldHighlightMap, reloadEntity: () => void) {
  const {getMessage} = useMessages();
  const {openFeedbackBanner, closeFeedbackBanner} = useFeedbackBanners();
  useEffect(() => {
    // show feedback banner on field updates
    if (entity?.id && entity.type) {
      const outdatedValues = Object.values(fieldHighlights).filter(value => value?.outdated);
      if (outdatedValues.length > 0) {
        openFeedbackBanner({
          key: `Activity:${entity?.id}`,
          title: getMessage(`${entity.type.toLowerCase()}.activitystream.updated`),
          variant: "WARNING",
          duration: "INDEFINITE",
          closeActions: [
            {
              label: getMessage(MessageKey.CORE.REFRESH),
              action: () => {
                reloadEntity();
              },
            }, {
              label: getMessage(MessageKey.CORE.IGNORE),
              action: () => {
                // NO-OP
              },
            },
          ],
        });
      }
    }
  }, [entity?.id, entity?.type, reloadEntity, fieldHighlights, getMessage, openFeedbackBanner, closeFeedbackBanner]);

  // clean up open feedback banners on unmount
  useEffect(() => {
    if (entity?.id) {
      return () => closeFeedbackBanner(`Activity:${entity?.id}`);
    }
    return () => {
      // NO-OP
    };
  }, [closeFeedbackBanner, entity?.id]);
}
