/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React from "react";

import {StaticListBasedDaoOptions, UrlBasedDaoOptions, useDao} from "../../../dao";
import {ReadOnlyComponent} from "../readonly/ReadOnlyComponent";
import {AutoCompleteFormComponentProps} from "./AutoCompleteComponent";
import {useAutoComplete, useValues, useValuesNotInOptions} from "./AutoCompleteHooks";


export type AutoCompleteComponentInternalProps<T> = {
  isBoundByValue: boolean
  withVersion: boolean
  daoOptions: UrlBasedDaoOptions | StaticListBasedDaoOptions<T>
}

export const ReadOnlyAutoCompleteComponent = <T extends unknown>(props: AutoCompleteFormComponentProps<T> & AutoCompleteComponentInternalProps<T>) => {
  const {daoOptions, withVersion, isBoundByValue} = props;
  const dao = useDao(daoOptions);
  const [values, valuesNotInOptions] = useValues(props.value || [], withVersion, isBoundByValue, [], dao);
  const [optionsFromValues] = useValuesNotInOptions(withVersion || isBoundByValue ? [] : valuesNotInOptions, false, isBoundByValue, dao);

  const autocomplete = useAutoComplete(props);
  const displayValues: string[] = [];
  if (props.possibleValuesUrl) {
    autocomplete.allOptions.forEach((option => {
      props.value?.forEach(value => {
        if (option.value === value) {
          displayValues.push(option.valueDisplay);
        }
      });
    }));
  }

  let renderValues;
  if (displayValues.length > 0) {
    renderValues = displayValues.map((value) => {
      return {valueDisplay: value};
    });
  } else if (withVersion || isBoundByValue) {
    renderValues = values;
  } else {
    renderValues = optionsFromValues;
  }
  const text = renderValues.map(o => o.valueDisplay).join("\n");
  return <ReadOnlyComponent label={props.label} text={text} badgeColor={props.badgeColor} />;
};
