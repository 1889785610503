/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {downloadFile, ImageViewerDialogProvider, useCommonImageViewerDialog, Image} from "@icm/core-common";
import {Dialog, DialogContent} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";

import {IconButtonComponent} from "../form";

export type ImageViewerProps = {
  fileId: string,
  onClose: () => void
}

export const ImageViewerDialog = (props: ImageViewerProps) => {
  const {fileId, onClose} = props;
  return (
    <Dialog open={true} onClose={onClose} maxWidth='lg' fullWidth={true}>
      <Box padding={1} display="flex" justifyContent="flex-end">
        <IconButtonComponent handleClick={e => downloadFile(fileId)} icon="cloud_download" />
        <IconButtonComponent handleClick={e => onClose()} icon="close" />
      </Box>
      <DialogContent>
        <Image fileId={fileId} />
      </DialogContent>
    </Dialog>
  );
};

export const useWebImageViewerDialog = (): ImageViewerDialogProvider => useCommonImageViewerDialog(ImageViewerDialog);
