/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {DynamicAttributeValue, ExpressionEvaluationService, GenericFormField, PossibleValue} from "@icm/core-common";
import {cloneDeep} from "lodash-es";

import {AttributeConfiguration, Entity, EntityConfiguration, EntityPropertyValue} from "../generated/api";

export type PropertyNameAndValue = {
  propertyName: string,
  propertyValue: any,
}

/**
 * This service combines all methods that are used for bridging between property values of auto-complete
 * components (id, value, or object combining both) and proper dynamic attributes.
 */
class EntityReferenceValueService {
  public static INSTANCE = new EntityReferenceValueService();

  public isStaticReferenceField(entityConfiguration: EntityConfiguration, field: GenericFormField) {
    const attributeConfiguration = this.getAttributeConfigurationFromField(entityConfiguration, field);
    return !!attributeConfiguration && this.isStaticReferenceAttribute(attributeConfiguration!);
  }

  public isStaticReferenceAttribute(attributeConfiguration: AttributeConfiguration) {
    return !!attributeConfiguration.possibleValuesList;
  }

  public isEntityReferenceField(entityConfiguration: EntityConfiguration, field: GenericFormField): boolean {
    const attributeConfiguration = this.getAttributeConfigurationFromField(entityConfiguration, field);
    return !!attributeConfiguration && this.isEntityReferenceAttribute(attributeConfiguration!);
  }

  public isEntityReferenceAttribute(attributeConfiguration: AttributeConfiguration): boolean {
    return !!attributeConfiguration.entityType && !!attributeConfiguration.referenceType && attributeConfiguration.referenceType !== "WEAK";
  }

  public getArrayOrSingleValue(value: any, valueProvider: (v: any) => any) {
    if (Array.isArray(value)) {
      return value.map(valueProvider);
    } else if (!!value) {
      return valueProvider(value);
    } else {
      return null;
    }
  }

  public getPropertyNameValuePairsForStaticReference(entityConfiguration: EntityConfiguration, field: GenericFormField, value: any) {
    const attributeConfiguration = this.getAttributeConfigurationFromField(entityConfiguration, field)!;
    const attributeName = attributeConfiguration.name;
    return [
      {propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: this.getArrayOrSingleValue(value, v => v.value)},
      {propertyName: `dynamicAttributes.${attributeName}.valueDisplay`, propertyValue: this.getArrayOrSingleValue(value, v => v.label)},
    ];
  }

  public getPropertyNameValuePairsForEntityReference(entityConfiguration: EntityConfiguration, field: GenericFormField, value?: any): PropertyNameAndValue[] {
    const attributeConfiguration = this.getAttributeConfigurationFromField(entityConfiguration, field)!;
    const attributeName = attributeConfiguration.name;
    if (attributeConfiguration.referenceType === "BY_ID") {
      return [
        {propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: this.getArrayOrSingleValue(value, () => attributeConfiguration.entityType)},
        {propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: this.getArrayOrSingleValue(value, v => v.value || v)},
      ];
    } else if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
      return [
        {propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: this.getArrayOrSingleValue(value, () => attributeConfiguration.entityType)},
        {propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: this.getArrayOrSingleValue(value, v => v.value || v)},
        {propertyName: `dynamicAttributes.${attributeName}.version`, propertyValue: this.getArrayOrSingleValue(value, v => v.version)},
        {propertyName: `dynamicAttributes.${attributeName}.valueDisplay`, propertyValue: this.getArrayOrSingleValue(value, v => v.valueDisplay)},
      ];
    } else if (attributeConfiguration.referenceType === "BY_VALUE") {
      const valueDisplay = this.getArrayOrSingleValue(value, v => attributeConfiguration.displayBinding
        ? ExpressionEvaluationService.evaluate(attributeConfiguration.displayBinding, v) : null);
      return [
        {propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: attributeConfiguration.entityType},
        {propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: value},
        {propertyName: `dynamicAttributes.${attributeName}.valueDisplay`, propertyValue: valueDisplay},
      ];
    } else {
      return [{propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: value}];
    }
  };

  public getFormEntity(baseEntity: Entity, entityConfiguration: EntityConfiguration): Entity {
    const result: Entity = cloneDeep(baseEntity);
    for (const [attributeName, dynamicAttributeValue] of Object.entries(result.dynamicAttributes || {})) {
      const attributeConfiguration = this.getAttributeConfiguration(entityConfiguration, attributeName);
      if (!!attributeConfiguration && this.isEntityReferenceAttribute(attributeConfiguration)) {
        this.updateFormEntityForEntityReference(result, attributeConfiguration, attributeName, dynamicAttributeValue);
      } else if (!!attributeConfiguration && this.isStaticReferenceAttribute(attributeConfiguration)) {
        this.updateFormEntityForStaticReference(result, attributeName, dynamicAttributeValue);
      }
    }
    return result;
  }

  private updateFormEntityForStaticReference(result: Entity, attributeName: string, dynamicAttributeValue: DynamicAttributeValue) {
    let value;
    if (Array.isArray(dynamicAttributeValue.value)) {
      value = this.getFormPossibleValueFromArrays(dynamicAttributeValue);
    } else if (dynamicAttributeValue.value != null || dynamicAttributeValue.valueDisplay) {
      value = {
        value: dynamicAttributeValue.value,
        label: dynamicAttributeValue.valueDisplay,
      };
    } else {
      value = undefined;
    }
    result.dynamicAttributes![attributeName].value = value;
  }

  private updateFormEntityForEntityReference(result: Entity, attributeConfiguration: AttributeConfiguration,
                                             attributeName: string, dynamicAttributeValue: DynamicAttributeValue) {
    let value;
    if (attributeConfiguration.referenceType === "BY_ID") {
      value = dynamicAttributeValue.id;
    } else if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
      if (Array.isArray(dynamicAttributeValue.id)) {
        value = this.getFormEntityValueFromArrays(dynamicAttributeValue);
      } else if (dynamicAttributeValue.id || dynamicAttributeValue.version || dynamicAttributeValue.valueDisplay) {
        value = {
          value: dynamicAttributeValue.id,
          version: dynamicAttributeValue.version,
          valueDisplay: dynamicAttributeValue.valueDisplay,
        };
      } else {
        value = null;
      }
    } else {
      value = dynamicAttributeValue.value;
    }
    result.dynamicAttributes![attributeName].value = value;
  }

  private getFormPossibleValueFromArrays(dynamicAttributeValue: DynamicAttributeValue): PossibleValue[] {
    const result: PossibleValue[] = [];
    if (Array.isArray(dynamicAttributeValue.value)) {
      for (let i = 0; i < dynamicAttributeValue.value.length; i++) {
        const value = dynamicAttributeValue.value[i];
        const valueDisplay = dynamicAttributeValue.valueDisplay ? dynamicAttributeValue.valueDisplay[i] : undefined;
        result.push({value: value, label: valueDisplay});
      }
    }
    return result;
  }

  private getFormEntityValueFromArrays(dynamicAttributeValue: DynamicAttributeValue) {
    const value = [];
    if (Array.isArray(dynamicAttributeValue.id)) {
      for (let i = 0; i < dynamicAttributeValue.id.length; i++) {
        const id = dynamicAttributeValue.id[i];
        const version = dynamicAttributeValue.version ? dynamicAttributeValue.version[i] : undefined;
        const valueDisplay = dynamicAttributeValue.valueDisplay ? dynamicAttributeValue.valueDisplay[i] : undefined;
        value.push({value: id, version: version, valueDisplay: valueDisplay});
      }
    }
    return value;
  }

  public getFormEntityPropertyChanges(changes: EntityPropertyValue[], entityConfiguration: EntityConfiguration, entity: Entity): EntityPropertyValue[] {
    const result: EntityPropertyValue[] = [];
    changes.forEach(change => {
      const attributeName = this.getAttributeNameFromValueBinding(change.propertyName!);
      const attributeConfiguration = this.getAttributeConfiguration(entityConfiguration, attributeName);
      if (!!attributeConfiguration && this.isEntityReferenceAttribute(attributeConfiguration)) {
        this.getFormEntityPropertyChangesForEntityReference(attributeConfiguration, change, attributeName, entity, changes, result);
      } else if (!!attributeConfiguration && this.isStaticReferenceAttribute(attributeConfiguration)) {
        this.getFormEntityPropertyChangesForStaticReference(changes, attributeName, result, entity);
      } else {
        result.push(change);
      }
    });
    return result;
  }

  private getFormEntityPropertyChangesForStaticReference(changes: EntityPropertyValue[], attributeName: string | undefined,
                                                         result: EntityPropertyValue[], entity: Entity) {
    const valueChange = changes.find(c => c.propertyName === `dynamicAttributes.${attributeName}.value`);
    const valueDisplayChange = changes.find(c => c.propertyName === `dynamicAttributes.${attributeName}.valueDisplay`);
    const arrayLength = this.getArrayLength(valueChange, valueDisplayChange);
    if (arrayLength >= 0) {
      const values = [];
      for (let i = 0; i < arrayLength; i++) {
        const value = valueChange?.propertyValue[i];
        const valueDisplay = valueDisplayChange?.propertyValue[i];
        values.push({value: value, label: valueDisplay});
      }
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: values});
    } else {
      const currentValue = entity.dynamicAttributes ? entity.dynamicAttributes[attributeName!]?.value : undefined;
      const currentValueDisplay = entity.dynamicAttributes ? entity.dynamicAttributes[attributeName!]?.valueDisplay : undefined;
      const value = valueChange ? valueChange.propertyValue : currentValue;
      const valueDisplay = valueDisplayChange ? valueDisplayChange.propertyValue : currentValueDisplay;
      result.push({
        propertyName: `dynamicAttributes.${attributeName}.value`,
        propertyValue: {value: value, label: valueDisplay},
      });
    }
  }

  private getFormEntityPropertyChangesForEntityReference(attributeConfiguration: AttributeConfiguration,
                                                         change: EntityPropertyValue, attributeName: string | undefined, entity: Entity,
                                                         changes: EntityPropertyValue[], result: EntityPropertyValue[]) {
    if (attributeConfiguration.referenceType === "BY_ID" && change.propertyName === `dynamicAttributes.${attributeName}.id`) {
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: change.propertyValue});
    } else if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
      this.getFormEntityPropertyChangesForEntityReferenceByIdAndVersion(changes, attributeName, result, entity);
    } else if (change.propertyName === `dynamicAttributes.${attributeName}.value`) {
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: change.propertyValue});
    }
  }

  private getFormEntityPropertyChangesForEntityReferenceByIdAndVersion(changes: EntityPropertyValue[],
                                                                       attributeName: string | undefined,
                                                                       result: EntityPropertyValue[], entity: Entity) {
    const idChange = changes.find(c => c.propertyName === `dynamicAttributes.${attributeName}.id`);
    const versionChange = changes.find(c => c.propertyName === `dynamicAttributes.${attributeName}.version`);
    const valueDisplayChange = changes.find(c => c.propertyName === `dynamicAttributes.${attributeName}.valueDisplay`);
    const arrayLength = this.getArrayLength(idChange, versionChange, valueDisplayChange);
    if (arrayLength >= 0) {
      const values = [];
      for (let i = 0; i < arrayLength; i++) {
        const id = idChange?.propertyValue[i];
        const version = versionChange?.propertyValue[i];
        const valueDisplay = valueDisplayChange?.propertyValue[i];
        if (id || version || valueDisplay) {
          values.push({value: id, version: version, valueDisplay: valueDisplay});
        }
      }
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: values});
    } else {
      const currentId = entity.dynamicAttributes ? entity.dynamicAttributes[attributeName!]?.id : undefined;
      const currentVersion = entity.dynamicAttributes ? entity.dynamicAttributes[attributeName!]?.version : undefined;
      const currentValueDisplay = entity.dynamicAttributes ? entity.dynamicAttributes[attributeName!]?.valueDisplay : undefined;
      const id = idChange ? idChange.propertyValue : currentId;
      const version = versionChange ? versionChange.propertyValue : currentVersion;
      const valueDisplay = valueDisplayChange ? valueDisplayChange.propertyValue : currentValueDisplay;
      const value = id || version || valueDisplay ? {value: id, version: version, valueDisplay: valueDisplay} : null;
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: value});
    }
  }

  private getAttributeConfiguration(entityConfiguration: EntityConfiguration, attributeName?: string): AttributeConfiguration | undefined {
    if (attributeName) {
      return entityConfiguration.attributesConfiguration!.attributeConfigurations!.find(a => a.name === attributeName);
    } else {
      return undefined;
    }
  }

  private getAttributeName(field: GenericFormField): string | undefined {
    const valueBinding = field.valueBinding;
    return valueBinding ? this.getAttributeNameFromValueBinding(valueBinding) : undefined;
  }


  public getAttributeConfigurationFromField(entityConfiguration: EntityConfiguration, field: GenericFormField): AttributeConfiguration | undefined {
    const attributeName = this.getAttributeName(field);
    return this.getAttributeConfiguration(entityConfiguration, attributeName);
  }

  private getAttributeNameFromValueBinding(valueBinding: string): string | undefined {
    const prefix = "dynamicAttributes.";
    if (valueBinding.startsWith(prefix)) {
      const result = valueBinding.substring(prefix.length);
      const dotIndex = result.indexOf(".");
      if (dotIndex > 0) {
        return result.substring(0, dotIndex);
      }
    }
    return undefined;
  }

  private getArrayLength(value1: EntityPropertyValue | undefined, value2?: EntityPropertyValue | undefined,
                         value3?: EntityPropertyValue | undefined): number {
    if (value1 && Array.isArray(value1.propertyValue)) {
      return value1.propertyValue.length;
    } else if (value2 && Array.isArray(value2.propertyValue)) {
      return value2.propertyValue.length;
    } else if (value3 && Array.isArray(value3.propertyValue)) {
      return value3.propertyValue.length;
    } else {
      return -1;
    }
  }

}

const entityReferenceValueService = EntityReferenceValueService.INSTANCE;
export {entityReferenceValueService};
