/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/


import {useMessages} from "../store";

/**
 * A JSX Label fragment.
 *
 * @param props properties like the message key and parameters
 * @constructor
 */
export const TranslatedLabel = (props: {code: string, defaultMessage?: string, params?: any}) => {
  const {getMessage} = useMessages();
  return (<>{getMessage(props.code, {...props})}</>);
};
