/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {BaseViewModelData, MessageKey, ViewDescriptor} from "@icm/core-common";
import React from "react";


export const settingsViewDescriptor: ViewDescriptor<BaseViewModelData> = {
  viewType: "UI_SETTINGS",
  view: React.lazy(() => import("./UiSettingsView")),
  initializeViewModelData: _viewParameters => ({printRequested: false}),
  getTitle: (_viewModel, getMessage) => getMessage(MessageKey.CORE.SETTINGS._),
};
