/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmThemeConfiguration} from "./types";

const THEME_NS = "http://www.cns.at/icm/core/common/api/data/theme-configuration";

/**
 * Stringifies the given configuration to XML.
 * @param config the theme configuration
 */
export function stringifyThemeConfigurationToXml(config: IcmThemeConfiguration): string {
  const [baseDoc, rootEl] = createDocument("ThemeConfiguration", {
    defaultTheme: config.defaultTheme,
    "xmlns:th": "http://www.thymeleaf.org",
  });
  Object.entries(config.themes).forEach(([name, th]) => {
    const theme = createAndAppendElement(baseDoc, rootEl, "Theme", {name: name});
    const palette = createAndAppendElement(baseDoc, theme, "Palette");
    Object.entries(th!.palette).forEach(([paletteName, colors]) => {
      createAndAppendElement(baseDoc, palette, paletteName, {...colors});
    });

    if (th!.images) {
      const configuredImages = Object.entries(th!.images);
      if (configuredImages.length) {
        const images = createAndAppendElement(baseDoc, theme, "Images");
        configuredImages.forEach(([image, path]) => {
          if (path) {
            createAndAppendElement(baseDoc, images, "Image", {name: image, path});
          }
        });
      }
    }
    if (th!.overrides) {
      const configuredOverrides = Object.entries(th!.overrides);
      if (configuredOverrides.length) {
        const overrides = createAndAppendElement(baseDoc, theme, "Overrides");
        configuredOverrides.forEach(([component, color]) => {
          if (color) {
            createAndAppendElement(baseDoc, overrides, "Override", {component, color});
          }
        });
      }
    }
  });

  return stringify(baseDoc, true);
}

// general XML helper functions

function createDocument(tag: string, attributes?: Record<string, string>): [XMLDocument, Element] {
  const doc = document.implementation.createDocument(THEME_NS, tag);
  const rootEl = doc.getElementsByTagName(tag)[0];
  if (attributes) {
    Object.entries(attributes).forEach(([key, value]) => {
      rootEl.setAttribute(key, value);
    });
  }
  return [doc, rootEl];
}
function createAndAppendElement(doc: XMLDocument, appendTo: Element, tag: string, attributes?: Record<string, string | undefined>) {
  const element = doc.createElementNS(THEME_NS, tag);
  if (attributes) {
    Object.entries(attributes).forEach(([key, value]) => {
      if (value) {
        element.setAttribute(key, value);
      }
    });
  }
  appendTo.appendChild(element);
  return element;
}

function stringify(doc: XMLDocument, prettyPrint: boolean = false): string {
  const serializer = new XMLSerializer();
  const result = serializer.serializeToString(doc);
  const pretty = prettyPrint ? formatXml(result, "  ") : result;
  return '<?xml version="1.0" encoding="UTF-8"?>\n' + pretty;
}

function formatXml(xml: string, tab = "\t") {
  let formatted = "";
  let indent = "";
  xml.split(/>\s*</).forEach(node => {
    if (node.match(/^\/\w/)) {
      // decrease indent by one 'tab'
      indent = indent.substring(tab.length);
    }
    formatted += indent + "<" + node + ">\r\n";
    if (node.match(/^<?\w[^>]*[^/]$/)) {
      // increase indent
      indent += tab;
    }
  });
  return formatted.substring(1, formatted.length - 3);
}
