/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ParameterUtilities, ViewDescriptor} from "@icm/core-common";
import {isString} from "lodash-es";
import React from "react";

export type IFrameViewModel = {
  title?: string,
  url: string,
  printRequested: boolean,
}

export const iFrameViewDescriptor: ViewDescriptor<IFrameViewModel> = {
  viewType: "IFRAME_VIEW",
  view: React.lazy(() => import("./IFrameView")),
  getTitle: (viewModel) => viewModel.viewModelData.title!,
  initializeViewModelData: (viewParameters) => {
    const title = ParameterUtilities.getResolvedParameterValue("title", viewParameters, isString);
    const url = ParameterUtilities.getResolvedParameterValue("url", viewParameters, isString)!;
    return {
      title,
      url,
      printRequested: false,
    };
  },
  createUniqueHash: (viewModelData) => viewModelData.url,
};
