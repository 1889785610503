/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";

import {MessageKey, useFileUpdateValueQueue, useFileUploadProgress, useMessages} from "../../../index";
import {FileReference} from "../../generated/api";
import {FetchService, useFeedbackBanners} from "../../service";
import {IdGenerator} from "../../util";

const FILE_TRANSFER_COMPLETED_PROGRESS = 0.9;
const UPLOAD_REQUEST_COMPLETED_PROGRESS = 100;

export const useFileUploader = (permanent: boolean = false, onUpload: (fileReference: FileReference) => Promise<void>) => {
  const {
    openFeedbackBanner,
  } = useFeedbackBanners();
  const {getMessage} = useMessages();
  const {
    queueInProgress,
    addToQueue,
  } = useFileUpdateValueQueue(onUpload);
  const {
    currentUploadProgress,
    uploadInProgress,
    uploadFinished,
    updateUploadProgress,
    removeUploadProgress,
  } = useFileUploadProgress();

  const uploadFile = (file: File) => {
    const fileName = file.name;
    const uuid = IdGenerator.randomUUID();
    console.log("Uploading file", fileName);

    FetchService.performUploadWithProgress<FileReference>("core/fileData", file, progress => {
      /**
       * At this point only the file transfer is completed, we want the progress indicator to be still visible, until the whole request is completed.
       * */
      updateUploadProgress(uuid, fileName, (progress.percentage ?? 0) * FILE_TRANSFER_COMPLETED_PROGRESS);
    }, permanent)
      .then((fileRef) => {
        /**
         * At this point the whole upload request is completed so we can end the progress indicator for a file.
         * */
        updateUploadProgress(uuid, fileName, UPLOAD_REQUEST_COMPLETED_PROGRESS);
        addToQueue(fileRef);
      })
      .catch(error => {
        handleFileUploadError(uuid, fileName);
        console.warn(`Error while uploading file ${fileName}. Error: ${error.message}`);
      });
  };

  const handleFileUploadError = useCallback((id: string, fileName: string) => {
    openFeedbackBanner({
      key: id,
      title: getMessage(MessageKey.CORE.LIST.UPLOAD.FAILURE, {params: {fileName}}),
      variant: "ERROR",
      duration: "LONG",
    });
    removeUploadProgress(id);
  }, [getMessage, openFeedbackBanner, removeUploadProgress]);

  return {
    uploadFile,
    uploadInProgress: uploadInProgress || queueInProgress,
    uploadFinished,
    currentUploadProgress,
  };
};
