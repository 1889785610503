/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useEffect, useMemo, useState} from "react";

import MessageKey from "../../generated/MessageKey";
import {useMessages} from "../../store";

type UploadedFile = {
  fileName: string,
  progressPercent: number
}

type UploadProgress = Record<string, UploadedFile>

type OverallUploadProgress = {
  percent: number
  title: string
}

export const useFileUploadProgress = () => {
  const {getMessage} = useMessages();
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
  const [overallUploadProgress, setOverallUploadProgress] = useState<OverallUploadProgress>({
    percent: 0,
    title: "",
  });

  useEffect(() => {
    const uploadProgressArray = Object.keys(uploadProgress);
    const count = uploadProgressArray.length;
    if (count <= 0) {
      setOverallUploadProgress({
        title: "",
        percent: 0,
      });
    } else {
      const sum = uploadProgressArray.map(key => uploadProgress[key].progressPercent)
        .reduce((current, item) => current + item, 0);
      const overallPercent = Math.round(sum / count);
      const title = getMessage(MessageKey.CORE.LIST.UPLOAD.IN_PROGRESS, {
        params: {
          count,
          progressPercent: overallPercent,
        },
      });
      setOverallUploadProgress({
        title,
        percent: overallPercent,
      });
      if (overallPercent >= 100) {
        setTimeout(() => setUploadProgress({}), 5000);
      }
    }
  }, [uploadProgress, getMessage]);

  const updateUploadProgress = useCallback((id: string, fileName: string, progressPercent: number) => {
    setUploadProgress(current => {
      return {
        ...current,
        [id]: {
          fileName,
          progressPercent,
        },
      };
    });
  }, []);

  const removeUploadProgress = useCallback((id: string) => {
    setUploadProgress(current => {
      const {
        [id]: deleted,
        ...rest
      } = current;
      return rest;
    });
  }, []);

  const uploadFinished = useMemo(() => overallUploadProgress.percent >= 100, [overallUploadProgress]);
  const uploadInProgress = useMemo(() => overallUploadProgress.percent > 0, [overallUploadProgress]);

  return {
    removeUploadProgress,
    updateUploadProgress,
    uploadFinished,
    uploadInProgress,
    currentUploadProgress: overallUploadProgress,
  };
};
