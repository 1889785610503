/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {UpdatingInfiniteListGenerator} from "@icm/activitystream-web";
import {
  ActionRunners,
  createDefaultsFromListConfiguration,
  isCreateActionType,
  ListConfiguration,
  SharedFilter,
  useConnectionState,
  useDefaultsOnFilter,
  useExecuteViewAction,
  useFilter,
  useFilterData,
  useModifierOnFilter,
} from "@icm/core-common";
import {
  IcmMuiIcon,
  LoadingSpinner,
  useDataTableStyles,
  useTabContentActions,
  ViewContentBox,
  WebSvgIcon,
} from "@icm/core-web";
import {
  EntityListComponentProps,
  isUpdateRelevant,
  ListRefresher,
  useCommonEntityActionHandlers,
  useEntityListConfiguration,
  useMarkEntitiesAsSeen,
  useUpdatingInfiniteEntityList,
} from "@icm/entity-common";
import {Fab, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import {isEqual} from "lodash-es";
import * as React from "react";
import {useEffect, useMemo} from "react";

const NOOP_LIST_CONFIGURATION_MODIFIER = (configuration: ListConfiguration) => configuration;

export const WebEntityListComponent = React.memo((props: EntityListComponentProps) => {
  const {
    viewModel,
    entityType,
    filterParameter,
    listVariant,
    maxHeight,
    embedded,
    listConfigurationModifier = NOOP_LIST_CONFIGURATION_MODIFIER,
    setDownloadHandler,
    setRefreshHandler,
    filterModifier,
    contextEntityId,
    loader,
    actionContext,
  } = props;
  const markEntitiesAsSeen = useMarkEntitiesAsSeen();

  const {listFilter,  updateFilter: handleUpdateFilter,  sort} = useFilter();
  const listConfiguration = useEntityListConfiguration(entityType, listVariant);
  // derive list filter defaults from list configuration
  const viewListFilterDefaults = useMemo(() => {
    return listConfiguration ? createDefaultsFromListConfiguration(listConfiguration) : {};
  }, [listConfiguration]);
  const listFilterWithDefaults = useDefaultsOnFilter(listFilter, filterParameter, viewListFilterDefaults);
  const listFilterWithDefaultsAndModifier = useModifierOnFilter(listFilterWithDefaults, filterModifier);


  const {
    result,
    startCsvDownload,
    reload,
    onEntityDeleted,
  } = useUpdatingInfiniteEntityList({
    entityType,
    listVariant,
    loader,
    contextEntityId,
    listFilter: listFilterWithDefaultsAndModifier,
    sort,
  });

  const actionHandlers: ActionRunners = useCommonEntityActionHandlers(entityType, onEntityDeleted);

  const hasElements = (result.data?.length ?? 0) > 0;
  useEffect(() => {
    setDownloadHandler?.(startCsvDownload, hasElements);
  }, [setDownloadHandler, startCsvDownload, hasElements]);

  useEffect(() => {
    if (setRefreshHandler) {
      const refreshHandler: ListRefresher = {
        run: reload,
        listContext: entityType,
      };
      setRefreshHandler(refreshHandler);
    }
  }, [setRefreshHandler, reload, entityType]);

  const classes = useDataTableStyles();

  const configuration = useMemo(() => {
    return {
      listConfiguration: listConfiguration && listConfigurationModifier(listConfiguration),
      maxHeight: maxHeight ? `${maxHeight}px` : undefined,
      className: embedded ? classes.embeddedTable : undefined,
    };
  }, [listConfigurationModifier, listConfiguration, maxHeight, embedded, classes.embeddedTable]);

  const tabContentActions = useTabContentActions(viewModel, isCreateActionType);
  const executeViewAction = useExecuteViewAction(viewModel?.id);
  const connected = useConnectionState();

  const actions = useMemo(() => {
    if (tabContentActions) {
      return tabContentActions.map(entry => {
        return {
          icon: <WebSvgIcon name={entry.action.icon as string} size="inherit" iconColor="primary" />,
          name: entry.action.title,
          key: entry.actionType,
          enabled: entry.action.enabled !== undefined ? entry.action.enabled : true,
        };
      });
    }
    return [];
  }, [tabContentActions]);

  const showEventFilterOptions = false;
  const filterData = useFilterData(filterParameter?.id, showEventFilterOptions);
  const filterReady = filterData?.isReady || (!filterParameter);

  return (
    <ViewContentBox style={{
      display: "flex",
      flexFlow: "column",
    }}
    >
      {filterParameter && (
        <div style={{flex: "0 1 auto"}}>
          <SharedFilter filterParameter={filterParameter} onUpdateFilter={handleUpdateFilter} showEventFilterOptions={showEventFilterOptions} />
        </div>
      )}
      {filterReady && configuration.listConfiguration ? (
        <div style={{
          flex: "1 1 auto",
          maxHeight: configuration.maxHeight,
        }}
             className={configuration.className}
        >
          <UpdatingInfiniteListGenerator listConfig={configuration.listConfiguration}
                                         actionHandlers={actionHandlers}
                                         activeSorting={listFilterWithDefaultsAndModifier.sorting}
                                         externalSort={sort}
                                         infiniteData={result}
                                         markAsSeen={markEntitiesAsSeen}
                                         isUpdateRelevant={isUpdateRelevant}
                                         actionContext={actionContext}
          />
          {actions && actions.length === 1 && (
            <Fab sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
            }}
                 color="primary"
                 aria-label="create"
                 onClick={() => executeViewAction(actions[0].key)}
            >
              <IcmMuiIcon name="add" fontSize="inherit" />
            </Fab>
          )}
          {actions && actions.length > 1 && (
            <SpeedDial
              ariaLabel="Create Entities"
              sx={{
                position: "absolute",
                bottom: 16,
                right: 16,
              }}
              FabProps={{disabled: !connected}}
              icon={<SpeedDialIcon />}
            >
              {actions.map((action, index) => (
                <SpeedDialAction
                  sx={{
                    padding: 0,
                    blockSize: "1.5em",
                    inlineSize: "2.5em",
                  }}
                  key={index}
                  FabProps={{disabled: !action.enabled}}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => executeViewAction(action.key)}
                />
              ))}
            </SpeedDial>
          )}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </ViewContentBox>
  );
}, isEqual);

export const entityListComponentFactory = (
  props: EntityListComponentProps
) => <WebEntityListComponent {...props} />;
