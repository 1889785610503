/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {BaseViewModelData, registerInStore} from "../../store";
import {
  ViewDescriptor,
  ActionHandlerFactoryHooks,
} from "../../ui";
import {LoginHandler} from "../security";
import {IcmServiceRegistry} from "./IcmServiceRegistry";

/**
 * Options being evaluated when loading a module.
 */
export type ModuleRegistrationOptions = {
  moduleName: string
  dependencies: Partial<Record<string, string>>
  // phase to register services
  registerServices?: (serviceRegistry: IcmServiceRegistry, registerLoginHandler: (loginHandler: LoginHandler) => void) => void

  /**
   * Register redux store contributions
   *
   * @param register
   */
  registerStoreContribution?: (register: typeof registerInStore) => void

  /**
   * Run "actions" that depend on services being available or other stuff that must be executed to make
   * the module work. This phase is called as last step of the module registration.
   */
  runPostInitialization?: (serviceRegistry: IcmServiceRegistry) => void
  providedViews?: ViewDescriptor<BaseViewModelData>[]
  providedActions?: ActionHandlerFactoryHooks
}

const loadedModules: {
  [moduleName: string]: {
    icmDependencies: string[]
  }
} = {};

function getIcmDependencies(dependencies: Partial<Record<string, string>>) {
  return Object.keys(dependencies).filter(dep => dep.startsWith("@icm"));
}


const registerModule = (serviceRegistry: IcmServiceRegistry, options: ModuleRegistrationOptions) => {
  const moduleIcmDependencies = getIcmDependencies(options.dependencies);
  const unregisteredIcmDependencies = moduleIcmDependencies.filter(dep => !loadedModules[dep]);
  if (unregisteredIcmDependencies.length > 0) {
    console.warn(`ModuleRegistration: Module ${options.moduleName} 
      depends on the following modules which are not yet loaded: ${unregisteredIcmDependencies}`);
  }
  const securityService = serviceRegistry.get("SECURITY");
  loadedModules[options.moduleName] = {icmDependencies: moduleIcmDependencies};

  options.registerStoreContribution?.(registerInStore);
  options.registerServices?.(serviceRegistry,
    async (loginHandler: LoginHandler) => securityService.addLoginHandler(loginHandler));
  options.runPostInitialization?.(serviceRegistry);
};

export const registerModules = (serviceRegistry: IcmServiceRegistry, options: ModuleRegistrationOptions[]) => {
  options.forEach(o => registerModule(serviceRegistry, o));
};
