/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  dataConverters,
  dateService,
  ExpressionEvaluationService,
  feedbackService,
  FetchService,
  FetchServiceException,
  FieldHighlightMap,
  FORM_VIEW_COMPONENT,
  FormGenerator,
  FormMessagesHelper,
  GeneralError,
  GenericFormConfiguration,
  GenericFormField,
  HighlightedFieldsService,
  IEntityInfo,
  IViewProps,
  ListFilter,
  MessageKey,
  messages,
  NamedComponentDescriptor,
  ParameterUtilities,
  userDownloadService,
  useService,
  ViewActionHandlersByActionId,
  ViewDescriptor,
} from "@icm/core-common";
import {LoadingSpinner} from "@icm/core-web";
import {cloneDeep, isString, set} from "lodash-es";
import * as React from "react";

interface IState {
  formConfig?: GenericFormConfiguration
  formSubmitUrl?: string
  formDownloadUrl?: string
  entity?: object
  newEntityTemplate?: object
  highlightedFields?: FieldHighlightMap
  onReadyExecuted?: boolean
}

type FormViewModelDataWithServices = FormViewModelData & {
  highlightedFieldsService?: HighlightedFieldsService
  setViewActionHandlers?: (viewActionHandlers: ViewActionHandlersByActionId, actionContextId: string) => void
  actionContextId: string
};

type ReloadEntityOptions = {
  entityUrl?: string,
  entityAndFormConfigUrl?: string,
  entityBinding?: string,
  formConfigBinding?: string
}

/**
 * A generic form (view) component.
 * Do not use it for entities stored in the ICM database, but only for external objects e.g. retrieved by an adapter.
 */
class FormViewComponent extends React.Component<FormViewModelDataWithServices, IState> {
  private unregisterEntityUpdateListener?: () => void;
  private entityInfo: IEntityInfo<any>;
  private reloadEntityProxy: () => void;
  private beforeUnloadListener: (ev: Event) => void;

  constructor(props: Readonly<FormViewModelDataWithServices>) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.loadEntityFromEntityUrl = this.loadEntityFromEntityUrl.bind(this);
    this.state = {};
    this.loadEntityAndFormConfig();
  }

  public componentDidMount(): void {
    this.updateHeader();
    this.setupBeforeUnloadListener();
    this.setState(state => ({
      ...state,
      onReadyExecuted: false,
    }));
  }

  public componentDidUpdate(
    prevProps: Readonly<FormViewModelDataWithServices & IViewProps<FormViewModelData>>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    // TODO PICM-1501 needed for resize of popup on map?
    // if (this.props.onFinishedRenderView && this.state.entity && this.state.formConfig) {
    //   this.props.onFinishedRenderView();
    // }
    if (this.state.entity && !this.state.onReadyExecuted) {
      this.onReady();
      this.updateHeader();
      this.setState(state => ({
        ...state,
        onReadyExecuted: true,
      }));
    }
  }

  public componentWillUnmount(): void {
    if (this.unregisterEntityUpdateListener) {
      this.unregisterEntityUpdateListener();
    }
    this.removeBeforeUnloadListener();
    this.markAsSeen();
    this.props.setViewActionHandlers?.({}, this.props.actionContextId);
  }

  public render() {
    if (this.state.entity && this.state.formConfig) {
      if (this.state.formSubmitUrl) {
        const submitButtonConfig = {
          submitLabel: messages.get(MessageKey.CORE.FORM.SUBMIT._),
          submitIcon: "send",
          onSubmit: this.handleSubmit,
          iconOnly: true,
        };
        return (
          <FormGenerator formConfig={this.state.formConfig}
                         entity={this.state.entity}
                         submitButtonConfiguration={submitButtonConfig}
                         onChange={this.handleChange}
                         highlightedFields={this.state.highlightedFields}
                         useSimpleValues={true}
                         renderActionsInHeader={true}
          />
        );
      } else {
        return (
          <FormGenerator formConfig={this.state.formConfig}
                         entity={this.state.entity}
                         highlightedFields={this.state.highlightedFields}
                         useSimpleValues={true}
                         renderActionsInHeader={true}
          />
        );
      }
    } else {
      return <LoadingSpinner />;
    }
  }

  private setupBeforeUnloadListener() {
    this.beforeUnloadListener = () => {
      this.markAsSeen();
    };
    window.addEventListener("beforeunload", this.beforeUnloadListener);
  }

  private removeBeforeUnloadListener() {
    if (this.beforeUnloadListener) {
      window.removeEventListener("beforeunload", this.beforeUnloadListener);
    }
  }

  private onReady(): void {
    this.markAsSeen();
  }

  private updateHeader() {
    this.props.setViewActionHandlers?.(this.getHeaderProperties(), this.props.actionContextId);
  }

  private getHeaderProperties(): ViewActionHandlersByActionId {
    return {
      REFRESH: {
        run: () => {
          const entityId = this.entityInfo.getId(this.state.entity);
          if (entityId) {
            const feedbackKey = "Activity:" + entityId;
            feedbackService.close(feedbackKey);
          }
          this.reloadEntityProxy();
        },
      },
      DELETE: {
        run: () => this.resetEntity(),
      },
      DOWNLOAD: {
        run: () => this.handleDownload(),
        enabled: !!this.state.formDownloadUrl,
      },
      SAVE: {
        run: () => this.handleSubmit(),
        enabled: !!this.state.formSubmitUrl,
      },
    };
  }


  private markAsSeen() {
    if (this.props.highlightUpdatesSince && this.state.entity && this.entityInfo) {
      const entityId = this.entityInfo.getId(this.state.entity);
      if (entityId) {
        const feedbackKey = "Activity:" + entityId;
        feedbackService.close(feedbackKey);
        this.props.highlightedFieldsService?.setEntityUpdateAsSeen(this.props.entityType, entityId);
      }
    }
  }

  private getMessageKeyFromCodes(base: any, codes: string[]): string | object {
    if (typeof base === "string" || codes.length === 0) {
      return base;
    } else {
      const code = codes[0];
      if (base[code]) {
        return this.getMessageKeyFromCodes(base[code], codes.splice(1));
      } else {
        return base._;
      }
    }
  }

  private findAvailableKeyInString(messageKey: string | object, searchString?: string) {
    if (typeof messageKey === "string") {
      return messageKey;
    } else if (searchString) {
      const keys = Object.keys(messageKey);
      keys.unshift("_");
      const result = keys.find(key => searchString.toUpperCase().includes(key)) || "_";
      return messageKey[result];
    } else {
      return messageKey["_"];
    }
  }

  private handleSubmit() {
    if (this.state.formSubmitUrl) {
      const formSubmitUrl = ExpressionEvaluationService.evaluate(this.state.formSubmitUrl, this.state.entity);
      FetchService.performPost(formSubmitUrl, this.state.entity, {catchableErrorCodes: [422, 500]})
        .then(response => response.json())
        .then((data) => {
          this.resetEntity();
          const message = FormMessagesHelper.getSuccessMessage(this.state.formConfig, data, messages.get(MessageKey.CORE.FORM.SUBMIT.SUCCESS));
          feedbackService.open({
            duration: "MEDIUM",
            key: "formSubmitSuccess",
            variant: message.variant,
            title: message.text,
          });
        }, (ex: FetchServiceException) => {
          if (ex.response?.status === 422) {
            ex.response.json().then(json => {
              const generalError = GeneralError.fromData(json);
              const codes = [];
              generalError.code && codes.push(generalError.code);
              generalError.subCode && codes.push(generalError.subCode);
              const messageKey = this.getMessageKeyFromCodes(MessageKey.CORE.FORM.SUBMIT.ERROR, codes);
              const selectedMessageKey = this.findAvailableKeyInString(messageKey, generalError.message);
              feedbackService.open({
                duration: "MEDIUM",
                key: "formSubmitError",
                title: messages.get(selectedMessageKey),
                variant: "ERROR",
              });
            });
          } else {
            const data = {statusCode: ex.response?.status};
            const message = FormMessagesHelper.getErrorMessage(this.state.formConfig, data, messages.get(MessageKey.CORE.FORM.SUBMIT.ERROR._));
            feedbackService.open({
              duration: "MEDIUM",
              key: "formSubmitError",
              variant: message.variant,
              title: message.text,
            });
          }
        });
    }
  }

  private handleDownload() {
    if (this.state.formDownloadUrl) {
      const url = ExpressionEvaluationService.evaluate(this.state.formDownloadUrl, this.state.entity);
      userDownloadService.startDownload(url);
    }
  }


  private handleChange(field: GenericFormField, value?: any) {
    const fieldName = field.valueBinding!;
    this.setState(state => {
      const entity = Array.isArray(state.entity) ? [...state.entity] : {...state.entity};
      set(entity, fieldName, value);
      return {entity};
    });
  }

  /**
   * Only call this method from the constructor because it will alter the state directly.
   *
   * @private
   */
  private loadEntityAndFormConfig() {
    const {
      entityType,
      entityUrlTemplate,
      entityTemplate,
      formConfigUrlTemplate,
      entityAndFormConfigUrlTemplate,
      formSubmitUrl,
      formDownloadUrl,
    } = this.props;

    const sectionEntity = this.props.entity;

    if (dataConverters[entityType]) {
      this.entityInfo = dataConverters[entityType];
    } else {
      this.entityInfo = dataConverters.UNKNOWN;
    }

    const newState: IState = {
      formSubmitUrl,
      formDownloadUrl,
    };

    if (formConfigUrlTemplate) {
      if (entityUrlTemplate) {
        const entityUrl = ExpressionEvaluationService.evaluate(entityUrlTemplate, sectionEntity);
        this.loadEntityFromEntityUrl(entityUrl);
        this.reloadEntityProxy = () => this.reloadEntity({entityUrl});
      } else if (entityTemplate) {
        const evaluatedEntityTemplate = ExpressionEvaluationService.evaluate(entityTemplate, sectionEntity);
        if (typeof evaluatedEntityTemplate === "string") {
          newState.newEntityTemplate = JSON.parse(evaluatedEntityTemplate);
        } else {
          newState.newEntityTemplate = evaluatedEntityTemplate;
        }
      } else {
        newState.newEntityTemplate = this.entityInfo.create();
      }
      newState.entity = createEntityFromTemplate(newState.newEntityTemplate);

      const formConfigUrl = ExpressionEvaluationService.evaluate(formConfigUrlTemplate, sectionEntity);
      FetchService.performGet<GenericFormConfiguration>(formConfigUrl).then(genericFormConfig => {
        const entity = this.state.entity;
        const initializedEntity = this.applyInitialValues(entity, genericFormConfig);
        this.setState(state => ({
          ...state,
          entity: initializedEntity,
          formConfig: genericFormConfig,
        }));
      });
      // Direct state mutation is "OK" if done in the constructor - this method is called from the constructor
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = newState;
    } else if (entityAndFormConfigUrlTemplate) {
      const {entityBinding, formConfigurationBinding} = this.props;

      if (entityBinding && formConfigurationBinding) {
        const entityAndFormConfigUrl = ExpressionEvaluationService.evaluate(entityAndFormConfigUrlTemplate, sectionEntity);

        FetchService.performGet(entityAndFormConfigUrl).then(entityAndFormConfig => {
          const newEntityTemplate = this.entityInfo.convert(ExpressionEvaluationService.get(entityAndFormConfig, entityBinding));
          const formConfig = ExpressionEvaluationService.get(entityAndFormConfig, formConfigurationBinding);
          const entity = createEntityFromTemplate(newEntityTemplate);
          const initializedEntity = this.applyInitialValues(entity, formConfig);
          this.setState({
            newEntityTemplate,
            entity: initializedEntity,
            formConfig: formConfig,
          });
          this.onReceiveEntity(newEntityTemplate);
        });
        this.reloadEntityProxy = () => this.reloadEntity({entityAndFormConfigUrl, entityBinding, formConfigBinding: formConfigurationBinding});
        // Direct state mutation is "OK" if done in the constructor - this method is called from the constructor
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state = newState;
        return;
      }
    } else {
      console.warn("No suitable view params provided. Either provide formConfigurationUrl (+ optionally entityUrl) or entityAndFormConfigurationUrl, entityBinding AND formConfigurationBinding");
    }
  }

  private applyInitialValues(entity: object | undefined, formConfig: GenericFormConfiguration | undefined): object | undefined {
    if (entity && formConfig) {
      formConfig.groups?.forEach(group => {
        group.widgets?.filter(widget => widget.widgetType === "GENERIC_FIELD")
          .map(widget => widget as GenericFormField)
          .forEach(field => {
            if (field.initialValueProvider) {
              const initialValue = ExpressionEvaluationService.evaluate(field.initialValueProvider);
              const value = ExpressionEvaluationService.get(entity, field.valueBinding!);
              if (!value && !!initialValue) {
                console.log("Setting initial value", field.label, initialValue);
                ExpressionEvaluationService.set(entity, field.valueBinding!, initialValue);
              }
            }
          });
      });
    }
    return entity;
  }

  // maybe it's possible to unify this code with the one in this.loadEntityAndFormConfig
  // (quite similar, main difference is, that here we don't reload the form config (if in a separate url))
  private reloadEntity({
    entityUrl,
    entityAndFormConfigUrl,
    entityBinding,
    formConfigBinding,
  }: ReloadEntityOptions) {
    this.setState(state => ({
      entity: undefined,
      highlightedFields: Object.keys(state.highlightedFields || {}).reduce((fields, key) => {
        fields[key] = {...state.highlightedFields![key], outdated: false};
        return fields;
      }, {}),
    }), this.updateHeader);
    if (entityUrl) {
      this.loadEntityFromEntityUrl(entityUrl);
    } else if (entityAndFormConfigUrl) {
      FetchService.performGet(entityAndFormConfigUrl).then(entityAndFormConfig => {
        const newEntityTemplate = this.entityInfo.convert(ExpressionEvaluationService.get(entityAndFormConfig, entityBinding!));
        this.setState({
          newEntityTemplate,
          entity: createEntityFromTemplate(newEntityTemplate),
          formConfig: ExpressionEvaluationService.get(entityAndFormConfig, formConfigBinding!),
        });
        this.onReceiveEntity(newEntityTemplate);
      });
    }
  }

  private loadEntityFromEntityUrl(entityUrl: string) {
    FetchService.performGet(entityUrl).then(entity => {
      const newEntityTemplate = this.entityInfo.convert(entity);
      this.setState({
        newEntityTemplate,
        entity: createEntityFromTemplate(newEntityTemplate),
      });
      this.onReceiveEntity(newEntityTemplate);
    });
  }

  private onReceiveEntity(entity: object) {
    const entityId = this.entityInfo.getId(entity);
    if (entityId) {
      const highlightUpdatesSince = this.props.highlightUpdatesSince && dateService.parse(this.props.highlightUpdatesSince);
      if (highlightUpdatesSince && this.props.highlightedFieldsService) {
        const filter: ListFilter = this.props.highlightedFieldsService.createFilter({
          entityType: this.props.entityType,
          entityId,
          afterDate: highlightUpdatesSince,
        });
        this.props.highlightedFieldsService.getHighlightedFieldsFromEntries(filter, false, undefined).then(highlightedFields => {
          this.setState(() => ({
            highlightedFields: highlightedFields,
          }), this.updateHeader);
        });
      }
      this.registerEntityUpdateListener(entityId);
    }
  }

  private registerEntityUpdateListener(entityId: string) {
    if (this.unregisterEntityUpdateListener) {
      this.unregisterEntityUpdateListener();
      this.unregisterEntityUpdateListener = undefined;
    }
    if (this.props.highlightedFieldsService) {
      const filter = this.props.highlightedFieldsService.createFilter({
        entityType: this.props.entityType,
        entityId,
      });
      this.unregisterEntityUpdateListener = this.props.highlightedFieldsService.registerHighlightedFieldsListener(
        filter,
        true,
        undefined,
        (highlightedFields: FieldHighlightMap) => {
          this.setState(state => ({
            highlightedFields: {
              ...state.highlightedFields,
              ...highlightedFields,
            },
          }), this.updateHeader);
          if (highlightedFields && Object.keys(highlightedFields).length > 0) {
            const feedbackKey = "Activity:" + entityId;
            feedbackService.open({
              key: feedbackKey,
              title: messages.get(this.props.entityType.toLowerCase() + ".activitystream.updated"),
              variant: "WARNING",
              duration: "INDEFINITE",
              closeActions: [
                {
                  label: messages.get(MessageKey.CORE.REFRESH),
                  action: () => {
                    this.reloadEntityProxy();
                    feedbackService.close(feedbackKey);
                  },
                },
                {
                  label: messages.get(MessageKey.CORE.IGNORE),
                  action: () => feedbackService.close(feedbackKey),
                },
              ],
            });
          }
        }
      );
    }
  }

  private resetEntity() {
    this.setState(state => {
      const entity = createEntityFromTemplate(state.newEntityTemplate);
      const initializedEntity = this.applyInitialValues(entity, state.formConfig);
      return {entity: initializedEntity};
    });
  }


}

function createEntityFromTemplate(newEntityTemplate: object | object[] | undefined): object | object[] | undefined {
  if (newEntityTemplate) {
    return Array.isArray(newEntityTemplate) ? [...newEntityTemplate] : cloneDeep(newEntityTemplate);
  }
  return undefined;
}

type FormViewModelData = {
  entityUrlTemplate?: string
  entityTemplate?: string
  formConfigUrlTemplate?: string
  entityAndFormConfigUrlTemplate?: string
  formSubmitUrl?: string
  formDownloadUrl?: string
  entity: any
  entityBinding?: string
  entityType: string
  formConfigurationBinding?: string
  highlightUpdatesSince?: string
  actionContextId: string
  allowRefresh: boolean,
  allowDelete: boolean,
  printRequested: boolean,
}


const FormView = (props: IViewProps<FormViewModelData>) => {
  const highlightedFieldsService = useService("HIGHLIGHTED_FIELDS_SERVICE");
  return (
    <FormViewComponent {...props.viewModel.viewModelData}
                       highlightedFieldsService={highlightedFieldsService}
                       setViewActionHandlers={props.setViewActionHandlers}
    />
  );
};

export const formViewDescriptor: ViewDescriptor<FormViewModelData> = {
  viewType: "FORM_VIEW",
  view: FormView,
  initializeViewModelData: viewParameters => {
    const entityType = ParameterUtilities.getResolvedParameterValue("entityType", viewParameters, isString) ?? "UNKNOWN";
    const entityUrlTemplate = ParameterUtilities.getResolvedParameterValue("entityUrl", viewParameters, isString);
    const entityTemplate = ParameterUtilities.getResolvedParameter("entity", viewParameters.genericParameters);
    const formConfigUrlTemplate = ParameterUtilities.getResolvedParameterValue("formConfigurationUrl", viewParameters, isString);
    const entityAndFormConfigUrlTemplate = ParameterUtilities.getResolvedParameterValue("entityAndFormConfigurationUrl", viewParameters, isString);
    const formSubmitUrl = ParameterUtilities.getResolvedParameterValue("formSubmitUrl", viewParameters, isString);
    const formDownloadUrl = ParameterUtilities.getResolvedParameterValue("formDownloadUrl", viewParameters, isString);

    const entityBinding = ParameterUtilities.getResolvedParameterValue("entityBinding", viewParameters, isString);
    const formConfigurationBinding = ParameterUtilities.getResolvedParameterValue("formConfigurationBinding", viewParameters, isString);

    const actionContextId = ParameterUtilities.getResolvedParameterValue("actionContextId", viewParameters, isString) ?? "";

    const allowRefresh = ParameterUtilities.getResolvedParameterValue("allowRefresh", viewParameters, isString) ?? "true";
    const allowDelete = ParameterUtilities.getResolvedParameterValue("allowDelete", viewParameters, isString) ?? "true";

    return {
      entity: {}, // TODO PICM-1501 section entity
      entityType,
      entityUrlTemplate,
      entityTemplate,
      formConfigUrlTemplate,
      entityAndFormConfigUrlTemplate,
      formSubmitUrl,
      formDownloadUrl,
      entityBinding,
      formConfigurationBinding,
      actionContextId,
      allowRefresh: allowRefresh === "true",
      allowDelete: allowDelete === "true",
      printRequested: false,
    };
  },
  getTitle: (viewModel) => viewModel.viewLabel ?? "TODO",
  createUniqueHash: (viewModelData) => {
    // a form should be unique based on the given form configuration
    // this holds as long as we do not use FORM_VIEW for entities from the root navigation
    return `FORM_VIEW_${viewModelData.formConfigUrlTemplate ?? viewModelData.entityAndFormConfigUrlTemplate}`;
  },
  getViewActionDescriptors: (viewModel, getMessage) => ({
    REFRESH: {
      icon: "refresh",
      title: getMessage(MessageKey.CORE.REFRESH),
      visible: viewModel.viewModelData.allowRefresh,
    },
    SAVE: {
      icon: "save",
      title: getMessage(MessageKey.CORE.SAVE),
      visible: !!viewModel.viewModelData.formSubmitUrl,
    },
    DELETE: {
      icon: "delete_outline",
      title: getMessage(MessageKey.CORE.FORM.RESET),
      visible: viewModel.viewModelData.allowDelete,
    },
    DOWNLOAD: {
      icon: "cloud_download",
      title: getMessage(MessageKey.CORE.DOWNLOAD._),
      visible: !!viewModel.viewModelData.formDownloadUrl,
    },
  }),
};

export const formComponentDescriptor: NamedComponentDescriptor<FormViewModelDataWithServices> = {
  name: FORM_VIEW_COMPONENT,
  component: FormViewComponent,
  initializeProps: (viewParameters) => {
    return {
      ...formViewDescriptor.initializeViewModelData(viewParameters),
    };
  },
};
