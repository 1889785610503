/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CommonIconColor, CommonIconProps, IcmIconType} from "@icm/core-common";
import {SvgIcon, SvgIconProps} from "@mui/material";
import * as React from "react";

/**
 * Icon to use with @material-ui.
 * @see IcmIcon IcmIcon for available icons
 */
export const IcmMuiIcon = React.memo(({name, iconColor, size, ...props}: CommonIconProps & SvgIconProps): JSX.Element => {
  const Icon = name && IcmMuiIcons[name] ? React.lazy(IcmMuiIcons[name]) : SvgIcon;
  const htmlColor = ((typeof iconColor) === "string") ? iconColor : undefined;
  const color: CommonIconColor | undefined = iconColor as CommonIconColor;
  return (
    <React.Suspense fallback={<SvgIcon {...props} htmlColor={htmlColor} />}>
      <Icon {...props} color={color} htmlColor={htmlColor} fontSize={size} />
    </React.Suspense>
  );
});

const IcmMuiIcons: Record<IcmIconType, () => Promise<{default: typeof SvgIcon }>> = {
  account_switch: () => import("mdi-material-ui/AccountSwitch"),
  add: () => import("mdi-material-ui/Plus"),
  add_comment: () => import("mdi-material-ui/CommentPlus"),
  alert_circle: () => import("mdi-material-ui/AlertCircle"),
  alert_empty: () => import("mdi-material-ui/BellOutline"),
  anchor: () => import("mdi-material-ui/Anchor"),
  archive: () => import("mdi-material-ui/Archive"),
  arrow_back: () => import("mdi-material-ui/ArrowLeft"),
  arrow_downward: () => import("mdi-material-ui/ArrowDown"),
  arrow_drop_down: () => import("mdi-material-ui/ChevronDown"),
  arrow_forward: () => import("mdi-material-ui/ArrowRight"),
  arrow_upward: () => import("mdi-material-ui/ArrowUp"),
  assignment: () => import("mdi-material-ui/ClipboardText"),
  badge_account_horizontal: () => import("mdi-material-ui/BadgeAccountHorizontal"),
  bookmark: () => import("mdi-material-ui/Bookmark"),
  bookmark_border: () => import("mdi-material-ui/BookmarkOutline"),
  broken_image: () => import("mdi-material-ui/ImageBrokenVariant"),
  build: () => import("mdi-material-ui/Wrench"),
  calendar_today: () => import("mdi-material-ui/CalendarToday"),
  call: () => import("mdi-material-ui/Phone"),
  category: () => import("mdi-material-ui/FormatListBulletedType"),
  chart_box: () => import("mdi-material-ui/ChartBoxOutline"),
  chat: () => import("mdi-material-ui/Chat"),
  check: () => import("mdi-material-ui/Check"),
  check_circle: () => import("mdi-material-ui/CheckCircle"),
  circle_medium: () => import("mdi-material-ui/CircleMedium"),
  close: () => import("mdi-material-ui/Close"),
  cloud_connected: () => import("mdi-material-ui/Cloud"),
  cloud_disconnected: () => import("mdi-material-ui/CloudOffOutline"),
  cloud_download: () => import("mdi-material-ui/CloudDownload"),
  create_chat: () => import("mdi-material-ui/ChatPlus"),
  date_range: () => import("mdi-material-ui/CalendarRange"),
  death: () => import("mdi-material-ui/Alert"),
  delete: () => import("mdi-material-ui/Delete"),
  delete_outline: () => import("mdi-material-ui/DeleteOutline"),
  edit: () => import("mdi-material-ui/Pencil"),
  email_send: () => import("mdi-material-ui/EmailArrowRight"),
  export_as_file: () => import("mdi-material-ui/FileExport"),
  filter: () => import("mdi-material-ui/Filter"),
  filter_remove: () => import("mdi-material-ui/FilterRemove"),
  find_chat: () => import("mdi-material-ui/CommentSearch"),
  fire: () => import("mdi-material-ui/Fire"),
  flask: () => import("mdi-material-ui/Flask"),
  forum: () => import("mdi-material-ui/Forum"),
  hazard: () => import("mdi-material-ui/Biohazard"),
  helicopter: () => import("mdi-material-ui/Helicopter"),
  image_multiple: () => import("mdi-material-ui/ImageMultiple"),
  information: () => import("mdi-material-ui/Information"),
  injured: () => import("mdi-material-ui/Ambulance"),
  list: () => import("mdi-material-ui/FormatListBulletedSquare"),
  list_alt: () => import("@mui/icons-material/ListAlt"),
  local_shipping: () => import("mdi-material-ui/Truck"),
  logout: () => import("mdi-material-ui/Logout"),
  map: () => import("mdi-material-ui/Map"),
  map_plus: () => import("mdi-material-ui/MapPlus"),
  merge_type: () => import("mdi-material-ui/CallMerge"),
  message_processing: () => import("mdi-material-ui/MessageProcessing"),
  minus: () => import("mdi-material-ui/Minus"),
  more: () => import("mdi-material-ui/More"),
  movie: () => import("mdi-material-ui/Movie"),
  notifications: () => import("mdi-material-ui/Bell"),
  open_in_browser: () => import("mdi-material-ui/OpenInApp"),
  open_in_new: () => import("mdi-material-ui/OpenInNew"),
  palette: () => import("mdi-material-ui/Palette"),
  paperclip: () => import("mdi-material-ui/Paperclip"),
  picture_as_pdf: () => import("mdi-material-ui/FilePdfBox"),
  place: () => import("mdi-material-ui/MapMarker"),
  playlist_play: () => import("mdi-material-ui/PlaylistPlay"),
  play_arrow: () => import("mdi-material-ui/Play"),
  play_circle_filled: () => import("mdi-material-ui/PlayCircle"),
  play_circle_outline: () => import("mdi-material-ui/PlayCircleOutline"),
  print: () => import("mdi-material-ui/Printer"),
  public_interest: () => import("mdi-material-ui/AccountGroup"),
  qr_code: () => import("mdi-material-ui/Qrcode"),
  quadcopter: () => import("mdi-material-ui/Quadcopter"),
  refresh: () => import("mdi-material-ui/Refresh"),
  rss_feed: () => import("mdi-material-ui/Rss"),
  save: () => import("mdi-material-ui/ContentSave"),
  save_move: () => import("mdi-material-ui/ContentSaveMove"),
  send: () => import("mdi-material-ui/Send"),
  share: () => import("mdi-material-ui/Share"),
  show_chart: () => import("mdi-material-ui/ChartLineVariant"),
  shuffle: () => import("mdi-material-ui/Shuffle"),
  star: () => import("mdi-material-ui/Star"),
  train: () => import("mdi-material-ui/Train"),
  unread_alert: () => import("mdi-material-ui/BellRing"),
  view_stream: () => import("mdi-material-ui/ViewStream"),
  visibility: () => import("mdi-material-ui/Eye"),
  voicemail: () => import("mdi-material-ui/Voicemail"),
};
