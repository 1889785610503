/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchService, HttpHeaderName} from "@icm/core-common";

import {EntityCommand, EntityCommandProcessResult} from "../generated/api";

export class EntityCommandApi {
  public static processEntityCommands(entityCommands: EntityCommand[], formVariantName?: string): Promise<EntityCommandProcessResult> {
    const additionalHeaders: Record<string, string> = {};
    if (formVariantName) {
      additionalHeaders[HttpHeaderName.FORM_VARIANT_NAME] = formVariantName;
    }
    return FetchService.performPutWithJsonResult("entity/entityCommands", entityCommands, {additionalHeaders: additionalHeaders})
      .then(result => {
        return EntityCommandProcessResult.fromData(result);
      });
  }
}
