/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ModuleRegistrationOptions, registerNamedComponentFactory} from "@icm/core-common";
import {COMPLETE_PROCESS_TASK_ICON_BUTTON, COMPLETE_PROCESS_TASK_MENU_ITEM, PROCESS_MODEL} from "@icm/workflow-common";

import {completeProcessTaskIconButtonFactory} from "./src/components/form/process/CompleteProcessTaskIconButton";
import {completeProcessTaskMenuItemFactory} from "./src/components/form/process/CompleteProcessTaskMenuItem";
import {ProcessModelComponent} from "./src/components/form/process/ProcessModelComponent";
import packageInfo from "./src/generated/package.clone.json";

export const workflowWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  runPostInitialization: (serviceRegistry) => {
    registerNamedComponentFactory(COMPLETE_PROCESS_TASK_ICON_BUTTON, completeProcessTaskIconButtonFactory);
    registerNamedComponentFactory(COMPLETE_PROCESS_TASK_MENU_ITEM, completeProcessTaskMenuItemFactory);
    registerNamedComponentFactory(PROCESS_MODEL, ProcessModelComponent);
  },
};
