/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SystemStyleObject} from "@mui/system";

import {IcmMuiTheme} from "./IcmMuiTheme";

// derived from `@mui/system/SxProps`
type IcmStyle = SystemStyleObject<IcmMuiTheme> | ((theme: IcmMuiTheme) => SystemStyleObject<IcmMuiTheme>);

/**
 * This function serves to type the created styles correctly using the IcmMuiTheme.
 * @param styles
 */
export function createStyles<ClassKey extends string>(styles: Record<ClassKey, IcmStyle>): Record<ClassKey, IcmStyle> {
  return styles;
}
