/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  arePropsEqual,
  DataTableCellProps,
  DataTableTextColumn,
  FetchService,
  FileReference, numberService,
  resolveRowTextElementIcon,
  SecurityService,
  Urls,
  useActionHandler,
  useService,
} from "@icm/core-common";
import {CircularProgress, Link, Typography} from "@mui/material";
import {Box} from "@mui/system";
import clsx from "clsx";
import * as React from "react";
import {JSXElementConstructor, ReactElement, ReactFragment, ReactNode, ReactPortal, useEffect, useState} from "react";

import {FormCallButton} from "../../../call";
import {listComponentRegistry} from "../../ListComponentRegistry";
import {useDataTableContext} from "./DataTableContext";
import {useDataTableStyles} from "./DataTableStyle";

export type DataTableDataCellContentProps<T> = DataTableCellProps<T> & {
  col: DataTableTextColumn<T>
  rowEntity: any
}

type RenderValueType = string | number | true | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal;

export const DataTableDataCellContent = React.memo(<T extends unknown>({
  rowValue,
  rowEntity,
  col,
}: DataTableDataCellContentProps<T>) => {
  const classes = useDataTableStyles();
  const value = col.valueAccessor(rowValue);
  const component = col.component ? listComponentRegistry[col.component] : undefined;
  const {actionHandlers} = useDataTableContext();
  const actionHandler = useActionHandler(actionHandlers);
  const [renderedValue, setRenderedValue] = useState<RenderValueType>(<CircularProgress />);
  const securityService = useService("SECURITY");

  useEffect(() => {
    renderValue(col, value, rowEntity, securityService).then(e => {
      if (e) {
        setRenderedValue(e);
      } else {
        setRenderedValue(<></>);
      }
    });
  }, [col, value, rowEntity, securityService]);

  function clickHandler(ev: React.MouseEvent) {
    if (col.onClickAction) {
      ev.stopPropagation();
      ev.preventDefault();
      actionHandler(col.onClickAction, {context: rowValue});
    }
  }

  const noWrap = col.format === "DATE" || col.format === "TIME" || numberService.isNumber(value);
  const isShort = typeof value === "string" && value.length < 20;
  return (
    <OptionalComponentWrapper Component={component} componentProps={{entity: rowValue}}>
      {col.onClickAction ? (
        <Link className={clsx({[classes.noWordBreak]: isShort})}
              noWrap={noWrap}
              variant="inherit"
              color="inherit"
              href="#"
              onClick={(ev) => clickHandler(ev)}
        >
          {renderedValue}
        </Link>
      ) : (
        <Typography className={clsx({[classes.noWordBreak]: isShort})}
                    noWrap={noWrap}
                    variant="inherit"
                    component="div"
        >
          {renderedValue}
        </Typography>
      )}
    </OptionalComponentWrapper>
  );
}, arePropsEqual);

const renderValue = (col: DataTableTextColumn<any>, value: unknown, rowEntity: any, securityService: SecurityService): Promise<ReactNode> => {
  const config = col.format.split(":");
  const name = config.length > 0 ? config[0] : "";
  if (name === "CALL") {
    const relatedObject = {
      id: rowEntity.id,
      type: rowEntity.type,
      dynamicAttributes: rowEntity.dynamicAttributes,
      object: rowEntity,
    };
    return Promise.resolve(
      <Box display="flex" alignItems="center">
        {col.formatValue(value)}
        <FormCallButton calledNumber={value} format={col.format} relatedObject={relatedObject} />
      </Box>
    );
  }
  if (col.format === "THUMBNAIL") {
    if (!value) {
      return Promise.resolve(<></>);
    }
    const file: FileReference = value as FileReference;
    const displaySize: number = 64;
    const url: string = `${Urls.FILE_THUMBNAIL}/${file.id}?pixelSize=${displaySize * 2}`;
    return FetchService.performGetBinary(url, {catchableErrorCodes: [404]})
      .then(data => {
        const dataUrl = URL.createObjectURL(data.blob);
        return <img style={{width: displaySize}} src={dataUrl} alt={"Preview for " + file.name} />;
      })
      .catch(() => {
        return <></>;
      });
  } else {
    return Promise.resolve(
      <Box display="flex" alignItems="center">
        {col.icon && (
        <Box paddingRight={0.5} display="flex" alignItems="center" fontSize="1.5rem">
          {resolveRowTextElementIcon(col.icon, rowEntity, securityService, value)}
        </Box>
        )}
        {col.formatValue(value)}
      </Box>
    );
  }
};

type OptionalComponentProps<PropsType> = {
  Component?: React.FC<PropsType>
  componentProps?: PropsType
  children: React.ReactNode
}

const OptionalComponentWrapper = <PropsType extends object>({
  Component,
  componentProps,
  children,
}: OptionalComponentProps<PropsType>) => {
  if (Component && componentProps) {
    return <Component {...componentProps}>{children}</Component>;
  } else {
    return <>{children}</>;
  }
};
