/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMemo} from "react";


import {ClientFilterSelection, createTemporaryFilter, useFallbackClientFilterSelection} from "../../components";
import {
  useCancelEditSharedFilterSelection,
  useSaveSharedFilterSelection,
  useSetSharedFilterSelection,
  useSharedFilterState,
  useStartEditSharedFilterSelection,
} from "./hooks";
import {SharedFilterStateController} from "./SharedFilterStateController";
import {EDITING, INITIAL, SAVING} from "./SharedFilterStateName";

/**
 * An object to manipulate the state of the SharedFilterState object.
 *
 * @param entityType
 * @param variant the variant of the filter.
 * @param defaultFilterSelection
 */
export const useSharedFilterStateController = (entityType?: string,
                                               variant?: string,
                                               defaultFilterSelection?: ClientFilterSelection): SharedFilterStateController => {
  const sharedFilterState = useSharedFilterState(entityType, variant, defaultFilterSelection);

  const setSharedFilterSelection = useSetSharedFilterSelection();
  const startEditSharedFilterSelection = useStartEditSharedFilterSelection();
  const stopEditSharedFilterSelection = useCancelEditSharedFilterSelection();
  const saveEditSharedFilterSelection = useSaveSharedFilterSelection();

  const fallbackFilter = useFallbackClientFilterSelection(entityType);

  return useMemo(() => ({
      setCurrentFilterSelection(selection: ClientFilterSelection | undefined) {
        if (entityType && sharedFilterState) {
          setSharedFilterSelection(entityType, sharedFilterState.variant, selection ?? fallbackFilter);
        }
      },
      currentFilterSelection: sharedFilterState?.filterSelection?.id ? sharedFilterState.filterSelection : fallbackFilter,
      fallbackFilterSelection: fallbackFilter,
      canEditCurrentFilterSelection(): boolean {
        if (sharedFilterState?.filterSelection) {
          return sharedFilterState.filterSelection.readOnly !== true
            && ![EDITING, SAVING, INITIAL].includes(sharedFilterState.stateName);
        }
        return false;
      },
      canDeleteCurrentFilterSelection(): boolean {
        if (sharedFilterState?.filterSelection) {
          return sharedFilterState.filterSelection.readOnly !== true
            && sharedFilterState.filterSelection.notDeletable !== true
            && ![EDITING, SAVING, INITIAL].includes(sharedFilterState.stateName);
        }
        return false;
      },
      startEditing(createNew: boolean) {
        if (!entityType) {
          console.warn("Can not start editing without an entity type.");
          return;
        }
        if (!sharedFilterState) {
          console.warn("Can not start editing without a shared filter state.");
          return;
        }
        if (createNew) {
          const newFilterSelection = createTemporaryFilter(entityType);
          startEditSharedFilterSelection(entityType, sharedFilterState.variant, newFilterSelection);
        } else {
          startEditSharedFilterSelection(entityType, sharedFilterState.variant);
        }
      },
      startEditingFilter(filter: ClientFilterSelection) {
        if (!sharedFilterState) {
          console.warn("Can not start editing without a shared filter state.");
          return;
        }
        if (entityType) {
          startEditSharedFilterSelection(entityType, sharedFilterState.variant, filter);
        }
      },
      cancelEditing() {
        if (!sharedFilterState) {
          console.warn("Can not cancel editing without a shared filter state.");
          return;
        }
        if (entityType) {
          stopEditSharedFilterSelection(entityType, sharedFilterState.variant);
        }
      },
      save() {
        if (!sharedFilterState) {
          console.warn("Can not start edting without a shared filter state.");
          return;
        }
        if (entityType) {
          saveEditSharedFilterSelection(entityType, sharedFilterState.variant);
        }
      },
      isSaving(): boolean {
        return sharedFilterState?.stateName === SAVING;
      },
      isEditing(): boolean {
        return sharedFilterState?.stateName === EDITING;
      },
      isReady(): boolean {
        return sharedFilterState ? sharedFilterState.stateName !== INITIAL : false;
      },
    }),
  [
    sharedFilterState,
    fallbackFilter,
    setSharedFilterSelection,
    entityType,
    startEditSharedFilterSelection,
    stopEditSharedFilterSelection,
    saveEditSharedFilterSelection,
  ]);
};
