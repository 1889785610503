/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Skeleton, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";

import {useDataTableStyles, DataTableRowHeader, SingleCellRow} from "../common";
import {ColumnWithWidth} from "./VirtualizedInfiniteTable";
import {getCellStyle} from "./VirtualizedTableUtil";

const LoadingDataRowComponent = ({columns}: {columns?: ColumnWithWidth<any>[]}) => {
  const classes = useDataTableStyles();
  if (columns) {
    return (
      <TableRow component="div" className={classes.flexRow}>
        <DataTableRowHeader />
        {columns.map((column) => {
          const cellStyle = getCellStyle(column);
          return (
            <TableCell component={"div" as any}
                       key={column.name}
                       className={classes.dataCell}
                       style={cellStyle}
            >
              <Typography variant="inherit" style={{width: "100%"}}>
                <Skeleton />
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  } else {
    return (
      <SingleCellRow>
        <Typography variant="inherit" style={{width: "100%"}}>
          <Skeleton />
        </Typography>
      </SingleCellRow>
    );
  }
};

export const LoadingDataRow = React.memo(LoadingDataRowComponent);
