/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ObjectUpdate} from "@icm/activitystream-common";
import {RowTextElement} from "@icm/core-common";

import {EntityProperties, getDynamicAttributeName} from "../data";

export const isUpdateRelevant = <T>(update: ObjectUpdate<T>, textElement: RowTextElement): boolean => {
  // updatedAt always changes
  if (textElement.valueBinding === EntityProperties.UPDATED_AT_PROPERTY) {
    return true;
  }
  // createdAt is only relevant when the entity was just created
  if (textElement.valueBinding === EntityProperties.CREATED_AT_PROPERTY) {
    return update.type === "CREATE";
  }
  const relevantPropertyUpdate = update.propertyUpdates?.find(propertyUpdate => {
    if (propertyUpdate.updateType === "VALUE" || propertyUpdate.updateType === "COLLECTION") {
      return getDynamicAttributeName(propertyUpdate.fieldReference?.valueBinding) === getDynamicAttributeName(textElement.valueBinding);
    }
    return false;
  });
  return !!relevantPropertyUpdate;
};
