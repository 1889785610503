/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CallButtonProps,
  CallType,
  openTelProtocolUrl,
  sendMarAPIRequest,
  sendMCSApiRequest,
  useCallButton,
  useMessages,
  useService,
} from "@icm/core-common";
import {Call as CallIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import * as React from "react";
import {FC} from "react";

export const FormCallButton: FC<CallButtonProps> = ({
  calledNumber,
  format,
  relatedObject,
}) => {
  const securityService = useService("SECURITY");
  const activityStreamService = useService("ACTIVITY_STREAM");
  const {getMessage} = useMessages();
  const callButtonHelper = useCallButton(format);
  if (!callButtonHelper) {
    return null;
  }
  const {
    callType,
    backendUrl,
  } = callButtonHelper;
  if (((CallType.MAR_CALL_API === callType || CallType.MCS_CALL_API === callType) && backendUrl) || (CallType.TEL_PROTOCOL === callType)) {
    return (
      <IconButton
        key="call"
        disabled={!calledNumber}
        onClick={() => {
          switch (callType) {
            case CallType.TEL_PROTOCOL:
              openTelProtocolUrl(calledNumber);
              break;
            case CallType.MAR_CALL_API:
              backendUrl && sendMarAPIRequest(calledNumber, backendUrl);
              break;
            case CallType.MCS_CALL_API:
              const userName: string | undefined = securityService.getCurrentUserDetails()?.userName;
              backendUrl && userName && sendMCSApiRequest(calledNumber, backendUrl, userName);
          }
          activityStreamService?.addCallInitiationActivityStreamEntry(calledNumber, securityService, getMessage, relatedObject);
        }}
        size="large"
      >
        <CallIcon />
      </IconButton>
    );
  } else {
    // format string not correct
    return null;
  }
};
