/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

export class HttpHeaderName {
  public static readonly USER_NAME = "ICM-User-Name";
  public static readonly ACTIVE_ROLE_NAMES = "ICM-Active-Role-Names";
  public static readonly USER_TIME_ZONE = "ICM-User-Time-Zone";
  public static readonly FORM_VARIANT_NAME = "ICM-Form-Variant-Name";
}
