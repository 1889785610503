/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/**
 * Validation type used to point a particular state field of the FieldValidationState.
 * "rule" - concerns "valid" rule from form field configuration,
 * "requirement" - concerns mandatory fields,
 * "parser" - concerns possible value inaccuracy in the case of a format / parser / valueParser,
 * "all" - used to set all of the flags at once.
 * */
export type FieldValidationType = "rule" | "requirement" | "parser" | "all";

export class FieldValidationState {
  private readonly validByRule: boolean = true;
  private readonly validByRequirement: boolean = true;
  private readonly validByParser: boolean = true;
  readonly valid: boolean = true;

  constructor(validByRule: boolean, validByRequirement: boolean, validByParser: boolean) {
    this.validByRule = validByRule;
    this.validByRequirement = validByRequirement;
    this.validByParser = validByParser;
    this.valid = validByRule && validByRequirement && validByParser;
  }

  static init(): FieldValidationState {
    return new FieldValidationState(true, true, true);
  }

  update(type: FieldValidationType, isValid: boolean): FieldValidationState {
    if (type === "all") {
      return new FieldValidationState(isValid, isValid, isValid);
    }

    const byRule = type === "rule" ? isValid : this.validByRule;
    const byRequirement = type === "requirement" ? isValid : this.validByRequirement;
    const byParser = type === "parser" ? isValid : this.validByParser;

    return new FieldValidationState(byRule, byRequirement, byParser);
  }

  validBy(validationType: FieldValidationType) {
    switch (validationType) {
      case "rule": {
        return this.validByRule;
      }
      case "requirement": {
        return this.validByRequirement;
      }
      case "parser": {
        return this.validByParser;
      }
      case "all": {
        return this.valid;
      }
      default: {
        return true;
      }
    }
  }
}
