/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  ActionRunner,
  CommunicationChannel,
  CommunicationChannelMapping,
  isCommunicationAddress,
  isCommunicationChannel,
  MessageKey,
  ParameterUtilities,
  ResolvedParameterList,
  TranslatedLabel,
  useDialogContext,
  useFeedbackBanners,
} from "@icm/core-common";
import {
  Address,
  AddressFieldInput,
  AddressSource,
  EmptyMessageTemplateProvider,
  LoadingSpinner,
  MessageEditor,
  useAddressFieldController,
  useMessageEditController,
} from "@icm/core-web";
import {useEntityEditModelGetter} from "@icm/entity-common";
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {isString} from "lodash-es";
import React, {useCallback, useState} from "react";


import {useSendMessageController} from "./useSendMessageController";


export type SendMessageDialogProps = {

  /** Text to be shown as main title */
  title: string,

  /** Text to be shown as second title */
  description?: string,

  /** Method to be called when the dialog is cancelled */
  cancel: () => void

  /** Channel to be used. */
  channel: CommunicationChannel

  addressSource: AddressSource,

  parentEntityType: string;

  parentEntityId: string;

}

function hasSubject(channel: CommunicationChannel) {
  return channel === CommunicationChannel.EMAIL;
}

/**
 * A dialog to compose and send a message on confirmation.
 *
 * @param props
 * @constructor
 */
export const SendMessageDialog = (props: SendMessageDialogProps) => {
  const {title, description, cancel, channel, addressSource, parentEntityType, parentEntityId} = props;
  const {setDialog} = useDialogContext();
  const {openFeedbackBanner} = useFeedbackBanners();

  const [sending, setSending] = useState(false);

  const addressType = CommunicationChannelMapping.getMessageAddressType(channel);

  const addressFieldController = useAddressFieldController({addressSource, addressType});
  const messageEditController = useMessageEditController({templateProvider: EmptyMessageTemplateProvider, hasSubject: hasSubject(channel)});
  const sendMessageController = useSendMessageController({addressFieldController, messageEditController, parentEntityType, parentEntityId, channel});

  const sendMessage = useCallback(() => {
    setSending(true);
    sendMessageController.sendMessage().then(response => {
      console.log("MessageDialog Response: ", response);
      setSending(false);
      setDialog(null);
      openFeedbackBanner({
        key: "SEND_MESSAGE_SUCCESS",
        title: "Message has been sent.",
        duration: "MEDIUM",
        variant: "SUCCESS",
      });
    }).catch(error => {
      setSending(false);
      console.error("MessageDialog: failed to start communication", error);
      openFeedbackBanner({
        key: "SEND_MESSAGE_ERROR",
        title: "Failed to send message. ",
        duration: "MEDIUM",
        variant: "ERROR",
      });
    });
  }, [sendMessageController, setDialog, setSending, openFeedbackBanner]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="lg" onClose={cancel}>
      <DialogTitle>
        {title}
        {description && (
          <DialogContentText>
            {description}
          </DialogContentText>
        )}
      </DialogTitle>
      <DialogContent>
        {sending && (<LoadingSpinner />)}
        <AddressFieldInput controller={addressFieldController} />
        <MessageEditor messageController={messageEditController} />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} variant="outlined" disabled={sending}>
          <TranslatedLabel code={MessageKey.CORE.CANCEL} />
        </Button>
        <Button onClick={() => sendMessage()} variant="outlined" disabled={sending}>
          <TranslatedLabel code={MessageKey.CORE.SEND} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};


/**
 * Returns an ActionRunner (aka callback) to open a dialog that can be used to
 * send messages. The returned method takes the following parameters (usually mapped
 * from the XML configuration):
 *    - channel, the MessageChannel to be used (required)
 *    - title, the title of the dialog (optional)
 *    - description, the headline below the title (optional)
 *    - addresses, the addresses to be used
 *
 * @see MessageChannel
 */
export const useSendMessageDialogActionHandler = (): ActionRunner => {
  const {setDialog, cancel} = useDialogContext();

  const getEditModel = useEntityEditModelGetter();
  return useCallback((resolvedParameters) => {
    const parameterList: ResolvedParameterList = {genericParameters: resolvedParameters};
    const parentEntityType = ParameterUtilities.getResolvedParameterValue("parentEntityType", parameterList, isString);
    const parentEntityId = ParameterUtilities.getResolvedParameterValue("parentEntityId", parameterList, isString);
    const editModel = getEditModel(parentEntityId);
    const title = ParameterUtilities.getResolvedParameterValue("title", parameterList, isString);
    const description = ParameterUtilities.getResolvedParameterValue("description", parameterList, isString);
    const channel = ParameterUtilities.getResolvedParameterValue("channel", parameterList, isCommunicationChannel);
    const addresses = ParameterUtilities.getResolvedParameterValue("addresses", parameterList, Array.isArray);
    const addressSource: AddressSource = {

      getAvailableAddresses(): Address[] {
        if (addresses) {
          return addresses
            .filter(isCommunicationAddress)
            .filter(a => a.channel === channel);
        }
        return [];
      },
      getDefaultSelectedAddresses(): Readonly<Address[]> {
        if (addresses) {
          return addresses
            .filter(isCommunicationAddress)
            .filter(a => a.channel === channel);
        }
        return [];
      },
    };


    if (channel && title && parentEntityType && parentEntityId && !editModel?.draft) {
      setDialog(<SendMessageDialog title={title}
                                   addressSource={addressSource}
                                   description={description}
                                   channel={channel}
                                   cancel={cancel}
                                   parentEntityType={parentEntityType}
                                   parentEntityId={parentEntityId}
      />);
    } else {
      console.error("Message dialog cannot be opened, properties", {channel, title, parentEntityType, parentEntityId, draft: editModel?.draft}, "Specify a valid configuration");
    }
  }, [cancel, setDialog, getEditModel]);
};
