/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CollectionModifier, createCancelable, objectQueryKey, useService} from "@icm/core-common";
import moment from "moment";
import {useEffect, useMemo} from "react";
import {InfiniteData, useInfiniteQuery, useQueryClient} from "react-query";

import {ActivityStreamApi} from "../api";
import {ActivityStreamEntry, ActivityStreamEntryListPortion} from "../generated/api";
import {isObjectChange, isOtherChange} from "../util";
import {ActivityStreamService} from "./ActivityStreamService";

export type ActivityStreamEntryWithHighlight = ActivityStreamEntry & {
  highlightUntil: Date | null
}

export type FilterParameters = {
  entityType?: string
  entityId?: string
  collectionModifier: CollectionModifier
};

type EntryPage = {
  entries: ActivityStreamEntryWithHighlight[]
  hasMore: boolean
  index: number
}

const PAGE_SIZE: number = 20;

// used as default in setQueryData
const NO_ENTRIES: InfiniteData<EntryPage> = {pages: [{index: 0, entries: [], hasMore: false}], pageParams: [undefined]};

export const useActivityStreamEntries = (filterReady: boolean, filterParameters: FilterParameters) => {
  const {entityType, entityId, collectionModifier} = filterParameters;
  const queryClient = useQueryClient();

  const securityService = useService("SECURITY");
  const activityStreamService = useMemo(() => new ActivityStreamService(securityService), [securityService]);

  const queryKey = useMemo(() => ["private", "activityStream", objectQueryKey(filterParameters)], [filterParameters]);

  const result = useInfiniteQuery(queryKey,
    ({
      pageParam = {
        page: 0,
        // number of entries that were added on page0 by live updates
        newEntriesOnPage0: 0,
      },
    }) => {
      const listFilter = activityStreamService.createFilter({
        entityType,
        entityId,
        collectionModifiers: [collectionModifier],
        offset: pageParam.page * PAGE_SIZE + pageParam.newEntriesOnPage0,
        limit: PAGE_SIZE,
      });
      return createCancelable(
        abortController => ActivityStreamApi.getEntries(listFilter, {abortController})
          .then(portion => createEntryPage(pageParam.page, portion, false))
      );
    },
    {
      getNextPageParam: (lastPage, [firstPage]) => lastPage.hasMore
        ? {page: lastPage.index + 1, newEntriesOnPage0: Math.max(firstPage.entries.length - PAGE_SIZE, 0)}
        : undefined,
      getPreviousPageParam: () => undefined, // future entries are added only through live updates
      select: data => {
        return {
          pages: data.pages.map(page => page.entries),
          pageParams: data.pageParams,
        };
      },
      refetchOnWindowFocus: false,
      enabled: filterReady,
    });

  useEffect(() => {
    // register for live updates
    const listFilter = activityStreamService.createFilter({
      entityType,
      entityId,
      collectionModifiers: [collectionModifier],
      offset: 0,
      limit: PAGE_SIZE,
    });
    const unregisterEntryListener = activityStreamService.registerListener(listFilter,
      (matchingEntries, nonMatchingEntries) => {
        queryClient.setQueryData<InfiniteData<EntryPage>>(queryKey, (existing = NO_ENTRIES) => {
          const newEntries = [
            ...getEntriesToAppendFromNonMatchingEntries(nonMatchingEntries, matchingEntries, ...existing.pages.map(p => p.entries)),
            ...matchingEntries,
          ].map(e => highlightEntry(e, true));
          const refreshedFirstPage = {...existing.pages[0], entries: [...newEntries, ...existing.pages[0].entries]};
          const pages = [refreshedFirstPage, ...existing.pages.slice(1)];
          return {pages, pageParams: existing.pageParams};
        },
        {

        });
      });
    return () => {
      unregisterEntryListener();
      queryClient.setQueryData<InfiniteData<EntryPage>>(queryKey, (existing = NO_ENTRIES) => {
        // only keep first page in cache on unmount/filter change
        // (otherwise react-query would try to refetch all pages in the cache (which could take a while))
        const newPages = existing.pages.slice(0, 1).map(page => ({
          ...page,
          // unhighlight all changes
          entries: page.entries.map(entry => highlightEntry(entry, false)),
        }));
        return {
          pages: newPages,
          pageParams: existing.pageParams.slice(0, 1),
        };
      });
    };
  }, [activityStreamService, collectionModifier, entityId, entityType, queryClient, queryKey]);

  return result;
};

function highlightEntry(entry: ActivityStreamEntry, highlight: boolean): ActivityStreamEntryWithHighlight {
  return {...entry, highlightUntil: highlight ? moment().add(5, "second").toDate() : null};
}

function createEntryPage(index: number, portion: ActivityStreamEntryListPortion, highlight: boolean): EntryPage {
  return {
    index,
    entries: portion.sublist?.map(entry => highlightEntry(entry, highlight)) ?? [],
    hasMore: portion.havingMore ?? false,
  };
}

/**
 * Returns an array of entries that shall be appended to the activity stream despite not matching the filter.
 * (To show the changes, that caused the drop off the filter)
 *
 * @param nonMatchingEntries entries that not match the filter
 * @param consideredEntries entries already in the activity stream
 */
function getEntriesToAppendFromNonMatchingEntries(
  nonMatchingEntries: ActivityStreamEntry[],
  ...consideredEntries: ActivityStreamEntry[][]
): ActivityStreamEntry[] {
  const objectRefIdsMatchingCurrentFilter = getObjectRefIdsThatMatchCurrentFilterFromNested(...consideredEntries);
  const newEntries: ActivityStreamEntry[] = [];
  for (const entry of nonMatchingEntries) {
    for (const change of entry.changes!) {
      if (isObjectChange(change) && change.objectReference?.id && objectRefIdsMatchingCurrentFilter.has(change.objectReference.id)) {
        newEntries.push({
          ...entry,
          changes: [{
            ...change,
            firstChangeNotMatchingFilter: true,
          }],
        });
      } else if (isOtherChange(change)) {
        newEntries.push(entry);
      }
    }
  }
  return newEntries;
}

function getObjectRefIdsThatMatchCurrentFilterFromNested(...entries: ActivityStreamEntry[][]) {
  const objectRefIdsMatchingCurrentFilter = new Set<string>();
  // need oldest entry first => iterate in reverse order
  for (let i = entries.length - 1; i >= 0; i--) {
    getObjectRefIdsThatMatchCurrentFilter(entries[i], objectRefIdsMatchingCurrentFilter);
  }
  return objectRefIdsMatchingCurrentFilter;
}

function getObjectRefIdsThatMatchCurrentFilter(entries: ActivityStreamEntry[], objectRefIdsMatchingCurrentFilter = new Set<string>()) {
  // need oldest entry first => iterate in reverse order
  for (let i = entries.length - 1; i >= 0; i--) {
    const entry = entries[i];
    for (const change of entry.changes!) {
      if (isObjectChange(change) && change.objectReference?.id) {
        if (change.firstChangeNotMatchingFilter) {
          objectRefIdsMatchingCurrentFilter.delete(change.objectReference.id);
        } else {
          objectRefIdsMatchingCurrentFilter.add(change.objectReference.id);
        }
      }
    }
  }
  return objectRefIdsMatchingCurrentFilter;
}
