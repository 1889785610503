/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DetailPanelRendererFactory} from "./DetailPanelRendererFactory";

export interface DetailPanelRendererFactoryKeys {
  KEY_VALUE: "KEY_VALUE",
  EXPANDABLE: "EXPANDABLE",
  SUBLIST: "SUBLIST"
}

export type DetailPanelRendererFactoryKey = keyof DetailPanelRendererFactoryKeys;

export type DetailPanelRendererFactories = Partial<Readonly<Record<DetailPanelRendererFactoryKey, DetailPanelRendererFactory>>>;

const factories: DetailPanelRendererFactories = {};

export const registerDetailPanelRendererFactory = (name: DetailPanelRendererFactoryKey, factory: DetailPanelRendererFactory) => {
  if (process.env.NODE_ENV === "development" && factories[name]) {
    console.error("Duplicate registration of ", name);
  }
  factories[name.valueOf()] = factory;
};

export const getDetailPanelRendererFactory = (name: DetailPanelRendererFactoryKey): DetailPanelRendererFactory | undefined => {
  if (process.env.NODE_ENV === "development" && !factories[name]) {
    console.error("Could not find factory ", name, " make sure you factory using register.");
  }
  return factories[name];
};
