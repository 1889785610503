/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IUiState} from "../types";

/**
 * Action that is used to change the current opened single view to the previous from the history if available.
 *
 * @param state
 * @constructor
 */
export const PopBackSingleView = (state: IUiState) => {
  const perspective = state.perspective;
  if (perspective?.perspectiveType === "SINGLE_VIEW") {
    const last = perspective.history.pop();
    if (last) {
      perspective.view = last;
    }
  } else {
    console.warn("Can't pop back View in non-SingleView perspective");
  }
};
