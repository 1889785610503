/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {EntityFormConfiguration, FetchService, ListConfiguration, queryClient, userDownloadService} from "@icm/core-common";

import {Entity, EntityConfiguration, EntityLifecycleConfiguration} from "../generated/api";

type FormConfigurationOptions = {
  entityType: string,
  entityId?: string,
  formVariant?: string,
  abortController?: AbortController
}

export interface EntityDataService {
  getLifecycleConfiguration(entityType: string, abortController?: AbortController): Promise<EntityLifecycleConfiguration>;
  getListConfiguration(entityType: string, variant?: string, abortController?: AbortController): Promise<ListConfiguration>;
  getFormConfiguration(options: FormConfigurationOptions): Promise<EntityFormConfiguration>;
  getEntityConfigurationForEntityAndRelated(entityType: string): Promise<Map<string, EntityConfiguration>>
  getEntityConfiguration(entityType: string): Promise<EntityConfiguration>
}

class DefaultEntityDataService implements EntityDataService {

  public getLifecycleConfiguration(entityType: string, abortController?: AbortController): Promise<EntityLifecycleConfiguration> {
    return queryClient.fetchQuery(["private", "core", "entities", "configuration", "lifecycle", entityType], () => {
      return FetchService.performGet<EntityLifecycleConfiguration>(`entity/lifecycleConfiguration?entityType=${entityType}`, {abortController})
        .catch(e => {
          console.warn("Could not load lifecycle for entity of type", entityType, e);
        });
    });
  }

  public getListConfiguration(entityType: string, variant?: string, abortController?: AbortController): Promise<ListConfiguration> {
    return FetchService.performGet(`entity/listConfiguration?entityType=${entityType}` + (variant ? `&variant=${variant}` : ""), {abortController})
      .then(json => ListConfiguration.fromData(json));
  }

  public getFormConfiguration({
    entityType,
    entityId,
    formVariant,
    abortController,
  }: FormConfigurationOptions): Promise<EntityFormConfiguration> {
    return FetchService.performGet(`entity/formConfiguration?entityType=${entityType}` + (formVariant ? `&variant=${formVariant}` : "") + (entityId ? `&entityId=${entityId}` : ""), {abortController})
      .then(json => EntityFormConfiguration.fromData(json));
  }

  public async getEntityConfiguration(entityType: string): Promise<EntityConfiguration> {
    return queryClient.fetchQuery(["private", "core", "entities", "entityConfiguration", entityType], () => {
      return FetchService.performGet(`entity/entityConfiguration?entityType=${entityType}`)
        .then(json => EntityConfiguration.fromData(json));
    }, {
      cacheTime: Infinity,
      staleTime: Infinity, // entity configurations are not changed during runtime
    });
  }

  public async getEntityConfigurationForEntityAndRelated(entityType: string): Promise<Map<string, EntityConfiguration>> {
    const entityConfiguration = await entityDataService.getEntityConfiguration(entityType);
    const referredTypes: string[] = [...new Set(entityConfiguration.attributesConfiguration!.attributeConfigurations
      ?.filter(a => a.entityType).map(a => a.entityType!))];
    const referredConfigurations: EntityConfiguration[] = await Promise.all(referredTypes.map(t => entityDataService.getEntityConfiguration(t)));
    const result: Map<string, EntityConfiguration> = new Map();
    result.set(entityType!, entityConfiguration);
    for (let i = 0; i < referredTypes.length; i++) {
      result.set(referredTypes[i], referredConfigurations[i]);
    }
    console.log("Got entity configurations based on ", entityType, result);
    return result;
  }

  public downloadEntityPdfReport(entity: Entity, variant: string): void {
    const url: string = `/api/entity/entityReport/${entity.type}/${entity.id}?variant=${variant}&header=Incident%20Report&fileName=report.pdf`;
    userDownloadService.startDownload(url, undefined, entity);
  }
}

const entityDataService = new DefaultEntityDataService();
export {entityDataService};
