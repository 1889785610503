/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {withAlpha} from "@icm/core-common";
import {Collapse} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PropsWithChildren, useEffect, useState} from "react";
import * as React from "react";
import {CSSTransition} from "react-transition-group";

import {IcmMuiTheme} from "../../theme/IcmMuiTheme";

const collapseInTime = 500;
const highlightDuration = 10000;

const useStyles = makeStyles((theme: IcmMuiTheme) => ({
  exit: {
    backgroundColor: "transparent",
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeIn,
    }),
  },
  exitActive: {
    backgroundColor: withAlpha(theme.palette.primary.main, 0.3),
  },
  exitDone: {
    backgroundColor: "transparent",
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
}));

export const Highlight: React.FC<PropsWithChildren<any>> = (props) => {
  const [collapseIn, setCollapseIn] = useState(false);
  const [highlightOut, setHighlightOut] = useState(true);
  const styles = useStyles();

  // after first render, collapse in and time highlight out
  useEffect(() => {
    setCollapseIn(true);
    const timer = setTimeout(() => setHighlightOut(false), collapseInTime);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Collapse in={collapseIn} timeout={collapseInTime}>
      <CSSTransition in={highlightOut}
                     timeout={highlightDuration}
                     classNames={{
                       exit: styles.exit,
                       exitActive: styles.exitActive,
                       exitDone: styles.exitDone,
                     }}
      >
        {props.children}
      </CSSTransition>
    </Collapse>
  );
};
