/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FCView, IViewProps} from "@icm/core-common";

import {IFrameWebComponent} from "../../components/form/iframe/IFrameWebComponent";
import {IFrameViewModel} from "./iFrameViewDescriptor";

export const IFrameView: FCView<{}, IFrameViewModel> = (props: IViewProps<IFrameViewModel>) => {
  return (<IFrameWebComponent url={props.viewModel.viewModelData.url} title={props.viewModel.viewModelData.title} />);
};

export {IFrameView as default};
