/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IdGenerator} from "../../util";

export type ConnectionListener = (connected: boolean) => void;

type IdentifiableConnectionListener = {
  id: string
  setConnectionState: ConnectionListener,
} ;

class ConnectionMonitoringService {
  private connected: boolean = false;

  private listeners: Array<IdentifiableConnectionListener> = [];


  constructor() {
    console.debug("Created ConnectionMonitoringService");
  }

  public addListener(listener: ConnectionListener) {
    const id = IdGenerator.randomUUID();
    this.listeners.push({id, setConnectionState: listener});
    console.debug("added connection listener", id, " Number of listeners:", this.listeners.length);
    return id;
  }

  public removeListener(id: string) {
    this.listeners = this.listeners.filter(l => l.id !== id);
  }

  public isConnected(): boolean {
    return this.connected;
  }

  public notifyConnected(): void {
    this.setConnected(true);
  }

  public notifyDisconnected(): void {
    this.setConnected(false);
  }

  private setConnected(connected: boolean): void {
    console.debug("Setting connection state to", connected);
    this.connected = connected;
    this.notifyListeners();
  }

  private notifyListeners(): void {
    this.listeners.forEach(listener => {
      try {
        listener.setConnectionState(this.connected);
      } catch (ex) {
        console.error("A problem occurred while trying to notify connection listener", ex);
      }
    });
  }
}

export {ConnectionMonitoringService};
