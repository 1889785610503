/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";
import {useSelector} from "react-redux";

import {ICoreApplicationState, Notification} from "../store";


export const useNotificationCenter = () => {
  const notifications: Notification[] = useSelector((state: ICoreApplicationState) => state.uiState.notifications);
  const unreadNotifications: number = React.useMemo(() => notifications.filter(n => !n.read).length, [notifications]);

  const [unannouncedLength, unannouncedNotifications]: [number, Notification[]] = useSelector((state: ICoreApplicationState) => {
    const unannounced = state.uiState.notifications.filter(n => !n.announced);
    return [unannounced.length, unannounced];
  });

  return {
    notifications,
    unannouncedLength,
    unannouncedNotifications,
    unreadNotifications,
  };
};
