/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {BaseViewModelData, EditModelDescriptor, IUiState, ViewModel} from "../types";


/**
 * Options passed to the ChangeSingleView
 */
export type ChangeSingleViewOptions = {
  viewModel: ViewModel<BaseViewModelData>,
} & EditModelDescriptor


/**
 * Action that is used to change the current opened single view.
 *
 * @param state
 * @param action
 * @constructor
 */
export const ChangeSingleView = (
  state: IUiState,
  action: PayloadAction<ChangeSingleViewOptions>
) => {
  const perspective = state.perspective;
  if (perspective?.perspectiveType === "SINGLE_VIEW") {
    const current = {...perspective.view};
    perspective.history.push(current);
    perspective.view.viewModel = action.payload.viewModel;
  } else {
    console.warn("Can't open View in non-SingleView perspective");
  }
};
