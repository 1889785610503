/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";

import {LIST} from "../../../constant";
import {getNamedComponentFactory} from "../../../ui";
import {arePropsEqual} from "../../../util";
import {GenericFormFieldWithFormConverter} from "../../FormGeneratorHelper";
import {getValueFromListValue, toListValue} from "../../ListComponentHelper";
import {IFormComponentProps} from "../AbstractFormComponent";
import {assertPropertiesExist} from "../FormGeneratorUtils";

export type FormListComponentProps = IFormComponentProps<any> & {
  field: GenericFormFieldWithFormConverter;
  useSimpleValues: boolean;
};

export const FormListComponent = React.memo((props: FormListComponentProps) => {
  const {
    label,
    readOnly,
    badgeColor,
    field,
    value,
    useSimpleValues,
    handleChange,
  } = props;
  assertPropertiesExist(field, "simpleListConfiguration");

  const listValue = React.useMemo(() => toListValue(value), [value]);
  const handleListChange = React.useCallback((val: any) => {
    return handleChange(getValueFromListValue(val, useSimpleValues));
  }, [handleChange, useSimpleValues]);

  const Component = getNamedComponentFactory(LIST)
    || (() => <span>List component is not supported.</span>);

  return (
    <Component
      label={label}
      badgeColor={badgeColor}
      value={listValue}
      readOnly={readOnly}
      handleChange={handleListChange}
      listConfiguration={field.simpleListConfiguration!}
      toGenericForm={field.toGenericForm}
      onFileAddedToList={field.onFileAddedToList}
      parameterList={field.parameterList}
    />
  );
}, arePropsEqual);
