/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ApiUtil, TabGroupTab, useAvailableViewDescriptors} from "@icm/core-common";
import {useCallback, useMemo} from "react";

import {useClipboard} from "../util";


/**
 * An object to create shareable links in the clipboard
 */
export type ShareLinkGenerator = {
  enabled: boolean,
  generate: () => string | undefined
}

/**
 * Creates a share link generator that will copy the link to clipboard.
 *
 * @param selectedTab
 */
export const useShareLinkGenerator = (selectedTab?: TabGroupTab): ShareLinkGenerator => {
  const availableViewDescriptors = useAvailableViewDescriptors();
  const viewDescriptor = selectedTab ? availableViewDescriptors[selectedTab.viewModel.viewType] : undefined;
  const clipboard = useClipboard();

  const shareParameters = useMemo(() => selectedTab ? viewDescriptor?.getShareParameters?.(selectedTab.viewModel) : undefined,
    [selectedTab, viewDescriptor]);

  const isShareable = shareParameters !== undefined;
  const createShareLink = useCallback(() => {
    const location = window.location.href.split("#")[0];
    if (selectedTab && shareParameters) {
      const viewId = selectedTab.viewModel.viewId;
      const url = `${location}#/${viewId}?${ApiUtil.convertObjectToGetParams(shareParameters)}`;
      clipboard.writeText(url, "URL was copied to clipboard");
      return url;
    }
    return undefined;
  }, [selectedTab, shareParameters, clipboard]);

  return {
    enabled: isShareable,
    generate: createShareLink,
  };
};
