/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CommunicationAddress, Contactable, ExpressionEvaluationService} from "@icm/core-common";

import {asCommunicationAddress} from "../data";
import {Entity} from "../generated/api";
import {isWeakAttribute, isWeakEntity, WeakAttribute, WeakEntityAlikeObject} from "../util";


export interface EntityAttributeService {
  getWeakAttributeValues(entity: Entity, valueName: string): WeakEntityAlikeObject[]
  getWeakValues(value: WeakAttribute): Entity[]
}

class DefaultEntityAttributeService implements EntityAttributeService {
  constructor() {
    this.tryGetWeakValues = this.tryGetWeakValues.bind(this);
    this.getWeakAttributeValues = this.getWeakAttributeValues.bind(this);
    this.getWeakValues = this.getWeakValues.bind(this);
    this.getContactOptions = this.getContactOptions.bind(this);
    this.getContactableEntityViewAttributes = this.getContactableEntityViewAttributes.bind(this);
    this.getContactableEntityGroupViewAttributes = this.getContactableEntityGroupViewAttributes.bind(this);

    ExpressionEvaluationService.registerHelper("entity", "weakValues", (value) => {
      return this.tryGetWeakValues(value);
    });

    ExpressionEvaluationService.registerHelper("entity", "contactableEntityViewAttributes", (entity, otherOptions) => {
      return this.getContactableEntityViewAttributes(entity, otherOptions);
    });

    ExpressionEvaluationService.registerHelper("entity", "contactableEntityGroupViewAttributes", (entity, otherOptions) => {
      return this.getContactableEntityGroupViewAttributes(entity, otherOptions);
    });
  }

  /**
   * Try to extract the dynamic attribute from the given entity.
   *
   * @param entity
   * @param dynamicAttributeName
   */
  getWeakAttributeValues(entity: Entity, dynamicAttributeName: string): WeakEntityAlikeObject[] {
    const attr = entity.dynamicAttributes?.[dynamicAttributeName]?.value;
    if (attr) {
      return this.tryGetWeakValues(attr);
    }
    return [];
  }

  tryGetWeakValues(value: unknown): WeakEntityAlikeObject[] {
    if (isWeakAttribute(value)) {
      return this.getWeakValues(value);
    }
    console.error(`Attribute ${value} is not a weakAttribute. Returning empty array.`);
    return [];
  }


  /**
   * Extract all weak values from a property of an entity.
   * @param value
   */
  getWeakValues(value: WeakAttribute): WeakEntityAlikeObject[] {
    const result: WeakEntityAlikeObject[] = [];
    value.ids.forEach(id => {
      const valueObject = value.data[id];
      if (isWeakEntity(valueObject)) {
        result.push(valueObject);
      }
    });
    return result;
  }

  hasAllAttributes(entity: Entity, attributes: string[]): boolean {
    return !attributes.some(a => {
      return entity.dynamicAttributes?.[a] === undefined;
    });
  }

  getContactableEntityGroupViewAttributes(entity: Entity, otherOptions?: object): Contactable {
    const memberValues = this.tryGetWeakValues(entity);
    const contactOptions = memberValues.flatMap(member => {
      return this.getContactOptions(member.dynamicAttributes?.contact?.value);
    });
    return {
      label: entity.dynamicAttributes?.name.value,
      addresses: this.asAddresses(contactOptions),
      ...otherOptions,
    };
  }

  getContactableEntityViewAttributes(entity: Entity, otherOptions?: object): Contactable {
    const contactOptions = this.getWeakValues(entity?.dynamicAttributes?.contactOptions.value);
    return {
      label: entity.dynamicAttributes?.name.value,
      addresses: this.asAddresses(contactOptions),
      ...otherOptions,
    };
  }

  getContactOptions(entity: Entity): Entity[] {
    return this.getWeakValues(entity?.dynamicAttributes?.contactOptions.value);
  }

  private asAddresses(contactOptions: Entity[]): CommunicationAddress[] {
    const result: CommunicationAddress[] = [];
    contactOptions.forEach(contactOption => {
      const address = asCommunicationAddress(contactOption);
      if (address) {
        result.push(address);
      }
    });
    return result;
  }

}

const entityAttributeService: EntityAttributeService = new DefaultEntityAttributeService();
export {entityAttributeService};
