/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {UiConfigurationApi, UserSetting, useUserSetting} from "../api";
import {useService} from "../service";
import {resetUiState, useUiState} from "../store";

/** This value is stored as persistence mode in user settings in case the user wants to use the default configured workspace after login */
export const DEFAULT_WORKSPACE = "DEFAULT_WORKSPACE";

/** This value is stored as persistence mode in user settings in case the user wants to restore its workspace after login */
export const PERSONAL_WORKSPACE = "PERSONAL_WORKSPACE";


/** Possible values of user interface persistence modes. */
export type PerspectivePersistenceMode = typeof DEFAULT_WORKSPACE | typeof PERSONAL_WORKSPACE

export const isPerspectivePersistence = (val: any): val is PerspectivePersistenceMode => {
  return [DEFAULT_WORKSPACE, PERSONAL_WORKSPACE].includes(val);
};

/**
 * @return A tuple providing the mode used for user interface
 *        persistence and a direct manipulation method.
 */
export const usePersistPerspectiveSetting = (): UserSetting<PerspectivePersistenceMode> => {
  return useUserSetting<PerspectivePersistenceMode>("persistPerspective", isPerspectivePersistence);
};

/**
 * A hook encapsulating two effects:
 * 1. The UI is reset after login. This ensures we do not transfer UI state between roles.
 * 2. The perspective is saved/reset based on the current user setting when the user logs out.
 */
export const useUiPersistence = () => {
  const securityService = useService("SECURITY");
  const uiState = useUiState();
  const persistPerspective = usePersistPerspectiveSetting();


  const dispatch = useDispatch();
  useEffect(() => {
    securityService.addLoginHandler((justLoggedIn: boolean) => {
      if (justLoggedIn) {
        dispatch(resetUiState());
      }
    });
  }, [securityService, dispatch]);


  useEffect(() => {
    const save = async () => {
      if (uiState && persistPerspective.value === PERSONAL_WORKSPACE) {
        console.log("Saving UI state...");
        return await UiConfigurationApi.storeUiState(uiState);
      }
      if (persistPerspective.value === DEFAULT_WORKSPACE) {
        console.log("Resetting UI state...");
        return await UiConfigurationApi.resetUiState();
      }
      return Promise.resolve();
    };
    const id = securityService.addPreLogoutHandler(save);
    return () => {
      securityService.removePreLogoutHandler(id);
    };
  }, [securityService, persistPerspective, uiState]);
};
