/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {
  IdGenerator,
} from "../../../util";
import {BaseViewModelData, IUiState, ViewModel} from "../types";
import {findTabGroup} from "./PerspectiveHelper";


/**
 * Options passed to the OpenTabGroup
 */
export type OpenTabOptions = {

  /**
   * Optional id of the group the tab should be added to.
   * If not provided, the tab will be added to the currentTabGroup of the perspective.
   * By default, this will be the first tab group of the perspective.
   *
   * In case there is an existing tab, the tab group is ignored.
   */
  tabGroupId?: string

  viewModel: ViewModel<BaseViewModelData>

  /**
   * Updated view model data, used if the view already existed before
   */
  updatedViewModelData?: any
}


/**
 * Action that is used to open a new tab OR select an existing tab within a tab group.
 *
 * @param state
 * @param action
 * @constructor
 */
export const OpenTab = (state: IUiState, action: PayloadAction<OpenTabOptions>) => {
  const perspective = state.perspective;
  if (perspective?.perspectiveType === "TABBED") {
    const {currentTabGroupId} = perspective;
    const {tabGroupId = currentTabGroupId, viewModel, updatedViewModelData} = action.payload;


    const targetTabGroup = findTabGroup(perspective, tabGroupId);
    if (targetTabGroup) {
      const existingTab = perspective.tabGroups.map(tg => {
        const tabIndex = tg.tabs.findIndex(t => t.viewModel.uniqueHash === viewModel.uniqueHash);
        return tabIndex >= 0 ?  {tg, tabIndex} : undefined;
      }).find(r => !!r);
      if (!existingTab) {
        targetTabGroup.tabs.push({
          id: IdGenerator.randomNanoId(),
          viewModel,
        });
        targetTabGroup.selectedTabIndex = targetTabGroup.tabs.length - 1;
        perspective.currentTabGroupId = targetTabGroup.id;
      } else {
        // reactivate tabGroup/tab that already exist
        perspective.currentTabGroupId = existingTab.tg.id;
        existingTab.tg.selectedTabIndex = existingTab.tabIndex;
        if (updatedViewModelData) {
          existingTab.tg.tabs[existingTab.tabIndex].viewModel.viewModelData = updatedViewModelData;
        }
      }
    }
  } else {
    console.warn("Can't open Tab in non-tabbed perspective");
  }
};
