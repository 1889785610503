/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CommunicationAddressType} from "./CommunicationAddressType";
import {CommunicationChannel} from "./CommunicationChannel";

const MAPPING: Record<CommunicationChannel, CommunicationAddressType> = {
  [CommunicationChannel.EMAIL]: CommunicationAddressType.EMAIL_ADDRESS,
  [CommunicationChannel.SMS]: CommunicationAddressType.PHONE_NUMBER,
  [CommunicationChannel.TWO_WAY_SMS]: CommunicationAddressType.PHONE_NUMBER,
  [CommunicationChannel.VOICE_MAIL]: CommunicationAddressType.PHONE_NUMBER,
};

/**
 * Returns an association between a communication channel and the address
 * type acceptable on the channel.
 */
export const CommunicationChannelMapping = {
  getMessageAddressType(channel: CommunicationChannel): CommunicationAddressType {
    return MAPPING[channel];
  },
};
