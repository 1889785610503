/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  AbstractFormField,
  GenericFormField,
  StoreService,
  ListComponentValueAndChanges,
  GenericFormConfiguration,
  GenericFormConfigurationAndVariantName,
} from "@icm/core-common";

import {
  Entity,
  EntityConfiguration,
  EntityPropertyValue,
} from "../generated/api";
import {
  entityReferenceValueService,
  PropertyNameAndValue,
} from "../service";
import {
  SubmitStrategy,
  SubmitEntityEditModel,
  updateEntityEditModel,
} from "../store";
import {EntitySaveParams} from "./EntityEditModelComponent";

export type DispatcherProps = {
  entity: Entity,
  entityConfigurations?: Map<string, EntityConfiguration>
}

export type EntityEditViewDispatcher = {
  updateEntityEditModel: (field: AbstractFormField, value?: any, referredObject?: any) => void;
  submitEntityEditModel: (submitStrategy: SubmitStrategy, formConfiguration?: GenericFormConfiguration, parameters?: EntitySaveParams) => Promise<void>;
}

/**
 * Controller to dispatch operations related to an EntityEditModel triggered by an EntityEditView.
 */
export const entityEditViewDispatcher = (props: DispatcherProps): EntityEditViewDispatcher => {
  const {entity, entityConfigurations} = {...props};

  return {

    /**
     * Fire an updateEntityEditModel action event to update EntityEditModel in the store.
     *
     * @param field
     * @param value
     * @param referredObject
     */
    updateEntityEditModel: (field: AbstractFormField, value?: any, referredObject?: any) => {
      if (field.widgetType === "GENERIC_FIELD") {
        const genericField = field as GenericFormField;
        const entityConfiguration = entityConfigurations?.get(entity.type!);

        const deletedPropertyNamePrefixes = getDeletedPropertyNamePrefixes(genericField, value);
        const currentPropertyChanges = getCurrentPropertyChanges(genericField, value, entityConfiguration);
        const currentPropertyChangesForEagerValues = getPropertyChangesForFetchEagerValue(genericField, referredObject, entityConfiguration);

        StoreService.store.dispatch(updateEntityEditModel({
          entityId: props.entity.id!,
          propertyChanges: currentPropertyChanges,
          propertyChangesForEagerValues: currentPropertyChangesForEagerValues,
          deletedPropertyNamePrefixes,
        }));
      } else {
        console.warn("Only generic fields are supported in EntityEditViewDispatcher.");
      }
    },

    submitEntityEditModel: async (submitStrategy, formConfiguration?: GenericFormConfigurationAndVariantName, parameters?: EntitySaveParams) => {
      StoreService.store.dispatch(SubmitEntityEditModel({entityId: props.entity.id!, submitStrategy, formConfiguration, parameters: parameters}));
    },
  };
};


const getCurrentPropertyChanges = (genericField: GenericFormField, value: any, entityConfiguration?: EntityConfiguration): EntityPropertyValue[] => {
  const newPropertyChanges = getPropertyNameValuePairs(genericField, value, entityConfiguration);
  return removePropertyChangesForDeletions(newPropertyChanges, genericField, value);
};

const getPropertyNameValuePairs = (field: GenericFormField, value?: any, entityConfiguration?: EntityConfiguration): PropertyNameAndValue[] => {
  const fieldName = field.valueBinding!;
  console.log("Retrieving property name/value pairs", fieldName, value);

  if (entityConfiguration && entityReferenceValueService.isEntityReferenceField(entityConfiguration, field)) {
    return entityReferenceValueService.getPropertyNameValuePairsForEntityReference(entityConfiguration, field, value);
  } else if (entityConfiguration && entityReferenceValueService.isStaticReferenceField(entityConfiguration, field)) {
    return entityReferenceValueService.getPropertyNameValuePairsForStaticReference(entityConfiguration, field, value);
  } else if (value !== undefined && value !== null) {
    if (value?.type === "ListComponentValue") {
      return getPropertyNameValuePairsForList(field, value as ListComponentValueAndChanges);
    } else {
      return [{propertyName: fieldName, propertyValue: value}];
    }
  } else {
    return [{propertyName: fieldName, propertyValue: null}];
  }
};

const getPropertyNameValuePairsForList = (field: GenericFormField, listValue: ListComponentValueAndChanges): PropertyNameAndValue[] => {
  const fieldName = field.valueBinding!;
  return listValue.changes.map(change => {
    const name = fieldName + "." + change.propertyName;
    console.debug("- included: ", name, change.propertyValue);
    return {propertyName: name, propertyValue: change.propertyValue};
  });
};

const getDeletedPropertyNamePrefixes = (field: GenericFormField, value?: any): string[] => {
  if (value?.type === "ListComponentValue") {
    const fieldName = field.valueBinding!;
    const listValue = value as ListComponentValueAndChanges;
    return (listValue.deletedPropertyNamePrefix || []).map(p => fieldName + "." + p);
  } else {
    return [];
  }
};

const removePropertyChangesForDeletions = (existingPropertyChanges: EntityPropertyValue[],
                                           genericField: GenericFormField, value: any): EntityPropertyValue[] => {
  let result = [...existingPropertyChanges];
  getDeletedPropertyNamePrefixes(genericField, value).forEach(propertyNamePrefix => {
    console.log("- removing changes of properties with prefix ", propertyNamePrefix, " except for change of ", propertyNamePrefix);
    result = result.filter(p => p.propertyName === propertyNamePrefix || !p.propertyName?.startsWith(propertyNamePrefix));
  });
  return result;
};


const getPropertyChangesForFetchEagerValue = (genericField: GenericFormField, valueObject?: any,
                                              entityConfiguration?: EntityConfiguration): EntityPropertyValue[] => {
  if (entityConfiguration && entityReferenceValueService.isEntityReferenceField(entityConfiguration, genericField)) {
    const attributeConfiguration = entityReferenceValueService.getAttributeConfigurationFromField(entityConfiguration, genericField)!;
    if (attributeConfiguration.referenceType === "BY_ID" && attributeConfiguration.fetchEager === true) {
      const attributeName = attributeConfiguration.name;
      return [{propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: valueObject}];
    }
  }
  return [];
};
