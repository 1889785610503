/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMemo} from "react";

import {ActionContextValue} from "../actions";
import {useReadOnlyAggregation} from "../components";
import {AbstractWidget, GenericFormGroup} from "../generated/api";

export type FormWidgetProps = {
  group: GenericFormGroup,
  widget: AbstractWidget,
  actionContext?: ActionContextValue,
}

export const useFormWidgetProps = (group: GenericFormGroup, widget: AbstractWidget): FormWidgetProps => {
  const readOnly = useReadOnlyAggregation(group);
  return useMemo(() => ({
    group,
    widget,
    actionContext: {
      readOnly,
    },
  }), [group, widget, readOnly]);
};
