/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ObjectLinkingParameter,
} from "@icm/core-common";
import React from "react";

export type LinkedObjectSelectionProps<T> = {
  objectLinking: ObjectLinkingParameter
  required: boolean,
  value: T[],
  onChange: (objects: T[]) => void,
}

export type LinkedObjectDisplayProps<T> = {
  value: T
}

export const contributedObjectSelects: Array<{
  acceptSource: (source: any) => boolean,
  LinkedSelect: (props: LinkedObjectSelectionProps<unknown>) => JSX.Element,
  LinkedObjectDisplay: (props: LinkedObjectDisplayProps<unknown>) => JSX.Element,
}> = [];

export const LinkedObjectSelection = <T extends unknown>(props: LinkedObjectSelectionProps<T>) => {
  const result = contributedObjectSelects.find(v => v.acceptSource(props.objectLinking.source));
  if (result) {
    return <result.LinkedSelect {...props} />;
  } else {
    return <p>No object source found</p>;
  }
};
