/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ListFilter} from "@icm/core-common";
import {useMemo} from "react";

const adaptedListFilter = (value?: string, property?: string) => {
  return (listFilter: ListFilter) => {
    if (value && property) {
      const newListFilter: ListFilter = {
        ...listFilter,
        filter: {
          conditionType: "ITEM",
          property: property,
          operator: "EQUALS",
          values: [value],
          valueType: "STRING",
        },
      };
      return newListFilter;
    } else {
      return listFilter;
    }
  };
};

export const useAdaptedSubViewListFilter = (value?: string, property?: string) => {
  return useMemo(() => {
    return adaptedListFilter(value, property);
  }, [value, property]);
};
