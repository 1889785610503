/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, useMessages} from "@icm/core-common";
import {Autocomplete, AutocompleteRenderGetTagProps, AutocompleteRenderOptionState, Chip, Grid, TextField, Typography} from "@mui/material";
import {AlertCircle} from "mdi-material-ui";
import React from "react";

import {IconButtonComponent} from "../form";
import {ConditionalTooltip, TextWithHighlights} from "../util";
import {Address} from "./Address";
import {AddressFieldController} from "./AddressFieldController";


export type AddressFieldInputProps = {
  controller: AddressFieldController
}

export const AddressFieldInput = (props: AddressFieldInputProps) => {
  const {controller} = props;
  const {getMessage} = useMessages();

  return (
    <Autocomplete
      disabled={!controller.canSelectAddress()}
      freeSolo
      multiple
      disableCloseOnSelect
      value={[...controller.getSelectedAddresses()]}
      renderInput={(params) => (
        <TextField variant="standard"
                   {...params}
                   label={getMessage(MessageKey.CHAT.LABEL.RECIPIENTS)}
                   margin="dense"
                   required
                   onBlur={(event) => controller.selectOrAdd(event.target.value)}
        />
      )}
      filterOptions={(options: Address[], params) => {
        return controller.findMatchingAddresses(params.inputValue);
      }}
      renderTags={(value: Address[], getTagProps) => value.map((address, index) => {
        const chipProps = getTagProps({index});
        return (
          // Note: Disabled key check of eslint in the next line because it is given through chipProps. Typescript knows it but es-lint does not.
          // eslint-disable-next-line react/jsx-key
          <AddressFieldEntry address={address} controller={controller} {...chipProps} />
        );
      })}
      isOptionEqualToValue={controller.isSameAddress}
      renderOption={(optionProps, option, state) => renderOption(optionProps, option, state, controller.deleteAddress)}
      onChange={(_event, newValue) => {
        controller.selectAddresses(newValue);
      }}
      options={controller.getAvailableAddresses()}
    />
  );
};


type AddressFieldEntryProps = {
  address: Address
  controller: AddressFieldController
} & ReturnType<AutocompleteRenderGetTagProps>

const AddressFieldEntry = (props: AddressFieldEntryProps) => {
  const {
    address,
    controller,
  } = props;
  const validEmail = controller.isValidAddress(address);
  const {getMessage} = useMessages();
  return (
    <ConditionalTooltip key={address.addressValue}
                        title={getMessage(MessageKey.CHAT.LABEL.INVALID_EMAIL)}
                        condition={!validEmail}
                        placement="top"
    >
      <Chip label={address.addressValue}
            icon={validEmail ? undefined : <AlertCircle color="error" />}
            {...props}
      />
    </ConditionalTooltip>
  );
};


const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Address,
  {
    inputValue,
    selected,
  }: AutocompleteRenderOptionState,
  deleteRecipient: (address: Address) => void
) => (
  <li {...props}>
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid item>
        <Typography noWrap>
          <TextWithHighlights text={option.addressValue} searchValue={inputValue} />
        </Typography>
      </Grid>
      {option.addressValue !== inputValue && (
        <Grid item>
          <IconButtonComponent padding="small"
                               fontSize="small"
                               icon="delete"
                               disabled={selected}
                               handleClick={event => {
                                 event.stopPropagation();
                                 deleteRecipient(option);
                               }}
          />
        </Grid>
      )}
    </Grid>
  </li>
);
