/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  GetMessage,
  MessageKey,
  StorageKey,
  StorageService,
  StorageStrategy,
  useFeedbackBanners, useService,
} from "@icm/core-common";
import {
  Button,
  Link,
  Select,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {FormEvent, useEffect, useState} from "react";

import {LoginStepContainer} from "../../components";
import {styles} from "./LoginPageUi.style";

const useStyles = makeStyles(styles);

export type LoginPageUiProps = {
  getMessage: GetMessage,
  storage: StorageService,
  onLogin: (userName: string, password: string) => void
  errorMessage?: string
  onErrorCleared?: () => void
}

export const LoginPageUi = (props: LoginPageUiProps) => {
  const classes = useStyles();
  const {getMessage, errorMessage, storage, onErrorCleared, onLogin} = props;

  const securityService = useService("SECURITY");

  const whatsNew = securityService.getWhatsNewConfiguration();
  const userNamePrefixConfiguration = securityService.getUserNamePrefixConfiguration();

  const [prefix, setPrefix] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const {openFeedbackBanner} = useFeedbackBanners();

  useEffect(() => {
    if (errorMessage) {
      console.log("Opening error message feedback banner", errorMessage);
      openFeedbackBanner({
        key: "LOGIN_ERROR",
        title: errorMessage,
        variant: "ERROR",
        duration: "SHORT",
        onClose: onErrorCleared,
      });
      setPassword("");
    }
  }, [openFeedbackBanner, errorMessage, onErrorCleared]);

  useEffect(() => {
    // only set prefix, if prefixes are configured
    if (userNamePrefixConfiguration) {
      storage.loadData<string>(StorageKey.LAST_USERNAME_PREFIX).then(storedPrefix => {
        // set initial prefix
        const defaultPrefix = userNamePrefixConfiguration.userNamePrefixes?.find(f => f.default === true)?.prefix;
        setPrefix(storedPrefix || defaultPrefix || "");
      });
    }
  }, [userNamePrefixConfiguration, storage]);

  useEffect(() => {
    // save prefix in localstorage
    if (prefix) {
      storage.saveData(StorageKey.LAST_USERNAME_PREFIX, prefix, new StorageStrategy("LOCAL"));
    } else {
      storage.deleteData(StorageKey.LAST_USERNAME_PREFIX);
    }
  }, [prefix, storage]);


  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const userName: string = prefix + name.trim();
    onLogin(userName, password);
    e.preventDefault();
  };

  return (
    <LoginStepContainer>
      <form className={classes.form} onSubmit={handleSubmit}>
        {userNamePrefixConfiguration
          ? (
            <FormControl variant="standard" margin="normal" required fullWidth>
              <InputLabel htmlFor="prefix">{userNamePrefixConfiguration.label}</InputLabel>
              <Select id="prefix"
                      value={prefix}
                      onChange={e => setPrefix("" + e.target.value)}
              >
                {userNamePrefixConfiguration.userNamePrefixes?.map(p => <MenuItem key={p.prefix} value={p.prefix}>{p.label}</MenuItem>)}
              </Select>
            </FormControl>
          )
          : null}
        <FormControl variant="standard" margin="normal" required fullWidth>
          <InputLabel htmlFor="name">{getMessage(MessageKey.CORE.LOGIN.USERNAME)}</InputLabel>
          <Input id="name"
                 name="name"
                 autoComplete="username"
                 autoFocus
                 value={name}
                 onChange={e => setName(e.target.value)}
          />
        </FormControl>
        <FormControl variant="standard" margin="normal" required fullWidth>
          <InputLabel htmlFor="password">{getMessage(MessageKey.CORE.LOGIN.PASSWORD)}</InputLabel>
          <Input name="password"
                 type="password"
                 id="password"
                 autoComplete="current-password"
                 value={password}
                 onChange={e => setPassword(e.target.value)}
          />
        </FormControl>
        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          {getMessage(MessageKey.CORE.LOGIN.ACTION)}
        </Button>
      </form>
      {whatsNew && (
        <div className={classes.whatsnew}>
          {whatsNew.url ? (
            <Link target="_blank" href={whatsNew.url}>{whatsNew.label}</Link>
          ) : (
            <Typography variant="body2">{whatsNew.label}</Typography>
          )}
        </div>
      )}
    </LoginStepContainer>
  );
};
