/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {GenericFormField} from "@icm/core-common";
import PlaceIcon from "@mui/icons-material/Place";
import {IconButton} from "@mui/material";
import * as React from "react";

import {ValueWithIndex} from "./types";
import {useShowFeatureOnMap} from "./useShowFeatureOnMap";


export type ActionAdornmentsProps = {
  field: GenericFormField
  eventIndexValues: ValueWithIndex[]
  mapViewId?: string
}

export const ActionAdornments = (props: ActionAdornmentsProps) => {
  const {mapViewId, field, eventIndexValues} = props;

  const showFeatureOnMap = useShowFeatureOnMap(mapViewId);

  if (mapViewId) {
    const {format} = field;
    if (format) {
      const [name] = format.split(":");
      switch (name) {
        case "COORDINATE":
        case "POINT":
        case "LINESTRING":
        case "POLYGON":
          const hasValues = eventIndexValues.some(iv => iv.value != null);
          if (hasValues) {
            return (
              <IconButton key="showOnMap"
                          onClick={() => showFeatureOnMap(eventIndexValues)}
                          size="large"
              >
                <PlaceIcon />
              </IconButton>
            );
          }
      }
    }
  }
  return null;
};
