/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IViewProps, MessageKey, ParameterUtilities, ViewDescriptor} from "@icm/core-common";
import {
  isBoolean,
  isString,
} from "lodash-es";
import * as React from "react";

import {MediaStreamPlayerComponent} from "../components";


export type MediaStreamPlayerComponentViewModel = {
  autoPlay: boolean,
  muted: boolean,
  configurationName: string,
  sourceAddress: string,
  sourceName: string,
  printRequested: boolean,
}

/**
 * A view that renders a MediaStreamPlayerComponent
 *
 * @param props
 * @constructor
 */
export const MediaStreamPlayerComponentView = ((props: IViewProps<MediaStreamPlayerComponentViewModel>) => {
  const {autoPlay, muted, configurationName, sourceName, sourceAddress} = props.viewModel.viewModelData;
  const streamSource = {sourceAddress, sourceName};
  return (
    <MediaStreamPlayerComponent autoPlay={autoPlay}
                                muted={muted}
                                configurationName={configurationName}
                                streamSource={streamSource}
    />
  );
});

export {MediaStreamPlayerComponentView as default};

export const mediaStreamPlayerComponentViewDescriptor: ViewDescriptor<MediaStreamPlayerComponentViewModel> = {
  viewType: "MEDIA_PLAYER",
  view: MediaStreamPlayerComponentView,
  initializeViewModelData: viewParameters => {
    const sourceAddress = ParameterUtilities.getResolvedParameterValue("sourceAddress", viewParameters, isString) ?? "";
    const sourceName = ParameterUtilities.getResolvedParameterValue("sourceName", viewParameters, isString) ?? "";
    const configurationName =  ParameterUtilities.getResolvedParameterValue("configuration", viewParameters, isString) ?? "";
    const autoPlay = ParameterUtilities.getResolvedParameterValue("autoPlay", viewParameters, isBoolean) ?? false;
    const muted = ParameterUtilities.getResolvedParameterValue("muted", viewParameters, isBoolean) ?? true;
    return {
      sourceAddress,
      sourceName,
      configurationName,
      autoPlay,
      muted,
      printRequested: false,
    };
  },
  getTitle: (viewModel, getMessage) => {
    // entity is named that way as a convention. thus, suppress the warning
    // eslint-disable-next-line no-underscore-dangle
    const label = getMessage(MessageKey._MEDIA_STREAM.LABEL.TITLE);
    return `${label} ${viewModel.viewModelData.sourceName}`;
  },
};
