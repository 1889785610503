/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CoreApi,
  createValueAccessor,
  DataTableIconsColumn,
  DataTableTextColumn,
  DataTableTextOrIconsColumn,
  formatService,
  ListConfiguration,
  ListKeyValueColumn,
  OnClickAction,
  RowActions,
  RowIconGroupElement,
  RowTextElement,
  SubListElement,
  UpdateFilterSorting,
  useService,
} from "@icm/core-common";
import {useCallback, useMemo} from "react";

import {DataTableContextDefinition} from "./DataTableContext";

/** Typeguard to check if the given row text element has element type "TEXT" */
export const isRowTextElement = (element: CoreApi.RowElement): element is RowTextElement => {
  return element.type === "TEXT";
};

/** Typeguard to check if the given row text element has element type SUBLIST */
export const isSublistElement = (element: CoreApi.RowElement): element is SubListElement => {
  return element.type === "SUBLIST";
};

/** Typeguard to check if the given row text element has element type ICON */
export const isRowIconGroupElement = (val: CoreApi.RowElement): val is RowIconGroupElement => {
  return val.type === "ICON";
};

/** Typeguard to check if the given row text element has element type ACTION */
export const isRowActionsElement = (val: CoreApi.RowElement): val is RowActions => {
  return val.type === "ACTION";
};

/** Typeguard to check if the given row text element has element type ON_CLICK_ACTION */
export const isOnClickActionElement = (val: CoreApi.RowElement): val is OnClickAction => {
  return val.type === "ON_CLICK_ACTION";
};

export const isKeyValueElement = (val: CoreApi.RowElement): val is ListKeyValueColumn => {
  return val.type === "KEY_VALUE";
};

export type RowElementType = keyof ListColumnRecord;

/**
 * A helper type to query row elements (aka columns) of a table/list.
 *
 * @see partitionByRowElementType
 */
export type ListColumnRecord = {

  /** Refers to all actions within a row. */
  ACTION: CoreApi.RowActions[]

  /** Refers to all text and icon group elements NOT in slot EXPANDABLE */
  TEXT_AND_ICON: (CoreApi.RowTextElement | CoreApi.RowIconGroupElement)[],

  /** Refers to all text elements WITH slot EXPANDABLE */
  EXPANDABLE: CoreApi.RowTextElement[],

  /** Refers to elements that should be listed using a key/value DetailPanel. */
  KEY_VALUE: CoreApi.ListKeyValueColumn[],

  /** Refers to the action that is executed when the user clicks the row itself */
  ON_CLICK_ACTION: CoreApi.OnClickAction[],

  /** Refers to the sublist element. */
  SUBLIST:  CoreApi.SubListElement[]
}


/**
 * Create a mapping from the element type to assigned types.
 *
 * @param items The row elements as read from a ListConfiguration
 * @param isVisible Optionally apply the passed visibility filter
 */
export function partitionByRowElementType(items: CoreApi.RowElement[], isVisible = VISIBLE_BY_DEFAULT): ListColumnRecord {
  return items.reduce((acc, item) => {
    if (isRowTextElement(item)) {
      if (isVisible(item)) {
        if (item.slot === "EXPANDABLE") {
          acc.EXPANDABLE.push(item);
        } else {
          acc.TEXT_AND_ICON.push(item);
        }
      }
    } else if (isRowIconGroupElement(item)) {
      if (isVisible(item)) {
        acc.TEXT_AND_ICON.push(item);
      }
    } else if (isRowActionsElement(item)) {
      if (isVisible(item)) {
        acc.ACTION.push(item);
      }
    } else if (isOnClickActionElement(item)) {
      if (isVisible(item)) {
        acc.ON_CLICK_ACTION.push(item);
      }
    } else if (isSublistElement(item)) {
      if (isVisible(item)) {
        acc.SUBLIST.push(item);
      }
    } else if (isKeyValueElement(item)) {
      acc.KEY_VALUE.push(item);
    } else {
      console.warn("Encountered unhandled row element of type: " + item.type);
    }
    return acc;
  }, {ACTION: [], TEXT_AND_ICON: [], KEY_VALUE: [], ON_CLICK_ACTION: [], SUBLIST: [], EXPANDABLE: []} as ListColumnRecord);
}


/**
 * Returns a memoized ListColumnRecord.
 *
 * @param listConfig
 * @param isVisible
 */
export const useRowElements = (listConfig: ListConfiguration, isVisible: VisibleRowElementFilter = VISIBLE_BY_DEFAULT) => {
  return useMemo(() => {
    return partitionByRowElementType(listConfig.rowConfiguration?.elements ?? [], isVisible);
  }, [listConfig.rowConfiguration, isVisible]);
};

export type ElementWithVisibleExpression =  { visible?: string };
export type VisibleRowElementFilter = (element: ElementWithVisibleExpression, additionalContext?: any) => boolean;

export const VISIBLE_BY_DEFAULT: VisibleRowElementFilter = (_element) => true;

export const useVisibleElementsFilter = (): VisibleRowElementFilter => {
  const securityService = useService("SECURITY");
  return useCallback((element: ElementWithVisibleExpression, additionalContext?: any) => {
    return element.visible ? securityService.evaluateVisibilityRule(element.visible, additionalContext) : true;
  }, [securityService]);
};

export function mapToDataColumns<T>(
  elements: (CoreApi.RowTextElement | CoreApi.RowIconGroupElement)[],
  externalSort: UpdateFilterSorting,
  activeSorting?: CoreApi.Sorting
): DataTableTextColumn<T>[] {
  return elements.map(element => {
    if (isRowIconGroupElement(element)) {
      return {
        headerText: element.label ?? "",
        type: "TEXT",
        name: element.label ?? "",
        valueBinding: "_",
        format: "TEXT", // maybe icon ?,
        sortable: false,
        valueAccessor: (row: T) => row,
        formatValue: () => "",
      };
    } else {
      const valueAccessor = createValueAccessor({
        valueBinding: element.valueBinding,
        valueDisplay: element.valueDisplay,
        textElement: element,
      });
      const sortable = element.sortable !== "false";
      return {
        headerText: element.label,
        type: "TEXT",
        name: element.name || element.valueBinding!,
        valueBinding: element.valueBinding!,
        format: element.format!,
        formatValue: value => formatService.format(value, element.format, "FULL"),
        valueAccessor,
        sortable,
        sortOrder: sortable && activeSorting && activeSorting.property === element.valueBinding ? activeSorting.order : undefined,
        sort: sortable
          ? (order: CoreApi.SortOrder) => externalSort({
            property: element.valueBinding,
            order,
          })
          : undefined,
        component: element.component,
        onClickAction: element.onClickAction,
      };
    }
  });
}

export function getComponent(variant: DataTableContextDefinition["variant"]): any {
  if (variant === "FLEX") {
    return "div";
  } else {
    // undefined => default is used (table, tr, td, ...)
    return undefined;
  }
}

export const isDataTableIconsColumn = (col: DataTableTextOrIconsColumn<any>): col is DataTableIconsColumn<any> => {
  return col.type === "ICONS";
};

export const isDataTableTextColumn = (col: DataTableTextOrIconsColumn<any>): col is DataTableTextColumn<any> => {
  return col.type === "TEXT";
};
