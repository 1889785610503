/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:12.

/**
 * Generated from: at.cns.icm.standby.backend.api.data.RecurringStandbyDto
 */
export class RecurringStandby {
    id?: string;
    startDate?: Date;
    endDate?: Date;
    standbyTeam?: StandbyTeam;
    person?: Person;
    priority?: number;
    effectiveOnBankHoliday?: boolean;
    days?: StandbyDay[];

    static fromData(data: any): RecurringStandby {
        if (!data) {
            return data;
        }
        const instance: RecurringStandby = { id: data.id && __verifyIsString(data.id), startDate: data.startDate && new Date(data.startDate), endDate: data.endDate && new Date(data.endDate), standbyTeam: data.standbyTeam && StandbyTeam.fromData(data.standbyTeam), person: data.person && Person.fromData(data.person), priority: data.priority && __verifyIsNumber(data.priority), effectiveOnBankHoliday: data.effectiveOnBankHoliday && __verifyIsBoolean(data.effectiveOnBankHoliday), days: data.days && __getCopyArrayFn(StandbyDay.fromData)(data.days) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','startDate','endDate','standbyTeam','person','priority','effectiveOnBankHoliday','days'];
    }
}

/**
 * Generated from: at.cns.icm.standby.backend.api.data.StandbyDayDto
 */
export class StandbyDay {
    id?: string;
    weekday?: Weekday;
    active?: boolean;
    startTime?: Date;
    endTime?: Date;
    phoneNumber?: string;

    static fromData(data: any): StandbyDay {
        if (!data) {
            return data;
        }
        const instance: StandbyDay = { id: data.id && __verifyIsString(data.id), weekday: data.weekday && data.weekday, active: data.active && __verifyIsBoolean(data.active), startTime: data.startTime && new Date(data.startTime), endTime: data.endTime && new Date(data.endTime), phoneNumber: data.phoneNumber && __verifyIsString(data.phoneNumber) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','weekday','active','startTime','endTime','phoneNumber'];
    }
}

/**
 * Generated from: at.cns.icm.standby.backend.api.data.StandbyDto
 */
export class Standby {
    id?: string;
    startDate?: Date;
    standbyTeam?: StandbyTeam;
    person?: Person;
    standbyPhoneNumber?: string;
    priority?: number;

    static fromData(data: any): Standby {
        if (!data) {
            return data;
        }
        const instance: Standby = { id: data.id && __verifyIsString(data.id), startDate: data.startDate && new Date(data.startDate), standbyTeam: data.standbyTeam && StandbyTeam.fromData(data.standbyTeam), person: data.person && Person.fromData(data.person), standbyPhoneNumber: data.standbyPhoneNumber && __verifyIsString(data.standbyPhoneNumber), priority: data.priority && __verifyIsNumber(data.priority) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','startDate','standbyTeam','person','standbyPhoneNumber','priority'];
    }
}

/**
 * Generated from: at.cns.icm.standby.backend.api.data.StandbyTeamDto
 */
export class StandbyTeam {
    id?: string;
    name?: string;

    static fromData(data: any): StandbyTeam {
        if (!data) {
            return data;
        }
        const instance: StandbyTeam = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.PersonDto
 */
export class Person {
    id?: string;
    firstName?: string;
    lastName?: string;
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };

    static fromData(data: any): Person {
        if (!data) {
            return data;
        }
        const instance: Person = { id: data.id && __verifyIsString(data.id), firstName: data.firstName && __verifyIsString(data.firstName), lastName: data.lastName && __verifyIsString(data.lastName), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','firstName','lastName','dynamicAttributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.DynamicAttributeValueDto
 */
export class DynamicAttributeValue {
    type?: any;
    id?: any;
    version?: any;
    value?: any;
    valueDisplay?: any;

    static fromData(data: any): DynamicAttributeValue {
        if (!data) {
            return data;
        }
        const instance: DynamicAttributeValue = { type: data.type && data.type, id: data.id && data.id, version: data.version && data.version, value: data.value && data.value, valueDisplay: data.valueDisplay && data.valueDisplay };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','version','value','valueDisplay'];
    }
}

export type Weekday = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
