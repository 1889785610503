/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActionContextValue,
  BaseViewModelData,
  FilterParameter,
  getNamedComponentFactory,
  ListConfiguration,
  ListFilterModifier,
  ViewModel,
} from "@icm/core-common";

import {ENTITY_LIST} from "../constants";
import {Entity} from "../generated/api";

export type ConditionalEntityListComponentProps = {
  visible: boolean,
} & EntityListComponentProps

export type ListRefresher = {
  run: () => Promise<void>;
  listContext: string
}


export type ListConfigurationModifier = (configuration: ListConfiguration) => ListConfiguration;

export interface EntityLoader {
  load(): Entity[]
}

export type EntityListComponentProps = {
  viewModel?: ViewModel<BaseViewModelData>
  entityType: string
  listVariant?: string
  maxHeight?: number
  embedded?: boolean
  selectedSegment?: string
  supportsFavorites?: boolean
  filterParameter?: FilterParameter
  filterModifier?: ListFilterModifier
  listConfigurationModifier?: ListConfigurationModifier
  setDownloadHandler?: (downloadHandler: () => void, enabled: boolean) => void
  setRefreshHandler?: (listRefresher: ListRefresher) => void
  contextEntityId?: string
  loader?: EntityLoader
  actionContext?: ActionContextValue
}
export const useEntityListComponent = (props: ConditionalEntityListComponentProps) => {
  const {
    visible,
    ...safeProps
  } = props;

  if (!visible) {
    return undefined;
  }


  const Component = getNamedComponentFactory(ENTITY_LIST);
  if (!Component) {
    return undefined;
  }

  return <Component {...safeProps} />;
};
