/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {AbstractFormField, DateService, formatService, MessageKey, messages, useService} from "@icm/core-common";
import {ValueWithIndex} from "@icm/core-web";
import {Typography} from "@mui/material";
import clsx from "clsx";
import * as React from "react";

import useStyles from "./EntityHistoryViewStyle";
import {RenderedValue} from "./types";

export type SimpleFieldValueProps = {
  eventIndexLabelAndValue: ValueWithIndex
  field: AbstractFormField
  isCurrentValue: boolean
}

export const SimpleFieldValue = (props: SimpleFieldValueProps) => {
  const {eventIndexLabelAndValue, field, isCurrentValue} = props;
  const classes = useStyles();
  const dateService = useService("DATE");
  const valueDeleted = false;
  const renderedValue: RenderedValue | null = renderValue(eventIndexLabelAndValue.value, field, isCurrentValue, dateService);
  const isImageValue = renderedValue?.isImage;
  return (
    <Typography variant="body2"
                color="inherit"
                className={clsx({
                  [classes.changeSet]: true,
                  [classes.deletedValue]: valueDeleted,
                  [classes.previousValue]: !isCurrentValue && !isImageValue,
                  [classes.previousValueWithoutLineThrough]: !isCurrentValue && isImageValue,
                })}
    >
      {renderedValue?.node}
      <sup style={{verticalAlign: "top"}}>&nbsp;{eventIndexLabelAndValue.indexLabel})</sup>
    </Typography>
  );
};

const renderValue = (value: any, field: AbstractFormField, isCurrentValue: boolean, dateService: DateService): RenderedValue | null => {
  let resultNode: React.ReactNode;
  let resultIsImage: boolean = false;
  if (Array.isArray(value)) {
    resultNode = value.filter(a => !!a).map(a => renderValue(a, field, isCurrentValue, dateService)?.node).filter(v => typeof (v) === "string").join(", ");
  } else if (typeof (value) === "boolean") {
    resultNode = (value as Boolean) ? messages.get(MessageKey.CORE.YES)! : messages.get(MessageKey.CORE.NO)!;
  } else if (typeof (value) === "string") {
    if (field.component === "DATETIME_PICKER") {
      resultNode = dateService.reformatDateTime(value);
    } else if (field.component === "DATE_PICKER") {
      resultNode = dateService.reformatDate(value);
    } else if (field.component === "TIME_PICKER") {
      resultNode = dateService.reformatTime(value);
    } else if (field.format) {
      resultNode = formatService.format(value, field.format);
    } else {
      resultNode = value;
    }
  } else if (value === null || value === undefined) {
    resultNode = null;
  } else if (value.svg) {
    const style: React.CSSProperties = {margin: "8px 0px"};
    if (!isCurrentValue) {
      style.opacity = 0.2;
    }
    const buff = new Buffer(value.svg);
    const base64data = buff.toString("base64");
    resultNode = <img src={`data:image/svg+xml;base64,${base64data}`} style={style} alt={field.label} />;
    resultIsImage = true;
  } else if (field.format) {
    resultNode = formatService.format(value, field.format);
  } else {
    resultNode = JSON.stringify(value);
  }
  return {node: resultNode, isImage: resultIsImage};
};
