/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {activityStreamCommonModuleRegistrationOptions} from "@icm/activitystream-common";
import {activityStreamWebModuleRegistrationOptions} from "@icm/activitystream-web";
import {chatCommonModuleOptions} from "@icm/chat-common";
import {chatWebModuleOptions} from "@icm/chat-web";
import {communicationCommonModuleOptions} from "@icm/communication-common";
import {communicationWebModuleRegistrationOptions} from "@icm/communication-web";
import {
  coreCommonModuleRegistrationOptions,
  CoreServiceRegistry,
  FetchService,
  IcmServiceProvider,
  queryClient,
} from "@icm/core-common";
import {coreWebModuleRegistrationOptions, WebStorageService} from "@icm/core-web";
import {demoCommonModuleRegistrationOptions} from "@icm/demo-common";
import {demoWebModuleRegistrationOptions} from "@icm/demo-web";
import {entityCommonModuleRegistrationOptions} from "@icm/entity-common";
import {entityWebModuleRegistrationOptions} from "@icm/entity-web";
import {mapCommonModuleRegistrationOptions} from "@icm/map-common";
import {mapWebModuleRegistrationOptions} from "@icm/map-web";
import {auditlogCommonModuleRegistrationOptions} from "@icm/rem-auditlog-common";
import {auditlogWebModuleRegistrationOptions} from "@icm/rem-auditlog-web";
import {incidentCommonModuleRegistrationOptions} from "@icm/rem-incident-common";
import {incidentWebModuleRegistrationOptions} from "@icm/rem-incident-web";
import {railCommonModuleRegistrationOptions} from "@icm/rem-rail-common";
import {railWebModuleRegistrationOptions} from "@icm/rem-rail-web";
import {standbyCommonModuleRegistrationOptions} from "@icm/rem-standby-common";
import {standbyWebModuleRegistrationOptions} from "@icm/rem-standby-web";
import {topologyCommonModuleRegistrationOptions} from "@icm/topology-common";
import {trackCommonModuleRegistrationOptions} from "@icm/track-common";
import {workflowCommonModuleRegistrationOptions} from "@icm/workflow-common";
import {workflowWebModuleRegistrationOptions} from "@icm/workflow-web";
import * as fallbackProcess from "process"; // no idea, why this works! from "process/browser" does not work!
import * as React from "react";
import {createRoot} from "react-dom/client";
import {QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {HashRouter} from "react-router-dom";

import "typeface-roboto";
import {App} from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

global.process = global.process ?? fallbackProcess; // using this process-nextick-args finally finds process!

const moduleRegistrationOptions = [
  coreCommonModuleRegistrationOptions, coreWebModuleRegistrationOptions,
  activityStreamCommonModuleRegistrationOptions, activityStreamWebModuleRegistrationOptions,
  entityCommonModuleRegistrationOptions, entityWebModuleRegistrationOptions,
  incidentCommonModuleRegistrationOptions, incidentWebModuleRegistrationOptions,
  auditlogCommonModuleRegistrationOptions, auditlogWebModuleRegistrationOptions,
  topologyCommonModuleRegistrationOptions, trackCommonModuleRegistrationOptions,
  mapCommonModuleRegistrationOptions, mapWebModuleRegistrationOptions,
  standbyCommonModuleRegistrationOptions, standbyWebModuleRegistrationOptions,
  railCommonModuleRegistrationOptions, railWebModuleRegistrationOptions,
  workflowCommonModuleRegistrationOptions, workflowWebModuleRegistrationOptions,
  communicationCommonModuleOptions, communicationWebModuleRegistrationOptions,
  chatCommonModuleOptions, chatWebModuleOptions,
  demoCommonModuleRegistrationOptions, demoWebModuleRegistrationOptions,
];


console.log("Starting Web...");

function startWithContextPath(contextPath: string) {
  console.log(`Using context path: '${contextPath}'`);
  const appNode = document.getElementById("root");
  const root = createRoot(appNode!);

  const storageService = new WebStorageService(window.location.pathname.replaceAll("/", "_"));
  root.render((
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools position="top-left" toggleButtonProps={{className: "dontPrint"}} />
      <HashRouter>
        <IcmServiceProvider storageService={storageService}
                            contextPath={contextPath}
                            moduleRegistrationOptions={moduleRegistrationOptions}
                            serviceRegistry={CoreServiceRegistry}
                            keycloakAdapter="default"
        >
          <App />
        </IcmServiceProvider>
      </HashRouter>
    </QueryClientProvider>
  ));
}

FetchService.performGetText("core/contextPath").then(contextPath => {
  startWithContextPath(contextPath);
}).catch(() => startWithContextPath(""));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
