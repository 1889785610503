/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useOpenView} from "@icm/core-common";
import {useCallback} from "react";

/**
 * A hook that can be used to select and follow an element on the map.
 * @param mapViewId
 */
export const useSelectOnMap = (mapViewId: string) => {
  const openView = useOpenView();
  return useCallback((sourcePattern: string, propertyNames: string, value: string, follow: boolean) => {
    openView(mapViewId, {
      genericParameters: [
        {key: "select", value: value},
        {key: "selectSource", value: sourcePattern},
        {key: "selectProperties", value: propertyNames},
        {key: "follow", value: follow ? "true" : "false"},
      ],
    });
  }, [openView, mapViewId]);
};
