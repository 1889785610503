/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import moment, {isMoment, Moment} from "moment";
// import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import * as React from "react";

import {dateService} from "../../../service";
import {AbstractFormComponent, IFormComponentProps} from "../AbstractFormComponent";

export interface IDateTimeComponentProps extends IFormComponentProps<Date> {
  minDate?: Date,
  maxDate?: Date
}

interface IState {
  valid: boolean;
  value?: Date | Moment | null;
}

export abstract class AbstractDateTimeComponent extends AbstractFormComponent<Date, IDateTimeComponentProps, IState> {

  private readonly format: string;
  private readonly placeholder: string;
  private valueSetByComponent: boolean;

  protected constructor(props: IDateTimeComponentProps, format: string) {
    super(props);
    this.state = {
      valid: true,
      value: this.props.value ? new Date(this.props.value) : null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.format = format;
    this.placeholder = (typeof this.props.minDate !== "undefined") ? dateService.format(this.props.minDate, this.format) : "";
  }

  componentDidUpdate(prevProps: Readonly<IFormComponentProps<Date> & IDateTimeComponentProps>, prevState: Readonly<IState>, snapshot?: any): void {
    // use changed props value, except it was set by the component itself
    if ((this.props.value !== prevProps.value || this.props.error !== prevProps.error) && !this.valueSetByComponent) {
      this.setState({
        valid: true,
        value: this.props.value,
      });
    }
    this.valueSetByComponent = false;

    this.handleValidationStateChangeIfNeeded(prevState);
  }

  private handleValidationStateChangeIfNeeded(prevState: Readonly<IState>) {
    if (prevState.valid !== this.state.valid) {
      this.props.updateFieldValidationState?.(this.state.valid);
    }
  }

  protected renderEditableComponent() {
    return this.renderDateTimeComponent(this.state.value, this.format, this.placeholder);
  }

  protected abstract renderDateTimeComponent(value: Date | Moment | null | undefined, format: string, placeholder: string): React.ReactNode;

  protected renderReadOnlyText(): string | undefined {
    return this.props.value && dateService.format(this.props.value, this.format);
  }

  protected isWithinRange(value: Date, minDate?: Date, maxDate?: Date): boolean {
    const minDateOk = !minDate || dateService.compare(value, minDate) >= 0;
    const maxDateOk = !maxDate || dateService.compare(maxDate, value) >= 0;

    return minDateOk && maxDateOk;
  }

  protected handleBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, format: string) {
    const input = event.target.value;
    const momentValue = moment(input, format, true);
    const isValid = momentValue.isValid();
    this.handleChange(isValid ? momentValue : undefined);
  }

  protected handleChange(value?: Date | Moment | null, inputValue?: string) {
    this.valueSetByComponent = true;
    console.log("value", value, "inputValue", inputValue, this.format, this.placeholder);
    if (value) {
      const dateValue = isMoment(value) ? value.toDate() : value;
      if (dateValue instanceof Date && !isNaN(dateValue.getTime())) {
        if (this.isWithinRange(dateValue, this.props.minDate, this.props.maxDate)) {
          this.setState({
            valid: true,
            value: dateValue,
          });
          this.props.handleChange(dateValue);
        } else {
          this.setState({
            valid: false,
            value: dateValue,
          });
          this.props.handleChange(undefined);
        }
      } else {
        this.setState({
          valid: false,
          value: dateValue,
        });
        this.props.handleChange(undefined);
      }
    } else {
      this.setState({
        valid: true,
        value: value,
      });
      this.props.handleChange(undefined);
    }
  }

  protected createMaskFromFormat(): string {
    return this.format.replace(/[a-z0-9]/gi, "_");
  }
}
