/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi} from "../../api";
import {SecurityService} from "../../service";
import {PerspectiveType} from "../../store";
import {ViewDescriptorMap} from "../../ui";
import {VersionedUiConfiguration} from "../useUiConfiguration";
import {PerspectiveMapper, PerspectiveMapperProps} from "./PerspectiveMapper";
import {SingleViewPerspectiveMapper} from "./SingleViewPerspectiveMapper";
import {TabbedPerspectiveMapper} from "./TabbedPerspectiveMapper";

export interface PerspectiveMapperFactory extends PerspectiveMapperProps {}

/**
 * Create a perspective mapper for a given perspective type.
 */
export class PerspectiveMapperFactory {

  withSecurityService(securityService: SecurityService): PerspectiveMapperFactory {
    this.securityService = securityService;
    return this;
  }

  withUiConfiguration(uiConfiguration: VersionedUiConfiguration): PerspectiveMapperFactory {
    this.uiConfiguration = uiConfiguration;
    return this;
  }

  withAvailableViewDescriptors(availableViewDescriptors: ViewDescriptorMap): PerspectiveMapperFactory {
    this.availableViewDescriptors = availableViewDescriptors;
    return this;
  }

  create(perspectiveType: PerspectiveType): PerspectiveMapper {
    const configureError = "You have to configure a securityService, a uiConfiguration and availableViewDescriptors";
    if (!this.securityService || !this.uiConfiguration || !this.availableViewDescriptors) {
      console.error(configureError);
      throw new Error(configureError);
    }

    this.configuredPerspective = this.getFirstAvailablePerspective();
    const missingPerspectiveError = "Could not find a perspective";
    if (!this.configuredPerspective) {
      console.error(missingPerspectiveError);
      throw new Error(missingPerspectiveError);
    }

    if (perspectiveType === "SINGLE_VIEW") {
      return new SingleViewPerspectiveMapper(this);
    }
    return new TabbedPerspectiveMapper(this);
  }

  private getFirstAvailablePerspective() {
    const availablePerspectives = this.uiConfiguration.perspectives
      ?.filter((p: CoreApi.Perspective | undefined) => {
        return p && this.securityService.evaluateExpression(p.available ?? "true");
      })
      ?? [];
    return availablePerspectives[0];
  }
}
