/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ErrorBoundary} from "@icm/core-common";
import {Box} from "@mui/system";
import * as React from "react";

import styles from "./TabContentComponentStyle";


export type TabContentComponentProps = React.PropsWithChildren<{}> & {
  id: string
  selected: boolean
}

/**
 * Renders the content of a tab within an error boundary.
 *
 * @param props
 * @constructor
 */
export const TabContentComponent = (props: TabContentComponentProps) => {
  const {id, selected} = props;
  return (
    <Box role="tabpanel" hidden={!selected} sx={styles.tabContentPanel} className="print" key={id}>
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Box>
  );
};
