/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {css} from "@emotion/css";

import {CommonIconColor} from "../components";
import {CustomColor, IcmPalette, PaletteColor} from "../theme";

export const styles = (palette: IcmPalette, paletteColor?: PaletteColor | CustomColor, iconColor?: CommonIconColor | string, size?: string,) => {
  return {
    container: css({
      display: "inline-block",
      height: size ?? "1em",
      whiteSpace: "nowrap",
      "--svg-background": palette.background.main,
      "--svg-primary": iconColor ?? (paletteColor ? palette[paletteColor]?.main : palette.background.textSecondary),
      svg: {
        height: "100%",
        width: "unset",
        pointerEvents: "none",
      },
    }),
    unknown: css({
      lineHeight: "1em",
      color: iconColor ?? (paletteColor ? palette[paletteColor]?.main : palette.background.textSecondary),
    }),
  };
};
