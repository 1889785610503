/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DynamicAttributeValue, ExpressionEvaluationService, IdGenerator, StoreService} from "@icm/core-common";

import {EntityApi} from "../api";
import {ProcessDefinitionDetails, EntityMetadata} from "../data";
import {AttributeConfiguration, Entity, EntityCreationConfiguration, EntityPropertyValue, SetValueConfiguration} from "../generated/api";
import {initEntityEditModel, InitEntityModelOptions} from "../store";
import {createWeakAttributeValue, EntityAlikeObject, isWeakAttribute, WeakEntityAlikeObject} from "../util";
import {EntityDataService, entityDataService} from "./EntityDataService";
import {entityReferenceValueService} from "./EntityReferenceValueService";

export const EntityEditService = {
  initializeEntityEditModel: async (
    entityType: string,
    entityKey?: string,
    creationParameters?: Record<string, any>,
    sourceEntity?: Entity,
    processDetails?: ProcessDefinitionDetails
  ): Promise<InitEntityModelOptions> => {
    console.log("Initialize entity edit model for entity of type:", entityType, "based on sourceEntity:", sourceEntity, "for process", processDetails);
    const options = await createEntityModelInitOptions(entityDataService, entityType, entityKey, creationParameters, sourceEntity, processDetails);
    StoreService.store.dispatch(initEntityEditModel({...options}));
    return options;
  },

  initializeWeakEntityEditModel: async (
    entityType: string,
    entityKey?: string,
    creationParameters?: Record<string, any>,
    sourceEntity?: Entity,
    processDetails?: ProcessDefinitionDetails
  ): Promise<InitEntityModelOptions> => {
    console.log("Initialize weak entity edit model for entity of type:", entityType, "based on sourceEntity:", sourceEntity, "for process", processDetails);
    return await createEntityModelInitOptions(entityDataService, entityType, entityKey, creationParameters, sourceEntity, processDetails);
  },
};

/**
 * Create the options required to initialize an entity edit model.
 * This function is only exported to enable testing without mocking the redux store.
 * You do not need to call it manually
 *
 * @param eds
 * @param entityType
 * @param creationParameters
 * @param entityKey
 * @param sourceObject
 * @param processDetails
 */
export async function createEntityModelInitOptions(
  eds: EntityDataService,
  entityType: string,
  entityKey?: string,
  creationParameters?: Record<string, any>,
  sourceObject?: Entity | SourceObject,
  processDetails?: ProcessDefinitionDetails
) {
  if (entityKey) {
    try {
      return await tryLoadEntity(entityType, entityKey);
    } catch (e) {
      console.log("Could not load entity with id", entityKey, "; Creating model with random id.");
      return tryCreateEntity(eds, entityType, creationParameters, sourceObject, processDetails);
    }
  } else {
    console.log("No entityKey provided to init model. Creating a new entity with random id.");
    return tryCreateEntity(eds, entityType, creationParameters, sourceObject, processDetails);
  }
}

/**
 * Try loading the entity from the server and return a "non draft" unmodified version.
 *
 * @param entityType
 * @param entityKey
 */
async function tryLoadEntity(entityType: string, entityKey: string):  Promise<InitEntityModelOptions> {
  console.log("Loading entity", entityKey, "of type", entityType);
  const entity = await EntityApi.getEntityByKey(entityType, entityKey);

  console.log("Loaded entity", entity);
  return {
    entity,
    draft: false,
    readOnly: false,
    propertyChanges: [],
  };
}
type SourceObject = {
  type: string
}
async function tryCreateEntity(
  eds: EntityDataService,
  entityType: string,
  creationParameters?: Record<string, any>,
  source?: Entity | SourceObject,
  processDetails?: ProcessDefinitionDetails
): Promise<InitEntityModelOptions> {
  console.log("Create entity with type:", entityType, "from source", source, "for process", processDetails);

  const id = IdGenerator.randomUUID();

  const metadata: { [index: string]: any } = {};
  if (processDetails) {
    metadata[EntityMetadata.START_PROCESS] = processDetails.processKey;
  }

  const entity: Entity = {
    type: entityType,
    id,
    dynamicAttributes: {},
    metadata: metadata,
    keys: [
      {
        name: "id",
        value: id,
      },
    ],
  };

  const propertyChanges = await collectInitialPropertyChanges(eds, entityType, creationParameters, source);

  return {
    entity,
    propertyChanges,
    draft: true,
    readOnly: false,
  };
}


const NO_VALUE_CONFIGS: SetValueConfiguration[] = [];

async function collectInitialPropertyChanges(
  eds: EntityDataService,
  entityType: string,
  creationParameters?: Record<string, any>,
  source?: Entity | SourceObject,
): Promise<EntityPropertyValue[]> {
  const lifecycle = await eds.getLifecycleConfiguration(entityType);

  if (lifecycle?.entityCreations) {
    const matchingCreationConfig = (creation: EntityCreationConfiguration) => {
      if (!source) {
        return creation.sourceEntityType === undefined;
      } else {
        return creation.sourceEntityType === source.type || creation.sourceObjectType === source.type;
      }
    };
    const attributeConfigurationMapping = await getAttributeConfigurationMapping(entityType);
    return lifecycle.entityCreations
      .filter(matchingCreationConfig)
      .flatMap(creation => creation.setValues ?? NO_VALUE_CONFIGS)
      .flatMap(setValueConfig => toEntityPropertyValues(attributeConfigurationMapping, setValueConfig, creationParameters, source));
  }

  return [];
}


function toEntityPropertyValues(attributeConfigurationMapping: Map<string, AttributeConfiguration>,
                                setValueConfig: SetValueConfiguration,
                                creationParameters?: Record<string, any>,
                                sourceObject?: Entity | SourceObject): EntityPropertyValue[] {
  console.group("Initialize Entity:");
  console.log("setValueConfig", setValueConfig);
  console.log("sourceEntity", sourceObject);
  console.log("creationParameters", creationParameters);
  console.groupEnd();

  const {source, target, value} = setValueConfig;

  if (!target) {
    console.warn(`Missing target attribute on setValueConfiguration: ${setValueConfig}.`);
    return [];
  }

  const attributeConfiguration = attributeConfigurationMapping.get(target);

  if (!attributeConfiguration) {
    console.warn(`Missing attributeConfiguration for attribute ${target}. Skipping initialization.`);
    return [];
  }

  if (source && value) {
    console.warn(`Found source and value for setValueConfiguration: ${setValueConfig}. Define source XOR value. Skipping initialization.`);
    return [];
  }

  const expression = source === "_" ? "_" : value;

  if (expression) {
    return getPropertyValues(expression, target, attributeConfiguration, creationParameters, sourceObject);
  } else if (sourceObject && isSourceEntity(sourceObject) && source !== "_" && source && !value) {
    if (sourceObject.dynamicAttributes[source] !== undefined) {
      const dynamicAttributeValue = sourceObject.dynamicAttributes[source];
      return copyFromSourceEntity(attributeConfiguration, dynamicAttributeValue);
    } else {
      console.debug("Skipping attribute", source, "as no value exists in the source entity");
    }
  }

  return [];
}


function copyFromSourceEntity(attributeConfiguration: AttributeConfiguration, dynamicAttributeValue: DynamicAttributeValue): EntityPropertyValue[] {
  const wrapValueIntoArray = attributeConfiguration.cardinality === "LIST";
  const attributeName = attributeConfiguration.name!;

  if (attributeConfiguration.referenceType === "BY_ID") {
    return [
      {
        propertyName: `dynamicAttributes.${attributeName}.type`,
        propertyValue: attributeConfiguration.entityType,
      },
      {
        propertyName: `dynamicAttributes.${attributeName}.id`,
        propertyValue: (wrapValueIntoArray ? [...dynamicAttributeValue.id] : dynamicAttributeValue.id),
      },
    ];
  } else if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
    return [
      {
        propertyName: `dynamicAttributes.${attributeName}.type`,
        propertyValue: attributeConfiguration.entityType,
      },
      {
        propertyName: `dynamicAttributes.${attributeName}.id`,
        propertyValue: (wrapValueIntoArray ? [...dynamicAttributeValue.id] : dynamicAttributeValue.id),
      },
      {
        propertyName: `dynamicAttributes.${attributeName}.version`,
        propertyValue: (wrapValueIntoArray ? [...dynamicAttributeValue.version] : dynamicAttributeValue.version),
      },
    ];
  } else if (attributeConfiguration.referenceType === "BY_VALUE") {
    return [
      {
        propertyName: `dynamicAttributes.${attributeName}.type`,
        propertyValue: attributeConfiguration.entityType,
      },
      {
        propertyName: `dynamicAttributes.${attributeName}.value`,
        propertyValue: (wrapValueIntoArray ? [...dynamicAttributeValue.value] : dynamicAttributeValue.value),
      },

    ];
  } else {
    return [
      {propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: dynamicAttributeValue.value},
    ];
  }
}

function getValueArray(expression: string, sourceObject: Entity | SourceObject | undefined, creationParameters: Record<string, any> | undefined) {
  const evaluatedExpression = ExpressionEvaluationService.evaluate(expression, sourceObject, creationParameters);
  const evaluatedArray = Array.isArray(evaluatedExpression) ? evaluatedExpression : [evaluatedExpression];
  return evaluatedExpression ? evaluatedArray : [];
}

function getPropertyValueForEntityReferenceAttribute(
  expression: string,
  sourceObject: Entity | SourceObject | undefined,
  creationParameters: Record<string, any> | undefined,
  attributeConfiguration: AttributeConfiguration,
  wrapValueIntoArray: boolean,
  attributeName: string
) {
  const valueArray = getValueArray(expression, sourceObject, creationParameters);
  const typeArray = valueArray.map(v => v.type || attributeConfiguration.entityType);
  const idArray = valueArray.map(v => v.id);
  const versionArray = valueArray.map(v => v.version);
  const result = [];
  if (wrapValueIntoArray) {
    result.push({propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: typeArray});
    if (attributeConfiguration.referenceType === "BY_ID") {
      result.push({propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: idArray});
    }
    if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
      result.push({propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: idArray});
      result.push({propertyName: `dynamicAttributes.${attributeName}.version`, propertyValue: versionArray});
    }
    if (attributeConfiguration.referenceType === "BY_VALUE") {
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: valueArray});
    }
  } else if (valueArray.length > 0) {
    result.push({propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: typeArray.at(0)});
    if (attributeConfiguration.referenceType === "BY_ID") {
      result.push({propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: idArray.at(0)});
    }
    if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
      result.push({propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: idArray.at(0)});
      result.push({propertyName: `dynamicAttributes.${attributeName}.version`, propertyValue: versionArray.at(0)});
    }
    if (attributeConfiguration.referenceType === "BY_VALUE") {
      result.push({propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: valueArray.at(0)});
    }
  }
  return result;
}


function getPropertyValuesForSourceEntity(expression: string,
                                          attributeName: string,
                                          attributeConfiguration: AttributeConfiguration,
                                          creationParameters?: Record<string, any>,
                                          sourceObject?: Entity | SourceObject): EntityPropertyValue[] {
  const wrapValueIntoArray = attributeConfiguration.cardinality === "LIST";

  if (isSourceEntity(sourceObject) && attributeConfiguration.referenceType === "BY_ID") {
    return [
      {propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: sourceObject.type},
      {propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: (wrapValueIntoArray ? [sourceObject.id] : sourceObject.id)},
    ];
  } else if (isSourceEntity(sourceObject) && attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
    return [
      {propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: sourceObject.type},
      {propertyName: `dynamicAttributes.${attributeName}.id`, propertyValue: (wrapValueIntoArray ? [sourceObject.id] : sourceObject.id)},
      {propertyName: `dynamicAttributes.${attributeName}.version`, propertyValue: (wrapValueIntoArray ? [sourceObject.version] : sourceObject.version)},
    ];
  } else if (isSourceEntity(sourceObject) && attributeConfiguration.referenceType === "BY_VALUE") {
    const value = ExpressionEvaluationService.evaluate(expression, sourceObject, creationParameters);
    return [
      {propertyName: `dynamicAttributes.${attributeName}.type`, propertyValue: sourceObject.type},
      {propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: (wrapValueIntoArray ? [value] : value)},
    ];
  } else {
    return [];
  }
}


function getPropertyValues(expression: string,
                           attributeName: string,
                           attributeConfiguration: AttributeConfiguration,
                           creationParameters?: Record<string, any>,
                           sourceObject?: Entity | SourceObject): EntityPropertyValue[] {
  const wrapValueIntoArray = attributeConfiguration.cardinality === "LIST";

  if (isSourceEntity(sourceObject) && attributeConfiguration.referenceType && ["BY_ID", "BY_ID_AND_VERSION", "BY_VALUE"].includes(attributeConfiguration.referenceType)) {
    return getPropertyValuesForSourceEntity(expression, attributeName, attributeConfiguration, creationParameters, sourceObject);
  } else if (entityReferenceValueService.isEntityReferenceAttribute(attributeConfiguration)) {
    return getPropertyValueForEntityReferenceAttribute(
      expression,
      sourceObject,
      creationParameters,
      attributeConfiguration,
      wrapValueIntoArray,
      attributeName
    );
  } else {
    try {
      const value = ExpressionEvaluationService.evaluate(expression, sourceObject, creationParameters);
      return [
        {propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: value ?? []},
      ];
    } catch (e) {
      return [
        {propertyName: `dynamicAttributes.${attributeName}.value`, propertyValue: []},
      ];
    }
  }
}

async function getAttributeConfigurationMapping(entityType: string): Promise<Map<string, AttributeConfiguration>> {
  const entityConfiguration = await entityDataService.getEntityConfiguration(entityType);

  const m:  Map<string, AttributeConfiguration> = new Map();
  entityConfiguration.attributesConfiguration?.attributeConfigurations?.forEach(c => {
    m.set(c.name!, c);
  });
  return m;
}

const isSourceEntity = (sourceObject?: Entity | SourceObject): sourceObject is Entity & Required<Pick<Entity, "dynamicAttributes">> => {
  return !!sourceObject?.["dynamicAttributes"];
};


export function createWeakEntityPropertyChanges(parent: EntityAlikeObject, attributeName: string,
                                                weakEntity: WeakEntityAlikeObject): EntityPropertyValue[] | undefined {
  const currentValue = parent.dynamicAttributes[attributeName]?.value ?? createWeakAttributeValue();

  if (isWeakAttribute(currentValue)) {
    const changes: EntityPropertyValue[] = [];

    const newIds: string[] = [...currentValue.ids];
    newIds.push(weakEntity.id);

    changes.push(createPropertyChange(`dynamicAttributes.${attributeName}.value.ids`, newIds));
    changes.push(createPropertyChange(`dynamicAttributes.${attributeName}.value.data.${weakEntity.id}`, {}));
    changes.push(createPropertyChange(`dynamicAttributes.${attributeName}.value.data.${weakEntity.id}.id`, weakEntity.id));

    Object.keys(weakEntity.dynamicAttributes).forEach(attribute => {
      const value = weakEntity.dynamicAttributes[attribute].value;
      const change = createPropertyChange(`dynamicAttributes.${attributeName}.value.data.${weakEntity.id}.dynamicAttributes.${attribute}.value`, value);
      changes.push(change);
    });

    return changes;
  }
  return undefined;
}


function createPropertyChange(name: string, value: unknown): EntityPropertyValue {
  return {
    propertyName: name,
    propertyValue: value,
  };
}
