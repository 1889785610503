/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  dateService,
  FieldHighlightingInformation,
  FieldHighlightMap,
  ListFilter,
  useGenericViewParam,
  useMessages,
  useService,
} from "@icm/core-common";
import {useCallback, useEffect, useMemo, useState} from "react";

import {Entity} from "../generated/api";

type FieldHighlightHelper = {
  fieldHighlights: FieldHighlightMap
  markAllHighlightsAsSeen: () => void
  unHighlightField: (valueBinding: string) => void
};

/**
* @return FieldHighlightHelper
*/
export const useEntityFieldHighlights = (entity?: Entity, highlightUpdatesSince?: Date): FieldHighlightHelper => {
  const [highlightedFields, setHighlightedFields] = useState<FieldHighlightMap>({});

  const securityService = useService("SECURITY");
  const highlightedFieldsService = useService("HIGHLIGHTED_FIELDS_SERVICE");

  const userName = useMemo(() => securityService.getCurrentUserDetails()?.userName, [securityService]);

  useEffect(() => {
    if (entity?.id && entity.type) {
      if (highlightUpdatesSince) {
        const filter: ListFilter = highlightedFieldsService.createFilter({
          entityType: entity.type,
          entityId: entity.id,
          afterDate: highlightUpdatesSince,
        });
        highlightedFieldsService?.getHighlightedFieldsFromEntries(filter, false, userName).then((updates) => {
          setHighlightedFields(updates);
        });
      }

      const filter = highlightedFieldsService.createFilter({
        entityType: entity.type,
        entityId: entity.id,
      });
      return highlightedFieldsService.registerHighlightedFieldsListener(filter, true, userName, (updates) => {
        // listener is also triggered, if filter results in no matching entries
        if (Object.keys(updates).length > 0) {
          setHighlightedFields(prev => ({
            ...prev,
            ...updates,
          }));
        }
      });
    } else {
      // reset highlighted fields, if entity was reset
      setHighlightedFields({});
    }
    return () => {};
  }, [highlightedFieldsService, entity?.id, entity?.type, highlightUpdatesSince, userName]);

  const markAllHighlightsAsSeen = useCallback(() => {
    setHighlightedFields(current => {
      const copy = {...current};
      Object.keys(copy).forEach((key: string) => {
        const field: FieldHighlightingInformation = current[key]!;
        field.outdated = false;
        return field;
      });
      return copy;
    });
  }, []);

  const unHighlightField = useCallback((valueBinding: string) => {
    setHighlightedFields(current => {
      const {[valueBinding]: toRemove, ...rest} = current;
      return rest;
    });
  }, []);

  return {
    fieldHighlights: highlightedFields,
    markAllHighlightsAsSeen,
    unHighlightField,
  };
};


// TODO PICM-1501 refactor view param
export const useFieldHighlightBoxInHeader = (fieldHighlights: FieldHighlightMap) => {
  const highlightUpdatesSinceParam = useGenericViewParam("highlightUpdatesSince");
  const {getMessage} = useMessages();
  useEffect(() => {
    // eslint-disable-next-line
    const highlightUpdatesSince = highlightUpdatesSinceParam && dateService.parse(highlightUpdatesSinceParam);
    // TODO PICM-1440 find replacement for additionalDetails
    if (Object.keys(fieldHighlights).length > 0) {
      console.warn("field highlights not implemented", highlightUpdatesSince);
    //   setHeaderProps(headerProps => ({
    //     ...headerProps,
    //     additionalDetails: (
    //       <SectionHeaderDetailsBox>
    //         {highlightUpdatesSince && (
    //           <>
    //             <LegendText>
    //               {getMessage(MessageKey.CORE.ACTIVITYSTREAM.LEGEND.HIGHLIGHT)} {formatService.format(highlightUpdatesSince, "DATE_TIME")}
    //             </LegendText>
    //             <br />
    //           </>
    //         )}
    //         <LegendText color="warning">
    //           {getMessage(MessageKey.CORE.ACTIVITYSTREAM.LEGEND.OUTDATED)}
    //         </LegendText>
    //       </SectionHeaderDetailsBox>
    //     ),
    //   }));
    }
    // setHeaderProps(headerProps => ({
    //   ...headerProps,
    //   additionalDetails: undefined,
    // }));
    // }
    // eslint-disable-next-line
  }, [highlightUpdatesSinceParam, fieldHighlights, getMessage]);
};
