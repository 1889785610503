/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FieldUpdatedMarker, IFormComponentProps, LabelWithFieldStateMarker} from "@icm/core-common";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Box} from "@mui/system";
import * as React from "react";

import styles from "./CheckboxComponentStyle";

type AllProps = IFormComponentProps<boolean>;

const useStyles = makeStyles(styles);

const FunctionalCheckboxComponent = (props: AllProps) => {
  const {disabled, error, required, handleChange, helperText, label, readOnly, badgeColor, onFocus} = props;

  const classes = useStyles();

  const selected = props.value ?? false;

  if (readOnly) {
    return (
      <>
        <LabelWithFieldStateMarker label={label} badgeColor={badgeColor} error={error} />
        <Grid item={true} container={true} direction="row" alignItems="flex-end">
          <Typography color="initial" variant="body1">
            {selected ? <CheckBox color="primary" fontSize="small" /> : <CheckBoxOutlineBlank color="inherit" fontSize="small" />}
          </Typography>
        </Grid>
      </>
    );
  } else {
    const updateChecked = (event: object, checked: boolean) => {
      handleChange(checked);
    };
    const htmlInputProps = {
      "aria-label": label,
    };
    return (
      <FormControl error={error} disabled={disabled} required={required} className={classes.root}>
        <FormControlLabel
                  control={<Checkbox color="primary" checked={selected} value={selected} inputProps={htmlInputProps} />}
                  value={selected}
                  onChange={updateChecked}
                  onFocus={onFocus}
                  label={(
                    <Box display="flex">
                      <Typography variant="body1">{label + (required ? "*" : "")}</Typography>
                      <FieldUpdatedMarker badgeColor={badgeColor} style={{marginLeft: 8}} />
                    </Box>
                  )}
                  labelPlacement="end"
        />
        {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
      </FormControl>
    );
  }
};

const component = FunctionalCheckboxComponent;
export {component as CheckboxComponent};
