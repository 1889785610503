/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService} from "@icm/core-common";
import {produce} from "immer";
import {cloneDeep} from "lodash-es";

import {Entity, EntityPropertyValue} from "../generated/api";

class EntityUpdateService {
  public static INSTANCE = new EntityUpdateService();

  /**
   * Return a copy of the given entity using the property values provided.
   *
   * @param entity the entity that is copied
   * @param propertyChanges the changes that should be applied to the copy
   */
  applyChanges(entity: Entity, propertyChanges: EntityPropertyValue[]): Entity {
    return produce(entity, (draft) => {
      propertyChanges.forEach(change => {
        if (change.propertyName) {
          try {
            if (typeof change.propertyValue === "object") {
              // avoid mutating original property changes through subsequent property changes  (see: ICM-1135)
              ExpressionEvaluationService.set(draft, change.propertyName, cloneDeep(change.propertyValue));
            } else {
              ExpressionEvaluationService.set(draft, change.propertyName, change.propertyValue);
            }
          } catch (e) {
            console.error("Failed setting", change.propertyName, "to", change.propertyValue, "in entity", entity, "cause:", e);
          }
        }
      });
    });
  }
}

const entityUpdateService = EntityUpdateService.INSTANCE;
export {entityUpdateService};
