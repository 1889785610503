/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {isString} from "lodash-es";

import {ExpressionEvaluationService} from "./ExpressionEvaluationService";

/**
 * Provides methods that can be accessed from expressions in configuration files using `$$.script.<method>`.
 */
export interface ScriptHelperService {

  /**
   * Get the value of a the passed property as string if (and only if)
   * property is a string and the trimmed value of this string has a length > 0.
   */
  getNonEmptyStringValue(property: any): string | undefined
}

class DefaultScriptHelperService implements ScriptHelperService {

  constructor() {
    ExpressionEvaluationService.registerHelper("script", "stringValue", this.getNonEmptyStringValue);
  };

  getNonEmptyStringValue(property: any): string | undefined {
    if (isString(property)) {
      const v = property.trim();
      if (v.length > 0) {
        return v;
      }
    }
    return undefined;
  }


}

const scriptHelperService = new DefaultScriptHelperService();

export {scriptHelperService};
