/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";

import {ExpressionEvaluationService} from "../../service";
import {ParameterUtilities, ResolvedParameter} from "../../util";
import {ActionRunner} from "./ActionRegistry";

export const useOpenUrlActionHandler = (): ActionRunner => {
  return useCallback((parameters: ResolvedParameter[]) => {
    const entity = ParameterUtilities.getResolvedParameter("entity", parameters);
    const urlTemplate = ParameterUtilities.getResolvedParameter("URL", parameters);
    const openInNewTab = ParameterUtilities.getResolvedParameter("openInNewTab", parameters) ?? true;
    const url = entity ? ExpressionEvaluationService.evaluate(urlTemplate, entity) : urlTemplate;
    if (openInNewTab) {
      window.open(url, "_blank");
    } else {
      window.open(url);
    }
  }, []);
};
