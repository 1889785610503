/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {customAlphabet} from "nanoid";

import {CoreApi} from "../../api";
import {isTabGroupTab, TabbedPerspective, TabGroup, TabGroupTab} from "../../store";
import {AbstractPerspectiveMapper} from "./AbstractPerspectiveMapper";
import {PerspectiveMapperProps} from "./PerspectiveMapper";

/**
 * Creates the common web perspective that supports tabs.
 */
export class TabbedPerspectiveMapper extends AbstractPerspectiveMapper {

  private readonly randomId: () => string;

  constructor(props: PerspectiveMapperProps) {
    super(props);
    this.randomId = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789", 4);
    this.map = this.map.bind(this);
    this.toTabGroups = this.toTabGroups.bind(this);
    this.toTabGroup = this.toTabGroup.bind(this);
    this.toTabGroupTab = this.toTabGroupTab.bind(this);
    this.emptyTabGroup = this.emptyTabGroup.bind(this);
  }

  private toTabGroups(icmTabGroup?: CoreApi.TabGroup[]): TabGroup[] {
    if (!icmTabGroup) {
      return [this.emptyTabGroup()];
    }
    return icmTabGroup.map(this.toTabGroup);
  }

  private toTabGroup(icmTabGroup: CoreApi.TabGroup): TabGroup {
    const tabs = icmTabGroup.tabs?.map(tab => {
      return this.toTabGroupTab(tab);
    }).filter(isTabGroupTab) ?? [];

    return {
      id: this.randomId(),
      selectedTabIndex: 0,
      tabs,
    };
  }

  private emptyTabGroup(): TabGroup {
    return {
      id: this.randomId(),
      selectedTabIndex: -1,
      tabs: [],
    };
  }

  private toTabGroupTab(tab: CoreApi.ViewRef): TabGroupTab | undefined {
    const viewModel = this.toViewModel(tab);
    if (!viewModel) {
      return undefined;
    }
    return {
      id: this.randomId(),
      viewModel,
    };
  }

  public map(): TabbedPerspective | undefined {
    if (!this.configuredPerspective.mainArea?.tabView) {
      console.error("The available perspective can't be used on this device");
      return undefined;
    }

    const sideBarId = this.configuredPerspective.sideBar?.sideBarRef;
    const globalActions = this.toActionBar(this.uiConfiguration.actions, this.configuredPerspective.globalActions);
    const tabGroups = this.toTabGroups(this.configuredPerspective.mainArea.tabView.tabGroups ?? []);

    return {
      serialVersion: this.uiConfiguration.version,
      perspectiveType: "TABBED",
      id: this.configuredPerspective.id!,
      label: this.configuredPerspective.label ?? "Unknown",
      tabGroups,
      currentTabGroupId: tabGroups[0].id,
      globalActions,
      sideBarId,
    };
  }
}
