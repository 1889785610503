/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {KeyboardShortcutsConfiguration, PlayerConfiguration} from "vimond-replay/default-player/PlayerConfiguration";

/**
 * Refers to a predefined configuration of the player.
 */
export type ConfigurationDescriptor = "MOVIE" | "SILENT_LIVE_STREAM" | "DEFAULT";

const DefaultKeyboardConfiguration: KeyboardShortcutsConfiguration = {
  keyMap: {
    togglePause: [" ", "Enter", "P"],
    toggleFullscreen: "F",
    decreaseVolume: "-",
    increaseVolume: "+",
    skipBack: ",",
    skipForward: ".",
    toggleMute: "M",
  },
};

const responsivenessRules = [{
  className: "narrow",
  width: {
    max: 640,
  },
}, {
  className: "medium-width",
  width: {
    min: 640,
    max: 1024,
  },
}, {
  className: "wide",
  width: {
    min: 1024,
  },
}];

/**
 * A set of default player configurations.
 */
const configurations: Record<ConfigurationDescriptor, PlayerConfiguration> = {
  MOVIE: {
    interactionDetector: {
      inactivityDelay: 1,
    },
    keyboardShortcuts: DefaultKeyboardConfiguration,
    userSettings: null,
    controls: {
      skipButtonOffset: -10,
      qualitySelectionStrategy: "cap-bitrate",
      liveDisplayMode: "clock-time",
    },
    responsivenessRules,
  },
  SILENT_LIVE_STREAM: {
    interactionDetector: {
      inactivityDelay: 1,
    },
    keyboardShortcuts: DefaultKeyboardConfiguration,
    controls: {
      includeControls: ["playPauseButton", "timeDisplay", "bufferingIndicator", "fullscreenButton", "exitButton"],
      qualitySelectionStrategy: "fix-bitrate",
      liveDisplayMode: "clock-time",
    },
    responsivenessRules,
  },
  DEFAULT: {
    interactionDetector: {
      inactivityDelay: 1,
    },
    controls: {
      includeControls: [],
      skipButtonOffset: -10,
      liveDisplayMode: "clock-time",
    },
  },
};


export function playerConfiguration(configurationDescriptor?: ConfigurationDescriptor): PlayerConfiguration {
  return configurations[configurationDescriptor ?? "DEFAULT"];
}
