/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableTextColumn, DataTableTextOrIconsColumn} from "@icm/core-common";
import {TableRow} from "@mui/material";
import * as React from "react";
import {useState} from "react";

import {DataTableActionCell, DataTableDetailPanel, DataTableRowHeader, useDataTableContext, useDataTableStyles} from "../common";
import {ClassicTableDataCell} from "./ClassicTableDataCell";

type DataTableRowProps<T> = {
  dataColumns: DataTableTextOrIconsColumn<T>[]
  row: T
  rowIndex: number
  rowProps?: any
  readOnly?: boolean
}

export const ClassicTableRow = <T extends unknown>({row, rowIndex, dataColumns, rowProps, readOnly}: DataTableRowProps<T>) => {
  const {tableConfig} = useDataTableContext<T>();
  const hasDetailPanel = tableConfig.detailPanelRenderer?.hasDetails(row) ?? false;
  const actions = tableConfig.rowActions;
  const hasActions = actions && actions.length > 0;
  const classes = useDataTableStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow hover className={classes.row} {...rowProps}>
        <DataTableRowHeader row={row}
                            expanded={expanded}
                            setExpanded={hasDetailPanel ? setExpanded : undefined}
        />
        {dataColumns.map((col: DataTableTextColumn<T>, colIndex: number) => (
          <ClassicTableDataCell key={colIndex} rowValue={row} rowEntity={row} col={col} />
        ))}
        {hasActions && !readOnly && (
          <DataTableActionCell rowValue={row} actions={actions!} rowIndex={rowIndex} />
        )}
      </TableRow>
      {expanded && hasDetailPanel && (
        <DataTableDetailPanel row={row}
                              detailPanelRenderer={tableConfig.detailPanelRenderer!}
                              colSpan={dataColumns.length + (hasActions ? 1 : 0)}
                              variant="CLASSIC"
        />
      )}
    </>
  );
};
