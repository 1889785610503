/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/


/**
 * Name of the chat view component to be used in chat view descriptors.
 */
export const CHAT_VIEW_TYPE = "CHAT_VIEW";

/**
 * JID of the room that should be entered when opening the ChatView.
 */
export const INITIAL_ROOM = "initialRoomJid";
