/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";

import {IcmTheme, useTheme} from "../theme";
import {IViewProps} from "../ui";
import {SvgIcon} from "../util";
import {styles} from "./IconDemoView.style";

export type IconDemoViewModel = {
  svgIconNames: string[],
  printRequested: boolean,
}

export const IconDemoView = (props: IViewProps<IconDemoViewModel>) => {
  const svgIconNames = props.viewModel.viewModelData.svgIconNames;
  const theme: IcmTheme = useTheme();
  const themedStyles = styles(theme);
  return (
    <div className={themedStyles.container}>
      {svgIconNames.map((name: string, index: number) => {
        return (
          <div
            className={themedStyles.objectContainer}
            key={index}
          >
            <div className={themedStyles.object}>
              <SvgIcon name={name} />
            </div>
            <br />
            <span>{name}</span>
          </div>
        );
      })}
    </div>
  );
};
