/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataLoad, ObjectDataUpdate} from "../generated/api";

export type EntityTypeListener = (load: DataLoad) => void;

export class DataLoadService {

  private static readonly INSTANCE = new DataLoadService();

  public static getInstance() {
    return DataLoadService.INSTANCE;
  }

  private readonly listeners: Array<EntityTypeListener>;

  private readonly entityTypeListeners: Map<string, Array<(update: DataLoad) => any>>;

  public constructor() {
    this.listeners = [];
    this.entityTypeListeners = new Map<string, Array<(update: DataLoad) => any>>();
  }

  public static isDataLoad(object?: any): object is DataLoad {
    return Array.isArray(object?.changes) && object.changes.every((entry: any) => entry instanceof ObjectDataUpdate);
  }

  public registerListener(listener: (update: DataLoad) => any) {
    this.listeners.push(listener);
  }

  public registerEntityTypeListener(entityType: string, listener: EntityTypeListener) {
    if (!this.entityTypeListeners.has(entityType)) {
      this.entityTypeListeners.set(entityType, []);
    }
    this.entityTypeListeners.get(entityType)!.push(listener);
  }

  public unregisterEntityTypeListener(entityType: string, listener: EntityTypeListener) {
    if (this.entityTypeListeners.has(entityType)) {
      const list: Array<(update: DataLoad) => any> = this.entityTypeListeners.get(entityType)!;
      list.splice(list.indexOf(listener), 1);
    }
  }

  public notify(load: DataLoad) {
    this.entityTypeListeners.forEach((listeners, entityType) => {
      const changes = load.changes && load.changes.filter(change => change.dataUpdate && change.dataUpdate.entityType === entityType);
      if (changes && changes.length > 0) {
        const entityTypeLoad: DataLoad = new DataLoad();
        entityTypeLoad.changes = changes;
        listeners.forEach(f => f(entityTypeLoad));
      }
    });
    this.listeners.forEach(f => f(load));
  }
}
