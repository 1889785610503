/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {cx} from "@emotion/css";
import {MessageKey, useMessages} from "@icm/core-common";
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useEffect, useState} from "react";

import {IcmMuiTheme} from "../../../theme";
import styles from "./IFrameWebComponentStyle";

export type IFrameFormComponentProps = {
  url: string,
  title?: string,
  minHeight?: string
}

export const IFrameWebComponent = (props: IFrameFormComponentProps) => {
  const useStyles = makeStyles<IcmMuiTheme>(styles);
  const classes = useStyles({minHeight: props.minHeight});
  const {title, url} = props;
  const msg = useMessages();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    // Assume the iframe didn't load after 5 seconds and show an error message behind the iframe.
    setTimeout(()=> setShowError(true), 5000);
  }, []);

  return (
    <Box className={classes.container}>
      {/* Show an error message behind the iFrame in case it didn't load. Browsers implement iFrame error handling */}
      {/* really badly. */}
      {showError && (
        <Box className={cx(classes.box, classes.error)}>
          {msg.getMessage(MessageKey.CORE.ERROR.UNKNOWN)}
        </Box>
      )}
      <Box className={classes.box}>
        <iframe title={title} src={url} className={classes.item} />
      </Box>
    </Box>
  );
};
