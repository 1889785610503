/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchService, FilterService, ListFilter, UserSettingsApi} from "@icm/core-common";
import type {Feature} from "geojson";
import update from "immutability-helper";
import {Reducer} from "redux";
import {combineEpics, Epic} from "redux-observable";
import {from, Observable} from "rxjs";
import {filter as rxFilter, map, switchMap} from "rxjs/operators";
import {ActionType, createReducer, isActionOf} from "typesafe-actions";

import {Layer, LayerBase, LayerGroup, MapConfiguration, Source, Observable as ApiObservable} from "../../generated/api";
import {isLogicalGroup, isLogicalLayer} from "../../util/ApiUtil";
import * as mapActions from "./actions";
import {
  addBookmark, addLayer,
  centerMap,
  clearLayerFilterAndSearch,
  fetchMapConfiguration, fitBounds,
  IMapState,
  moveLayer,
  removeBookmark, removeLayer,
  resetMapConfiguration,
  saveMapConfiguration, setFeatures,
  setLayerFilter,
  setLayerGroupState,
  setLayerOpacity,
  setLayerSearch,
  setLayerVisibility, updateSource,
} from "./actions";
import {fixMapConfiguration, mergeMapConfiguration, resolveMapConfigurationReferences} from "./configuration";

export type MapAction = ActionType<typeof mapActions>;


const fetchMapConfigurationEpic: Epic<MapAction> = action$ => action$.pipe(
  rxFilter(isActionOf(fetchMapConfiguration.request)),
  switchMap(action => from(getUserMapConfiguration(action.payload))),
  map(value => fetchMapConfiguration.success(value!))
);

const resetMapConfigurationEpic: Epic<MapAction> = action$ => action$.pipe(
  rxFilter(isActionOf(resetMapConfiguration.request)),
  switchMap(action => from(resetUserMapConfiguration(action.payload))),
  map(value => resetMapConfiguration.success(value!))
);

// Attention: state$ is the full state, not just the map state!
const saveMapConfigurationEpic: Epic<MapAction, MapAction, {mapState: IMapState}> = (action$, state$) => action$.pipe(
  rxFilter(isActionOf(saveMapConfiguration.request)),
  switchMap(action => from(saveUserMapConfiguration(state$.value.mapState.mapConfiguration, action.payload))),
  map(value => saveMapConfiguration.success(value!))
);

export const mapEpics = combineEpics(fetchMapConfigurationEpic, resetMapConfigurationEpic, saveMapConfigurationEpic);


const initialMapState: IMapState = {
  // empty
  features: {},
};

export const mapReducer: Reducer<IMapState> = createReducer<IMapState, MapAction>(initialMapState)
  .handleAction([fetchMapConfiguration.success, resetMapConfiguration.success], (state, action) => {
    const config = action.payload;
    return update(state, {
      mapConfiguration: {$set: config},
    });
  })
  .handleAction(centerMap, (state, action) => {
    const {center, zoom} = action.payload;
    return update(state, {
      mapConfiguration: {
        map: {
          center: {x: {$set: center[0]}, y: {$set: center[1]}},
          zoom: {level: current => zoom || current},
        },
      },
    });
  })
  .handleAction(fitBounds, (state, action) => {
    const {bounds, padding} = action.payload;
    return update(state, {
      mapConfiguration: {
        map: {
          bounds: {$set: {x1: bounds[0], y1: bounds[1], x2: bounds[2], y2: bounds[3]}},
          padding: {$set: padding},
        },
      },
    });
  })
  .handleAction(setFeatures, (state, action) => {
    const {name, features} = action.payload;
    return update(state, {
      features: {$merge: {[name]: features}},
    });
  })
  .handleAction(addBookmark, (state, action) => {
    const bookmark = action.payload;
    return update(state, {
      mapConfiguration: {
        bookmarks: {$push: [bookmark]},
      },
    });
  })
  .handleAction(removeBookmark, (state, action) => {
    const bookmarkName = action.payload;
    return update(state, {
      mapConfiguration: {
        bookmarks: current => (current || []).filter(b => b.name !== bookmarkName),
      },
    });
  })
  .handleAction(setLayerGroupState, (state, action) => {
    const {layerId, open} = action.payload;
    return update(state, {
      mapConfiguration: {
        layers: layers => updateLayers(layers!, layer => updateLayerState(layer, layerId, open), true),
      },
    });
  })
  .handleAction(setLayerVisibility, (state, action) => {
    const {layerId, visible} = action.payload;
    return update(state, {
      mapConfiguration: {
        layers: layers => updateLayers(layers!, layer => updateLayerVisibility(layer, layerId, visible), true),
      },
    });
  })
  .handleAction(setLayerOpacity, (state, action) => {
    const {layerId, opacity} = action.payload;
    return update(state, {
      mapConfiguration: {
        layers: layers => updateLayers(layers!, layer => updateLayerOpacity(layer, layerId, opacity), true),
      },
    });
  })
  .handleAction(setLayerFilter, (state, action) => {
    const {layerId, filter} = action.payload;
    const layer = getLayer(state.mapConfiguration!.layers!, layerId);
    if (layer && isLogicalLayer(layer) && layer.mapboxLayers) {
      const sourceIds: string[] = getSourceIds(layer);
      if (sourceIds && sourceIds.length > 0) {
        const filtered = isFilterNotEmpty(filter);
        return update(state, {
          mapConfiguration: {
            layers: layers => updateLayers(layers!, layerToUpdate => updateLayerFilter(layerToUpdate, layerId, filtered), true),
            sources: sources => updateSources(sources!, source => updateSourceFilter(source, sourceIds, filtered ? filter : undefined), false),
          },
        });
      }
    }
    return state;
  })
  .handleAction(setLayerSearch, (state, action) => {
    const {layerId, filter} = action.payload;
    const layer = getLayer(state.mapConfiguration!.layers!, layerId);
    if (layer && isLogicalLayer(layer) && layer.mapboxLayers) {
      const sourceIds: string[] = getSourceIds(layer);
      if (sourceIds && sourceIds.length === 1) {
        const searched = isFilterNotEmpty(filter);
        return update(state, {
          mapConfiguration: {
            layers: layers => updateLayers(layers!, layerToUpdate => updateLayerSearch(layerToUpdate, layerId, searched), true),
            sources: sources => updateSources(sources!, source => {
              return updateSourceSearch(source, sourceIds[0], searched ? filter : undefined, layer.searchUrl);
            }, true),
          },
        });
      }
    }
    return state;
  })
  .handleAction(clearLayerFilterAndSearch, (state, action) => {
    const {layerId} = action.payload;
    const layer = getLayer(state.mapConfiguration!.layers!, layerId);
    if (layer && isLogicalLayer(layer) && layer.mapboxLayers) {
      const sourceIds: string[] = getSourceIds(layer);
      if (sourceIds && sourceIds.length > 0) {
        return update(state, {
          mapConfiguration: {
            layers: layers => updateLayers(layers!, layerToUpdate => resetLayerFilterAndSearch(layerToUpdate, layerId), true),
            sources: sources => updateSources(sources!, source => resetSourceFilterAndSearch(source, sourceIds), false),
          },
        });
      }
    }
    return state;
  })
  .handleAction(moveLayer, (state, action) => {
    const {layerId, parentLayerId, beforeLayerId} = action.payload;
    return update(state, {
      mapConfiguration: {
        layers: layers => moveLayerInLayers(layers!, layerId, parentLayerId, beforeLayerId),
      },
    });
  })
  .handleAction(addLayer, (state, action) => {
    const {source, layer, parentLayerId} = action.payload;
    return update(state, {
      mapConfiguration: {
        sources: {$push: [source]},
        layers: layers => addLayerToLayers(undefined, layers!, layer, parentLayerId, undefined),
      },
    });
  })
  .handleAction(removeLayer, (state, action) => {
    const {layerId} = action.payload;
    // TODO: also remove sources that are not used anywhere else
    return update(state, {
      mapConfiguration: {
        layers: layers => removeLayerFromLayers(layers!, layerId),
      },
    });
  })
  .handleAction(updateSource, (state, action) => {
    const {sourceId, mapboxSource} = action.payload;
    return update(state, {
      mapConfiguration: {
        sources: sources => sources?.map(s => s.id === sourceId ? {...s, mapboxSource} : s),
      },
    });
  });

function isFilterNotEmpty(filter?: ListFilter): boolean {
  return (filter && (filter.filter !== undefined || filter.collectionModifiers !== undefined)) || false;
}

export type RuntimeApiObservable<D extends {} = {}> = ApiObservable & {
  /** the function to convert a DTO to a feature, where f is the existing feature */
  toFeatureFunction?: (d: D, f: Feature | null) => Feature | null;
  /** the filter for the fetched objects and especially the updates */
  filterFunction?: (d: D) => boolean;
}

/** Properties for MapboxSource */
export type RuntimeSourceProperties = Source & {
  /** the temporary filter - if a filter definition, works on the properties of the features
   * TODO: should not put function in store, see https://redux.js.org/style-guide/style-guide#do-not-put-non-serializable-values-in-state-or-actions
   */
  filter?: (feature: Feature) => boolean | {[name: string]: any};
  /** the temporary observable providing the geojson data - for searched or filtered layers - must not be set on startup */
  alternateObservable?: Observable<Feature[]> | RuntimeApiObservable<any>;
};

function parseJSON(s: string): any {
  if (s) {
    try {
      return JSON.parse(s);
    } catch (e) {
      console.error("Error parsing map configuration JSON '" + s + "': " + e);
    }
  }
  return undefined;
}

function getSourceIds(layer: Layer): string[] {
  return (layer.mapboxLayers || [])
    .filter(l => l)
    .map(l => parseJSON(l!))
    .filter(l => l)
    .map(l => l.source)
    .filter(s => typeof s === "string")
    .filter((s, i, sources) => sources.indexOf(s) === i) as string[];
}

/**
 * Update all layers.
 * If nothing was changed (all updated layers identical to the input), the original array is returned.
 *
 * @param layers the layers to update
 * @param doUpdate the updater
 * @param abortOnFirstChange if true, assumes that nothing changed after the first change
 * @return the updated layers (or the original layers, if nothing changed)
 */
function updateLayers(layers: LayerBase[],
                      doUpdate: (layer: LayerBase) => LayerBase,
                      abortOnFirstChange: boolean = false): LayerBase[] {
  let changed = false;
  const newLayers: LayerBase[] = layers.map(layer => {
    if (!abortOnFirstChange || !changed) {
      const newLayer = doUpdate(layer);
      changed = changed || (newLayer !== layer);
      return newLayer;
    }
    return layer;
  });
  return changed ? newLayers : layers;
}

/**
 * Update all sources.
 * If nothing was changed (all updated sources identical to the input), the original array is returned.
 *
 * @param sources the sources to update
 * @param doUpdate the updater
 * @param abortOnFirstChange if true, assumes that nothing changed after the first change
 * @return the updated sources (or the original sources, if nothing changed)
 */
function updateSources(sources: RuntimeSourceProperties[],
                       doUpdate: (source: RuntimeSourceProperties) => RuntimeSourceProperties,
                       abortOnFirstChange: boolean = false): RuntimeSourceProperties[] {
  let changed = false;
  const newSources: RuntimeSourceProperties[] = sources.map(source => {
    if (!abortOnFirstChange || !changed) {
      const newSource = doUpdate(source);
      changed = changed || (newSource !== source);
      return newSource;
    }
    return source;
  });
  return changed ? newSources : sources;
}

/**
 * Set the visibilty of a layer.
 *
 * @param layer the layer to check
 * @param layerId the ID of the layer, whose visibility should change - or undefined for all layers
 * @param visible the new visibility
 * @return the updated layers (or the original layers, if nothing changed)
 */
function updateLayerVisibility(layer: LayerBase, layerId: string | undefined, visible: boolean) {
  const match = !layerId || layer.id === layerId;
  const newVisible = match ? visible : layer.visible;
  if (layer.type === "logicalGroup") {
    const layerGroup = layer as LayerGroup;
    // if this is a match, all sub layers get the same visibility
    const newLayers = updateLayers(layerGroup.layers || [], layerToUpdate => updateLayerVisibility(layerToUpdate, match ? undefined : layerId, visible), false);
    if (newLayers !== layerGroup.layers || newVisible !== layerGroup.visible) {
      // if there was a change in the sub tree, we might need to adjust the visibility
      const groupVisible = newLayers.some(l => l.visible !== false);
      return {...layer, layers: newLayers, visible: groupVisible};
    }
  } else if (newVisible !== layer.visible) {
    return {...layer, visible: newVisible};
  }
  return layer;
}

/**
 * Update the opacity
 *
 * @param layer the layer to check
 * @param layerId the ID of the layer whose opacity should change
 * @param opacity the new opacity
 * @return the updated layer (or the original layer, if nothing changed)
 */
function updateLayerOpacity(layer: LayerBase, layerId: string, opacity: number) {
  if (layer.id === layerId) {
    return layer.opacity !== opacity ? {...layer, opacity} : layer;
  } else if (layer.type === "logicalGroup") {
    const layerGroup = layer as LayerGroup;
    const newLayers = updateLayers(layerGroup.layers || [], layerToUpdate => updateLayerOpacity(layerToUpdate, layerId, opacity), true);
    return layerGroup.layers !== newLayers ? {...layer, layers: newLayers} : layer;
  }
  return layer;
}

/**
 * Update the layer state (expanded/collapsed)
 *
 * @param layer the layer to check
 * @param layerId the ID of the layer whose state should change
 * @param open the new state (true = expanded, false = collapsed)
 * @return the updated layer (or the original layer, if nothing changed)
 */
function updateLayerState(layer: LayerBase, layerId: string, open: boolean) {
  if (layer.type === "logicalGroup" && layer.id === layerId) {
    const layerGroup = layer as LayerGroup;
    return layerGroup.open !== open ? {...layer, open} : layer;
  } else if (layer.type === "logicalGroup") {
    const layerGroup = layer as LayerGroup;
    const newLayers = updateLayers(layerGroup.layers || [], layerToUpdate => updateLayerState(layerToUpdate, layerId, open), true);
    return layerGroup.layers !== newLayers ? {...layer, layers: newLayers} : layer;
  }
  return layer;
}

/**
 * Update the layer filter state.
 *
 * @param layer the layer to check
 * @param layerId the ID of the layer whose filter state should change
 * @param filtered the new filter state
 * @return the updated layer (or the original layer, if nothing changed)
 */
function updateLayerFilter(layer: LayerBase, layerId: string, filtered: boolean) {
  if (isLogicalGroup(layer)) {
    const newLayers = updateLayers(layer.layers || [], layerToUpdate => updateLayerFilter(layerToUpdate, layerId, filtered), true);
    return layer.layers !== newLayers ? {...layer, layers: newLayers} : layer;
  } else if (layer.id === layerId) {
    const simpleLayer = layer as Layer;
    return simpleLayer.filtered !== filtered ? {...layer, filtered, searched: false} : layer;
  }
  return layer;
}

/**
 * Update the source filter.
 *
 * @param source the source to check
 * @param sourceIds the IDs of the sources whose filter should change
 * @param filter the filter group or undefined to remove the filter
 * @return the updated source (or the original source, if nothing changed)
 */
function updateSourceFilter(source: RuntimeSourceProperties, sourceIds: string[], filter?: ListFilter): RuntimeSourceProperties {
  if (sourceIds.includes(source.id!)) {
    if (filter?.filter) {
      // ignore collection modifiers
      const predicate = FilterService.createPredicate(filter.filter!);
      return {...source, filter: feature => predicate(feature.properties), alternateObservable: undefined};
    } else if (source.filter) {
      return {...source, filter: undefined, alternateObservable: undefined};
    }
  }
  return source;
}

/**
 * Update the layer search state.
 *
 * @param layer the layer to check
 * @param layerId the ID of the layer whose search state should change
 * @param searched the new search state
 * @return the updated layer (or the original layer, if nothing changed)
 */
function updateLayerSearch(layer: LayerBase, layerId: string, searched: boolean) {
  if (layer.type === "logicalGroup") {
    const layerGroup = layer as LayerGroup;
    const newLayers = updateLayers(layerGroup.layers || [], layerToUpdate => updateLayerSearch(layerToUpdate, layerId, searched), true);
    return layerGroup.layers !== newLayers ? {...layer, layers: newLayers} : layer;
  } else if (layer.id === layerId) {
    const simpleLayer = layer as Layer;
    return simpleLayer.searched !== searched ? {...layer, searched, filtered: false} : layer;
  }
  return layer;
}

/**
 * Update the source search.
 *
 * @param source the source to check
 * @param sourceId the ID of the source whose search should change
 * @param filter the filter group or undefined to remove the search
 * @param searchUrl the search URL with a placeholder {filter}
 * @return the updated source (or the original source, if nothing changed)
 */
function updateSourceSearch(source: RuntimeSourceProperties, sourceId: string, filter?: ListFilter, searchUrl?: string): RuntimeSourceProperties {
  if (source.id === sourceId) {
    const urlPattern = searchUrl || source.observable?.url;
    if (filter && urlPattern) {
      const json = encodeURIComponent(JSON.stringify(filter));
      const filterJson = encodeURIComponent(JSON.stringify(filter.filter));
      const collectionModifierJson = encodeURIComponent(JSON.stringify(filter.collectionModifiers));
      const url = urlPattern
        .replace("{filter}", json)
        .replace("{filter.filter}", filterJson)
        .replace("{filter.collectionModifiers}", collectionModifierJson);
      const filterDef = JSON.stringify(filter.filter);
      // this should also work, if the URL does not contain a placeholder, then filtering occurs on the client:
      if (source.alternateObservable?.["url"] !== url || source.alternateObservable?.["filterDefinition"] !== filterDef) {
        // console.log("create filter predicate from", filter.filter);
        const filterPredicate = FilterService.createPredicate(filter.filter);
        const observable = source.observable || {};
        return {
          ...source,
          alternateObservable: {...observable, url, filterFunction: filterPredicate, filterDefinition: filterDef},
          filter: undefined,
        };
      }
    } else if (source.alternateObservable) {
      return {
        ...source,
        alternateObservable: undefined,
        filter: undefined,
      };
    }
  }
  return source;
}

/**
 * Clear filter and search of a layer.
 *
 * @param layer the layer to check
 * @param layerId the ID of the layer whose filter/search should be cleared
 * @return the updated layer (or the original layer, if nothing changed)
 */
function resetLayerFilterAndSearch(layer: LayerBase, layerId: string) {
  if (layer.type === "logicalGroup") {
    const layerGroup = layer as LayerGroup;
    const newLayers = updateLayers(layerGroup.layers || [], layerToUpdate => resetLayerFilterAndSearch(layerToUpdate, layerId), true);
    return layerGroup.layers !== newLayers ? {...layer, layers: newLayers} : layer;
  } else if (layer.id === layerId) {
    const simpleLayer = layer as Layer;
    return simpleLayer.filtered || simpleLayer.searched ? {...layer, filtered: false, searched: false} : layer;
  }
  return layer;
}

/**
 * Clear filter and search of sources.
 *
 * @param source the source to check
 * @param sourceIds the IDs of the sources whose filter and search should be cleared
 * @return the updated source (or the original source, if nothing changed)
 */
function resetSourceFilterAndSearch(source: RuntimeSourceProperties, sourceIds: string[]): RuntimeSourceProperties {
  if (sourceIds.includes(source.id!)) {
    if (source.filter || source.alternateObservable) {
      return {...source, filter: undefined, alternateObservable: undefined};
    }
  }
  return source;
}

/**
 * Clear filter and search of all sources.
 *
 * @param source the source to check
 * @return the updated source (or the original source, if nothing changed)
 */
function resetAllSourceFilterAndSearch(source: RuntimeSourceProperties): RuntimeSourceProperties {
  if (source.filter || source.alternateObservable) {
    return {...source, filter: undefined, alternateObservable: undefined};
  }
  return source;
}

/**
 * Update the layers in response to a layer move.
 *
 * @param layers the layers
 * @param layerId the ID of the layer to move
 * @param parentLayerId the ID of the parent layer group into which to move the layer (or undefined for root layers)
 * @param beforeLayerId the ID of the layer before which to insert the layer (or undefined to insert at the end)
 * @return the updated layers (or the original layers, if nothing changed)
 */
function moveLayerInLayers(layers: LayerBase[], layerId: string,
                           parentLayerId: string | undefined, beforeLayerId: string | undefined): LayerBase[] {
  const layer = getLayer(layers, layerId);
  if (layer) {
    return addLayerToLayers(undefined, removeLayerFromLayers(layers, layerId), layer, parentLayerId, beforeLayerId);
  }
  return layers;
}

/**
 * Get the logical layer or group with the given ID.
 *
 * @param layers the logical layers
 * @param layerId the ID of the layer to get
 * @return the logical layer with the given ID (or undefined if not found)
 */
function getLayer(layers: LayerBase[], layerId: string): LayerBase | undefined {
  return layers.map(layer => {
    if (layer.id === layerId) {
      return layer;
    } else if (layer.type === "logicalGroup") {
      const layerGroup = (layer as LayerGroup);
      return getLayer(layerGroup.layers || [], layerId);
    }
    return null;
  }).find(layer => !!layer) || undefined;
}

/**
 * Remove the logical layer or group with the given ID.
 *
 * @param layers the logical layers
 * @param layerId the ID of the layer to get
 * @return the updated logical layers (or the original layers, if the layer could not be found)
 */
function removeLayerFromLayers(layers: LayerBase[], layerId: string): LayerBase[] {
  let changed = false;
  const newLayers: LayerBase[] = [];
  layers.forEach(layer => {
    if (layer.id === layerId) {
      changed = true;
    } else if (isLogicalGroup(layer)) {
      const layerGroup = (layer as LayerGroup);
      const childLayers = removeLayerFromLayers(layerGroup.layers || [], layerId);
      const visible = childLayers.some(childLayer => childLayer.visible);
      const newLayer = childLayers !== layerGroup.layers || visible !== layerGroup.visible ? {...layerGroup, layers: childLayers, visible} : layerGroup;
      changed = changed || childLayers !== layerGroup.layers || visible !== layerGroup.visible;
      newLayers.push(newLayer);
    } else {
      newLayers.push(layer);
    }
  });
  return changed ? newLayers : layers;
}

/**
 * Add a layer.
 *
 * @param currentParentLayerId the ID of the parent layer of the layers (undefined for root layers)
 * @param layers the layers
 * @param layerToAdd the layer to add
 * @param parentLayerId the ID of the parent layer group into which to move the layer (or undefined for root layers)
 * @param beforeLayerId the ID of the layer before which to insert the layer (or undefined to insert at the end)
 * @return the updated layers (or the original layers, if the parent or before layer could not be found)
 */
function addLayerToLayers(currentParentLayerId: string | undefined, layers: LayerBase[],
                          layerToAdd: LayerBase, parentLayerId: string | undefined, beforeLayerId: string | undefined): LayerBase[] {
  let changed = false;
  const newLayers: LayerBase[] = [];
  layers.forEach(layer => {
    if (currentParentLayerId === parentLayerId && layer.id === beforeLayerId) {
      changed = true;
      newLayers.push(layerToAdd);
    }
    if (isLogicalGroup(layer)) {
      const layerGroup = (layer as LayerGroup);
      const childLayers = addLayerToLayers(layer.id, layerGroup.layers!, layerToAdd, parentLayerId, beforeLayerId);
      const visible = childLayers.some(childLayer => childLayer.visible);
      const newLayer = childLayers !== layerGroup.layers || visible !== layerGroup.visible ? {...layer, layers: childLayers, visible} : layer;
      changed = changed || childLayers !== layerGroup.layers || visible !== layerGroup.visible;
      newLayers.push(newLayer);
    } else {
      newLayers.push(layer);
    }
  });
  if (currentParentLayerId === parentLayerId && !beforeLayerId) {
    changed = true;
    newLayers.push(layerToAdd);
  }
  return changed ? newLayers : layers;
}

function getPublicMapConfiguration(variant?: string): Promise<MapConfiguration> {
  return FetchService.performGetText(variant ? `map/configuration?variant=${variant}` : "map/configuration")
    .then(response => JSON.parse(response))
    .then(mapConfig => mapConfig as MapConfiguration)
    .then(mapConfig => fixMapConfiguration(mapConfig))
    .then(config => resolveMapConfigurationReferences(config))
    .catch(ex => {
      console.error(`Error resolving references in map configuration: ${ex}`);
      return {map: {center: {x: 0, y: 0}, zoom: {level: 6}}, images: [], sources: [], layers: []};
    });
}

function getUserMapConfiguration(variant?: string): Promise<MapConfiguration | null> {
  return getPublicMapConfiguration(variant)
    .then(publicConfig => {
      return UserSettingsApi.getUserSetting(getMapUserSettingName(variant))
        .then(json => {
          if (json) {
            const config = JSON.parse(json) as MapConfiguration;
            return mergeMapConfiguration(publicConfig, config);
          }
          return publicConfig;
        })
        .catch(ex => {
          console.error(`Error retrieving/merging user map configuration: ${ex}`);
          return {map: {center: {x: 0, y: 0}, zoom: {level: 6}}, images: [], sources: [], layers: []};
        });
    });
}

function saveUserMapConfiguration(config?: MapConfiguration, variant?: string): Promise<boolean> {
  if (config) {
    const cleanConfig = {...config, sources: updateSources(config.sources!, resetAllSourceFilterAndSearch)};
    const configString = JSON.stringify(cleanConfig);
    return UserSettingsApi.postUserSetting(getMapUserSettingName(variant), configString);
  }
  return Promise.resolve(false);
}

function resetUserMapConfiguration(variant?: string): Promise<MapConfiguration> {
  // we must post the empty string, as the backend ignores null!
  UserSettingsApi.postUserSetting(getMapUserSettingName(variant), "");
  return getPublicMapConfiguration(variant);
}

function getMapUserSettingName(variant?: string): string {
  return "icmMapConfiguration" + (variant ? `-${variant}` : "");
}
