/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  Action,
  dateService,
  MessageKey,
  Notification,
  SortService,
  useActionHandler,
  useMessages,
  useNotificationCenterContext,
} from "@icm/core-common";
import {KeyboardArrowRight} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import React, {useCallback, useState} from "react";

import {IconButtonComponent} from "../../components";
import {IcmMuiTheme} from "../../theme";

type NotificationCenterProps = {
  open: boolean
  anchor: Element
  notifications: Notification[]
}


type GroupedNotification = {
  type: "GROUP"
  groupTitle: string
  notifications: Notification[]
  // timestamp of latest notification within group
  timestamp: Date
};

type SingleNotification = {
  type: "SINGLE"
} & Notification;

type NotificationOrNotificationGroup = SingleNotification | GroupedNotification


const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  listContainer: {
    width: 600,
    maxWidth: "calc(100vw - 16px)",
    minHeight: 300,
    maxHeight: 600,
    overflowY: "scroll",
  },
  listTitle: {
    padding: theme.spacing(1, 2),
  },
  notificationTitle: {
    paddingTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "310px",
  },
  titleIconContainer: {
    marginRight: theme.spacing(2),
  },
  notificationMessage: {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "pre-line",
    color: theme.palette.text.primary,
  },
  notificationMessageCollapsed: {
    height: `calc(${theme.typography.body2.fontSize}*${theme.typography.body2.lineHeight})`,
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  notificationTimestamp: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  titleLine: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 2,
  },
  messageLine: {
  },
  confirmIconContainer: {
    minWidth: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  confirmIcon: {
    transition: "all .2s ease-in-out",
    "&:not(:hover)": {
      color: theme.icm.palette.primary.main,
    },
    "&:hover,&:focus": {
    },
  },
  notificationListItem: {
    alignItems: "flex-start",
  },
  confirmIconDisabled: {

  },
}));

function groupNotifications(notifications: Notification[]) {
  const notificationGroups = notifications.reduce((acc, next) => {
    if (next.groupTitle !== undefined) {
      const existingGroup = acc.find(n => n.type === "GROUP" && n.groupTitle === next.groupTitle);
      if (existingGroup?.type === "GROUP") {
        existingGroup.notifications.push(next);
        existingGroup.timestamp = existingGroup.timestamp > next.timestamp ? existingGroup.timestamp : next.timestamp;
      } else {
        const newGroup: GroupedNotification = {
          type: "GROUP",
          groupTitle: next.groupTitle,
          timestamp: next.timestamp,
          notifications: [next],
        };
        acc.push(newGroup);
      }
    } else {
      acc.push({type: "SINGLE", ...next});
    }
    return acc;
  }, [] as NotificationOrNotificationGroup[]);

  // sort descending by timestamp
  notificationGroups.sort(SortService.createComparator(n => n.timestamp, (t1, t2) => SortService.compare(t2, t1)));
  return notificationGroups;
}

export const NotificationCenter = ({anchor, open, notifications}: NotificationCenterProps) => {
  const classes = useStyles();
  const notificationGroups = groupNotifications(notifications);

  const {getMessage} = useMessages();
  const {deleteNotifications, closeNotificationCenter} = useNotificationCenterContext();

  return (
    <Popover open={open}
             onClose={closeNotificationCenter}
             anchorEl={anchor}
             anchorOrigin={{
               vertical: "bottom",
               horizontal: "right",
             }}
             transformOrigin={{
               vertical: "top",
               horizontal: "right",
             }}
    >
      <Box className={classes.listContainer}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.listTitle} variant="h6">{getMessage(MessageKey.CORE.NOTIFICATIONS.NOTIFICATION_CENTER.TITLE)}</Typography>
          </Grid>
          <Grid item className={classes.titleIconContainer}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <IconButtonComponent handleClick={() => deleteNotifications(notifications.map(n => n.key))} icon="delete_outline" tooltip={getMessage(MessageKey.CORE.NOTIFICATIONS.DELETE_ALL)} tooltipPlacement="top" padding="medium" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <List dense disablePadding>
          {notificationGroups.map((notification, idx) => (
            <React.Fragment key={notification.type === "GROUP" ? notification.groupTitle : notification.key}>
              {notification.type === "GROUP" ? (
                <GroupedNotificationItem groupedNotification={notification} />
              ) : (
                <SingleNotificationItem notification={notification} />
              )}
              {idx < notificationGroups.length && (
                <Divider component="li" />
              )}
            </React.Fragment>
          ))}
          {notificationGroups.length === 0 && (
            <ListItem>
              <ListItemText>{getMessage(MessageKey.CORE.NOTIFICATIONS.NO_NOTIFICATIONS)}</ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
    </Popover>
  );
};

type SingleNotificationItemProps = {
  notification: Notification
}


const SINGLE_ITEM_COLLAPSED_HEIGHT = 80;
const SingleNotificationItem = ({notification}: SingleNotificationItemProps) => {
  const classes = useStyles();
  const {getMessage} = useMessages();
  const actionHandler = useActionHandler();

  const [open, setOpen] = useState(false);
  const [textWrap, setTextWrap] = useState(false);
  const {read, followUpAction} = notification;

  const {markNotificationAsRead, deleteNotifications, closeNotificationCenter} = useNotificationCenterContext();
  const hasAdditionalActions = (notification.actions?.length ?? 0) > 0;

  const handleAction = useCallback((action: Action, context?: any) => {
    actionHandler(action, context);
    closeNotificationCenter();
  }, [closeNotificationCenter, actionHandler]);

  const triggerFollowUpAction = useCallback(() => {
    markNotificationAsRead(notification.key);
    if (followUpAction) {
      followUpAction.run();
      deleteNotifications(notification.key);
      closeNotificationCenter();
    }
  }, [markNotificationAsRead, closeNotificationCenter, deleteNotifications, notification, followUpAction]);

  return (
    <>
      <Collapse collapsedSize={SINGLE_ITEM_COLLAPSED_HEIGHT} in={open} onExited={() => setTextWrap(false)}>
        <ListItem button
                  className={classes.notificationListItem}
                  onClick={() => {
                    setOpen(o => !o);
                    if (!open) {
                      // is closed by collapse exit event
                      setTextWrap(true);
                    }
                    markNotificationAsRead(notification.key);
                  }}
        >
          <ListItemIcon className={classes.confirmIconContainer}>
            {followUpAction
              ? <FollowUpActionButton read={read} onClick={triggerFollowUpAction} icon={followUpAction?.icon} tooltip={followUpAction?.tooltip} />
              : <ReadIndicator read={read} onClick={() => markNotificationAsRead(notification.key)} />}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{component: "div"}}
                        primary={(
                          <>
                            <Typography className={classes.notificationTitle} variant="body2" component="div">
                              {notification.title}
                            </Typography>
                            <div style={{display: "flex"}}>
                              <div className={classes.notificationTimestamp}>
                                {dateService.formatDistanceToNow(notification.timestamp)}
                              </div>
                              <div>
                                <IconButtonComponent handleClick={() => deleteNotifications(notification.key)} icon="delete_outline" tooltip={getMessage(MessageKey.CORE.NOTIFICATIONS.DELETE)} tooltipPlacement="top" padding="medium" />
                              </div>
                            </div>
                          </>
                        )}
                        secondary={(
                          <>
                            {notification.message && (
                              <Typography className={clsx(classes.notificationMessage, !textWrap && classes.notificationMessageCollapsed)}
                                          component="span"
                                          variant="body2"
                              >
                                {notification.message}
                              </Typography>
                            )}
                          </>
                        )}
                        secondaryTypographyProps={{component: "div"}}
                        classes={{
                          primary: classes.titleLine,
                          secondary: classes.messageLine,
                        }}
          />
        </ListItem>
        {hasAdditionalActions && (
          <ListItem>
            <Grid container justifyContent="flex-end" spacing={1}>
              {notification.actions?.map((action: Action, idx: number) => (
                <Grid key={idx} item>
                  <IconButtonComponent handleClick={() => handleAction(action, notification.actionContext)}
                                       icon={action.icon}
                                       tooltip={action.label}
                                       tooltipPlacement="top"
                                       padding="medium"
                  />
                </Grid>
              ))}
            </Grid>
          </ListItem>
        )}
      </Collapse>
    </>
  );
};


type GroupedNotificationItemProps = {
  groupedNotification: GroupedNotification
}

const GroupedNotificationItem = ({groupedNotification}: GroupedNotificationItemProps) => {
  const classes = useStyles();
  const {markNotificationAsRead} = useNotificationCenterContext();

  const hasUnread = groupedNotification.notifications.some((n: Notification) => !n.read);
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItem button className={classes.notificationListItem} onClick={() => setOpen(o => !o)}>
        <ListItemIcon className={classes.confirmIconContainer}>
          <FollowUpActionButton read={hasUnread} onClick={() => markNotificationAsRead(groupedNotification.notifications.map(n => n.key))} icon="check" tooltip="TODO" />
        </ListItemIcon>
        <ListItemText primary={`${groupedNotification.notifications.length} ${groupedNotification.groupTitle}`}
                      secondary={dateService.formatDistanceToNow(groupedNotification.timestamp)}
        />
        <ListItemSecondaryAction>
          <IconButton
          edge="end"
          onClick={() => setOpen(o => !o)}
          onMouseDown={(e) => e.stopPropagation()}
          size="large"
          >
            <KeyboardArrowRight />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {open && (
        groupedNotification.notifications.map(n => (
          <SingleNotificationItem key={n.key} notification={n} />
        ))
      )}
    </>
  );
};


type FollowUpActionButtonProps = {
  onClick: () => void
  icon: string
  tooltip: string
  read: boolean
}

const FollowUpActionButton = ({onClick, tooltip, icon, read}: FollowUpActionButtonProps) => {
  const classes = useStyles();
  return (
    <IconButtonComponent classes={{button: clsx(!read && classes.confirmIcon)}}
                         fontSize="inherit"
                         padding="small"
                         icon={icon}
                         tooltip={tooltip}
                         tooltipPlacement="left"
                         handleClick={(event) => {
                           event.preventDefault();
                           event.stopPropagation();
                           onClick?.();
                         }}
                         IconButtonProps={{
                           onMouseDown: (event) => event.stopPropagation(),
                         }}
    />
  );
};

type ReadIndicatorProps = {
  read: boolean
  onClick: () => void
}

const ReadIndicator = ({read, onClick}: ReadIndicatorProps) => {
  const classes = useStyles();
  const {getMessage} = useMessages();
  return (
    <IconButtonComponent classes={{button: clsx(!read && classes.confirmIcon)}}
                         fontSize="inherit"
                         padding="small"
                         icon="circle_medium"
                         iconOnHover={read ? undefined : "check"}
                         tooltip={read ? undefined : getMessage(MessageKey.CORE.NOTIFICATIONS.MARK_AS_READ)}
                         tooltipPlacement="left"
                         handleClick={(event) => {
                           event.preventDefault();
                           event.stopPropagation();
                           onClick();
                         }}
                         IconButtonProps={{
                           onMouseDown: (event) => event.stopPropagation(),
                         }}
                         disabled={read}
    />
  );
};
