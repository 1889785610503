/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";
import {customAlphabet} from "nanoid";

import {IUiState, TabbedPerspective, TabGroup, TabGroupTab} from "../types";
import {canSplit, findTabGroup, findTabInGroup, tabGroupIndex} from "./PerspectiveHelper";

export type SplitTabGroupOptions = {
  tabGroupId: string,
  tabId: string,
}

/**
 * Add a new group to the right of the group identified by the tabGroupId
 * and move the current selected tab into that group. If the current
 * selected group has one tab, this is a noop.
 *
 * @param state
 * @param action
 * @constructor
 */
export const SplitTabGroup = (state: IUiState, action: PayloadAction<SplitTabGroupOptions>) => {
  const perspective = state.perspective;
  const {tabGroupId, tabId} = action.payload;
  if (perspective?.perspectiveType === "TABBED") {
    const leftGroup = findTabGroup(perspective, tabGroupId);
    if (leftGroup && canSplit(leftGroup) && perspective) {
      const tabToMove = findTabInGroup(leftGroup, tabId);
      if (tabToMove) {
        const rightGroup = createTabGroup(perspective);
        addGroup(perspective, leftGroup, rightGroup);
        moveTab(leftGroup, tabToMove, rightGroup);
      }
    }
  } else {
    console.warn("Can't split TabGroup in non-tabbed perspective");
  }
};

export const generateTabGroupId = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789", 4);

function addGroup(
  perspective: TabbedPerspective,
  leftGroup: TabGroup,
  rightGroup: TabGroup
) {
  const groupIndex = tabGroupIndex(perspective, leftGroup);
  const currentGroups = perspective.tabGroups;
  const head = currentGroups.slice(0, groupIndex + 1);
  const tail = currentGroups.slice(groupIndex + 1);

  // assign new ids to the tail entries to
  // force recreation of allotment.panes in the perspective
  // See: https://github.com/johnwalley/allotment/discussions/57
  perspective.tabGroups = head.concat([rightGroup]).concat(tail);
  perspective.currentTabGroupId = rightGroup.id;
}


function createTabGroup(perspective: TabbedPerspective): TabGroup {
  // const size = perspective.mainAreaWidth / (perspective.tabGroups.length + 1);
  return {
    id: generateTabGroupId(),
    tabs: [],
    selectedTabIndex: 0,
    // size,
  };
}

function moveTab(
  sourceGroup: TabGroup,
  sourceTab: TabGroupTab,
  targetGroup: TabGroup
) {
  // select the tab left of the current tab
  sourceGroup.selectedTabIndex = Math.max(sourceGroup.selectedTabIndex - 1, 0);

  sourceGroup.tabs = sourceGroup.tabs.filter(t => t.id !== sourceTab.id);
  targetGroup.tabs.push(sourceTab);
}
