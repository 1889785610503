/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {createWeakEntityPropertyChanges, entityUpdateService} from "../../service";
import {isEntity, WeakEntityAlikeObject} from "../../util";
import {EntityEditState} from "../types";


export type AddWeakEntityOptions = {

  /** Id of the entity that hosts the weak entity */
  entityId: string,

  /** Name of the attribute that references a weak entity */
  attributeName: string

  weakEntity: WeakEntityAlikeObject

};


export const AddWeakEntity = (state: EntityEditState, action: PayloadAction<AddWeakEntityOptions>) => {
  const {entityId, attributeName, weakEntity} = action.payload;

  const updatedModel  = state.editModels[entityId];
  if (!updatedModel) {
    throw new Error("Cannot find entity edit model for id " + entityId);
  }

  const editModel = state.editModels[entityId];
  if (!editModel) {
    throw new Error(`Cannot find entity edit model for id ${entityId}`);
  }

  const entity = editModel.entity;
  if (isEntity(entity)) {
    const propertyChanges = createWeakEntityPropertyChanges(entity, attributeName, weakEntity);
    if (propertyChanges) {
      editModel.propertyChanges.push(...propertyChanges);
      editModel.currentEntity = entityUpdateService.applyChanges(entity,
        [...editModel.propertyChanges, ...editModel.propertyChangesForEagerValues]);

      state.editModels[entityId] = editModel;
    }
  }
};
