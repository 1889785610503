/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ModuleRegistrationOptions} from "@icm/core-common";

import {webChatViewDescriptor} from "./src/component/webChatViewDescriptor";
import packageInfo from "./src/generated/package.clone.json";

export const chatWebModuleOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  registerServices: (serviceRegistry, registerLoginHandler) => {
    registerLoginHandler((justLoggedIn) => {
      return import("./src/api/ChatServiceProvider").then(({ChatServiceProvider}) => {
        const securityService = serviceRegistry.get("SECURITY");
        ChatServiceProvider.getOrCreateInstance(securityService)
          .then(cs => cs.connect(justLoggedIn))
          .catch((reason: Error) => {
            console.log("No chat configured", reason.message);
          });
        return () => {
          ChatServiceProvider.destroy();
        };
      });
    });
  },
  providedViews: [webChatViewDescriptor],
};
