/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {BaseTextBoxComponent, LabelWithFieldStateMarker, TextBoxComponentProps} from "@icm/core-common";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {StyledComponentProps, withStyles} from "@mui/styles";
import * as React from "react";

import {FormatSelection} from "./FormatSelection";
import styles from "./TextBoxComponentStyle";

type AllProps = TextBoxComponentProps & StyledComponentProps;

class TextBoxComponent extends BaseTextBoxComponent<AllProps> {

  constructor(props: AllProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  protected renderEditableComponent() {
    if (this.state.error) {
      console.debug(`Value "${this.state.value}" is invalid`);
    }
    const adornments = [];
    if (this.props.formats) {
      adornments.push(
        <FormatSelection key="formats"
                         format={this.state.format || ""}
                         formats={this.props.formats!}
                         formatDisplay={this.props.formatDisplay}
                         handleChange={this.handleFormatChange}
                         valid={!this.state.error}
                         value={this.state.value}
        />
      );
    }
    if (this.props.adornments && this.props.adornments.length > 0) {
      adornments.push(...this.props.adornments);
    }
    const isHtmlHelperText = this.props.helperText?.startsWith("<");
    if (isHtmlHelperText) {
      adornments.push(
        <Tooltip key="help" title={<div dangerouslySetInnerHTML={{__html: this.props.helperText || ""}} />}>
          <IconButton size="large"><HelpOutlineIcon /></IconButton>
        </Tooltip>
      );
    }

    const InputProps = {
      endAdornment: adornments.length > 0 ? <InputAdornment position="end">{adornments}</InputAdornment> : undefined,
    };
    const htmlInputProps = {
      "aria-label": this.props.label,
    };

    return (
      <TextField variant="standard"
                 label={<LabelWithFieldStateMarker label={this.props.label} badgeColor={this.props.badgeColor} required={this.props.required} />}
                 type={this.props.multiline ? undefined : this.props.type}
                 value={this.state.displayValue || ""}
                 onChange={this.handleChange}
                 onBlur={this.handleBlur}
                 multiline={this.props.multiline}
                 disabled={this.props.disabled}
                 fullWidth={true}
                 helperText={!isHtmlHelperText ? this.props.helperText : undefined}
                 error={this.state.error}
                 onFocus={this.props.onFocus}
                 InputProps={InputProps}
                 inputProps={htmlInputProps}
      />
    );
  }


  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.handleTextChange(event.target.value);
  }
}

const component = withStyles(styles)(TextBoxComponent);
export {component as TextBoxComponent};
