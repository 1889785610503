/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {AbstractDateTimeComponent, dateService, ErrorBoundary, IDateTimeComponentProps, LabelWithFieldStateMarker} from "@icm/core-common";
import {TextField, TextFieldProps} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {Moment} from "moment";
import * as React from "react";

export class DateTimeComponent extends AbstractDateTimeComponent {

  constructor(props: IDateTimeComponentProps) {
    super(props, dateService.getMomentDateFormats().date["SHORT"] + " " + dateService.getMomentDateFormats().time["SHORT"]);
  }

  protected renderDateTimeComponent(value: Date | Moment | null | undefined, format: string, placeholder: string) {
    return (
      <ErrorBoundary>
        <DateTimePicker disabled={this.props.disabled}
                        minDateTime={this.props.minDate}
                        maxDateTime={this.props.maxDate}
                        value={value}
                        inputFormat={format}
          // TODO PICM-1455 masked input doesn't work right now
                        disableMaskedInput
          // mask={this.createMaskFromFormat()}
          // acceptRegex={/[a-z0-9]/gi}
                        onChange={this.handleChange}
                        ampm={false}
          // TODO PICM-1455 temporary workaround until problem is fixed by material-ui
                        InputAdornmentProps={{
                          sx: {padding: 1},
                        }}
                        onOpen={this.props.onFocus}
                        ignoreInvalidInputs={true}
                        renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
                          <TextField {...props}
                                     variant="standard"
                                     label={(
                                       <LabelWithFieldStateMarker
                                         required={this.props.required}
                                         label={this.props.label}
                                         badgeColor={this.props.badgeColor}
                                         error={this.props.error}
                                       />
                                     )}
                                     onBlur={event => this.handleBlur(event, format)}
                                     helperText={this.props.helperText}
                                     fullWidth
                                     error={!this.state.valid || this.props.error}
                                     onFocus={this.props.onFocus}
                                     InputProps={{
                                       ...props.InputProps,
                                       "aria-label": `${this.props.label}`,
                                     }}
                          />
                        )}
        />
      </ErrorBoundary>
    );
  }
}
