/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ActionDescriptor, createRefreshActionId, FilterParameter, MessageKey, ParameterUtilities, View, ViewDescriptor} from "@icm/core-common";
import {isString} from "lodash-es";

import {CreateEntityType, mapToCreateEntityType, prepareCreateEntityTypeActionDescriptorKey, prepareDefaultCreateEntityType} from "../ui";

export type EntityListViewModelData = {
  entityType: string
  listVariant?: string
  processVariant?: string
  filterParameter?: FilterParameter
  showCsvDownload: boolean
  showCreateEntity: boolean
  entityEditorViewId?: string
  createEntityTypes: CreateEntityType[],
  printRequested: boolean,
}

export const createEntityListViewDescriptor = (Component: View<EntityListViewModelData>): ViewDescriptor<EntityListViewModelData> => {
  return {
    viewType: "ENTITY_LIST",
    view: Component,
    getTitle: (viewModel, getMessage) => {
      const {
        entityType,
        listVariant = "default",
      } = viewModel.viewModelData;
      const {viewLabel} = viewModel;
      return viewLabel ?? getMessage(`${entityType.toLowerCase()}.list.${listVariant.toLowerCase()}.title`,
        {fallbackMessageCode: MessageKey.ENTITY.LIST.DEFAULT.TITLE});
    },
    getViewActionDescriptors: (viewModel, getMessage) => {
      const {entityType} = viewModel.viewModelData;
      const isCreateEntityActionVisible = viewModel.viewModelData.entityEditorViewId !== undefined && viewModel.viewModelData.showCreateEntity;
      const refreshActionKey = createRefreshActionId(entityType);

      const actionDescriptors: Record<string, ActionDescriptor> = {
        START_PROCESS: {
          title: getMessage(`${entityType.toLowerCase()}.label.create`, {fallbackMessageCode: MessageKey.CORE.CREATE}),
          icon: "play_arrow",
          visible: !!viewModel.viewModelData.processVariant,
        },
        DOWNLOAD_CSV: {
          title: getMessage(MessageKey.CORE.DOWNLOAD.CSV),
          icon: "export_as_file",
          enabled: true,
          visible: viewModel.viewModelData.showCsvDownload,
        },
        FILTER: {
          title: getMessage(MessageKey.CORE.LIST.FILTER),
          icon: "filter",
          enabled: true,
          visible: true,
          type: "primary",
        },
        [refreshActionKey]: {
          title: getMessage(MessageKey.CORE.REFRESH),
          icon: "refresh",
        },
      };

      viewModel.viewModelData.createEntityTypes.forEach((createEntityType: CreateEntityType) => {
        const actionDescriptorKey = prepareCreateEntityTypeActionDescriptorKey(createEntityType);
        const title = getMessage(createEntityType.labelKey ? createEntityType.labelKey : `${entityType.toLowerCase()}.label.create`, {fallbackMessageCode: MessageKey.CORE.CREATE});
        actionDescriptors[actionDescriptorKey] = {
          title,
          visible: isCreateEntityActionVisible,
          icon: createEntityType.icon ?? "add",
        };
      });

      return actionDescriptors;
    },
    createUniqueHash: (viewModelData) => viewModelData.entityType,
    getShareParameters: viewModel => {
      const {
        entityType,
        listVariant,
      } = viewModel.viewModelData;
      if (entityType) {
        return {
          entityType,
          listVariant,
        };
      } else {
        return undefined;
      }
    },
    initializeViewModelData: viewParameters => {
      const entityType = ParameterUtilities.getResolvedParameterValue("entityType", viewParameters, isString);
      const listVariant = ParameterUtilities.getResolvedParameterValue("listVariant", viewParameters, isString);
      const processVariant = ParameterUtilities.getResolvedParameterValue("processVariant", viewParameters, isString);
      const showCsvDownload = ParameterUtilities.getResolvedFlag("csvDownload", viewParameters, true);
      const showCreateEntity = ParameterUtilities.getResolvedFlag("createEntity", viewParameters, true);
      const createEntityTypes: CreateEntityType[] = showCreateEntity ? ParameterUtilities.getResolvedArrayParameterValue(
        "createEntityTypes",
        viewParameters,
        mapToCreateEntityType
      ) ?? [prepareDefaultCreateEntityType()] : [];
      const entityEditorViewId = ParameterUtilities.getResolvedParameterValue("editViewId", viewParameters, isString);

      if (!entityType) {
        throw Error("No entity type given");
      }
      return {
        entityType,
        filterParameter: viewParameters.filter,
        listVariant,
        processVariant,
        showCsvDownload,
        showCreateEntity,
        entityEditorViewId,
        createEntityTypes,
        printRequested: false,
      };
    },
  };
};
