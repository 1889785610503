/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {keys} from "lodash-es";

/**
 * Structure to associate a mimeType with one or more file extensions.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
 */
export type MimeTypeMapping = {
  [key: string]: string[];
}


/**
 * @param a An MimeTypeMapping object
 * @return The keys (mimeTypes) of the passed object
 */
export function mimeTypes(a: MimeTypeMapping): string[] {
  return keys(a);
}

/**
 * Extract all file extensions from the given mapping.
 *
 * @param mapping An MimeTypeMapping object
 * @param removeDot flag to toggle removal of leading '.' in the returned strings.
 *                  If false, the returned strings will be something link ['.pdf', '.doc'] as opposed
 *                  to ['pdf', 'doc'].
 * @return All values of the passed object
 */
export function extensions(mapping: MimeTypeMapping, removeDot = true): string[] {
  return keys(mapping)
    .flatMap(k => mapping[k])
    .map(s => s.trim())
    .map(s => s.toLowerCase())
    .map(s => removeDot ? s.replace(".", "") : s);
}

/**
 * Create a MimeTypeMapping from a comma separated list of MimeTypes.
 * The returned mapping will be created from the ACCEPTED_* mappings.
 *
 * @param types A comma separated string of mime types. If nothing provided,
 *              '*' will be used which means all accepted types will be returned.
 * @see ACCEPTED_APPLICATION_TYPES
 * @see ACCEPTED_AUDIO_TYPES
 * @see ACCEPTED_IMAGE_TYPES
 * @see ACCEPTED_TEXT_TYPES
 * @see ACCEPTED_VIDEO_TYPES
 * @see DEFAULT_ACCEPTED_TYPES
 */
export function mimeTypeMapping(types = "*"): MimeTypeMapping {
  return types.split(",")
    .map(t => defaultMapping(t))
    .reduce((prev, current) => ({...prev, ...current}));
}

function defaultMapping(mimeType: string): MimeTypeMapping {
  const input = mimeType.trim().toLowerCase();
  switch (input) {
    case "*":
      return DEFAULT_ACCEPTED_TYPES;
    case "image/*":
      return ACCEPTED_IMAGE_TYPES;
    case "video/*":
      return ACCEPTED_VIDEO_TYPES;
    case "audio/*":
      return ACCEPTED_AUDIO_TYPES;
    case "application/*":
      return ACCEPTED_APPLICATION_TYPES;
    case "text/*":
      return ACCEPTED_TEXT_TYPES;
    default:
      const ext = DEFAULT_ACCEPTED_TYPES[input];
      if (ext) {
        return {input: [...ext]};
      }
      return {input: []};
  }
}

/**
 * Currently accepted image types. Add a new image type here
 * if it should be supported in our components.
 */
export const ACCEPTED_IMAGE_TYPES: MimeTypeMapping = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/svg+xml": [".svg"],
};

/**
 * Currently accepted audio types. Add a new type here
 * if it should be supported in our components.
 */
export const ACCEPTED_AUDIO_TYPES: MimeTypeMapping = {
  "audio/mpeg": [".mpg", ".mpeg"],
  "audio/mp3": [".mp3"],
  "audio/mp4": [".mp4"],
  "audio/vnd.wav": [".wav"],
};

/**
 * Currently accepted video types. Add a new type here
 * if it should be supported in our components.
 */
export const ACCEPTED_VIDEO_TYPES: MimeTypeMapping = {
  "video/mp4": [".mp4"],
  "video/mpeg": [".mpg", ".mpeg"],
  "video/mpeg2": [".mpeg2"],
  "video/x-ms-wmv": [".wmv"],
  "video/webm": [".webm"],
  "video/quicktime": [".quicktime"],
};

/**
 * Currently accepted text types. Add a new type here
 * if it should be supported in our components.
 */
export const ACCEPTED_TEXT_TYPES: MimeTypeMapping = {
  "text/rtf": [".rtf"],
  "text/csv": [".csv"],
  "text/plain": [".txt", ".md", ".adoc"],
};

/**
 * Currently accepted application types. Add a new type here
 * if it should be supported in our components.
 */
export const ACCEPTED_APPLICATION_TYPES: MimeTypeMapping = {
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.ms-word": [".doc"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml": [".xlsx"],
  "application/vnd.openxmlformats-officedocument.presentationml": [".pptx"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml": [".docx"],
  "application/vnd.oasis.opendocument.text": [".odf"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.oasis.opendocument.chart": [".odc"],
  "application/vnd.oasis.opendocument.graphics": [".odg"],
  "application/pdf": [".pdf"],
};

/**
 * @return An object that describes MimeTypeMapping we accept as upload.
 * @see MimeTypeMapping
 */
export const DEFAULT_ACCEPTED_TYPES: MimeTypeMapping = {
  ...ACCEPTED_IMAGE_TYPES,
  ...ACCEPTED_VIDEO_TYPES,
  ...ACCEPTED_AUDIO_TYPES,
  ...ACCEPTED_APPLICATION_TYPES,
  ...ACCEPTED_TEXT_TYPES,
};
