/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {dateService, SortService} from "@icm/core-common";
import {set} from "lodash-es";
import {Reducer} from "redux";
import {ActionType, getType} from "typesafe-actions";

import {Standby} from "../../generated/api";
import * as standbysActions from "./actions";
import {IStandbyState} from "./types";

export type StandbysAction = ActionType<typeof standbysActions>

const initialStandbyState: IStandbyState = {
  availableStandbyTeams: undefined,
  interval: 7,
  newStandby: fillDate(new Standby(), 7, []),
  standbyList: [],
  loadingStandbys: false,
  loadingStandbyTeams: false,
  loadingStandbyTeamMembers: false,
};

const standbyReducer: Reducer<IStandbyState> = (state = initialStandbyState, action: StandbysAction): IStandbyState => {
  switch (action.type) {
    case getType(standbysActions.fetchStandbyTeams.request): {
      return {...state, loadingStandbyTeams: true};
    }
    case getType(standbysActions.fetchStandbyTeams.success): {
      if (action.payload.length > 0) {
        const teams =  action.payload;
        teams.sort(SortService.createComparator(a => a.name!, (a, b) => a.localeCompare(b)));

        return {
          ...state,
          availableStandbyTeams: teams,
          standbyTeam: teams[0],
        };
      }

      return {
        ...state,
        loadingStandbyTeams: false,
        availableStandbyTeams: [],
        standbyTeam: undefined,
      };
    }
    case getType(standbysActions.fetchStandbyTeams.failure): {
      return {...state, loadingStandbyTeams: false};
    }
    case getType(standbysActions.fetchStandbyTeamMembers.request): {
      return {...state, loadingStandbyTeamMembers: true};
    }
    case getType(standbysActions.fetchStandbyTeamMembers.success): {
      if (action.payload.length > 0) {
        const members =  action.payload;
        members.sort(SortService.createComparator(a => a.lastName!, (a, b) => a.localeCompare(b)));

        return {
          ...state,
          standbyTeamMembers: members,
        };
      }

      return {
        ...state,
        loadingStandbyTeamMembers: false,
        standbyTeamMembers: [],
      };
    }
    case getType(standbysActions.fetchStandbyTeamMembers.failure): {
      return {...state, loadingStandbyTeamMembers: false};
    }
    case getType(standbysActions.fetchStandbys.request): {
      return {...state, loadingStandbys: true, standbyTeam: action.payload};
    }
    case getType(standbysActions.fetchStandbys.success): {
      const standbyList = action.payload;
      standbyList.sort(SortService.createComparator(a => a.startDate?.getTime()));
      return {
        ...state,
        loadingStandbys: false,
        standbyList,
        newStandby: fillDate(new Standby(), state.interval, action.payload),
      };
    }
    case getType(standbysActions.fetchStandbys.failure): {
      return {...state, loadingStandbys: false};
    }
    case getType(standbysActions.submitStandby.request): {
      return {...state, error: undefined};
    }
    case getType(standbysActions.submitStandby.success): {
      return {...state, newStandby: fillDate(new Standby(), state.interval, state.standbyList)};
    }
    case getType(standbysActions.submitStandby.failure): {
      return {...state, error: action.payload};
    }
    case getType(standbysActions.setInterval): {
      return {
        ...state,
        interval: action.payload,
        newStandby: fillDate(state.newStandby, action.payload, state.standbyList),
      };
    }
    case getType(standbysActions.updateNewStandby): {
      const standby = {...state.newStandby};
      set(standby, action.payload.fieldName, action.payload.value);
      return {...state, newStandby: standby};
    }
    case getType(standbysActions.updateNewStandbyTime): {
      const standby = fillDate(state.newStandby, state.interval, state.standbyList);
      return {...state, newStandby: standby};
    }
    case getType(standbysActions.deleteError): {
      return {...state, error: undefined};
    }
    default: {
      return state;
    }
  }
};

function isDateInPast(date: Date): boolean {
  return date < new Date();
}

/**
 * Returns the last standby-date or today.
 * @param standbyList List of all available standby-entries
 */
function getLastDate(standbyList: Standby[]): Date {
  if (standbyList.length > 0) {
    return standbyList.map(s => s.startDate!).sort((a, b) => dateService.compare(a, b, "DESC"))[0];
  }
  return new Date();
}

function addInterval(date: Date, intervalInDays: number): Date {
  const temp = new Date(date);
  temp.setDate(date.getDate() + intervalInDays);
  return temp;
}

function calculateStandbyStartDate(standbyList: Standby[], intervalInDays: number): Date {
  const lastDate = getLastDate(standbyList);
  const lastDatePlusInterval = addInterval(lastDate, intervalInDays);
  if (isDateInPast(lastDatePlusInterval)) {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + intervalInDays);
    newDate.setHours(lastDate.getHours(), lastDate.getMinutes(), lastDate.getSeconds());
    return newDate;
  } else {
    return lastDatePlusInterval;
  }
}

function fillDate(standby: Standby, intervalInDays: number, standbyList: Standby[]) {
  const newStandby = {...standby};
  newStandby.startDate = calculateStandbyStartDate(standbyList, intervalInDays);
  return newStandby;
}

export {standbyReducer};
