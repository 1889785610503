/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {AbstractFormComponent, ExpressionEvaluationService, FetchService, IFormComponentProps} from "@icm/core-common";
import {Button, Grid, Menu, MenuItem} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import * as React from "react";


import {IcmMuiTheme} from "../../../theme";

interface IButtonDropDownProps<T> extends IFormComponentProps<T> {
  label: string,
  type?: ButtonProps["type"],
  icon?: JSX.Element,
  color?: "inherit" | "primary" | "secondary",
  disabled?: boolean,

  availableValues?: T[],
  apiUrl?: string,
  valueDisplay?: string,
}

interface IState<T> {
  anchorElement: any,
  availableValues?: T[];
}

const styles = (theme: IcmMuiTheme) => createStyles({
  rightIcon: {
    marginLeft: theme.spacing(1),
    width: 24,
  },
});

type AllProps<T> = IButtonDropDownProps<T> & WithStyles<typeof styles>;

class ButtonDropDownComponent<T> extends AbstractFormComponent<T, AllProps<T>, IState<T>> {

  constructor(props: Readonly<AllProps<T>>) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      anchorElement: null,
    };
    if (this.props.apiUrl) {
      FetchService.performGet(this.props.apiUrl)
        .then((values: T[]) => {
          this.setState(state => ({
            ...state,
            availableValues: values,
          }));
        }).catch(e => console.log(e));
    } else if (this.props.availableValues) {
      this.state = {
        anchorElement: null,
        availableValues: this.props.availableValues,
      };
    } else {
      throw new Error("either apiUrl or availableValues required for rendering");
    }
  }

  protected renderReadOnlyText(): string | undefined {
    return undefined;
  }

  protected renderEditableComponent() {
    const {anchorElement} = this.state;
    const availableValues = this.state.availableValues || this.props.availableValues;
    if (availableValues) {
      return (
        <div>
          <Button aria-owns={anchorElement ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  disabled={this.props.disabled}
                  variant="contained"
                  color={this.props.color || "primary"}
                  type={this.props.type}
                  onClick={this.handleClick}
          >
            {this.props.label}
            {this.props.icon && (
            <Grid container={true} alignItems="center" className={this.props.classes.rightIcon}>{this.props.icon}</Grid>
            )}
          </Button>
          <Menu
                        id="simple-menu"
                        anchorEl={anchorElement}
                        open={Boolean(anchorElement)}
                        onClose={this.handleClose}
          >
            {availableValues!.map((value, index) => this.addMenuItem(index, value))}
          </Menu>
        </div>
      );
    }
    return (
      <span>Loading data...</span>
    );
  }

  public componentDidUpdate(prevProps: Readonly<AllProps<T>>, prevState: Readonly<IState<T>>): void {
    if (this.props.availableValues !== prevProps.availableValues) {
      this.setState(state => ({
        ...state,
        availableValues: this.props.availableValues,
      }));
    }
  }

  private addMenuItem(idx: number, value: T) {
    return (
      <MenuItem key={idx}
                onClick={() => this.handleSelect(value)}
      >{ExpressionEvaluationService.evaluate(this.props.valueDisplay!, value)}
      </MenuItem>
    );
  }

  private handleClick(event: React.MouseEvent) {
    this.setState({anchorElement: event.currentTarget});
  };

  private handleClose() {
    this.setState({anchorElement: null});
  };

  private handleSelect(value: T) {
    if (value) {
      this.props.handleChange(value);
      this.handleClose();
    }
  };

}

const component = withStyles(styles)(ButtonDropDownComponent);
export {component as ButtonDropDownComponent};
