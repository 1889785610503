/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import moment from "moment";
import {customAlphabet} from "nanoid";

import {MessageKey, IdGenerator} from "../../index";
import {CounterConfiguration, CounterConfigurationList} from "../generated/api";
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";
import {FetchService} from "./FetchService";
import {messages} from "./MessageService";
import {queryClient} from "./QueryClientService";
import {CoreServiceRegistry} from "./registry";

const COUNTER_CONFIGURATIONS_CACHE_KEY = ["public", "core", "configuration", "counter"];

/**
 * Service to create a unique identifier based on a server configurated pattern.
 */
export interface CounterService {

  /**
   * Get the identifier value using the counter configuration associated with the given name.
   * Note: This operation can not be repeated with the same result (i.e. it is not idempotent)
   *
   * @param counterName the counter name to identify the counter configuration
   */
  nextValue(counterName: string): string | undefined;
}

/** Number of retries to load the counter configuration before giving up and switching to fallback service. */
const MAX_RETRY_COUNT = 2;

/**
 * Factory to create a service that uses server configured counter
 * expressions.
 */
export function createCounterService(): Promise<CounterService> {
  console.log("Creating counter service");
  return fetchCounterConfigurations()
    .then((counterConfigList) => {
      return DefaultCounterService(counterConfigList);
    })
    .catch(e => {
      console.error("Could not fetch counter configuration after", MAX_RETRY_COUNT, "attempts. Using FallbackCounterService.", e);
      return FallbackCounterService();
    })
    .then(instance => {
      ExpressionEvaluationService.registerHelper("counter", "nextValue", (counterName) => {
        if (counterName !== undefined) {
          return instance.nextValue(counterName);
        }
        return undefined;
      });
      ExpressionEvaluationService.registerHelper("counter", "uuid", () => {
        return IdGenerator.randomUUID();
      });
      return instance;
    });
}

const DefaultCounterService = (counterConfigList: CounterConfigurationList): CounterService => {
  console.warn("Info creating default counter service using config:", counterConfigList);
  return {
    nextValue: counterName => {
      const counterConfig = getConfiguration(counterConfigList, counterName);
      if (counterConfig) {
        return nextValue(counterConfig);
      } else {
        throw Error(`Counter configuration not available for ${counterName}. Fix setup!`);
      }
    },
  };
};

const FallbackCounterService = (): CounterService => {
  console.warn("Using fallback counter service. You can not use counter expressions.");
  return {
    nextValue: _counterName => {
      console.warn("Using fallback CounterService. Make sure a counter configuration was loaded.");
      return undefined;
    },
  };
};


/**
 * Provides access to the CounterService registered at the CoreServiceRegistry.
 */
export const counterService = () => CoreServiceRegistry.get("COUNTER");

const TINY = 3;
const SHORT = 4;
const MEDIUM = 6;
const LONG = 8;

/**
 * Create a nano id based on an alphabet which is visually secure.
 * Thus, the created id will contain number/letters that look alike
 * (e.g. 0, O, I, l and 1).
 */
function randomId(size: number) {
  return () => {
    return customAlphabet("ABCDEFGHJKLMNOPQRSTUVWX23456789", size)();
  };
}

/**
 * Create the handlebars context that contains all variables for client side counters.
 *
 * @param d The reference date whose values are used.
 */
function counterTemplateContext(d: Date) {
  const m = moment(d);
  return {
    yyyy: m.format("yyyy"),
    YEAR: m.format("yyyy"),

    MM: m.format("MM"),
    MONTH: m.format("MM"),

    DD: m.format("DDD"), // day of year
    dd: m.format("DD"), // day of month

    ww: m.format("WW"),
    WEEK: m.format("WW"),
    w: m.format("w"),

    HH: m.format("HH"),
    mm: m.format("mm"),
    ss: m.format("ss"),

    RANDOM_UPPER_TINY: randomId(TINY),
    RANDOM_UPPER_SHORT: randomId(SHORT),
    RANDOM_UPPER_MEDIUM: randomId(MEDIUM),
    RANDOM_UPPER_LONG: randomId(LONG),

    DRAFT: messages.get(MessageKey.CORE.FORM.DRAFT),
  };
}

/**
 * Get the next value using the counter configuration associated with the given name.
 * Note: This operation can not be repeated with the same result (i.e. it is not idempotent)
 *
 * @param counterConfiguration the counter configuration
 */
function nextValue(counterConfiguration: CounterConfiguration): string | undefined {
  const context = counterTemplateContext(new Date());
  console.info("Creating counter with pattern:", counterConfiguration.pattern!);
  return ExpressionEvaluationService.evaluate(counterConfiguration.pattern!, context);
}


function getConfiguration(configList: CounterConfigurationList, name: string): CounterConfiguration | undefined {
  return configList.counterConfigurations?.find(c => c.counterName === name);
}


async function fetchCounterConfigurations() {
  return await queryClient.fetchQuery(COUNTER_CONFIGURATIONS_CACHE_KEY, {
    queryFn: fetchConfigurations,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: MAX_RETRY_COUNT,
  });
}


function fetchConfigurations(): Promise<CounterConfigurationList> {
  console.log("Loading counter configurations");
  return FetchService.performGet("/core/counterConfiguration")
    .then(json => CounterConfigurationList.fromData(json));
}
