/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActionRunners,
  Action,
  CommonQuickActionHelper,
  ParameterUtilities,
  useActionHandler,
  useViewParameters,
  useService,
} from "@icm/core-common";
import {Fab, Grid, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {FC, useMemo} from "react";

import {IcmMuiIcon} from "../util";
import styles from "./QuickActionContainerStyle";

const useStyles = makeStyles(styles);

type QuickActionContainerProps = {
  quickActions: Action[]
  quickActionHandlers?: ActionRunners
  contextEntity?: unknown
  visibilityContext?: any
}

export const QuickActionContainer = ({quickActions, visibilityContext, contextEntity, quickActionHandlers}: QuickActionContainerProps) => {
  const viewParams = ParameterUtilities.flattenParameterList(useViewParameters());
  return (
    <Grid container
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
    >
      {quickActions.map((val, key) => (
        <React.Fragment key={key}>
          {renderQuickActionOrGroup(val)}
        </React.Fragment>
      ))}
    </Grid>
  );

  function renderQuickActionOrGroup(val: Action) {
    return (
      <QuickActionFab quickAction={val}
                      contextEntity={contextEntity}
                      viewParams={viewParams}
                      quickActionHandlers={quickActionHandlers}
                      visibilityContext={visibilityContext}
      />
    );
  }
};

type QuickActionFabProps = {
  quickAction: Action,
  viewParams: Record<string, any>,
  quickActionHandlers?: ActionRunners,
  contextEntity: unknown,
  visibilityContext?: any
}
const QuickActionFab: FC<QuickActionFabProps> = ({
  quickAction,
  quickActionHandlers,
  contextEntity,
  viewParams,
  visibilityContext,
}) => {
  const defaultActionHandler = useActionHandler(quickActionHandlers);
  const securityService = useService("SECURITY");

  // TODO PICM-1501 mb quick action handling
  const quickActionHelper = useMemo(
    () => new CommonQuickActionHelper(securityService, quickAction, viewParams, defaultActionHandler, contextEntity, visibilityContext),
    [securityService, defaultActionHandler, quickAction, contextEntity, viewParams, visibilityContext]
  );
  const classes = useStyles();

  if (!quickActionHelper.isValid) {
    return <></>;
  }

  return (
    <Grid item={true}>
      <Tooltip placement="top"
               title={quickActionHelper.label}
      >
        <Fab color="primary"
             size="small"
             onClick={quickActionHelper.handleQuickAction}
             classes={{
               root: classes.adaptFab,
             }}
        >
          <IcmMuiIcon name={quickAction.icon} fontSize="small" />
        </Fab>
      </Tooltip>
    </Grid>
  );
};
