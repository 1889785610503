/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ClientProfile} from "../..";
import {ClientProfileApi} from "../api";
import {UserAgentParser} from "../util/UserAgentParser";

class ClientProfileService {
  public static INSTANCE = new ClientProfileService();

  public static getInstance() {
    return ClientProfileService.INSTANCE;
  }

  public sendData() {
    const parser = new UserAgentParser();
    const browser = parser.getBrowserInformation();
    const os = parser.getOperatingSystemInformation();
    const profile: ClientProfile = {
      timestamp: new Date(),
      processorArchitecture: parser.getCpuArchitecture(),
      processorCount: navigator.hardwareConcurrency,
      osName: os.name,
      osVersion: os.version,
      browserName: browser.name,
      browserVersion: browser.version,
      screenWidth: window.screen ? window.screen.width : 0,
      screenHeight: window.screen ? window.screen.height : 0,
    };
    ClientProfileApi.postClientProfile(profile);
  }
}

const clientProfile = ClientProfileService.getInstance();

export {clientProfile};
