/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  Actor, CommunicationChangeDetails,
  isCollectionPropertyUpdate,
  isValuePropertyUpdate,
  ObjectChangeType,
  ObjectReference,
  OtherPropertyUpdate,
  PropertyUpdate,
} from "@icm/activitystream-common";
import {AutoRefreshingDateTime, dateService, formatService, MessageKey, messages, useMessages} from "@icm/core-common";
import {Highlight} from "@icm/core-web";
import {Box, Divider, Grid, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {escape as escapeHtml, truncate} from "lodash-es";
import {Archive, Delete, Information, Pencil, PlusCircle} from "mdi-material-ui";
import moment from "moment";
import * as React from "react";
import ReactDOMServer from "react-dom/server";

import styles from "./ChangeComponentStyle";
import {
  isCommunicationChange,
  isCommunicationChangeWithStatus,
  isObjectLifecycleChange,
  isProcessChange,
} from "./TypeGuards";

const useStyles = makeStyles(styles);

export type ConsolidatedChangeListProps = {
  key: string
  title: React.ReactNode
  date?: Date
  highlightUntil: Date | null
  // objectType (lowercased)
  objectType?: string
  changes: ConsolidatedChange[]
}

export type ConsolidatedChange = {
  key: string
  actor: Actor
  date: Date
  type?: ObjectChangeType
  eventType?: string;
  eventDetails?: { [index: string]: any };
  propertyUpdates?: PropertyUpdate[]
  relatedObjects?: ObjectReference[]
  otherUpdate?: string
  communicationDetails?: CommunicationChangeDetails
  highlightUntil: Date | null
}

export type ConsolidatedCommunicationChange = ConsolidatedChange & {
  communicationDetails: ValidCommunicationChangeDetails;
}

export type ValidCommunicationChangeDetails = Required<CommunicationChangeDetails>;

export const ConsolidatedChangeList = (props: ConsolidatedChangeListProps) => {
  const hasNoVisibleChange = props.changes.find(isVisibleChange) === undefined;
  return hasNoVisibleChange && shouldHighlight(props.highlightUntil)
    ? (
      <Highlight>
        <ChangeBox hasNoVisibleChange={hasNoVisibleChange} {...props} />
      </Highlight>
    )
    : <ChangeBox hasNoVisibleChange={hasNoVisibleChange} {...props} />;
};

type ChangeBoxProps = ConsolidatedChangeListProps & {
  hasNoVisibleChange: boolean
}

const ChangeBox = ({title, changes, objectType}: ChangeBoxProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <ChangeTitle title={title} />
      <Box className={classes.changesContainer}>
        {changes.filter(isVisibleChange)
          .map((change) => {
            return (
              <React.Fragment key={`change_${change.key}`}>
                <Divider className={classes.divider} />
                {shouldHighlight(change.highlightUntil) ? (
                  <Highlight>
                    <ChangeContent change={change} objectType={objectType} />
                  </Highlight>
                ) : (
                  <ChangeContent change={change} objectType={objectType} />
                )}
              </React.Fragment>
            );
          })}
      </Box>
    </Box>
  );
};

type ChangeTitleProps = {
  title: React.ReactNode
}

const ChangeTitle = ({title}: ChangeTitleProps) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item container className={classes.titleText} justifyContent="space-between">
        <Typography variant="subtitle2">
          {Array.isArray(title)
            ? title.map((el, idx) => <React.Fragment key={idx}>{el}</React.Fragment>)
            : (title)}
        </Typography>
      </Grid>
    </Grid>
  );
};

type ChangeContentProps = {
  change: ConsolidatedChange
  objectType?: string
}

const ChangeContent = ({change, objectType}: ChangeContentProps) => {
  const classes = useStyles();
  const changeTitleMessageKey = useChangeTitleMessageKey(change.type, objectType);
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="caption" className={classes.changeSecondary}>
          <ChangeIcon type={change.type} />
        </Typography>
      </Grid>
      <Grid item className={classes.changesList} container>
        <Grid item container justifyContent="space-between" color="text.secondary">
          <Typography variant="caption"
                      className={classes.changeSecondary}
                      dangerouslySetInnerHTML={{
                        __html: messages.get(changeTitleMessageKey, {
                          params: {
                            actor: `<span class="${classes.actor}">${getActorDisplay(change.actor)}</span>`,
                            ...getCommunicationMessageParams(change),
                          },
                        }) || "",
                      }}
          />
          <Tooltip title={dateService.formatDateTime(change.date)} placement="top-end">
            <Typography variant="caption" className={classes.changeSecondary}>
              <AutoRefreshingDateTime date={change.date} format={dateService.formatDistanceToNow} interval={60000} />
            </Typography>
          </Tooltip>
        </Grid>
        {change.otherUpdate && (
          <Grid item>
            <Typography variant="body2">
              {change.otherUpdate}
            </Typography>
          </Grid>
        )}
        {change.propertyUpdates && (
          <Grid item>
            <ul className={classes.propertyChangeList}>
              {change.propertyUpdates!.map((property, idx) => (
                <PropertyUpdateEntry key={idx} propertyUpdate={property} />
              ))}
            </ul>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const PropertyUpdateEntry = ({propertyUpdate}: {propertyUpdate: PropertyUpdate }) => {
  const classes = useStyles();
  if (isValuePropertyUpdate(propertyUpdate)) {
    const fieldName = escapeHtml(propertyUpdate.fieldReference?.displayName ?? "");
    const oldValue: RenderedValue = formatValue(propertyUpdate.oldValue, true, 100, propertyUpdate.format);
    const newValue: RenderedValue = formatValue(propertyUpdate.newValue, false, undefined, propertyUpdate.format);
    if (oldValue.hasValueDisplay !== newValue.hasValueDisplay || oldValue.valueDisplay !== newValue.valueDisplay) {
      const expression = oldValue.hasValueDisplay && newValue.hasValueDisplay
        ? MessageKey.ACTIVITYSTREAM.PROPERTY.VALUE.CHANGED_FROM_TO
        : (newValue.hasValueDisplay ? MessageKey.ACTIVITYSTREAM.PROPERTY.VALUE.CHANGED_TO : MessageKey.ACTIVITYSTREAM.PROPERTY.VALUE.CHANGED_FROM);

      const htmlString = messages.get(expression, {
        params: {
          field: `<span class="${classes.field}">${fieldName}</span>`,
          oldValue:
            `<span class="${classes.oldValue}" title="${oldValue.title}">${oldValue.valueDisplay}</span>`,
          newValue: `<span class="${classes.newValue}" title="${newValue.title}">${newValue.valueDisplay}</span>`,
        },
      }) || "";
      return (
        <li>
          <Typography variant="body2" display="inline" dangerouslySetInnerHTML={{__html: htmlString}} />
        </li>
      );
    } else {
      return null;
    }
  } else if (isCollectionPropertyUpdate(propertyUpdate)) {
    const fieldName = escapeHtml(propertyUpdate.fieldReference?.displayName ?? "");
    let added;
    let removed;
    let removedTruncated;
    if (propertyUpdate.addedItems?.length) {
      added = propertyUpdate.addedItems.map(value => formatValue(value, false).valueDisplay).join(", ");
    }
    if (propertyUpdate.removedItems?.length) {
      removed = propertyUpdate.removedItems.map(value => formatValue(value, true).valueDisplay).join(", ");
      removedTruncated = propertyUpdate.removedItems.map(value => formatValue(value, true, 100).valueDisplay).join(", ");
    }
    // title displayed when different, line breaks ignored
    return (
      <>
        {removed && (
          <li>
            <Typography variant="body2"
                        display="inline"
                        dangerouslySetInnerHTML={{
                          __html: messages.get(MessageKey.ACTIVITYSTREAM.PROPERTY.COLLECTION.REMOVED_ITEMS, {
                            params: {
                              field: `<span class="${classes.field}">${fieldName}</span>`,
                              removedItems:
                                `<span class="${classes.oldValue}" title="${removed !== removedTruncated ? removed : ""}">${removedTruncated}</span>`,
                            },
                          }) || "",
                        }}
            />
          </li>
        )}
        {added && (
          <li>
            <Typography variant="body2"
                        display="inline"
                        dangerouslySetInnerHTML={{
                          __html: messages.get(MessageKey.ACTIVITYSTREAM.PROPERTY.COLLECTION.ADDED_ITEMS, {
                            params: {
                              field: `<span class="${classes.field}">${fieldName}</span>`,
                              addedItems: `<span class="${classes.newValue}">${added}</span>`,
                            },
                          }) || "",
                        }}
            />
          </li>
        )}
      </>
    );
  } else {
    const otherPropertyUpdate = propertyUpdate as OtherPropertyUpdate;
    return (
      <li>
        <Typography variant="body2" display="inline">{otherPropertyUpdate.description}</Typography>
      </li>
    );
  }
};

//
// Utilities:
//

const isVisibleChange = (change: ConsolidatedChange): boolean => {
  const hasPropertyUpdates = change.propertyUpdates?.length !== undefined && change.propertyUpdates.length > 0;
  const hasOtherChange = change.otherUpdate !== undefined;
  return isObjectLifecycleChange(change) || isProcessChange(change) || isCommunicationChange(change)
    || hasPropertyUpdates || hasOtherChange || hasRelatedObjects(change);
};

const hasRelatedObjects = (change: ConsolidatedChange) => {
  return change.relatedObjects?.length !== undefined && change.relatedObjects.length > 0;
};

const shouldHighlight = (highlightUntil: Date | null) => {
  return highlightUntil && moment(highlightUntil).isAfter(moment());
};

const useChangeTitleMessageKey = (type?: ObjectChangeType, objectType?: string): string => {
  const {hasMessage} = useMessages();
  const getChangeTitleMessageKeyWithSuffix = (suffix: string) => {
    const genericKey = MessageKey.ACTIVITYSTREAM.CHANGE.TITLE[suffix];
    const typedKey = objectType ? `${objectType}.${genericKey}` : null;
    return (typedKey && hasMessage(typedKey)) ? typedKey : genericKey;
  };

  if (type) {
    switch (type) {
      case "CREATE":
        return getChangeTitleMessageKeyWithSuffix("HAS_CREATED");
      case "UPDATE":
        return getChangeTitleMessageKeyWithSuffix("HAS_UPDATED");
      case "ARCHIVE":
        return getChangeTitleMessageKeyWithSuffix("HAS_ARCHIVED");
      case "DELETE":
        return getChangeTitleMessageKeyWithSuffix("HAS_DELETED");
      case "COMMUNICATION":
        return getChangeTitleMessageKeyWithSuffix("COMMUNICATION_UPDATED");
      default:
        return MessageKey.ACTIVITYSTREAM.CHANGE.TITLE.OTHER;
    }
  } else {
    return MessageKey.ACTIVITYSTREAM.CHANGE.TITLE.OTHER;
  }
};

const getActorDisplay = (actor: Actor) => {
  return messages.get(MessageKey.ACTIVITYSTREAM.ACTOR, {
    params: {
      actor,
    },
  });
};

const getCommunicationMessageParams = (change: ConsolidatedChange) => {
  if (isCommunicationChangeWithStatus(change)) {
    const messageKey = MessageKey.ACTIVITYSTREAM.CHANGE.COMMUNICATION_STATUS[change.communicationDetails.status];
    return {
      ...change.communicationDetails,
      status: messages.get(messageKey, {defaultMessage: change.communicationDetails.status}),
    };
  }
  return {};
};

const ChangeIcon = ({type}: {type?: ObjectChangeType }) => {
  const classes = useStyles();
  switch (type) {
    case "CREATE":
      return <PlusCircle className={classes.icon} />;
    case "UPDATE":
      return <Pencil className={classes.icon} />;
    case "ARCHIVE":
      return <Archive className={classes.icon} />;
    case "DELETE":
      return <Delete className={classes.icon} />;
    case "COMMUNICATION":
      return <Pencil className={classes.icon} />;
    default:
      return <Information className={classes.icon} />;
  }
};

type RenderedValue = {
  valueDisplay: string,
  hasValueDisplay: boolean,
  isImage: boolean,
  title?: string,
}

const formatValue = (value: any, isOldValue: boolean, truncateAt?: number, format?: string): RenderedValue => {
  const formatType: string = format ?? "TEXT";
  let valueDisplay: string;
  let hasValueDisplay: boolean = true;
  let title: string;
  let isImage = false;
  if (value && typeof value === "string" && value.length >= 10 && dateService.parse(value) !== undefined && formatType === "TEXT") {
    valueDisplay = escapeHtml(formatService.format(value, "DATE_TIME"));
    title = ""; // not needed as value is not truncated
    hasValueDisplay = valueDisplay.length > 0;
  } else if (value && value.svg) {
    const style: React.CSSProperties = {margin: "8px 0px"};
    if (isOldValue) {
      style.opacity = 0.2;
    }
    const base64data = new Buffer(value.svg).toString("base64");
    valueDisplay = ReactDOMServer.renderToString(<div><img src={`data:image/svg+xml;base64,${base64data}`} style={style} alt="Process Definition" /></div>);
    title = "";
    isImage = true;
  } else {
    const formattedValue = formatService.format(value, formatType);
    const truncatedValue = truncateAt ? truncate(formattedValue, {
      length: truncateAt,
      separator: " ",
    }) : formattedValue;
    valueDisplay = escapeHtml(truncatedValue);
    // only show title, if it is different from the truncated value
    // or has line breaks, as the text of the change does not show them (alternatively add .replaceAll("\n", "<br>") to valueDisplay)
    title = formattedValue !== truncatedValue || formattedValue.includes("\n") ? escapeHtml(formattedValue) : "";
    hasValueDisplay = valueDisplay.length > 0;
  }
  return {valueDisplay, title, hasValueDisplay, isImage};
};
