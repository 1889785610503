/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ClientFilterSelection} from "../../components";
import {ICoreApplicationState} from "../register";
import {DEFAULT_VARIANT, filterActions, IFilterState} from "./reducer";
import {NOT_FOUND, SharedFilterState} from "./SharedFilterState";
import {EDITING, INITIAL, READY, SAVING} from "./SharedFilterStateName";

/**
 * @param filterState the filter state to evaluate
 * @return A ClientFilterSelection depending on the current stateName.
 *         If the name is EDITING, the edited filter selection is returned etc.
 */
function getFilterSelection(filterState: IFilterState): ClientFilterSelection | undefined {
  switch (filterState.stateName) {
    case EDITING:
      return filterState.editedFilterSelection;
    case SAVING:
      return filterState.editedFilterSelection;
    case INITIAL:
      return undefined;
    case READY:
      return filterState.sharedFilterSelection;
    default:
      return undefined;
  }
}

/**
 * Hook to select the filterSelection from the ICoreApplicationState.
 *
 * @param entityType
 * @param variant
 * @param defaultFilterSelection
 */
export const useSharedFilterState = (entityType?: string,
                                     variant?: string,
                                     defaultFilterSelection?: ClientFilterSelection): SharedFilterState | undefined => {
  const dispatch = useDispatch();
  const effectiveVariant = variant ?? DEFAULT_VARIANT;

  const sharedFilterState = useSelector((applicationState: ICoreApplicationState) => {
    if (entityType) {
      const typeSpecificFilterState = applicationState.filterStates[entityType];
      if (!typeSpecificFilterState) {
        return NOT_FOUND;
      }
      if (!typeSpecificFilterState[effectiveVariant]) {
        return NOT_FOUND;
      }

      const variantState = typeSpecificFilterState[effectiveVariant];
      return {
        filterSelection: getFilterSelection(variantState),
        stateName: variantState.stateName,
        entityType: entityType,
        variant: effectiveVariant,

      };
    }
    return undefined;
  });

  if (entityType && sharedFilterState === NOT_FOUND && defaultFilterSelection) {
    console.log("Initializing filter for", entityType, "/", effectiveVariant, "with", defaultFilterSelection);
    dispatch(filterActions.initSharedFilterSelection({
      entityType,
      variant: effectiveVariant,
      clientFilterSelection: defaultFilterSelection,
    }));
  }
  return sharedFilterState !== NOT_FOUND ? sharedFilterState : undefined;
};

/**
 * Update the shared filter selection to the given value.
 */
export const useSetSharedFilterSelection = () => {
  const dispatch = useDispatch();
  return useCallback((entityType: string, variant: string, clientFilterSelection?: ClientFilterSelection) => {
    dispatch(filterActions.setSharedFilterSelection({entityType, variant, clientFilterSelection}));
  }, [dispatch]);
};

export const useStartEditSharedFilterSelection = () => {
  const dispatch = useDispatch();
  return useCallback((entityType: string, variant: string, clientFilterSelection?: ClientFilterSelection) => {
    dispatch(filterActions.startEditing({entityType, variant, clientFilterSelection}));
  }, [dispatch]);
};

export const useSaveSharedFilterSelection = () => {
  const dispatch = useDispatch();
  return useCallback((entityType: string, variant: string) => {
    dispatch(filterActions.save({entityType, variant}));
  }, [dispatch]);
};

export const useCancelEditSharedFilterSelection = () => {
  const dispatch = useDispatch();
  return useCallback((entityType: string, variant: string) => {
    dispatch(filterActions.cancelEditing({entityType, variant}));
  }, [dispatch]);
};
