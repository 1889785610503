/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/** Constant we refer to when registering the history view as component. */
export const ENTITY_FORM_COMPONENT = "ENTITY_FORM_COMPONENT";
export const ENTITY_HISTORY = "ENTITY_HISTORY_VIEW_COMPONENT";
export const ENTITY_JOURNAL_WIDGET = "ENTITY_JOURNAL";
export const ENTITY_LIST = "ENTITY_LIST_COMPONENT";
export const ENTITY_LIST_WIDGET = "ENTITY_LIST";
export const ENTITY_WORKFLOW_ACTIVE_TASKS_WIDGET = "ENTITY_WORKFLOW_ACTIVE_TASKS";
export const WEATHER_BUTTON = "WEATHER_BUTTON";
