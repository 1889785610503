/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isEqual} from "lodash-es";

import {ExpressionEvaluationService} from "../service";

export type DaoOptions = {
  // binding to the `value` that will be saved
  valueBinding: string
  // expression used for displaying the object
  displayBinding: string
  // binding to the version (if undefined, getVersion will return undefined)
  versionBinding?: string
}

export type ResolvedObject<T> = {
  originalObject: T
  value: unknown
  valueDisplay: string
  version?: string
}

/**
 * Offers basic data access methods
 */
export abstract class Dao<T>  {
  private readonly baseOptions: DaoOptions;

  protected constructor(options: DaoOptions) {
    this.baseOptions = options;
    this.getValue = this.getValue.bind(this);
    this.getDisplay = this.getDisplay.bind(this);
    this.getVersion = this.getVersion.bind(this);
    this.getResolved = this.getResolved.bind(this);
    this.equal = this.equal.bind(this);
  }

  /** Returns the object's value as per the options' `valueBinding`. */
  getValue(object: T): unknown {
    return ExpressionEvaluationService.get(object, this.baseOptions.valueBinding) as unknown;
  }

  /** Returns the object's display as per the options' `displayBinding`. */
  getDisplay(object: T): string {
    return ExpressionEvaluationService.evaluate(this.baseOptions.displayBinding, object).toString() as string;
  }

  /** Returns the object's version as per the options' `versionBinding`. */
  getVersion(object: T): string | undefined {
    return this.baseOptions.versionBinding ? ExpressionEvaluationService.get(object, this.baseOptions.versionBinding) as string : undefined;
  }

  /**
   * Resolves the object using the `getValue|Display|Version` methods
   */
  getResolved(object: T): ResolvedObject<T> {
    return {
      value: this.getValue(object),
      valueDisplay: this.getDisplay(object),
      version: this.getVersion(object),
      originalObject: object,
    };
  }

  /**
   * @return `true` if the objects' values are deep-equal (see `valueEqual`)
   */
  equal(object1?: T, object2?: T) {
    return object1 && object2 && this.valueEqual(this.getValue(object1), this.getValue(object2));
  }

  /**
   * @return `true` if the values are deep-equal
   */
  valueEqual(value1?: unknown, value2?: unknown) {
    return valueEqual(value1, value2);
  }

  /**
   * loads possibleValues using the given values (source depending on DAO implementation)
   */
  abstract loadPossibleValuesByValues(values: unknown[], abortController?: AbortController): Promise<T[]>

  /**
   * loads possibleValues using the given search string (source depending on DAO implementation)
   */
  abstract loadPossibleValues(search?: string, abortController?: AbortController): Promise<T[]>
}
/**
 * @return `true` if the values are deep-equal
 */
export function valueEqual(value1?: unknown, value2?: unknown) {
  return isEqual(value1, value2);
}
