/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../theme/IcmMuiTheme";

const styles = (theme: IcmMuiTheme) => createStyles({
  // use the same shadows as in default button
  adaptFab: {
    boxShadow: theme.shadows[2],
    "&:active": {
      boxShadow: theme.shadows[8],
    },
  },
});

export default styles;
