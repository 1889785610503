/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {StorageService, StorageStrategy} from "@icm/core-common";
import Cookies from "js-cookie";

export class WebStorageService  implements StorageService {
  private readonly prefix: string;

  constructor(prefix: string = "") {
    this.prefix = prefix;
  }

  private prefixName(name: string) {
    return this.prefix + name;
  }

  public loadData<T>(_name: string): Promise<T | null> {
    const name = this.prefixName(_name);
    if (typeof (sessionStorage) !== "undefined" && sessionStorage.getItem(name) !== null) {
      return Promise.resolve(sessionStorage.getItem(name) ? JSON.parse(sessionStorage.getItem(name) || "{}") : null);
    } else if (typeof (localStorage) !== "undefined" && localStorage.getItem(name) !== null) {
      return Promise.resolve(localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name) || "{}") : null);
    } else if (Cookies.get(name) !== undefined) {
      return Promise.resolve(JSON.parse(Cookies.get(name) || "{}"));
    }  else {
      return Promise.resolve(null);
    }
  }

  public saveData(_name: string, data: any, strategy: StorageStrategy): void {
    const name = this.prefixName(_name);
    const stringifiedData = JSON.stringify(data);
    if (strategy.type === "SESSION" && typeof (sessionStorage) !== "undefined") {
      sessionStorage.setItem(name, stringifiedData);
    } else if (strategy.type === "LOCAL" && typeof (localStorage) !== "undefined") {
      localStorage.setItem(name, stringifiedData);
    } else if (strategy.expiresInDays !== undefined) {
      Cookies.set(name, stringifiedData, {expires: strategy.expiresInDays, sameSite: "Strict"});
    } else {
      Cookies.set(name, stringifiedData, {sameSite: "Strict"});
    }
  }

  public deleteData(_name: string): void {
    const name = this.prefixName(_name);
    if (typeof (sessionStorage) !== "undefined") {
      sessionStorage.removeItem(name);
    }
    if (typeof (localStorage) !== "undefined") {
      localStorage.removeItem(name);
    }
    Cookies.remove(name);
  }
}
