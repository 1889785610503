/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {GetMessageProps} from "../store";
import {StoreService} from "../store/store";
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";


export type MessageService = {
  has(code: string): boolean
  get(code: string, props?: GetMessageProps): string
  isReady(): boolean
}

class MessageServiceImpl implements MessageService {
  public constructor() {
    console.info("Registering message helper");
    this.registerHelper();
  }

  private registerHelper() {
    ExpressionEvaluationService.registerHelper("msg", "get", (code: string, props?: GetMessageProps) => {
      return this.get(code, props);
    });
  }

  public has(code: string): boolean {
    return !!StoreService.store.getState().messageState?.messages[code];
  }

  /**
   * @see use `useMessage` hook for components
   */
  public get(code: string, props?: GetMessageProps): string {
    // NOTE: keep implementation in sync with useMessage hook
    const message = StoreService.store.getState().messageState?.messages[code];
    if (message) {
      if (props?.params) {
        return ExpressionEvaluationService.evaluate(message, props.params);
      } else {
        return message;
      }
    } else if (props?.fallbackMessageCode) {
      return this.get(props.fallbackMessageCode, {defaultMessage: props.defaultMessage, params: props.params});
    } else if (props?.defaultMessage) {
      return props.defaultMessage;
    } else {
      console.warn("No message found for:", code);
      return "?" + code + "?";
    }
  }

  public isReady(): boolean {
    return !!StoreService.store?.getState().messageState?.ready;
  }
}

const messages = new MessageServiceImpl();

export {messages};
