/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ApiUtil, createRefreshActionId, dateService, FetchService, MessageKey, messages, SortService} from "@icm/core-common";
import {LoadingSpinner} from "@icm/core-web";
import {Load, REM_TRAIN_INFORMATION, TrainInformation, TrainInformationProps, Wagon} from "@icm/rem-rail-common";
import {ExpandMore, Warning} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {withStyles, WithStyles} from "@mui/styles";
import clsx from "clsx";
import * as React from "react";


import styles from "./TrainInformationViewStyle";

interface IState {
  trainInformationList?: TrainInformation[]
}

type AllProps = TrainInformationProps & WithStyles<typeof styles>;


class TrainInformationView extends React.Component<AllProps, IState> {

  private trainInformation: Array<{
    label?: string
    value: (trainInfo: TrainInformation) => any
  }>;

  private trainSums: Array<{
    short?: string
    long?: string
    span?: number
    value: (trainInfo: TrainInformation) => any
  }>;

  private wagonInfo: Array<{
    short?: string
    long?: string
    value: (wagon: Wagon, loads: Load[]) => any
    dangerousGoodValue?: (wagon: Wagon, loads: Load[]) => any
    cssClass?: string
  }>;

  constructor(props: Readonly<AllProps>) {
    super(props);
    this.state = {};
    this.loadData = this.loadData.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.loadData();
  }

  private loadData() {
    const trainInfoUrl = `/api/icm/backend/trainInformation/${this.props.incidentId}`;
    // load train information data
    FetchService.performGet(trainInfoUrl).then(entity => {
      this.setState({
        trainInformationList: TrainInformationView.sortTrainInformation(ApiUtil.convertArray(entity, TrainInformation.fromData)),
      });
    });
  }

  /**
   * sorts trans by registration date and the train's wagons by their position
   * ATTENTION: modifies the given array and its content
   */
  private static sortTrainInformation(trainInfos: TrainInformation[]): TrainInformation[] {
    trainInfos.sort(SortService.createComparator(ti => ti.registrationDate));
    trainInfos.forEach(trainInfo => {
      if (trainInfo.wagons) {
        trainInfo.wagons.sort(SortService.createComparator(w => w.wagonPosition));
      }
    });
    return trainInfos;
  }

  private reloadData() {
    this.setState({trainInformationList: undefined});
    this.loadData();
  }

  componentDidMount(): void {
    const key = createRefreshActionId(REM_TRAIN_INFORMATION);
    this.props.setViewActionHandlers({
      [key]: {run: this.reloadData},
    });
  }

  componentWillUnmount(): void {
    const key = createRefreshActionId(REM_TRAIN_INFORMATION);
    this.props.setViewActionHandlers({
      [key]: {run: () => {}},
    });
  }

  public render(): React.ReactNode {
    const msg = messages.get;

    this.trainInformation = [
      {label: msg(MessageKey.RAIL.TRAIN.EXISTING_BREAKING_PERCENTAGE._), value: ti => ti.existingBrakingPercentageTrain},
      {label: msg(MessageKey.RAIL.TRAIN.TRACTION_VEHICLE), value: ti => ti.tractionVehicle},
      {label: msg(MessageKey.RAIL.TRAIN.TOTAL_LENGTH), value: ti => ti.length},
      {label: msg(MessageKey.RAIL.TRAIN.LAST_VEHICLE), value: ti => ti.lastVehicle},
      {label: msg(MessageKey.RAIL.TRAIN.TOTAL_WEIGHT), value: ti => this.formatFloat(ti.totalWeight)},
      {label: msg(MessageKey.RAIL.TRAIN.OPERATOR_DESCRIPTION), value: ti => ti.operatorDescription},
      {label: msg(MessageKey.RAIL.TRAIN.MINIMUM_TOP_SPEED), value: ti => ti.minimumTopSpeed},
      {
        label: msg(MessageKey.RAIL.TRAIN.EXTRAORDINARY_DELIVERY),
        value: ti => ti.extraordinaryDelivery ? msg("core.yes") : msg("core.no"),
      },
    ];

    this.trainSums = [
      {
        short: msg(MessageKey.RAIL.TRAIN.WAGONS),
        span: 3,
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.LOADED.SHORT) + ": " + ti.wagons!.filter(w => w.loaded).length,
      },
      {
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.UNLOADED.SHORT) + ": " + ti.wagons!.filter(w => !w.loaded).length,
      },
      {
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.ALL.SHORT) + ": " + ti.wagons!.length,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.AXLES._),
        span: 2,
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.LOADED.SHORT) + ": " + ti.wagons!.map(w => w.loaded ? w.numberOfAxles : 0).reduce((pv: number, cv: number) => pv + cv, 0),
      },
      {
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.UNLOADED.SHORT) + ": " + ti.wagons!.map(w => w.loaded ? 0 : w.numberOfAxles).reduce((pv: number, cv: number) => pv + cv, 0),
      },
      {
        short: msg(MessageKey.RAIL.WAGON.CURB_WEIGHT.SHORT),
        long: msg(MessageKey.RAIL.WAGON.CURB_WEIGHT._),
        value: ti => this.formatFloat((ti.totalWeight || 0) - (ti.totalWeightOfLoad || 0)),
      },
      {
        short: msg(MessageKey.RAIL.WAGON.WEIGHT_OF_CARGO.SHORT),
        long: msg(MessageKey.RAIL.WAGON.WEIGHT_OF_CARGO._),
        value: ti => this.formatFloat(ti.totalWeightOfLoad),
      },
      {
        short: msg(MessageKey.RAIL.WAGON.TOTAL_WEIGHT_OF_WAGON.SHORT),
        long: msg(MessageKey.RAIL.WAGON.TOTAL_WEIGHT_OF_WAGON._),
        value: ti => this.formatFloat(ti.totalWeight),
      },
      {
        short: msg(MessageKey.RAIL.TRAIN.BRAKE_WEIGHTS.SHORT),
        long: msg(MessageKey.RAIL.TRAIN.BRAKE_WEIGHTS._),
        value: ti => ti.trainBrakesWeight,
      },
      {
        short: msg(MessageKey.RAIL.TRAIN.HANDBRAKE),
        span: 2,
        value: ti => msg(MessageKey.RAIL.WAGON.BRAKE_WEIGHT.SHORT) + ": " + ti.bgHandbrakeLoad,
      },
      {
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.WAGON.SHORT) + ": " + ti.wgHandbrakeLoad,
      },
      {
        short: msg(MessageKey.RAIL.TRAIN.EXISTING_BREAKING_PERCENTAGE.SHORT),
        long: msg(MessageKey.RAIL.TRAIN.EXISTING_BREAKING_PERCENTAGE._),
        span: 2,
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.WAGON.SHORT) + ": " + ti.existingBrakingPercentage,
      },
      {
        value: ti => msg(MessageKey.RAIL.TRAIN.SUMS.TRAIN.SHORT) + ": " + ti.existingBrakingPercentageTrain,
      },
    ];

    this.wagonInfo = [
      {
        short: msg(MessageKey.RAIL.WAGON.WAGON_POSITION.SHORT),
        long: msg(MessageKey.RAIL.WAGON.WAGON_POSITION._),
        value: w => w.wagonPosition,
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.WAGON_NUMBER),
        value: w => w.wagonNumber,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.AXLES.SHORT),
        long: msg(MessageKey.RAIL.WAGON.AXLES._),
        value: w => w.numberOfAxles,
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.LOADED.SHORT),
        long: msg(MessageKey.RAIL.WAGON.LOADED._),
        value: w => w.loaded ? msg(MessageKey.CORE.YES) : msg(MessageKey.CORE.NO),
      },
      {
        short: msg(MessageKey.RAIL.WAGON.LENGTH_OVER_BUFFER.SHORT),
        long: msg(MessageKey.RAIL.WAGON.LENGTH_OVER_BUFFER._),
        value: w => w.lengthOverBuffer,
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.CURB_WEIGHT.SHORT),
        long: msg(MessageKey.RAIL.WAGON.CURB_WEIGHT._),
        value: w => this.formatFloat((w.totalWeightOfWagon || 0) - (w.weightOfLoad || 0)),
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.WEIGHT_OF_CARGO.SHORT),
        long: msg(MessageKey.RAIL.WAGON.WEIGHT_OF_CARGO._),
        value: w => this.formatFloat(w.weightOfLoad),
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.TOTAL_WEIGHT_OF_WAGON.SHORT),
        long: msg(MessageKey.RAIL.WAGON.TOTAL_WEIGHT_OF_WAGON._),
        value: w => this.formatFloat(w.totalWeightOfWagon),
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.BRAKE_POSITION.SHORT),
        long: msg(MessageKey.RAIL.WAGON.BRAKE_POSITION._),
        value: w => w.brakePosition,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.BRAKE_WEIGHT.SHORT),
        long: msg(MessageKey.RAIL.WAGON.BRAKE_WEIGHT._),
        value: w => w.brakeWeight,
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.HANDBRAKE_WEIGHT.SHORT),
        long: msg(MessageKey.RAIL.WAGON.HANDBRAKE_WEIGHT._),
        value: w => w.handbrakeWeight,
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.LOAD_INFO),
        value: (w, loads) => this.renderLoadInfo(loads),
        cssClass: this.props.classes.loadInfoCell,
      },

      {
        short: msg(MessageKey.RAIL.WAGON.FORWARDING_LOCATION_NAME.SHORT),
        long: msg(MessageKey.RAIL.WAGON.FORWARDING_LOCATION_NAME._),
        value: w => w.forwardingLocationName,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.RECEIVING_LOCATION_NAME.SHORT),
        long: msg(MessageKey.RAIL.WAGON.RECEIVING_LOCATION_NAME._),
        value: w => w.receivingLocationName,
      },
      {short: msg(MessageKey.RAIL.WAGON.CONSIGNEE), value: w => w.consignee},
      {
        short: msg(MessageKey.RAIL.WAGON.TOP_SPEED.SHORT),
        long: msg(MessageKey.RAIL.WAGON.TOP_SPEED._),
        value: w => w.topSpeed,
        cssClass: this.props.classes.numberCell,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.TRANSPORT_TYPE.SHORT),
        long: msg(MessageKey.RAIL.WAGON.TRANSPORT_TYPE._),
        value: w => w.transportType,
      },
      {
        short: msg(MessageKey.RAIL.WAGON.NUMBER_OF_TRANSPORT_SCHEDULE.SHORT),
        long: msg(MessageKey.RAIL.WAGON.NUMBER_OF_TRANSPORT_SCHEDULE._),
        value: w => w.numberOfTransportSchedule,
      },
    ];

    return (
      <>
        {this.state.trainInformationList
          ? (this.state.trainInformationList.length > 0
            ? this.renderTrainList(this.state.trainInformationList)
            : (
              <Typography variant="body1" className={this.props.classes.noEntries}>
                {messages.get("core.form.list.noEntries")}
              </Typography>
            )
          )
          : <LoadingSpinner />}
      </>
    );
  }


  private formatFloat(n?: number): number | undefined {
    return n ? +n.toFixed(2) : n;
  }

  private renderTrainList(trainInformationList: TrainInformation[]): React.ReactNode {
    return (
      <>
        {trainInformationList.map((trainInfo: TrainInformation, key) => (
          <Accordion key={key}>
            <AccordionSummary expandIcon={<ExpandMore />} className={this.props.classes.trainPanelSummary}>
              <Typography variant="subtitle1" display="inline">
                {messages.get(MessageKey.RAIL.TRAIN.TITLE, {
                  params: {
                    ...trainInfo,
                    registrationDate: dateService.formatDate(trainInfo.registrationDate!),
                    registrationTime: dateService.formatTime(trainInfo.registrationDate!),
                  },
                })}
              </Typography>
              {trainInfo.wagons!.filter(wagon => wagon.loads!.find(load => !!load.dangerousGoodNumber) !== undefined).length > 0 && (
              <Typography variant="subtitle1"
                          display="inline"
                          className={this.props.classes.trainPanelRidWarning}
              >
                <Warning fontSize="inherit" />&nbsp;{messages.get(MessageKey.RAIL.TRAIN.RID_LOAD_PRESENT)}
              </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container={true} spacing={2}>
                <Grid item={true} style={{width: "100%"}} xs={12}>
                  <Paper square={true}>
                    {trainInfo.wagons && this.renderWagonList(trainInfo.wagons)}
                  </Paper>
                </Grid>
                <Grid item={true} container={true} xs={12} lg={6}>
                  {this.renderGeneralTrainInfo(trainInfo)}
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  {this.renderTrainSums(trainInfo)}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  }

  private renderGeneralTrainInfo(trainInfo: TrainInformation) {
    return (
      <Paper square={true} className={this.props.classes.infoPaper}>
        <Typography variant="h6">
          {messages.get(MessageKey.RAIL.TRAIN.GENERAL_INFORMATION)}
        </Typography>
        <Grid container={true} spacing={1}>
          {this.trainInformation.map((label, subkey) => (
            <React.Fragment key={subkey}>
              <Grid item={true} xs={4}>
                <Typography className={this.props.classes.headerLabel}>{label.label}</Typography>
              </Grid>
              <Grid item={true} xs={2}>
                <Typography className={this.props.classes.trainGeneralValue}>{label.value(trainInfo)}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
    );
  }

  private renderTrainSums(trainInfo: TrainInformation) {
    const {classes} = this.props;
    return (
      <Paper square={true} className={classes.infoPaper}>
        <Typography variant="h6">
          {messages.get(MessageKey.RAIL.TRAIN.SUMS._)}
        </Typography>
        <Table>
          <TableHead>
            <TableRow className={classes.compactRow}>
              {this.trainSums.filter(s => !!s.short).map((s, subkey) => (
                <TableCell key={subkey}
                           colSpan={s.span || 1}
                           className={classes.trainSumCell}
                >
                  {s.long
                    ? (
                      <Tooltip title={s.long} placement="top">
                        <Typography className={classes.headerLabel}>{s.short}</Typography>
                      </Tooltip>
                    )
                    :                                    <Typography className={classes.headerLabel}>{s.short}</Typography>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.compactRow}>
              {this.trainSums.map(s => s.value(trainInfo)).map((val, subkey) => (
                <TableCell key={subkey}
                           className={classes.trainSumCell}
                >
                  {val}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  private renderWagonList(wagons: Wagon[]): React.ReactNode {
    const {classes} = this.props;
    return (
      <div className={classes.wagonListContainer}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              {this.wagonInfo.map((wi, key) => (
                <TableCell key={key} className={clsx(classes.wagonCell, classes.headerCell, wi.cssClass)}>
                  {wi.long
                    ? (
                      <Tooltip title={wi.long} placement="top">
                        <Typography className={classes.headerLabel}>{wi.short}</Typography>
                      </Tooltip>
                    )
                    : <Typography className={classes.headerLabel}>{wi.short}</Typography>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {wagons.map((wagon: Wagon, key: number) => (
              <TableRow key={key} className={classes.compactRow}>
                {this.wagonInfo.map((wi, subkey) => (
                  <TableCell key={subkey} className={classes.wagonCell + " " + wi.cssClass}>
                    {wi.value(wagon, wagon.loads || [])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  private renderLoadInfo(loads: Load[]) {
    return (
      <List disablePadding={true} className={this.props.classes.loadList}>
        {loads.map((load: Load, subkey) => {
          const cargoName = (load.nhmCargoCode ? messages.get(MessageKey.RAIL.LOAD.NHM_CARGO_CODE, {params: {nhmCargoCode: load.nhmCargoCode}}) + " " : "") + (load.cargoName ? load.cargoName : "");
          return (
            <ListItem key={subkey} disableGutters={true} className={this.props.classes.loadListItem}>
              <ListItemText disableTypography={true} className={this.props.classes.loadListItemText}>
                {(load.dangerousGoodNumber || load.unMaterialNumber) ? (
                  <Grid container={true} wrap="nowrap">
                    <Grid item={true} xs={3} className={this.props.classes.dangerBoard}>
                      <div>
                        <Typography variant="h6" component="p">{load.dangerousGoodNumber}</Typography>
                      </div>
                      <div>
                        <Typography variant="h6" component="p">{load.unMaterialNumber}</Typography>
                      </div>
                    </Grid>
                    <Grid item={true}
                          xs={9}
                          container={true}
                          className={this.props.classes.dangerousGoodInfo}
                    >
                      <Tooltip title={cargoName} placement="top">
                        <Typography variant="body2" className={this.props.classes.loadInfo}>
                          {cargoName}
                        </Typography>
                      </Tooltip>
                      <Grid item={true}>
                        <Typography variant="caption">
                          {messages.get(MessageKey.RAIL.LOAD.RID_CLASSIFICATION)}:&nbsp;{load.ridClassification}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Tooltip title={cargoName} placement="top">
                    <Typography className={this.props.classes.loadInfo} variant="body2">
                      {cargoName}
                    </Typography>
                  </Tooltip>
                )}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    );
  }
}


const StyledTrainInformationComponent = withStyles(styles)(TrainInformationView);
export {StyledTrainInformationComponent as TrainInformationComponent};

export const trainInformationComponentFactory = (props: TrainInformationProps) => <StyledTrainInformationComponent {...props} />;
