/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, ViewDescriptor} from "@icm/core-common";
import * as React from "react";

export type MobileConfigurationViewModel = {
  printRequested: boolean,
}

export const mobileConfigurationViewDescriptor: ViewDescriptor<MobileConfigurationViewModel> = {
  viewType: "MOBILE_CONFIGURATION_VIEW",
  view: React.lazy(() => import("./MobileConfigurationView")),
  initializeViewModelData: viewParameters => ({
    printRequested: false,
  }),
  getTitle: (_viewModel, getMessage) => getMessage(MessageKey.CORE.MOBILE_APP_CONFIGURATION.TITLE),
  getViewActionDescriptors: (_viewModel, _getMessage) => ({}),
};
