/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi, FormWidgetProps, useFormGeneratorContext} from "@icm/core-common";
import {TaskInstance} from "@icm/workflow-common";
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";

import styles from "./WebEntityWorkflowActiveTasksWidgetStyle";

const useStyles = makeStyles(styles);

export const WebEntityWorkflowActiveTasksWidget = (props: FormWidgetProps) => {
  const widget = props.widget as CoreApi.EntityWorkflowActiveTasks;
  const {entity} = useFormGeneratorContext();
  const classes = useStyles();

  const activeTasks: TaskInstance[] = entity.metadata["ACTIVE_TASKS"] || [];

  return (
    <div>
      <Typography variant="body2" className={classes.header}>{widget.label}</Typography>
      <span>
        {activeTasks.map(task => (
          <div className={classes.taskNameBox} key={task.id}>
            <span>{task.taskDefinition?.name}</span>
          </div>
        ))}
      </span>
    </div>
  );
};
