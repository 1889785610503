/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import React, {PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {useQueryClient} from "react-query";
import * as Redux from "react-redux";

import {SecurityConfigurationApi} from "../../api";
import {SecurityConfiguration} from "../../generated/api";
import {StoreService} from "../../store";
import {ActionProvider, ViewDescriptorRegistryProvider} from "../../ui";
import {resolveDelayed} from "../../util/DelayedPromise";
import {useNavigationService} from "../NavigationService";
import {PropertyService} from "../PropertyService";
import {StartSecurityOptions} from "../security";
import {StorageService} from "../StorageService";
import {CoreServiceSetup} from "./CoreServiceSetup";
import {DefaultIcmServiceRegistry} from "./DefaultIcmServiceRegistry";
import {IcmServiceRegistry} from "./IcmServiceRegistry";
import {IcmServiceKey, IcmServices} from "./IcmServices";
import {ModuleRegistrationOptions, registerModules} from "./ModuleRegistrationService";

export const CoreServiceRegistry: IcmServiceRegistry = new DefaultIcmServiceRegistry();

const ServiceContext = React.createContext<IcmServiceRegistry>(CoreServiceRegistry);

type IcmServiceProviderProps = PropsWithChildren<{
  contextPath: string
  serviceRegistry: IcmServiceRegistry;
  moduleRegistrationOptions: ModuleRegistrationOptions[]
  securityConfigurationRetriever?: () => Promise<SecurityConfiguration>
  storageService: StorageService,
  skipInitializationOfCoreServices?: boolean,
  keycloakAdapter: StartSecurityOptions["adapter"]
}>

/**
 * Default delay to use when switching from FAILED state to a retry state.
 * Rationale: Some services may fail when initializing. For example, the
 * keycloak backend might not be responsive. In this case, we wait a
 * little before we retry.
 */
const FAILED_STATE_RECOVERY_DELAY = 3000;

type ProviderState = "INITIAL"
| "CREATE_CORE_SERVICES"
| "CREATE_CORE_SERVICES_SUCCESS"
| "CREATE_CORE_SERVICES_FAILED"
| "REGISTER_MODULES"
| "REGISTER_MODULES_SUCCESS"
| "REGISTER_MODULES_FAILED"
| "INIT_SUCCESS";

export const IcmServiceProvider = (props: IcmServiceProviderProps) => {
  const {
    moduleRegistrationOptions,
    contextPath,
    securityConfigurationRetriever = SecurityConfigurationApi.get,
    children,
    keycloakAdapter,
    storageService,
    skipInitializationOfCoreServices = false,
    serviceRegistry,
  } = props;

  const [providerState, setProviderState] = useState<ProviderState>("INITIAL");

  const navigationService = useNavigationService(contextPath);

  const queryClient = useQueryClient();

  const propertyService = useMemo(() => new PropertyService(queryClient), [queryClient]);

  const enterState = (state: ProviderState) => {
    console.log(`Enter State ${state}`);
    setProviderState(state);
  };

  useEffect(() => {
    const tryCreateCoreServices = async (): Promise<ProviderState> => {
      try {
        await new CoreServiceSetup(serviceRegistry)
          .withKeycloakAdapter(keycloakAdapter)
          .withNavigationService(navigationService)
          .withStorageService(storageService)
          .withPropertyServiceService(propertyService)
          .withSecurityConfigurationProvider(securityConfigurationRetriever)
          .skipInitializationStep(skipInitializationOfCoreServices)
          .run();
        return "CREATE_CORE_SERVICES_SUCCESS";
      } catch (e) {
        return "CREATE_CORE_SERVICES_FAILED";
      }
    };

    const tryPerformModuleRegistration = async (): Promise<ProviderState> => {
      try {
        registerModules(serviceRegistry, moduleRegistrationOptions);
        StoreService.createStore();
        return Promise.resolve("REGISTER_MODULES_SUCCESS");
      } catch (e) {
        console.error("Register modules failed", e);
        return Promise.resolve("REGISTER_MODULES_FAILED");
      }
    };

    // following const describes the state machine of this service provider.
    const getNextState = async (state: ProviderState): Promise<ProviderState> => {
      switch (state) {
        case "INITIAL":
          return Promise.resolve("CREATE_CORE_SERVICES");
        case "CREATE_CORE_SERVICES":
          return tryCreateCoreServices();
        case "CREATE_CORE_SERVICES_SUCCESS":
          return Promise.resolve("REGISTER_MODULES");
        case "REGISTER_MODULES":
          return tryPerformModuleRegistration();
        case "REGISTER_MODULES_FAILED":
          console.log("REGISTER_MODULES_FAILED, Trying to register again");
          return resolveDelayed("REGISTER_MODULES", FAILED_STATE_RECOVERY_DELAY);
        case "REGISTER_MODULES_SUCCESS":
          return Promise.resolve("INIT_SUCCESS");
        case "CREATE_CORE_SERVICES_FAILED":
          console.log("CREATE_CORE_SERVICES_FAILED, Trying to initialize");
          return resolveDelayed("INITIAL", FAILED_STATE_RECOVERY_DELAY);
        case "INIT_SUCCESS":
          return Promise.resolve("INIT_SUCCESS");
        default:
          return Promise.reject(`Unknown or unhandled ProviderState ${state}`);
      }
    };

    // update the service state
    getNextState(providerState)
      .then(enterState)
      .catch(e => console.error(e));
  }, [
    keycloakAdapter,
    moduleRegistrationOptions,
    navigationService,
    propertyService,
    providerState,
    securityConfigurationRetriever,
    serviceRegistry,
    skipInitializationOfCoreServices,
    storageService,
  ]);

  if (providerState === "INIT_SUCCESS") {
    return (
      <ServiceContext.Provider value={serviceRegistry}>
        <ViewDescriptorRegistryProvider availableViewDescriptors={moduleRegistrationOptions.flatMap(opt => opt.providedViews ?? [])}>
          <ActionProvider actionHandlers={moduleRegistrationOptions.flatMap(opt => opt.providedActions ?? {})}>
            {StoreService.store && (
              <Redux.Provider store={StoreService.store}>
                {children}
              </Redux.Provider>
            )}
          </ActionProvider>
        </ViewDescriptorRegistryProvider>
      </ServiceContext.Provider>
    );
  } else {
    console.debug("IcmServiceProvider not ready yet", providerState);
    return null;
  }
};

export const useService = <T extends IcmServiceKey>(id: T): IcmServices[T] => {
  const service = useContext(ServiceContext).get(id);
  return useMemo(() => service, [service]);
};
