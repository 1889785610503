/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";

import {FeedbackContextType, GetMessage, MessageKey} from "../../index";
import {ApiUtil, CoreApi} from "../api";
import {useUiConfiguration} from "../configuration";
import {useFeedbackBanners, useService} from "../service";
import {changeSingleView, findExistingViewModel, ICoreApplicationState, openTab, useMessages} from "../store";
import {ParameterUtilities, ResolvedParameterList} from "../util";
import {createViewModel, updateViewModelDataByViewParameters} from "./ViewModel";
import {ViewDescriptorRegistryContext} from "./ViewRegistry";

export const useAvailableViewDescriptors = () => {
  return useContext(ViewDescriptorRegistryContext);
};

/**
 * An effect hook that will open the view passed using the URL.
 */
export const useOpenViewFromUrl = () => {
  const location = useLocation();
  const openView = useOpenView();
  const uiConfiguration = useUiConfiguration();
  const history = useNavigate();
  const perspectiveDefinition = useSelector(({uiState}: ICoreApplicationState) => uiState.perspective);
  const securityService = useService("SECURITY");

  useEffect(() => {
    if (securityService.isLoggedIn()) {
      const viewId = location.pathname;
      if (perspectiveDefinition && uiConfiguration.isSuccess && viewId.length > 1) {
        const urlParamString = location.search;
        const params = ApiUtil.convertGetParamsToObject(urlParamString?.substring(1));
        try {
          openView(viewId.substring(1), {genericParameters: params});
        } catch {
          console.warn("navigation to ", viewId, "failed with parameters", params);
        }
        history("", {replace: true});
      }
    }
  }, [location, openView, uiConfiguration.isSuccess, history, perspectiveDefinition, securityService]);
};

/**
 * Returns a method to open a new view OR update an existing view within the perspective.
 * In case of the tabbed perspective this means a new tab will be created OR the contents of an existing
 * tab will be updated. This will also cause the perspective to update its state (make a new tab the "current"
 * active tab).
 */
export const useOpenView = () => {
  const dispatch = useDispatch();

  const perspective = useSelector(({uiState}: ICoreApplicationState) => uiState.perspective);

  const uiConfiguration = useUiConfiguration();
  const availableViewDescriptors = useAvailableViewDescriptors();
  const securityService = useService("SECURITY");

  const {getMessage} = useMessages();
  const feedbackBanners = useFeedbackBanners();

  return useCallback((viewId: string, viewParameters: ResolvedParameterList) => {
    const viewDef: CoreApi.View | undefined = uiConfiguration.data?.views?.find(view => view.id === viewId);
    if (viewDef?.viewType) {
      if (!securityService.evaluateVisibilityRule(viewDef.available)) {
        showAccessDenied(feedbackBanners, getMessage);
        return;
      }

      const viewDescriptor = availableViewDescriptors[viewDef.viewType];
      if (viewDescriptor) {
        const viewDefParameters = ParameterUtilities.resolveParameterList(viewDef.viewParameters);
        const combinedParameters = ParameterUtilities.combineParameterLists(viewParameters, viewDefParameters);
        const viewModel = createViewModel(viewDescriptor, viewId, combinedParameters, viewDef.label);
        if (perspective?.perspectiveType === "TABBED") {
          const existingViewModel = findExistingViewModel(perspective, viewModel);
          let updatedViewModelData = undefined;
          if (existingViewModel) {
            updatedViewModelData = updateViewModelDataByViewParameters(viewDescriptor, existingViewModel.viewModelData, combinedParameters);
          }
          dispatch(openTab({
            viewModel,
            updatedViewModelData,
          }));
        } else if (perspective?.perspectiveType === "SINGLE_VIEW") {
          // TODO PICM-1501 take care of updateViewModel... (fetch view from history and update its data?)
          const currentEditModelId = perspective.view.viewModel.editModelId;
          dispatch(changeSingleView({
            viewModel,
            editModelId: currentEditModelId,
          }));
        } else {
          console.error("Can't open view because there is no perspective", perspective);
        }
      } else {
        console.warn("Couldn't find view descriptor for", viewDef.viewType);
      }
    } else {
      console.warn("Couldn't find view with viewId", viewId);
    }
  }, [availableViewDescriptors, dispatch, uiConfiguration.data?.views, perspective, feedbackBanners, getMessage, securityService]);
};

function showAccessDenied(feedbackBanners: FeedbackContextType, getMessage: GetMessage) {
  console.log("Cannot open view. User not allowed");
  feedbackBanners.openFeedbackBanner({
    key: MessageKey.CORE.ERROR.PERMISSION_DENIED,
    duration: "SHORT",
    variant: "ERROR",
    title: getMessage(MessageKey.CORE.ERROR.PERMISSION_DENIED),
  });
}

export const useFindViewHelper = () => {
  const uiConfiguration = useUiConfiguration();

  const findViewIdByWidgetType = useCallback((widgetType: string): string | undefined => {
    return uiConfiguration.data?.views?.find(view => view.viewType === widgetType)?.id;
  }, [uiConfiguration]);

  return {
    findViewIdByWidgetType,
  };
};
