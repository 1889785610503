/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PossibleValue} from "../generated/api";
import MessageKey from "../generated/MessageKey";
import {coordinateService} from "./CoordinateService";
import {dateService} from "./DateService";
import {messages} from "./MessageService";
import {numberService} from "./NumberService";
import {Unit, unitService} from "./UnitService";

export type DataFormat = "TEXT" | "DATE" | "TIME" | "DATE_TIME" | string;

class FormatService {

  /**
   * Format the value for read-only display.
   *
   * @param value the value
   * @param format the format
   * @param as what to format for: a label (for a format option), for a text input or full (for copying/viewing)
   * @return a formatted value (or empty string)
   */
  public format(value?: any, format?: DataFormat, as?: "LABEL" | "INPUT" | "FULL"): string {
    if (format) {
      const [name, ...params] = format.split(":");
      switch (name) {
        case "DATE":
          return value ? dateService.formatDate(value) : "";
        case "DATE_TIME":
          return value ? dateService.formatDateTime(value) : "";
        case "TIME":
          return value ? dateService.formatTime(value) : "";
        case "NUMBER":
          const numberFormat = params[0]?.split(";")[0];
          return numberService.format(value, numberFormat);
        case "UNIT":
          const unit = params[0];
          const asUnit = params[1]?.split(";")[0];
          switch (as) {
            case "LABEL": return unitService.format(value || 0, unit, asUnit);
            case "INPUT": return unitService.formatNumber(value, unit, asUnit);
            case "FULL":
            default: return unitService.format(value, unit, asUnit);
          }
        case "LATITUDE":
          const latFormat = params[0]?.split(";")[0];
          return coordinateService.formatLatitude(as === "LABEL" ? value ?? 0 : value, latFormat) || "";
        case "LONGITUDE":
          const lonFormat = params[0]?.split(";")[0];
          return coordinateService.formatLongitude(as === "LABEL" ? value ?? 0 : value, lonFormat) || "";
        case "COORDINATE":
        case "POINT":
          const coordFormat = params[0]?.split(";")[0];
          return coordinateService.format(as === "LABEL" ? value ?? [0, 0] : value, coordFormat) || "";
        case "LINESTRING":
          const lsFormat = params[0]?.split(";")[0];
          switch (as) {
            case "LABEL": return coordinateService.format(value?.coordinates?.[0] ?? [0, 0], lsFormat) || "";
            default: return coordinateService.formatLineString(value, lsFormat) || "";
          }
        case "POLYGON":
          const pFormat = params[0]?.split(";")[0];
          switch (as) {
            case "LABEL": return coordinateService.format(value?.coordinates?.[0]?.[0] ?? [0, 0], pFormat) || "";
            default: return coordinateService.formatPolygon(value, pFormat) || "";
          }
        case "GEOMETRY":
          const gFormat = params[0]?.split(";")[0];
          switch (as) {
            case "LABEL": return coordinateService.formatGeometryForLabel(value, gFormat) || "";
            default: return coordinateService.formatGeometry(value, gFormat) || "";
          }
      }
    }
    if (typeof value === "string") {
      return value;
    } else if (typeof value === "boolean") {
      if (value) {
        return messages.get(MessageKey.CORE.YES) || "";
      } else {
        return messages.get(MessageKey.CORE.NO) || "";
      }
    } else if (Array.isArray(value)) {
      return value.join(", ");
    }
    return (value || "").toString();
  }

  /**
   * TODO: Refactor entire function as passing any to a formatter is considered unsafe PICM-2497 and leads to issues like PICM-2461
   *
   * Get a formatter (for a text field).
   *
   * @param format the format
   * @return a function to format a value using a detailFormat
   */
  public getFormatter(format?: DataFormat): undefined | ((value: any, detailFormat?: string, as?: "LABEL" | "INPUT" | "FULL") => string) {
    if (format) {
      const [name, ...params] = format.split(":");
      switch (name) {
        case "NUMBER":
          const numberFormat = params[0]?.split(";")[0];
          return (value, detailFormat) => numberService.format(value, detailFormat || numberFormat);
        case "UNIT":
          const unit = params[0];
          const asUnit = params[1]?.split(";")[0];
          return (value, detailFormat, as) => {
            switch (as) {
              case "LABEL": return unitService.format(value || 0, unit, detailFormat || asUnit);
              case "INPUT": return unitService.formatNumber(value, unit, detailFormat || asUnit);
              case "FULL":
              default: return unitService.format(value, unit, detailFormat || asUnit);
            }
          };
        case "LATITUDE":
          const latFormat = params[0]?.split(";")[0];
          return (value, detailFormat, as) => coordinateService.formatLatitude(as === "LABEL" ? value ?? 0 : value, detailFormat || latFormat) || "";
        case "LONGITUDE":
          const lonFormat = params[0]?.split(";")[0];
          return (value, detailFormat, as) => coordinateService.formatLongitude(as === "LABEL" ? value ?? 0 : value, detailFormat || lonFormat) || "";
        case "COORDINATE":
        case "POINT":
          const coordFormat = params[0]?.split(";")[0];
          return (value, detailFormat, as) => coordinateService.format(as === "LABEL" ? value ?? [0, 0] : value, detailFormat || coordFormat) || "";
        case "LINESTRING":
          const lsFormat = params[0]?.split(";")[0];
          return (value, detailFormat, as) => {
            switch (as) {
              case "LABEL": return coordinateService.format(value?.coordinates?.[0] ?? [0, 0], detailFormat || lsFormat) || "";
              default: return coordinateService.formatLineString(value, detailFormat || lsFormat) || "";
            }
          };
        case "POLYGON":
          const pFormat = params[0]?.split(";")[0];
          return (value, detailFormat, as) => {
            switch (as) {
              case "LABEL": return coordinateService.format(value?.coordinates?.[0]?.[0] ?? [0, 0], detailFormat || pFormat) || "";
              default: return coordinateService.formatPolygon(value, detailFormat || pFormat) || "";
            }
          };
        case "GEOMETRY":
          const gFormat = params[0]?.split(";")[0];
          return (value, detailFormat, as) => {
            switch (as) {
              case "LABEL": return coordinateService.formatGeometryForLabel(value, detailFormat || gFormat) || "";
              default: return coordinateService.formatGeometry(value, detailFormat || gFormat) || "";
            }
          };
      }
    }
    return undefined;
  }

  /**
   * Get a parser (for a text field).
   *
   * @param format the format.
   */
  public getParser(format?: DataFormat): undefined | ((text?: string, detailFormat?: string) => any) {
    if (format) {
      const [name, ...params] = format.split(":");
      switch (name) {
        case "NUMBER":
          return (value) => numberService.parse(value);
        case "UNIT":
          const unit = params[0];
          const asUnit = params[1]?.split(";")[0];
          return (value, detailFormat) => unitService.parseNumber(value, unit, detailFormat || asUnit);
        case "LATITUDE":
          return (value) => coordinateService.parseLatitude(value);
        case "LONGITUDE":
          return (value) => coordinateService.parseLongitude(value);
        case "COORDINATE":
          return (value) => coordinateService.parse(value);
        case "POINT":
          return (value) => coordinateService.parsePoint(value);
        case "LINESTRING":
          return (value) => coordinateService.parseLineString(value);
        case "POLYGON":
          return (value) => coordinateService.parsePolygon(value);
        case "GEOMETRY":
          return (value) => coordinateService.parseGeometry(value);
      }
    }
    return undefined;
  }

  public getDefaultDetailFormat(format?: DataFormat): undefined | string {
    if (format) {
      const [name, ...params] = format.split(":");
      switch (name) {
        case "UNIT":
          const unit = params[0];
          const asUnit = params[1]?.split(";")[0];
          return asUnit || unit;
        case "LATITUDE":
        case "LONGITUDE":
        case "COORDINATE":
        case "POINT":
        case "LINESTRING":
        case "POLYGON":
        case "GEOMETRY":
          return params[0]?.split(";")[0];
      }
    }
    return undefined;
  }

  public getDetailFormats(format?: DataFormat): undefined | PossibleValue[] {
    if (format) {
      const [name, ...params] = format.split(":");
      switch (name) {
        case "UNIT":
          const unit = params[0];
          const units = FormatService.getUnits(unit, params[1]?.split(";") || []);
          return units.length > 0 ? units.map(u => ({label: unitService.label(u.name) || "", value: u.name})) : undefined;
        case "LATITUDE":
        case "LONGITUDE":
        case "COORDINATE":
        case "POINT":
        case "LINESTRING":
        case "POLYGON":
        case "GEOMETRY":
          const formats = FormatService.getCoordinateFormats(name, params[0]?.split(";") || []);
          return formats && formats.length > 0 ? formats.map(f => ({label: "", value: f})) : undefined;
      }
    }
    return undefined;
  }

  private static getCoordinateFormats(type: string, formats: string[]): string[] {
    const nonEmptyFormats = formats.filter(f => f).map(f => f!);
    if (formats.length < 2) {
      FormatService.getDefaultCoordinateFormats(type).forEach(f => {
        if (!nonEmptyFormats.includes(f)) {
          nonEmptyFormats.push(f);
        }
      });
    }
    return nonEmptyFormats;
  }

  private static getDefaultCoordinateFormats(type: string) {
    if (messages.isReady() && messages.has(`format.${type}.options`)) {
      return messages.get(`format.${type}.options`).split(";");
    }
    switch (type) {
      case "LATITUDE":
        return ["dmsLat", "dmLat", "dLat", "sdmsLat", "sdmLat", "decimalLat"];
      case "LONGITUDE":
        return ["dmsLon", "dmLon", "dLon", "sdmsLon", "sdmLon", "decimalLon"];
      default:
        return ["dmsLatLon", "dmLatLon", "dLatLon", "sdmsLatLon", "sdmLatLon", "dmsLonLat", "dmLonLat", "dLonLat", "sdmsLonLat", "sdmLonLat"];
    }
  }

  private static getUnits(unitName: string, asUnitNames: string[]): Unit[] {
    const unit = unitService.getUnit(unitName);
    if (unit) {
      const asUnits = asUnitNames.map(name => unitService.getUnit(name)).filter(u => u && u.type === unit.type).map(u => u!);
      if (asUnits.length === 0) {
        asUnits.push(unit);
      }
      if (asUnitNames.length < 2) {
        FormatService.getDefaultUnits(unit.type).forEach(u => {
          if (!asUnits.find(au => au.name === u.name)) {
            asUnits.push(u);
          }
        });
      }
      return asUnits;
    }
    return [];
  }

  private static getDefaultUnits(type: string): Unit[] {
    if (messages.isReady() && messages.has(`format.unit.${type}.options`)) {
      return messages.get(`format.unit.${type}.options`).split(";")
        .map(name => unitService.getUnit(name))
        .filter(u => u && u.type === type)
        .map(u => u!);
    }
    return unitService.getUnitsOfType(type);
  }

}

const formatService = new FormatService();

export {formatService};
