/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {arePropsEqual, dateService, MessageKey, messages, SortService, useMessages} from "@icm/core-common";
import {AuditlogEntity, ChangeInformation, EntityHistory, Property, PropertyChange, Reference, ReferralChange} from "@icm/rem-auditlog-common";
import {Box, Grid, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import * as React from "react";
import {createContext, useContext} from "react";

import styles from "./AuditLog.style";

interface IAuditLogRendererProps {
  auditLog: EntityHistory;
  type: string;
}

const useStyles = makeStyles(styles);

const chapterClasses = [
  "InitialReportDbo", "ProtectDbo", "HelpDbo", "WeatherDbo", "DynamicChecklistChapterDbo",
  "AbstractChecklistElementDbo", "SimpleChecklistElementDbo", "DynamicChecklistElementDbo",
  "CommunicationDbo", "IncidentInvolvedPersonTypeDboEnum", "IncidentInvolvedPersonDbo",
  "IncidentToFacilityDbo", "CommunicationTypeDboEnum", "EventNotificationDbo",
  "IncidentJournalDbo", "AttachmentDbo",
];

const chaptersWithReferenceHistory = ["EventNotificationDbo"];

const INCIDENT_PROPERTY_NAME = "linkedtoincident";
const METADATA_ROW = "row";
const METADATA_ROW_TITLE = "rowTitle";
const METADATA_PARENTELEMENT = "parentElement";
const METADATA_GROUP_TITLE = "groupTitle";

const AuditlogContext = createContext({} as EntityHistory);

export const AuditLogRenderer = React.memo(({
  auditLog,
  type,
}: IAuditLogRendererProps) => {
  const classes = useStyles();
  const {getMessage} = useMessages();

  return (
    <div key="root" className={classes.root}>
      <Grid container={true} key="title" direction="row" justifyContent="space-between" align-content="flex-end">
        <Typography variant="subtitle1">
          <span>{getMessage(`auditlog.${type.toLowerCase()}`)} {auditLog.entity!.properties!.find(value => value.name === "number")!.changes![0].newValue}</span>
        </Typography>
        <Typography variant="subtitle1">
          <span>{getMessage(MessageKey.CORE.DATE)}: {dateService.formatDate(new Date())}</span><br />
          <span>{getMessage(MessageKey.CORE.TIME)}: {dateService.formatTime(new Date(), "MEDIUM")}</span>
        </Typography>
      </Grid>
      {auditLog.entity!.label && (
        <Typography variant="subtitle1"
                    key={auditLog.entity!.entityId}
                    className={classes.heading1}
        >
          {auditLog.entity!.label}
        </Typography>
      )}
      <AuditlogContext.Provider value={auditLog}>
        <Grid container={true} key={auditLog.entity!.entityId + ".properties"} direction="column">
          <Typography variant="subtitle1"
                      className={classes.heading1}
          >
            {getMessage(MessageKey.AUDITLOG.MAIN_DATA)}
          </Typography>
          <PropertyList properties={auditLog.entity!.properties!} />
        </Grid>
        {renderContent(auditLog.entity!, auditLog.entity!.entityId!)}
      </AuditlogContext.Provider>
      <Grid container={true}
            key="changeInformation"
            className={classes.changeInformation}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
      >
        {auditLog.changeInformations!.map(value => renderChangeInformation(value))}
      </Grid>
    </div>
  );

  function renderContent(rootEntity: AuditlogEntity, incidentId: string): JSX.Element[] {
    let lastRow: string | undefined;
    let lastParentElement: string | undefined;
    return getChaptersFrom(rootEntity, incidentId, 0)
      .flatMap((chapter, idx) => {
        const render = [];
        const currentRow: string | undefined = chapter.metaData[METADATA_ROW];
        const parentElement: string | undefined = chapter.metaData[METADATA_PARENTELEMENT];
        const groupTitle: string | undefined = chapter.metaData[METADATA_GROUP_TITLE];
        const rowTitle: string | undefined = chapter.metaData[METADATA_ROW_TITLE];

        if (parentElement && groupTitle && (!lastParentElement || lastParentElement !== parentElement)) {
          render.push(<Typography key={`${idx}.groupTitle`} className={classes.heading2}>{groupTitle}</Typography>);
        }
        if (parentElement && currentRow && rowTitle && (!lastRow || lastRow !== currentRow)) {
          render.push(<Typography key={`${idx}.rowTitle`} className={classes.heading2}>{rowTitle}</Typography>);
        }

        lastRow = currentRow;
        lastParentElement = parentElement;

        if (parentElement && (groupTitle || rowTitle)) {
          render.push(
            <Box key={`${idx}.chapter`} className={classes.indent1}>
              <Chapter key={idx} chapter={chapter} />
            </Box>
          );
        } else {
          render.push(<Chapter key={idx} chapter={chapter} />);
        }
        return render;
      });
  }

  function renderChangeInformation(changeInfo: ChangeInformation) {
    return (
      <Typography key={changeInfo.id} className={classes.changeSet} variant="body2">
        <ChangeInformationText changeInfo={changeInfo} />
      </Typography>
    );
  }
}, arePropsEqual);

const Chapter = ({chapter}: { chapter: AuditlogEntity }) => {
  const classes = useStyles();
  const {getMessage} = useMessages();
  return (
    <>
      <Typography variant="subtitle1"
                  key={chapter.entityId + ".title"}
                  className={!chapter.metaData || chapter.metaData.level === 0 ? classes.heading1 : classes.heading2}
      >
        {chapter.label} {chapter.labelSuffix}
      </Typography>
      {chapter.metaData.deleted ? (
        <div className={classes.deletedChapter}>
          {getMessage(MessageKey.AUDITLOG.INCIDENT.VALUEDELETED)}<ChangeInformationRef changeInfoId={chapter.metaData.deletionChange} />
        </div>
      ) : ""}
      {chapter.properties && chapter.properties.length > 0
        ? (
          <Grid container={true} key={chapter.entityId + ".properties"} direction="column">
            <PropertyList properties={chapter.properties} />
          </Grid>
        ) : ""}
    </>
  );
};

const PropertyList = ({properties}: { properties: Property[] }) => {
  const classes = useStyles();
  const {getMessage} = useMessages();
  return (
    <>
      {properties.map((property, idx) => (
        <Grid key={`${idx}.${property.name}`}
              container={true}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={clsx({
                printTwoColumns: true,
              })}
        >
          <Typography variant="body2" className={classes.propertyName}>
            {property.label}
          </Typography>
          <Grid container={true}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.propertyValue + " " + classes.lineThrough}
          >
            {property.changes!.map((value, subIndex) => renderPropertyChanges(subIndex, value, property.type!))}
          </Grid>
        </Grid>
      ))}
    </>
  );

  function renderPropertyChanges(index: number, propertyChange: PropertyChange, type: string) {
    if (propertyChange.newLabels) {
      return (
        <Grid container={true} key={index} direction="column" className={classes.changeSet}>
          {propertyChange.newLabels.map((value, idx) => (
            <Typography
              key={idx}
              className={clsx({
                value: true,
              })}
              variant="body2"
              color="inherit"
            >
              {value}{idx + 1 < propertyChange.newLabels!.length ? ", " : <ChangeInformationRef changeInfoId={propertyChange.changeInformation} />}
            </Typography>
          ))}
        </Grid>
      );
    } else {
      let value: string;
      let valueDeleted = false;
      if (type.toLowerCase()
        .endsWith("boolean")) {
        value = propertyChange.newValue.toString() === "true" ? getMessage(MessageKey.CORE.YES)! : getMessage(MessageKey.CORE.NO)!;
      } else if (type.toLowerCase()
        .endsWith("date")) {
        value = dateService.formatDateTime(propertyChange.newValue, "SHORT", "MEDIUM");
      } else if (propertyChange.oldValue != null && propertyChange.newValue == null) {
        value = getMessage(MessageKey.AUDITLOG.INCIDENT.VALUEDELETED);
        valueDeleted = true;
      } else {
        value = (propertyChange.newValue && propertyChange.newValue.toString()) || "";
        value = value.replace(/\\n/g, "\n");
      }

      return (
        <Typography key={index}
                    className={clsx({
                      [classes.changeSet]: true,
                      [classes.deletedValue]: valueDeleted,
                      value: true,
                    })}
                    variant="body2"
                    color="inherit"
        >
          {value}
          <ChangeInformationRef changeInfoId={propertyChange.changeInformation} />
        </Typography>
      );
    }
  }
};

const ChangeInformationRef = ({changeInfoId}: { changeInfoId?: number }) => {
  const auditLog = useContext(AuditlogContext);
  if (changeInfoId) {
    const changeInfo = auditLog.changeInformations?.find(ci => ci.id === changeInfoId);
    return (
      <Tooltip title={changeInfo ? <ChangeInformationText changeInfo={changeInfo} /> : ""} placement="top">
        <sup>&nbsp;{changeInfoId})</sup>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

const ChangeInformationText = ({changeInfo}: { changeInfo: ChangeInformation }) => (
  <>
    <sup>&nbsp;{changeInfo.id})</sup>&nbsp;{changeInfo.changedBy}, {changeInfo.clientHostname}, {dateService.formatDateTime(changeInfo.changedAt!, "SHORT", "MEDIUM")}
  </>
);

//
// Utility functions
//
function getChaptersFrom(entity: AuditlogEntity, incidentId: string, level: number) {
  let chapters: AuditlogEntity[] = [];
  const sortedReferences = [...entity.references!].sort((a, b) => SortService.compare((a.referencedObject!.label ? a.referencedObject!.label : "") + a.referencedObject!.entityId, (b.referencedObject!.label ? b.referencedObject!.label : "") + b.referencedObject!.entityId));
  for (const chapterClass of chapterClasses) {
    for (const tmp of sortedReferences) {
      const reference: AuditlogEntity = tmp.referencedObject!;
      const changes: ReferralChange[] = tmp.changes ?? [];
      const isRelevantClass = reference.fullyQualifiedClassName!.endsWith("." + chapterClass);
      const hasReferences = reference.references!.length > 0;
      const hasProperties = reference.properties!.length > 0;
      if (isRelevantClass && (hasReferences || hasProperties)) {
        if (!reference.metaData) {
          reference.metaData = {};
        }
        reference.metaData.level = level;
        const name: string = chapterClass.toLowerCase();
        const deletionChange: number | null = getDeletionChangeInformation(changes);
        const chapter: AuditlogEntity = getChapterFromReferencedObject(reference, name, incidentId, level, deletionChange);
        const subchapters = getChaptersFrom(reference, incidentId, level + 1);
        const hasSubchapters = subchapters.length > 0;
        const isMainData = reference.metaData.tags?.includes("DYNAMIC_MAIN_DATA_CHAPTER");
        // insert dynamic entity chapters tagged as "main data" directly under main chapter
        if (isMainData && hasSubchapters) {
          chapters = [...subchapters, ...chapters];
        } else if (reference.properties!.length > 0 || hasSubchapters) {
          chapters = [...chapters, chapter, ...subchapters];
        }
      }
    }
  }
  const result: AuditlogEntity[] = mergeChapters(chapters);
  if (level === 0) {
    console.log("Top level chapters", result);
  }
  return result;
}

function getChapterLabelKey(chapter: AuditlogEntity, lastParentLabel?: string): string {
  const parentLabel = isInvolvedPerson(chapter) && lastParentLabel ? lastParentLabel + "@@@" : "";
  const label = chapter.label ?? "";
  const suffix = chapter.labelSuffix ?? "";
  return parentLabel + label + "@@@" + suffix + chapter.entityId;
}

function mergeChapters(chapters: AuditlogEntity[]): AuditlogEntity[] {
  // group chapters by label
  const chaptersByLabel = new Map();
  let lastParentLabel = undefined;
  let lastKey: string | undefined = undefined;
  for (const chapter of chapters) {
    // save parent chapter for grouping involved persons, assuming correct order of chapters
    if (isInvolvedPersonEnum(chapter)) {
      lastParentLabel = chapter.label;
    }
    const key: string = lastKey && hasParent(chapter) ? lastKey : getChapterLabelKey(chapter, lastParentLabel);
    if (chaptersByLabel.has(key)) {
      chaptersByLabel.get(key)
        .push(chapter);
    } else {
      chaptersByLabel.set(key, [chapter]);
    }
    lastKey = key;
  }
  // iterate over chapters by label
  const result: AuditlogEntity[] = [];
  let mergeList: AuditlogEntity[] = [];
  let mergeTopLevelCount = 0;
  let lastLabel: string = "";
  let lastSuffix: string = "";
  for (const key of chaptersByLabel.keys()) {
    for (const chapter of chaptersByLabel.get(key)) {
      if (chapter.metaData.level === 0) {
        const label = chapter.label || "";
        const suffix = chapter.labelSuffix || "";
        const isChapterJournal = isJournal(chapter);
        if (label !== lastLabel || suffix !== lastSuffix) {
          pushMergedChapterIntoResult(mergeList, result, lastLabel, lastSuffix, mergeTopLevelCount);
          mergeTopLevelCount = !isChapterJournal ? 1 : 0;
          mergeList = [];
        } else if (!isChapterJournal) {
          mergeTopLevelCount++;
        }
        lastLabel = label;
        lastSuffix = suffix;
      }
      mergeList.push(chapter);
    }
  }
  pushMergedChapterIntoResult(mergeList, result, lastLabel, lastSuffix, mergeTopLevelCount);
  return result;
}

function pushMergedChapterIntoResult(mergeList: AuditlogEntity[], result: AuditlogEntity[], lastLabel: string,
                                     lastSuffix: string, mergeTopLevelCount: number) {
  console.debug("Merging chapters under key", lastLabel, lastSuffix, mergeTopLevelCount, mergeList);
  const indent = mergeTopLevelCount > 1;
  if (indent) {
    result.push({
      label: lastLabel,
      labelSuffix: lastSuffix,
      metaData: {level: 0},
    });
  }
  orderMergeList(mergeList);
  if (mergeList.length > 0 && mergeList.filter(isJournal).length === mergeList.length) {
    // handle journals only: add chapter title, then chapter
    if (!indent) {
      result.push({
        label: lastLabel,
        labelSuffix: lastSuffix,
        metaData: {level: 0},
      });
    }
    pushMergedChapterWithJournalsIntoResultInternal(mergeList, result, indent);
  } else {
    // handle mix of subchapters & journals
    pushMergedChapterWithJournalsIntoResultInternal(mergeList, result, indent);
  }
}

function orderMergeList(list: AuditlogEntity[]) {
  if (list && list.length > 0 && list[0].label) {
    const fullyQualifiedName = list[0].fullyQualifiedClassName!.toLowerCase()
      .split(".");
    switch (fullyQualifiedName[fullyQualifiedName.length - 1]) {
      case "incidentjournaldbo":
        list.sort(orderIncidentJournalEntries);
        break;
      case "abstractchecklistelementdbo":
      case "simplechecklistelementdbo":
      case "dynamicchecklistelementdbo":
      case "communicationdbo":
        list.sort(orderByMetaDataSequence);
        break;
      case "attachmentdbo":
        list.sort(orderAttachmentsByCreationChangeInformation);
        break;
      default:
        // do nothing
        break;
    }
  }
}

/**
 * Orders the passed list of entity journal entries by the log time.
 * Otherwise the same list is returned.
 */
function orderIncidentJournalEntries(a: AuditlogEntity, b: AuditlogEntity): number {
  return SortService.compare(a.metaData?.logTime, b.metaData?.logTime);
}

/**
 * Orders the passed list of entries by the metaData sequence.
 * Otherwise the same list is returned.
 */
function orderByMetaDataSequence(a: AuditlogEntity, b: AuditlogEntity): number {
  return SortService.compare(a.metaData?.sequence, b.metaData?.sequence);
}

/**
 * Orders the passed list of attachments by the creation change information sequence number.
 * Otherwise the same list is returned.
 */
function orderAttachmentsByCreationChangeInformation(a: AuditlogEntity, b: AuditlogEntity): number {
  const createChangeA = a.entityChanges!.find(e => e.operationType === "CREATE");
  const createChangeB = b.entityChanges!.find(e => e.operationType === "CREATE");
  return SortService.compare(createChangeA && createChangeA.changeInformation, createChangeB && createChangeB.changeInformation);
}

function getDeletionChangeInformation(changes: ReferralChange[]): number | null {
  for (const change of changes) {
    if (change.referralChange === "DEREFERRAL") {
      return change.changeInformation || null;
    }
  }
  return null;
}

function addReferenceHistoryProperty(reference: AuditlogEntity, incidentId: string): void {
  const incidentReference = getReference(reference, incidentId);
  const linkedToIncident = reference.properties!.find(p => p.name === INCIDENT_PROPERTY_NAME);
  if (incidentReference !== null && !linkedToIncident) {
    const property: Property = new Property();
    property.label = messages.get(MessageKey.AUDITLOG.LINKEDTOINCIDENT);
    property.name = INCIDENT_PROPERTY_NAME;
    property.type = "boolean";
    property.changes = [];
    for (const referralChange of incidentReference.changes || []) {
      const propertyChange: PropertyChange = new PropertyChange();
      propertyChange.changeInformation = referralChange.changeInformation;
      propertyChange.newValue = (referralChange.referralChange === "REFERRAL");
      property.changes.push(propertyChange);
    }
    reference.properties!.push(property);
  }
}

function getReference(entity: AuditlogEntity, id: string): Reference | null {
  if (entity.references) {
    return entity.references.find(r => r.referencedObject!.entityId === id) || null;
  } else {
    return null;
  }
}

function getChapterFromReferencedObject(reference: AuditlogEntity, name: string, incidentId: string, _level: number,
                                        deletionChange: number | null): AuditlogEntity {
  // handle chapters with reference referral history, such as event notification (which is added to and removed from incident)
  if (isChapterWithReferenceHistory(name)) {
    addReferenceHistoryProperty(reference, incidentId);
  }
  reference.metaData.deleted = (deletionChange !== null);
  reference.metaData.deletionChange = deletionChange;
  return {...reference};
}

function pushMergedIncidentJournalChapterIntoResult(mergeList: AuditlogEntity[], result: AuditlogEntity[], indent: boolean) {
  const groupedList = groupBy(mergeList, e => e.metaData.checklistElementDefinitionId);
  groupedList.forEach((journals, key) => {
    if (journals.length > 0 && key !== undefined) {
      // add header line
      result.push({
        label: journals[0].metaData.checklistElementLabel,
        labelSuffix: "",
        metaData: {level: indent ? 1 : 0},
      });
      // add journal entries
      journals.sort(orderIncidentJournalEntries);
      pushMergedChapterIntoResultInternal(journals, result, indent);
    }
  });
}

function pushMergedChapterIntoResultInternal(mergeList: AuditlogEntity[], result: AuditlogEntity[], indent: boolean) {
  let topLevelCounter = 0;
  for (const entity of mergeList) {
    if (indent) {
      const topLevel = entity.metaData.level === 0;
      entity.metaData.level += 1;
      if (topLevel) {
        entity.label = messages.get(MessageKey.CORE.ENTRY);
        entity.labelSuffix = "" + (topLevelCounter + 1);
        topLevelCounter++;
      }
    }
    result.push(entity);
  }
}

function pushMergedChapterWithJournalsIntoResultInternal(mergeList: AuditlogEntity[], result: AuditlogEntity[], indent: boolean) {
  const mergeListOthers = mergeList.filter(e => !isJournal(e));
  const mergeListJournals = mergeList.filter(isJournal);
  pushMergedChapterIntoResultInternal(mergeListOthers, result, indent);
  pushMergedIncidentJournalChapterIntoResult(mergeListJournals, result, true);
}

function groupBy(list: AuditlogEntity[], keyGetter: (e: AuditlogEntity) => string) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function isJournal(entity: AuditlogEntity): boolean {
  return entity.fullyQualifiedClassName!.toLowerCase()
    .endsWith("incidentjournaldbo");
}

function isInvolvedPerson(chapter: AuditlogEntity): boolean {
  return chapter.fullyQualifiedClassName!.includes("IncidentInvolvedPersonDbo");
}

function isInvolvedPersonEnum(chapter: AuditlogEntity): boolean {
  return chapter.fullyQualifiedClassName!.includes("IncidentInvolvedPersonTypeDboEnum");
}

function isChapterWithReferenceHistory(name: string): boolean {
  return chaptersWithReferenceHistory.findIndex(s => s.toLowerCase() === name.toLowerCase()) >= 0;
}

function hasParent(entity: AuditlogEntity): boolean {
  return !!entity.metaData.parentElement;
}
