/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "../../theme";

const styles = createStyles({
  root: theme => ({
    borderRadius: "50%",
    display: "inline-block",
    position: "fixed",
    width: theme.spacing(7),
    height: theme.spacing(7),
    top: "50%",
    left: "50%",
    marginLeft: theme.spacing(-3.5),
    marginTop: theme.spacing(-3.5),
  }),
  ring: theme => ({
    margin: theme.spacing(1),
  }),
});

export default styles;
