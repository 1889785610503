/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableCellProps, RowAction} from "@icm/core-common";
import {Box, ClickAwayListener, Grid, TableCell} from "@mui/material";
import clsx from "clsx";
import {DotsVertical} from "mdi-material-ui";
import * as React from "react";
import {useMemo, useState} from "react";

import {IconButtonComponent} from "../../../form";
import {useDataTableContext} from "./DataTableContext";
import {useDataTableStyles} from "./DataTableStyle";
import {getComponent} from "./DataTableUtil";

type DataTableActionCellProps<T> = DataTableCellProps<T> & {
  actions: RowAction<T>[]
  rowIndex: number
}
export const DataTableActionCell = <T extends unknown>({rowValue, rowIndex, actions}: DataTableActionCellProps<T>) => {
  const classes = useDataTableStyles();
  const {variant} = useDataTableContext();

  function stopPropagation(ev: React.MouseEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  return (
    <TableCell component={getComponent(variant)} className={classes.actionsColumn} onClick={(ev) => stopPropagation(ev)}>
      <Actions actions={actions}
               row={rowValue}
               rowIndex={rowIndex}
      />
    </TableCell>
  );
};


type ActionsProps<T> = {
  actions: RowAction<T>[]
  row: T
  rowIndex: number
}

const Actions = <T extends unknown>({actions, row, rowIndex}: ActionsProps<T>) => {
  const classes = useDataTableStyles();
  const [open, setOpen] = useState(false);
  const context = useDataTableContext();

  const innerContainer = useMemo(() => (
    <Grid container wrap="nowrap" gap={1} justifyContent="flex-end" alignItems="center" className={classes.actions}>
      {actions
        .filter(action => action.isVisible(row, rowIndex))
        .map((action: RowAction<T>, key) => {
          return (
            <Grid key={key} item className={classes.actionContainer}>
              <IconButtonComponent tooltip={action.label}
                                   tooltipPlacement="top"
                                   icon={action.icon}
                                   fontSize="small"
                                   handleClick={() => action.onClick(row, rowIndex)}
                                   padding="small"
                                   disabled={!action.isEnabled(row, rowIndex)}
              />
            </Grid>
          );
        })}
      <Grid item className={classes.actionMoreContainer}>
        <IconButtonComponent icon={<DotsVertical />}
                             fontSize="small"
                             padding="small"
                             handleClick={() => setOpen(o => !o)}
        />
      </Grid>
    </Grid>
  ), [actions, classes.actionContainer, classes.actionMoreContainer, classes.actions, row, rowIndex]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box className={clsx(classes.actionsBox, open && classes.actionsBoxOpen)}>
        <Box className={classes.actionsInnerBox} flex={{right: context.actionBarOffset}}>
          {innerContainer}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
