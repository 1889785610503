/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import * as React from "react";

import {createThemeState} from "./ThemeDefaults";
import {IcmThemeConfiguration, IcmThemeState} from "./types";
import {useThemeConfiguration} from "./useTheme";

type ThemeContextType = {
  ready: true
  themeState: IcmThemeState
  themeConfiguration: IcmThemeConfiguration
  setThemeConfiguration: Dispatch<SetStateAction<IcmThemeConfiguration>>
  setCurrentTheme: Dispatch<SetStateAction<string>>
}

export type UseThemeContextResult = ThemeContextType | {
  ready: false
  setCurrentTheme: Dispatch<SetStateAction<string>>
}

/**
 * Use this hook to populate the ThemeContext
 */
export function useThemeContextState(): UseThemeContextResult {
  const serverThemeConfiguration = useThemeConfiguration(); // from server
  const [themeConfiguration, setThemeConfiguration] = useState<IcmThemeConfiguration>();
  const [currentTheme, setCurrentTheme] = useState<string>();

  useEffect(() => {
    if (serverThemeConfiguration.isSuccess) {
      setThemeConfiguration(serverThemeConfiguration.data);
    }
  }, [serverThemeConfiguration.data, serverThemeConfiguration.isSuccess]);

  const themeState = useMemo(() => {
    if (themeConfiguration) {
      return createThemeState(themeConfiguration, currentTheme);
    }
    return undefined;
  }, [themeConfiguration, currentTheme]);

  if (themeState) {
    return {ready: true, themeState, themeConfiguration: themeConfiguration!, setThemeConfiguration, setCurrentTheme};
  } else {
    return {ready: false, setCurrentTheme};
  }
}

export const ThemeContext = React.createContext<ThemeContextType | undefined>(undefined);
