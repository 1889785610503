/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export const CHECKBOX = "CHECKBOX";
export const COMPOSITE_VIEW = "COMPOSITE_VIEW";
export const DATETIME_PICKER = "DATETIME_PICKER";
export const DATE_PICKER = "DATE_PICKER";
export const IFRAME = "IFRAME";
export const FILTER = "FILTER_COMPONENT";
export const FORM_DIALOG = "FORM_DIALOG_COMPONENT";
export const FORM_GENERATOR_LAYOUT = "FORM_GENERATOR_LAYOUT";
export const FORM_VIEW_COMPONENT = "FORM_VIEW_COMPONENT";
export const ICON = "ICON";
export const LIST = "LIST";
export const LOADER = "LOADER";
export const MEDIA_STREAM_PLAYER = "MEDIA_STREAM_PLAYER";
export const RADIOBUTTON = "RADIOBUTTON";
export const SELECT_MULTI = "SELECT_MULTI";
export const SELECT_SINGLE = "SELECT_SINGLE";
export const TEXT = "TEXT";
export const TEXT_AREA = "TEXT_AREA";
export const TEXT_FIELD = "TEXT_FIELD";
export const TIME_PICKER = "TIME_PICKER";
