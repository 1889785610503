/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  FeedbackBannerProvider,
  HTMLHeadService,
  MessageKey,
  ThemeContext,
  useConfiguration,
  useConnectionStateMonitoring,
  useMessages,
  UserSettingsApi,
  useService,
  useThemeContextState,
} from "@icm/core-common";
import {ContainerGrid, IcmMuiThemeAdapter, LoadingSpinner, RoutingPage} from "@icm/core-web";
import React, {useEffect} from "react";

export const App = () => {
  const {messageStateReady} = useConfiguration();
  const {getMessage} = useMessages();
  const themeContextState = useThemeContextState();
  const securityService = useService("SECURITY");

  useEffect(() => {
    if (!securityService.isLoggedIn()) {
      securityService.login().then();
    }
  }, [securityService]);

  useEffect(() => {
    if (messageStateReady) {
      HTMLHeadService.setTitle(getMessage(MessageKey.ROOT.TITLE, {defaultMessage: "ICM"}));
    }
  }, [getMessage, messageStateReady]);

  useEffect(() => {
    if (themeContextState.ready) {
      const faviconDir = themeContextState.themeState.themes[themeContextState.themeState.selectedTheme]?.images.FAVICON_DIRECTORY;
      HTMLHeadService.addFaviconHeadInformation(faviconDir);
    }
  }, [themeContextState]);

  const {setCurrentTheme} = themeContextState;
  useEffect(() => {
    securityService.addLoginHandler(() => {
      UserSettingsApi.getUserSetting("theme").then(theme => {
        if (theme) {
          setCurrentTheme(theme);
        }
      });
    });
  }, [securityService, setCurrentTheme]);

  useConnectionStateMonitoring();


  if (messageStateReady && themeContextState.ready) {
    return (
      <ThemeContext.Provider value={themeContextState}>
        <IcmMuiThemeAdapter>
          <FeedbackBannerProvider>
            <ContainerGrid enabled={true} />
            <RoutingPage />
          </FeedbackBannerProvider>
        </IcmMuiThemeAdapter>
      </ThemeContext.Provider>
    );
  } else {
    console.log("Application is not yet ready, messages ready:", messageStateReady, "theme config:", themeContextState);
    return <LoadingSpinner />;
  }
};
