/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {JSEncrypt} from "jsencrypt";

class RsaEncryptor {
  private readonly publicKey: string = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDKO0i+jr2P0PaloA9c3uEWCrGCF"
        + "Bnu0NkZ9zPcrv+a4nx5ih85PCosoG8CusvoGjH69j1Cujcxr0A1PJ2X/rLPlXE"
        + "jeyWaE+JezUbK+RHw8wfvU9ISy+KoZ/jr/qhZLooTIHvYtNP+x7cijuW68LZJM"
        + "5bDZd95Vc99z/UgWShGLQIDAQAB";

  public encrypt(text: string): string {
    const rsaEncrypt = new JSEncrypt({});
    rsaEncrypt.setPublicKey(this.publicKey);
    return rsaEncrypt.encrypt(text) || "";
  }
}

export default RsaEncryptor;
