/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";

import {ExpressionEvaluationService} from "../../service";
import {ICoreApplicationState} from "../register";

export type GetMessageProps = {
  fallbackMessageCode?: string
  defaultMessage?: string
  params?: any
}

export type GetMessage = (code: string, props?: GetMessageProps) => string;

export type HasMessage = (code: string) => boolean;

/**
 * use the returned method to get messages, e.g.:
 * ```
 * const {getMessage} = useMessages();
 * getMessage(MessageKey.CORE.YES);
 * ```
 */
export const useMessages = (): {getMessage: GetMessage, hasMessage: HasMessage} => {
  const messages = useSelector((state: ICoreApplicationState) => state.messageState?.messages);
  const getMessage = useCallback((code: string, props?: GetMessageProps) => getMessageFromMessages(messages, code, props), [messages]);
  const hasMessage = useCallback((code: string) => !!messages && !!messages[code], [messages]);
  return useMemo(() => ({getMessage, hasMessage}), [getMessage, hasMessage]);
};

// NOTE: keep implementation in sync with MessageService
function getMessageFromMessages(messages: Partial<Record<string, string>> | undefined, code: string, props?: GetMessageProps): string {
  const errorResult = "?" + code + "?";
  if (messages) {
    const message = messages[code];
    if (message) {
      if (props?.params) {
        return ExpressionEvaluationService.evaluate(message, props.params);
      } else {
        return message;
      }
    } else if (props?.fallbackMessageCode) {
      return getMessageFromMessages(messages, props.fallbackMessageCode, {
        defaultMessage: props.defaultMessage,
        params: props.params,
      });
    } else if (props?.defaultMessage) {
      return props.defaultMessage;
    } else {
      console.warn("No message found for:", code);
      return errorResult;
    }
  } else {
    console.warn("The i18n messages have not been initialized yet.");
    return errorResult;
  }
}
