/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {WritableDraft} from "immer/dist/types/types-external";

import {ClientFilterSelection} from "../../components";
import {SharedFilterStateName, READY, EDITING, SAVING} from "./SharedFilterStateName";

/**
 * Variant to be used when no variant string is given.
 */
export const DEFAULT_VARIANT = "DEFAULT";


/**
 * Describes the properties of filter within Redux.
 */
export type IFilterState = {
  /** The filter to be shared across different views. */
  sharedFilterSelection: ClientFilterSelection | undefined

  /** The filter currently edited. If not editing, this filter is undefined. */
  editedFilterSelection: ClientFilterSelection | undefined

  /** Identifier of the curren state. Components may bind logic to this state. */
  stateName: SharedFilterStateName
}

/**
 * Maps a general identifier to a filter state object like this:
 * EntityType_Variant -> IFilterState
 *
 */
export type FilterStates = Record<string, Record<string, IFilterState>>;

const FilterStateMap: FilterStates = {};

export type FilterIdentifyingActionPayload = {
  entityType: string,
  variant: string,
};

/**
 * Properties to be passed as payload
 * to actions that modify the shared filter state.
 */
export type FilterActionPayload = {
  clientFilterSelection: ClientFilterSelection | undefined
} & FilterIdentifyingActionPayload;


const {reducer, actions} = createSlice({
  name: "@@filter",
  initialState: FilterStateMap,
  reducers: {

    /**
     * Start editing a filter depending on the action payload.
     *
     * the current shared filter OR use the filter selection passed as payload. */
    startEditing: ((filterStates: WritableDraft<FilterStates>, action: PayloadAction<FilterActionPayload>) => {
      const {clientFilterSelection} = action.payload;
      const state = getCurrentState(filterStates, action);
      if (state.stateName === READY) {
        if (clientFilterSelection) {
          state.editedFilterSelection = {...clientFilterSelection};
          state.stateName = EDITING;
        } else if (state.sharedFilterSelection) {
          state.editedFilterSelection = {...state.sharedFilterSelection};
          state.stateName = EDITING;
        }
      } else {
        console.warn("Cannot start editing filter not ready yet.");
      }
    }),
    save: ((filterStates: WritableDraft<FilterStates>, action: PayloadAction<FilterIdentifyingActionPayload>) => {
      const state = getCurrentState(filterStates, action);
      if (!state || state.stateName !== EDITING) {
        console.warn("Cannot save. Filter for ", action.payload, "not in editing mode.");
        return;
      }
      if (state.editedFilterSelection) {
        state.sharedFilterSelection = {...state.editedFilterSelection};
      }
      state.stateName = SAVING;
    }),
    cancelEditing: ((filterStates: WritableDraft<FilterStates>, action: PayloadAction<FilterIdentifyingActionPayload>) => {
      const state = getCurrentState(filterStates, action);
      state.editedFilterSelection = undefined;
      state.stateName = READY;
    }),
    initSharedFilterSelection: ((filterStates: WritableDraft<FilterStates>, action: PayloadAction<FilterActionPayload>) => {
      const {entityType, variant, clientFilterSelection} = action.payload;
      if (clientFilterSelection) {
        filterStates[entityType] = {
          [variant]: {
            stateName: READY,
            editedFilterSelection: undefined,
            sharedFilterSelection: {...clientFilterSelection},
          },
        };
      }
    }),
    setSharedFilterSelection: ((filterStates: WritableDraft<FilterStates>, action: PayloadAction<FilterActionPayload>) => {
      const state = getCurrentState(filterStates, action);
      const {clientFilterSelection} = action.payload;
      if (state.stateName === EDITING) {
        state.editedFilterSelection = clientFilterSelection ? {...clientFilterSelection} : undefined;
      } else if (state.stateName === READY) {
        state.sharedFilterSelection = clientFilterSelection ? {...clientFilterSelection} : undefined;
      } else {
        console.warn("Cannot change filter in state:", state);
      }
    }),
  },
});

function getCurrentState(filterStates: WritableDraft<FilterStates>,
                         action: PayloadAction<FilterIdentifyingActionPayload>): IFilterState {
  const {entityType, variant} = action.payload;
  return getCurrentStateByTypeAndVariant(filterStates, entityType, variant);
}

function getCurrentStateByTypeAndVariant(filterStates: WritableDraft<FilterStates>, entityType: string, variant: string): IFilterState {
  if (!filterStates[entityType]) {
    throw new Error("Cannot find filter for entityType" + entityType);
  }
  const filterByTypes = filterStates[entityType];
  if (!filterByTypes[variant]) {
    throw new Error("Cannot find filter for variant:" + variant);
  }
  return filterByTypes[variant];
}

export {actions as filterActions};
export {reducer as filterReducer};
