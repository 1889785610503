/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React from "react";

import {ConfirmDialogProvider, useDialogContext} from "../dialog";

export interface WithConfirmDialogProps {
  confirmDialog: ConfirmDialogProvider
}

export const withConfirmDialog = <P extends WithConfirmDialogProps>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
  const {useConfirmDialog} = useDialogContext();
  return (<WrappedComponent {...props} confirmDialog={useConfirmDialog()} />);
};
