/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    "& sup": {
      fontSize: "75%",
    },
  },

  heading1: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#cccccc",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },

  headingSection: {
    borderTop: "none",
    fontSize: "130%",
    textTransform: "uppercase",
  },

  heading2: {
    borderTopWidth: 0,
    borderTopStyle: undefined,
    fontWeight: "bold",
    fontSize: 15,
  },

  heading3: {
    borderTopWidth: 0,
    borderTopStyle: undefined,
    fontWeight: "bold",
    fontSize: 14,
  },

  changeInformation: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#cccccc",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  weakEntity: {
    width: "100%",
  },

  field: {
    marginTop: "10px",
  },

  fieldAdornments: {
    // TODO: better way?
    marginTop: "-13px",
  },

  previousValue: {
    textDecoration: "line-through",
    color: "#969696",
  },

  previousValueWithoutLineThrough: {
    color: "#969696",
  },

  propertyName: {
    flex: 0.33,
    flexWrap: "wrap",
  },

  propertyValue: {
    flex: 1,
  },

  changeSet: {
    marginBottom: theme.spacing(0.25),
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
  deletedValue: {
    fontStyle: "italic",
  },
  deletionNote: {
    fontStyle: "italic",
    margin: "10px 0px",
  },
}));

export default useStyles;
