/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isString} from "lodash-es";

export type AddressValueHolder = {
  /** the actual value in any format. */
  readonly addressValue: string;
}

export const isAddressValueHolder = (v: any): v is AddressValueHolder  => {
  if (v === null) {
    return false;
  } else if (typeof v === "object") {
    return typeof v["addressValue"] === "string";
  }
  return false;
};

/**
 * Model of an address as used in the Address UI component.
 */
export type Address = {

  /** label to be shown in the UI (e.g. the name of a contact) */
  label: string

  id?: string;

} & AddressValueHolder;

export const isAddress = (v: any): v is Address  => {
  if (v === null) {
    return false;
  } else if (typeof v === "object") {
    return typeof v["label"] === "string" && isAddressValueHolder(v);
  }
  return false;
};

/**
 * Check of some of the addresses contains an address whose value
 * equals the given value.
 *
 * @param addresses the set of addresses to search in
 * @param address the value to check for
 */
export function isContained(addresses: Address[], address: string | AddressValueHolder) {
  if (isString(address)) {
    return address.length === 0 || addresses.some(existing => isSameAddress(existing, address));
  } else {
    return addresses.some(existing => isSameAddress(existing, address.addressValue));
  }
}

/**
 * @param address an address or any string
 * @param valueToTest The value to test against
 * @return true if the label or the valueToTest contains the value to be tested
 */
export function isMatchingAddress(address: string | Address, valueToTest: string): boolean {
  const reference = canonicalValue(isString(address) ? address : (address.addressValue.concat(address.label)));
  return reference.includes(valueToTest);
}

export function canonicalValue(address: string | AddressValueHolder): string {
  const c = isString(address) ? address : address.addressValue;
  return c.trim().toLowerCase();
}

export function isSameAddress(address1: string | AddressValueHolder, address2: string | AddressValueHolder): boolean {
  return canonicalValue(address1) === canonicalValue(address2);
}

export function isDifferentAddress(a1: Address, a2: Address): boolean {
  return !isSameAddress(a1, a2);
}

export function createAddress(addressValue: string): Address | undefined {
  const address = canonicalValue(addressValue);
  if (address.length > 0) {
    return {
      label: address,
      addressValue: address,
    };
  }
  return undefined;
}
