/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ACTIVITY_STREAM} from "@icm/activitystream-common";
import {ModuleRegistrationOptions, registerNamedComponentFactory} from "@icm/core-common";
import {listComponentRegistry} from "@icm/core-web";

import {EntityUpdateBadgeComponent} from "./src/component/EntityUpdateBadgeComponent";
import packageInfo from "./src/generated/package.clone.json";
import {ActivityStreamComponent, activityStreamComponentFactory} from "./src/pages/ActivityStreamComponent";
import {ActivityStreamView, activityStreamViewDescriptor} from "./src/pages/ActivityStreamView";

export {ActivityStreamView, ActivityStreamComponent};

export {UpdatingInfiniteListGenerator} from "./src/component/UpdatingInfiniteListGenerator";

export const activityStreamWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  runPostInitialization: () => {
    listComponentRegistry["EntityUpdateBadgeComponent"] = EntityUpdateBadgeComponent;
    registerNamedComponentFactory(ACTIVITY_STREAM, activityStreamComponentFactory);
  },
  providedViews: [activityStreamViewDescriptor],
};
