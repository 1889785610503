/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {BaseViewModelData, IUiState, ViewModel} from "../types";

export type UpdateViewModelOptions<ViewModelData extends BaseViewModelData> = {
  newViewModel: ViewModel<ViewModelData>
}
export type UpdateTabbedViewModelOptions<ViewModelData extends BaseViewModelData> = UpdateViewModelOptions<ViewModelData> & {
  tabGroupId: string,
  tabId: string,
}

const isUpdateTabbedViewModelOptions = <ViewModelData extends BaseViewModelData>(val?: any): val is UpdateTabbedViewModelOptions<ViewModelData> => {
  return val?.tabId && val?.tabGroupId;
};

export const UpdateViewModel = <ViewModelData extends BaseViewModelData>(
  state: IUiState,
  action: PayloadAction<UpdateViewModelOptions<ViewModelData> | UpdateTabbedViewModelOptions<ViewModelData>>
) => {
  const perspective = state.perspective;
  const {newViewModel} = action.payload;

  if (isUpdateTabbedViewModelOptions(action.payload)) {
    const payload = action.payload as UpdateTabbedViewModelOptions<ViewModelData>;
    if (perspective?.perspectiveType === "TABBED") {
      const tab = perspective
        ?.tabGroups.find(tg => tg.id === payload.tabGroupId)
        ?.tabs.find(t => t.id === payload.tabId);
      if (tab) {
        tab.viewModel = newViewModel;
      }
    } else {
      console.warn("Can't update ViewModel for a tab in a non-tabbed perspective");
    }
  } else if (perspective?.perspectiveType === "SINGLE_VIEW") {
    perspective.view.viewModel = newViewModel;
  } else {
    console.warn("Can't update ViewModel in a tabbed perspective without tabGroupId and tabId");
  }
};
