/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ANY_EDIT_MODEL, EditModelDescriptor} from "@icm/core-common";
import {PayloadAction} from "@reduxjs/toolkit";

import {EntityEditState} from "../types";


export const DisposeEntityEditModel = (state: EntityEditState, action: PayloadAction<EditModelDescriptor>) => {
  const {editModelId} = action.payload;
  if (editModelId) {
    if (editModelId === ANY_EDIT_MODEL) {
      console.log("Disposing all edit models");
      state.editModels = {};
    } else {
      const editModel = state.editModels[editModelId];
      if (editModel) {
        console.log("Disposing entity edit model", editModelId);
        delete state.editModels[editModelId!];
      }
    }
  }
};
