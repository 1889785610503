/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableTextOrIconsColumn, MessageKey, SortOrder, useMessages} from "@icm/core-common";
import {TableSortLabel} from "@mui/material";
import {AlertCircle} from "mdi-material-ui";
import * as React from "react";
import {PropsWithChildren} from "react";

import {ConditionalTooltip} from "../../../util";
import {useDataTableContext} from "./DataTableContext";
import {useDataTableStyles} from "./DataTableStyle";

type DataTableHeadCellContentProps = PropsWithChildren<{
  col: DataTableTextOrIconsColumn<unknown>,
  // @default = true
  orderValid?: boolean
}>

export const DataTableHeadCellContent = ({children, col, orderValid = true}: DataTableHeadCellContentProps) => {
  const sortable = col.type === "TEXT" && col.sortable && col.sort;
  const active = "sortOrder" in col && col.sortOrder !== undefined;
  const {getMessage} = useMessages();
  const {tableConfig} = useDataTableContext();
  const classes = useDataTableStyles();
  return (
    <ConditionalTooltip placement="top" title={active && !orderValid ? getMessage(MessageKey.CORE.LIST.TABLE_STATE.INVALID_ORDER) : col.headerTextTooltip}>
      {sortable ? (
        <TableSortLabel active={active}
                        className={classes.sortLabelRoot}
                        direction={mapSortOrder(col.sortOrder)}
                        onClick={() => active && !orderValid ? tableConfig.fixOrderAndConfirmChanges?.() : col.sort!(cycleOrder(col.sortOrder))}
                        IconComponent={active && !orderValid ? AlertCircle : undefined}
                        classes={{icon: classes.sortLabelIcon}}
        >
          <span className={classes.sortLabelText}>
            {children}
          </span>
        </TableSortLabel>
      ) : (
        <>{children}</>
      )}
    </ConditionalTooltip>
  );
};


const mapSortOrder = (order?: SortOrder) => {
  switch (order) {
    case "ASC":
      return "asc";
    case "DESC":
      return "desc";
    default:
      return "desc";
  }
};

const cycleOrder = (order?: SortOrder): SortOrder => {
  switch (order) {
    case "DESC":
      return "ASC";
    case "ASC":
      return "DESC";
    default:
      return "DESC";
  }
};
