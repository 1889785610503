/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {clearEntityUpdates} from "@icm/activitystream-common";
import {QueryKey, useQueryClient} from "react-query";
import {useDispatch} from "react-redux";

export const useInfiniteEntityListReload = (entityType: string, queryKey: QueryKey) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return () => {
    // Make sure the entity updates recorded so far get removed
    // See PICM-2068 for example why.
    dispatch(clearEntityUpdates({type: entityType}));
    return queryClient.resetQueries(queryKey);
  };
};
