/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMemo, useState} from "react";

import {Dao} from "./Dao";
import {StaticListBasedDao, StaticListBasedDaoOptions} from "./StaticListBasedDao";
import {UrlBasedDao, UrlBasedDaoOptions} from "./UrlBasedDao";

function isUrlBasedDaoOptions(opt: any): opt is UrlBasedDaoOptions {
  return opt.possibleValuesUrl;
}

/**
 * Provides a dao depending on the given options.
 * @param daoOptions either UrlBasedDaoOptions or StaticListBasedDaoOptions
 */
export function useDao<T>(daoOptions: UrlBasedDaoOptions | StaticListBasedDaoOptions<T>): Dao<T> {
  const [additionalOptions, setAdditionalOptions] = useState<T[]>([]);
  return useMemo(() => {
    if (isUrlBasedDaoOptions(daoOptions)) {
      return new UrlBasedDao(daoOptions);
    } else {
      return new StaticListBasedDao(daoOptions, additionalOptions, setAdditionalOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daoOptions]); // if adding additionalOptions here: input in auto-select is set to "" after change of options
}
