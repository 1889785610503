/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isNonEmptyString} from "@icm/core-common";

import {ProcessDefinitionDetails} from "../data";
import {Entity} from "../generated/api";

/**
 * Type alias to access entities in a way where we know that
 *
 * - id
 * - dynamicAttributes
 *
 * are present.
 */
export type WeakEntityAlikeObject = Entity & Required<Pick<Entity, "dynamicAttributes" | "id" >>;

export const isWeakEntity = (val?: unknown): val is WeakEntityAlikeObject => {
  return val !== null
    && typeof val === "object"
    && isNonEmptyString(val["id"])
    && typeof val["dynamicAttributes"] === "object";
};

/**
 * Type alias to access entities in a way where we know that
 *
 * - id
 * - type
 * - dynamicAttributes
 *
 * are present.
 */
export type EntityAlikeObject = Entity & Required<Pick<Entity, "dynamicAttributes" | "type" | "id" >>;

/**
 * Check if the given value is considered an entity.
 * Therefore the passed object must be an object, have an id, dynamicAttributes and a type.
 *
 * @param val any object
 */
export const isEntity = (val?: unknown): val is EntityAlikeObject => {
  return val !== null
    && typeof val === "object"
    && isNonEmptyString(val["type"])
    && isNonEmptyString(val["id"])
    && typeof val["dynamicAttributes"] === "object";
};

export const isProcessDetails = (val?: any): val is ProcessDefinitionDetails => {
  return typeof val?.processId === "string";
};

/**
 * Type which reflects the properties of an dynamicAttribute which
 * is configured as "WEAK".
 *
 * @see createWeakAttributeValue
 */
export type WeakAttribute = {
  ids: string[],
  data: {}
} & Entity

/**
 * Return the structure that is used to store weak references.
 */
export function createWeakAttributeValue() {
  return {
    ids: [],
    data: {},
  };
}

export const isWeakAttribute = (sourceObject?: unknown): sourceObject is WeakAttribute => {
  return sourceObject !== null
    && typeof sourceObject === "object"
    && sourceObject["ids"]
    && sourceObject["data"]
    && Array.isArray(sourceObject["ids"])
    && sourceObject["data"] !== null
    && typeof sourceObject["data"] === "object";
};
