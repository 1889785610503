/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {styled, Tab} from "@mui/material";
import React from "react";

export const PaperTab = React.memo(styled(Tab)(({theme}) => ({
  cursor: "pointer",
  background: theme.palette.background.default,
  height: "40px",
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: "none",
  display: "flex",
  alignItems: "center",
  color: theme.icm.palette.background.textSecondary,

  "&:hover": {
    color: theme.icm.palette.primary.main,
  },

  "&.Mui-focusVisible": {
    color: "#fff",
    outline: "none",
  },

  "&.Mui-selected": {
    // color: theme.icm.palette.primary.main,
    // alternatively:
    color: theme.icm.palette.primary.textPrimary,
    background: theme.palette.primary.main,
    boxShadow: "0px 2px 0px -1px rgb(255 255 255)",
  },

  "&.Mui-disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
    boxShadow: "0 0 0 0 rgba(0, 127, 255, 0)",
  },
})));
