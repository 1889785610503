/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/**
 * Marker type for Promises (or other things) that can be cancelled.
 */
export type Cancelable = {
  cancel(): void
};

/**
 * Extends the given promise with a `.cancel()` function. When called, `.abort()` is invoked at the given abortController.
 *
 * Background: Some libraries, like react-query require a promise to have a .cancel() function to abort them.
 *
 * WARNING: the .cancel() function gets lost when you call then/catch on your promise.
 * @param promise the promise to cancel
 * @param abortController the abort controller to use
 * @param onCancel callback that's called right after abortController.abort() was invoked
 */
export function makeCancelable<T>(promise: Promise<T>, abortController?: AbortController, onCancel?: () => void): Cancelable & Promise<T> {
  // widen type to add cancel method
  const p: any = promise;
  if (abortController) {
    p.cancel = () => {
      abortController.abort();
      onCancel?.();
    };
  }
  return p;
}

/**
 * Alternative to {makeCancelable}, that provides an abort controller that can be used within the promise.
 *
 * WARNING: the .cancel() function gets lost when you call then/catch on your promise.
 * @param promiseCreator function to create the promise to cancel
 * @param onCancel callback that's called right after abortController.abort() was invoked
 */
export function createCancelable<T>(promiseCreator: (abortController: AbortController) => Promise<T>, onCancel?: () => void): Cancelable & Promise<T> {
  const abortController = new AbortController();
  const promise = promiseCreator(abortController);
  return makeCancelable(promise, abortController, onCancel);
}
