/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {
  ActivateTabGroup,
  AddExistingNotification,
  AddNewNotification,
  ChangeSingleView,
  CloseSideBar,
  CloseTab,
  DeleteNotification,
  MarkNotificationAsAnnounced,
  MarkNotificationAsRead,
  OpenSideBar,
  OpenTab,
  ResetUiState,
  InitPerspective,
  SelectTab,
  SplitTabGroup,
  UpdateTabbedPerspectiveSizes,
  UpdateViewModel,
} from "./actions/";
import {PopBackSingleView} from "./actions/PopBackSingleView";
import {SetConnectionStatus} from "./actions/SetConnectionStatus";
import {DEFAULT_SERIAL_VERSION, IUiState} from "./types";

export const INITIAL_UI_STATE: IUiState = {

  serialVersion: DEFAULT_SERIAL_VERSION,

  notifications: [],

  /** @see updateViewStateFn */
  metadata: {},

  connected: false,
};

// The idea of the view state is to exchange data between different views and components with loose coupling.
// This is solved by putting key value pairs in a global place (accessible by all components) in the redux store
//
// The initial use case was the propagation of pending invitations and unread chat messages from the chat module to the global menu
const updateViewStateFn = (
  state: IUiState,
  action: PayloadAction<{key: string, setValue?: ((prev?: unknown) => unknown | undefined) | unknown }>
) => {
  const {key, setValue} = action.payload;
  const prev = state.metadata[key];
  const newValue: unknown | undefined = typeof setValue === "function" ? setValue(prev) : setValue;
  if (newValue !== undefined) {
    state.metadata[key] = newValue;
  } else {
    delete state.metadata[key];
  }
};

const {reducer, actions} = createSlice({
  name: "@@ui",
  initialState: INITIAL_UI_STATE,
  reducers: {
    openTab: OpenTab,
    changeSingleView: ChangeSingleView,
    popBackSingleView: PopBackSingleView,
    selectTab: SelectTab,
    closeTab: CloseTab,
    splitTabGroup: SplitTabGroup,
    activateTabGroup: ActivateTabGroup,
    initPerspective: InitPerspective,
    resetUiState: ResetUiState,
    openSideBar: OpenSideBar,
    closeSideBar: CloseSideBar,
    updateViewData: UpdateViewModel,
    updateViewState: updateViewStateFn,
    // updateViewStateAcrossTabs can only receive serializable data as it is propagated to other ICM browser tabs
    updateViewStateAcrossTabs: updateViewStateFn,
    addNewNotification: AddNewNotification,
    addExistingNotification: AddExistingNotification,
    markNotificationAsRead: MarkNotificationAsRead,
    markNotificationsAsAnnounced: MarkNotificationAsAnnounced,
    deleteNotifications: DeleteNotification,
    updateTabbedPerspectiveSizes: UpdateTabbedPerspectiveSizes,
    setConnectionStatus: SetConnectionStatus,
  },
});

export const {
  openTab,
  changeSingleView,
  popBackSingleView,
  selectTab,
  closeTab,
  splitTabGroup,
  activateTabGroup,
  initPerspective,
  resetUiState,
  openSideBar,
  closeSideBar,
  addNewNotification,
  addExistingNotification,
  markNotificationAsRead,
  markNotificationsAsAnnounced,
  deleteNotifications,
  updateViewState,
  updateViewStateAcrossTabs,
  updateViewData,
  updateTabbedPerspectiveSizes,
  setConnectionStatus,
} = actions;
export {reducer as uiReducer};
