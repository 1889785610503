/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {AuthenticationAdapterName} from "../../constant/AuthenticationAdapterName";
import {SecurityConfiguration} from "../../generated/api";
import {NavigationService} from "../NavigationService";
import {AuthenticationService} from "./AuthenticationService";
import {KeycloakAuthenticationService} from "./keycloak/KeycloakAuthenticationService";
import {LocalAuthenticationService} from "./local/LocalAuthenticationService";
import {LoginStorageService} from "./LoginStorageService";


const getAuthenticationAdapterType = (configuration: SecurityConfiguration | undefined): string | undefined => {
  return configuration?.keycloakAuthenticationAdapter?.type || configuration?.localAuthenticationAdapter?.type;
};

export const isAuthenticationAdapter = (configuration: SecurityConfiguration | undefined, type: string): boolean => {
  const adapterType = getAuthenticationAdapterType(configuration);
  return (adapterType && adapterType.toLowerCase() === type.toLowerCase()) || false;
};

export const createAuthenticationService = (securityConfiguration: SecurityConfiguration,
                                            loginStorage: LoginStorageService,
                                            navigationService: NavigationService): AuthenticationService => {
  console.info("Using authentication service:", getAuthenticationAdapterType(securityConfiguration));
  if (isAuthenticationAdapter(securityConfiguration, AuthenticationAdapterName.KEYCLOAK)) {
    return new KeycloakAuthenticationService(securityConfiguration, navigationService);
  } else {
    return new LocalAuthenticationService(securityConfiguration, loginStorage, navigationService);
  }
};
