/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FormWidgetProps, useFormGeneratorContext, useFormWidgetProps, getWidgetFactory} from "@icm/core-common";
import {Grid} from "@mui/material";
import {GridSize} from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import React, {FC} from "react";

import {styles} from "./WebFormField.style";

const useStyles = makeStyles(styles);

export const WebFormWidget: FC<FormWidgetProps> = (props) => {
  const {group, widget} = props;
  const {evaluateRule} = useFormGeneratorContext();
  const classes = useStyles();
  const visible = evaluateRule(widget.visible, true);
  const formWidgetProps = useFormWidgetProps(group, widget);
  // when calculating values for different screen sizes, make sure to use multiples of 3, in order to make sure
  // that a full-width form stays a full-width form also on smaller screen sizes. See https://material-ui.com/components/grid/
  const lg = (widget.width && widget.width >= 1 && widget.width <= 12 ? Math.round(widget.width) : 3) as GridSize;
  const md = lg < 3 ? 3 : lg < 6 ? 6 : 12;
  const sm = lg < 6 ? 6 : 12;
  const xs = 12;

  function buildWidget() {
    const Widget = getWidgetFactory(widget.widgetType);
    if (Widget) {
      return <Widget {...formWidgetProps} />;
    } else {
      return <span>Widget {widget.widgetType} not supported</span>;
    }
  }

  return (
    <Grid item={true}
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          className={clsx({
            [classes.fieldContainer]: true,
            // no min-height for label component
            [classes.fieldVisible]: visible,
            [classes.fieldInvisible]: !visible,
          })}
    >
      {visible && (
        <>{buildWidget()}</>
      )}
    </Grid>
  );
};
