/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  getRowStatus,
  isFromMe,
  ObjectUpdate,
  ObjectWithUpdateInformation,
  PropertyUpdateUnion, useContextMenu,
  useEntityAction,
  useListActions,
} from "@icm/activitystream-common";
import {
  ActionContextValue,
  ActionRunners,
  ArrayUtilities,
  createValueAccessor,
  DataTableIconsColumn,
  DataTableTextColumn,
  DataTableTextOrIconsColumn,
  evaluateIconVisibility,
  ExpressionEvaluationService,
  formatService,
  Icon,
  IdGenerator,
  ListConfiguration,
  NormalizedInfiniteData,
  RowIconGroupElement,
  RowStatus,
  RowTextElement,
  SecurityService,
  Sorting,
  SortOrder,
  UpdateFilterSorting,
  useService,
} from "@icm/core-common";
import {
  isRowIconGroupElement,
  LoadingSpinner,
  TableConfig,
  useRowElements,
  useVisibleElementsFilter,
  VirtualizedInfiniteTable,
} from "@icm/core-web";
import * as React from "react";
import {useCallback, useMemo} from "react";

import {useDetailPanelRenderer} from "./useDetailPanelRenderer";


type InfiniteListGeneratorProps<T extends {}> = {
  // pageSize is used for fetching next entities
  listConfig: ListConfiguration
  infiniteData: NormalizedInfiniteData<ObjectWithUpdateInformation<T>>
  actionHandlers?: ActionRunners
  actionContext?: ActionContextValue
  activeSorting?: Sorting
  externalSort: UpdateFilterSorting
  markAsSeen?: (object: T[]) => void
  isUpdateRelevant?: (update: ObjectUpdate<T>, rowTextElement: RowTextElement) => boolean
}

const getLatest = <T extends {}>(row: ObjectWithUpdateInformation<T>) => ArrayUtilities.last(row.updateInfo?.objectUpdates)?.object ?? row.object;

export const UpdatingInfiniteListGenerator = <T extends {}>(props: InfiniteListGeneratorProps<T>) => {
  const {
    listConfig,
    infiniteData,
    activeSorting,
    externalSort,
    actionHandlers,
    actionContext,
    markAsSeen,
    isUpdateRelevant,
  } = props;

  const securityService = useService("SECURITY");

  const getRowHeaderColor = useMemo(
    () => listConfig.rowColorDisplay ? (row: ObjectWithUpdateInformation<T> | T) => {
      const latestRow = resolveRow(row);
      return rowHeaderColor(listConfig.rowColorDisplay!, latestRow);
    } : undefined,
    [listConfig.rowColorDisplay]
  );

  const isVisible = useVisibleElementsFilter();

  const rowElements = useRowElements(listConfig, isVisible);

  const textRowElements = useMemo(
    () => {
      const rowTextElements = rowElements.TEXT_AND_ICON;
      return mapToDataColumns<T>(rowTextElements, externalSort, securityService, activeSorting, isUpdateRelevant);
    },
    [activeSorting, rowElements.TEXT_AND_ICON, externalSort, isUpdateRelevant, securityService]
  );

  const rowActions = useMemo(() => rowElements.ACTION.filter(isVisible), [isVisible, rowElements]);
  const actions = useListActions(rowActions, actionHandlers, actionContext);
  const onClickRowAction = useEntityAction(rowElements.ON_CLICK_ACTION, actionHandlers);
  const contextMenu = useContextMenu(listConfig.rowConfiguration?.menu);

  const fixOrderAndConfirmChanges = useCallback(() => {
    const idsWithUpdates = infiniteData?.data?.filter(e => !!e.updateInfo)
      .map(e => e.object);
    if (markAsSeen && idsWithUpdates) {
      markAsSeen(idsWithUpdates);
    }
    if (!infiniteData.orderValid) {
      externalSort(activeSorting);
    }
  }, [activeSorting, externalSort, infiniteData?.data, infiniteData.orderValid, markAsSeen]);

  const rowStates = useMemo((): Record<string, RowStatus | undefined> => {
    if (infiniteData.data) {
      return infiniteData.data.reduce((acc, row) => {
        acc[row.id] = getRowStatus(row, securityService);
        return acc;
      }, {});
    }
    return {};
  }, [infiniteData.data, securityService]);

  const detailPanelRenderer = useDetailPanelRenderer<T>(rowElements);

  const tableConfig: TableConfig<ObjectWithUpdateInformation<T>> = useMemo(() => ({
    getRowEntity: (row) => row.object,
    dataColumns: textRowElements,
    getRowHeaderColor,
    getRowStatus: (row) => rowStates[row.id],
    rowActions: actions,
    onClickRowAction,
    contextMenu,
    markRowAsSeen: markAsSeen ? (row => markAsSeen([row.object])) : undefined,
    getTableStatus: () => {
      if (!infiniteData.orderValid) {
        return "INVALID_ORDER";
      } else {
        return Object.values(rowStates)
          .find(rs => !!rs) !== undefined ? "UNSEEN_CHANGES" : undefined;
      }
    },
    showColumnHeaders: listConfig.showColumnHeaders ?? true,
    fixOrderAndConfirmChanges,
    detailPanelRenderer,
  }), [
    textRowElements,
    getRowHeaderColor,
    actions,
    markAsSeen,
    fixOrderAndConfirmChanges,
    detailPanelRenderer,
    rowStates,
    infiniteData.orderValid,
    onClickRowAction,
    listConfig.showColumnHeaders,
    contextMenu,
  ]);

  if (infiniteData) {
    return (
      <VirtualizedInfiniteTable<ObjectWithUpdateInformation<T>> infiniteData={infiniteData}
                                                                tableConfig={tableConfig}
      />
    );
  } else {
    return <LoadingSpinner />;
  }
};

function rowHeaderColor<T>(rowColorDisplay: string, row: T) {
  return ExpressionEvaluationService.evaluate(rowColorDisplay, row);
}

function mapToIconGroupColumn<T extends {}>(element: RowIconGroupElement): DataTableIconsColumn<ObjectWithUpdateInformation<T>> {
  const getIcons = (row: ObjectWithUpdateInformation<T>): Icon[] => element.icons?.filter(icon => evaluateIconVisibility(icon, row.object)) ?? [];

  return {
    headerText: element.label ?? "",
    type: "ICONS",
    name: element.label ?? IdGenerator.randomUUID(),
    getIcons,
    sortable: false,
  };
}

function mapToDataTextColumn<T extends {}>(
  element: RowTextElement,
  activeSorting: Sorting | undefined,
  externalSort: (sorting?: Sorting) => void,
  securityService: SecurityService,
  isUpdateRelevant: ((update: ObjectUpdate<T>, column: RowTextElement) => boolean) | undefined
): DataTableTextColumn<ObjectWithUpdateInformation<T>> {
  const originalValueAccessor = createValueAccessor({
    valueBinding: element.valueBinding,
    valueDisplay: element.valueDisplay,
    textElement: element,
  });
  const latestValueAccessor = (row: ObjectWithUpdateInformation<T>) => originalValueAccessor(getLatest(row));
  const sortable = element.sortable !== "false";
  const formatValue = (value: unknown) => formatService.format(value, element.format, "FULL");

  return {
    headerText: element.label,
    type: "TEXT",
    name: element.name || element.valueBinding!,
    valueBinding: element.valueBinding!,
    format: element.format!,
    formatValue,
    valueAccessor: latestValueAccessor,
    sortable,
    sortOrder: sortable && activeSorting && activeSorting.property === element.valueBinding ? activeSorting.order : undefined,
    sort: sortable
      ? (order: SortOrder) => externalSort({
        property: element.valueBinding,
        order,
      })
      : undefined,
    component: element.component,
    onClickAction: element.onClickAction,
    icon: element.icon,
    getCellStatus: row => {
      if (row.updateInfo && row.updateInfo.objectUpdates.length > 0) {
        return row.updateInfo.objectUpdates.filter(
          lastUpdate => !isFromMe(lastUpdate, securityService) && (!row.updateInfo!.showUpdatesSince || lastUpdate.date > row.updateInfo!.showUpdatesSince)
        )
          .find(lastUpdate => {
            if (isUpdateRelevant) {
              return isUpdateRelevant(lastUpdate, element);
            } else {
              const relevantPropertyUpdate = lastUpdate.propertyUpdates?.find(pu => getValueBinding(pu) === element.valueBinding);
              return !!relevantPropertyUpdate;
            }
          }) ? "UPDATED" : undefined;
      }
      return undefined;
    },
  };
}

function mapToDataColumns<T extends {}>(
  rowTextElements: (RowTextElement | RowIconGroupElement)[],
  externalSort: UpdateFilterSorting,
  securityService: SecurityService,
  activeSorting?: Sorting,
  isUpdateRelevant?: (update: ObjectUpdate<T>,
    column: RowTextElement,
  ) => boolean
): DataTableTextOrIconsColumn<ObjectWithUpdateInformation<T>>[] {
  return rowTextElements.map(element => {
    if (isRowIconGroupElement(element)) {
      return mapToIconGroupColumn(element);
    }
    return mapToDataTextColumn(element, activeSorting, externalSort, securityService, isUpdateRelevant);
  });
}

function getValueBinding(pu: PropertyUpdateUnion): string | undefined {
  if (pu.updateType === "VALUE" || pu.updateType === "COLLECTION") {
    return pu.fieldReference?.valueBinding;
  }
  return undefined;
}

const resolveRow = (object: any): any => isObjectWithUpdateInformation(object) ? getLatest(object) : object;

const isObjectWithUpdateInformation = (object: any): object is ObjectWithUpdateInformation<any> => {
  return "object" in object;
};
