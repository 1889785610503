/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {setEntityUpdateAsSeen} from "@icm/activitystream-common";
import {
  feedbackService,
  FetchServiceException,
  FormMessagesHelper,
  GeneralError,
  GenericFormConfigurationAndVariantName,
  ICoreApplicationState,
  IdGenerator,
} from "@icm/core-common";
import {CompleteEntityTaskCommand, StartEntityProcessCommand} from "@icm/workflow-common";
import {createAsyncThunk} from "@reduxjs/toolkit";

import {EntityCommandApi} from "../../api";
import {EntitySaveParams} from "../../components";
import {EntityMetadata} from "../../data";
import {EntityCommand, UpdateEntityCommand} from "../../generated/api";
import {EntityEditModel, MAKE_READONLY_AFTER_SUBMIT, RELOAD_AFTER_SUBMIT, SubmitStrategy} from "../types";
import {ReloadEntityEditModel} from "./ReloadEntityEditModel";


export type SubmitEntityEditModelParam = {
  entityId: string
  submitStrategy: SubmitStrategy
  formConfiguration?: GenericFormConfigurationAndVariantName
  parameters?: EntitySaveParams
}

const getCommands = (editModel: EntityEditModel, parameters?: EntitySaveParams): EntityCommand[] => {
  const entity = editModel.entity;
  const entityType = entity.type!;
  const entityId = entity.id!;

  const result: EntityCommand[] = [];

  const commandId = IdGenerator.randomUUID();
  const updateCommand: UpdateEntityCommand = {
    entityCommandType: "UPDATE",
    id: commandId,
    predecessorIds: entity.version ? [entity.version] : [],
    entityId,
    entityType,
    timestamp: new Date(),
    propertyChanges: editModel.propertyChanges,
  };
  result.push(updateCommand);

  const processDefinitionKey = entity?.metadata?.[EntityMetadata.START_PROCESS];
  if (processDefinitionKey) {
    const startProcessCommand: StartEntityProcessCommand = {
      entityCommandType: "START_PROCESS",
      id: IdGenerator.randomUUID(),
      predecessorIds: [commandId],
      entityId,
      entityType,
      timestamp: new Date(),
      processDefinitionKey: processDefinitionKey,
    };
    // @ts-ignore
    result.push(startProcessCommand);
  }

  if (parameters?.completeTaskId) {
    const completeTaskCommand: CompleteEntityTaskCommand = {
      entityCommandType: "COMPLETE_TASK",
      id: IdGenerator.randomUUID(),
      predecessorIds: [commandId],
      entityId,
      entityType,
      timestamp: new Date(),
      taskId: parameters.completeTaskId,
      userActionId: parameters.completeTaskUserActionId,
    };
    // @ts-ignore
    result.push(completeTaskCommand);
  }

  return result;
};

/**
 * Reducer (ActionHandler) to submitEntityEditModel the current entity model to the server.
 */
export const SubmitEntityEditModel = createAsyncThunk("@@entity/submitEntityModel",
  async (submitParameters: SubmitEntityEditModelParam, api) => {
    const applicationState = api.getState() as ICoreApplicationState;
    const editModel = applicationState.entityEditState.editModels[submitParameters.entityId];

    if (editModel) {
      const entity = editModel.entity;

      // assert type and id because we should not store edit models without
      const entityType = entity.type!;
      const entityId = entity.id!;

      const commands = getCommands(editModel, submitParameters.parameters);
      console.debug("Sending command", commands);
      try {
        const result = await EntityCommandApi.processEntityCommands(commands, submitParameters.formConfiguration?.variantName);

        if (submitParameters.submitStrategy === RELOAD_AFTER_SUBMIT) {
          api.dispatch(ReloadEntityEditModel({entityId, entityType, lock: false}));
        } else if (submitParameters.submitStrategy === MAKE_READONLY_AFTER_SUBMIT) {
          api.dispatch(ReloadEntityEditModel({entityId, entityType, lock: true}));
        }

        const message = FormMessagesHelper.getSuccessMessage(submitParameters.formConfiguration, {result: result});
        feedbackService.open({
          title: message.text,
          variant: message.variant,
          duration: "SHORT",
          key: "FORM_SAVE_SUCCESS",
        });

        api.dispatch(setEntityUpdateAsSeen({type: entityType, idOrIds: entityId}));

        return result;
      } catch (e) {
        let message;
        try {
          const response = (e as FetchServiceException).response;
          const statusCode = response?.status;
          const error: GeneralError = await response?.json();
          message = FormMessagesHelper.getErrorMessage(submitParameters.formConfiguration, {statusCode: statusCode, error: error});
        } catch {
          message = FormMessagesHelper.getErrorMessage(submitParameters.formConfiguration, {});
        }
        feedbackService.open({
          title: message.text,
          variant: message.variant,
          duration: "SHORT",
          key: "FORM_SAVE_FAILED",
        });
      }
    }

    throw new Error(`Unknown entityId ${submitParameters.entityId}`);
  });
