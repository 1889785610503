/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {LinearProgress, LinearProgressProps} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import * as React from "react";

import {IcmMuiTheme} from "../../theme";

const useStyles = makeStyles((_theme: IcmMuiTheme) => createStyles({
  root: {
    height: 2,
  },
  hidden: {
    "&, $reserveSpace": {
      visibility: "hidden",
    },
    "&:not($reserveSpace)": {
      display: "none",
    },
  },
  reserveSpace: {
  },
}));


type ProgressBarProps = {
  /**
   * @default true
   */
  visible?: boolean
  /**
   * @default true
   */
  reserveSpaceWhenHidden?: boolean
} & LinearProgressProps;

export const LoadingBar = ({visible = true, reserveSpaceWhenHidden = true, className, ...props}: ProgressBarProps) => {
  const classes = useStyles();
  return (
    <LinearProgress {...props}
                    className={clsx({
                      [classes.root]: true,
                      [classes.hidden]: !visible,
                      [classes.reserveSpace]: reserveSpaceWhenHidden,
                      [className ?? ""]: !!className,
                      dontPrint: true,
                    })}
    />
  );
};
