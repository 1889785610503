/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:11.

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.AuditlogEntityDto
 */
export class AuditlogEntity {
    entityId?: string;
    fullyQualifiedClassName?: string;
    label?: string;
    labelSuffix?: string;
    properties?: Property[];
    references?: Reference[];
    entityChanges?: EntityChange[];
    metaData?: any;

    static fromData(data: any): AuditlogEntity {
        if (!data) {
            return data;
        }
        const instance: AuditlogEntity = { entityId: data.entityId && __verifyIsString(data.entityId), fullyQualifiedClassName: data.fullyQualifiedClassName && __verifyIsString(data.fullyQualifiedClassName), label: data.label && __verifyIsString(data.label), labelSuffix: data.labelSuffix && __verifyIsString(data.labelSuffix), properties: data.properties && __getCopyArrayFn(Property.fromData)(data.properties), references: data.references && __getCopyArrayFn(Reference.fromData)(data.references), entityChanges: data.entityChanges && __getCopyArrayFn(EntityChange.fromData)(data.entityChanges), metaData: data.metaData && data.metaData };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityId','fullyQualifiedClassName','label','labelSuffix','properties','references','entityChanges','metaData'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.ChangeInformationDto
 */
export class ChangeInformation {
    id?: number;
    correlationId?: string;
    changedAt?: Date;
    changedBy?: string;
    clientHostname?: string;

    static fromData(data: any): ChangeInformation {
        if (!data) {
            return data;
        }
        const instance: ChangeInformation = { id: data.id && __verifyIsNumber(data.id), correlationId: data.correlationId && __verifyIsString(data.correlationId), changedAt: data.changedAt && new Date(data.changedAt), changedBy: data.changedBy && __verifyIsString(data.changedBy), clientHostname: data.clientHostname && __verifyIsString(data.clientHostname) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','correlationId','changedAt','changedBy','clientHostname'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.EntityChangeDto
 */
export class EntityChange {
    operationType?: OperationTypeEnum;
    changeInformation?: number;

    static fromData(data: any): EntityChange {
        if (!data) {
            return data;
        }
        const instance: EntityChange = { operationType: data.operationType && data.operationType, changeInformation: data.changeInformation && __verifyIsNumber(data.changeInformation) };
        return instance;
    }

    static getProperties(): string[] {
        return ['operationType','changeInformation'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.EntityHistoryDto
 */
export class EntityHistory {
    entity?: AuditlogEntity;
    changeInformations?: ChangeInformation[];
    createdAt?: Date;

    static fromData(data: any): EntityHistory {
        if (!data) {
            return data;
        }
        const instance: EntityHistory = { entity: data.entity && AuditlogEntity.fromData(data.entity), changeInformations: data.changeInformations && __getCopyArrayFn(ChangeInformation.fromData)(data.changeInformations), createdAt: data.createdAt && new Date(data.createdAt) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entity','changeInformations','createdAt'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.PropertyChangeDto
 */
export class PropertyChange {
    newValue?: any;
    oldValue?: any;
    newLabels?: string[];
    oldLabels?: string[];
    changeInformation?: number;

    static fromData(data: any): PropertyChange {
        if (!data) {
            return data;
        }
        const instance: PropertyChange = { newValue: data.newValue && data.newValue, oldValue: data.oldValue && data.oldValue, newLabels: data.newLabels && __getCopyArrayFn(__identity<string>())(data.newLabels), oldLabels: data.oldLabels && __getCopyArrayFn(__identity<string>())(data.oldLabels), changeInformation: data.changeInformation && __verifyIsNumber(data.changeInformation) };
        return instance;
    }

    static getProperties(): string[] {
        return ['newValue','oldValue','newLabels','oldLabels','changeInformation'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.PropertyDto
 */
export class Property {
    name?: string;
    type?: string;
    label?: string;
    changes?: PropertyChange[];

    static fromData(data: any): Property {
        if (!data) {
            return data;
        }
        const instance: Property = { name: data.name && __verifyIsString(data.name), type: data.type && __verifyIsString(data.type), label: data.label && __verifyIsString(data.label), changes: data.changes && __getCopyArrayFn(PropertyChange.fromData)(data.changes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['name','type','label','changes'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.ReferenceDto
 */
export class Reference {
    referencedObject?: AuditlogEntity;
    changes?: ReferralChange[];

    static fromData(data: any): Reference {
        if (!data) {
            return data;
        }
        const instance: Reference = { referencedObject: data.referencedObject && AuditlogEntity.fromData(data.referencedObject), changes: data.changes && __getCopyArrayFn(ReferralChange.fromData)(data.changes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['referencedObject','changes'];
    }
}

/**
 * Generated from: at.cns.icm.auditlog.backend.api.data.ReferralChangeDto
 */
export class ReferralChange {
    referralChange?: ReferralChangeEnum;
    changeInformation?: number;

    static fromData(data: any): ReferralChange {
        if (!data) {
            return data;
        }
        const instance: ReferralChange = { referralChange: data.referralChange && data.referralChange, changeInformation: data.changeInformation && __verifyIsNumber(data.changeInformation) };
        return instance;
    }

    static getProperties(): string[] {
        return ['referralChange','changeInformation'];
    }
}

export type OperationTypeEnum = "CREATE" | "PROPERTY_UPDATE" | "REFERENCE_ADDED" | "REFERENCE_REMOVED" | "DELETE";

export type ReferralChangeEnum = "REFERRAL" | "DEREFERRAL";

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
