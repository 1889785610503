/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FilterSelection} from "../generated/api";
import {FetchService, FetchServiceException} from "../service";
import {ApiUtil} from "./ApiUtil";

export class FilterSelectionApi {
  public static postFilterSelection(filterSelection: FilterSelection): Promise<string> {
    return FetchService.performPost("core/filterSelections", filterSelection)
      .then(response => response.text())
      .catch(async (ex: FetchServiceException) => {
        throw await ex.response?.json();
      });
  }

  public static getFilterSelectionByEntityType(entityType?: string): Promise<FilterSelection[]> {
    return FetchService.performGet("core/filterSelections?" + (entityType ? `entityType=${entityType}` : ""))
      .then(json => ApiUtil.convertArray(json, FilterSelection.fromData));
  }

  public static deleteFilterSelection(id: string): Promise<boolean> {
    return FetchService.performDelete(`core/filterSelections/${id}`)
      .then(response => response.json());
  }
}
