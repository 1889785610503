/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SnackbarProvider} from "notistack";
import * as React from "react";
import {PropsWithChildren} from "react";

import {useTheme} from "../../theme";
import {styles} from "./FeedbackBanner.style";
import {FeedbackContextProvider} from "./FeedbackContextProvider";

export const FeedbackBannerProvider = ({children}: PropsWithChildren<{}>) => {
  const theme = useTheme();

  return (
    <SnackbarProvider anchorOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
                      maxSnack={5}
                      className="icm-feedback-snackbar"
                      classes={{
                        variantInfo: styles.variantInfo(theme.palette.info.main),
                      }}
    >
      <FeedbackContextProvider>
        {children}
      </FeedbackContextProvider>
    </SnackbarProvider>
  );
};
