/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {BaseViewModelData, IViewProps, ParameterUtilities} from "@icm/core-common";
import * as React from "react";

/**
 * Super class for Views
 *
 * Provides convenience methods to access ViewParams.
 */
export abstract class View<
    P extends IViewProps<ViewModelData>,
    ViewModelData extends BaseViewModelData,
    S = {},
    ViewParameter extends string = "",
    SS = any
  > extends React.Component<P & IViewProps<ViewModelData>, S, SS> {

  protected getViewParam(key: ViewParameter): any | undefined {
    return ParameterUtilities.getResolvedParameter(key, this.props.viewParameters?.genericParameters);
  }

  protected didViewParametersChange(prevProps: Readonly<P & IViewProps<ViewModelData>>, ...keys: ViewParameter[]): boolean {
    for (const key of keys) {
      const currentValue = this.getViewParam(key);
      const prevValue = ParameterUtilities.getResolvedParameter(key, prevProps.viewParameters?.genericParameters);
      if (currentValue !== prevValue) {
        return true;
      }
    }
    return false;
  }
}
