/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMemo} from "react";

import {DataTableContextDefinition, TableConfig} from "../common";
import {useActionbarOffset} from "./useActionBarOffset";
import {useHasVerticalScrollbars} from "./useHasVerticalScrollbars";

export type FlexDataTableContextDefinition = DataTableContextDefinition & {
  hasVerticalScrollbars: boolean
}

export const useFlexDataTableContext = <T>(tableConfig: TableConfig<T>, table: HTMLElement | null) => {
  const hasVerticalScrollbars = useHasVerticalScrollbars(table);
  const actionBarOffset = useActionbarOffset(table);
  const context: DataTableContextDefinition<T> = useMemo(() => ({
    tableConfig,
    variant: "FLEX",
    actionBarOffset,
  }), [tableConfig, actionBarOffset]);

  return {
    ...context,
    hasVerticalScrollbars,
  };
};
