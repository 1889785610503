/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {splitTabGroup} from "@icm/core-common";
import {useMemo} from "react";
import {useDispatch} from "react-redux";

import {TabGroupDescriptor} from "./useTabGroupDescriptor";

/**
 * A thing that can be used to split a tab group.
 */
export type TabGroupSplitter = {
  enabled: boolean
  split: () => void
}

/**
 * Returns an object that can be used to the given tab into a new tab group.
 *
 * @param groupDescriptor
 */
export const useTabGroupSplitter = (groupDescriptor: TabGroupDescriptor): TabGroupSplitter => {
  const dispatch = useDispatch();
  return useMemo(() => {
    const enabled = groupDescriptor.canSplit;
    return {
      enabled,
      split: () => {
        if (enabled) {
          dispatch(splitTabGroup({tabGroupId: groupDescriptor.id, tabId: groupDescriptor.selectedTab.id}));
        }
      },
    };
  }, [dispatch, groupDescriptor]);
};
