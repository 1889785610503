/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiIcon} from "@icm/core-web";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as React from "react";

import {CompleteProcessTaskCommonsProps} from "./types";
import {useCompleteProcessTaskCommons} from "./useCompleteProcessTaskCommons";


export const CompleteProcessTaskMenuItem = (props: CompleteProcessTaskCommonsProps) => {
  const {visible, handleClick, menu} = useCompleteProcessTaskCommons(props);

  if (visible) {
    return (
      <MenuItem onClick={handleClick} disabled={props.disabled}>
        <ListItemIcon>
          {typeof props.icon === "string" ? <IcmMuiIcon name={props.icon as string} size="small" /> : props.icon}
        </ListItemIcon>
        <ListItemText>{props.text}</ListItemText>
        {menu}
      </MenuItem>
    );
  } else {
    return (<span />);
  }
};

export const completeProcessTaskMenuItemFactory = (props: CompleteProcessTaskCommonsProps) => <CompleteProcessTaskMenuItem {...props} />;
