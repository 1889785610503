/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as FileSaver from "file-saver";
import Cookies from "js-cookie";

import {SecurityService} from ".";
import {UserAgentParser} from "../util/UserAgentParser";

type LogLevel = "log" | "error" | "warn" | "debug" | "request";

type Log = {
  level: LogLevel
  timestamp: string
  values: string[]
}

/**
 * Collects log files and provides download functionality for a report.
 */
export class LogReportService {
  private static logCount = 0;
  private static readonly logs: Log[] = [];

  /**
   * Opens save as dialog with the log report.
   */
  public static async downloadLogReport(securityService: SecurityService) {
    const parser = new UserAgentParser();
    const content = JSON.stringify({
      currentUser: (await securityService.getCurrentUserDetails())?.userName,
      activeRoleNames: securityService.getActiveRolesNameString(),
      userAgent: parser.getAllParsableInformation(),
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
      environment: process.env,
      browserStorage: {
        cookies: Cookies.get(),
        sessionStorage,
        localStorage,
      },
      totalLogs: LogReportService.logCount,
      logs: LogReportService.logs,
      logsAsText: LogReportService.logs.map(log => `${log.timestamp} | ${log.level.padEnd(7)} | ${log.values.join(" ")}`),
    }, null, 2);
    FileSaver.saveAs(new Blob([content], {type: "application/json;charset=utf-8"}), `report_${new Date().toISOString()}.json`);
  }

  /**
   * Logs only into the log report, not on the console.
   * @param level log level
   * @param args log message
   */
  public static log(level: LogLevel, ...args: any) {
    LogReportService.logCount++;
    LogReportService.logs.push({
      level,
      timestamp: new Date().toISOString(),
      values: args.map((arg: any) => {
        try {
          return typeof arg === "string" ? arg : JSON.stringify(arg);
        } catch (e) {
          return `failed to stringify arg: ${JSON.stringify(e)} (${typeof arg})`;
        }
      }),
    });
    while (LogReportService.logs.length >= 200) {
      LogReportService.logs.splice(0, 1);
    }
  }

  public static init() {
    LogReportService.init = () => {
    };
    if (process.env.NODE_ENV === "production") {
      const defaultLog = console.log.bind(console);
      console.log = (...args: any) => {
        defaultLog.apply(console, args);
        LogReportService.log("log", ...args);
      };
      const defaultError = console.error.bind(console);
      console.error = (...args: any) => {
        defaultError.apply(console, args);
        LogReportService.log("error", ...args);
      };
      const defaultWarn = console.warn.bind(console);
      console.warn = (...args: any) => {
        defaultWarn.apply(console, args);
        LogReportService.log("warn", ...args);
      };
      const defaultDebug = console.debug.bind(console);
      console.debug = (...args: any) => {
        defaultDebug.apply(console, args);
        LogReportService.log("debug", ...args);
      };
    }
  }
}
