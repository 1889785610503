/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ActionRunners, BaseTableConfig, RowStatus} from "@icm/core-common";
import React, {useContext} from "react";

import {DetailPanelRenderer} from "./DetailPanelRenderer";


export interface DataTableContextDefinition<T = any> {
  actionHandlers?: ActionRunners;
  variant: "CLASSIC" | "FLEX";
  tableConfig: TableConfig<T>;
  actionBarOffset: number
}

export type TableConfig<T> = {
  // table
  getTableStatus?: () => "UNSEEN_CHANGES" | "INVALID_ORDER" | undefined
  fixOrderAndConfirmChanges?: () => void
  // rows
  getRowHeaderColor?: (row: T) => string | undefined
  getRowStatus?: (row: T) => RowStatus | undefined
  markRowAsSeen?: (row: T) => void
  detailPanelRenderer?: DetailPanelRenderer<T>
  getRowEntity?: (row: T) => any
  showColumnHeaders?: boolean
} & BaseTableConfig<T>;

export const DataTableContext = React.createContext<DataTableContextDefinition>({
  variant: "CLASSIC",
  tableConfig: {
    dataColumns: [],
    showColumnHeaders: true,
  },
  actionBarOffset: 0,
});

export const useDataTableContext = <T extends unknown>() => useContext(DataTableContext) as DataTableContextDefinition<T>;
