/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ClientFilterSelection} from "../../components";
import {isSharedFilterStateName, SharedFilterStateName} from "./SharedFilterStateName";

/**
 * State of the shared filter when nothing was found remotely.
 */
export const NOT_FOUND = "NOT_FOUND";


/**
 * Type that describes the properties of a filter which is shared between different views.
 */
export type SharedFilterState = {

  /** Type of the entity this filter can be applied to. */
  entityType: string,

  variant: string

  /** Collection of attributes and values */
  filterSelection: ClientFilterSelection | undefined,

  /** State the filter is currently in. */
  stateName: SharedFilterStateName
}

/**
 * Check if the given value is structurally compatible with a SharedFilterState.
 *
 * @param val
 */
export const isSharedFilterState = (val: any): val is SharedFilterState => {
  return val
   && typeof val === "object"
   && typeof val.entityType === "string"
   && typeof val.variant === "string"
   && isSharedFilterStateName(val.stateName);
};
