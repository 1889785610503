/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/


import {isString} from "lodash-es";
import moment from "moment";

import {dateService} from "../service";


export type DurationSign = "past" | "future";
export type DurationUnit = "y" | "M" | "w" | "d";
export type DurationItemType = "sign" | "number" | "unit";
export type DurationItemOrder = [DurationItemType, DurationItemType, DurationItemType];

function isOrderUnit(value: string): value is DurationItemType {
  switch (value) {
    case "sign":
    case "number":
    case "unit":
      return true;
    default:
      return false;
  }
}

function isOrder(units: DurationItemType[]): units is DurationItemOrder {
  const distinctUnits = Array.from(new Set(units));
  return distinctUnits.length === 3;
}

// Creates an ISO 8601 based time duration
export function getISO8601DurationString(
  sign: DurationSign,
  integerString: string | undefined,
  unit: DurationUnit
): string {
  const sanitizedInput = integerString?.replace(/\D/g, ""); // Replace all non integers.
  const integer = sanitizedInput ? Number.parseInt(sanitizedInput, 10) : 0;
  const prefix = sign === "past" ? "-" : "";
  const upperCaseUnit = unit.toUpperCase();
  return `${prefix}P${integer}${upperCaseUnit}`;
}

export function getSignFromValue(value: string | undefined): DurationSign | undefined {
  if (!value) {
    return undefined;
  }

  return value.includes("-") ? "past" : "future";
}

export function getUnitFromValue(value: string | undefined): DurationUnit | undefined {
  if (!value) {
    return undefined;
  }

  const unitChar: string = value[value.length - 1];
  let unit: DurationUnit = "d";
  if (unitChar === "M") {
    unit = unitChar;
  } else if (unitChar === "Y" || unitChar === "W" || unitChar === "D") {
    unit = (unitChar.toLowerCase() as DurationUnit);
  }

  return unit;
}

export function getInputFromValue(value: string | undefined, unit: DurationUnit): string | undefined {
  if (!value) {
    return undefined;
  }

  return Math.abs(moment.duration(value).as(unit)).toString();
}

/**
 * Converts a duration string (ISO 8601) to a date (at the start of the day) relative to now and
 * returns it as a string.
 *
 * @param value a string in ISO 8601 format
 */
export function getDateStringFromDuration(value: string | undefined): string | undefined {
  if (!value) {
    return undefined;
  }

  return dateService.formatDate(dateService.getStartOfDayFromNow(value));
}

/**
 * This function converts a string like "[sign, order, unit]" into it's typed array (DurationItemOrder) representation.
 *
 * @param order a string representing the order of the duration input element for an ISO 8601 component
 */
export function getDurationItemOrder(order: string | undefined): DurationItemOrder | undefined {
  if (!order) {
    return undefined;
  }

  try {
    const json = JSON.parse(order);

    if (Array.isArray(json)) {
      const strings = json.filter(isString);
      const orderUnits = strings.filter(isOrderUnit);

      if (isOrder(orderUnits)) {
        return orderUnits;
      }
    }
  } catch (e) {
    console.error(e);
  }

  console.error("This is an invalid order array: ", order);

  return undefined;
}
