/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {EvaluatedElement, ExpressionEvaluationService, isEvaluatedElement, ListKeyValueColumn} from "@icm/core-common";
import * as React from "react";

import {ListColumnRecord} from "./DataTableUtil";
import {DetailPanel} from "./DetailPanel";
import {DetailPanelRenderer} from "./DetailPanelRenderer";
import {DetailPanelRendererFactory} from "./DetailPanelRendererFactory";

export const KeyValueDetailPanelRendererFactory: DetailPanelRendererFactory = {
  createRenderer<T>(listElements: ListColumnRecord): DetailPanelRenderer<T> | undefined {
    const cols = listElements.KEY_VALUE;
    if (cols.length > 0) {
      return {
        hasDetails: (row: T) => {
          return cols.map(col => ({
            col,
            keys: ExpressionEvaluationService.get(row, col.keysBinding!, []),
          }))
            .map((container: { col: ListKeyValueColumn, keys: string[] }) => container.keys.map(key => {
              const label = ExpressionEvaluationService.evaluate(container.col.keyDisplay!, row, key);
              const value = ExpressionEvaluationService.evaluate(container.col.valueDisplay!, row, key);
              return [label, value];
            })
              .some(([label, value]: any) => label && value))
            .some((someValue: boolean) => someValue);
        },
        DetailPanel: ({row}: { row: T }) => {
          const labelAndValues = [].concat(...cols.map(col => ExpressionEvaluationService.get(row, col.keysBinding!, [])
            .map((key: string): EvaluatedElement => {
              const label = ExpressionEvaluationService.evaluate(col.keyDisplay!, row, key);
              const value = ExpressionEvaluationService.evaluate(col.valueDisplay!, row, key);
              const format = ExpressionEvaluationService.evaluate(col.valueFormatDisplay!, row, key);
              return {
                label,
                value,
                format,
              };
            })
            .filter(isEvaluatedElement)));

          if (labelAndValues.length > 0) {
            return <DetailPanel elements={labelAndValues} row={row} />;
          } else {
            return null;
          }
        },
      };
    }
    return undefined;
  },
};
