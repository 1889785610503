/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SecurityService} from "@icm/core-common";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

import {setEntityUpdateAsSeen} from "./reducer";
import {ObjectUpdate} from "./types";

export function isFromMe(objectUpdate: ObjectUpdate<any>, securityService: SecurityService) {
  return objectUpdate.actor?.userName === securityService.getCurrentUserDetails()?.userName;
}

/**
 * @return a function that marks the given entity as seen until the given date
 */
export const useMarkObjectAsSeen = () => {
  const dispatch = useDispatch();
  return useCallback((type: string, idOrIds: string | string[], seenUntil?: Date) => {
    dispatch(setEntityUpdateAsSeen({type, idOrIds, seenUntil}));
  }, [dispatch]);
};
