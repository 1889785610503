/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ICoreApplicationState, useService} from "@icm/core-common";
import {IcmMuiIcon} from "@icm/core-web";
import {TaskInstance, WorkflowUserAction} from "@icm/workflow-common";
import {Menu, MenuItem} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {useState} from "react";
import {useSelector} from "react-redux";

import {CompleteProcessTaskCommonsProps} from "./types";
import styles from "./useCompleteProcessTaskCommonsStyle";

const useStyles = makeStyles(styles);

type TaskUserAction = {
  task: TaskInstance,
  userAction: WorkflowUserAction,
}

const isAvailableForAnyRole = (task: TaskInstance, roleNames: string[]) => {
  if (roleNames.length > 0) {
    const candidateRoleNames = task.taskDefinition?.candidateRoleNames || [];
    const upperCaseRoleNames = roleNames.map(r => r.toUpperCase());
    return candidateRoleNames.findIndex(candidateRoleName => upperCaseRoleNames.includes(candidateRoleName.toUpperCase())) >= 0;
  } else {
    return false;
  }
};

const toTaskUserActions = (task: TaskInstance, userActions: WorkflowUserAction[]): TaskUserAction[] => {
  return userActions.map(userAction => ({task: task, userAction: userAction}));
};


export const useCompleteProcessTaskCommons = (props: CompleteProcessTaskCommonsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const securityService = useService("SECURITY");
  const classes = useStyles();

  const metadata: Record<string, any> = useSelector((state: ICoreApplicationState) => {
    if (props.entityId) {
      return state.entityEditState.editModels[props.entityId]?.entity.metadata;
    } else {
      return {};
    }
  }) || {};
  const activeTasks: TaskInstance[] = metadata["ACTIVE_TASKS"] || [];
  const activeTasksForCurrentRole: TaskInstance[] = activeTasks.filter(
    t => isAvailableForAnyRole(t, securityService.getActiveRoles().filter(r => !!r.name).map(r => r.name!))
  );
  const taskUserActionsForCurrentRole: TaskUserAction[] = activeTasksForCurrentRole
    .flatMap(task => toTaskUserActions(task, task.taskDefinition?.possibleUserActions || []));
  taskUserActionsForCurrentRole.sort((a, b) => a.userAction.name && b.userAction.name && a.userAction.name < b.userAction.name ? -1 : 1);

  const handleUserActionClick = (task: TaskInstance, userAction: WorkflowUserAction) => {
    setOpen(false);
    if (props.handleClick) {
      props.handleClick(task, userAction);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorElement(e.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorElement(null);
  };

  const menu = (
    <Menu
    id="basic-menu"
    open={open}
    onClose={handleClose}
    anchorEl={anchorElement}
    MenuListProps={{
      "aria-labelledby": "basic-button",
    }}
    >
      {taskUserActionsForCurrentRole.map(taskUserAction => {
        const {task, userAction} = taskUserAction;
        const margin = userAction.name && userAction.name.length > 0 && (userAction.nextTaskNames || []).length > 0 ? 10 : 0;
        return (
          <MenuItem key={userAction.id} onClick={() => handleUserActionClick(task, userAction)}>
            <div className={classes.actionName}>{userAction.name}</div>
            <div className={classes.nextTaskNamesContainer} style={{marginLeft: margin}}>
              {userAction.nextTaskNames?.map(nextTaskName => (
                <div className={classes.nextTaskNameBox} key={nextTaskName}>
                  <IcmMuiIcon name="arrow_forward" size="small" style={{paddingTop: 8}} />
                  <span>{nextTaskName}</span>
                </div>
              ))}
            </div>
          </MenuItem>
        );
      })}
    </Menu>
  );

  const visible = taskUserActionsForCurrentRole.length > 0;

  return {visible, menu, props, handleClick};
};
