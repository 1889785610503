/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../../theme";


export const styles = (theme: IcmMuiTheme) => createStyles({
  fieldContainer: {
    overflow: "hidden",
  },
  // use when initially loading and field is visible
  fieldVisible: {
    minHeight: 68,
  },
  // use when initially loading and field is invisible
  fieldInvisible: {
    minHeight: 0,
    height: 0,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
});
