/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  AbstractFormConfiguration,
  AbstractFormField,
  AbstractFormGroup,
  arePropsEqual,
  EntityFormConfiguration,
  EntityFormField,
  ExpressionEvaluationService,
  FetchService,
  FormVariantWithTitle,
  GenericFormField,
  getNamedComponentFactory,
  IViewProps,
  LOADER,
  MessageKey,
  messages,
  queryClient,
  UrlBasedDao,
  useMessages,
  useService,
} from "@icm/core-common";
import {ValueWithIndex} from "@icm/core-web";
import {
  AttributeConfiguration,
  convertEntityFormFieldToGenericFormField,
  Entity,
  EntityConfiguration,
  EntityCreatedEvent,
  entityDataService,
  EntityEvent,
  EntityEventApi,
  EntityHistoryViewComponentProps,
  EntityJournal,
  EntityPropertyValue,
  entityReferenceValueService,
  EntityUpdatedEvent,
  entityUpdateService,
  getGroups,
  getWidgets,
} from "@icm/entity-common";
import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {ReactNode, useCallback, useEffect, useState} from "react";

import useStyles from "./EntityHistoryViewStyle";
import {SimpleField} from "./SimpleField";
import {EntityEventWithIndex, UrlWithResult, ValueWithPossibleValue} from "./types";

function getFieldAttributeName(field: AbstractFormField): string | undefined {
  if (field.widgetType === "GENERIC_FIELD") {
    const genericField = (field as GenericFormField);
    const valueBinding = genericField.valueBinding || "";
    const parts = valueBinding.split(".");
    if (parts.length === 3 && parts[0] === "dynamicAttributes") {
      return parts[1];
    }
  } else if (field.widgetType === "ENTITY_FIELD") {
    const entityField = field as EntityFormField;
    return entityField.attributeName;
  }
  return undefined;
}

function getFieldAttributeConfiguration(entityType: string, field: AbstractFormField,
                                        entityConfigurations?: Map<string, EntityConfiguration>): AttributeConfiguration | undefined {
  const attributeName = getFieldAttributeName(field);
  if (attributeName) {
    return entityConfigurations?.get(entityType || "")?.attributesConfiguration?.attributeConfigurations
      ?.find(a => attributeName === a.name);
  } else {
    return undefined;
  }
}

function getActualValueBinding(entityType: string, field: AbstractFormField, entityConfigurations: Map<string, EntityConfiguration>): string {
  const attributeConfiguration = getFieldAttributeConfiguration(entityType, field, entityConfigurations);
  const idReference = attributeConfiguration?.referenceType === "BY_ID" || attributeConfiguration?.referenceType === "BY_ID_AND_VERSION";
  if (attributeConfiguration && idReference) {
    return `dynamicAttributes.${attributeConfiguration.name}.id`;
  } else if (field.widgetType === "GENERIC_FIELD") {
    return (field as GenericFormField).valueBinding!;
  } else if (field.widgetType === "ENTITY_FIELD") {
    const entityField = field as EntityFormField;
    return  `dynamicAttributes.${entityField.attributeName}.value`;
  } else {
    throw new Error("Field type not supported in EntityHistoryService: " + JSON.stringify(field));
  }
}


function loadPossibleValues(entityType: string, formConfiguration: AbstractFormConfiguration, entityEvents: EntityEventWithIndex[],
                            toGenericFormField: (field: AbstractFormField) => GenericFormField,
                            entityConfigurations: Map<string, EntityConfiguration>): Promise<ValueWithPossibleValue[][]> {
  console.debug("Loading possible values...");
  const promises: Promise<ValueWithPossibleValue[]>[] = [];
  getGroups(formConfiguration).forEach(group => {
    getWidgets(group)
      .filter(widget => widget.widgetType === "GENERIC_FIELD" || widget.widgetType === "ENTITY_FIELD")
      .map(widget => widget as AbstractFormField)
      .forEach(field => {
        const genericField = toGenericFormField(field);
        if (genericField.possibleValuesUrl && genericField.possibleValuesByValuesUrl) {
          const actualValueBinding = getActualValueBinding(entityType, field, entityConfigurations);
          const propertyValues: string[] = getPropertyValues(actualValueBinding, entityEvents);
          if (propertyValues.length !== 0) {
            const dao = new UrlBasedDao({
              valueBinding: genericField.possibleValuesValueBinding!,
              versionBinding: genericField.possibleValuesVersionBinding,
              displayBinding: genericField.possibleValuesDisplayBinding ?? genericField.possibleValuesValueBinding!,
              possibleValuesUrl: genericField.possibleValuesUrl,
              possibleValuesByValuesUrl: genericField.possibleValuesByValuesUrl,
            });
            promises.push(dao.loadPossibleValuesByValues(propertyValues)
              .then(values => values.map(val => ({
                value: dao.getValue(val), possibleValue: val,
              }))));
          }
        }
      });
  });
  return Promise.all(promises);
}


function getPropertyChanges(event: EntityEvent): EntityPropertyValue[] {
  if (event.entityEventType === "CREATED") {
    return (event as EntityCreatedEvent).propertyValues || [];
  } else if (event.entityEventType === "UPDATED") {
    return (event as EntityUpdatedEvent).propertyChanges || [];
  } else {
    return [];
  }
}

function getPropertyValues(valueBinding: string, entityEvents: EntityEventWithIndex[]): string[] {
  const result: string[] = [];
  entityEvents.forEach(e => {
    getPropertyChanges(e).filter(u => u.propertyName === valueBinding).forEach(u => {
      if (!!u.propertyValue) {
        if (typeof (u.propertyValue) === "string") {
          result.push(u.propertyValue);
        } else if (Array.isArray(u.propertyValue)) {
          u.propertyValue.filter(v => typeof v === "string").forEach(v => result.push(v));
        }
      }
    });
  });
  return result;
}

function createFormConfigurationDownloadPromise(url: string): Promise<UrlWithResult> {
  return queryClient.fetchQuery(["private", "core", url], async () => {
    return await FetchService.performGet(url);
  }).then(result => {
    return {url: url, result};
  });
}

function loadSubformConfigurations(parentVariant: string,
                                   formConfigurations: AbstractFormConfiguration[],
                                   toGenericFormField: (field: AbstractFormField) => GenericFormField): Promise<UrlWithResult[]> {
  console.debug("Loading subform configurations for", formConfigurations);
  const promises: Promise<UrlWithResult>[] = [];
  formConfigurations.forEach(formConfiguration => {
    getGroups(formConfiguration)
      .forEach(group => {
        getWidgets(group)
          .map(widget => widget as AbstractFormField)
          .forEach(field => {
            const genericField = toGenericFormField(field);
            if (genericField.component === "LIST" && genericField.simpleListConfiguration && genericField.simpleListConfiguration.formConfigurationUrl) {
              promises.push(createFormConfigurationDownloadPromise(genericField.simpleListConfiguration.formConfigurationUrl));
            }
          });
      });
  });
  return Promise.all(promises);
}


export const EntityHistoryViewComponent = React.memo((props: EntityHistoryViewComponentProps) => {
  const {entityType, entityId, mapViewId, formVariants} = props;
  const {getMessage} = useMessages();

  const [formConfigurations, setFormConfigurations] = useState<AbstractFormConfiguration[] | null>(null);
  const [entityConfigurations, setEntityConfigurations] = useState<Map<string, EntityConfiguration>>();
  const [entityEvents, setEntityEvents] = useState<EntityEventWithIndex[]>([]);
  const [entityEventsLoaded, setEntityEventsLoaded] = useState<boolean>(false);
  const [loadedPossibleValues, setLoadedPossibleValues] = useState<ValueWithPossibleValue[]>();
  const [loadedSubformConfigurations, setLoadedSubformConfigurations] = useState<UrlWithResult[]>();

  const dateService = useService("DATE");

  const toGenericFormField = useCallback((field: AbstractFormField, fieldEntityType?: string) => {
    if (field.widgetType === "GENERIC_FIELD") {
      const genericField = field as GenericFormField;
      if (!genericField.possibleValuesByValuesUrl) {
        const attributeConfiguration = getFieldAttributeConfiguration(entityType, field, entityConfigurations);
        if (attributeConfiguration?.entityType) {
          genericField.possibleValuesByValuesUrl = `ids => 'entity/entities/${attributeConfiguration.entityType}?ids=' + ids.join(',')`;
        }
      }
      return genericField;
    } else {
      const result = convertEntityFormFieldToGenericFormField(fieldEntityType || entityType || "", field as EntityFormField, entityConfigurations);
      // @ts-ignore
      if (field.widgetType === "ENTITY_JOURNAL") {
        result.component = "LIST";
        // @ts-ignore
        result.simpleListConfiguration = {formFieldListConfigurationType: "GENERIC", formConfigurationUrl: `entity/formConfiguration?entityType=${(field as EntityJournal).entityType}`};
      }
      return result;
    }
  }, [entityType, entityConfigurations]);

  useEffect(() => {
    if (formVariants && formVariants.length > 0) {
      const promises: Promise<AbstractFormConfiguration>[] = [];
      formVariants.forEach((formVariant: FormVariantWithTitle)  => {
        promises.push(new Promise<AbstractFormConfiguration>((resolve) => {
          entityDataService.getFormConfiguration({entityType, entityId, formVariant: formVariant.variantName})
            .then((formConfigurationVariant: AbstractFormConfiguration) => {
              resolve(formConfigurationVariant);
            });
        }));
      });

      Promise.all(promises).then((formConfigurationVariants: AbstractFormConfiguration[]) => {
        setFormConfigurations(formConfigurationVariants);
      });
    } else {
      entityDataService.getFormConfiguration({entityType}).then((formConfiguration: EntityFormConfiguration) => {
        setFormConfigurations([formConfiguration]);
      });
    }
  }, [entityType, entityId, formVariants]);

  useEffect(() => {
    entityDataService.getEntityConfigurationForEntityAndRelated(entityType).then(setEntityConfigurations);
  }, [entityType]);

  useEffect(() => {
    EntityEventApi.getEntityEvents(entityType, entityId).then(events => {
      events.sort((a, b) => {
        const ad: Date = a.timestamp || new Date(0);
        const bd: Date = b.timestamp || new Date(0);
        if (ad < bd) {
          return -1;
        } else if (ad > bd) {
          return 1;
        } else {
          return 0;
        }
      });
      setEntityEvents(events.map((e, i) => {
        return {...e, index: i, indexLabel: "" + (i + 1)};
      }));
      setEntityEventsLoaded(true);
    });
  }, [entityId, entityType]);


  useEffect(() => {
    if (entityType && formConfigurations && formConfigurations.length > 0 && entityEventsLoaded && entityConfigurations) {
      console.log("Loading possible values for ", formConfigurations);

      const promises: Promise<ValueWithPossibleValue[][]>[] = [];
      formConfigurations.forEach((formConfiguration: AbstractFormConfiguration)  => {
        promises.push(loadPossibleValues(entityType, formConfiguration, entityEvents, toGenericFormField, entityConfigurations));
      });

      Promise.all(promises).then((possibleValues: ValueWithPossibleValue[][][]) => {
        setLoadedPossibleValues(possibleValues.flat(3));
      });
    }
  }, [entityType, formConfigurations, entityEvents, entityEventsLoaded, toGenericFormField, entityConfigurations]);

  useEffect(() => {
    if (formConfigurations && formConfigurations.length > 0 && entityEventsLoaded && entityConfigurations) {
      formVariants?.forEach(variantWithTitle => {
        loadSubformConfigurations(variantWithTitle.variantName, formConfigurations, toGenericFormField).then(setLoadedSubformConfigurations);
      });
    }
  }, [formConfigurations, entityEventsLoaded, toGenericFormField, entityConfigurations, formVariants]);

  const classes = useStyles();

  /**
   * Retrieve property update value.
   *
   * @param field field to get value for
   * @param valueBindingPrefix the prefix for the value binding
   * @param changes changes to read from
   * @param attributeConfiguration associated attribute configuration
   * @return undefined if no value is present, null if changed to empty (deselction etc.), value otherwise
   */
  const getPropertyUpdateValue = (field: GenericFormField, valueBindingPrefix: string, changes: EntityPropertyValue[],
                                  attributeConfiguration: AttributeConfiguration | undefined): any => {
    let result = undefined;
    if (attributeConfiguration && entityReferenceValueService.isEntityReferenceAttribute(attributeConfiguration)) {
      const valueDisplayChange = changes.find(c => c.propertyName === `${valueBindingPrefix}dynamicAttributes.${attributeConfiguration.name}.valueDisplay`);
      const valueChange = changes.find(c => c.propertyName === `${valueBindingPrefix}dynamicAttributes.${attributeConfiguration.name}.value`);
      const idChange = changes.find(c => c.propertyName === `${valueBindingPrefix}dynamicAttributes.${attributeConfiguration.name}.id`);
      if (valueDisplayChange) {
        result = valueDisplayChange.propertyValue || null;
      } else if (valueChange || idChange) {
        const referenceValue = attributeConfiguration.referenceType === "BY_VALUE" ? valueChange?.propertyValue : idChange?.propertyValue;
        result = getPropertyUpdateValueForReference(referenceValue, field);
      } else {
        return undefined;
      }
    } else {
      const change = changes.find(c => c.propertyName === `${valueBindingPrefix}${field.valueBinding}`);
      if (change)  {
        const value = change.propertyValue;
        result = getPropertyUpdateValueForValue(value, field, changes, attributeConfiguration) || null;
      }
    }
    return result;
  };

  const getPropertyUpdateValueForValue = (value: any, field: GenericFormField, changes: EntityPropertyValue[],
                                          attributeConfiguration: AttributeConfiguration | undefined): any => {
    if (Array.isArray(value)) {
      return value.map(v => getPropertyUpdateValueForValue(v, field, changes, attributeConfiguration)).join(", ");
    } else if (value && value.valueDisplay) {
      return value.valueDisplay;
    } else if (field.possibleValuesList) {
      const possibleValue = field.possibleValuesList.find(pv => pv.value === value);
      return possibleValue ? possibleValue.label : value;
    } else if (field.possibleValuesDisplayBinding) {
      return getPropertyUpdateValueForReference(value, field);
    } else {
      return value;
    }
  };

  const getPropertyUpdateValueForReference = (value: any, field: GenericFormField): any => {
    if (Array.isArray(value)) {
      return value.map(v => getPropertyUpdateValueForReference(v, field));
    } else if (value === null || value === undefined) {
      return null;
    } else if (field.possibleValuesValueBinding === "_") {
      return ExpressionEvaluationService.evaluate(field.possibleValuesDisplayBinding!, value);
    } else if (field.possibleValuesByValuesUrl) {
      const val = value && value.value ? value.value : value;
      const possibleValue = loadedPossibleValues?.find(i => i.value === val)?.possibleValue;
      if (possibleValue) {
        return ExpressionEvaluationService.evaluate(field.possibleValuesDisplayBinding!, possibleValue);
      } else {
        console.warn("Possible value has not yet been loaded", value);
        return null;
      }
    } else {
      console.warn("Possible value can not be retrieved for value " + JSON.stringify(value) + " in " + JSON.stringify(field));
      return null;
    }
  };

  const getEventValuesWithIndex = (field: GenericFormField, valueBindingPrefix: string,
                                   attributeConfiguration: AttributeConfiguration | undefined): ValueWithIndex[] => {
    const result: ValueWithIndex[] = [];
    let previousValue: any = null;
    entityEvents.forEach(event => {
      const changes = getPropertyChanges(event);
      const value = getPropertyUpdateValue(field, valueBindingPrefix, changes, attributeConfiguration);
      if (event.id && value !== undefined && value !== previousValue) {
        result.push({indexLabel: event.indexLabel, value, userLabel: event.userName, timeStampLabel: event.commandTimestamp});
      }
      previousValue = value;
    });
    if (entityEvents.length > 0 && result.length === 0) {
      const fieldValueBinding = valueBindingPrefix + field.valueBinding;
      if (fieldValueBinding === "id" || fieldValueBinding.endsWith(".id")) {
        result.push({
          indexLabel: entityEvents[0].indexLabel,
          value: entityId,
          userLabel: entityEvents[0].userName,
          timeStampLabel: entityEvents[0].timeStampLabel,
        });
      }
    }
    return result;
  };

  const getEventIndexOfDeletion = (valueBinding: string): string | null => {
    let result: string | null = null;
    entityEvents.forEach(event => {
      const change = getPropertyChanges(event).find(u => u.propertyName === valueBinding);
      if (change && event.id && (change.propertyValue === null || change.propertyValue === undefined)) {
        result = event.indexLabel;
      }
    });
    return result;
  };

  const getWeakEntityIds = (field: GenericFormField, valueBindingPrefix: string): string[] => {
    const fieldValueBinding = valueBindingPrefix + field.valueBinding;
    const result: string[] = [];
    entityEvents.forEach(event => {
      const idChange = getPropertyChanges(event).find(u => u.propertyName === fieldValueBinding + ".ids");
      if (idChange && event.id) {
        const ids: string[] = idChange.propertyValue || [];
        ids.filter(id => !result.includes(id)).forEach(i => result.push(i));
      }
    });
    return result;
  };


  const getAttributeConfiguration = (field: AbstractFormField, fieldEntityType?: string): AttributeConfiguration | undefined => {
    if (field.widgetType === "ENTITY_FIELD") {
      const entityField = field as EntityFormField;
      return entityConfigurations?.get(fieldEntityType || entityType || "")?.attributesConfiguration?.attributeConfigurations
        ?.find(a => entityField.attributeName === a.name);
    } else {
      return getFieldAttributeConfiguration(entityType, field, entityConfigurations);
    }
  };

  const getHeadingClassByLevel = (level: number): string => {
    switch (level) {
      case 0:
        return classes.heading1;
      case 1:
        return classes.heading2;
      default:
        return classes.heading3;
    }
  };

  const renderListEntry = (id: string, entity: Entity, field: GenericFormField, level: number, subformConfiguration: AbstractFormConfiguration,
                           fieldEntityType?: string): ReactNode => {
    const valueBindingPrefix = field.valueBinding + ".data." + id + ".";
    const deletedEventIndex = getEventIndexOfDeletion(field.valueBinding + ".data." + id);
    return (
      <div key={`${id}`} className={classes.weakEntity}>
        <Typography variant="subtitle2"
                    className={getHeadingClassByLevel(level)}
        >
          {getTitle(entity, subformConfiguration, valueBindingPrefix)}
        </Typography>
        {deletedEventIndex ? (
          <p className={classes.deletionNote}>
            {messages.get(MessageKey.ENTITY.HISTORY.VALUEDELETED)}<sup>&nbsp;{deletedEventIndex})</sup>
          </p>
        ) : null}
        {getGroups(subformConfiguration).map((g, i) => renderGroup(entity, g, i, level + 1, valueBindingPrefix, fieldEntityType))}
      </div>
    );
  };

  const renderListField = (entity: Entity, baseField: AbstractFormField, fieldIndex: number, level: number, valueBindingPrefix: string,
                           fieldEntityType?: string): ReactNode => {
    const attrConfig = getAttributeConfiguration(baseField, fieldEntityType);
    const field = toGenericFormField(baseField, fieldEntityType);
    const subformConfiguration: AbstractFormConfiguration =
      loadedSubformConfigurations?.find(i => i.url === field.simpleListConfiguration?.formConfigurationUrl)?.result;
    return (
      <Grid container={true}
            key={`${fieldIndex}.${field.label}`}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
      >
        {getWeakEntityIds(field, valueBindingPrefix)
          .map(id => renderListEntry(id, entity, field, level + 1, subformConfiguration, attrConfig?.entityType))}
      </Grid>
    );
  };

  const evaluateRule = (rule: string | undefined, dflt: boolean, entity: Entity, fieldValue?: any): boolean => {
    return rule ? ExpressionEvaluationService.evaluateRule(rule, entity, fieldValue, {}) : dflt;
  };

  const renderField = (entity: Entity, field: AbstractFormField, fieldIndex: number, level: number,
                       valueBindingPrefix: string, fieldEntityType?: string): ReactNode =>{
    const visible = evaluateRule(field.visible, true, entity);
    if (visible) {
      // @ts-ignore
      if (field.component === "LIST" || field.widgetType === "ENTITY_JOURNAL") {
        return renderListField(entity, field, fieldIndex, level, valueBindingPrefix, fieldEntityType);
        // @ts-ignore
      } else if (field.widgetType !== "ENTITY_LIST" && field.widgetType !== "ENTITY_WORKFLOW_ACTIVE_TASKS") {
        const attributeConfiguration = getAttributeConfiguration(field, fieldEntityType);
        const genericField = toGenericFormField(field, fieldEntityType);
        const eventIndexValues: ValueWithIndex[] = getEventValuesWithIndex(genericField, valueBindingPrefix, attributeConfiguration);
        return (
          <SimpleField key={`${fieldIndex}.${field.label}`}
                       field={genericField}
                       eventIndexValues={eventIndexValues}
                       fieldIndex={fieldIndex}
                       mapViewId={mapViewId}
          />
        );
      }
    }
    return <span />;
  };

  const renderGroup = (entity: Entity, group: AbstractFormGroup, index: number, level: number, valueBindingPrefix: string,
                       fieldEntityType?: string): ReactNode => {
    const widgets = getWidgets(group);
    const headerStyle: React.CSSProperties = (index === 0) ? {marginTop: 0} : {};
    return (
      <div key={`${index}.${group.label}`}>
        <Typography variant="subtitle1"
                    key={group.label + ".title"}
                    className={getHeadingClassByLevel(level)}
                    style={headerStyle}
        >
          {group.label}
        </Typography>
        {widgets && widgets.length > 0
          ? (
            <Grid container={true} key={group.label + ".properties"} direction="column">
              {widgets.map(widget => widget as AbstractFormField).map((field, i) => renderField(entity, field, i, level, valueBindingPrefix, fieldEntityType))}
            </Grid>
          ) : ""}
      </div>
    );
  };

  const renderEntityEvent = (event: EntityEventWithIndex): ReactNode => {
    return (
      <Typography key={event.index} className={classes.changeSet} variant="body2">
        <sup>&nbsp;{event.indexLabel})</sup> {event.userName}, {event.hostName}, {dateService.formatDateTime(event.timestamp!, "SHORT", "MEDIUM")}
      </Typography>
    );
  };

  const getTitle = (entity: Entity, form: AbstractFormConfiguration, valueBindingPrefix: string): string => {
    const typeDisplay = form.formTypeDisplay || null;
    const valueDisplayExpression = form.formValueDisplay ? form.formValueDisplay.replaceAll("{{", "{{" + valueBindingPrefix) : null;
    const valueDisplay = valueDisplayExpression ? ExpressionEvaluationService.evaluate(valueDisplayExpression, entity) : null;
    let result = "";
    result += (typeDisplay) || "";
    result += (typeDisplay && valueDisplay) ? " " : "";
    result += (valueDisplay) || "";
    return result;
  };

  if (!entityType) {
    return <div>No entity type specified.</div>;
  } else {
    const ready = !!formConfigurations && formConfigurations.length > 0 && !!entityEvents
      && !!loadedPossibleValues && !!loadedSubformConfigurations && !!entityConfigurations;
    if (ready) {
      const valueBindingPrefix = "";
      const entity: Entity = entityUpdateService.applyChanges({}, entityEvents.flatMap(e => getPropertyChanges(e)));
      return (
        <div className="print">
          <Grid container={true} key="datetime" direction="row-reverse" justifyContent="space-between" align-content="flex-end">
            <Typography variant="subtitle1">
              <span>{messages.get(MessageKey.CORE.DATE)}: {dateService.formatDate(new Date())}</span><br />
              <span>{messages.get(MessageKey.CORE.TIME)}: {dateService.formatTime(new Date(), "MEDIUM")}</span>
            </Typography>
          </Grid>
          {formConfigurations.map((formConfiguration: AbstractFormConfiguration, i) => {
            const formConfigurationHeaderStyle: React.CSSProperties = (i === 0) ? {margin: 0} : {};
            return (
              <div className={classes.root} key={`formConfiguration-${i}`}>
                <Grid container={true} key="title" direction="row" justifyContent="space-between" align-content="flex-end">
                  <Typography variant="subtitle1" className={`${classes.heading1} ${classes.headingSection}`} style={formConfigurationHeaderStyle}>
                    <span>{getTitle(entity, formConfiguration, valueBindingPrefix)}</span>
                  </Typography>
                </Grid>
                {getGroups(formConfiguration).map((g, j) => renderGroup(entity, g, j, 0, valueBindingPrefix))}
                <Grid container={true}
                      key="changeInformation"
                      className={classes.changeInformation}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                />
              </div>
            );
          })}
          <div className="printNoBreak">
            {entityEvents.map(renderEntityEvent)}
          </div>
        </div>
      );
    } else {
      const Loader = getNamedComponentFactory(LOADER) || (() => <span>{getMessage(MessageKey.CORE.LOADING)}</span>);
      return (
        <>
          <Loader />
        </>
      );
    }
  }
}, arePropsEqual);

export type EntityHistoryViewModelData = {
  entityType: string,
  entityId: string,
  mapViewId?: string,
  printRequested: boolean,
}


/**
 * A view factory to create the EntityHistoryView
 *
 * @param props
 * @constructor
 */
export const EntityHistoryView = (props: IViewProps<EntityHistoryViewModelData>) => {
  return <EntityHistoryViewComponent {...props.viewModel.viewModelData} />;
};


/**
 * A view factory to create the EntityHistoryViewComponent directly
 *
 * @param props
 * @constructor
 */
export const entityHistoryViewComponentFactory = (props: EntityHistoryViewComponentProps) => <EntityHistoryViewComponent {...props} />;
