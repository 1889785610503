/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchService, ListFilter} from "@icm/core-common";

import {Entity, EntityListPortion} from "../generated/api";

class EntityApi {
  public static getEntities(entityType: string, listFilter?: ListFilter, abortController?: AbortController): Promise<EntityListPortion> {
    if (listFilter) {
      return FetchService.performGet(`entity/entities/${entityType}?filter=${encodeURIComponent(JSON.stringify(listFilter))}`, {abortController})
        .then(json => EntityListPortion.fromData(json));
    } else {
      return FetchService.performGet(`entity/entities/${entityType}`, {abortController})
        .then(json => EntityListPortion.fromData(json));
    }
  }

  public static getEntity(entityType: string, id: string): Promise<Entity> {
    return FetchService.performGet(`entity/entities/${entityType}/${id}`)
      .then(json => Entity.fromData(json));
  }

  public static getEntityByKey(entityType: string, key: string): Promise<Entity> {
    return FetchService.performGet(`entity/entityByKey/${entityType}/${key}`)
      .then(json => Entity.fromData(json));
  }
}

export {EntityApi};
