/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  isFromMe,
  ObjectUpdate,
  ObjectUpdateInformation,
  ObjectWithUpdateInformation,
  UpdatedObjectsMap,
  useObjectUpdateInformation,
} from "@icm/activitystream-common";
import {
  ArrayUtilities,
  FilterService,
  isListFilterValid,
  ListFilter,
  NormalizedInfiniteData,
  SecurityService,
  Sorting,
  SortService,
  UpdateFilterSorting,
  useService,
} from "@icm/core-common";
import {isEqual, partition} from "lodash-es";
import {useCallback, useMemo} from "react";
import {InfiniteData, QueryClient, useInfiniteQuery, useQueryClient} from "react-query";
import {QueryFunctionContext} from "react-query/types/core/types";

import {Entity} from "../generated/api";
import {EntityListQueryKeyGenerator, EntityPage, PagedEntityLoader} from "../util";
import {useCsvDownload} from "./useCsvDownload";
import {EntityLoader} from "./useEntityListComponent";
import {useEntityListConfiguration} from "./useEntityListConfiguration";
import {useInfiniteEntityListReload} from "./useInfiniteEntityListReload";
import {EntityListElementViewFactory, useListElementViewFactory} from "./useListElementViewFactory";


export type EntityListOptions = {

  entityType: string,

  contextEntityId: string | undefined,

  listFilter: ListFilter,

  sort: UpdateFilterSorting,

  /**
   * @default "default"
   */
  listVariant?: string

  loader?: EntityLoader

}


type ExistingEntityCount = number;

/**
 * This type is used when showing entities in a list.
 * Each entity is enriched with attributes that can be
 * calculated in advance. These attributes are called
 * viewAttributes.
 */
export type EntityListElementView = Entity & {

  /**
   * An object describing properties relevant to
   * render an entity in an entity list.
   */
  viewAttributes: Readonly<object>
}

export type OnEntityDeleted = (entityId: string) => void;

const DEFAULT_ENTITY_LIST_CACHE_TIME = 30000;

export const useUpdatingInfiniteEntityList = (entityListOptions: EntityListOptions) => {
  const {
    entityType,
    contextEntityId,
    listFilter,
    sort,
    listVariant,
    loader,
  } = entityListOptions;
  const listConfiguration = useEntityListConfiguration(entityType, listVariant);

  const queryClient = useQueryClient();

  const queryKey = EntityListQueryKeyGenerator.buildEntityListQueryKey(entityType, listFilter, contextEntityId);
  const viewFactory = useListElementViewFactory(listConfiguration?.rowConfiguration);

  const createObjectWithUpdateInformation = useCallback((e: Entity): ObjectWithUpdateInformation<EntityListElementView> => {
    return {
      id: e.id!,
      object: viewFactory(e),
      listState: "INITIAL",
    };
  }, [viewFactory]);

  const entityUpdatesById = useObjectUpdateInformation(viewFactory, entityType, Entity.fromData) as UpdatedObjectsMap;

  const result = useInfiniteQuery<EntityPage>(queryKey,
    ({pageParam = 0}: QueryFunctionContext<any, ExistingEntityCount>) => {
      if (loader) {
        const newEntities = loader.load();
        return Promise.resolve({
          entities: newEntities.map(createObjectWithUpdateInformation),
          lastUpdateConsidered: {},
          moreEntities: false,
          overallEntityCount: newEntities.length,
          pageNumber: 0,
        });
      } else {
        return PagedEntityLoader.loadEntities({
          pageParam,
          pageSize: listConfiguration?.pageSize,
          viewFactory,
          listFilter,
          entityType,
          queryClient,
          queryKey,
        });
      }
    },
    {
      getNextPageParam: (page, _allPages) => {
        if (!page) {
          return 0;
        } else if (page.moreEntities) {
          return page.pageNumber + 1;
        } else {
          return undefined;
        }
      },
      getPreviousPageParam: (page, _allPages) => {
        if (page) {
          return Math.max(page.pageNumber - 1, 0);
        }
        return undefined;
      },
      // wait for list config and a valid filter
      enabled: !!listConfiguration && (!listFilter || isListFilterValid(listFilter)),
      cacheTime: DEFAULT_ENTITY_LIST_CACHE_TIME,
      staleTime: Infinity, // never stale, because it is self-updating or forced by user
      refetchOnWindowFocus: false,
    });

  const securityService = useService("SECURITY");

  const updatingResult = useMemo((): NormalizedInfiniteData<ObjectWithUpdateInformation<EntityListElementView>> => {
    const existingEntities = result.data?.pages.flatMap(page => page.entities) ?? [];
    const lastUpdateConsidered = result.data?.pages[0]?.lastUpdateConsidered ?? {};
    if (entityUpdatesById) {
      const {
        newEntities,
        updatedEntities,
        outdatedEntities,
        removedEntities,
      } = classifyUpdates(entityUpdatesById, lastUpdateConsidered, existingEntities, securityService, viewFactory, listFilter);

      if (result.isSuccess && (
        newEntities.length > 0
        || Object.entries(removedEntities).length > 0
        || Object.keys(outdatedEntities).length > 0
        || Object.keys(updatedEntities).length > 0
      )) {
        updateUpdatingInfiniteEntityList(newEntities, removedEntities, outdatedEntities, updatedEntities, queryClient, queryKey, listFilter);
      }
    }

    return {
      fetchNextPage: async () => {
        if (!result.isFetchingNextPage) {
          return await result.fetchNextPage()
            .then();
        }
        return Promise.resolve();
      },
      hasNextPage: result.hasNextPage,
      isLoading: result.isLoading,
      isFetching: result.isFetching,
      isFetchingNextPage: result.isFetchingNextPage,
      isIdle: result.isIdle,
      data: existingEntities,
      orderValid: SortService.isSorted(existingEntities, createLatestVersionComparator(listFilter)),
      refetch: result.refetch,
    };
  }, [viewFactory, securityService, result, entityUpdatesById, queryClient, queryKey, listFilter]);

  const startCsvDownload = useCsvDownload(entityType, listFilter, result.data?.pages[0]?.overallEntityCount);

  const extraSort = useCallback((sorting?: Sorting) => {
    const currentSorting = listFilter.sorting;
    if (!updatingResult.orderValid && currentSorting?.property === sorting?.property && currentSorting?.order === sorting?.order) {
      console.debug("Resorting list result. This will merge all current pages into one page.");

      queryClient.setQueryData<InfiniteData<EntityPage>>(queryKey, (existingData: InfiniteData<EntityPage>) => {
        if (existingData) {
          const moreEntities = !!ArrayUtilities.last(existingData.pages)?.moreEntities;
          const overallEntityCount: number | undefined = ArrayUtilities.last(existingData.pages)?.overallEntityCount;
          const lastUpdateConsidered = existingData.pages[0]?.lastUpdateConsidered ?? {};
          const currentEntities = existingData.pages.flatMap(page => page.entities);
          const [unchanged, changed] = partition(currentEntities, (e) => {
            const lastUpdate = ArrayUtilities.last(e.updateInfo?.objectUpdates);
            return !lastUpdate || e.object.updatedAt === lastUpdate.object?.updatedAt;
          });

          const updatedChanged = changed.map((e): typeof e => {
            return ({
              ...e,
              object: ArrayUtilities.last(e.updateInfo?.objectUpdates)?.object ?? e.object,
            });
          });
          insertEntitiesInSortedList(updatedChanged, unchanged, moreEntities, lastUpdateConsidered, listFilter);
          return {
            pages: [{
              entities: unchanged,
              moreEntities,
              lastUpdateConsidered,
              overallEntityCount,
              pageNumber: 0,
            }],
            pageParams: [0],
          };
        }
        return {
          pages: [],
          pageParams: [],
        };
      });
    }
    sort(sorting);
  }, [updatingResult.orderValid, sort, queryClient, queryKey, listFilter]);

  const onEntityDeleted: OnEntityDeleted = useCallback((entityId: string) => {
    const existingEntities = result.data?.pages.flatMap(page => page.entities) ?? [];
    const removedEntity: ObjectWithUpdateInformation<any> | undefined = existingEntities.find(e => e.object.id === entityId);
    if (removedEntity) {
      const removedEntityRecord = {
        [removedEntity.id]: true,
      };
      updateUpdatingInfiniteEntityList([], removedEntityRecord, {}, {}, queryClient, queryKey, listFilter);
    }
  }, [result, queryKey, queryClient, listFilter]);

  return {
    result: updatingResult,
    sort: extraSort,
    startCsvDownload,
    reload: useInfiniteEntityListReload(entityType, queryKey),
    onEntityDeleted,
  };
};


const createLatestVersionComparator = (listFilter?: ListFilter) => {
  const valueAccessor = (o: ObjectWithUpdateInformation<EntityListElementView>) => ArrayUtilities.last(o.updateInfo?.objectUpdates)?.object ?? o.object;
  const comparator = FilterService.createComparator(listFilter);
  return SortService.createComparator<ObjectWithUpdateInformation<EntityListElementView>, EntityListElementView>(valueAccessor, comparator);
};

const updateUpdatingInfiniteEntityList = (
  newEntities: ObjectWithUpdateInformation<EntityListElementView>[],
  removed: Record<string, boolean>,
  outdated: Record<string, ObjectUpdateInformation<EntityListElementView>>,
  newUpdateInformation: Record<string, ObjectUpdateInformation<EntityListElementView>>,
  queryClient: QueryClient,
  queryKey: any,
  listFilter?: ListFilter
) => {
  queryClient.setQueryData<InfiniteData<EntityPage>>(queryKey, (existingData?: InfiniteData<EntityPage>) => {
    if (existingData) {
      const lastUpdateConsidered = existingData.pages[0]?.lastUpdateConsidered ?? {};
      const currentEntities = existingData.pages.flatMap(page => page.entities)
        .filter(e => !removed[e.object.id!])
        .map((e): typeof e => {
          if (newUpdateInformation[e.object.id!]) {
            return {
              ...e,
              updateInfo: newUpdateInformation[e.object.id!],
              listState: e.listState === "FILTER_REMOVED" ? "ADDED" : e.listState,
            };
          } else if (outdated[e.object.id!]) {
            return {
              ...e,
              updateInfo: outdated[e.object.id!],
              listState: "FILTER_REMOVED",
            };
          }
          return e;
        });
      const moreEntities = !!ArrayUtilities.last(existingData.pages)?.moreEntities;
      const overallEntityCount: number | undefined = ArrayUtilities.last(existingData.pages)?.overallEntityCount;

      const withNewEntities = [...currentEntities];
      insertEntitiesInSortedList(newEntities, withNewEntities, moreEntities, lastUpdateConsidered, listFilter);

      return {
        pages: [{
          entities: withNewEntities,
          moreEntities,
          lastUpdateConsidered,
          overallEntityCount,
          pageNumber: 0,
        }],
        pageParams: [0],
      };
    }
    return {
      pages: [],
      pageParams: [],
    };
  });
};

/**
 * This function inserts entities into an existing, sorted list of entities according to the sorting given by a ListFilter
 * and updates {lastUpdateConsidered} for entities that cannot be inserted.
 *
 * @param insert items to insert
 * @param target array where they should be inserted
 * @param hasMoreEntities true, if the array is not complete (more items could be appended)
 * @param lastUpdateConsidered is updated with entities that would fall outside of the target array
 * @param listFilter the user's filter
 */
const insertEntitiesInSortedList = (
  insert: ObjectWithUpdateInformation<EntityListElementView>[],
  target: ObjectWithUpdateInformation<EntityListElementView>[],
  hasMoreEntities: boolean,
  lastUpdateConsidered: Record<string, Date>,
  listFilter?: ListFilter
) => {
  for (const newEntity of insert) {
    const targetIndex = SortService.sortedIndex(target, newEntity, createLatestVersionComparator(listFilter));
    console.log("index of new entity", newEntity, "is", targetIndex, target.length);
    if (targetIndex !== undefined && (targetIndex < target.length || !hasMoreEntities)) {
      // only allow adding at the end, if there are no more entities to load
      target.splice(targetIndex, 0, newEntity);
    } else {
      // outside loaded entries
      console.info("changed entity would be outside loaded entities. not including");
      lastUpdateConsidered[newEntity.object.id!] = newEntity.updateInfo!.lastUpdatedAt;
    }
  }
};

/**
 * This functions classifies the given updates into four categories (new, updated, outdated, removed)
 * @param entityUpdatesById the updates to classify
 * @param lastUpdateConsidered a map with the timestamp of the last update that was considered for each entity
 * @param existingEntities the list of existing entities
 * @param securityService security service to use
 * @param entityViewFactory a function that should be used to create a "view model" of an entity that is used as row object
 * @param listFilter the applied filter
 */
const classifyUpdates = (
  entityUpdatesById: UpdatedObjectsMap,
  lastUpdateConsidered: Record<string, Date>,
  existingEntities: ObjectWithUpdateInformation<EntityListElementView>[],
  securityService: SecurityService,
  entityViewFactory: EntityListElementViewFactory,
  listFilter?: ListFilter
) => {
  const outdatedEntities: Record<string, ObjectUpdateInformation<EntityListElementView>> = {};
  const removedEntities: Record<string, boolean> = {};
  const newEntities: ObjectWithUpdateInformation<EntityListElementView>[] = [];
  const updatedEntities: Record<string, ObjectUpdateInformation<EntityListElementView>> = {};

  Object.values(entityUpdatesById as Record<string, ObjectUpdateInformation<EntityListElementView>>)
    .forEach((entityUpdate) => {
      const lastUpdate = ArrayUtilities.last(entityUpdate.objectUpdates);
      if (lastUpdate && (!lastUpdateConsidered[entityUpdate.id] || lastUpdateConsidered[entityUpdate.id] < lastUpdate.date)) {
        const latestRevision = lastUpdate.object;
        const existingEntity = existingEntities.find(e => e.object.id === entityUpdate.id);
        const alreadySeen = isAlreadySeen(lastUpdate, securityService, entityUpdate);
        const isDeleteOrArchive = lastUpdate.type === "DELETE" || lastUpdate.type === "ARCHIVE";

        if (viewMatches(latestRevision, listFilter)) {
          if (existingEntity) {
            if (alreadySeen && isDeleteOrArchive) {
              removedEntities[existingEntity.object.id!] = true;
            } else if (!isEqual(existingEntity.updateInfo, entityUpdate)) {
              updatedEntities[existingEntity.object.id!] = entityUpdate;
            }
          } else if (!isDeleteOrArchive) {
            newEntities.push({
              id: latestRevision!.id!,
              object: entityViewFactory(latestRevision!),
              updateInfo: entityUpdate,
              listState: alreadySeen ? "INITIAL" : "ADDED",
            });
          }
        } else if (existingEntity) {
          if (existingEntity.listState !== "FILTER_REMOVED") {
            outdatedEntities[existingEntity.object.id!] = entityUpdate;
          }
          if (alreadySeen) {
            removedEntities[existingEntity.object.id!] = true;
          }
        }
      }
    });
  return {
    newEntities,
    updatedEntities,
    outdatedEntities,
    removedEntities,
  };
};

function isAlreadySeen(lastUpdate: ObjectUpdate<EntityListElementView>,
                       securityService: SecurityService,
                       entityUpdate: ObjectUpdateInformation<Entity & { viewAttributes: Readonly<object> }>) {
  return isFromMe(lastUpdate, securityService)
    || (entityUpdate.showUpdatesSince && entityUpdate.showUpdatesSince >= entityUpdate.lastUpdatedAt);
}

function viewMatches(view?: EntityListElementView, listFilter?: ListFilter) {
  return view && FilterService.matchesFilter(view, listFilter);
}
