/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ResolvedParameter} from "../util";

export class ApiUtil {
  public static convertArray<T>(json: any, convert: (val: any) => T): T[] {
    return json.map((val: any) => val && convert(val));
  }

  public static convertObjectToGetParams(object: any): string {
    return Object.entries(object)
      .filter(e => e[1] !== null && e[1] !== undefined)
      .map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent("" + e[1])}`)
      .join("&");
  }

  public static convertGetParamsToObject(params?: string): ResolvedParameter[] {
    const result: ResolvedParameter[] = [];
    new URLSearchParams(params ?? "").forEach((value, key) => {
      result.push({key, value});
    });
    return result;
  }

}
