/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi, FormWidgetProps, useFormGeneratorContext} from "@icm/core-common";
import {Journal} from "@icm/core-web";
import * as React from "react";
import {useMemo} from "react";

import {useEntityJournalController} from "./useEntityJournalController";


/**
 * Provides a journal that uses entity properties to store its contents
 */
export const WebEntityJournalWidget = (props: FormWidgetProps) => {
  const widget = props.widget as CoreApi.EntityJournal;
  const {entity} = useFormGeneratorContext();
  const controller = useEntityJournalController(entity, widget.attributeName!, widget.entityType!);
  return useMemo(() => {
    if (controller) {
      return (<Journal controller={controller} label={props.widget.label} />);
    } else {
      return <></>;
    }
  }, [controller, props.widget.label]);
};
