/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService} from "@icm/core-common";
import * as maplibregl from "maplibre-gl";

import {Bookmark, Layer, LayerBase, LayerBaseUnion, LayerGroup, MapConfiguration, Source} from "../../generated/api";
import {isLogicalGroup, isLogicalLayer} from "../../util/ApiUtil";

type Replacement = {
  from: string;
  to: string;
}

type MapboxStyleReference = {
  /** the style URL */
  url: string;
  /** replacements */
  replace?: Replacement[];
  /** the source ID(s) to include */
  include?: string;
  /** the source ID(s) to exclude */
  exclude?: string;
  /** optional bounds: [west, south, east, north] */
  bounds?: [number, number, number, number];
  /** minimum zoom, default 0 */
  minzoom?: number;
  /** maximum zoom, default 22 */
  maxzoom?: number;
};

type LayerTemplateFunction = (layer: any) => any[];
type LayerTemplateFunctions = {[name: string]: LayerTemplateFunction};

function parseJSON(s: string): any {
  if (s) {
    try {
      return JSON.parse(s);
    } catch (e) {
      console.error(`Error parsing map configuration JSON: ${e}`, s);
    }
  }
  return undefined;
}

/**
 * Fix possible problems in the configuration.
 * E.g. fixes the visibility of group layers, if incorrectly specified.
 * This does not create new objects, but mutates the configuration.
 *
 * @param config the configuration
 */
function fixMapConfiguration(config: MapConfiguration): MapConfiguration {
  config.layers && fixGroupLayerVisibility(config.layers);
  return config;
}

/**
 * Fix the visibility of group layers:
 * They are visible, if any of the children are visible.
 *
 * @param layers the layers
 * @return true, if any of the layers are visible
 */
function fixGroupLayerVisibility(layers: LayerBaseUnion[]): boolean {
  let anyVisible = false;
  layers.forEach(layer => {
    if (isLogicalLayer(layer)) {
      anyVisible = anyVisible || (layer.visible ?? false);
    } else if (layer.layers) {
      const anyChildVisible = fixGroupLayerVisibility(layer.layers);
      layer.visible = anyChildVisible;
      anyVisible = anyVisible || anyChildVisible;
    }
  });
  return anyVisible;
}

function getLayerTemplates(config: MapConfiguration): LayerTemplateFunctions {
  const templates: {[name: string]: LayerTemplateFunction} = {};
  const settings = config.settings?.find(s => s.name === "layerTemplates")?.items ?? [];
  settings?.forEach(setting => {
    const name = setting.name;
    const defaultParams = {};
    setting.parameters?.filter(p => p.name && p.name !== "mapboxLayers").forEach(p => defaultParams[p.name!] = p.value);
    const template = setting.parameters?.find(p => p.name === "mapboxLayers")?.value;
    if (name && template) {
      templates[name] = (layer: any) => {
        const type = layer.type ?? "";
        const params = {
          ...defaultParams,
          id: layer.id,
        };
        const qPos = type.indexOf("?");
        if (qPos && qPos >= 0) {
          new URLSearchParams(type.substring(qPos + 1)).forEach((value, key) => params[key] = value);
        }
        try {
          const evaluatedTemplate = ExpressionEvaluationService.evaluate(template, params);
          const parsedTemplate = JSON.parse(evaluatedTemplate);
          const templateLayers = Array.isArray(parsedTemplate) ? parsedTemplate : [parsedTemplate];
          return templateLayers.map(templateLayer => ({
            ...templateLayer,
            ...layer,
            id: templateLayer.id ?? layer.id,
            type: templateLayer.type,
            layout: {...templateLayer.layout, ...layer.layout},
            paint: {...templateLayer.paint, ...layer.paint},
          }));
        } catch (e) {
          console.error(`Error evaluating or parsing layer template ${name}`, e);
          return [];
        }
      };
    } else {
      console.warn(`Layer templates need a name and at least a parameter "mapboxLayers": ${name}`);
    }
  });
  return templates;
}

function applyPossibleLayerTemplate(layer: any, templates: LayerTemplateFunctions): any[] {
  const qPos = layer.type.indexOf("?");
  const templateName = qPos > 0 ? layer.type.substring(0, qPos) : layer.type;
  const template = templates?.[templateName];
  return template ? template(layer).flatMap(l => applyPossibleLayerTemplate(l, templates)) : [layer];
}

/**
 * Resolve all references to external mapbox style definitions and to internal templates.
 *
 * @param config the configuration
 */
function resolveMapConfigurationReferences(config: MapConfiguration): Promise<MapConfiguration> {
  const templates = getLayerTemplates(config);
  const result: MapConfiguration = {...config, sources: [], layers: []};
  const promises = [
    resolveSourceReferences(config.sources || []).then(sources => result.sources = sources, () => []),
    resolveLogicalLayerReferences(config.layers || [], templates).then(layers => result.layers = layers, () => []),
  ];
  return Promise.all(promises).then(() => {
    return result;
  });
}

function resolveSourceReferences(sources: Source[]): Promise<Source[]> {
  const promises: Promise<Source[]>[] = sources
    .map(source => resolvePossibleSourceReference(source));
  return Promise.all(promises).then(sourceArrays => ([] as Source[]).concat(...sourceArrays));
}

function resolvePossibleSourceReference(source: Source): Promise<Source[]> {
  const mapboxSource = source.mapboxSource ? parseJSON(source.mapboxSource) : undefined;
  if (mapboxSource && "url" in mapboxSource && !("type" in mapboxSource)) {
    return resolveSourceReference(mapboxSource as MapboxStyleReference);
  } else if (!source.mapboxSource || mapboxSource) {
    return Promise.resolve([source]);
  } else {
    return Promise.resolve([]);
  }
}

function resolveSourceReference(ref: MapboxStyleReference): Promise<Source[]> {
  return fetch(ref.url)
    .then(response => response.text())
    .then(text => parseStyle(text, ref.replace || []))
    .then(style => {
      const doInclude = getIncludePredicate(ref.include, ref.exclude);
      const sources: Source[] = [];
      for (const [id, source] of Object.entries(style.sources || {})) {
        if (source.type === "vector") {
          ref.bounds && (source.bounds = ref.bounds);
          ref.minzoom && (source.minzoom = ref.minzoom);
          ref.maxzoom && (source.maxzoom = ref.maxzoom);
        }
        doInclude(id) && sources.push({id: id, mapboxSource: JSON.stringify(source)});
      }
      return sources;
    })
    .catch(() => []);
}

function resolveLogicalLayerReferences(layers: LayerBase[], templates: LayerTemplateFunctions): Promise<LayerBase[]> {
  const promises: Promise<LayerBase>[] = layers.map(layer => {
    if (isLogicalGroup(layer)) {
      return resolveLogicalLayerReferences(layer.layers || [], templates).then(resolvedLayers => ({...layer, layers: resolvedLayers}));
    } else {
      return resolveLayerReferences(layer as Layer, templates);
    }
  });
  return Promise.all(promises);
}

function resolveLayerReferences(layer: Layer, templates: LayerTemplateFunctions): Promise<Layer> {
  const promises = (layer.mapboxLayers || [])
    .map(l => resolvePossibleLayerReference(l, templates));
  return Promise.all(promises).then(layerArrays => ({...layer, mapboxLayers: ([] as string[]).concat(...layerArrays)}));
}

function resolvePossibleLayerReference(layer: string, templates: LayerTemplateFunctions): Promise<string[]> {
  const mapboxLayer = parseJSON(layer || "");
  if (mapboxLayer && "url" in mapboxLayer && !("type" in mapboxLayer)) {
    return resolveLayerReference(mapboxLayer as MapboxStyleReference);
  } else if (mapboxLayer) {
    return Promise.resolve(applyPossibleLayerTemplate(mapboxLayer, templates).map(l => JSON.stringify(l)));
  } else {
    return Promise.resolve([]);
  }
}

function resolveLayerReference(ref: MapboxStyleReference): Promise<string[]> {
  return fetch(ref.url)
    .then(response => response.text())
    .then(text => parseStyle(text, ref.replace || []))
    .then(style => {
      const doInclude = getIncludePredicate(ref.include, ref.exclude);
      const layers = (style.layers || [])
        .filter(layer => doInclude(layer.id))
        .map(layer => JSON.stringify(layer));
      return layers;
    })
    .catch(() => {
      return [];
    });
}

function parseStyle(text: string, replacements: Replacement[]): maplibregl.StyleSpecification {
  const t = replacements.reduce((acc, replace) => {
    return acc.replace(new RegExp(replace.from, "g"), replace.to);
  }, text);
  return JSON.parse(t) as maplibregl.StyleSpecification;
}

function getIncludePredicate(include?: string, exclude?: string): (s: string) => boolean {
  const includeRE = include ? new RegExp("^" + include + "$") : /^.*$/;
  const excludeRE = exclude ? new RegExp("^" + exclude + "$") : /^$/;
  return s => includeRE.test(s) && !excludeRE.test(s);
}

/**
 * Merge the user's saved configuration with the public configuration:
 * sources: all public sources + any additional sources from the user's configuration
 * bookmarks: all public bookmarks + any additional bookmarks from the user's configuration
 * layers: the user's layer tree, but properties except visibility and opacity are taken from the public layer, if available
 *   all public layers not in the user's layer tree, are added at the end
 *
 * @param publicConfig the public configuration
 * @param privateConfig the user's saved configuration
 */
function mergeMapConfiguration(publicConfig: MapConfiguration, privateConfig: MapConfiguration | null): MapConfiguration {
  if (privateConfig && privateConfig.layers) {
    return {
      ...publicConfig,
      sources: mergeSources(privateConfig.sources!, publicConfig.sources!),
      layers: mergeLayers(privateConfig.layers, getLogicalLayersById(publicConfig.layers!), true),
      bookmarks: mergeBookmarks(privateConfig.bookmarks || [], publicConfig.bookmarks || []),
    };
  } else {
    return publicConfig;
  }
}

function getLogicalLayersById(layers: LayerBase[]): {[prop: string]: Layer} {
  let result: {[prop: string]: Layer} = {};
  layers.forEach(layer => {
    if (isLogicalLayer(layer)) {
      result[layer.id!] = layer;
    } else {
      const layerGroup = layer as LayerGroup;
      result = {...result, ...getLogicalLayersById(layerGroup.layers!)};
    }
  });
  return result;
}

function mergeLayers(layers: LayerBase[], publicLayers: {[prop: string]: LayerBase}, addRemaining: boolean): LayerBase[] {
  const result = layers.map(layer => {
    if (isLogicalGroup(layer)) {
      return {
        ...layer,
        layers: mergeLayers(layer.layers!, publicLayers, false),
      };
    } else if (publicLayers[layer.id!]) {
      const publicLayer = publicLayers[layer.id!];
      delete publicLayers[layer.id!];
      return {
        ...publicLayer,
        visible: layer.visible,
        opacity: layer.opacity,
      };
    } else {
      return {
        ...layer,
        searched: false,
        filtered: false,
      } as LayerBase;
    }
  });
  if (addRemaining) {
    Object.values(publicLayers).reverse().forEach(layer => result.unshift(layer));
  }
  return result;
}

function mergeSources(sources: Source[], publicSources: Source[]): Source[] {
  const sourceIds = publicSources.map(source => source.id);
  const additionalSources = sources.filter(source => !sourceIds.includes(source.id));
  return [...publicSources, ...additionalSources];
}

function mergeBookmarks(bookmarks: Bookmark[], publicBookmarks: Bookmark[]): Bookmark[] {
  const bookmarkNames = publicBookmarks.map(bookmark => bookmark.name);
  const additionalBookmarks = bookmarks.filter(bookmark => !bookmarkNames.includes(bookmark.name));
  return [...publicBookmarks, ...additionalBookmarks];
}

export {fixMapConfiguration, resolveMapConfigurationReferences, mergeMapConfiguration};
