/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  MessageKey,
  messages,
  NavigationService,
  Paths,
  Role,
  SecurityService,
  SortService,
  UserDetails,
  useService,
} from "@icm/core-common";
import {
  Button,
} from "@mui/material";
import {
  WithStyles,
  withStyles,
} from "@mui/styles";
import * as React from "react";

import {LoginStepContainer} from "../../components";
import styles from "./RoleSelectionPageStyle";

type AllProps = WithStyles<typeof styles>

type AllPropsWithServices = AllProps & {
  securityService: SecurityService
  navigationService: NavigationService,
};

class RoleSelectionPage extends React.Component<AllPropsWithServices, {}> {
  constructor(props: Readonly<AllPropsWithServices>) {
    super(props);
    this.state = {};
  }

  public render() {
    const classes = this.props.classes!;
    const user: UserDetails = this.getMandatoryUser();
    const roles = this.props.securityService.getSelectableRoles(user?.roles || []);
    const sortedRoles = [...roles].sort(SortService.createComparator(role => (role.displayName ?? role.name)?.toLowerCase()));
    return (
      <LoginStepContainer>
        <form className={classes.form}>
          {sortedRoles.length > 0
            ? sortedRoles.map(role => (
              <Button key={role.name}
                      type="button"
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => this.handleRoleSelection(user, role)}
              >
                {role.displayName ?? role.name}
              </Button>
            )) : (
              <div className={classes.error}>
                {messages.get(MessageKey.CORE.LOGIN.ERROR.NO_ROLES)}
                <Button type="button"
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this.backToLogin()}
                >
                  {messages.get(MessageKey.CORE.LOGIN.ERROR.BACK_TO_LOGIN)}
                </Button>
              </div>
            )}
        </form>
      </LoginStepContainer>
    );
  }

  private handleRoleSelection(user: UserDetails, selectedRole: Role): void {
    this.props.securityService.selectActiveRoles([selectedRole], user.roles);
  }

  private backToLogin(): void {
    this.props.securityService.logout().then();
  }

  private getMandatoryUser(): UserDetails {
    const optionalUser = this.props.securityService.getCurrentUserDetails();
    if (!optionalUser) {
      console.log("Role selection page not finding current user, redirecting to login");
      this.props.navigationService.navigate(Paths.LOGIN);
    }
    return optionalUser!;
  }
}

function RoleSelectionPageWithServices(props: AllProps) {
  const securityService = useService("SECURITY");
  const navigationService = useService("NAVIGATION");
  return <RoleSelectionPage {...props} securityService={securityService} navigationService={navigationService} />;
}


const page = withStyles(styles)(RoleSelectionPageWithServices);
export {page as RoleSelectionPage};
