/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  MessageKey,
  useDialogContext,
  useMessages,
  ParameterUtilities,
  ActionRunners,
} from "@icm/core-common";

import {useDeleteEntity} from "./useDeleteEntity";
import {OnEntityDeleted} from "./useUpdatingInfiniteEntityList";

export const useCommonEntityActionHandlers = (entityType: string, onEntityDeleted: OnEntityDeleted): ActionRunners => {
  const {useConfirmDialog} = useDialogContext();
  const {getMessage} = useMessages();
  const deleteEntity = useDeleteEntity(entityType, onEntityDeleted);
  const confirm = useConfirmDialog();
  return {
    DELETE: (parameters) => {
      return confirm({title: getMessage(MessageKey.CORE.FORM.CONFIRM_DELETE)})
        .then(okay => okay && deleteEntity(ParameterUtilities.getResolvedParameter("id", parameters), ParameterUtilities.getResolvedParameter("version", parameters)));
    },
  };
};
