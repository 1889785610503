/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {selectTab} from "@icm/core-common";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

/**
 * Dispatch tab selection.
 */
export const useSelectTab = () => {
  const dispatch = useDispatch();
  return useCallback((newTabId: string) => {
    if (newTabId) {
      dispatch(selectTab({tabId: newTabId}));
    }
  }, [dispatch]);
};
