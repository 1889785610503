/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {FormTextComponentProps, useFormGeneratorContext} from "@icm/core-common";
import {useShowFeatureOnMap, useSelectOnMap, FormCallButton, TextBoxComponent} from "@icm/core-web";
import {getHighestEntityKeyValueIfPossible, isEntity} from "@icm/entity-common";
import {Place as PlaceIcon} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import React, {useCallback} from "react";


export const WebFormTextComponent = (props: FormTextComponentProps) => {
  const {component, readOnly, originalValue, format} = props;
  const showFeatureOnMap = useShowFeatureOnMap("MAP_VIEW");
  const selectOnMap = useSelectOnMap("MAP_VIEW");
  const formGeneratorContext = useFormGeneratorContext();
  const entity: any = formGeneratorContext.entity;

  const getShowGeometryOnMapButton = useCallback((readonly: boolean, value: any): JSX.Element | undefined => {
    if (value || !readonly) {
      const shouldDisable = !value || (typeof value !== "object" && !Array.isArray(value));
      const label = isEntity(entity) ? getHighestEntityKeyValueIfPossible(entity) : undefined;
      return (
        <IconButton
          key="showOnMap"
          disabled={shouldDisable}
          onClick={() => showFeatureOnMap([{value, indexLabel: ""}], label)}
          size="large"
        >
          <PlaceIcon />
        </IconButton>
      );
    }
    return undefined;
  }, [showFeatureOnMap, entity]);

  const getSelectFeaturesOnMapButton = useCallback((readonly: boolean, value: any, propertyNames: string,
                                                    sourcePattern: string, follow: boolean): JSX.Element | undefined => {
    if (value || !readonly) {
      return (
        <IconButton
          key="showOnMap"
          disabled={!value}
          onClick={() => selectOnMap(sourcePattern, propertyNames, value, follow)}
          size="large"
        >
          <PlaceIcon />
        </IconButton>
      );
    }
    return undefined;
  }, [selectOnMap]);

  const getActionComponents = useCallback((readonly: boolean, frmt: string | undefined, value: any): JSX.Element[] => {
    const relatedObject = {
      id: entity.id,
      type: entity.type,
      dynamicAttributes: entity.dynamicAttributes,
      object: entity,
    };
    const elements: JSX.Element[] = [];
    if (frmt) {
      const frmtParts = frmt.split(":");
      const name =  frmtParts.length > 0 ? frmtParts[0] : "";
      switch (name) {
        case "COORDINATE":
        case "POINT":
        case "LINESTRING":
        case "POLYGON": {
          const button = getShowGeometryOnMapButton(readonly, value);
          button && elements.push(button);
          break;
        }
        case "MAP-SHOW":
        case "MAP-FOLLOW": {
          const follow = name === "MAP-FOLLOW";
          const info = frmt.substring(name.length + 1);
          const colonPos = info.indexOf(":");
          const propertyNames = info.substring(0, colonPos);
          const sourcePattern = info.substring(colonPos + 1) || "*";
          if (propertyNames && sourcePattern) {
            const button = getSelectFeaturesOnMapButton(readonly, value, propertyNames, sourcePattern, follow);
            button && elements.push(button);
          }
          break;
        }
        case "CALL": {
          if (!readonly) {
            elements.push(<FormCallButton key="call" calledNumber={value} format={frmt} relatedObject={relatedObject} />);
          }
          break;
        }
      }
    }
    return elements;
  }, [entity, getSelectFeaturesOnMapButton, getShowGeometryOnMapButton]);

  const actionComponents = React.useMemo(() => {
    return getActionComponents?.(readOnly || false, format, originalValue) || [];
  }, [getActionComponents, readOnly, originalValue, format]);
  return (
    <TextBoxComponent {...props}
                      type="text"
                      multiline={component === "TEXT_AREA"}
                      adornments={actionComponents}
    />
  );
};
