/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {TEXT} from "../../../constant";
import {getNamedComponentFactory} from "../../../ui";

type Props = {
  label?: string;
  value?: any;
  helperText?: string
}
export const FormLabelComponent = (props: Props) => {
  const Text = getNamedComponentFactory(TEXT) || ((spanProps) => <span>{spanProps.children}</span>);
  return (
    <div className="form-label-widget">
      {props.label && (
        <Text variant="h6">{props.label}</Text>
      )}
      {typeof props.value === "string" && props.value.length > 0 && (
        <Text variant="body2">{props.value}</Text>
      )}
      {props.helperText && (
        <Text variant="body2">{props.helperText}</Text>
      )}
    </div>
  );
};
