/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IdentifiableViewActionHandler, userDownloadService} from "@icm/core-common";
import {REM_INCIDENT_TYPE} from "@icm/rem-rail-common";
import {useMemo} from "react";

import {AuditLogApi} from "../api/AuditLogApi";


export const useAuditLogDownloadHandler = ((incidentNumber?: string): IdentifiableViewActionHandler<void> => {
  return useMemo(() => ({
    key: "DOWNLOAD_AUDITLOG",
    run: () => userDownloadService.startDownloadWith(
      abortController => AuditLogApi.downloadAuditlog({
        type: REM_INCIDENT_TYPE,
        number: incidentNumber,
      }, abortController),
    ),
    enabled: !!incidentNumber,
  }), [incidentNumber]);
});
