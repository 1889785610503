/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  IFormComponentProps,
  MessageKey,
  numberService, Parameter,
  ParameterUtilities,
  useMessages,
  useService,
} from "@icm/core-common";
import {ButtonComponent} from "@icm/core-web";
import {Point} from "geojson";
import * as React from "react";
import {useMemo, useState} from "react";

export type WeatherSource = {
  lastSeenLocation: Point | null,
  lastSeenDate: Date | null,
};

export type WeatherTarget = {
  lowCloudsPercentage: number,
  precipitationType: string,
  temperature: number,
  wavesHeight: number,
  wavesDirection: number,
  wavesPeriod: number,
  windDirection: number,
  windSpeed: number,
  windGust: number,
  windWavesHeight: number,
  windWavesDirection: number,
  windWavesPeriod: number,
};


const toValue = (entity: any, fieldName: string): any => entity.dynamicAttributes[fieldName] ? entity.dynamicAttributes[fieldName].value : null;

const fillInValue = (handleChange: (field: any, value: any) => void, fieldName: string, value: string | undefined): void => {
  const field = {
    formFieldType: "GENERIC",
    widgetType: "GENERIC_FIELD",
    valueBinding: `dynamicAttributes.${fieldName}.value`,
  };

  handleChange(field, value);
};

export type WeatherFormComponentProps = {
  parameterList?: Parameter[],
  entity: any
} & IFormComponentProps<any>;

/**
 * WeatherFormComponent
 *
 * @param props
 * @constructor
 */
export const WeatherFormComponent = (props: WeatherFormComponentProps) => {
  const entity = props.entity;

  const parameters = ParameterUtilities.flattenParameters(props.parameterList);

  const {getMessage} = useMessages();

  const formatWithUnit = (value: number, unit: string | null): string => {
    if (value == null) {
      return "---";
    }

    if (unit == null) {
      return value.toString();
    }
    const translatedUnit = translateUnit(unit);
    const valueFormatted = numberService.format(value);
    return valueFormatted + " " + translatedUnit;
  };

  const translateUnit = (unit: string): string => {
    switch (unit) {
      case "CELSIUS_DEGREE":
        return "°C";
      case "METER":
        return "m";
      case "DEGREE":
        return "°";
      case "SECOND":
        return "s";
      case "METERS_PER_SECOND":
        return "m/s";
    }
    return unit;
  };

  const translatePrecipitationType = (precipitationType: string | null): string => {
    switch (precipitationType) {
      case "NOTHING":
        return MessageKey.CORE.WEATHER.PRECIPITATION_TYPE.NOTHING;
      case "RAIN":
        return MessageKey.CORE.WEATHER.PRECIPITATION_TYPE.RAIN;
      case "SNOW":
        return MessageKey.CORE.WEATHER.PRECIPITATION_TYPE.SNOW;
      case "WET_SNOW":
        return MessageKey.CORE.WEATHER.PRECIPITATION_TYPE.WET_SNOW;
      case "MIX_RAIN_SNOW":
        return MessageKey.CORE.WEATHER.PRECIPITATION_TYPE.MIX_RAIN_SNOW;
    }
    return MessageKey.CORE.WEATHER.PRECIPITATION_TYPE.UNKNOWN;
  };

  const handleResult = ((result: any) => {
    fillInValue(props.handleChange, parameters["lowCloudsPercentage"], formatWithUnit(result.lowCloudsPercentage, "%"));
    fillInValue(props.handleChange, parameters["precipitationType"], getMessage(translatePrecipitationType(result.precipitationType)));
    fillInValue(props.handleChange, parameters["temperature"], formatWithUnit(result.temperature, result.temperatureUnit));
    fillInValue(props.handleChange, parameters["wavesHeight"], formatWithUnit(result.wavesHeight, result.wavesHeightUnit));
    fillInValue(props.handleChange, parameters["wavesDirection"], formatWithUnit(result.wavesDirection, result.wavesDirectionUnit));
    fillInValue(props.handleChange, parameters["wavesPeriod"], formatWithUnit(result.wavesPeriod, result.wavesPeriodUnit));
    fillInValue(props.handleChange, parameters["windDirection"], formatWithUnit(result.windDirection, result.wavesDirectionUnit));
    fillInValue(props.handleChange, parameters["windSpeed"], formatWithUnit(result.windSpeed, result.windSpeedUnit));
    fillInValue(props.handleChange, parameters["windGust"], formatWithUnit(result.windGust, result.windGustUnit));
    fillInValue(props.handleChange, parameters["windWavesHeight"], formatWithUnit(result.windWavesHeight, result.windWavesHeightUnit));
    fillInValue(props.handleChange, parameters["windWavesDirection"], formatWithUnit(result.windWavesDirection, result.windWavesDirectionUnit));
    fillInValue(props.handleChange, parameters["windWavesPeriod"], formatWithUnit(result.windWavesPeriod, result.windWavesPeriodUnit));
  });

  return (
    <WeatherComponent
        weatherSource={{
          lastSeenLocation: toValue(entity, parameters["lastSeenLocation"]),
          lastSeenDate: toValue(entity, parameters["lastSeenDate"]),
        }}
        handleResult={handleResult}
        weatherTarget={{
          lowCloudsPercentage: parameters["lowCloudsPercentage"],
          precipitationType: parameters["precipitationType"],
          temperature: parameters["temperature"],
          wavesHeight: parameters["wavesHeight"],
          wavesDirection: parameters["wavesDirection"],
          wavesPeriod: parameters["wavesPeriod"],
          windDirection: parameters["windDirection"],
          windSpeed: parameters["windSpeed"],
          windGust: parameters["windGust"],
          windWavesHeight: parameters["windWavesHeight"],
          windWavesDirection: parameters["windWavesDirection"],
          windWavesPeriod: parameters["windWavesPeriod"],
        }}
    />
  );
};

export type WeatherComponentProps = {
  weatherSource: WeatherSource,
  weatherTarget: WeatherTarget,
  handleResult: (result: any) => void
};

/**
 * WeatherComponent
 *
 * @param props
 * @constructor
 */
export const WeatherComponent = (props: WeatherComponentProps) => {
  return useMemo(() => (<Weather {...props} />), [props]);
};

/**
 * Weather
 *
 * @constructor
 * @param weatherProps
 */
const Weather = (weatherProps: WeatherComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const weatherService = useService("WEATHER_SERVICE");
  const handleClick = () => {
    if (weatherProps.weatherSource.lastSeenLocation !== null
        && weatherProps.weatherSource.lastSeenLocation.coordinates !== undefined
        && weatherProps.weatherSource.lastSeenDate !== null
    ) {
      setError(false);
      setLoading(true);

      let date = weatherProps.weatherSource.lastSeenDate;
      if (date) {
        date = new Date(date);
      }

      weatherService.getWeatherData(weatherProps.weatherSource.lastSeenLocation?.coordinates, date)
        .then(weatherData => {
          weatherProps.handleResult(weatherData);
          setLoading(false);
        }).catch(e => {
        console.log(e);
      });
    } else {
      setError(true);
    }
  };

  const {getMessage} = useMessages();

  return (
    <>
      <ButtonComponent onClick={handleClick} label={getMessage(MessageKey.CORE.WEATHER.BUTTON)} />
      <span> {loading ? getMessage(MessageKey.CORE.WEATHER.LOADING) : ""}</span>
      <span> {error ? getMessage(MessageKey.CORE.WEATHER.ERROR) : ""}</span>
    </>
  );
};

export const weatherComponentFactory = (props: WeatherComponentProps) => <WeatherComponent {...props} />;
