/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import MessageApi from "../../api/MessageApi";
import {IMessageState} from "./types";

const initialMessageState: IMessageState = {
  messages: {},
  loading: false,
  ready: false,
};

export const fetchMessages = createAsyncThunk("@@messages/fetchMessages", MessageApi.getAllMessages);

const {reducer} = createSlice({
  name: "@@messages",
  initialState: initialMessageState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(fetchMessages.pending, state => {
      state.loading = true;
    })
    .addCase(fetchMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.ready = true;
      action.payload.filter(msg => msg.code && msg.message).forEach(msg => state.messages[msg.code!] = msg.message!);
    })
    .addCase(fetchMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }),
});

export {reducer as messageReducer};
