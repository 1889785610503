/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  AutoCompleteComponent,
  dateService,
  FilterOperation,
  FilterOption,
  FilterSelectionItem,
  FilterValueType,
  formatService,
  getMatchingFilterOperation,
  MessageKey,
  PossibleValue,
  Runnable,
  useMessages,
} from "@icm/core-common";
import {CancelSharp, Clear, DeleteOutline, Save} from "@mui/icons-material";
import {Button, Divider, Grid, Menu, MenuItem, Switch, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Box} from "@mui/system";
import * as React from "react";

import {DateComponent, DateTimeComponent, IconButtonComponent, RelativeDateComponent} from "../form";
import {TextBoxComponent} from "../form/textbox/TextBoxComponent";
import styles from "./BasicFilterStyle";

const useStyles = makeStyles(styles);

export type BasicFilterProps = {
  /** all available filter options */
  filterOptions: FilterOption[]
  /** the selected filter options including selected operation and value(s) */
  selectedFilters: FilterSelectionItem[]

  /**
   * Callback which is triggered whenever the user changes FilterSelectionItems.
   *
   * @param selectedFilters the filters selected by the user plus the changes
   * @param valueChange boolean, whether an actual value change happened i.e. a change having an effect on the filtered values
   */
  onUpdateSelectedFilters: (selectedFilters: FilterSelectionItem[], valueChange: boolean) => void

  addFilterIcon?: JSX.Element

  /**
   * Callback to be triggered when the filter configuration should be cancelled.
   * This is only required when using this component as part of
   * another stateful component.
   */
  onCancel?: Runnable

  /**
   * Callback to be triggered when the filter configuration should be saved.
   * This is only required when using this component as part of
   * another stateful component.
   */
  onSave?: Runnable
}

export const BasicFilter = React.memo((props: BasicFilterProps) => {
  // the callbacks must always use the newest props:
  const propsRef = React.useRef(props);
  propsRef.current = props;

  const [menuAnchorElement, setMenuAnchorElement] = React.useState<HTMLElement | undefined>(undefined);
  const {getMessage} = useMessages();
  const closeMenu = React.useCallback(() => {
    setMenuAnchorElement(undefined);
  }, []);

  const addFilters = React.useCallback((filterOptionsToAdd: FilterOption[]) => {
    let actualChange = false;
    const selectedFiltersToAdd = filterOptionsToAdd.map(filterOption => {
      // the filter only changes, if there are default values for the added options:
      actualChange = actualChange || (filterOption.defaultFilterOperation?.defaultValues?.some(v => v != null) ?? false);
      return {
        filterOptionId: filterOption.id!,
        filterUserOperation: filterOption.defaultFilterOperation?.filterUserOperation,
        filterValueType: filterOption.defaultFilterOperation?.filterValueType,
        values: undefined,
      } as FilterSelectionItem;
    });
    propsRef.current.onUpdateSelectedFilters([...propsRef.current.selectedFilters, ...selectedFiltersToAdd], actualChange);
    closeMenu();
  }, [closeMenu]);

  const updateFilter = React.useCallback((selectedFilterOptionId: string, selectedFilter: FilterSelectionItem) => {
    const updatedSelectedFilters = propsRef.current.selectedFilters.map((sf) => sf.filterOptionId === selectedFilterOptionId ? selectedFilter : sf);
    propsRef.current.onUpdateSelectedFilters(updatedSelectedFilters, true);
  }, []);

  const removeFilters = React.useCallback((selectedFilterOptionIds: string[]) => {
    let actualChange = false;
    const updatedSelectedFilters = propsRef.current.selectedFilters.filter((sf) => {
      if (sf.filterOptionId && selectedFilterOptionIds.includes(sf.filterOptionId)) {
        actualChange = actualChange || sf.values !== undefined;
        return false;
      }
      return true;
    });
    propsRef.current.onUpdateSelectedFilters(updatedSelectedFilters, actualChange);
    closeMenu();
  }, [closeMenu]);

  const removeFilter = React.useCallback((selectedFilterOptionId: string) => {
    removeFilters([selectedFilterOptionId]);
  }, [removeFilters]);

  const resetFilters = React.useCallback((selectedFilterOptionIds: string[]) => {
    let actualChange = false;
    const resetSelectedFilters = propsRef.current.selectedFilters.map((sf) => {
      if (sf.filterOptionId && sf.values !== undefined && selectedFilterOptionIds.includes(sf.filterOptionId)) {
        actualChange = true;
        return {
          ...sf,
          values: undefined,
        };
      }
      return sf;
    });
    propsRef.current.onUpdateSelectedFilters(resetSelectedFilters, actualChange);
    closeMenu();
  }, [closeMenu]);

  const resetFilter = React.useCallback((selectedFilterOptionId: string) => {
    resetFilters([selectedFilterOptionId]);
  }, [resetFilters]);

  const {
    filterOptions,
    selectedFilters,
    onSave,
    onCancel,
  } = props;
  const classes = useStyles();

  if (filterOptions.length === 0) {
    return <></>;
  }


  return (
    <Box className={classes.root}>
      <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
      >
        {selectedFilters.map((selectedFilter) => {
          const filterOption = filterOptions.find(fo => fo.id === selectedFilter.filterOptionId);
          return filterOption && (
            <FilterOptionComponent key={selectedFilter.filterOptionId}
                                   filterSelection={selectedFilter}
                                   filterOption={filterOption}
                                   onUpdate={updateFilter}
                                   onRemove={removeFilter}
                                   onReset={resetFilter}
            />
          );
        })}
        <Grid item xs className={classes.filterActionsContainer}>
          <Grid container justifyContent="space-between" wrap="nowrap" gap={1}>
            <Grid item>
              <Button variant="outlined"
                      size="medium"
                      aria-label={getMessage(MessageKey.CORE.FILTER.OPTIONS.ADD)}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorElement(event.currentTarget)}
              >
                {getMessage(MessageKey.CORE.FILTER.OPTIONS.ADD)}
              </Button>
              {menuAnchorElement && (
              <FilterMenuComponent anchorElement={menuAnchorElement}
                                   filterSelections={selectedFilters}
                                   filterOptions={filterOptions}
                                   onAdd={addFilters}
                                   onRemove={removeFilters}
                                   onReset={resetFilters}
                                   onClose={closeMenu}
              />
              )}
            </Grid>
            <Grid item>
              <Grid container gap={1}>
                <Grid item>
                  {onCancel !== undefined && (
                    <Button variant="outlined"
                            aria-label={getMessage(MessageKey.CORE.CANCEL)}
                            startIcon={<CancelSharp />}
                            onClick={(_) => onCancel()}
                    >
                      {getMessage(MessageKey.CORE.CANCEL)}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {onSave !== undefined && (
                    <Button variant="outlined"
                            size="medium"
                            aria-label={getMessage(MessageKey.CORE.SAVE)}
                            startIcon={<Save />}
                            onClick={(_) => onSave()}
                    >
                      {getMessage(MessageKey.CORE.SAVE)}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

type FilterOptionProps = {
  filterSelection: FilterSelectionItem,
  filterOption: FilterOption,
  onUpdate: (optionId: string, fs: FilterSelectionItem) => void;
  onReset: (optionId: string) => void;
  onRemove: (optionId: string) => void;
};

const FilterOptionComponent = React.memo(function FilterOptionComponent(props: FilterOptionProps) {
  const {
    filterSelection,
    filterOption,
    onUpdate,
    onReset,
    onRemove,
  } = props;
  const {getMessage} = useMessages();
  const handleChange = (values?: unknown[]) => {
    const flatValues: any = values?.map((v: any) => v && v.value ? v.value : v);
    const oldValues = filterSelection.values;
    const newValues = flatValues && flatValues.length > 0 ? stringifyValues(flatValues, filterSelection.filterValueType) : undefined;
    if (filterSelection.filterOptionId && (newValues?.length !== oldValues?.length || newValues?.some((v, i) => v !== oldValues?.[i]))) {
      onUpdate(filterSelection.filterOptionId, {
        ...filterSelection,
        values: newValues,
      });
    }
  };
  const filterOperation = getMatchingFilterOperation(filterSelection, filterOption) ?? filterOption.defaultFilterOperation!;
  const filterOperations = [filterOption.defaultFilterOperation!, ...(filterOption.filterOperations ?? [])];
  return (
    <Grid xs={12} md={6} lg={4} xl={3} item>

      <Grid container alignItems="center" spacing={0} wrap="nowrap">
        <Grid item xs={12} zeroMinWidth>
          <FilterInputComponent filterOption={filterOption}
                                filterOperation={filterOperation}
                                selectedValues={filterSelection.values && parseValues(filterSelection.values, filterOperation.filterValueType!)}
                                handleChange={handleChange}
          />
        </Grid>
        {filterOperations.length === 2 && (
        <Grid item xs="auto">
          <Tooltip
                title={filterOperations[0] !== filterOperation
                  ? getMessage(MessageKey.CORE.FORM.DATE.RELATIVE.SWITCH.USE_ABSOLUTE_INPUT)
                  : getMessage(MessageKey.CORE.FORM.DATE.RELATIVE.SWITCH.USE_RELATIVE_INPUT)}
          >
            <Switch checked={filterOperations[0] !== filterOperation}
                    onChange={(_evt, checked) => {
                      const oldOperation = checked ? filterOperations[0]! : filterOperations[1]!;
                      const newOperation = checked ? filterOperations[1]! : filterOperations[0]!;
                      filterSelection.filterOptionId && onUpdate(filterSelection.filterOptionId, {
                        ...filterSelection,
                        filterUserOperation: newOperation.filterUserOperation,
                        filterValueType: newOperation.filterValueType,
                        values: stringifyValues(convertValues(oldOperation, newOperation, filterSelection.values)!, newOperation.filterValueType),
                      });
                    }}
            />
          </Tooltip>
        </Grid>
        )}
        <Grid item xs="auto">
          <IconButtonComponent icon={<Clear />}
                               tooltip={getMessage(MessageKey.CORE.FILTER.ATTRIBUTE.RESET)}
                               padding="large"
                               handleClick={() => filterSelection.filterOptionId && onReset(filterSelection.filterOptionId)}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButtonComponent icon={<DeleteOutline />}
                               tooltip={getMessage(MessageKey.CORE.FILTER.ATTRIBUTE.REMOVE)}
                               padding="large"
                               handleClick={() => filterSelection.filterOptionId && onRemove(filterSelection.filterOptionId)}
          />
        </Grid>
      </Grid>

    </Grid>
  );
});

type FilterMenuProps = {
  anchorElement?: HTMLElement,
  filterSelections: FilterSelectionItem[],
  filterOptions: FilterOption[],
  onAdd: (options: FilterOption[]) => void,
  onRemove: (filterSelectionOptionIds: string[]) => void,
  onReset: (filterSelectionOptionIds: string[]) => void,
  onClose: () => void,
};

const FilterMenuComponent = React.memo(function FilterMenuComponent(props: FilterMenuProps) {
  const {getMessage} = useMessages();
  if (!props.anchorElement) {
    return <></>;
  }
  const unusedFilterOptions = props.filterOptions.filter(fo => !props.filterSelections.find(fs => fs.filterOptionId === fo.id));

  const getFilterOptionIds = (): string[] => props.filterOptions.filter(option => option.id !== undefined).map(option => option.id as string);

  const handleResetAll = () => {
    const optionIds = getFilterOptionIds();
    props.onRemove(optionIds);
  };

  const handleRemoveAll = () => {
    const optionIds = getFilterOptionIds();
    props.onReset(optionIds);
  };

  return (
    <Menu open={!!props.anchorElement} anchorEl={props.anchorElement} onClose={props.onClose}>
      {unusedFilterOptions.map((fo) => (
        <MenuItem key={fo.id} onClick={() => props.onAdd([fo])}>
          <span dangerouslySetInnerHTML={{__html: fo.label ?? ""}} />
        </MenuItem>
      ))}
      {unusedFilterOptions.length > 0 && <Divider />}
      {unusedFilterOptions.length > 0 && (
        <MenuItem onClick={() => props.onAdd(unusedFilterOptions)}>
          {getMessage(MessageKey.CORE.FILTER.ADD_ALL)}
        </MenuItem>
      )}
      {props.filterSelections.length > 0 && (
        <MenuItem onClick={handleResetAll}>
          {getMessage(MessageKey.CORE.FILTER.REMOVE_ALL)}
        </MenuItem>
      )}
      {props.filterSelections.length > 0 && (
        <MenuItem onClick={handleRemoveAll}>
          {getMessage(MessageKey.CORE.FILTER.RESET_ALL)}
        </MenuItem>
      )}
    </Menu>
  );
});

type FilterInputProps = {
  filterOption: FilterOption;
  filterOperation: FilterOperation;
  selectedValues?: any[];
  handleChange: (values?: unknown[]) => void
};

const FilterInputComponent = React.memo(function FilterInputComponent(props: FilterInputProps) {
  switch (props.filterOperation.filterValueType) {
    case "DATE":
      return <DateFilterInputComponent {...props} />;
    case "PERIOD":
      return <PeriodFilterInputComponent {...props} />;
    case "DATETIME":
      return <DateTimeFilterInputComponent {...props} />;
    case "ENUM":
      return <EnumFilterInputComponent {...props} />;
    case "STRING":
      return <StringFilterInputComponent {...props} />;
    case "BOOLEAN":
      return <BooleanFilterInputComponent {...props} />;
    case "NUMBER":
      return <NumberFilterInputComponent {...props} />;
    case "GEOMETRY":
      return <GeometryFilterInputComponent {...props} />;
    case "TIME":
    default:
      throw Error(`Component of type ${props.filterOperation.filterValueType} not implemented`);
  }
});

const DateFilterInputComponent = React.memo(function DateFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      return (
        <DateComponent label={label}
                       handleChange={(value?: Date) => handleChange(value ? [value] : undefined)}
                       value={values[0] || null}
        />
      );
    case "RANGE":
      throw Error(`Not yet implemented: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

const PeriodFilterInputComponent = React.memo(function PeriodFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      return (
        <RelativeDateComponent label={label}
                               value={values[0] || ""}
                               handleChange={value => handleChange(value ? [value] : undefined)}
        />
      );
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

const DateTimeFilterInputComponent = React.memo(function DateTimeFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      return (
        <DateTimeComponent label={label}
                           handleChange={val => handleChange(val ? [val] : undefined)}
                           value={values[0] || null}
        />
      );
    case "RANGE":
      throw Error(`Not yet implemented: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

const EnumFilterInputComponent = React.memo(function EnumFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const {filterWidgetSettings} = filterOperation;
  const values = selectedValues || [];

  if (filterWidgetSettings?.possibleValuesValueBinding) {
    switch (filterOperation.filterValueCardinality) {
      case "MULTI":
        return (
          <AutoCompleteComponent label={label}
                                 value={values}
                                 multiple={true}
                                 onChange={handleChange}
                                 providedOptions={filterWidgetSettings.possibleValues}
                                 possibleValuesUrl={filterWidgetSettings.possibleValuesUrl}
                                 valueBinding={filterWidgetSettings.possibleValuesValueBinding}
                                 valueDisplay={filterWidgetSettings.possibleValuesDisplayBinding}
                                 hideClearButton={true}
          />
        );
      case "SINGLE":
      case null:
        return (
          <AutoCompleteComponent label={label}
                                 value={values}
                                 multiple={false}
                                 onChange={handleChange}
                                 providedOptions={filterWidgetSettings.possibleValues}
                                 possibleValuesUrl={filterWidgetSettings.possibleValuesUrl}
                                 valueBinding={filterWidgetSettings.possibleValuesValueBinding}
                                 valueDisplay={filterWidgetSettings.possibleValuesDisplayBinding}
                                 hideClearButton={true}
          />
        );
      default:
        throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
    }
  } else {
    throw Error("Require `filterWidgetSettings.possibleValuesValueBinding` for rendering `ENUM` filterOption:"
      + JSON.stringify(filterOption) + ", filterOperation:" + JSON.stringify(filterOperation));
  }
});

const StringFilterInputComponent = React.memo(function StringFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      return (
        <TextBoxComponent label={label}
                          value={values[0]}
                          type="text"
                          handleChange={(val?: string) => handleChange(val ? [val] : undefined)}
        />
      );
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

const BooleanFilterInputComponent = React.memo(function BooleanFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {getMessage} = useMessages();
  const {label = ""} = filterOption;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      const possibleValues: PossibleValue[] = [
        {
          value: "true",
          label: getMessage(MessageKey.CORE.YES),
        },
        {
          value: "false",
          label: getMessage(MessageKey.CORE.NO),
        },
      ];
      return (
        <AutoCompleteComponent label={label}
                               value={values}
                               multiple={false}
                               onChange={handleChange}
                               providedOptions={possibleValues}
                               valueDisplay="{{{label}}}"
                               valueBinding="value"
                               hideClearButton={true}
        />
      );
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

const NumberFilterInputComponent = React.memo(function NumberFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const toArray = (val?: any) => val != null ? [val] : undefined;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      const format = filterOperation.filterWidgetSettings?.format || "NUMBER";
      const number = values[0] ? Number(values[0]) : null;
      return (
        <FilterTextComponent label={label}
                             format={format}
                             value={number}
                             handleChange={(n?: number) => handleChange(toArray(n?.toString()))}
        />
      );
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

const GeometryFilterInputComponent = React.memo(function GeometryFilterInputComponent(props: FilterInputProps) {
  const {
    filterOption,
    filterOperation,
    selectedValues,
    handleChange,
  } = props;
  const {label = ""} = filterOption;
  const toArray = (val?: any) => val != null ? [val] : undefined;
  const toJson = (val?: any) => val != null ? JSON.stringify(val) : undefined;
  const values = selectedValues || [];

  switch (filterOperation.filterValueCardinality) {
    case "SINGLE":
    case null:
      const format = filterOperation.filterWidgetSettings?.format || "POLYGON";
      const [name] = format.split(":");
      switch (name) {
        case "POINT":
        case "LINESTRING":
        case "POLYGON":
        case "GEOMETRY":
          const geometry = values[0] ? JSON.parse(values[0]) : null;
          return (
            <FilterTextComponent label={label}
                                 format={format}
                                 value={geometry}
                                 handleChange={(g?: object) => handleChange(toArray(toJson(g)))}
                                 multiLine={name !== "POINT"}
            />
          );
        case "CIRCLE":
          const circle = values[0] ? JSON.parse(values[0]) : null;
          return (
            <FilterCircleComponent label={label} value={circle} handleChange={c => handleChange(toArray(toJson(c)))} />
          );
        default:
          throw Error(`Not supported: format ${format} for ${filterOperation.filterValueType}`);
      }
    default:
      throw Error(`Not supported: cardinality ${filterOperation.filterValueCardinality} for ${filterOperation.filterValueType}`);
  }
});

type FilterTextProps<T> = {
  label: string;
  format?: string;
  value?: T;
  handleChange: (value?: T) => void;
  multiLine?: boolean;
}

const FilterTextComponent = React.memo(function FilterTextComponent<T>(props: FilterTextProps<T>) {
  const {
    label,
    format,
    value,
    handleChange,
    multiLine,
  } = props;
  const formattedValue = formatService.format(value, format, "INPUT");
  const detailFormats = formatService.getDetailFormats(format);
  const formatter = formatService.getFormatter(format);
  const parser = formatService.getParser(format);
  const validate = parser ? (text?: string, fmt?: string) => !!text === (parser(text, fmt) != null) : undefined;
  // _value is not used as this function may become buggy, as the original version didn't have this parameter.
  const formatDisplay = formatter ? (_value: any, fmt?: string, as?: "LABEL" | "INPUT" | "FULL") => formatter(value, fmt, as) : undefined;
  return (
    <TextBoxComponent label={label}
                      value={formattedValue}
                      type="text"
                      multiline={multiLine}
                      validate={validate}
                      formats={detailFormats}
                      formatDisplay={formatDisplay}
                      handleChange={(text?: string, fmt?: string) => handleChange(parser ? parser(text, fmt) : text)}
    />
  );
});

type Circle = {
  type: "Circle";
  coordinates: [number, number];
  radius: string;
};

type FilterCircleProps = {
  label: string;
  value?: Circle;
  handleChange: (value?: Circle) => void;
}

const FilterCircleComponent = React.memo(function FilterCircleComponent(props: FilterCircleProps) {
  const [coordinates, setCoordinates] = React.useState(props.value?.coordinates);
  const [radius, setRadius] = React.useState(props.value?.radius && Number.parseFloat(props.value.radius));
  const {getMessage} = useMessages();
  const handleCoordinatesChange = (c: [number, number] | null | undefined) => {
    setCoordinates(c ?? undefined);
    if (radius && c !== coordinates) {
      props.handleChange(c ? {
        type: "Circle",
        coordinates: c,
        radius: radius + "m",
      } : undefined);
    }
  };
  const handleRadiusChange = (r: number | null | undefined) => {
    setRadius(r ?? undefined);
    if (coordinates && r !== radius) {
      props.handleChange(r ? {
        type: "Circle",
        coordinates: coordinates,
        radius: r + "m",
      } : undefined);
    }
  };
  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item xs={8}>
        <FilterTextComponent label={props.label} format="COORDINATE" value={coordinates} handleChange={handleCoordinatesChange} />
      </Grid>
      <Grid item xs={4}>
        <FilterTextComponent label={getMessage(MessageKey.CORE.FILTER.RADIUS)}
                             format="UNIT:METRE:METRE;KILOMETRE;MILE;NAUTICAL_MILE"
                             value={radius}
                             handleChange={handleRadiusChange}
        />
      </Grid>
    </Grid>
  );
});

function convertValues(from: FilterOperation, to: FilterOperation, values: unknown[] | undefined): unknown[] | undefined {
  if (from.filterValueType === to.filterValueType) {
    return values;
  } else if (values?.[0] && from.filterValueType === "DATE" && to.filterValueType === "PERIOD") {
    return [dateService.getDurationToDateFromNow(values[0] as Date)];
  } else if (values?.[0] && from.filterValueType === "PERIOD" && to.filterValueType === "DATE") {
    return [dateService.getStartOfDayFromNow(values[0] as string)];
  }
  return [];
}

function parseValues(values: string[], type: FilterValueType): any[] {
  return values.map(v => parseValue(v, type));
}

function parseValue(value?: string, type?: FilterValueType): any | undefined {
  switch (type) {
    case "DATE":
      return (value && dateService.parse(value)) || undefined;
    case "DATETIME":
      return (value && dateService.parse(value)) || undefined;
    case "ENUM":
    case "STRING":
    case "NUMBER":
    case "BOOLEAN":
    case "GEOMETRY":
    case "PERIOD":
      return value;
    case "TIME":
    default:
      throw Error(`Cannot parse value of type ${type}`);
  }
}

function stringifyValues(values: any[], type?: FilterValueType): string[] {
  return values.map(v => stringifyValue(v, type));
}

function stringifyValue(value: any, type?: FilterValueType) {
  switch (type) {
    case "DATE":
    case "DATETIME":
      return dateService.toISOString(value);
    case "ENUM":
    case "STRING":
    case "NUMBER":
    case "BOOLEAN":
    case "GEOMETRY":
    case "PERIOD":
      return value.toString();
    case "TIME":
    default:
      throw Error(`Cannot stringify value of type ${type}`);
  }
}
