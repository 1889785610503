/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {TEXT} from "../../../constant";
import {getNamedComponentFactory} from "../../../ui";
import {BadgeColor} from "../../badge";
import {LabelWithFieldStateMarker} from "../label";
import {styles} from "./ReadOnlyComponent.style";

interface IReadOnlyComponentProps {
  label: string;
  text?: string;
  badgeColor?: BadgeColor;
  adornments?: React.ReactNode[];
}

export const ReadOnlyComponent = ({label, badgeColor, text, adornments}: IReadOnlyComponentProps) => {
  const Text = getNamedComponentFactory(TEXT) || ((props) => <span>{props.children}</span>);
  return (
    <div id="readonly-container">
      <div id="readonly-label-container">
        <LabelWithFieldStateMarker label={label} badgeColor={badgeColor} />
      </div>
      <div className={styles.valueContainer} id="readonly-value-container">
        <Text variant="body1" className={styles.value}>{text}</Text>
        {adornments && adornments.length > 0 && <Text className={styles.adornments}>{adornments}</Text>}
      </div>
    </div>
  );
};
