/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:20.

/**
 * Generated from: at.cns.icm.chat.backend.api.data.ChatConfigurationDto
 */
export class ChatConfiguration {
    xmppConnectionUrl?: string;
    xmppDomain?: string;
    maxAttachmentSize?: number;
    entityLinking?: EntityLinking;

    static fromData(data: any): ChatConfiguration {
        if (!data) {
            return data;
        }
        const instance: ChatConfiguration = { xmppConnectionUrl: data.xmppConnectionUrl && __verifyIsString(data.xmppConnectionUrl), xmppDomain: data.xmppDomain && __verifyIsString(data.xmppDomain), maxAttachmentSize: data.maxAttachmentSize && __verifyIsNumber(data.maxAttachmentSize), entityLinking: data.entityLinking && EntityLinking.fromData(data.entityLinking) };
        return instance;
    }

    static getProperties(): string[] {
        return ['xmppConnectionUrl','xmppDomain','maxAttachmentSize','entityLinking'];
    }
}

/**
 * Generated from: at.cns.icm.chat.backend.api.data.EntityLinkingDto
 */
export class EntityLinking {
    idBinding?: string;
    entitySource?: EntitySource;

    static fromData(data: any): EntityLinking {
        if (!data) {
            return data;
        }
        const instance: EntityLinking = { idBinding: data.idBinding && __verifyIsString(data.idBinding), entitySource: data.entitySource && EntitySource.fromData(data.entitySource) };
        return instance;
    }

    static getProperties(): string[] {
        return ['idBinding','entitySource'];
    }
}

/**
 * Generated from: at.cns.icm.chat.backend.api.data.EntityReferenceDto
 */
export class EntityReference {
    id?: string;
    type?: string;
    version?: string;
    valueDisplay?: string;

    static fromData(data: any): EntityReference {
        if (!data) {
            return data;
        }
        const instance: EntityReference = { id: data.id && __verifyIsString(data.id), type: data.type && __verifyIsString(data.type), version: data.version && __verifyIsString(data.version), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','type','version','valueDisplay'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.EntitySourceDto
 */
export class EntitySource {
    entityType?: string;
    typeDisplay?: string;
    valueDisplay?: string;
    initialOptionsUrl?: string;
    optionsBySearchUrl?: string;
    optionsSortOrder?: SortOrder;

    static fromData(data: any): EntitySource {
        if (!data) {
            return data;
        }
        const instance: EntitySource = { entityType: data.entityType && __verifyIsString(data.entityType), typeDisplay: data.typeDisplay && __verifyIsString(data.typeDisplay), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), initialOptionsUrl: data.initialOptionsUrl && __verifyIsString(data.initialOptionsUrl), optionsBySearchUrl: data.optionsBySearchUrl && __verifyIsString(data.optionsBySearchUrl), optionsSortOrder: data.optionsSortOrder && data.optionsSortOrder };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityType','typeDisplay','valueDisplay','initialOptionsUrl','optionsBySearchUrl','optionsSortOrder'];
    }
}

export type SortOrder = "ASC" | "DESC";

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
