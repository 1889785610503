/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  FormGenerator,
  IFormDialogProps,
  MessageKey,
  useFormDialogComponent,
  useMessages,
} from "@icm/core-common";
import {Add} from "@mui/icons-material";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import * as React from "react";
import {useCallback} from "react";

import {ButtonComponent} from "./button/ButtonComponent";

export const FormDialogComponent = <T extends object>({entity, onClose, onSubmit, formConfiguration, submitLabel}: IFormDialogProps<T>) => {
  const {getMessage} = useMessages();

  const {handleChange, state} = useFormDialogComponent(entity);

  const submitButtonConfiguration = {
    submitLabel,
    submitIcon: "add",
    onSubmit: () => onSubmit(state.entity, state.propertyUpdates),
    iconOnly: true,
  };

  const handleClose = useCallback((_event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      return;
    }
    onClose();
  }, [onClose]);

  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={handleClose}>
      <FormGenerator
          formConfig={formConfiguration}
          entity={state.entity}
          submitButtonConfiguration={submitButtonConfiguration}
          onChange={handleChange}
          useSimpleValues={true}
          renderFormContainer={(children) => (
            <DialogContent>{children}</DialogContent>
          )}
          renderActionContainer={actions => (
            <DialogActions>
              <ButtonComponent variant="outlined" onClick={handleClose} label={getMessage(MessageKey.CORE.FORM.CANCEL)} />
              {actions}
            </DialogActions>
          )}
          renderSubmitButton={buttonProps => <ButtonComponent {...buttonProps} variant="contained" icon={<Add />} />}
      />
    </Dialog>
  );
};
