/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {LoadingSpinner} from "@icm/core-web";
import {AuditLogProps, useAuditLog} from "@icm/rem-auditlog-common";
import * as React from "react";

import {AuditLogRenderer} from "./AuditLogRenderer";

export const AuditLogComponent = (props: AuditLogProps) => {
  const {
    incidentNumber,
    entityType,
  } = props;
  const auditLogResult = useAuditLog(incidentNumber);

  return (
    <>
      {(auditLogResult.isLoading || auditLogResult.isFetching) && <LoadingSpinner />}
      {auditLogResult.data && <AuditLogRenderer auditLog={auditLogResult.data} type={entityType} />}
    </>
  );
};

export const auditLogComponentFactory = (props: AuditLogProps) => <AuditLogComponent {...props} />;
