/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  AbstractFormComponent,
  IFormComponentProps,
  MessageKey,
  messages,
} from "@icm/core-common";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {withStyles, WithStyles} from "@mui/styles";
import * as FileSaver from "file-saver";
import {DropzoneDialog} from "material-ui-dropzone";
import * as React from "react";

import BpmnProcessEditor, {BpmnProcessEditorHandle} from "./BpmnProcessEditor";
import styles from "./ProcessModelComponentStyle";

export type ProcessModel = {
  bpmn: string,
  svg: string,
}
export interface IProcessModelComponentProps extends IFormComponentProps<ProcessModel> {}

type AllProps = IProcessModelComponentProps & WithStyles<typeof styles>;

type ProcessModelComponentState = {
  value?: ProcessModel,
  error?: boolean,
  openEditorDialog: boolean,
  openUploadDialog: boolean,
}
class ProcessModelComponent extends AbstractFormComponent<ProcessModel, AllProps, ProcessModelComponentState> {
  private readonly EMPTY_PROCESS_BPMN: string = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n"
    + "<bpmn:definitions xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:bpmn=\"http://www.omg.org/spec/BPMN/20100524/MODEL\" xmlns:bpmndi=\"http://www.omg.org/spec/BPMN/20100524/DI\" xmlns:dc=\"http://www.omg.org/spec/DD/20100524/DC\" id=\"Definitions_0y7y78o\" targetNamespace=\"http://bpmn.io/schema/bpmn\" exporter=\"bpmn-js (https://demo.bpmn.io)\" exporterVersion=\"8.0.0\">\n"
    + "  <bpmn:process id=\"Process_1i8jpua\" isExecutable=\"false\">\n"
    + "    <bpmn:startEvent id=\"StartEvent_1hs0khm\" />\n"
    + "  </bpmn:process>\n"
    + "  <bpmndi:BPMNDiagram id=\"BPMNDiagram_1\">\n"
    + "    <bpmndi:BPMNPlane id=\"BPMNPlane_1\" bpmnElement=\"Process_1i8jpua\">\n"
    + "      <bpmndi:BPMNShape id=\"_BPMNShape_StartEvent_2\" bpmnElement=\"StartEvent_1hs0khm\">\n"
    + "        <dc:Bounds x=\"156\" y=\"81\" width=\"36\" height=\"36\" />\n"
    + "      </bpmndi:BPMNShape>\n"
    + "    </bpmndi:BPMNPlane>\n"
    + "  </bpmndi:BPMNDiagram>\n"
    + "</bpmn:definitions>";

  private readonly EMPTY_SVG = "<svg height=\"100\" width=\"100\"></svg>";

  private readonly processEditor: React.RefObject<BpmnProcessEditorHandle>;

  private readonly boundOpenEditor: () => void;
  private readonly boundHandleCloseEditor: (doSave: boolean) => void;
  private readonly boundHandleUpload: (files: File[]) => void;
  private readonly boundHandleCloseUpload: () => void;
  private readonly boundOpenUpload: () => void;
  private readonly boundDownloadBpmn: () => void;
  private readonly boundImportBpmn: (bpmn: string) => void;
  private readonly boundReset: () => void;

  constructor(props: AllProps) {
    super(props);
    const initialValue = this.props.value || {
      bpmn: this.EMPTY_PROCESS_BPMN,
      svg: this.EMPTY_SVG,
    };
    this.state = {
      value: initialValue,
      error: props.error,
      openEditorDialog: false,
      openUploadDialog: false,
    };
    this.boundOpenEditor = this.openEditor.bind(this);
    this.boundHandleCloseEditor = this.handleCloseEditor.bind(this);
    this.boundHandleUpload = this.handleUpload.bind(this);
    this.boundHandleCloseUpload = this.handleCloseUpload.bind(this);
    this.boundOpenUpload = this.openUpload.bind(this);
    this.boundImportBpmn = this.importBpmn.bind(this);
    this.boundDownloadBpmn = this.downloadBpmn.bind(this);
    this.boundReset = this.reset.bind(this);
    this.processEditor = React.createRef();
  }

  componentDidMount() {
  }

  protected renderEditableComponent() {
    if (this.state.error || !this.state.value) {
      console.debug("Value is invalid: ", this.state.value);
    }
    return this.renderComponent(false);
  }

  // not used as renderReadOnlyComponent is overridden
  protected renderReadOnlyText(): string | undefined {
    return "";
  }

  protected renderReadOnlyComponent(): React.ReactNode {
    return this.renderComponent(true);
  }

  private reset() {
    this.importBpmn(this.EMPTY_PROCESS_BPMN);
  }

  private openEditor() {
    this.setState(state => ({
      ...state,
      openEditorDialog: true,
    }));
  }

  private closeEditor() {
    this.setState(state => ({
      ...state,
      openEditorDialog: false,
    }));
  }

  private handleCloseEditor(doSave: boolean) {
    if (doSave && this.processEditor.current) {
      this.processEditor.current.processCurrentBpmn((bpmn: string, svg: string) => {
        this.handleProcessModelChange(bpmn, svg);
      });
    }
    this.closeEditor();
  }

  private handleProcessModelChange(bpmn: string, svg: string) {
    const value: ProcessModel = {bpmn: bpmn, svg: svg};
    this.setState(state => ({
      ...state,
      value: value,
    }));
    this.props.handleChange(value);
  }

  private openUpload() {
    this.setState(state => ({
      ...state,
      openUploadDialog: true,
    }));
  }

  private downloadBpmn() {
    if (this.processEditor.current) {
      this.processEditor.current.processCurrentBpmn((bpmn: string /* , svg: string*/) => {
        FileSaver.saveAs(new Blob([bpmn], {type: "application/xml;charset=utf-8"}), `process_${new Date().toISOString()}.bpmn`);
      });
    } else {
      console.log("Can not export BPMN as process editor is not present");
    }
  }

  private importBpmn(bpmn: string) {
    if (this.processEditor.current) {
      this.processEditor.current.importBpmn(bpmn);
    } else {
      console.log("Can not import BPMN as process editor is not present");
    }
  }

  private handleUpload(files: File[]) {
    this.closeUpload();
    if (files.length > 0) {
      const file: File = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const data: string | ArrayBuffer | null = reader.result;
        console.log("Read data from file", file, data);
        if (typeof (data) === "string") {
          this.boundImportBpmn(data);
        }
      };
      reader.readAsText(file);
    }
  }

  private closeUpload() {
    this.setState(state => ({
      ...state,
      openUploadDialog: false,
    }));
  }

  private handleCloseUpload() {
    this.closeUpload();
  }

  private renderComponent(readOnly: boolean): React.ReactNode {
    const bpmn = this.state.value ? this.state.value.bpmn : "";
    return (
      <div>
        <Grid container alignItems="center">
          <Grid item className={this.props.classes.label}>
            {this.props.label}
          </Grid>
          <Grid item>
            <Button disabled={this.props.disabled} variant="text" color="primary" onClick={this.boundOpenEditor}>
              {readOnly ? messages.get(MessageKey.CORE.VIEW) : messages.get(MessageKey.CORE.EDIT)}
            </Button>
            <Dialog open={this.state.openEditorDialog}
                    onClose={() => this.boundHandleCloseEditor(false)}
                    aria-labelledby="form-dialog-title"
                    classes={{paper: this.props.classes.dialogPaper}}
                    disableEscapeKeyDown={true}
            >
              <DialogTitle id="form-dialog-title" />
              <DialogContent className={this.props.classes.dialogContent}>
                {/* TODO fix innerRef usage*/}
                <BpmnProcessEditor bpmn={bpmn} ref={this.processEditor} />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.boundReset} color="primary">
                  {messages.get(MessageKey.CORE.RESET)}
                </Button>
                <Button onClick={this.boundOpenUpload} color="primary">
                  {messages.get(MessageKey.CORE.IMPORT)}
                </Button>
                <Button onClick={this.boundDownloadBpmn} color="primary">
                  {messages.get(MessageKey.CORE.EXPORT)}
                </Button>
                <div style={{flex: "1 0 0"}} />
                <Button onClick={() => this.boundHandleCloseEditor(false)} color="primary">
                  {messages.get(MessageKey.CORE.CANCEL)}
                </Button>
                {!readOnly
                  ? (
                    <Button onClick={() => this.boundHandleCloseEditor(true)} color="primary">
                      {messages.get(MessageKey.CORE.OK)}
                    </Button>
                  ) : null}
              </DialogActions>
            </Dialog>
            <DropzoneDialog
                open={this.state.openUploadDialog}
                onSave={this.boundHandleUpload}
                acceptedFiles={[".bpmn"]}
                filesLimit={1}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNamesInPreview={true}
                showAlerts={false}
                maxFileSize={500000}
                onClose={this.boundHandleCloseUpload}
                classes={{root: this.props.classes.dropZoneRoot}}
                dialogTitle={messages.get(MessageKey.CORE.UPLOAD.BPMN)}
                dropzoneText={messages.get(MessageKey.CORE.DROP_FILE_OR_CLICK)}
                submitButtonText={messages.get(MessageKey.CORE.OK)}
                cancelButtonText={messages.get(MessageKey.CORE.CANCEL)}
            />
          </Grid>
        </Grid>
        {this.renderValue(readOnly)}
      </div>
    );
  }

  private renderValue(readOnly: boolean): React.ReactNode {
    if (this.state.value) {
      const style = readOnly ? {} : {cursor: "pointer"};
      const handleClick = readOnly ? () => {} : this.boundOpenEditor;
      return (
        <div dangerouslySetInnerHTML={{__html: this.state.value.svg}} onClick={handleClick} style={style} />
      );
    } else {
      return <div />;
    }
  }
}

const component = withStyles(styles)(ProcessModelComponent);
export {component as ProcessModelComponent};
