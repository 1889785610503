/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

const styles = () => createStyles({
  dropzoneAreaWrapper: {
    "& .MuiDropzoneArea-root": {
      position: "absolute",
      minHeight: 300,
      zIndex: 9999,
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiDropzoneArea-textContainer": {
      alignSelf: "center",
    },
  },
  progressText: {
    marginLeft: 8,
  },
  progressIndicator: {
    flexGrow: 1,
  },
});

export default styles;
