/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActivityStreamApi,
  ActivityStreamComponentProps,
  ActivityStreamEntry,
  ActivityStreamEntryWithHighlight,
  Change,
  FilterParameters, isCommunicationChange,
  isObjectChange,
  isOtherChange,
  OtherChange,
  setEntityUpdateAsSeen,
  useActivityStreamEntries,
} from "@icm/activitystream-common";
import {
  arePropsEqual,
  FilterAndModifier,
  GetMessage,
  SharedFilter,
  MessageKey,
  useMessages,
  useViewModel,
} from "@icm/core-common";
import {BorderBox, contributedObjectSelects, LoadingBar} from "@icm/core-web";
import {Box, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {InfiniteQueryObserverSuccessResult} from "react-query";
import {useDispatch} from "react-redux";
import {Virtuoso} from "react-virtuoso";

import {ActivityStreamEditor} from "../component/ActivityStreamEditor";
import {ActivityStreamViewModel} from "./ActivityStreamView";
import {styles} from "./ActivityStreamViewStyle";
import {ConsolidatedChange, ConsolidatedChangeList, ConsolidatedChangeListProps} from "./ChangeComponent";


export const ActivityStreamComponent = React.memo((props: ActivityStreamComponentProps) => {
  const {manualEntryCreation, entityId, entityType, entity, objectLinking} = props;
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<FilterAndModifier>();

  const filterParameters: FilterParameters = useMemo(() => ({
    entityId,
    entityType,
    collectionModifier: {
      propertyOfCollectionToFilterOn: "changes",
      listFilter: filter,
    },
  }), [entityId, entityType, filter]);

  const {viewModelData} = useViewModel<ActivityStreamViewModel>();
  const {filterParameter} = viewModelData;

  const result = useActivityStreamEntries(
    // either entityId is given (no further filtering) or there is a filter param and a filter (which we wait for)
    !!entityId || (!!filterParameter && !!filter),
    filterParameters,
  );

  useEffect(() => {
    // on unmount, set entity as seen
    return () => {
      if (entityType && entityId) {
        dispatch(setEntityUpdateAsSeen({type: entityType, idOrIds: entityId}));
      }
    };
  }, [dispatch, entityId, entityType]);

  return (
    <Box sx={styles.root}>
      {!entityId && filterParameter && (
        <BorderBox>
          <SharedFilter filterParameter={filterParameter}
                        onUpdateFilter={setFilter}
                        showEventFilterOptions={true}
          />
        </BorderBox>
      )}
      {manualEntryCreation && (
        <Box paddingX={2} paddingTop={2}>
          <ActivityStreamEditor entity={entity}
                                onSubmit={(entry) => {
                                  ActivityStreamApi.putEntries([entry])
                                    .then(createdIds => {
                                      console.log("created new entry, result:", createdIds);
                                    });
                                }}
                                objectLinking={objectLinking}
          />
        </Box>
      )}
      <LoadingBar variant="query"
                  visible={result.isLoading || (result.isFetching && !result.isFetchingNextPage)}
                  sx={styles.loadingBar}
      />
      {result.isSuccess && (
        <ChangeList result={result} />
      )}
    </Box>
  );
}, arePropsEqual);

type ChangeListProps = {
  result: InfiniteQueryObserverSuccessResult<ActivityStreamEntryWithHighlight[]>
}

const ChangeList = ({result}: ChangeListProps) => {
  const {getMessage} = useMessages();

  const changePropsList = useMemo(() => {
    const entries = result.data.pages.flatMap(page => page);
    return entries.filter(entry => entry.changes !== undefined)
      .flatMap(entry => entry.changes!.map((change: Change) => [entry, change]))
      .reduce((groupedList, [entry, change]: [ActivityStreamEntryWithHighlight, Change]) => {
        const lastChangeProps: ConsolidatedChangeListProps | undefined = groupedList.pop();
        const key = getKeyFromChange(change, entry);
        const objectType = getObjectTypeFromChange(change);
        const highlightUntil = entry.highlightUntil;
        if (lastChangeProps) {
          if (lastChangeProps.key === key) {
            // append
            lastChangeProps.changes.push(getChange(change, entry, highlightUntil));
            groupedList.push(lastChangeProps);
          } else {
            groupedList.push(lastChangeProps);

            const newChangeProps: ConsolidatedChangeListProps = {
              key,
              objectType,
              title: getTitleFromChange(change, entry, getMessage),
              date: entry.timestamp,
              highlightUntil: highlightUntil,
              changes: [getChange(change, entry, highlightUntil)],
            };
            groupedList.push(newChangeProps);
          }
        } else {
          const newChangeProps: ConsolidatedChangeListProps = {
            key,
            objectType,
            title: getTitleFromChange(change, entry, getMessage),
            date: entry.timestamp,
            highlightUntil: highlightUntil,
            changes: [getChange(change, entry, highlightUntil)],
          };
          groupedList.push(newChangeProps);
        }
        return groupedList;
      }, [] as ConsolidatedChangeListProps[]);
  }, [result.data.pages, getMessage]);

  const renderLoadingBar = () => (
    <LoadingBar variant="query" sx={[styles.loadingBar, styles.loadingBarBottom]} />
  );

  return (
    <>
      {changePropsList.length === 0 ? (
        <Typography variant="body1" sx={styles.noEntries}>
          {getMessage(MessageKey.CORE.ACTIVITYSTREAM.NO_ENTRIES)}
        </Typography>
      ) : (
        <Box sx={styles.changesContainer}>
          <Virtuoso
            style={{width: "100%"}}
            data={changePropsList}
            atBottomStateChange={(atBottom) => atBottom && result.hasNextPage && result.fetchNextPage()}
            overscan={{main: window.screen.height / 2, reverse: window.screen.height}}
            components={{
              Footer: () => result.isFetchingNextPage ? renderLoadingBar() : null,
            }}
            itemContent={(_index, objectChangeListProps) => (
              <div
                key={objectChangeListProps.key + ":" + (objectChangeListProps.changes.length > 0 ? objectChangeListProps.changes[objectChangeListProps.changes.length - 1].date.toISOString() : "")}
              >
                <ConsolidatedChangeList {...objectChangeListProps} />
              </div>
            )}
          />
        </Box>

      )}
    </>
  );
};

const getKeyFromChange = (change: Change, entry: ActivityStreamEntry): string => {
  if (isObjectChange(change) || isCommunicationChange(change)) {
    return `OBJECT:${change.objectReference!.type}:${change.objectReference!.id}`;
  } else {
    const otherChange = change as OtherChange;
    if (otherChange.relatedObjects?.length === 1) {
      return `OBJECT:${otherChange.relatedObjects[0]!.type}:${otherChange.relatedObjects[0]!.id}`;
    }
    return `OTHER:${entry.id}:${otherChange.description}`;
  }
};

const getTitleFromChange = (change: Change, entry: ActivityStreamEntry, getMessage: GetMessage): React.ReactNode => {
  if (isObjectChange(change) || isCommunicationChange(change)) {
    return getMessage(change.objectReference!.type!.toLowerCase() + ".activitystream.changeTemplate", {
      params: {
        entry,
        change,
      },
    });
  } else if (isOtherChange(change)) {
    return getOtherTitle(change, entry, getMessage);
  } else {
    return "";
  }
};

const getOtherTitle = (change: OtherChange, _entry: ActivityStreamEntry, getMessage: GetMessage): React.ReactNode => {
  const EntityValueDisplay = contributedObjectSelects[0]?.LinkedObjectDisplay;
  if (change.relatedObjects && change.relatedObjects.length > 0) {
    return (
      change.relatedObjects?.map((ro, i) => (
        <span key={ro.id}>
          {i > 0 ? ", " : ""}
          {ro.type ? getMessage(ro.type.toLowerCase() + ".label.title") + " " : ""}
          <EntityValueDisplay value={ro.object} />
        </span>
      ))
    );
  } else {
    return getMessage(MessageKey.ACTIVITYSTREAM.ENTRY.TITLE.OTHER);
  }
};

const getObjectTypeFromChange = (change: Change): string | undefined => {
  if (isObjectChange(change) || isCommunicationChange(change)) {
    return change.objectReference!.type!.toLowerCase();
  } else {
    return undefined;
  }
};

const getChange = (change: Change, entry: ActivityStreamEntry, highlightUntil: Date | null): ConsolidatedChange => {
  return {
    key: `${entry.id}_${change.changeType}_${entry.createdAt?.toISOString()}_${entry.timestamp?.toISOString()}`,
    actor: entry.actor!,
    date: entry.createdAt || entry.timestamp!,
    type: isObjectChange(change) || isCommunicationChange(change) ? change.type : undefined,
    eventType: isObjectChange(change) || isCommunicationChange(change) ? change.eventType : undefined,
    eventDetails: isObjectChange(change) || isCommunicationChange(change) ? change.eventDetails : undefined,
    propertyUpdates: isObjectChange(change) ? change.propertyUpdates : undefined,
    relatedObjects: isOtherChange(change) ? change.relatedObjects : undefined,
    otherUpdate: isOtherChange(change) ? change.description : undefined,
    communicationDetails: isCommunicationChange(change) ? change.communicationDetails : undefined,
    highlightUntil,
  };
};


export const activityStreamComponentFactory = (props: ActivityStreamComponentProps) => <ActivityStreamComponent {...props} />;
