/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {RegisterInStore} from "@icm/core-common";

import {sketchListFlows} from "./sketches/epics";
import {sketchListReducer} from "./sketches/reducer";
import {ISketchListState} from "./sketches/types";

export const register = (registerInStore: RegisterInStore) => {
  registerInStore("sketchListState", sketchListReducer, sketchListFlows);
};

export type ISketchListApplicationState = {
  sketchListState: ISketchListState,
}
