/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {produce} from "immer";
import {useState} from "react";

import {GenericFormField} from "../../../generated/api";
import {ExpressionEvaluationService} from "../../../service";
import {SafeObjectPropertyUpdate} from "../../ListComponentHelper";

type IState<T> = {
  entity: T,
  propertyUpdates: SafeObjectPropertyUpdate[],
}

export const useFormDialogComponent = <T extends {}>(entity: T) => {
  const [state, setState] = useState<IState<T>>({
    entity,
    propertyUpdates: [],
  });

  const handleChange = (field: GenericFormField, value?: any) => {
    const fieldName = field.valueBinding!;
    setState(prevState => produce(prevState, newState => {
      ExpressionEvaluationService.set(newState.entity, fieldName, value);
      newState.propertyUpdates.push({
        propertyName: fieldName,
        propertyValue: value,
      });
    }));
  };

  return {
    handleChange,
    state,
  };
};
