/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CompositeViewProps, useCompositeView, ViewContext} from "@icm/core-common";
import {MoreVertRounded} from "@mui/icons-material";
import {AppBar, Box, MenuItem, Tab, Tabs} from "@mui/material";
import Menu from "@mui/material/Menu";
import * as React from "react";
import {PropsWithChildren, useContext, useLayoutEffect, useState} from "react";

import {IconButtonComponent} from "../form";


const InnerCompositeView = (props: CompositeViewProps) => {
  const {
    validChildren,
    selectedTabId,
    setSelectedTabId,
    labelsAndIds,
  } = props;

  const tabStyle = useTabStyle();
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {labelsAndIds.length > 1 && (
        <AppBar position="static" elevation={0} className="dontPrint">

          <Box display="flex"
               sx={{
                 alignItems: "baseline",
                 justifyContent: "space-between",
               }}
          >
            <Box>
              <Tabs value={selectedTabId}
                    sx={tabStyle}
                    scrollButtons={true}
                    onChange={(event, value) => setSelectedTabId(value)}
                    indicatorColor="primary"
                    textColor="inherit"
              >
                {labelsAndIds.map(c => <Tab value={c.id} key={c.id} label={c.label} />)}
              </Tabs>
            </Box>
            <Box>
              <SubTabMenu tabs={labelsAndIds} onClick={setSelectedTabId} />
            </Box>

          </Box>
        </AppBar>
      )}
      <Box flex="1 1 auto" overflow="auto" height="100%">
        {validChildren.map(child => (
          <React.Fragment key={child.props.id}>
            {child.props.id === selectedTabId && (
              <Box height="100%" key={child.props.id} hidden={child.props.id !== selectedTabId}>
                {child}
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export const WebCompositeView = (props: PropsWithChildren<CompositeViewProps>) => {
  const commonProps = useCompositeView(props.children);
  return (
    <InnerCompositeView {...props} {...commonProps} />
  );
};

// IconSize + left button padding + right button padding + 2 * tab padding
// 24 + 8 + 8 + 16
const MENU_BUTTON_SIZE_WITH_PADDING = 56;


const useTabStyle = (): object | undefined => {
  const viewContext = useContext(ViewContext);
  if (!viewContext) {
    throw new Error("Cannot use useSubTabMenu outside of view context.");
  }
  const [tabHeaderSettings, setTabHeaderSettings] = useState<object | undefined>(undefined);
  useLayoutEffect(() => {
    if (viewContext.size) {
      const maxWidth = viewContext.size - MENU_BUTTON_SIZE_WITH_PADDING;
      setTabHeaderSettings({width: `${maxWidth}px`});
    }
  }, [viewContext.size, setTabHeaderSettings]);

  return tabHeaderSettings;
};

type SubTabMenuProps = {
  onClick: (newTabId: string) => void,
  tabs: { id: string, label: string }[],
}

const SubTabMenu = (props: SubTabMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    tabs,
    onClick,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const selectTab = (tabId: string) => {
    onClick(tabId);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButtonComponent color="inherit" handleClick={handleClick} icon={<MoreVertRounded />} />
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={closeMenu} color="inherit" className="dontPrint">
        {tabs.map(tab => <MenuItem key={tab.id} onClick={_ => selectTab(tab.id)}>{tab.label}</MenuItem>)}
      </Menu>
    </div>
  );
};
