/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ISelectComponentProps, LabelWithFieldStateMarker, SingleSelectComponent} from "@icm/core-common";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {withStyles, WithStyles} from "@mui/styles";
import * as React from "react";

import styles from "./SingleSelectRadioComponentStyle";

type AllProps<T> = ISelectComponentProps<T> & WithStyles<typeof styles>

class SingleSelectRadioComponent<T> extends SingleSelectComponent<T, AllProps<T>> {
  protected renderEditableComponent(): React.ReactNode {
    const availableValues = this.getAvailableValues();
    if (availableValues) {
      return (
        <FormControl error={this.props.error}
                     disabled={this.props.disabled}
        >
          <FormLabel>
            <LabelWithFieldStateMarker label={this.props.label} badgeColor={this.props.badgeColor} required={this.props.required} />
          </FormLabel>
          <RadioGroup className={this.props.classes.radioGroup}
                      row={true}
                      onChange={e => this.handleRowChange(availableValues, JSON.parse(e.target.value))}
                      onFocus={this.props.onFocus}
          >
            {availableValues.map((v, index) => this.addRadioButton(availableValues, index))}
          </RadioGroup>
        </FormControl>
      );
    } else {
      return <span>Loading data...</span>;
    }
  }

  private addRadioButton(availableValues: T[], index: number) {
    const valueDisplay: string = this.evaluateValueDisplay(availableValues[index]);
    const value: any = this.getValue(availableValues[index], valueDisplay);
    const selected = this.isSelected(value);
    const htmlInputProps = {
      "aria-label": `${this.props.label}-${valueDisplay}`,
    };
    return (
      <FormControlLabel key={index}
                        value={value}
                        control={(
                          <Radio
                          className={this.props.classes.radioButton}
                          color="primary"
                          checked={selected}
                          value={JSON.stringify(value)}
                          inputProps={htmlInputProps}
                          />
                        )}
                        label={(
                          <Typography variant="body1">
                            {valueDisplay}
                          </Typography>
                        )}
                        labelPlacement="end"
      />
    );
  }
}

const component = withStyles(styles)(SingleSelectRadioComponent);
export {component as SingleSelectRadioComponent};
