/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

export const journalStyles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    margin: 0,
    overflow: "auto",
    height: "20vh",
  },
  log: {
    margin: theme.spacing(2),
    flexGrow: 1,

  },
  actionItem: {
    textAlign: "center",
  },
  message: {
    textAlign: "left",
  },
  avatar: {
    float: "left",
  },
  editor: {
    height: "10vh",
  },
  divider: {
    margin: theme.spacing(0.5, 0),
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
});

export const useWebJournalClasses = () => {
  const useStyles = makeStyles(journalStyles);
  return useStyles();
};
