/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {produce} from "immer";
import {defaults, isNumber, isString, partition} from "lodash-es";
import * as React from "react";

import {
  ActionHandler,
  ActionRunners,
  BaseTableConfig,
  DataTableTextColumn,
  FilesComponentRef,
  formatService,
  GenericFormFieldListColumn,
  getResolvedParameters,
  IdGenerator,
  IFormDialogProps,
  ListComponentValue,
  mapToRowActions,
  MessageKey,
  ParameterUtilities,
  queryClient,
  SortOrder,
  SortService,
  Urls,
  userDownloadService,
  WithConfirmDialogProps,
} from "../../../index";
import {
  AbstractFormConfiguration,
  Action,
  FileReference,
  GenericFormConfiguration,
  GenericFormField,
  GenericFormFieldListConfiguration,
  Parameter,
  Sorting,
} from "../../generated/api";
import {CoreServiceRegistry, ExpressionEvaluationService, FetchService, messages, SecurityService} from "../../service";
import {ResolvedFilesComponentParameters} from "../files";
import {AbstractFormComponent, IFormComponentProps} from "../form";
import {createListComponentEmptyValue, ListComponentValueAndChanges, produceListComponentEntity, SafeObjectPropertyUpdate} from "../ListComponentHelper";

export interface IListProps {
  listConfiguration: GenericFormFieldListConfiguration;
  toGenericForm?: (form: any) => GenericFormConfiguration;
  onFileAddedToList?: (fileReference: FileReference, currentValue?: ListComponentValueAndChanges) => Promise<ListComponentValueAndChanges>;
  parameterList?: Parameter[];
}

export interface IListState<T> {
  formDialogProps: IFormDialogProps<any> | null;
  dialogFormConfiguration?: GenericFormConfiguration;
  sort: Sorting;
  originalValueArray: T[];
  tableConfig?: BaseTableConfig<T>;
  sortColumn?: DataTableTextColumn<T>;
  sortedListComponentValue?: T[];
}

export type CommonListComponentProps = IFormComponentProps<ListComponentValueAndChanges> & IListProps & WithConfirmDialogProps

export interface Identifiable {
  id: string,

  [key: string]: any
}

export abstract class BaseListComponent<T extends Identifiable>
  extends AbstractFormComponent<ListComponentValueAndChanges, CommonListComponentProps, IListState<T>> {

  protected readonly filesComponentRef = React.createRef<FilesComponentRef>();
  protected readonly securityService: SecurityService;

  constructor(props: CommonListComponentProps) {
    super(props);

    this.openNewItemDialog = this.openNewItemDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.addEntity = this.addEntity.bind(this);
    this.updateEntity = this.updateEntity.bind(this);
    this.removeEntity = this.removeEntity.bind(this);
    this.removeEntityAtIndex = this.removeEntityAtIndex.bind(this);
    this.showFilePicker = this.showFilePicker.bind(this);
    this.handleUploadedFile = this.handleUploadedFile.bind(this);
    this.getSortedListComponentValue = this.getSortedListComponentValue.bind(this);
    this.getSortColumn = this.getSortColumn.bind(this);
    this.partitionDataAndActionColumns = this.partitionDataAndActionColumns.bind(this);
    this.mapDataColsToDataTableColumns = this.mapDataColsToDataTableColumns.bind(this);
    this.resolveActionsAndActionRunners = this.resolveActionsAndActionRunners.bind(this);
    this.updateListState = this.updateListState.bind(this);
    this.resolveFilesComponentParameters = this.resolveFilesComponentParameters.bind(this);
    this.securityService = CoreServiceRegistry.get("SECURITY");

    this.state = {
      formDialogProps: null,
      sort: {
        property: props.listConfiguration.defaultSortColumn,
        order: props.listConfiguration.defaultSortOrder,
      },
      originalValueArray: this.getOriginalValueArray(props.value),
    };

    if (this.props.listConfiguration.formConfigurationUrl) {
      const formConfigurationUrl = `${this.props.listConfiguration.formConfigurationUrl}`;
      queryClient.fetchQuery(["private", "core", formConfigurationUrl], {
        queryFn: () => {
          return FetchService.performGet<AbstractFormConfiguration>(formConfigurationUrl!);
        },
        cacheTime: Infinity,
        staleTime: Infinity,
      })
        .then(formConfiguration => {
          const genericFormConfiguration = this.props.toGenericForm?.(formConfiguration) || (formConfiguration as GenericFormConfiguration);
          this.setState({
            dialogFormConfiguration: genericFormConfiguration,
          });
        });
    }
  }

  renderReadOnlyText(): string | undefined {
    // not needed, as renderReadOnlyComponent is overridden
    return undefined;
  }

  componentDidMount() {
    this.setState({...this.resolveListState()});
  }

  componentDidUpdate(prevProps: Readonly<IFormComponentProps<ListComponentValueAndChanges> & CommonListComponentProps>, prevState: Readonly<IListState<T>>) {
    this.updateListState(prevProps);
  }

  protected handleUploadedFile(fileReference: FileReference): Promise<void> {
    if (this.props.onFileAddedToList) {
      return this.props.onFileAddedToList?.(fileReference, this.props.value)
        .then(newValue => this.props.handleChange(newValue));
    }
    return Promise.resolve();
  }

  protected showFilePicker() {
    this.filesComponentRef?.current?.showFilePicker?.();
  }

  protected getValue(): ListComponentValueAndChanges {
    // make a shallow copy of the base object because "defaults(...)" will alter it
    const baseCopy = {...this.props.value};
    // freeze the object to ensure we do not alter it outside of the add/editEntity
    // to enable checking, use <React.StrictMode> around the return value
    // of your component
    return Object.freeze(defaults(baseCopy, createListComponentEmptyValue()));
  }

  protected addEntity(entity: T, propertyUpdates: SafeObjectPropertyUpdate[]) {
    const newValue = produceListComponentEntity(this.getValue(), entity, propertyUpdates);
    this.props.handleChange(newValue);
  }

  protected updateEntity(entity: T, propertyUpdates: SafeObjectPropertyUpdate[]) {
    const newValue = produce(this.getValue(), draft => {
      draft.type = "ListComponentValue";
      draft.data[entity.id] = entity;
      draft.changes = draft.changes || [];
      propertyUpdates.forEach(u => draft.changes.push({
        propertyName: "data." + entity.id + "." + u.propertyName,
        propertyValue: u.propertyValue,
      }));
    });
    this.props.handleChange(newValue);
  }

  private removeEntityAtIndex(index: number) {
    this.removeEntity(this.state.originalValueArray[index]);
  }

  protected removeEntity(entity: T) {
    const newValue = produce(this.getValue(), draft => {
      draft.type = "ListComponentValue";
      delete draft.data[entity.id];
      draft.changes = draft.changes || [];
      // clean up temporarily created entities
      draft.ids = draft.ids.filter(id => id !== entity.id);
      draft.changes.push({
        propertyName: "ids",
        propertyValue: draft.ids,
      });
      draft.changes = draft.changes.filter(c => !c.propertyName.startsWith("data." + entity.id));
      draft.changes.push({
        propertyName: "data." + entity.id,
        propertyValue: null,
      }); // make sure to do this after removal of changes in data!
      draft.deletedPropertyNamePrefix = ["data." + entity.id];
    });
    this.props.handleChange(newValue);
  }

  protected addOrSetParameter(action: Action, key: string, value: string) {
    if (!action.parameters) {
      action.parameters = [];
    }
    const existingParameter: Parameter | undefined = action.parameters.find(p => p.key === key);
    if (existingParameter) {
      existingParameter.value = value;
    } else {
      action.parameters.push({
        key: key,
        value: value,
      });
    }
  }

  protected renderValue(entity: T, valueBinding: string, valueDisplay?: string) {
    if (valueBinding) {
      const value = ExpressionEvaluationService.get(entity, valueBinding);
      if (value && value.valueDisplay) {
        return value.valueDisplay;
      } else if (valueDisplay) {
        return ExpressionEvaluationService.evaluate(valueDisplay, value, value, {formConfiguration: this.state.dialogFormConfiguration});
      } else {
        const valueLabel = this.getLabelFromPossibleValue(valueBinding, value);
        return valueLabel || value;
      }
    } else {
      return "";
    }
  }

  protected getLabelFromPossibleValue(valueBinding: string, value: any): string | undefined {
    const field: GenericFormField | undefined = this.getFormFieldByValueBinding(valueBinding);
    const possibleValue = field?.possibleValuesList?.find(v => v.value === value);
    return possibleValue?.label;
  }

  protected getFormFieldByValueBinding(valueBinding: string): GenericFormField | undefined {
    return this.state.dialogFormConfiguration?.groups?.flatMap(g => g.widgets)
      .filter(widget => !!widget && widget.widgetType === "GENERIC_FIELD")
      .map(widget => widget as GenericFormField)
      .find(widget => widget!.valueBinding === valueBinding);
  }

  protected openNewItemDialog() {
    this.openDialog({id: IdGenerator.randomUUID()});
  }

  protected openDialog(entity: any, index?: number) {
    if (this.state.dialogFormConfiguration) {
      const update = index !== undefined;
      this.setState({
        formDialogProps: {
          formConfiguration: this.state.dialogFormConfiguration,
          entity,
          submitLabel: update ? messages.get(MessageKey.CORE.FORM.SAVE)! : messages.get(MessageKey.CORE.FORM.ADD)!,
          onClose: this.closeDialog,
          onSubmit: (newEntity: T, propertyUpdates: SafeObjectPropertyUpdate[], closeDialog = true) => {
            if (update) {
              this.updateEntity(newEntity, propertyUpdates);
            } else {
              this.addEntity(newEntity, propertyUpdates);
            }
            if (closeDialog) {
              this.closeDialog();
            }
          },
        },
      });
    }
  }

  private closeDialog() {
    this.setState({
      formDialogProps: null,
    });
  }

  private getOriginalValueArray(value: ListComponentValueAndChanges | undefined): T[] {
    const originalValue: ListComponentValue = value || createListComponentEmptyValue();
    // make sure to be backward-compatible to simple arrays
    return Array.isArray(originalValue) ? originalValue
      : originalValue?.ids?.filter(id => !!originalValue.data[id])
        .map(id => originalValue.data[id]);
  }

  private getSortedListComponentValue(originalValueArray: T[], sortColumn?: DataTableTextColumn<T>): T[] {
    return sortColumn
      ? [...originalValueArray].sort((a: T, b: T) => this.state.sort.order === "ASC"
        ? SortService.compare(sortColumn.valueAccessor(a), sortColumn.valueAccessor(b))
        : SortService.compare(sortColumn.valueAccessor(b), sortColumn.valueAccessor(a)))
      : originalValueArray;
  }

  private getSortColumn(dataTableColumns: Array<DataTableTextColumn<T>>): DataTableTextColumn<T> | undefined {
    return this.state.sort.property ? dataTableColumns.find((col, idx) => `${idx}_${col.name}` === this.state.sort.property) : undefined;
  }

  private partitionDataAndActionColumns(): [GenericFormFieldListColumn[], GenericFormFieldListColumn[]] {
    const {listConfiguration} = this.props;
    const [dataCols, actionCols] = partition(listConfiguration.simpleColumns!, col => col.columnType === "DATA");
    return [dataCols, actionCols];
  }

  private mapDataColsToDataTableColumns(dataCols: GenericFormFieldListColumn[]): Array<DataTableTextColumn<T>> {
    return dataCols.map((column, index) => ({
      type: "TEXT",
      name: column.columnName!,
      headerText: column.headerText,
      valueBinding: column.valueBinding!,
      sortable: column.sortable || true,
      sortOrder: this.state.sort.property === `${index}_${column.columnName}` ? this.state.sort.order : undefined,
      sort: (order: SortOrder) => this.setState({
        sort: {
          property: `${index}_${column.columnName}`,
          order,
        },
      }),
      format: column.format!,
      formatValue: (val: any) => formatService.format(val, column.format, "FULL"),
      valueAccessor: (entity: T) => this.renderValue(entity, column.valueBinding!, column.valueDisplay),
    }));
  }

  /**
   * Action labels are required for the mobile application
   **/
  private resolveActionsAndActionRunners(actionCols: GenericFormFieldListColumn[]) {
    const {
      readOnly,
      listConfiguration,
    } = this.props;
    const actions: Action[] = actionCols.flatMap(col => col.action!);
    actions.filter(a => a.action === "DOWNLOAD")
      .forEach(a => {
        a.label = messages.get(MessageKey.CORE.DOWNLOAD._);
        this.addOrSetParameter(a, "row", "_");
        this.addOrSetParameter(a, "ROW_INDEX", "ROW_INDEX");
      });

    const actionRunners: ActionRunners = {};
    actionRunners.DOWNLOAD = (parameters) => {
      const row = ParameterUtilities.getResolvedParameter("row", parameters);
      const rowIndex = ParameterUtilities.getResolvedParameter("ROW_INDEX", parameters);
      const entityAttributeName = ParameterUtilities.getResolvedParameter("ENTITY_ATTRIBUTE_NAME", parameters);
      if (entityAttributeName) {
        const entity = row;
        const fileId: string | undefined = entity.dynamicAttributes![entityAttributeName]?.value.id as string;
        if (fileId) {
          const fileUrl: string = `${Urls.FILE_DATA}/${fileId}`;
          const fileName = entity.dynamicAttributes![entityAttributeName]?.value.name as string;
          console.log("Downloading file associated with entity", row, rowIndex, entityAttributeName, fileUrl);
          userDownloadService.startDownload(fileUrl, fileName);
        } else {
          console.log("Can not determine file to download for ", entity, entityAttributeName);
        }
      } else {
        console.log("Can not determine file to download for ", row);
      }
    };


    if (!readOnly && (listConfiguration.itemAddable || listConfiguration.itemRemovable)) {
      if (listConfiguration.itemAddable) {
        actions.push({
          action: "OPEN",
          icon: "edit",
          label: messages.get(MessageKey.CORE.EDIT),
          parameters: [{
            key: "row",
            value: "_",
          }, {
            key: "ROW_INDEX",
            value: "ROW_INDEX",
          }],
        });
        actionRunners.OPEN = (parameters) => {
          const row = ParameterUtilities.getResolvedParameter("row", parameters);
          const rowIndex = ParameterUtilities.getResolvedParameter("ROW_INDEX", parameters);
          console.log("Open dialog", row, rowIndex, parameters);
          this.openDialog(row, rowIndex!);
        };
      }
      if (listConfiguration.itemRemovable) {
        actions.push({
          action: "DELETE",
          icon: "delete_outline",
          label: messages.get(MessageKey.CORE.DELETE),
          parameters: [{
            key: "ROW_INDEX",
            value: "ROW_INDEX",
          }],
        });
        actionRunners.DELETE = (parameters) => {
          this.props.confirmDialog({title: messages.get(MessageKey.CORE.FORM.CONFIRM_DELETE)})
            .then(yes => {
              if (yes) {
                const rowIndex = ParameterUtilities.getResolvedParameter("ROW_INDEX", parameters);
                this.removeEntityAtIndex(rowIndex);
              }
            });
        };
      }
    }
    return {
      actions,
      actionRunners,
    };
  }

  private updateListState(prevProps: Readonly<IFormComponentProps<ListComponentValueAndChanges> & CommonListComponentProps>) {
    if (JSON.stringify(prevProps.listConfiguration) !== JSON.stringify(this.props.listConfiguration)) {
      this.setState({...this.resolveListState()});
    }

    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      const originalValueArray = this.getOriginalValueArray(this.props.value);
      this.setState({
        originalValueArray,
        sortedListComponentValue: this.getSortedListComponentValue(originalValueArray, this.state.sortColumn),
      });
    }
  }

  private resolveListState() {
    const [dataCols, actionCols] = this.partitionDataAndActionColumns();
    const dataTableColumns = this.mapDataColsToDataTableColumns(dataCols);
    const sortColumn = this.getSortColumn(dataTableColumns);
    const sortedListComponentValue = this.getSortedListComponentValue(this.state.originalValueArray, sortColumn);

    const {
      actions,
      actionRunners,
    } = this.resolveActionsAndActionRunners(actionCols);

    const resolveActionHandler: ActionHandler = (action: Action, options) => {
      if (action.action && actionRunners[action.action]) {
        const parameters = getResolvedParameters(action, options);
        actionRunners[action.action]!(parameters);
      }
    };

    const tableConfig: BaseTableConfig<T> = {
      dataColumns: dataTableColumns,
      rowActions: mapToRowActions<T>(
        {
          securityService: this.securityService,
          actions,
          actionHandler: resolveActionHandler,
          getRowValue: row => row,
        }
      ),
    };
    return {
      tableConfig,
      sortedListComponentValue,
      sortColumn,
    };
  }

  protected resolveFilesComponentParameters(): ResolvedFilesComponentParameters {
    const parameters = ParameterUtilities.flattenParameters(this.props.parameterList);
    const maxFiles = ParameterUtilities.getResolvedParameterValue("maxFiles", parameters, isNumber);
    const maxFileSize = ParameterUtilities.getResolvedParameterValue("maxFileSize", parameters, isNumber);
    const acceptedFileTypes = ParameterUtilities.getResolvedParameterValue("acceptedFileTypes", parameters, isString);
    return {
      maxFiles,
      maxFileSize,
      acceptedFileTypes,
    };
  }
}
