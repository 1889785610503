/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import React, {FC} from "react";

import {TEXT} from "../../../constant";
import {getNamedComponentFactory} from "../../../ui";
import {arePropsEqual} from "../../../util";
import {BadgeColor} from "../../badge";
import {FieldUpdatedMarker} from "./FieldUpdatedMarker";


type Props = {
  badgeColor?: BadgeColor
  label?: string
  required?: boolean
  error?: boolean
};

export const LabelWithFieldStateMarker: FC<Props> = React.memo(({
  badgeColor,
  label,
  required,
  error,
}) => {
  const Text = getNamedComponentFactory(TEXT) || ((props) => <span>{props.children}</span>);
  return (
    <Text variant="body2" color={error ? "error" : "textSecondary"}>
      <span
        className="label-with-state-marker"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span dangerouslySetInnerHTML={{__html: label ?? ""}} />
        {required && "*"}
        <FieldUpdatedMarker badgeColor={badgeColor} style={{marginLeft: 8}} />
      </span>
    </Text>
  );
}, arePropsEqual);
