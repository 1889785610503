/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {ActionContextValue} from "../../actions";
import {ICON} from "../../constant";
import {Action, Icon, RowActions, RowTextElement} from "../../generated/api";
import {ExpressionEvaluationService, SecurityService} from "../../service";
import {ActionHandler, getNamedComponentFactory, getResolvedParameters} from "../../ui";
import {ViewContextValue} from "../view";
import {EvaluatedElement, RowAction} from "./CommonListTypes";

export function mapToActions(rowActions: RowActions[]): Action[] {
  return rowActions.flatMap(actionItem => actionItem.actions ?? []);
}

export type GetRowValue<T> = (row: T) => any;

export type IsRowVisible<T> = (row: T) => boolean;

export type IsRowEnabled<T> = (row: T) => boolean;

type CreateValueAccessorOptions = {
  valueBinding?: string,
  valueDisplay?: string,
  textElement?: RowTextElement
}

type RowActionsProps<T> = {
  securityService: SecurityService,
  actions: Action[],
  actionHandler: ActionHandler,
  getRowValue: GetRowValue<T>,
  viewContext?: ViewContextValue,
  actionContext?: ActionContextValue,
  isVisible?: IsRowVisible<T>,
  isEnabled?: IsRowEnabled<T>,
}

export function mapToRowActions<T>(props: RowActionsProps<T>): RowAction<T>[] {
  const {
    securityService,
    actions,
    actionHandler,
    getRowValue,
    viewContext,
    actionContext,
    isVisible,
    isEnabled,
  } = props;

  return actions.map(action => {
    return {
      isVisible: (row, rowIndex) => (!isVisible || isVisible(row)) && securityService.evaluateVisibilityRule(
        action.visible,
        mergeActionContext(viewContext, actionContext, getRowValue(row), rowIndex)
      ),
      isEnabled: row => (!isEnabled || isEnabled(row)),
      icon: action.icon!,
      label: action.label ?? "",
      onClick: (row, rowIndex) => handleActionClick(
        action,
        actionHandler,
        mergeActionContext(viewContext,
          actionContext,
          getRowValue(row),
          rowIndex)
      ),
    };
  });
}

function mergeActionContext(
  viewContext: ViewContextValue | undefined,
  actionContext: ActionContextValue | undefined,
  rowValue: object,
  rowIndex: number
): any {
  return {
    ...(viewContext ?? {}),
    ...(actionContext ?? {}),
    ...rowValue,
    row: rowValue,
    rowIndex: rowIndex,
    // FIXME: jschoeff @ ppayerl, PICM-2274: why do we duplicate rowValue here, also possibly overwriting fields?
  };
}

function handleActionClick(action: Action, actionHandler: ActionHandler, actionContext: any) {
  const resolvedParameters = getResolvedParameters(action, actionContext).map(param => {
    // special handling for edit/delete in ListComponent
    if (param.key === "ROW_INDEX" && param.value === "ROW_INDEX") {
      return {
        key: param.key,
        value: actionContext.rowIndex,
      };
    } else {
      return param;
    }
  });
  actionHandler(action, {
    resolvedParameters,
  });
}

export function createValueAccessor({valueBinding, valueDisplay, textElement}: CreateValueAccessorOptions): GetRowValue<unknown> {
  if (valueBinding) {
    return (row: unknown) => {
      const value = ExpressionEvaluationService.get(row, valueBinding);
      if (valueDisplay) {
        if (isValueDisplayExpression(valueDisplay)) {
          return ExpressionEvaluationService.evaluate(valueDisplay, value);
        } else  {
          return ExpressionEvaluationService.get(row, valueDisplay);
        }
      } else if (value && value.valueDisplay) {
        // don't render [Object] for simple select values but resolve (contained) value display
        return value.valueDisplay;
      } else {
        return value;
      }
    };
  } else {
    console.error("No valueBinding for textElement", textElement);
    return () => "ERROR";
  }
}

function isValueDisplayExpression(valueDisplay: string) {
  return valueDisplay.includes("=>") || valueDisplay.includes("{{");
}

export const evaluateIconVisibility = (icon: Icon, entity: any): boolean => {
  if (!icon.visible) {
    return true;
  }
  try {
    return ExpressionEvaluationService.evaluate(icon.visible, entity);
  } catch (e) {
    return true;
  }
};

export const resolveRowTextElementIcon = (
  columnIcon: Icon,
  entity: any,
  securityService: SecurityService,
  value?: any,
  className?: string,
): JSX.Element | undefined => {
  const {
    icon,
    color,
    visible,
    tooltipText,
  } = columnIcon;
  const securityVisibilityExpressionContext = securityService.getVisibilityExpressionContext();
  const evaluatedIconName = icon ? ExpressionEvaluationService.evaluate(icon, entity, securityVisibilityExpressionContext, value) : undefined;
  const evaluatedColor = color ? ExpressionEvaluationService.evaluate(color, entity, securityVisibilityExpressionContext, value) : undefined;
  const evaluatedVisibility = visible ? ExpressionEvaluationService.evaluate(visible, entity, securityVisibilityExpressionContext, value) : undefined;
  const evaluatedTooltipText = tooltipText ? ExpressionEvaluationService.evaluate(tooltipText, entity, securityVisibilityExpressionContext, value) : undefined;
  const IconComponent = getNamedComponentFactory(ICON);
  if (evaluatedIconName && evaluatedVisibility && IconComponent) {
    return <IconComponent iconColor={evaluatedColor} name={evaluatedIconName} className={className} toolTipText={evaluatedTooltipText} />;
  }
  return undefined;
};

export const evaluateEntityListElement = (entity: any, rowTextElement?: RowTextElement): EvaluatedElement | undefined => {
  if (rowTextElement) {
    const valueAccessor = createValueAccessor({
      valueBinding: rowTextElement.valueBinding,
      valueDisplay: rowTextElement.valueDisplay,
      textElement: rowTextElement,
    });
    return {
      label: rowTextElement.label,
      value: valueAccessor(entity),
      format: rowTextElement.format!,
      icon: rowTextElement.icon,
    };
  } else {
    return undefined;
  }
};
