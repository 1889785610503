/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export const ArrayUtilities =  {

  /**
   * Split an array into chunks of fixed size.
   *
   * @param items the array
   * @param chunkSize the number of array items in a chunk
   */
  getChunks<T>(items: T[], chunkSize: number): T[][] {
    return items.reduce((resultArray: any[], item, index) => {
      const chunkIndex: number = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  },

  /**
   * Get an array of distinct items.
   * If there are multiple items with the same value calculated by the function, the first item is used.
   *
   * @param items the array
   * @param by the function to calculate a value by which the items need to be distinct
   */
  distinct<T>(items: T[], by: (t: T) => string | number | undefined): T[] {
    const values = new Set();
    return items.filter(item => {
      const value = by(item);
      if (!values.has(value)) {
        values.add(value);
        return true;
      }
      return false;
    });
  },

  /**
   * @param items an array
   * @returns the last item of the array (if there is one)
   */
  last<T>(items?: T[]): T | undefined {
    if (items && items.length > 0) {
      return items[items.length - 1];
    } else {
      return undefined;
    }
  },
};
