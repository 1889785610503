/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {AbstractDateTimeComponent, dateService, ErrorBoundary, IDateTimeComponentProps, LabelWithFieldStateMarker} from "@icm/core-common";
import {TextField, TextFieldProps} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import * as React from "react";

export class TimeComponent extends AbstractDateTimeComponent {

  constructor(props: IDateTimeComponentProps) {
    super(props, dateService.getMomentDateFormats().time.SHORT);
  }

  private static removeDate(date: Moment | null, format: string) {
    return date ? moment(dateService.removeDate(date.toDate(), format)) : date;
  }

  protected renderDateTimeComponent(value: Date | null | undefined, format: string, placeholder: string) {
    return (
      <ErrorBoundary>
        <TimePicker<Moment> disabled={this.props.disabled}
                            ampm={false}
                            value={value ? moment(value) : null}
                            inputFormat={format}
          // keyboardIcon={<QueryBuilder />}
                            onChange={(newValue: moment.Moment | null) => this.handleChange(TimeComponent.removeDate(newValue, format))}
          // TODO PICM-1455 temporary workaround until problem is fixed by material-ui
                            InputAdornmentProps={{
                              sx: {padding: 2},
                            }}
                            onOpen={this.props.onFocus}
                            ignoreInvalidInputs={true}
                            renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
                              <TextField {...props}
                                         variant="standard"
                                         label={(
                                           <LabelWithFieldStateMarker
                                             required={this.props.required}
                                             label={this.props.label}
                                             badgeColor={this.props.badgeColor}
                                           />
                                         )}
                                         helperText={this.props.helperText}
                                         fullWidth
                                         error={!this.state.valid || this.props.error}
                                         placeholder={placeholder}
                                         onFocus={this.props.onFocus}
                                         onBlur={event => this.handleBlur(event, format)}
                                         InputProps={{
                                           ...props.InputProps,
                                           "aria-label": `${this.props.label}`,
                                         }}
                              />
                            )}
        />
      </ErrorBoundary>
    );
  }

  protected isWithinRange(value: Date, minDate?: Date, maxDate?: Date): boolean {
    return super.isWithinRange(TimeComponent.normalizeDate(value)!, TimeComponent.normalizeDate(minDate), TimeComponent.normalizeDate(maxDate));
  }

  private static normalizeDate(value?: Date): Date | undefined {
    if (value) {
      const copy = new Date(value);
      copy.setFullYear(1970, 0, 1);
      return copy;
    } else {
      return undefined;
    }
  }
}
