/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService, RowConfiguration} from "@icm/core-common";
import {useCallback} from "react";

import {Entity} from "../generated/api";
import {EntityListElementView} from "./useUpdatingInfiniteEntityList";


/**
 * Create a view object of an entity that will be used as a row in an entity list view.
 */
export type EntityListElementViewFactory = (entity: Entity) => EntityListElementView;

/**
 * Create an EntityListElementViewFactory for the given rowConfiguration.
 * The factory is based on the viewAttributes of the rowConfiguration.
 * If no viewAttributes are found, the returned entity factory will create an
 * emtpy viewAttributes object.
 *
 * @param rowConfiguration
 */
export const useListElementViewFactory = (rowConfiguration?: RowConfiguration): EntityListElementViewFactory => {
  const viewAttributesParameter = rowConfiguration?.viewAttributes;
  return useCallback((entity: Entity) => {
    if (viewAttributesParameter) {
      try {
        const viewAttributes = ExpressionEvaluationService.evaluate(viewAttributesParameter, entity);
        if (typeof viewAttributes !== "object") {
          console.warn(`The viewAttributes Expression ${viewAttributesParameter} should produce an object. It produced a literal of value: '${viewAttributes}'.`);
          return createDefaultViewObject(entity);
        }
        return {
          ...entity,
          viewAttributes,
        };
      } catch (error) {
        console.error("Could not evaluate view expression. viewAttributes will be empty.", error);
        return createDefaultViewObject(entity);
      }
    }
    return createDefaultViewObject(entity);
  }, [viewAttributesParameter]);
};


const createDefaultViewObject = (entity: Entity) => {
  return {
    ...entity,
    viewAttributes: {},
  };
};
