/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  MessageKey,
  ViewDescriptor,
} from "@icm/core-common";
import {FormatPaint, Restore} from "mdi-material-ui";
import React from "react";

export type ThemePreviewViewModel = {
  printRequested: boolean,
}

export const themePreviewViewDescriptor: ViewDescriptor<ThemePreviewViewModel> = {
  viewType: "THEME_PREVIEW_VIEW",
  view: React.lazy(() => import("./ThemePreviewView")),
  initializeViewModelData: viewParameters => ({
    printRequested: false,
  }),
  getTitle: (_viewModel, getMessage) => getMessage(MessageKey.CORE.THEME.EDITOR),
  getViewActionDescriptors: (_viewModel, _getMessage) => ({
    APPLY: {
      icon: <FormatPaint />,
      title: "Apply temporarily",
    },
    EXPORT: {
      icon: "export_as_file",
      title: "Export current configuration",
    },
    RESET: {
      icon: <Restore />,
      title: "Reset changes",
    },
  }),
};
