/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, PromptDialogProps, PromptDialogProvider, useCommonPromptDialog, useCommonPromptDialogState, useMessages} from "@icm/core-common";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

import {ButtonComponent, TextBoxComponent} from "../form";
import {useDialogStyles} from "./DialogStyle";


const PromptDialog = ({title, description, confirmLabel = "OK", onConfirm, onCancel, textBoxProps}: PromptDialogProps) => {
  const classes = useDialogStyles();
  const {getMessage} = useMessages();
  const {value, setValue, confirm} = useCommonPromptDialogState(confirmLabel);

  return (
    <Dialog open={true} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        {description && (
          <DialogContentText>{description}</DialogContentText>
        )}
        <TextBoxComponent {...textBoxProps} value={value} handleChange={setValue} />
      </DialogContent>
      <DialogActions>
        <ButtonComponent label={getMessage(MessageKey.CORE.CANCEL)} onClick={onCancel} variant="outlined" />
        <ButtonComponent label={confirm}
                         variant="contained"
                         onClick={() => onConfirm(value!)}
                         disabled={!value}
        />
      </DialogActions>
    </Dialog>
  );
};

export const useWebPromptDialog = (): PromptDialogProvider => useCommonPromptDialog(PromptDialog);
