/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:15.

/**
 * Generated from: at.cns.icm.api.rem.backend.data.LoadDto
 */
export class Load {
    id?: string;
    dangerousGoodNumber?: string;
    unMaterialNumber?: number;
    ridClassification?: string;
    nhmCargoCode?: number;
    cargoName?: string;

    static fromData(data: any): Load {
        if (!data) {
            return data;
        }
        const instance: Load = { id: data.id && __verifyIsString(data.id), dangerousGoodNumber: data.dangerousGoodNumber && __verifyIsString(data.dangerousGoodNumber), unMaterialNumber: data.unMaterialNumber && __verifyIsNumber(data.unMaterialNumber), ridClassification: data.ridClassification && __verifyIsString(data.ridClassification), nhmCargoCode: data.nhmCargoCode && __verifyIsNumber(data.nhmCargoCode), cargoName: data.cargoName && __verifyIsString(data.cargoName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','dangerousGoodNumber','unMaterialNumber','ridClassification','nhmCargoCode','cargoName'];
    }
}

/**
 * Generated from: at.cns.icm.api.rem.backend.data.TrainInformationDto
 */
export class TrainInformation {
    id?: string;
    wagons?: Wagon[];
    trainNumber?: number;
    registrationDate?: Date;
    reportLocationName?: string;
    forwardingLocationName?: string;
    receivingLocationName?: string;
    length?: number;
    dangerousGoods?: boolean;
    extraordinaryDelivery?: boolean;
    minimumTopSpeed?: number;
    lastVehicle?: string;
    tractionVehicle?: string;
    totalWeight?: number;
    bgHandbrakeLoad?: number;
    wgHandbrakeLoad?: number;
    existingBrakingPercentage?: number;
    existingBrakingPercentageTrain?: number;
    operatorDescription?: string;
    totalWeightOfLoad?: number;
    trainBrakesWeight?: number;
    trainWagonLength?: number;

    static fromData(data: any): TrainInformation {
        if (!data) {
            return data;
        }
        const instance: TrainInformation = { id: data.id && __verifyIsString(data.id), wagons: data.wagons && __getCopyArrayFn(Wagon.fromData)(data.wagons), trainNumber: data.trainNumber && __verifyIsNumber(data.trainNumber), registrationDate: data.registrationDate && new Date(data.registrationDate), reportLocationName: data.reportLocationName && __verifyIsString(data.reportLocationName), forwardingLocationName: data.forwardingLocationName && __verifyIsString(data.forwardingLocationName), receivingLocationName: data.receivingLocationName && __verifyIsString(data.receivingLocationName), length: data.length && __verifyIsNumber(data.length), dangerousGoods: data.dangerousGoods && __verifyIsBoolean(data.dangerousGoods), extraordinaryDelivery: data.extraordinaryDelivery && __verifyIsBoolean(data.extraordinaryDelivery), minimumTopSpeed: data.minimumTopSpeed && __verifyIsNumber(data.minimumTopSpeed), lastVehicle: data.lastVehicle && __verifyIsString(data.lastVehicle), tractionVehicle: data.tractionVehicle && __verifyIsString(data.tractionVehicle), totalWeight: data.totalWeight && __verifyIsNumber(data.totalWeight), bgHandbrakeLoad: data.bgHandbrakeLoad && __verifyIsNumber(data.bgHandbrakeLoad), wgHandbrakeLoad: data.wgHandbrakeLoad && __verifyIsNumber(data.wgHandbrakeLoad), existingBrakingPercentage: data.existingBrakingPercentage && __verifyIsNumber(data.existingBrakingPercentage), existingBrakingPercentageTrain: data.existingBrakingPercentageTrain && __verifyIsNumber(data.existingBrakingPercentageTrain), operatorDescription: data.operatorDescription && __verifyIsString(data.operatorDescription), totalWeightOfLoad: data.totalWeightOfLoad && __verifyIsNumber(data.totalWeightOfLoad), trainBrakesWeight: data.trainBrakesWeight && __verifyIsNumber(data.trainBrakesWeight), trainWagonLength: data.trainWagonLength && __verifyIsNumber(data.trainWagonLength) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','wagons','trainNumber','registrationDate','reportLocationName','forwardingLocationName','receivingLocationName','length','dangerousGoods','extraordinaryDelivery','minimumTopSpeed','lastVehicle','tractionVehicle','totalWeight','bgHandbrakeLoad','wgHandbrakeLoad','existingBrakingPercentage','existingBrakingPercentageTrain','operatorDescription','totalWeightOfLoad','trainBrakesWeight','trainWagonLength'];
    }
}

/**
 * Generated from: at.cns.icm.api.rem.backend.data.WagonDto
 */
export class Wagon {
    id?: string;
    wagonPosition?: number;
    wagonNumber?: string;
    loaded?: boolean;
    loads?: Load[];
    forwardingLocationName?: string;
    receivingLocationName?: string;
    numberOfTransportSchedule?: string;
    transportType?: string;
    topSpeed?: number;
    consignee?: string;
    handbrakeWeight?: number;
    brakeWeight?: number;
    brakePosition?: string;
    weightOfLoad?: number;
    lengthOverBuffer?: number;
    totalWeightOfWagon?: number;
    numberOfAxles?: number;

    static fromData(data: any): Wagon {
        if (!data) {
            return data;
        }
        const instance: Wagon = { id: data.id && __verifyIsString(data.id), wagonPosition: data.wagonPosition && __verifyIsNumber(data.wagonPosition), wagonNumber: data.wagonNumber && __verifyIsString(data.wagonNumber), loaded: data.loaded && __verifyIsBoolean(data.loaded), loads: data.loads && __getCopyArrayFn(Load.fromData)(data.loads), forwardingLocationName: data.forwardingLocationName && __verifyIsString(data.forwardingLocationName), receivingLocationName: data.receivingLocationName && __verifyIsString(data.receivingLocationName), numberOfTransportSchedule: data.numberOfTransportSchedule && __verifyIsString(data.numberOfTransportSchedule), transportType: data.transportType && __verifyIsString(data.transportType), topSpeed: data.topSpeed && __verifyIsNumber(data.topSpeed), consignee: data.consignee && __verifyIsString(data.consignee), handbrakeWeight: data.handbrakeWeight && __verifyIsNumber(data.handbrakeWeight), brakeWeight: data.brakeWeight && __verifyIsNumber(data.brakeWeight), brakePosition: data.brakePosition && __verifyIsString(data.brakePosition), weightOfLoad: data.weightOfLoad && __verifyIsNumber(data.weightOfLoad), lengthOverBuffer: data.lengthOverBuffer && __verifyIsNumber(data.lengthOverBuffer), totalWeightOfWagon: data.totalWeightOfWagon && __verifyIsNumber(data.totalWeightOfWagon), numberOfAxles: data.numberOfAxles && __verifyIsNumber(data.numberOfAxles) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','wagonPosition','wagonNumber','loaded','loads','forwardingLocationName','receivingLocationName','numberOfTransportSchedule','transportType','topSpeed','consignee','handbrakeWeight','brakeWeight','brakePosition','weightOfLoad','lengthOverBuffer','totalWeightOfWagon','numberOfAxles'];
    }
}

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
