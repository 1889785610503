/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
  header: {
    transform: "translate(0, 1.5px) scale(0.875)",
    transformOrigin: "left",
  },
  taskNameBox: {
    float: "left",
    borderRadius: 4,
    paddingLeft: 6,
    paddingTop: 0,
    paddingBottom: 1,
    paddingRight: 6,
    marginRight: 4,
    marginTop: 2,
    fontSize: "0.75em",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
    border: "1px solid",
    borderColor: theme.palette.text.primary,
  },
});

export default styles;
