/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FCView, MessageKey, useFeedbackBanners, useMessages, useViewModel} from "@icm/core-common";
import {LoadingSpinner} from "@icm/core-web";
import {useAuditLog} from "@icm/rem-auditlog-common";
import {REM_INCIDENT_TYPE} from "@icm/rem-rail-common";
import * as React from "react";

import {AuditLogChooser} from "../components/AuditLogChooser";
import {AuditLogRenderer} from "../components/AuditLogRenderer";

export type AuditLogViewModelData = {
  incidentNumber?: string,
  printRequested: boolean,
}

export const AuditLogView: FCView<{}, AuditLogViewModelData> = () => {
  const {getMessage} = useMessages();
  const {
    viewModelData,
    setViewModelData,
  } = useViewModel<AuditLogViewModelData>();
  const incidentNumber = viewModelData?.incidentNumber;

  const {openFeedbackBanner} = useFeedbackBanners();

  const auditLogResult = useAuditLog(incidentNumber, () => {
    const auditLogErrorMessage = getMessage(MessageKey.AUDITLOG.REQUEST.ERROR);
    openFeedbackBanner({
      key: "AuditLogError",
      duration: "SHORT",
      variant: "ERROR",
      title: auditLogErrorMessage,
    });
  });

  if (auditLogResult.isLoading) {
    return <LoadingSpinner />;
  } else if (!auditLogResult.data) {
    return (
      <AuditLogChooser openAuditLog={entityNumberInput => setViewModelData({incidentNumber: entityNumberInput, printRequested: false})} />
    );
  } else {
    return <AuditLogRenderer auditLog={auditLogResult.data} type={REM_INCIDENT_TYPE} />;
  }
};
