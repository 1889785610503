/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PropsWithChildren, useMemo} from "react";
import {useDispatch} from "react-redux";

import {deleteNotifications, markNotificationAsRead} from "../store";
import {NotificationCenterContext, NotificationCenterContextType} from "./NotificationCenterContext";

type Props = {
  openNotificationCenter: () => void
  closeNotificationCenter: () => void
}
export const NotificationCenterProvider = (props: PropsWithChildren<Props>) => {
  const dispatch = useDispatch();
  const {
    children,
    openNotificationCenter,
    closeNotificationCenter,
  } = props;

  const notificationCenterContext: NotificationCenterContextType = useMemo(() => ({
    deleteNotifications: (key: string | string[]) => dispatch(deleteNotifications(key)),
    markNotificationAsRead: (key: string | string[]) => dispatch(markNotificationAsRead(key)),
    openNotificationCenter,
    closeNotificationCenter,
  }), [closeNotificationCenter, dispatch, openNotificationCenter]);

  return (
    <NotificationCenterContext.Provider value={notificationCenterContext}>
      {children}
    </NotificationCenterContext.Provider>
  );
};
