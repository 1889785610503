/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Action, Menu} from "../../generated/api";
import {SecurityService} from "../../service";
import {ActionHandler, getResolvedParameters} from "../../ui";
import {ViewContextValue} from "../view";
import {ContextMenu, ContextMenuActionItem, ContextMenuItem, DividerItem, GetContextObject, isContextMenuItem, IsItemVisible} from "./MenuTypes";

export function mapToContextMenu<T extends object>(
  securityService: SecurityService,
  menu: Menu,
  actionHandler: ActionHandler,
  getContextObject: GetContextObject<T>,
  viewContext?: ViewContextValue,
  isVisible?: IsItemVisible<T>,
): ContextMenu {
  const menuItems: ContextMenuItem[] = menu.items
    ?.map(item => {
      if (item.type === "DIVIDER") {
        return dividerItem();
      } else if (item.type === "ACTION_ITEM" && item.action) {
        return actionItem(item.action, getContextObject, actionHandler, viewContext);
      } else {
        return undefined;
      }
    }).filter(i => isContextMenuItem(i))
    .map(i => i as ContextMenuItem) ?? [];

  const contextMenu: ContextMenu = {
    menuItemType: "MENU",
    icon: menu.icon,
    label: menu.label ?? "",
    isVisible: row => (!isVisible || isVisible(row as T)) && securityService.evaluateVisibilityRule(menu.visible, getContextObject(row as T)),
    items: menuItems,
  };

  return contextMenu;
}

function dividerItem(): DividerItem {
  return {
    menuItemType: "DIVIDER",
  };
}

function actionItem<T extends object>(actionDefinition: Action,
                                      getContextObject: GetContextObject<T>,
                                      actionHandler: ActionHandler,
                                      viewContext?: ViewContextValue): ContextMenuActionItem {
  return {
    menuItemType: "ACTION_ITEM",
    label: actionDefinition.label ?? "",
    icon: actionDefinition.icon ?? "",
    onClick: (context: T) => {
      const contextObject = getContextObject(context);
      const actionContext = buildActionContext(viewContext, contextObject);
      handleActionClick(actionDefinition, actionHandler, actionContext);
    },
  };
}


function buildActionContext(viewContext: ViewContextValue | undefined, contextObject: object): any {
  return {
    ...contextObject,
    ...viewContext,
    contextObject,
  };
}

function handleActionClick(action: Action, actionHandler: ActionHandler, actionContext: any) {
  const resolvedParameters = getResolvedParameters(action, actionContext);
  actionHandler(action, {
    resolvedParameters,
  });
}
