/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  COMPOSITE_VIEW,
  CompositeSubView,
  CompositeViewDataModelExtension,
  createRefreshActionId,
  getNamedComponentFactory,
  IViewProps,
  LOADER,
  MessageKey,
  ParameterUtilities,
  useExecuteViewAction,
  useMessages,
  ViewDescriptor,
} from "@icm/core-common";
import {
  ActivityStreamSubView,
  ActivityStreamSubViewProps,
  entityDataService,
  EntityEditModelComponent,
  EntityEditModelComponentRef,
  EntityListSubView,
  EntityListSubViewProps,
  isEntity,
  REFRESH_EAGER,
  RELOAD_AFTER_SUBMIT,
  useEntityEditModel,
  useEntityUpdateResetEffect,
  useListRefreshActionHandler,
} from "@icm/entity-common";
import {AuditLogSubView, AuditLogSubViewProps} from "@icm/rem-auditlog-common";
import {
  REM_ATTACHMENT,
  REM_AUDIT_LOG,
  REM_COMM_STATUS_USER_REPORT,
  REM_INCIDENT_TYPE,
  REM_OPERATIONAL_CONSEQUENCE,
  REM_TRAIN_INFORMATION,
  TrainInformationSubView,
  TrainInformationSubViewProps,
} from "@icm/rem-rail-common";
import {isBoolean, isString} from "lodash-es";
import * as React from "react";
import {useCallback, useEffect, useRef} from "react";

import {useAdaptedSubViewListFilter, useIncidentViewerPermissions} from "../hooks";

type IncidentViewerDataModel = {
  incidentNumber: string
  incidentId?: string
  printRequested: boolean
} & CompositeViewDataModelExtension


export const IncidentViewerComposite = ((props: IViewProps<IncidentViewerDataModel>) => {
  const {getMessage} = useMessages();
  const {setViewActionHandlers} = props;

  const {
    incidentNumber,
    incidentId,
    printRequested,
  } = props.viewModel.viewModelData;
  const executeAction = useExecuteViewAction(props.viewModel.id);
  const editModel = useEntityEditModel({entityType: REM_INCIDENT_TYPE, initialEntityId: incidentId, initialEntityKey: incidentNumber});

  useEntityUpdateResetEffect(incidentId, REM_INCIDENT_TYPE);

  const {
    showOperationConsequences,
    showAuditLog,
    showMessages,
    showAttachments,
    showTrainInformation,
    showActivityStream,
  } = useIncidentViewerPermissions();

  const setRefreshHandler = useListRefreshActionHandler(setViewActionHandlers);

  const doRefresh = useCallback(() => {
    if (editModelComponent.current) {
      editModelComponent.current.refresh();
    }
    if (showTrainInformation) {
      executeAction(createRefreshActionId(REM_TRAIN_INFORMATION));
    }
    if (showAttachments) {
      executeAction(createRefreshActionId(REM_ATTACHMENT));
    }
    if (showOperationConsequences) {
      executeAction(createRefreshActionId(REM_OPERATIONAL_CONSEQUENCE));
    }
    if (showMessages) {
      executeAction(createRefreshActionId(REM_COMM_STATUS_USER_REPORT));
    }
    if (showAuditLog) {
      executeAction(createRefreshActionId([REM_AUDIT_LOG, incidentNumber]));
    }
  }, [executeAction, showAttachments, showMessages, showOperationConsequences, showTrainInformation, showAuditLog, incidentNumber]);

  const editModelComponent = useRef<EntityEditModelComponentRef>(null);
  useEffect(() => {
    setViewActionHandlers({
      REFRESH: {
        run: doRefresh,
        enabled: editModel !== undefined,
      },
    });
  }, [setViewActionHandlers, executeAction, editModel, showTrainInformation, showAttachments, showOperationConsequences, showMessages, doRefresh]);


  useEffect(() => {
    if (editModel?.entity.id) {
      setViewActionHandlers({
        DOWNLOAD_DETAILS: {
          run: () => entityDataService.downloadEntityPdfReport(editModel.entity, "DETAIL_REPORT"),
          enabled: editModel && !editModel.draft,
        },
      });
    }
  }, [setViewActionHandlers, editModel, editModel?.entity.id]);

  const attachmentListFilter = useAdaptedSubViewListFilter(editModel?.entity.id, "resource_id");
  const operationalConsequencesListFilter = useAdaptedSubViewListFilter(editModel?.entity.id, "incidentId");
  const messagesListFilter = useAdaptedSubViewListFilter(incidentNumber, "contextId");

  const operationConsequencesListProps: EntityListSubViewProps = {
    visible: showOperationConsequences,
    id: "operationalConsequences",
    label: getMessage(MessageKey.RAIL.OPERATIONALCONSEQUENCES.OPERATIONALCONSEQUENCES),
    entityType: REM_OPERATIONAL_CONSEQUENCE,
    filterModifier: operationalConsequencesListFilter,
    contextEntityId: editModel?.entity.id,
    setRefreshHandler,
  };
  const operationConsequencesList = EntityListSubView(operationConsequencesListProps);

  const attachmentListProps: EntityListSubViewProps = {
    visible: showAttachments,
    id: "attachments",
    label: getMessage(MessageKey.INCIDENT.ATTACHMENTS.TITLE),
    entityType: REM_ATTACHMENT,
    filterModifier: attachmentListFilter,
    contextEntityId: editModel?.entity.id,
    setRefreshHandler,
  };
  const attachmentList = EntityListSubView(attachmentListProps);

  const messageListProps: EntityListSubViewProps = {
    visible: showMessages,
    id: "messages",
    label: getMessage(MessageKey.INCIDENT.MESSAGES.TITLE),
    entityType: REM_COMM_STATUS_USER_REPORT,
    filterModifier: messagesListFilter,
    contextEntityId: editModel?.entity.id,
    setRefreshHandler,
  };
  const messageList = EntityListSubView(messageListProps);

  const activityStreamProps: ActivityStreamSubViewProps = {
    showActivityStream,
    editModel: editModel,
    entityId: editModel?.entity.id,
    entityType: REM_INCIDENT_TYPE,
    manualEntryCreation: false,
  };
  const activityStreamSubView = ActivityStreamSubView(activityStreamProps);

  const auditLogProps: AuditLogSubViewProps = {
    visible: showAuditLog,
    incidentNumber: incidentNumber,
    entityType: REM_INCIDENT_TYPE,
  };
  const auditLogSubView = AuditLogSubView(auditLogProps, setViewActionHandlers);

  const trainInformationProps: TrainInformationSubViewProps = {
    visible: showTrainInformation,
    incidentId: editModel?.entity.id,
    setViewActionHandlers,
  };
  const trainInformation = TrainInformationSubView(trainInformationProps);
  const Loader = getNamedComponentFactory(LOADER) || (() => <span>{getMessage(MessageKey.CORE.LOADING)}</span>);
  if (!editModel || !editModel.entity.id) {
    return <Loader />;
  }

  const CompositeView = getNamedComponentFactory(COMPOSITE_VIEW)
    || (() => <span>The composite view is not configured.</span>);
  return (
    <CompositeView onRefresh={doRefresh}>
      <CompositeSubView id="details" label={getMessage(MessageKey.INCIDENT.DETAILS)} key="details">
        <EntityEditModelComponent key="details"
                                  ref={editModelComponent}
                                  editModel={editModel}
                                  formVariant="DEFAULT"
                                  readOnly={true}
                                  submitStrategy={RELOAD_AFTER_SUBMIT}
                                  refreshPolicy={REFRESH_EAGER}
                                  printRequested={printRequested}
        />
      </CompositeSubView>

      {operationConsequencesList}
      {attachmentList}
      {trainInformation}
      {messageList}
      {activityStreamSubView}
      {auditLogSubView}
    </CompositeView>
  );
});


export const incidentViewerCompositeDescriptor: ViewDescriptor<IncidentViewerDataModel> = {
  viewType: "INCIDENT_VIEWER",
  view: IncidentViewerComposite,
  isPrintable: (viewModel) => {
    return !viewModel.viewModelData.selectedTabId
      || ["details", "entityHistory", "trainInformation"].includes(viewModel.viewModelData.selectedTabId);
  },
  getTitle: (viewModel, getMessage) => {
    return viewModel.viewLabel ?? getMessage(MessageKey.INCIDENT.TITLE, {
      params: {
        number: viewModel.viewModelData.incidentNumber,
      },
    });
  },
  createUniqueHash: (viewModelData) => viewModelData.incidentNumber,
  getShareParameters: (viewModel) => {
    const {
      incidentNumber,
      selectedTabId,
    } = viewModel.viewModelData;

    return {
      selectedTabId,
      incidentNumber,
    };
  },
  getViewActionDescriptors: (_viewModel, getMessage) => {
    return {
      REFRESH: {
        title: getMessage(MessageKey.CORE.REFRESH),
        icon: "refresh",
      },
    };
  },
  updateViewModelData: (viewModelData, viewParameters) => {
    viewModelData.selectedTabId = ParameterUtilities.getResolvedParameterValue("selectedTabId", viewParameters, isString);
    viewModelData.printRequested = ParameterUtilities.getResolvedParameterValue("printRequested", viewParameters, isBoolean) ?? false;
  },
  initializeViewModelData: (viewParameters): IncidentViewerDataModel => {
    const printRequested = ParameterUtilities.getResolvedParameterValue("printRequested", viewParameters, isBoolean);
    const incidentParam = ParameterUtilities.getResolvedParameterValue("incident", viewParameters, isEntity);
    const incidentIdParam = ParameterUtilities.getResolvedParameterValue("incidentId", viewParameters, isString);
    const incidentNumberParam = ParameterUtilities.getResolvedParameterValue("incidentNumber", viewParameters, isString);
    const selectedTabId = ParameterUtilities.getResolvedParameterValue("selectedTabId", viewParameters, isString);
    const incidentId = incidentParam?.id ?? incidentIdParam;
    const incidentNumber = incidentParam?.dynamicAttributes?.number.value ?? incidentNumberParam;
    if (!incidentId && !incidentNumber) {
      throw Error("incidentId or incidentNumber must be specified.");
    }
    return {
      incidentId,
      incidentNumber,
      selectedTabId,
      printRequested: printRequested ?? false,
    };
  },
};
