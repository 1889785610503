/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmIconType, MessageKey, RowStatus, useMessages} from "@icm/core-common";
import {ArrowRight} from "@mui/icons-material";
import {TableCell} from "@mui/material";
import clsx from "clsx";
import {PropsWithChildren, SetStateAction} from "react";
import * as React from "react";

import {IconButtonComponent} from "../../../form";
import {ConfirmableInfoChip} from "../../../util";
import {useDataTableContext} from "./DataTableContext";
import {useDataTableStyles} from "./DataTableStyle";
import {getComponent} from "./DataTableUtil";

type RowHeaderProps<T> = {
  row?: T
  /**
   * if set, an expand button will be rendered
   */
  setExpanded?: React.Dispatch<SetStateAction<boolean>>
  /**
   * true if the row is expanded (arrow points down)
   */
  expanded?: boolean
}

const IconByStatus: Record<RowStatus, IcmIconType> = {
  CREATED: "add",
  UPDATED: "edit",
  ARCHIVED: "archive",
  DELETED: "delete",
  FILTER_REMOVED: "filter_remove",
};

const RowHeaderCell = ({children, style}: PropsWithChildren<{style?: React.CSSProperties}>) => {
  const classes = useDataTableStyles();
  const {variant, tableConfig} = useDataTableContext();
  const hasRowHeaderColor = !!tableConfig.getRowHeaderColor;
  return (
    <TableCell component={getComponent(variant)}
               padding="none"
               style={style}
               className={clsx(classes.cell, classes.rowHeaderCell, hasRowHeaderColor && classes.rowHeaderStatusIndicator)}
    >
      {children}
    </TableCell>
  );
};

export const DataTableRowHeader = React.memo(<T extends unknown>({row, expanded, setExpanded}: RowHeaderProps<T>) => {
  const classes = useDataTableStyles();
  const {tableConfig} = useDataTableContext<T>();
  const {getMessage} = useMessages();

  function clickHandler(ev: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (setExpanded) {
      ev.stopPropagation();
      ev.preventDefault();
      setExpanded(e => !e);
    }
  }

  if (!!tableConfig.getRowHeaderColor || tableConfig.getRowStatus || tableConfig.detailPanelRenderer) {
    if (row !== undefined) {
      const rowStatus = tableConfig.getRowStatus?.(row);
      const hasDetails = !!tableConfig.detailPanelRenderer?.hasDetails(row);
      if (hasDetails && !setExpanded) {
        return null;
      }
      return (
        <RowHeaderCell style={{borderLeftColor: tableConfig.getRowHeaderColor?.(row)}}>
          {rowStatus ? (
            <ConfirmableInfoChip icon={IconByStatus[rowStatus]}
                                 description={getMessage(MessageKey.CORE.LIST.ROW_STATE[rowStatus])}
                                 color="primary"
                                 onConfirm={() => tableConfig.markRowAsSeen?.(row)}
            />
          ) : (hasDetails && setExpanded && (
            <IconButtonComponent icon={<ArrowRight fontSize="small" />}
                                 classes={{root: clsx(classes.expandIcon, expanded && classes.expanded)}}
                                 padding="small"
                                 handleClick={(ev) => clickHandler(ev)}
            />
          ))}
        </RowHeaderCell>
      );
    } else if (tableConfig.getTableStatus) {
      const tableStatus = tableConfig.getTableStatus();
      return (
        <RowHeaderCell>
          {tableStatus && (
            <ConfirmableInfoChip icon="information"
                                 description={getMessage(MessageKey.CORE.LIST.TABLE_STATE[tableStatus])}
                                 color="primary"
                                 onConfirm={() => tableConfig.fixOrderAndConfirmChanges?.()}
            />
          )}
        </RowHeaderCell>
      );
    } else {
      return <RowHeaderCell />;
    }
  } else {
    return null;
  }
});
