/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CoreApi,
  FormWidgetProps,
  useFormGeneratorContext,
  useViewModel,
} from "@icm/core-common";
import {getListFilterModifier} from "@icm/entity-common";
import * as React from "react";

import {WebEntityListComponent} from "./WebEntityListComponent";

export const WebEntityListWidget = React.memo((props: FormWidgetProps) => {
  const {viewModel} = useViewModel();
  const widget = props.widget as CoreApi.EntityList;
  const filterParameter: CoreApi.FilterParameter | undefined = widget.filterVariant ? {
    id: widget.filterVariant,
    readOnly: false,
  } : undefined;
  const {evaluateExpression, entity} = useFormGeneratorContext();
  const effectiveType = evaluateExpression(widget.entityType, entity);
  if (effectiveType) {
    return (
      <WebEntityListComponent
        viewModel={viewModel}
        entityType={effectiveType}
        listVariant={widget.listVariant!}
        filterParameter={filterParameter}
        filterModifier={getListFilterModifier(widget, entity)}
        maxHeight={widget.maxHeight}
        actionContext={props.actionContext}
        embedded={true}
      />
    );
  } else {
    console.error("The entity list needs an entityType configured for widget.");
    return <></>;
  }
});
