/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export class Urls {
  public static readonly LOGIN_REQUEST = "/core/loginRequest";
  public static readonly LOGIN_STATUS = "/core/loginStatus";
  public static readonly PASSWORD_CHANGE = "/core/passwordChange";
  public static readonly FILE_THUMBNAIL = "/core/fileThumbnail";
  public static readonly FILE_DATA = "/core/fileData";
  public static readonly FILE_DETAILS = "/core/fileDetails";

  // base server url for api calls, etc.
  private static SERVER_URL = ".";
  public static get API() { return Urls.SERVER_URL + "/api"; }
  public static get SOCKET() { return Urls.SERVER_URL + "/socket"; }

  private static getProtocol(url: string): string | null {
    const index = url.indexOf("://");
    if (index >= 0 && !url.toLowerCase()
      .startsWith("capacitor://") && !url.toLowerCase()
      .startsWith("ionic://")) {
      return url.substring(0, index);
    } else {
      return null;
    }
  }

  private static getUrlForProtocol(url: string, protocol: string | null): string {
    const index = url.indexOf("://");
    if (protocol && index >= 0) {
      return protocol + url.substring(index);
    } else {
      return url;
    }
  }

  private static calculateProtocolForEnvironment(): string | null {
    const protocol = document != null && document.location != null ? Urls.getProtocol(document.location.href) : "http";
    return Urls.isWebEnvironment() ? protocol : null;
  }

  public static isWebEnvironment(): boolean {
    return Urls.SERVER_URL === ".";
  }

  public static equalsServerUrl(url: string): boolean {
    return Urls.SERVER_URL === url;
  }

  // Do not create a getServerUrl method and build URLs elsewhere in the project. Do it in this file.
  public static setServerUrl(url: string): void {
    Urls.SERVER_URL = url;
  }

  public static finalizeRelativeUrl(relativeUrl: string): string {
    if (relativeUrl.startsWith("/")) {
      relativeUrl = relativeUrl.substring(1);
    }
    const absoluteUrl = `${Urls.SERVER_URL}/${relativeUrl}`;
    const targetProtocol = Urls.calculateProtocolForEnvironment();
    return Urls.getUrlForProtocol(absoluteUrl, targetProtocol);
  }

  public static finalizeRelativeApiUrl(relativeUrl: string): string {
    if (relativeUrl.startsWith("/api/")) {
      relativeUrl = relativeUrl.substring("/api/".length);
    } else if (relativeUrl.startsWith("/")) {
      relativeUrl = relativeUrl.substring(1);
    }
    const absoluteUrl = `${Urls.API}/${relativeUrl}`;
    const targetProtocol = Urls.calculateProtocolForEnvironment();
    return Urls.getUrlForProtocol(absoluteUrl, targetProtocol);
  }

}
