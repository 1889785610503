/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ApiUtil,
  CoreServiceRegistry,
  CreateFilterOptions,
  DataLoad,
  DataLoadService,
  FieldHighlightMap,
  FilterConditionItem,
  FilterService,
  GetMessage,
  HighlightedFieldsService,
  IdGenerator,
  ListFilter,
  MessageKey,
  SecurityService,
  StoreService,
} from "@icm/core-common";

import {ActivityStreamApi} from "../api";
import {
  ActivityStreamEntry,
  Change,
  CollectionPropertyUpdate,
  ObjectChange,
  ObjectReference,
  PropertyUpdate,
  PropertyUpdateUnion,
  ValuePropertyUpdate,
} from "../generated/api";
import {notifyNewObjectUpdate, setEntityUpdateAsSeen} from "../store";
import {isCollectionPropertyUpdate, isObjectChange, isValuePropertyUpdate} from "../util";

class ActivityStreamService implements HighlightedFieldsService {
  private static readonly TYPE = "ACTIVITY_STREAM_ENTRY";

  private readonly securityService: SecurityService;

  constructor(securityService: SecurityService) {
    this.securityService = securityService;
  }

  public addCallInitiationActivityStreamEntry(
    calledNumber: string,
    securityService: SecurityService,
    getMessage: GetMessage,
    relatedObject?: ObjectReference
  ) {
    const currentUser = securityService.getCurrentUserDetails();
    const activeRoleNames = securityService.getActiveRolesNameString();
    const activeRoleDisplayNames = securityService.getActiveRolesDisplayNameString();
    const callStartedMessage = getMessage(MessageKey.CORE.CALL.STARTED, {params: {calledNumber: calledNumber}});
    const entry: ActivityStreamEntry = {
      id: IdGenerator.randomUUID(),
      actor: {
        userName: currentUser?.userName,
        displayName: securityService.getCurrentUserFullName(),
        roleName: activeRoleNames,
        roleDisplayName: activeRoleDisplayNames,
      },
      timestamp: new Date(),
      changes: [{
        changeType: "OTHER",
        description: callStartedMessage,
        relatedObjects: relatedObject ? [relatedObject] : undefined,
      }],

    };
    ActivityStreamApi.putEntries([entry])
      .then(createdIds => {
        console.log("Posted Activity Stream Update, result:", createdIds);
      });
  };

  public createFilter({
                        entityType,
                        entityId,
                        collectionModifiers,
                        afterDate,
                        offset,
                        limit,
                      }: CreateFilterOptions): ListFilter {
    const filterConditions: FilterConditionItem[] = [
      {
        conditionType: "ITEM",
        property: "changes.size()",
        operator: "IS_GREATER",
        valueType: "NUMBER",
        values: ["0"],
      },
    ];
    if (afterDate) {
      const afterDateWithMillis = new Date(afterDate);
      filterConditions.push({
        conditionType: "ITEM",
        property: "timestamp",
        operator: "IS_GREATER",
        valueType: "DATETIME",
        values: [afterDateWithMillis.toISOString()],
      });
    }

    const result: ListFilter = {
      filter: {
        conditionType: "GROUP",
        logicalOperator: "AND",
        filterConditions: filterConditions,
      },
      sorting: {
        property: "createdAt",
        order: "DESC",
      },
      offset: offset,
      limit: limit,
    };
    if (entityId && entityType) {
      result.collectionModifiers = [
        {
          propertyOfCollectionToFilterOn: "changes",
          listFilter: {
            filter: {
              conditionType: "GROUP",
              logicalOperator: "OR",
              filterConditions: [
                {
                  conditionType: "GROUP",
                  logicalOperator: "AND",
                  filterConditions: [
                    {
                      conditionType: "ITEM",
                      valueType: "STRING",
                      property: "objectReference.type",
                      operator: "EQUALS",
                      values: [entityType],
                    },
                    {
                      conditionType: "ITEM",
                      valueType: "STRING",
                      property: "objectReference.id",
                      operator: "EQUALS",
                      values: [entityId],
                    },
                  ],
                },
                {
                  conditionType: "GROUP",
                  logicalOperator: "AND",
                  filterConditions: [
                    {
                      conditionType: "ITEM",
                      valueType: "STRING",
                      property: "relatedObjects.type",
                      operator: "EQUALS",
                      values: [entityType],
                    },
                    {
                      conditionType: "ITEM",
                      valueType: "STRING",
                      property: "relatedObjects.id",
                      operator: "EQUALS",
                      values: [entityId],
                    },
                  ],
                },
              ],
            },
          },
        },
      ];
    } else if (collectionModifiers) {
      result.collectionModifiers = collectionModifiers;
    }

    return result;
  }

  public async getHighlightedFieldsFromEntries(listFilter: ListFilter, outdated: boolean, filterActor: string | undefined): Promise<FieldHighlightMap> {
    const entries = await ActivityStreamApi.getEntries(listFilter);
    return this.getHighlightedFieldsFromActivityStreamEntries(entries.sublist || [], outdated, undefined);
  }

  public getHighlightedFieldsFromActivityStreamEntries(entries: ActivityStreamEntry[], outdated: boolean, filterActor: string | undefined) {
    const highlightedFields: FieldHighlightMap = {};
    entries.forEach(entry => {
      if (entry.changes) {
        entry.changes.forEach(change => {
          if (this.isObjectChange(change) && change.propertyUpdates) {
            change.propertyUpdates.forEach(propertyUpdate => {
              if ((
                  this.isValuePropertyUpdate(propertyUpdate)
                  || this.isCollectionPropertyUpdate(propertyUpdate)
                )
                && propertyUpdate.fieldReference && propertyUpdate.fieldReference.valueBinding
                && (filterActor === undefined || entry.actor?.userName !== filterActor)) {
                highlightedFields[propertyUpdate.fieldReference.valueBinding] = {
                  displayName: propertyUpdate.fieldReference.displayName ?? "",
                  updatedAt: entry.createdAt!,
                  actor: entry.actor?.displayName ?? "",
                  outdated: outdated,
                };
              }
            });
          }
        });
      }
    });
    return highlightedFields;
  }

  public initialize() {
    this.initialize = () => {
    };
    DataLoadService.getInstance().registerEntityTypeListener(ActivityStreamService.TYPE, (load: DataLoad) => {
      const objects = load.changes ? load.changes.map(c => c.object) : [];
      const entries: ActivityStreamEntry[] = ApiUtil.convertArray(objects, ActivityStreamEntry.fromData);
      for (const entry of entries) {
        const isChangeTriggeredByCurrentUser = this.isTriggeredByCurrentUser(entry);
        for (const change of entry.changes!) {
          if (isObjectChange(change)) {
            if (!isChangeTriggeredByCurrentUser && entry.id && change.objectReference?.type) {
              const notificationService = CoreServiceRegistry.getOptional("NOTIFICATION");
              notificationService?.fireObjectChange({
                id: entry.id,
                objectType: change.objectReference.type,
                change,
                changedObject: change.objectReference.object,
                timestamp: entry.timestamp,
                propertyUpdates: ActivityStreamService.mapToNotificationPropertyUpdates(change.propertyUpdates),
              });
            }
            StoreService.store.dispatch(notifyNewObjectUpdate({
              type: change.objectReference!.type!,
              id: change.objectReference!.id!,
              lastUpdatedAt: entry.createdAt!,
              objectChange: change,
              actor: entry.actor,
            }));
          }
        }
      }
    });
  }

  public isCollectionPropertyUpdate(propertyUpdate: PropertyUpdate): propertyUpdate is CollectionPropertyUpdate {
    return isCollectionPropertyUpdate(propertyUpdate);
  }

  public isObjectChange(change: Change): change is ObjectChange {
    return isObjectChange(change);
  }

  public isValuePropertyUpdate(propertyUpdate: PropertyUpdate): propertyUpdate is ValuePropertyUpdate {
    return isValuePropertyUpdate(propertyUpdate);
  }

  public putEntries(entries: ActivityStreamEntry[]): Promise<string[]> {
    return ActivityStreamApi.putEntries(entries);
  }

  /**
   * Registers the given changeHandler for the changes of a certain object.
   * @param filter filter that will be applied to the incoming elements
   * @param entryHandler the entryHandler
   * @return a function to unregister from updates
   */
  public registerListener(
    filter: ListFilter,
    entryHandler: (matchingEntries: ActivityStreamEntry[], nonMatchingEntries: ActivityStreamEntry[]) => void
  ): () => void {
    console.log("Registering activity stream entry listener for", filter);
    const listener = (load: DataLoad) => {
      const objects = load.changes ? load.changes.map(c => c.object) : [];
      const entries: ActivityStreamEntry[] = ApiUtil.convertArray(objects, ActivityStreamEntry.fromData);
      const filteredEntries = FilterService.applyFilter(entries, filter);
      entryHandler(filteredEntries, entries.filter(e => !filteredEntries.find(fe => e.id === fe.id)));
    };

    DataLoadService.getInstance().registerEntityTypeListener(ActivityStreamService.TYPE, listener);
    return () => {
      console.log("Unregistered listener for ", filter);
      DataLoadService.getInstance().unregisterEntityTypeListener(ActivityStreamService.TYPE, listener);
    };
  }

  public registerHighlightedFieldsListener(
    filter: ListFilter,
    outdated: boolean,
    filterActor: string | undefined,
    entryHandler: (highlightedFields: FieldHighlightMap) => void
  ): () => void {
    console.log("Registering activity stream entry listener for", filter);
    const listener = (load: DataLoad) => {
      const objects = load.changes ? load.changes.map(c => c.object) : [];
      const entries: ActivityStreamEntry[] = ApiUtil.convertArray(objects, ActivityStreamEntry.fromData);
      const filteredEntries = FilterService.applyFilter(entries, filter);
      const highlightedFields = this.getHighlightedFieldsFromActivityStreamEntries(filteredEntries, outdated, filterActor);
      entryHandler(highlightedFields);
    };

    DataLoadService.getInstance().registerEntityTypeListener(ActivityStreamService.TYPE, listener);
    return () => {
      console.log("Unregistered listener for ", filter);
      DataLoadService.getInstance().unregisterEntityTypeListener(ActivityStreamService.TYPE, listener);
    };
  }

  public setEntityUpdateAsSeen(entityType: string, idOrIds: string | string[]) {
    StoreService.store.dispatch(setEntityUpdateAsSeen({type: entityType, idOrIds}));
  }

  private isActorCurrentRole(entry: ActivityStreamEntry): boolean {
    if (entry.actor && entry.actor.roleName) {
      return this.securityService.isActiveRoleName(entry.actor.roleName);
    } else {
      return false;
    }
  }

  private isActorCurrentUser(entry: ActivityStreamEntry): boolean {
    if (this.securityService.getCurrentUserDetails() && entry.actor && entry.actor.userName) {
      return this.securityService.getCurrentUserDetails()!.userName === entry.actor.userName;
    } else {
      return false;
    }
  }

  private isTriggeredByCurrentUser(entry: ActivityStreamEntry): boolean {
    return this.isActorCurrentUser(entry) && this.isActorCurrentRole(entry);
  }

  private static mapToNotificationPropertyUpdates(propertyUpdates?: PropertyUpdateUnion[]) {
    return propertyUpdates
      ?.filter(pu => (isValuePropertyUpdate(pu) || isCollectionPropertyUpdate(pu)) && pu.fieldReference?.valueBinding)
      ?.map((pu: ValuePropertyUpdate | CollectionPropertyUpdate) => ({
        valueBinding: pu.fieldReference!.valueBinding!,
        oldValue: isValuePropertyUpdate(pu) ? pu.oldValue : undefined,
        newValue: isValuePropertyUpdate(pu) ? pu.newValue : undefined,
      }));
  }
}

export {ActivityStreamService};
