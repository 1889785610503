/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {StorageStrategy} from "../data/";

export interface StorageService {
  loadData<T>(name: string): Promise<T | null>;

  saveData(name: string, data: any, strategy: StorageStrategy): void;

  deleteData(name: string): void;
}

/**
 * Default implementation for StorageService that does nothing.
 */
export const NullStorageService: StorageService = {
  deleteData(_name: string): void {
  },
  loadData<T>(_name: string): Promise<T | null> {
    return Promise.resolve(null);
  },
  saveData(_name: string, _data: any, _strategy: StorageStrategy): void {
  },
};
