/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {EditModelDescriptor, IUiState} from "../types";
import {canCloseTabGroup, findTabGroup, tabGroupIndex} from "./PerspectiveHelper";


export type CloseTabOptions = {

  /** The id of the tab to be closed */
  tabId: string,
  /** the id of group if the tab that should be closed */
  tabGroupId: string,

} & EditModelDescriptor


/**
 * Close a tab identified by the given id. If the tab is the last in its group,
 * collapse the group as well.
 *
 * @param state
 * @param action
 * @constructor
 */
export const CloseTab = (state: IUiState, action: PayloadAction<CloseTabOptions>) => {
  const perspective = state.perspective;
  const {tabGroupId, tabId} = action.payload;

  if (perspective?.perspectiveType === "TABBED") {
    const tabGroup = findTabGroup(perspective, tabGroupId);
    if (tabGroup && perspective) {
      tabGroup.tabs = tabGroup.tabs.filter(t => t.id  !== tabId);
      tabGroup.selectedTabIndex = Math.max(0, Math.max(tabGroup.selectedTabIndex - 1, 0));

      if (canCloseTabGroup(perspective, tabGroup)) {
        const groupIndex = tabGroupIndex(perspective, tabGroup);

        // select the left group if we close from the right
        // if we close the leftmost group, choose the right
        const nextCurrentGroup = groupIndex > 0 ? perspective.tabGroups[groupIndex - 1] : perspective.tabGroups[1];

        perspective.tabGroups  = perspective.tabGroups.filter(g => g.id !== tabGroupId);
        perspective.currentTabGroupId = nextCurrentGroup.id;
      }
    }
  } else {
    console.warn("Can't close tab in non-tabbed perspective");
  }
};
