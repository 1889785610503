/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  DialogProvider,
  useActionHandler,
  useFeatureConfiguration,
  useOpenViewFromUrl,
  useService,
  useTabbedPerspective,
  useUiPersistence,
  WebSocketConnection,
} from "@icm/core-common";
import {CssBaseline, GlobalStyles} from "@mui/material";
import {Box} from "@mui/system";
import * as React from "react";
import {useEffect} from "react";

import {
  LoadingSpinner,
  TabbedPerspectiveComponent,
  useWebAlertDialog,
  useWebConfirmDialog,
  useWebImageViewerDialog,
  useWebPromptDialog,
} from "../../components";
import {ActionBarViewer} from "../menu/ActionBarViewer";
import {ApplicationHeader} from "../menu/ApplicationHeader";
import styles from "./MainPageStyle";

import "allotment/dist/style.css";


const globalStyles = (
  <GlobalStyles styles={{
    body: {
      // hide overflowX to avoid scrollbars when quickly moving between two tooltips that are close to the left/right edge
      overflowX: "hidden",
    },
  }}
  />
);


export const MainPage = () => {
  const featureConfiguration = useFeatureConfiguration();
  const perspectiveDefinition = useTabbedPerspective();
  const securityService = useService("SECURITY");
  const connectionMonitoringService = useService("CONNECTION_MONITORING");

  useUiPersistence();
  useOpenViewFromUrl();

  useEffect(() => {
    if (featureConfiguration.data) {
      WebSocketConnection.initialize(securityService, connectionMonitoringService, featureConfiguration.data);
    }
  }, [securityService, connectionMonitoringService, featureConfiguration.data]);

  const handler = useActionHandler();

  if (perspectiveDefinition) {
    return (
      <Box sx={styles.icmAppRoot}>
        <DialogProvider useAlertDialog={useWebAlertDialog}
                        usePromptDialog={useWebPromptDialog}
                        useConfirmDialog={useWebConfirmDialog}
                        useImageDialog={useWebImageViewerDialog}
        >
          <CssBaseline />
          {globalStyles}
          <ApplicationHeader />
          <Box sx={styles.icmAppContentContainer}>
            <ActionBarViewer actionBar={perspectiveDefinition.globalActions}
                             dispatchAction={handler}
            />
            <TabbedPerspectiveComponent perspective={perspectiveDefinition} />
          </Box>
        </DialogProvider>
      </Box>
    );
  } else {
    return (
      <LoadingSpinner />
    );
  }
};
