/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {createContext, PropsWithChildren, useContext} from "react";

import {GenericFormConfiguration, GenericFormField} from "../../generated/api";
import {FieldValidationType} from "./FieldValidationState";
import {FormGeneratorProps} from "./FormGenerator";
import {FieldHighlightingInformation, FieldHighlightMap} from "./FormGeneratorTypes";
import {useFormGenerator} from "./useFormGenerator";

/**
 * A function that takes a rule expression and evaluates to a true/false value.
 * If there is no rule, the defaultResult will be returned.
 */
export type FormRuleEvaluator = (rule: string | undefined, defaultResult: boolean, fieldValue?: any) => boolean;

/**
 * A function that takes the expression of a form field and evaluates the field value to a new string value.
 */
export type FormExpressionEvaluator = (expr: string | undefined, fieldValue?: any) => string | undefined;

export type FormGeneratorContextType = {
  evaluateRule: FormRuleEvaluator,
  evaluateExpression: FormExpressionEvaluator,
  handleChange: (field: GenericFormField, value?: any, referredObject?: any) => void
  valid: boolean
  updateFieldValidationState: (validationType: FieldValidationType, valueBinding: string, isFieldValid: boolean) => void
  entity: any
  readOnly?: boolean
  formConfig: GenericFormConfiguration
  findHighlight: (valueBinding?: string) => FieldHighlightingInformation | undefined
  highlightedFields?: FieldHighlightMap
  unHighlightField?: (valueBinding: string) => void
  useSimpleValues: boolean
  getDetailsForExpressionContext: () => any
};
const errorMessage = "Using FormGeneratorContext outside a FormGeneratorContextProvider";
const FormGeneratorContext = createContext<FormGeneratorContextType>({
  evaluateRule: () => {
    throw new Error(errorMessage);
  },
  evaluateExpression: () => {
    throw new Error(errorMessage);
  },
  handleChange: () => {
    throw new Error(errorMessage);
  },
  valid: true,
  updateFieldValidationState: () => {
    throw new Error(errorMessage);
  },
  entity: {},
  formConfig: {
    formConfigurationType: "GENERIC",
  },
  useSimpleValues: false,
  getDetailsForExpressionContext: () => {
    throw new Error(errorMessage);
  },
  findHighlight: () => {
    throw new Error(errorMessage);
  },
  unHighlightField: () => {
    throw new Error(errorMessage);
  },
});

export const FormGeneratorContextProvider = React.memo(<T extends {}>(props: PropsWithChildren<FormGeneratorProps<T>>) => {
  const formGeneratorContext = useFormGenerator(props);
  return (
    <FormGeneratorContext.Provider value={formGeneratorContext}>
      {props.children}
    </FormGeneratorContext.Provider>
  );
});

export const useFormGeneratorContext = () => {
  return useContext(FormGeneratorContext);
};
