/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
/**
 * This function can be used as equalFn in React.memo(Component, equalFn).
 *
 * It compares the given props structurally and warns (during development) if they are structurally equal but are referentially unequal.
 *
 * @return true if props are structurally/deep equal, false otherwise
 */
export function arePropsEqual(prevProps: object, nextProps: object): boolean {
  const prevKeys = prevProps ? Object.keys(prevProps) : [];
  const nextKeys = nextProps ? Object.keys(nextProps) : [];
  const keys = [...prevKeys, ...nextKeys.filter(k => !prevKeys.includes(k))];
  const diffKeys = keys.filter(k => prevProps?.[k] !== nextProps?.[k]);
  if (process.env.NODE_ENV === "development" && diffKeys.length > 0) {
    const diffs = diffKeys.map(k => ({key: k, oldValue: value(prevProps?.[k]), newValue: value(nextProps?.[k])}));
    const diffsText = diffs.map(d => `${d.key}: ${d.oldValue} !== ${d.newValue}`).join(", ");
    console.debug(`Difference ${nextProps["label"]}: ${diffsText}`);
    const equalDiffs = diffs.filter(diff => diff.oldValue === diff.newValue);
    if (equalDiffs.length > 0) {
      const equalDiffsText = equalDiffs.map(d => `${d.key}: ${d.oldValue}`).join(", ");
      console.warn(`Reference difference ${nextProps["label"]}: ${equalDiffsText}`);
    }
  }
  return diffKeys.length === 0;
}

function value(v: any) {
  if (typeof v === "function") {
    return "" + v;
  }
  return JSON.stringify(v);
}
