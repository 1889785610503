/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import produce from "immer";
import React, {useCallback, useContext, useMemo, useRef, useState} from "react";

export type ViewActionRunner<T> = (actionParameter: T) => void;

export type ViewActionHandler<T> = {
  // @default true
  enabled?: boolean | (() => boolean)
  run: ViewActionRunner<T>
} | {
  enabled: false | (() => boolean)
  run?: ViewActionRunner<T>
};

export type IdentifiableViewActionHandler<T> = {
  key: string
} & ViewActionHandler<T>;

export type ViewActionHandlersByActionId = Record<string, ViewActionHandler<any>>;

export type ViewActionsByViewModelId = Record<string, ViewActionHandlersByActionId>;

type ViewActionStatesByViewModelId = Record<string, Record<string, boolean>>;

export type ViewActionsContextType = {
  setViewActionHandlers: (viewModelId: string, handlers: ViewActionHandlersByActionId) => void
  handleAction: <T, >(viewModelId: string, actionId: string, actionParameter: T) => void
  hasActionHandler: (viewModelId: string, actionId: string) => boolean
  isActionHandlerEnabled: (viewModelId: string, actionId: string) => boolean
  viewActionState: ViewActionStatesByViewModelId
}

const ViewActionsContext = React.createContext<ViewActionsContextType>({
  setViewActionHandlers: () => {
    throw Error("Trying to use ViewActionsContext.setViewActionHandlers outside of existing context");
  },
  handleAction: () => {
    throw Error("Trying to use ViewActionsContext.handleAction outside of existing context");
  },
  hasActionHandler: () => {
    throw Error("Trying to use ViewActionsContext.hasActionHandler outside of existing context");
  },
  isActionHandlerEnabled: () => {
    throw Error("Trying to use ViewActionsContext.isActionHandlerEnabled outside of existing context");
  },
  viewActionState: {},
});

export const ViewActionsProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [viewActions, setViewActions] = useState<ViewActionsByViewModelId>({});
  const [viewActionState, setViewActionState] = useState<ViewActionStatesByViewModelId>({});

  const currentViewActions = useRef(viewActions);
  currentViewActions.current = viewActions;

  const contextValue: ViewActionsContextType = useMemo(() => ({
    setViewActionHandlers: (viewModelId: string, handlers: ViewActionHandlersByActionId) => {
      setViewActions(currentHandlers => {
        return {
          ...currentHandlers,
          [viewModelId]: {...currentHandlers[viewModelId], ...handlers},
        };
      });
      setViewActionState(prevState => {
        const enabledByActionKey = Object.entries(handlers)
          .map(([actionKey, handler]) => ({
            actionKey,
            enabled: handler.enabled ?? true,
          }));
        return produce(prevState, (draft) => {
          for (const actionKeyAndEnabled of enabledByActionKey) {
            if (!draft[viewModelId]) {
              draft[viewModelId] = {};
            }
            draft[viewModelId][actionKeyAndEnabled.actionKey] = (typeof actionKeyAndEnabled.enabled === "boolean") ? actionKeyAndEnabled.enabled : actionKeyAndEnabled.enabled();
          }
          return draft;
        });
      });
    },
    handleAction: <T, >(viewModelId: string, actionId: string, actionParameter: T) => {
      const actionHandler = currentViewActions.current[viewModelId]?.[actionId];
      if (actionHandler?.run) {
        actionHandler.run(actionParameter);
      } else {
        console.warn("No handler found for actionId", actionId, "and viewModelId", viewModelId);
      }
    },
    hasActionHandler: (viewModelId: string, actionId: string): boolean => {
      const actionHandler = currentViewActions.current[viewModelId]?.[actionId];
      return !!actionHandler?.run;
    },
    isActionHandlerEnabled: (viewModelId: string, actionId: string): boolean => {
      const actionHandler = currentViewActions.current[viewModelId]?.[actionId];
      return (typeof actionHandler?.enabled === "boolean") ? actionHandler?.enabled : (actionHandler?.enabled ? actionHandler?.enabled() : false);
    },
    viewActionState,
  }), [viewActionState]);


  return (
    <ViewActionsContext.Provider value={contextValue}>
      {children}
    </ViewActionsContext.Provider>
  );
};

export const useSetViewActionHandlers = (viewModelId: string) => {
  const {setViewActionHandlers} = useContext(ViewActionsContext);
  return useCallback((handlers: ViewActionHandlersByActionId) => {
    setViewActionHandlers(viewModelId, handlers);
  }, [setViewActionHandlers, viewModelId]);
};

export const useExecuteViewAction = <T, >(viewModelId?: string) => {
  const {handleAction} = useContext(ViewActionsContext);
  return useCallback((actionId: string, actionParameter?: T) => {
    if (viewModelId) {
      handleAction(viewModelId, actionId, actionParameter);
    }
  }, [handleAction, viewModelId]);
};

export const useHasActionHandler = (viewModelId?: string) => {
  const {hasActionHandler} = useContext(ViewActionsContext);
  return useCallback((actionId: string) => {
    if (viewModelId) {
      return hasActionHandler(viewModelId, actionId);
    }
    return false;
  }, [hasActionHandler, viewModelId]);
};

export const useIsActionHandlerEnabled = (viewModelId?: string) => {
  const {isActionHandlerEnabled} = useContext(ViewActionsContext);
  return useCallback((actionId: string) => {
    if (viewModelId) {
      return isActionHandlerEnabled(viewModelId, actionId);
    }
    return false;
  }, [isActionHandlerEnabled, viewModelId]);
};

export const useViewActionState = (viewModelId: string) => {
  const {viewActionState} = useContext(ViewActionsContext);
  const viewModelViewActionState = viewActionState[viewModelId];
  return useMemo(() => viewModelViewActionState ?? {}, [viewModelViewActionState]);
};

export const createRefreshActionId = (context: string | (string | undefined)[]) => {
  if (typeof context === "string") {
    return `REFRESH_${context.trim().toUpperCase()}`;
  } else {
    return `REFRESH_${context.filter(c => c !== undefined).join("_").trim().toUpperCase()}`;
  }
};
