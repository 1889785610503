/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState, Notification} from "../types";


export const AddExistingNotification = (
  state: IUiState,
  action: PayloadAction<Omit<Notification, "read" | "announced">>
) => {
  const notification = action.payload;
  if (!state.notifications.find(n => n.key === notification.key)) {
    const ommit = notification.omitCondition ? notification.omitCondition(state) : false;
    if (!ommit) {
      state.notifications.push({
        ...notification,
        read: true,
        announced: true,
      });
    }
  }
};
