/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMarkObjectAsSeen} from "@icm/activitystream-common";
import {useCallback} from "react";

import {EntityListElementView} from "./useUpdatingInfiniteEntityList";

export const useMarkEntitiesAsSeen = () => {
  const markAsSeen = useMarkObjectAsSeen();
  return useCallback((views: EntityListElementView[]) => {
    if (views.length > 0 && views[0].type) {
      const entityType = views[0].type;
      markAsSeen(entityType, views.map(v => v.id).filter(id => id !== undefined).map(id => id as string));
    }
  }, [markAsSeen]);
};
