/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmColorScheme, IcmTheme, isDark, withAlpha} from "@icm/core-common";
import {createTheme, CSSObject, Palette, SxProps, Theme} from "@mui/material";
// eslint-disable-next-line camelcase
import {unstable_styleFunctionSx} from "@mui/system";

import {IcmMuiTheme} from "./IcmMuiTheme";
import {OverrideOptions} from "./withThemeOverride";

/**
 * copied from material-ui createTypography.js
 */
function round(value: number) {
  return Math.round(value * 1e5) / 1e5;
}

// alpha values taken from material-ui@v4 default light theme
// See https://material-ui.com/customization/default-theme/
const actionLight = (contrastColor: string) => ({
  // The color of an active action like an icon button.
  active: withAlpha(contrastColor, 0.54),
  // The color of an hovered action.
  hover: withAlpha(contrastColor, 0.04),
  hoverOpacity: 0.04,
  // The color of a selected action.
  selected: withAlpha(contrastColor, 0.08),
  selectedOpacity: 0.08,
  // The color of a disabled action.
  disabled: withAlpha(contrastColor, 0.26),
  // The background color of a disabled action.
  disabledBackground: withAlpha(contrastColor, 0.12),
  disabledOpacity: 0.38,
  focus: withAlpha(contrastColor, 0.12),
  focusOpacity: 0.12,
  activatedOpacity: 0.12,
});

// alpha values taken from material-ui@v4 default dark theme
// See https://material-ui.com/customization/default-theme/
const actionDark = (contrastColor: string) => ({
  active: withAlpha(contrastColor, 1),
  hover: withAlpha(contrastColor, 0.08),
  hoverOpacity: 0.08,
  selected: withAlpha(contrastColor, 0.16),
  selectedOpacity: 0.16,
  disabled: withAlpha(contrastColor, 0.3),
  disabledBackground: withAlpha(contrastColor, 0.12),
  disabledOpacity: 0.38,
  focus: withAlpha(contrastColor, 0.12),
  focusOpacity: 0.12,
  activatedOpacity: 0.24,
});

function createMuiColorPaletteFrom(colorScheme: IcmColorScheme) {
  return {
    main: colorScheme.main,
    light: colorScheme.light,
    dark: colorScheme.dark,
    contrastText: colorScheme.contrast,
  };
}
function createDefaultColors(colorScheme: IcmColorScheme): Partial<Palette> {
  return {
    text: {
      primary: colorScheme.textPrimary,
      secondary: colorScheme.textSecondary,
      disabled: colorScheme.disabled,
    },
    divider: colorScheme.divider,
    background: {
      paper: colorScheme.main,
      default: colorScheme.dark,
    },
    action: isDark(colorScheme.main) ? actionDark(colorScheme.contrast) : actionLight(colorScheme.contrast),
    mode: isDark(colorScheme.main) ? "dark" : "light",
  };
}

export function overrideDefaultColor(muiTheme: IcmMuiTheme, overrideOptions: OverrideOptions): IcmMuiTheme {
  const {background, primary, secondary, info, warning, error, success} = overrideOptions;
  const backgroundColors = background ? createDefaultColors(background) : {};
  return {
    ...muiTheme,
    palette: {
      ...muiTheme.palette,
      primary: primary ? createMuiColorPaletteFrom(primary) : muiTheme.palette.primary,
      secondary: secondary ? createMuiColorPaletteFrom(secondary) : muiTheme.palette.secondary,
      info: info ? createMuiColorPaletteFrom(info) : muiTheme.palette.info,
      warning: warning ? createMuiColorPaletteFrom(warning) : muiTheme.palette.warning,
      error: error ? createMuiColorPaletteFrom(error) : muiTheme.palette.error,
      success: success ? createMuiColorPaletteFrom(success) : muiTheme.palette.success,
      ...backgroundColors,
    },
  };
}

/**
 * Creates material-ui theme using the icm theme configuration.
 */
export function createMaterialUiTheme(icmTheme: IcmTheme): IcmMuiTheme {
  const body1FontSize = "1rem";
  const body2FontSize = "0.875rem";
  const body2FontWeight = 400;
  const captionLineHeight = 1.66;
  const muiTheme = createTheme({
    typography: {
      caption: {
        fontSize: body2FontSize,
        letterSpacing: `${round(0.15 / 14)}em`, // body2 letter spacing / body2 size in px (as done in material-ui createTypography.js)
      },
    },
    palette: {
      primary: createMuiColorPaletteFrom(icmTheme.palette.primary),
      secondary: createMuiColorPaletteFrom(icmTheme.palette.secondary),
      error: createMuiColorPaletteFrom(icmTheme.palette.error),
      warning: createMuiColorPaletteFrom(icmTheme.palette.warning),
      success: createMuiColorPaletteFrom(icmTheme.palette.success),
      info: createMuiColorPaletteFrom(icmTheme.palette.info),
      ...createDefaultColors(icmTheme.palette.background),
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: body1FontSize,
            maxWidth: 600,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: body2FontSize,
            fontWeight: body2FontWeight,
            lineHeight: captionLineHeight,
          },
          body: {
            fontSize: body1FontSize,
          },
          footer: {
            fontSize: body2FontSize,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            transform: "translate(0, 1.5px) scale(0.875)", // shrink to body2 font size
            lineHeight: 1,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionEnd: {
            marginLeft: 0,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          tagSizeSmall: {
            // prevent auto complete from breaking in two lines if only one tag is selected
            maxWidth: "calc(100% - 67px)",
          },
        },
      },
    },
  });

  return {
    unstable_sxConfig: {},
    /* eslint-disable camelcase */
    unstable_sx: function unstable_sx(props: SxProps<Theme>): CSSObject {
      return unstable_styleFunctionSx({
        sx: props,
        theme: this,
      }) as CSSObject;
    },
    shape: muiTheme.shape,
    breakpoints: muiTheme.breakpoints,
    direction: muiTheme.direction,
    mixins: muiTheme.mixins,
    components: muiTheme.components,
    palette: muiTheme.palette,
    shadows: muiTheme.shadows,
    spacing: muiTheme.spacing,
    transitions: muiTheme.transitions,
    typography: muiTheme.typography,
    zIndex: muiTheme.zIndex,
    icm: icmTheme,
  };
}
