/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ExpressionEvaluationService, valueEqual} from "@icm/core-common";
import {PayloadAction} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash-es";

import {EntityPropertyValue} from "../../generated/api";
import {entityUpdateService} from "../../service";
import {EntityEditState} from "../types";


function isEqualValue(value1: any, value2: any): boolean {
  const value1NullOrUndefined = value1 === null || value1 === undefined;
  const value2NullOrUndefined = value2 === null || value2 === undefined;
  const bothNullOrUndefined = value1NullOrUndefined && value2NullOrUndefined;
  return bothNullOrUndefined || valueEqual(value1, value2);
}

function isParentChange(p: EntityPropertyValue, change: EntityPropertyValue): boolean {
  return p.propertyName?.startsWith(change.propertyName + ".") ?? false;
}


/**
 * Options object that can be used to update an EntityEditModel.
 * In case the id is passed, an entity must have been initialized before.
 * Otherwise an error is raised.
 */
export type UpdateEntityEditModelOptions = {
  entityId: string,
  propertyChanges?: EntityPropertyValue[],
  deletedPropertyNamePrefixes?: string[],
  propertyChangesForEagerValues?: EntityPropertyValue[]
};

/**
 * Update an existing entity edit model.
 * If no model is found for the related entity id, an error is thrown.
 *
 * @param state
 * @param action
 * @see UpdateEntityEditModelOptions
 * @see InitUpdateEntityEditModelOptions
 */
export const UpdateEntityEditModel = (state: EntityEditState, action: PayloadAction<UpdateEntityEditModelOptions>) => {
  const {
    entityId,
    propertyChanges,
    deletedPropertyNamePrefixes = [],
    propertyChangesForEagerValues,
  } = action.payload;

  const updatedModel  = state.editModels[entityId];
  if (!updatedModel) {
    throw new Error("Cannot find entity edit model for id " + entityId);
  }

  const entity = updatedModel.entity;

  propertyChanges?.forEach(change => {
    const originalValue = ExpressionEvaluationService.get(entity, change.propertyName!);
    const isOriginalValue: boolean = isEqualValue(change.propertyValue, originalValue);
    if (isOriginalValue) {
      // remove from changes
      updatedModel.propertyChanges = updatedModel.propertyChanges.filter(c => c.propertyName !== change.propertyName);
    } else {
      const existingChange = updatedModel.propertyChanges.find(p => p.propertyName === change.propertyName);
      if (existingChange) {
        // update
        existingChange.propertyValue = change.propertyValue;
        // in case of property deletion: remove pending nested updates (relevant for deletion of weak entities)
        if (change.propertyValue === null) {
          updatedModel.propertyChanges = updatedModel.propertyChanges.filter(p => !p.propertyName?.startsWith(change.propertyName + "."));
        }
      } else {
        updatedModel.propertyChanges.push({...change});
      }
    }
  });


  propertyChangesForEagerValues?.forEach(change => {
    const existingChange = updatedModel.propertyChangesForEagerValues.find(p => p.propertyName === change.propertyName);
    if (existingChange) {
      existingChange.propertyValue = change.propertyValue;
      if (change.propertyValue === null) {
        updatedModel.propertyChangesForEagerValues = updatedModel.propertyChangesForEagerValues.filter(p => !isParentChange(p, change));
      }
    } else {
      // clone here as value eager property values could be complex objects.
      // if we would not clone, properties of nested objects would be lost
      updatedModel.propertyChangesForEagerValues.push(cloneDeep(change));
    }
  });

  if (deletedPropertyNamePrefixes.length > 0) {
    deletedPropertyNamePrefixes.forEach(prefix => {
      updatedModel.propertyChanges = updatedModel.propertyChanges.filter(p => !p.propertyName?.startsWith(prefix) || p.propertyName === prefix);
    });
  }

  updatedModel.currentEntity = entityUpdateService.applyChanges(entity, [...updatedModel.propertyChanges, ...updatedModel.propertyChangesForEagerValues]);

  state.editModels[entityId] = updatedModel;
};
