/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, useMessages} from "@icm/core-common";
import {TablePagination} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {sortBy, sortedUniq} from "lodash-es";
import * as React from "react";

import {PaginationActionsComponent} from "./PaginationActionsComponent";
import styles from "./PaginationStyle";

export type PaginationProps = {
  listSize: number
  pageSize: number
  offset: number
  onChangePage: (offset: number, pageSize: number) => void
}

const useStyles = makeStyles(styles);

export const Pagination: React.VFC<PaginationProps> = ({listSize, pageSize, offset, onChangePage}) => {
  const classes = useStyles();
  const {getMessage} = useMessages();
  return (
    <div className={classes.root}>
      <TablePagination count={listSize}
                       page={offset / pageSize}
                       rowsPerPage={pageSize}
                       onPageChange={(event, page) => onChangePage(page * pageSize, pageSize)}
                       onRowsPerPageChange={(event) => onChangePage(0, parseInt(event.target.value, 10))}
                       rowsPerPageOptions={sortedUniq(sortBy([5, 10, 20, 50, pageSize]))}
                       labelRowsPerPage={getMessage(MessageKey.CORE.PAGINATION.ROWS_PER_PAGE)}
                       labelDisplayedRows={params => getMessage(MessageKey.CORE.PAGINATION.DISPLAYED_ROWS, {params})}
                       component="div"
                       ActionsComponent={PaginationActionsComponent}
      />
    </div>
  );
};
