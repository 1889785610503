/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FCView, FetchService, IViewProps} from "@icm/core-common";
import {useEffect, useState} from "react";
import ReactJson from "react-json-view";

import {SystemMetricsViewModel} from "./SystemMetricsViewDescriptor";

export const SystemMetricsView: FCView<{}, SystemMetricsViewModel> = (_props: IViewProps<SystemMetricsViewModel>) => {
  const [info, setInfo] = useState<any>({});

  useEffect(() => {
    FetchService.performGet("../actuator/info").then(result => {
      setInfo(result);
    });
  }, []);

  return (
    <div style={{padding: 10}}>
      <ReactJson src={info} style={{fontSize: 12}} />
    </div>
  );
};

export {SystemMetricsView as default};
