/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {Dispatch, PropsWithChildren, ReactNode, SetStateAction, useCallback, useContext, useEffect, useRef, useState} from "react";

export type AlertDialogProps = {
  title: string
  description?: string
  onClose?: () => void
}

export type ConfirmDialogProps = {
  title: string
  description?: string
  confirmLabel?: "OK" | "YES"
  cancelLabel?: "CANCEL" | "NO"
  onConfirm: () => void
  onCancel?: () => void
}

type TextBoxProps = {
  label: string,
  type: "date" | "email" | "number" | "password" | "tel" | "text" | "url" | "time" | "week" | "month";
}

export type PromptDialogProps = {
  title: string
  description?: string
  confirmLabel?: "OK" | "ADD" | string
  textBoxProps: TextBoxProps
  onConfirm: (value: string) => void
  onCancel?: () => void
}

export type ImageViewerDialogProps = {
  fileId: string
  onClose?: () => void
}


export type AlertDialogProvider = (alertProps: Omit<AlertDialogProps, "onClose">) => Promise<void>;
export type ConfirmDialogProvider = (confirmProps: Omit<ConfirmDialogProps, "onConfirm" | "onCancel">) => Promise<boolean>;
export type PromptDialogProvider = (promptProps: Omit<PromptDialogProps, "onConfirm" | "onCancel">) => Promise<string | undefined>;
export type ImageViewerDialogProvider  = (alertProps: Omit<ImageViewerDialogProps, "onClose">) => Promise<void>;

type DialogContextType = {

  setDialog: Dispatch<SetStateAction<ReactNode>>;

  cancel: () => void;

  /**
   * Returns a function that, when called, shows an alert dialog with the given properties.
   * The promise returned by that function resolves when the dialog is closed.
   */
  useAlertDialog: () => AlertDialogProvider;
  /**
   * Returns a function that, when called, shows a confirm dialog with the given properties.
   * The promise returned by that function resolves when the dialog is closed.
   * It resolves with `true` if the user clicks confirm and with `false` otherwise.
   */
  useConfirmDialog: () => ConfirmDialogProvider;
  /**
   * Returns a function that, when called, shows a prompt dialog with the given properties.
   * The promise returned by that function resolves when the dialog is closed.
   * It resolves with the string entered by the user, or with `undefined` if the user cancelled the dialog.
   */
  usePromptDialog: () => PromptDialogProvider;

  /**
   * Returns a function that, when called, shows a dialog containing an image including the means to download it.
   * The promise returned by that function resolves when the dialog is closed with undefined.
   */
  useImageDialog: () => ImageViewerDialogProvider;
};

const throwOutOfContextError = () => {
  throw new Error("Using DialogContext outside a DialogProvider");
};
export const DialogContext = React.createContext<DialogContextType>({
  setDialog: throwOutOfContextError,
  cancel: throwOutOfContextError,
  useAlertDialog: throwOutOfContextError,
  useConfirmDialog: throwOutOfContextError,
  usePromptDialog: throwOutOfContextError,
  useImageDialog: throwOutOfContextError,
});

type DialogProviderProps = {
  useAlertDialog: () =>  AlertDialogProvider;
  useConfirmDialog: () =>  ConfirmDialogProvider;
  usePromptDialog: () =>  PromptDialogProvider;
  useImageDialog: () => ImageViewerDialogProvider;
};

export const DialogProvider: React.FC<PropsWithChildren<DialogProviderProps>> = React.memo(({
  children, useAlertDialog, useConfirmDialog,
  usePromptDialog, useImageDialog,
}) => {
  const dialogRef = useRef<ReactNode>(null);
  const [dialog, setDialog] = useState<ReactNode | null>(null);
  useEffect(() => setDialog(dialogRef.current), []);

  const cancel = useCallback(() => setDialog(null), [setDialog]);

  return (
    <DialogContext.Provider value={{
      setDialog,
      cancel,
      useAlertDialog,
      useConfirmDialog,
      usePromptDialog,
      useImageDialog,
    }}
    >
      {children}
      {dialog}
    </DialogContext.Provider>
  );
});

export const useDialogContext = () => {
  return useContext(DialogContext);
};
