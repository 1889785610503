/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CssBaseline, Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";

import {styles} from "./LoginStepContainerUi.style";

const useStyles = makeStyles(styles);

type LoginStepContainerProps = {}

export const LoginStepContainer = (props: React.PropsWithChildren<LoginStepContainerProps>) => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.logoContainer}>
          <div className={classes.logo} />
        </div>
        <div className={classes.content}>
          {props.children}
        </div>
      </Paper>
    </main>
  );
};
