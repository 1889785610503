/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi} from "../../api";

export const DEFAULT_SERIAL_VERSION = -1;


export type IUiState = {

  /**
   * Version number used to check if a stored state is compatible with this client version.
   * This serialVersion is assigned the core-ui-configuration. Each time an
   * incompatible change occurred, it should be incremented to force a layout reset.
   * For example, if a component type was removed, the number must be increased
   * in the configuration.
   */
  serialVersion: number

  // CLIENT STATE
  notifications: Notification[]

  /**
   * Represents the perspective currently shown in the screen.
   */
  perspective?: Perspective

  // used to store key-value pairs that need to be exchanged across views or are used in the configuration
  metadata: Partial<Record<string, unknown>>

  /**
   * Flag which represents the current conntection status of the application.
   * If connection is lost, certain actions may become unavailable.
   */
  connected: boolean

}

export type Perspectives = {
  SINGLE_VIEW: SingleViewPerspective
  TABBED: TabbedPerspective
};
export type PerspectiveType = keyof Perspectives;

export type Perspective = SingleViewPerspective | TabbedPerspective;

type PerspectiveBase = {
  serialVersion: string
  id: string
  label: string
  globalActions: GlobalActions
}

export type SingleViewPerspective = PerspectiveBase & {
  perspectiveType: "SINGLE_VIEW"
  view: SingleView
  history: SingleView[]

  // in future
  // sidebar?
  // history stack?
}
export type SingleView = {
  viewModel: ViewModel<BaseViewModelData>
}
/**
 * A perspective has a unique identifier and a descriptive label which could be shown in the UI.
 * The perspective defines the building blocks of the ICM UI:
 *
 * - actionbar (consisting of action items)
 * - sidebar
 * - main area (consisting of tabbed panes)
 * - bottom panel
 */
export type TabbedPerspective = PerspectiveBase & {
  perspectiveType: "TABBED",
  sizes?: number[],

  /**
   * Id of the tab group that is in "focus". When opening a
   * new tab, this group will serve as a parent.
   */
  currentTabGroupId: string
  sideBarId?: string
  tabGroups: TabGroup[]
}

export type BaseViewModelData = {
  printRequested: boolean
};

export type ViewModel<ViewModelData extends BaseViewModelData> = {
  // unique across application, does not change
  id: string
  // id used in the configuration, does not change
  viewId: string
  // label used in the configuration, does not change, may not be given
  viewLabel?: string

  // id, uniquely identifying the viewModel across all browser tabs
  uniqueHash: string
  viewType: string
  viewModelData: ViewModelData
  editModelId?: string
}

/**
 * Logged notifications are announced by a snackbar
 */
export type Notification = {
  // unique key identifying the notification
  key: string
  // notifications with the same groupTitle will be grouped
  groupTitle?: string
  title: string
  message?: string
  // true if the notification has been announced to the user (e.g. by a Snackbar or a sound)
  announced: boolean
  read: boolean
  // finalized notifications don't allow actions anymore
  // finalized: boolean // not a property of the notification, rather the action
  timestamp: Date

  actions?: CoreApi.Action[]
  // context used to resolve the action's parameters
  actionContext?: any

  // optional action to be executed when clicking the "read" icon
  followUpAction?: FollowUpAction,

  // condition checked before the notification is added
  omitCondition?: (uiState: IUiState) => boolean,
}

export type FollowUpAction = {
  tooltip: string
  icon: string
  run: () => void
}

export type SideBarType = "NAVIGATION_SIDEBAR" | "";

export interface SideBar {
  sideBarType: SideBarType
}

export type NavigationSideBar = {
  label?: string,
  actions: ActionBarItem[]
} & SideBar


/**
 * The action bar hosts a collection of items that are connected to actions.
 * A action in the action bar will usually open a tab in the main area or
 * show the sidebar.
 */
export type GlobalActions = {
  startItems: ActionBarItem[]
  endItems: ActionBarItem[]
}

export type ActionBarItem = {
  available: boolean
} & (ActionBarDivider | ActionBarActionItem);

/**
 * A divider within the action bar is used to separate items visually.
 */
export type ActionBarDivider = {
  itemType: "DIVIDER"
}

/**
 * An ActionBarActionItem is connected to an action and therefore
 * with an action handler.
 */
export type ActionBarActionItem = {
  itemType: "ACTION"
  connectionRequired: boolean
} & CoreApi.Action;

/**
 * Definition of a tabbed pane. A tabbed pane groups a collection of tabs.
 * Usually one of the tabs is selected (defined through its 'index').
 */
export type TabGroup = {
  // identifier of the tab group
  id: string,
  // width of the Allotment.Pane when the user dragged the component
  // if not assigned, the panes should distribute equally
  size?: number,
  selectedTabIndex: number,
  tabs: TabGroupTab[],
}

/**
 * Definition of a tab as it is persisted.
 */
export type TabGroupTab = {
  /** String to identify a tab. This id should be unique per tab group. */
  id: string

  viewModel: ViewModel<BaseViewModelData>
}

export const isTabGroupTab = (obj?: any): obj is TabGroupTab => {
  return obj
    && typeof obj.id === "string"
    && obj.viewModel !== null
    && typeof obj.viewModel === "object";
};


/** Refers to all entity edit models.*/
export const ANY_EDIT_MODEL = "*";

export type EditModelDescriptor = {
  /** Refers to the id of edit model in another slice of the redux store. */
  editModelId?: string | typeof ANY_EDIT_MODEL;
}
