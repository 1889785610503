/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useDispatch} from "react-redux";

import {useRemotePerspective} from "../configuration";
import {useDefaultPerspective} from "../configuration/useDefaultPerspective";
import {useLocalPerspective} from "../configuration/useLocalPerspective";
import {
  initPerspective, Perspective, Perspectives, PerspectiveType,
  SingleViewPerspective,
  TabbedPerspective,
} from "../store";


/**
 * @return A SingleViewPerspective (for mobile client), false if there is no perspective available, undefined if
 * it's still loading
 */
export const useSingleViewPerspective = (): SingleViewPerspective | undefined | false => {
  return usePerspective("SINGLE_VIEW");
};


/**
 * @return A TabbedPerspective (for web client), false if there is no perspective, undefined if it's still loading
 */
export const useTabbedPerspective = (): TabbedPerspective | undefined | false => {
  return usePerspective("TABBED");
};

/**
 * The function first queries the server for the perspective configuration. On a second call it stores the data
 * in redux. On the third call it returns the stored perspective from redux.
 *
 * @param perspectiveType
 * @return A perspective of the given type based on the ui configuration on the server.
 *         As long as the perspective is being fetched, "undefined" is returned.
 *         If the fetched configuration could not be mapped or an error occurs, the function returns false.
 */
const usePerspective = <T extends PerspectiveType>(perspectiveType: T): Perspectives[T] | undefined | false => {
  const dispatch = useDispatch();

  const defaultPerspective = useDefaultPerspective(perspectiveType);
  const localPerspective = useLocalPerspective();
  const remotePerspective = useRemotePerspective();

  // Wait for the default perspective to load in order to compare serialVersions.
  if (!defaultPerspective || remotePerspective === "LOADING") {
    return undefined;
  }

  if (isInitialized(localPerspective)) {
    return localPerspective as Perspectives[T];
  }

  // Queue the dispatch call that saves the perspective in order to avoid an infinite loop.
  // Comparable to SwingUtilities.invokeLater(...) or EventQueue.asyncExec(...).
  setTimeout(() => {
    if (remotePerspective === "NOT_FOUND") {
      dispatch(initPerspective({perspective: defaultPerspective}));
    } else if (isCompatible(remotePerspective, defaultPerspective)) {
      dispatch(initPerspective({perspective: remotePerspective}));
    } else {
      console.log("Stored perspective is not compatible. Using default");
      dispatch(initPerspective({perspective: defaultPerspective}));
    }
  }, 0);


  return undefined;
};

function isInitialized(localPerspective?: Perspective) {
  return localPerspective !== undefined;
}

/**
 * A perspective is compatible with another perspective if:
 * - the types are the same
 * - the serial versions match
 *
 * @param p1
 * @param p2
 */
function isCompatible(p1: Perspective, p2: Perspective) {
  return p1.perspectiveType === p2.perspectiveType && p1.serialVersion === p2.serialVersion;
}
