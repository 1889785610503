/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createAction, createAsyncAction} from "typesafe-actions";

import {Structure} from "../../generated/api";
import {SketchActionTypes} from "./types";

export const fetchSketches = createAsyncAction(
  SketchActionTypes.FETCH_LIST_REQUEST,
  SketchActionTypes.FETCH_LIST_SUCCESS,
  SketchActionTypes.FETCH_LIST_ERROR
)<void, Structure[], Error>();

export const resetNotifications = createAction(SketchActionTypes.NOTIFY_RESET)();
