/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  feedbackService,
  LoginRequest,
  LoginStatus,
  MessageKey,
  messages,
  NavigationService,
  PasswordState,
  SecurityService,
  useNavigationService,
  useService,
} from "@icm/core-common";
import {Button, FormControl, Input, InputLabel, Typography} from "@mui/material";
import {withStyles, WithStyles} from "@mui/styles";
import * as React from "react";
import {useLocation} from "react-router-dom";

import {LoginStepContainer} from "../../components";
import styles from "./PasswordChangePageStyle";

interface IPasswordChangeState {
  currentPassword: string,
  newPassword: string,
  newPasswordRepeat: string,
}

type LocationState = {
  passwordState: PasswordState,
  userName: string,
  originalRequest: LoginRequest,
  originalResponse: LoginStatus,
}

function isPassordStateChange(state: unknown): state is LocationState {
  return state !== null
    && typeof state === "object"
    && state["userName"]
    && typeof state["originalRequest"] === "object"
    && state["passwordState"]
    && state["originalResponse"];
}

type StyleProps = WithStyles<typeof styles>;
type ServiceProps = {
  securityService?: SecurityService
  navigationService: NavigationService
};

type AllProps = StyleProps & ServiceProps;

class PasswordChangePage extends React.Component<AllProps, IPasswordChangeState> {

  constructor(props: Readonly<AllProps>) {
    super(props);
    this.state = {currentPassword: "", newPassword: "", newPasswordRepeat: ""};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.handlePasswordChangeFailure = this.handlePasswordChangeFailure.bind(this);
    this.getLocationState = this.getLocationState.bind(this);

    this.props.securityService?.addFailedLoginListener(this.handleLoginFailure);
    this.props.securityService?.addFailedPasswordChangeListener(this.handlePasswordChangeFailure);
  }

  public componentWillUnmount() {
    this.props.securityService?.removeFailedLoginListener(this.handleLoginFailure);
    this.props.securityService?.removeFailedPasswordChangeListener(this.handlePasswordChangeFailure);
  }

  private getLocationState() {
    const state = this.props.navigationService.getLocationState();
    if (isPassordStateChange(state)) {
      return state;
    }
    return undefined;
  }

  public render() {
    const classes = this.props.classes!;
    const passwordState = this.getLocationState()?.passwordState;
    console.log("Rendering for password state", passwordState);
    const isEmptyPassword = passwordState === "EMPTY";
    const isExpiredPassword = passwordState === "EXPIRED";
    const isCurrentPasswordRequired = !isEmptyPassword;
    const isSkipAllowed = !isEmptyPassword && !isExpiredPassword;
    const messageCode = isEmptyPassword ? "core.passwordChange.empty" : isExpiredPassword ? "core.passwordChange.expired" : "core.passwordChange.aboutToExpire";
    return (
      <LoginStepContainer>
        <Typography variant="body1" className={classes.formDescription}>
          {messages.get(messageCode)}
        </Typography>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          {isCurrentPasswordRequired
            ? (
              <FormControl variant="standard" margin="normal" required={true} fullWidth={true}>
                <InputLabel htmlFor="currentPassword">{messages.get(MessageKey.CORE.PASSWORD_CHANGE.CURRENT_PASSWORD)}</InputLabel>
                <Input name="currentPassword"
                       type="password"
                       id="currentPassword"
                       autoComplete="current-password"
                       value={this.state.currentPassword}
                       onChange={e => this.setState({currentPassword: e.target.value})}
                />
              </FormControl>
            )
            : null}
          <FormControl variant="standard" margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="newPassword">{messages.get(MessageKey.CORE.PASSWORD_CHANGE.NEW_PASSWORD)}</InputLabel>
            <Input name="newPassword"
                   type="password"
                   id="newPassword"
                   value={this.state.newPassword}
                   onChange={e => this.setState({newPassword: e.target.value})}
            />
          </FormControl>
          <FormControl variant="standard" margin="normal" required={true} fullWidth={true}>
            <InputLabel htmlFor="newPasswordRepeat">{messages.get(MessageKey.CORE.PASSWORD_CHANGE.REPEAT_PASSWORD)}</InputLabel>
            <Input name="newPasswordRepeat"
                   type="password"
                   id="newPasswordRepeat"
                   value={this.state.newPasswordRepeat}
                   onChange={e => this.setState({newPasswordRepeat: e.target.value})}
            />
          </FormControl>
          <Button type="submit" fullWidth={true} variant="contained" color="primary" className={classes.submit}>
            {messages.get(MessageKey.CORE.APPLY)}
          </Button>
          {isSkipAllowed
            ? (
              <Button type="button"
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={this.handleSkip}
              >
                {messages.get(MessageKey.CORE.SKIP)}
              </Button>
            )
            : null}
        </form>
      </LoginStepContainer>
    );
  }

  private handleSubmit(e: React.FormEvent): void {
    if (this.state.newPassword === this.state.newPasswordRepeat) {
      const locationState = this.getLocationState();
      if (this.state.newPassword !== this.state.currentPassword && locationState) {
        this.props.securityService?.changePassword(locationState.userName, this.state.newPassword, this.state.currentPassword);
      } else {
        PasswordChangePage.displayError(messages.get(MessageKey.CORE.LOGIN.ERROR.INVALID_NEW_PASSWORD));
      }
    } else {
      PasswordChangePage.displayError(messages.get(MessageKey.CORE.LOGIN.ERROR.INVALID_REPEAT_PASSWORD));
    }
    e.preventDefault();
  }

  private static displayError(errorMessage?: string): void {
    const fallbackErrorText: string = messages.get(MessageKey.CORE.ERROR.GENERAL) || "ERROR";
    feedbackService.open({
      key: "PASSWORD_CHANGE_ERROR",
      title: errorMessage || fallbackErrorText,
      variant: "ERROR",
      duration: "SHORT",
    });
  }

  private handleSkip(): void {
    const locationState = this.getLocationState();
    if (locationState) {
      this.props.securityService?.handleSkippedPasswordChange(locationState.originalRequest, locationState.originalResponse);
    }
  }

  private handleLoginFailure(errorMessage: string): void {
    this.setState({currentPassword: ""});
    PasswordChangePage.displayError(errorMessage);
  }

  private handlePasswordChangeFailure(currentPasswordInvalid: boolean, newPasswordInvalid: boolean): void {
    this.setState({currentPassword: ""});
    PasswordChangePage.displayError(PasswordChangePage.getPasswordChangeErrorMessage(currentPasswordInvalid, newPasswordInvalid));
  }

  private static getPasswordChangeErrorMessage(currentPasswordInvalid: boolean, newPasswordInvalid: boolean): string {
    const fallbackErrorText: string = messages.get(MessageKey.CORE.ERROR.GENERAL) || "ERROR";
    let errorText: string | undefined;
    if (currentPasswordInvalid) {
      errorText = messages.get(MessageKey.CORE.LOGIN.ERROR.BAD_CREDENTIALS);
    } else if (newPasswordInvalid) {
      errorText = messages.get(MessageKey.CORE.LOGIN.ERROR.INVALID_NEW_PASSWORD);
    }
    return errorText || fallbackErrorText;
  }
}


function PasswordChangePageWithServices(props: StyleProps) {
  const securityService = useService("SECURITY");
  const location = useLocation();
  const navigationService = useNavigationService(location.pathname);
  // set the location in the navigation service here even if it is also set in within an useEffect hook useNavigationService
  // otherwise the location would not be set when it is required upon rendering this page
  // the analysis why the location is set within an useEffectHook in useNavigationService is scope of PICM-2491
  navigationService.setLocation(location);
  return (
    <PasswordChangePage {...props}
                        navigationService={navigationService}
                        securityService={securityService}
    />
  );
}

const page = withStyles(styles)(PasswordChangePageWithServices);
export {page as PasswordChangePage};
