/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {GenericFormField} from "@icm/core-common";
import {ActionAdornments, ValueWithIndex} from "@icm/core-web";
import {Grid, Typography} from "@mui/material";
import * as React from "react";


import useStyles from "./EntityHistoryViewStyle";
import {SimpleFieldValue} from "./SimpleFieldValue";


export type SimpleFieldProps = {
  field: GenericFormField
  eventIndexValues: ValueWithIndex[]
  fieldIndex: number
  mapViewId?: string
}

export const SimpleField = (props: SimpleFieldProps) => {
  const classes = useStyles();
  const {field, fieldIndex, eventIndexValues, mapViewId} = props;

  return (
    <Grid container={true}
          key={`${fieldIndex}.${field.label}`}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.field}
    >
      <Typography variant="body2" className={classes.propertyName}>
        {field.label}:
      </Typography>
      <Grid container={true} direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.propertyValue}>
        {eventIndexValues.map((eventIndexLabelAndValue, fieldValueIndex: number) => {
          const isCurrentValue = eventIndexValues.length - 1 === fieldValueIndex;
          return (
            <SimpleFieldValue key={fieldValueIndex}
                              eventIndexLabelAndValue={eventIndexLabelAndValue}
                              field={field}
                              isCurrentValue={isCurrentValue}
            />
          );
        })}
      </Grid>
      <Typography className={classes.fieldAdornments}>
        <ActionAdornments mapViewId={mapViewId} eventIndexValues={eventIndexValues} field={field} />
      </Typography>
    </Grid>
  );
};
