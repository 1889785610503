/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ICoreApplicationState, ListPortion} from "@icm/core-common";
import produce from "immer";
import {useMemo} from "react";
import {useSelector} from "react-redux";

import {ObjectUpdateInformation} from "../store";

type WithId<T> = T & {
  id?: string
};

export type WithUpdateInformation<T> = WithId<T> & {
  objectUpdateInformation?: ObjectUpdateInformation
}

export type ObjectDeserializer<T> = (obj: any) => T;

export type ViewElementFactory = (obj: any) => any;

export type UpdatedObjectsMap = Partial<Record<string, ObjectUpdateInformation>>;

/**
 * @param factory A factory to create an object that includes viewAttributes
 * @param entityType entity type
 * @param deserializer (optional) object parser that deserializes the object contained in the object update
 */
export const useObjectUpdateInformation = <T>(factory: ViewElementFactory, entityType?: string, deserializer?: ObjectDeserializer<T>): UpdatedObjectsMap => {
  const result = useSelector((
    {activityStreamState}: ICoreApplicationState
  ) => (entityType ? activityStreamState.objectUpdatesByType[entityType] : undefined) ?? {});
  return useMemo(() => {
    return deserializer ? produce(result, (draft) => {
      Object.values(draft).forEach(v => {
        v!.objectUpdates = v!.objectUpdates?.map(ou => (
          {
            ...ou,
            object: factory(deserializer(ou.object) ?? ou.object),
          }));
      });
    }) : result;
  }, [deserializer, result, factory]);
};

/**
 * @return list portion where each entity was extended by update information
 */
export const useListPortionWithEntityUpdateInformation = <T, >(
  viewElementFactory: ViewElementFactory,
  entityType?: string,
  listPortion?: ListPortion<WithId<T>>,
): ListPortion<WithUpdateInformation<T>> | undefined => {
  const objectUpdateInformation = useObjectUpdateInformation(viewElementFactory, entityType);
  return useMemo(() => {
    if (listPortion) {
      return {
        ...listPortion,
        sublist: listPortion.sublist.map(object => ({
          ...object,
          objectUpdateInformation: object.id ? objectUpdateInformation[object.id] : undefined,
        })),
      };
    }
    return undefined;
  }, [objectUpdateInformation, listPortion]);
};
