/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ActionHandler, ActionBarItem, GlobalActions, useConnectionState} from "@icm/core-common";
import {Box, Divider, List, ListItem, ListItemIcon, Paper} from "@mui/material";
import * as React from "react";

import {IcmMuiIcon, ConditionalTooltip} from "../../components";
import {withColorOverride} from "../../theme";
import styles from "./ActionBarStyle";


type ActionBarViewerProps = {
  actionBar: GlobalActions
  dispatchAction: ActionHandler
}

const BareActionBarViewer = (props: ActionBarViewerProps) => {
  const {actionBar, dispatchAction} = props;

  return (
    <Paper elevation={1} square sx={styles.root} className="dontPrint">
      <List sx={styles.list}>
        <Box>
          {actionBar.startItems.map((item, idx) => (
            <ActionBarItemComponent key={`top_${idx}`} item={item} dispatchAction={dispatchAction} />
          ))}
        </Box>
        <Box>
          {actionBar.endItems.map((item, idx) => (
            <ActionBarItemComponent key={`bottom_${idx}`} item={item} dispatchAction={dispatchAction} />
          ))}
        </Box>
      </List>
    </Paper>
  );
};
type ActionBarItemComponentProps = {
  item: ActionBarItem
  dispatchAction: ActionHandler
}
const ActionBarItemComponent = (props: ActionBarItemComponentProps) => {
  const {item, dispatchAction} = props;
  const connected = useConnectionState();

  if (item.itemType === "DIVIDER") {
    return <Divider />;
  }

  const disabled: boolean = item.connectionRequired && !connected;
  return (
    <ConditionalTooltip title={item.label} placement="right">
      <ListItem button={true}
                sx={styles.listItem}
                onClick={disabled ? () => {} : () => dispatchAction(item)}
                disabled={disabled}
      >
        <ListItemIcon>
          <IcmMuiIcon name={item.icon} />
        </ListItemIcon>
      </ListItem>
    </ConditionalTooltip>
  );
};

export const ActionBarViewer = React.memo(withColorOverride(BareActionBarViewer, "NavigationMenu"));
