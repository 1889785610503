/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {useMemo} from "react";

import {GenericFormConfiguration, GenericFormGroup} from "../../generated/api";
import {FormRuleEvaluator} from "./FormGeneratorContextProvider";


/**
 * Hook to calculate all form groups that are visible through their expression and have visible widgets.
 *
 * @param formRuleEvaluator A function to evaluate a visibility expression
 * @param formConfiguration The form configuration whose groups should be assessed
 */
export const useGenericFormGroups = (formRuleEvaluator: FormRuleEvaluator, formConfiguration: GenericFormConfiguration): GenericFormGroup[] => {
  return useMemo(() => {
    const result: GenericFormGroup[]  = [];
    formConfiguration.groups?.forEach((formGroup) => {
      const isGroupVisible = formRuleEvaluator(formGroup.visible, true);
      if (isGroupVisible && hasVisibleFields(formRuleEvaluator, formGroup)) {
        result.push(formGroup);
      }
    });
    return result;
  }, [formRuleEvaluator, formConfiguration.groups]);
};

/**
 * Check if there is at least one visible field.
 *
 * @param formRuleEvaluator
 * @param formGroup
 */
function hasVisibleFields(formRuleEvaluator: FormRuleEvaluator, formGroup: GenericFormGroup) {
  if (formGroup.widgets) {
    return formGroup.widgets
      .map(w => formRuleEvaluator(w.visible, true))
      .reduce((v1, v2) => v1 || v2, false);
  }
  return false;
}
