/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

interface IUserState {
  userName: string
}

export class UserStateService {

  public static INSTANCE = new UserStateService();

  public static getInstance() {
    return UserStateService.INSTANCE;
  }

  private readonly userState = {
    userName: "user-" + Math.ceil(Math.random() * 100000),
  };

  public getUserState(): IUserState {
    return this.userState;
  }

}
