/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  MimeTypeMapping,
  DEFAULT_ACCEPTED_TYPES,
  DEFAULT_MAX_FILE_SIZE,
  extensions,
  FileReference,
  FilesComponentRef,
  MessageKey,
  useFeedbackBanners,
  useFileUploader,
  useMessages,
} from "@icm/core-common";
import {Box, LinearProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {DropzoneArea} from "material-ui-dropzone/dist";
import {File} from "mdi-material-ui";
import * as React from "react";
import {forwardRef, Ref, useCallback, useImperativeHandle, useMemo} from "react";
import {DropEvent, DropzoneOptions, DropzoneState, FileError, FileRejection, useDropzone} from "react-dropzone";

import {convertBytesToMbsOrKbs} from "./FilesComponentHelper";
import styles from "./FilesComponentStyle";


export type FilesComponentProps = {
  /**
   * Refer to https://react-dropzone.js.org/#section-accepting-specific-file-types.
   */
  acceptedFileTypes?: MimeTypeMapping,
  children: React.ReactNode
  maxFiles?: number,
  currentFileCount?: number,
  maxFileSize?: number,
  onUpload: (fileReference: FileReference) => Promise<void>,
  readOnly?: boolean,
  /** If the file upload is permanent or should create a temporary file. */
  permanent?: boolean
  /**
   * When false the upload progress won't be presented
   * */
  displayProgress?: boolean
};

const useStyles = makeStyles(styles);

const UNLIMITED = Infinity;

export const FilesComponent = forwardRef((props: FilesComponentProps, ref: Ref<FilesComponentRef>) => {
  const {
    acceptedFileTypes = DEFAULT_ACCEPTED_TYPES,
    children,
    maxFiles = UNLIMITED,
    currentFileCount = 0,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    onUpload,
    readOnly,
    displayProgress = true,
    permanent,
  } = props;
  const {
    openFeedbackBanner,
  } = useFeedbackBanners();
  const {getMessage} = useMessages();
  const classes = useStyles();

  const allowedFileCount = useMemo(() => maxFiles === UNLIMITED ? UNLIMITED : maxFiles - currentFileCount, [maxFiles, currentFileCount]);

  const {uploadFile, uploadInProgress, uploadFinished, currentUploadProgress} = useFileUploader(permanent, onUpload);

  const displayFileLimitExceeded = useCallback(() => {
    const message = getMessage(MessageKey.CORE.LIST.UPLOAD.FILE.LIMIT_EXCEEDED, {
      params: {
        limit: maxFiles,
      },
    });
    openFeedbackBanner({
      key: "FILE_COMPONENT_FILE_LIMIT_EXCEEDED_ERROR",
      title: message,
      variant: "ERROR",
      duration: "SHORT",
    });
    return message;
  }, [maxFiles, openFeedbackBanner, getMessage]);


  const onDrop = useCallback((acceptedFiles: File[]) => {
    /**
     * Since Dropzone is always allowing a single file upload, despite file limits being set (maxFiles, filesLimit),
     * the component has to check if the files limit is exceeded.
     **/
    if (maxFiles !== UNLIMITED && allowedFileCount <= 0) {
      displayFileLimitExceeded();
      return;
    }
    acceptedFiles.forEach(uploadFile);
  }, [maxFiles, allowedFileCount, uploadFile, displayFileLimitExceeded]);

  const dropzoneOptions = {
    maxFiles: allowedFileCount,
    maxSize: maxFileSize,
    accept: acceptedFileTypes,
    onDropAccepted: onDrop,
    noClick: true,
  };

  const acceptedFileExtensions = extensions(dropzoneOptions.accept, false);

  const dropzoneProps: DropzoneOptions = {
    ...dropzoneOptions,
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => handleInputRejection(fileRejections),
  };

  const dropzoneState: DropzoneState = useDropzone(dropzoneProps);

  const handleInputRejection = (fileRejections: FileRejection[]) => {
    const errors: FileError[] = fileRejections.flatMap(item => item.errors);
    const fileLimitExceeded = errors.some(item => item.code === "too-many-files");
    if (fileLimitExceeded) {
      displayFileLimitExceeded();
    }

    const fileNotSupported = errors.some(item => ["file-invalid-type", "file-too-large"].includes(item.code));
    if (fileNotSupported) {
      fileRejections.map(item => item.file)
        .forEach(file => {
          displayFileValidationFeedback(file, acceptedFileExtensions ?? [], maxFileSize);
        });
    }
  };

  const displayFileValidationFeedback = (rejectedFile: File, acceptedTypes: string[], maxFileSizeLimit: number): string => {
    let message = getMessage(MessageKey.CORE.LIST.UPLOAD.FILE.REJECTED, {params: {fileName: rejectedFile.name}}) + " ";

    const fileSizeExceeded = rejectedFile.size > maxFileSizeLimit;
    if (fileSizeExceeded) {
      message += getMessage(MessageKey.CORE.LIST.UPLOAD.FILE.SIZE_EXCEEDED, {params: {sizeLimit: convertBytesToMbsOrKbs(maxFileSizeLimit)}}) + " ";
    }

    const fileExt = "." + rejectedFile.name.split(".")
      .pop();

    const fileTypeValidationOnly = !fileSizeExceeded;
    const fileTypeValidationEnabled = acceptedTypes.length > 0;
    const fileExtensionSupported = fileExt && fileTypeValidationEnabled && acceptedTypes.includes(fileExt);
    const fileTypeSupported = fileTypeValidationEnabled && acceptedTypes.includes(rejectedFile.type);
    const acceptableType = !fileTypeValidationEnabled || fileExtensionSupported || fileTypeSupported;
    console.log(`Filetype: ${rejectedFile.type} supported: ${fileTypeSupported} ,ext: ${fileExt} supported: ${fileExtensionSupported}`);

    if (fileTypeValidationOnly || !acceptableType) {
      message += getMessage(MessageKey.CORE.LIST.UPLOAD.FILE.TYPE_NOT_SUPPORTED);
    }

    openFeedbackBanner({
      key: rejectedFile.name,
      title: message,
      variant: "ERROR",
      duration: "SHORT",
    });
    return message;
  };

  useImperativeHandle(ref, () => ({
    showFilePicker,
  }));

  const showFilePicker = () => {
    dropzoneState.open();
  };

  const renderDropzoneArea = (display = true) => {
    return (
      <Box
        className={classes.dropzoneAreaWrapper}
        sx={{
          display: display ? "initial" : "none",
        }}
      >
        <DropzoneArea
          acceptedFiles={acceptedFileExtensions}
          dropzoneText={getMessage(MessageKey.CORE.DROP_FILE_OR_CLICK)}
          maxFileSize={dropzoneOptions.maxSize}
          filesLimit={dropzoneOptions.maxFiles}
          dropzoneProps={dropzoneOptions.accept}
          showPreviewsInDropzone={false}
          showAlerts={false}
          disableRejectionFeedback={true}
          getDropRejectMessage={displayFileValidationFeedback}
          getFileLimitExceedMessage={displayFileLimitExceeded}
        />
      </Box>
    );
  };

  const shouldRenderDropzoneArea = !readOnly && dropzoneState.isDragActive;
  const dropzoneContainerProps = !readOnly ? {...dropzoneState.getRootProps({noclick: "true"})} : {};
  return (
    <Box sx={{minHeight: shouldRenderDropzoneArea ? 300 : "auto"}} {...dropzoneContainerProps}>
      {displayProgress && uploadInProgress && (
        <Box display="flex" alignItems="center" marginBottom={8}>
          <LinearProgress
            className={classes.progressIndicator}
            color={uploadFinished ? "success" : "primary"}
            variant={uploadFinished ? "determinate" : "indeterminate"}
            value={currentUploadProgress.percent}
          />
          <span className={classes.progressText}>
            {currentUploadProgress.title}
          </span>
        </Box>
      )}
      {!readOnly && <input {...dropzoneState.getInputProps()} style={{display: "none"}} />}
      {shouldRenderDropzoneArea && renderDropzoneArea(dropzoneState.isDragActive)}
      <div style={{visibility: shouldRenderDropzoneArea ? "hidden" : "visible"}}>
        {children}
      </div>
    </Box>
  );
});
