/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {PossibleValue} from "../../../generated/api";
import {AbstractFormComponent, IFormComponentProps} from "../AbstractFormComponent";
import {ReadOnlyComponent} from "../readonly/ReadOnlyComponent";

export interface TextBoxComponentProps extends IFormComponentProps<string> {
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  type: string,
  validate?: (value?: string, format?: string) => boolean,
  multiline?: boolean,
  formats?: PossibleValue[],
  formatDisplay?: (value: string, format?: string, as?: "LABEL" | "INPUT" | "FULL") => string | null,
  handleChange: (newValue?: string, format?: string) => void,
  adornments?: JSX.Element[];
}

export interface TextBoxComponentState {
  value: any,
  displayValue: string,
  error: boolean,
  format?: string,
}

export abstract class BaseTextBoxComponent<P extends TextBoxComponentProps> extends AbstractFormComponent<string, P, TextBoxComponentState> {

  protected constructor(props: Readonly<P>) {
    super(props);
    const format = props.formats && props.formats.length > 0 ? props.formats[0].value : undefined;
    const displayValue = this.getDisplayValue(props.value, format);
    this.state = {
      value: props.value,
      displayValue: displayValue,
      error: props.error || this.isError(displayValue, format),
      format: format,
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
  }

  componentDidMount() {
    if (this.props.value === null || this.props.value === undefined) {
      this.props.handleChange(""); // make sure that first appearance of component triggers change to default value
    }
  }

  componentDidUpdate(prevProps: Readonly<TextBoxComponentProps & P>) {
    if (this.props.value !== prevProps.value || this.props.error !== prevProps.error) {
      const displayValue = this.getDisplayValue(this.props.value, this.state.format);
      this.setState({
        value: this.props.value,
        displayValue: displayValue,
        error: this.props.error || this.isError(displayValue, this.state.format),
      });
    }
  }

  protected renderReadOnlyComponent(): React.ReactNode {
    return (
      <ReadOnlyComponent label={this.props.label}
                         text={this.renderReadOnlyText()}
                         badgeColor={this.props.badgeColor}
                         adornments={this.props.adornments}
      />
    );
  }

  protected renderReadOnlyText(): string | undefined {
    return this.props.formatDisplay?.(this.props.value || "", undefined, "FULL") ?? this.props.value;
  }

  protected handleTextChange(text: string) {
    const format = this.state.format;
    const displayValue = text || "";
    this.setState({
      displayValue: displayValue,
      error: this.props.error || this.isError(displayValue, format), // could be debounced
    });
  }

  private isError(text?: string, format?: string) {
    return this.props.validate?.(text || "", format) === false;
  }

  protected handleBlur() {
    const format = this.state.format;
    // reset value to passed one. parent might already have updated props, when the state is set!
    this.setState((state, props: Readonly<TextBoxComponentProps>) => {
      const previousDisplayValue = this.getDisplayValue(props.value, format);

      const currentDisplayValue = state.displayValue;
      const changed = previousDisplayValue !== currentDisplayValue;

      // notify parent if changed
      if (changed) {
        // switching formats might reduce the (visible) precision.
        // as long as the user does not change anything this should not trigger an update
        this.props.handleChange(currentDisplayValue, format);
      }

      return ({
        error: props.error || this.isError(currentDisplayValue, format),
      });
    });
  }

  protected handleFormatChange(format?: string) {
    this.setState((state, props: Readonly<TextBoxComponentProps>) => {
      const changedDisplayValue = this.getDisplayValue(props.value, format);
      return ({
        value: props.value,
        displayValue: changedDisplayValue,
        format: format,
        error: props.error || this.isError(changedDisplayValue, format),
      });
    });
  }

  private getDisplayValue(value?: string, format?: string): string {
    if (this.props.formatDisplay && format && value) {
      return this.props.formatDisplay(value, format, "INPUT") || "";
    }
    return value || "";
  }
}
