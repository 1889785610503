/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import MessageKey from "../generated/MessageKey";
import {feedbackService} from "./feedback";
import {FetchService} from "./FetchService";
import {FetchServiceException} from "./FetchServiceException";
import {messages} from "./MessageService";

class UserDownloadService {

  private static readonly INSTANCE = new UserDownloadService();

  public static getInstance() {
    return UserDownloadService.INSTANCE;
  }

  private downloadInProgress: boolean = false;

  public startDownload(url: string, fileName?: string, requestBody?: any): void {
    this.startDownloadWith(abortController => {
      if (!!requestBody) {
        return FetchService.performPostWithDownload(url,  requestBody, fileName, {abortController});
      } else {
        return FetchService.performDownload(url,  fileName, {abortController});
      }
    });
  }

  public startDownloadWith(downloadFunction: (abortController: AbortController) => Promise<void>): void {
    if (this.downloadInProgress) {
      feedbackService.open({
        key: "DOWNLOAD_ONLY_ONE_ALLOWED",
        title: messages.get(MessageKey.CORE.DOWNLOAD.ONLY_ONE_ALLOWED)!,
        variant: "WARNING",
        duration: "SHORT",
      });
      return;
    }

    this.downloadInProgress = true;

    const abortController = new AbortController();
    downloadFunction(abortController)
      .then(() => {
        this.downloadInProgress = false;
        feedbackService.close("DOWNLOAD");
      },
      (ex: FetchServiceException) => {
        this.downloadInProgress = false;
        feedbackService.close("DOWNLOAD");

        if (ex.aborted) {
          feedbackService.open({
            key: "DOWNLOAD_ABORTED",
            title: messages.get(MessageKey.CORE.DOWNLOAD.ABORTED)!,
            variant: "WARNING",
            duration: "SHORT",
          });
        } else {
          feedbackService.open({
            key: "DOWNLOAD_FAILED",
            title: messages.get(MessageKey.CORE.DOWNLOAD.FAILED)!,
            variant: "ERROR",
            duration: "SHORT",
          });
        }
      });

    feedbackService.open({
      key: "DOWNLOAD",
      title: messages.get(MessageKey.CORE.DOWNLOAD.IN_PROGRESS)!,
      variant: "INFO",
      duration: "INDEFINITE",
      closeActions: [{
        label: messages.get(MessageKey.CORE.CANCEL)!,
        action: () => abortController.abort(),
      }],
    });
  }

}

const service = UserDownloadService.getInstance();

export {service as userDownloadService};
