/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SecurityService} from "../service";
import {BaseViewModelData, GetMessage, ViewModel} from "../store";
import {IcmIconType, ResolvedParameterList} from "../util";
import {View} from "./ViewRegistry";

/**
 * Function that returns a unique identifier for the view. The returned value should be unique across browser tabs and stable. Thus, given two models having
 * the same properties, the identifier should be identical. This ensures that a view can be identified and or updated having only the model or its id at hand.
 * Note that this identifier is the suffix of the generated model hash. The final model IDs look like this:
 *
 *    ENTITY_MULTI_EDITOR-e9772966-f760-4084-a6d3-c98ef4223164
 *    ENTITY_LIST-DEMO
 *    ENTITY_LIST-AIRSPACE
 */
export type CreateUniqueHash<ViewModelData extends BaseViewModelData> = (viewModelData: ViewModelData) => string;

/**
 * Get the id for the edit model that is related to this view.
 * If the return value is undefined, the edit model is not yet "bound" to the view.
 */
export type GetEditModelId<ViewModelData extends BaseViewModelData> = (viewModelData: ViewModelData) => string | undefined;

/**
 * Returns the title of the view, based on the view parameters and the view model.
 */
export type GetTitle<ViewModelData extends BaseViewModelData> = (viewModel: ViewModel<ViewModelData>, getMessage: GetMessage) => string;

export type ActionDescriptorType = "primary" | "secondary" | "ternary" | "default";

export type ActionDescriptor = {
  title: string
  icon: IcmIconType | JSX.Element
  // @default true
  enabled?: boolean
  // @default true
  visible?: boolean
  // @default default
  type?: ActionDescriptorType
  componentFactory?: (key: string, action: ActionDescriptor, executeViewAction: <T>(actionId: string, actionParameter: T) => void,
    disabled: boolean, asMenuItem: boolean, mobile: boolean) => JSX.Element
}

export type ActionDescriptorEntry = {
  actionType: string
  action: ActionDescriptor
};

export enum CreateActionType {
  CREATE_ENTITY = "CREATE_ENTITY",
}

/**
 * Typeguard to check if the given action is considered an action to create things.
 *
 * @param val
 */
export const isCreateActionType = (val: any): val is CreateActionType => {
  return val !== null
    && typeof val === "string"
    && val.startsWith("CREATE")
    && val !== "CREATE_ROOM";
};


/**
 * Returns a map of actions that are available in the view.
 *
 * Note that the title, icon and visible state can only be modified from here (depending on the ViewModel).
 * This is restricted to ensure stability in the UI (no icon/text change, no appearing/disappearing icons).
 *
 * Use the setViewActionHandlers function provided by the {@link IViewProps} or by {@link useSetViewActionHandlers}
 * to set the action runner and the enabled state of the actions.
 */
export type GetViewActionDescriptors<ViewModelData extends BaseViewModelData> = (
  viewModel: ViewModel<ViewModelData>,
  getMessage: GetMessage,
  securityService: SecurityService,
) => Record<string, ActionDescriptor>;


/**
 * Map the given record to a list of action descriptor entries.
 * @param descriptorMap
 */
export function asActionDescriptorEntries(descriptorMap: Record<string, ActionDescriptor>): ActionDescriptorEntry[] {
  return Object.entries(descriptorMap)
    .map(v => {
      return {
        actionType: v[0],
        action: v[1],
      };
    });
}


/**
 * The result of this function will be used to create a sharable link.
 *
 * The entries of the returned key value map are connected like so: ?key1=value1&key2=value2
 *
 * Note that the returned keys need to be processed in the {@link InitializeViewModelData} function to make the sharing work.
 *
 * @return return `undefined` if the view is not sharable
 */
export type GetShareParameters<ViewModelData extends BaseViewModelData> = (viewModel: ViewModel<ViewModelData>) => SharableParameters;

export type IsPrintable<ViewModelData extends BaseViewModelData> = (viewModel: ViewModel<ViewModelData>) => boolean;

export type SharableParameters = Record<string, string | undefined> | undefined;

export type GetCloseOptions<ViewModelData extends BaseViewModelData> = (viewModelData: ViewModelData, getMessage: GetMessage) => CloseViewOptions;

export const DO_NOTHING = "DO_NOTHING";
export const CONFIRM_YES_NO = "CONFIRM_YES_NO";
export const CONFIRM_OK_CANCEL = "CONFIRM_OK_CANCEL";

export type CloseViewOptions = typeof DO_NOTHING | CommonConfirmationDialogOptions;

export type CommonConfirmationDialogOptions = {
  optionsType: typeof CONFIRM_YES_NO | typeof CONFIRM_OK_CANCEL
  title: string
  description: string
}

export const isCommonConfirmation = (val?: any): val is CommonConfirmationDialogOptions => {
  return val?.optionsType === CONFIRM_YES_NO || val?.optionsType === CONFIRM_OK_CANCEL;
};


/**
 * Creates the initial view model data object based on the viewParameters given to open the view.
 * @throw an {@link IcmError} if the given view parameters are insufficient to create the view
 */
export type InitializeViewModelData<ViewModelData extends BaseViewModelData> = (viewParameters: ResolvedParameterList) => ViewModelData

/**
 * Updates an existing view model data object based on the viewParameters given to open the view.
 *
 * NOTE: The viewModelData is immutable, and therefore the caller of this function has to use e.g. immer produce to not manipulate it.
 * @throw an {@link IcmError} if the given view parameters are invalid
 */
export type UpdateViewModelData<ViewModelData extends BaseViewModelData> = (
  viewModelData: ViewModelData,
  viewParameters: ResolvedParameterList,
) => void

export type ViewDescriptor<ViewModelData extends BaseViewModelData> = {
  /**
   * unique and stable name for the component (will be referred to from the ui configuration)
   */
  viewType: string
  view: View<ViewModelData>
  /**
   * @see InitializeViewModelData
   */
  initializeViewModelData: InitializeViewModelData<ViewModelData>
  /**
   * @see UpdateViewModelData
   */
  updateViewModelData?: UpdateViewModelData<ViewModelData>

  /**
   * Creates an identifier that is used to find the view model when opening a view.
   * This method should return a stable string based on the view model data provided.
   *
   *
   * Implement, if your view is a multi instance view (i.e. when it can be opened
   * multiple times in different contexts). In this case, the context should be used to
   * create the unique hash. For example, in an entity view, the entity id would make
   * a good unique identifier.
   *
   * @see CreateUniqueHash
   * @default empty string (only one instance per {@link viewType})
   */
  createUniqueHash?: CreateUniqueHash<ViewModelData>

  /**
   * Return an object that describes the edit model related to the view referred to by this view descriptor.
   * Only implement this if your view has an edit model.
   *
   * @see GetEditModelId
   */
  getEditModelId?: GetEditModelId<ViewModelData>

  /**
   * @see GetTitle
   */
  getTitle: GetTitle<ViewModelData>

  /**
   * @see GetViewActionDescriptors
   */
  getViewActionDescriptors?: GetViewActionDescriptors<ViewModelData>

  /**
   * @see GetShareParameters
   */
  getShareParameters?: GetShareParameters<ViewModelData>;

  /**
   * @see IsPrintable
   */
  isPrintable?: IsPrintable<ViewModelData>;

  /**
   * @see GetCloseOptions
   */
  getCloseOptions?: GetCloseOptions<ViewModelData>;
}
