/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import axios from "axios";

import {HttpHeaderName, Urls} from "../constant";
import {makeCancelable, TimeZoneProvider} from "../util";
import {FetchServiceException} from "./FetchServiceException";
import {LogReportService} from "./LogReportService";

export type ProgressInfo = {
  loadedBytes: number,
  totalBytes: number | undefined,
  percentage: number | undefined,
}

export type FetchOptions = {
  /**
   * Supply HTTP error codes that are expected and handled by invoker of fetch.
   */
  catchableErrorCodes?: number[]

  /**
   * By supplying an AbortController, you can abort requests. Be sure to catch ensuing FetchServiceExceptions (aborted === true).
   */
  abortController?: AbortController

  /**
   * If true, the url will stay unchanged.
   */
  useStrictUrl?: boolean

  /**
   * A RequestCredentials dictionary value indicating whether the user agent should send cookies from the other domain in the case of cross-origin requests.
   * default "include".
   */
  requestCredentials?: RequestCredentials

  /**
   * Override headers in request.
   */
  headers?: Record<string, string>

  /**
   * Additional headers, to add to default ones.
   */
  additionalHeaders?: Record<string, string>
}

type OpenBlobHandler = (blob: Blob, fileName?: string) => void;

type DefaultHeadersProvider = () => Promise<Record<string, string>>;

class FetchService {
  private static readonly INSTANCE = new FetchService();

  private defaultHeadersProvider?: DefaultHeadersProvider;

  private handleMissingAuthorization?: (url: string) => void;

  private handleUncaughtError?: (reason: any) => void;

  private openBlobHandler?: OpenBlobHandler;

  public static getInstance() {
    return FetchService.INSTANCE;
  }

  constructor() {
    this.parseJson = this.parseJson.bind(this);
  }

  public setDefaultsHeaderProvider(defaultHeadersProvider: DefaultHeadersProvider) {
    console.log("Initializing FetchService");
    this.defaultHeadersProvider = defaultHeadersProvider;
  }

  public onMissingAuthorization(handleMissingAuthorization: (url: string) => void) {
    this.handleMissingAuthorization = handleMissingAuthorization;
  }

  public onUncaughtError(handleUncaughtError: (reason: any) => void) {
    this.handleUncaughtError = handleUncaughtError;
  }

  // propably this should not be used anymore - currently only used from the map?
  public async performUpload(requestUrl: string, file: Blob, options?: FetchOptions): Promise<Response> {
    const data = new FormData();
    data.append("file", file);
    return this.fetchInternal(requestUrl, {
      body: data,
      headers: await this.getHeaders(options),
      method: "POST",
    }, options);
  }

  public async performUploadWithProgress<A>(requestUrl: string, file: Blob, progressHandler: (progress: ProgressInfo) => void, permanent = false): Promise<A> {
    const data = new FormData();
    data.append("file", file);
    const url = Urls.finalizeRelativeApiUrl(requestUrl);
    LogReportService.log("request", "Fetch", "POST", url, data);
    const params = {permanent: permanent ? "true" : "false"};
    return axios.post(url, data, {
      headers: await this.getHeaders(),
      params,
      onUploadProgress: progressEvent => {
        progressHandler({
          loadedBytes: progressEvent.loaded,
          totalBytes: progressEvent.total,
          percentage: progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : undefined,
        });
      },
    }).then(r => r.data);
  }

  public async performDownload(requestUrl: string, localFileName?: string, options?: FetchOptions): Promise<void> {
    return this.fetchInternal(requestUrl, {
      headers: await this.getHeaders(options),
    }, options)
      .then(this.getBlobAndFilename)
      .then(({
        blob,
        fileName,
      }) => this.openBlob(blob, localFileName || fileName));
  }


  public async performGet<A>(requestUrl: string, options?: FetchOptions): Promise<A> {
    return this.fetchInternal(requestUrl, {headers: await this.getHeaders(options)}, options)
      .then(this.parseJson)
      .then(json => {
        FetchService.log(json, requestUrl);
        return json;
      });
  }

  public async performGetBinary(requestUrl: string, options?: FetchOptions): Promise<{ blob: Blob, fileName?: string }> {
    return this.fetchInternal(requestUrl, {headers: await this.getHeaders(options)}, options)
      .then(this.getBlobAndFilename);
  }

  public async performGetText(requestUrl: string, options?: FetchOptions): Promise<string> {
    return this.fetchInternal(requestUrl, {headers: await this.getHeaders(options)}, options)
      .then(FetchService.parseText);
  }

  public async performDelete(requestUrl: string, options?: FetchOptions) {
    return this.fetchInternal(requestUrl, {
      headers: await this.getHeaders(options),
      method: "DELETE",
    }, options);
  }

  public performPostWithDownload(requestUrl: string, object: any, localFileName?: string, options?: FetchOptions): Promise<void> {
    return this.performPost(requestUrl, object, options)
      .then(this.getBlobAndFilename)
      .then(({
        blob,
        fileName,
      }) => this.openBlob(blob, localFileName || fileName));
  }

  // returns promise with url for the image blob
  public async performPostWithImageResult(requestUrl: string, object: any, options?: FetchOptions): Promise<string> {
    return this.performPost(requestUrl, object, options)
      .then(this.getBlobAndFilename)
      .then(({blob}) => FetchService.getImage(blob));
  }

  public async performPostWithJsonResult<A>(requestUrl: string, object: any, options?: FetchOptions): Promise<A> {
    return this.performPost(requestUrl, object, options)
      .then(this.parseJson)
      .then(json => {
        FetchService.log(json, requestUrl);
        return json;
      });
  }

  public async performPutWithJsonResult<A>(requestUrl: string, object: any, options?: FetchOptions): Promise<A> {
    return this.performPut(requestUrl, object, options)
      .then(this.parseJson)
      .then(json => {
        FetchService.log(json, requestUrl);
        return json;
      });
  }

  public async performPost(requestUrl: string, object: any, options?: FetchOptions): Promise<Response> {
    return this.fetchInternal(requestUrl, {
      body: JSON.stringify(object),
      headers: await this.getHeaders(options, "application/json"),
      method: "POST",
    }, options);
  }

  public async performPut(requestUrl: string, object: any, options?: FetchOptions): Promise<Response> {
    return this.fetchInternal(requestUrl, {
      body: JSON.stringify(object),
      headers: await this.getHeaders(options, "application/json"),
      method: "PUT",
    }, options);
  }

  private async getDefaultHeaders(contentType?: string): Promise<Record<string, string>> {
    if (this.defaultHeadersProvider) {
      return this.defaultHeadersProvider().then(securityHeaders => FetchService.getSecurityHeadersWithFetchHeaders(securityHeaders, contentType));
    } else {
      return FetchService.getSecurityHeadersWithFetchHeaders({}, contentType);
    }
  }

  private async getHeaders(options?: FetchOptions, contentType?: string): Promise<Record<string, string>> {
    if (options?.headers) {
      return options.headers;
    } else {
      const defaultHeaders: Record<string, string> = await this.getDefaultHeaders(contentType);
      const additionalHeaders: Record<string, string> = options?.additionalHeaders || {};
      return {...defaultHeaders, ...additionalHeaders};
    }
  }

  private static getSecurityHeadersWithFetchHeaders(securityHeaders: Record<string, string>, contentType?: string): Record<string, string> {
    const headers = {...securityHeaders};
    headers[HttpHeaderName.USER_TIME_ZONE] = TimeZoneProvider.getTimeZone();
    if (contentType) {
      headers["Content-Type"] = contentType;
    }
    return headers;
  }

  public setOpenBlobHandler(handler: OpenBlobHandler): void {
    this.openBlobHandler = handler;
  }

  private checkStatus(response: Response, url: string, _parameters: RequestInit, catchableErrorCodes: number[] = []): Response {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    if (catchableErrorCodes.includes(response.status)) {
      throw new FetchServiceException(`HTTP response code was ${response.status} for URL ${url}`, false, response);
    }
    if (response.status === 401 || response.status === 403) {
      console.debug("Response status for URL", url, "was", response.status, "Triggering missing authorization handling");
      this.handleMissingAuthorization?.(url);
    }

    throw new FetchServiceException(`HTTP response code was ${response.status} for URL ${url}`, false, response);
  }

  private openBlob(blob: Blob, fileName?: string): void {
    this.openBlobHandler?.(blob, fileName);
  }

  private getBlobAndFilename(response: Response): Promise<{ blob: Blob, fileName?: string }> {
    const header = response.headers.get("Content-Disposition");
    const regexResult = header && header.match(/filename="(.+)"/);
    let fileName: string;
    if (regexResult && regexResult[1]) {
      fileName = regexResult[1];
    }
    return response.blob()
      .then(blob => ({
        blob,
        fileName,
      }));
  }

  private static getImage(blob: Blob): string {
    // @ts-ignore
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  }

  private parseJson(response: Response): Promise<any> {
    return response.json()
      .catch(ex => {
        if (ex.name === "AbortError") {
          throw new FetchServiceException("Request was aborted", true, response, ex);
        } else {
          this.handleUncaughtError?.(ex);
          throw new FetchServiceException("Request failed", false, response, ex);
        }
      });
  }

  private static parseText(response: Response): Promise<string> {
    return response.text();
  }

  private static log(response: Response, url = "?"): Response {
    console.debug("Loaded data from", url, response);
    return response;
  }

  private static logUncaughtErrors(error: object, requestUrl: string, parameters: RequestInit, status?: number): void {
    console.warn("Uncaught HTTP error", status, error, requestUrl, parameters);
  }

  private fetchInternal(requestUrl: string, parameters: RequestInit, options?: FetchOptions): Promise<Response> {
    const url = options?.useStrictUrl === true ? requestUrl : Urls.finalizeRelativeApiUrl(requestUrl);
    LogReportService.log("request", "Fetch", parameters.method ?? "GET", url, parameters);
    return makeCancelable(fetch(url, {
      ...parameters,
      signal: options?.abortController?.signal,
      credentials: options?.requestCredentials || "include",
    })
      .then(obj => this.checkStatus(obj, url, parameters, options?.catchableErrorCodes))
      .catch((ex: FetchServiceException | Error) => {
        let fetchServiceException: FetchServiceException;
        if (ex instanceof FetchServiceException) {
          fetchServiceException = ex;
        } else if (ex.name === "AbortError") {
          fetchServiceException = new FetchServiceException("Request was aborted", true, undefined, ex);
        } else {
          fetchServiceException = new FetchServiceException("Request failed", false, undefined, ex);
        }
        if (!(fetchServiceException.aborted
          || (fetchServiceException.response
            && [
              401, 403, ...(options?.catchableErrorCodes ?? []),
            ].includes(fetchServiceException.response.status)) // 401/403 are always handled within checkStatus
        )) {
          FetchService.logUncaughtErrors(ex, url, parameters, fetchServiceException.response?.status);
        }
        throw fetchServiceException;
      }), options?.abortController);
  }
}

const fetchService = FetchService.getInstance();

export {fetchService as FetchService};
