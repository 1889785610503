/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ApiUtil, FetchService, FetchServiceException} from "@icm/core-common";

import {Person, RecurringStandby, Standby, StandbyTeam} from "../generated/api";

export class StandbyApi {
  public static getAllStandbys(): Promise<Standby[]> {
    return FetchService.performGet("standby/standbys")
      .then(json => ApiUtil.convertArray(json, Standby.fromData));
  }

  public static getAllStandbysFromTeam(teamId: string): Promise<Standby[]> {
    return FetchService.performGet(`standby/standbys?teamId=${teamId}`)
      .then(json => ApiUtil.convertArray(json, Standby.fromData));
  }

  public static postStandby(standby: Standby): Promise<string> {
    return FetchService.performPost("standby/standbys", standby)
      .then(response => response.text())
      .catch(async (ex: FetchServiceException) => {
        throw await ex.response?.json();
      });
  }

  public static deleteStandby(standbyId: string): Promise<boolean> {
    return FetchService.performDelete(`standby/standbys/${standbyId}`)
      .then(response => Boolean(response));
  }

  public static getAllRecurringStandbys(): Promise<RecurringStandby[]> {
    return FetchService.performGet("standby/recurringStandbys")
      .then(json => ApiUtil.convertArray(json, RecurringStandby.fromData));
  }

  public static getAllRecurringStandbysFromTeam(teamId: string, filterAfter: Date, filterBefore: Date): Promise<RecurringStandby[]> {
    console.log(`Fetching recurring standbys for team ${teamId} from ${filterAfter} to ${filterBefore}`);
    return FetchService.performGet(`standby/recurringStandbys?teamId=${teamId}&from=${filterAfter.toISOString()}&to=${filterBefore.toISOString()}`)
      .then(json => ApiUtil.convertArray(json, RecurringStandby.fromData));
  }

  public static postRecurringStandby(standby: RecurringStandby): Promise<string> {
    return FetchService.performPost("standby/recurringStandbys", standby, {catchableErrorCodes: [422]})
      .then(response => response.text())
      .catch(async (ex: FetchServiceException) => {
        throw await ex.response?.json();
      });
  }

  public static deleteRecurringStandby(standbyId: string): Promise<boolean> {
    return FetchService.performDelete(`standby/recurringStandbys/${standbyId}`)
      .then(response => Boolean(response));
  }

  public static getAllStandbyTeams(): Promise<StandbyTeam[]> {
    return FetchService.performGet("standby/standbyTeams")
      .then(json => ApiUtil.convertArray(json, StandbyTeam.fromData));
  }

  public static getAllMembersFromStandbyTeam(teamId: string): Promise<Person[]> {
    return FetchService.performGet(`standby/standbyTeamMembers?teamId=${teamId}`)
      .then(json => ApiUtil.convertArray(json, Person.fromData));
  }


  public static downloadStandbyReport(teamId: string): Promise<void> {
    return FetchService.performDownload(`standby/standbys/pdf?teamId=${teamId}`);
  }

  public static downloadStandbyReportGrouped(teamId: string): Promise<void> {
    return FetchService.performDownload(`standby/standbys/pdf?teamId=${teamId}&grouped=true`);
  }

  public static downloadStandbyReportPerPerson(personId: string): Promise<void> {
    return FetchService.performDownload(`standby/standbys/pdf?personId=${personId}`);
  }
}
