/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {makeStyles} from "@mui/styles";
// @ts-ignore
import PropertiesPanelModule from "bpmn-js-properties-panel";
// @ts-ignore
// import PropertiesProviderModule from "bpmn-js-properties-panel/lib/provider/camunda";
// @ts-ignore
import BpmnModeler from "bpmn-js/lib/Modeler";
// @ts-ignore
import CamundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda.json";
import * as React from "react";
import {ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";

import styles from "./BpmnProcessEditorStyle";
import PropertiesProviderModule from "./properties";

import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css";

type BpmnProcessEditorProps = {
  bpmn: string
}

export type BpmnProcessEditorHandle = {
  processCurrentBpmn: (handler: (bpmn: string, svg: string) => void) => void
  importBpmn: (bpmnDefinition: string) => void
}

const useStyles = makeStyles(styles);

const BpmnProcessEditor = React.forwardRef(({bpmn}: BpmnProcessEditorProps, ref: ForwardedRef<BpmnProcessEditorHandle>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const propertiesPanelRef = useRef<HTMLDivElement>(null);
  const [bpmnEditor, setBpmnEditor] = useState<BpmnModeler>();

  useEffect(() => {
    const modeler = new BpmnModeler({
      container: containerRef.current,
      propertiesPanel: {
        parent: propertiesPanelRef.current,
      },
      additionalModules: [
        PropertiesPanelModule,
        PropertiesProviderModule,
      ],
      // needed if you'd like to maintain camunda:XXX properties in the properties panel
      moddleExtensions: {
        camunda: CamundaModdleDescriptor,
      },
    });
    modeler.on("import.done", (event: any) => {
      const {error, warnings} = event;
      if (error) {
        return handleError(error);
      } else {
        return handleShown(warnings);
      }
    });
    setBpmnEditor(modeler);
    return () => modeler.destroy();
  }, []);

  const importBpmn = useCallback((bpmnDefinition: string) => {
    if (bpmnEditor && bpmnDefinition && bpmnDefinition.length > 0) {
      console.log("Importing BPMN", bpmnDefinition);
      bpmnEditor.importXML(bpmnDefinition).catch((error: any) => {
        if (error) {
          handleError(error);
        }
      });
    }
  }, [bpmnEditor]);

  useEffect(() => {
    if (bpmnEditor && bpmn && bpmn.length > 0) {
      importBpmn(bpmn);
    }
  }, [bpmn, bpmnEditor, importBpmn]);

  const processCurrentBpmn = useCallback((handler: (bpmn: string, svg: string) => void) => {
    if (bpmnEditor) {
      Promise.all([bpmnEditor.saveXML({format: true}), bpmnEditor.saveSVG({format: true})]).then(values => {
        const bpmnResult: any = values[0];
        const cleanXml = bpmnResult.xml.replace(/assignee_tmp=".*"/ig, "");
        const svg: any = values[1];
        handler(cleanXml, svg.svg);
      }).catch((error: any) => {
        console.log("A problem occurred", error);
      });
    }
  }, [bpmnEditor]);

  useImperativeHandle(ref, () => ({
    processCurrentBpmn,
    importBpmn,
  }), [importBpmn, processCurrentBpmn]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.editor} ref={containerRef} />
      <div className={classes.properties} ref={propertiesPanelRef} />
    </div>
  );
});

function handleError(error: any) {
  console.log("Error occurred", error);
}

function handleShown(warnings: any) {
  console.log("BPMN is shown", warnings);
}

export default BpmnProcessEditor;
