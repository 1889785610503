/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {useEffect, useState} from "react";

interface IProps {
  /**
   * the date
   */
  date: Date
  /**
   * date format function
   */
  format: (date: Date) => string
  /**
   * update interval in ms
   * @default 60000 (every minute)
   */
  interval?: number
}

const DEFAULT_INTERVAL = 60000;

export const AutoRefreshingDateTime: React.FC<IProps> = (props: IProps) => {
  const [update, forceUpdate] = useState(true);
  useEffect(() => {
    const intervalId = setInterval(() => forceUpdate(!update), props.interval ?? DEFAULT_INTERVAL);
    return () => clearInterval(intervalId);
  }, [props.interval, update]);

  return (
    <>
      {props.format(props.date)}
    </>
  );
};
