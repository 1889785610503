/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FCView, FilterParameter, MessageKey, ObjectLinkingParameter, ParameterUtilities, ViewDescriptor} from "@icm/core-common";
import {isString} from "lodash-es";
import * as React from "react";

import {ActivityStreamComponent} from "./ActivityStreamComponent";

export type ActivityStreamViewModel = {
  manualEntryCreation: boolean
  entityId?: string
  entityType?: string
  filterParameter?: FilterParameter
  objectLinking?: ObjectLinkingParameter,
  printRequested: boolean,
}

export const ActivityStreamView: FCView<{}, ActivityStreamViewModel> = ({viewModel}) => {
  const {manualEntryCreation, entityId, entityType, filterParameter, objectLinking} = viewModel.viewModelData;
  return (
    <ActivityStreamComponent manualEntryCreation={manualEntryCreation}
                             entityId={entityId}
                             entityType={entityType}
                             filterParameter={filterParameter}
                             objectLinking={objectLinking}
    />
  );
};

export const activityStreamViewDescriptor: ViewDescriptor<ActivityStreamViewModel> = {
  viewType: "ACTIVITY_STREAM_VIEW",
  view: ActivityStreamView,
  initializeViewModelData: viewParameters => {
    const entityId = ParameterUtilities.getResolvedParameterValue("entityId", viewParameters, isString);
    const entityType = ParameterUtilities.getResolvedParameterValue("entityType", viewParameters, isString);
    return {
      manualEntryCreation: viewParameters.activityStream?.manualEntryCreation ?? false,
      entityId,
      entityType,
      filterParameter: viewParameters.filter,
      objectLinking: viewParameters.objectLinking,
      printRequested: false,
    };
  },
  getTitle: (_viewModel, getMessage) => getMessage(MessageKey.ACTIVITYSTREAM.TITLE),
  getShareParameters: viewModel => {
    const {entityId, entityType} = viewModel.viewModelData;
    return {entityId, entityType};
  },
};
