/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createCancelable} from "@icm/core-common";
import {useQuery} from "react-query";

import {entityDataService} from "../service";

/**
 *
 * @param entityType the entity type
 * @param variant (optional)
 */
export const useEntityListConfiguration = (entityType: string, variant?: string) => {
  const queryResult = useQuery(["private", "core", "entities", entityType, "listConfiguration", variant],
    () => createCancelable(ac => entityDataService.getListConfiguration(entityType, variant, ac)),
    {
      staleTime: Infinity,
    });
  return queryResult.data;
};
