/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {debounce, uniqWith} from "lodash-es";
import {useCallback, useEffect, useMemo, useState} from "react";

import {Dao, useDao} from "../../../dao";
import {AutocompleteFormChangeReason, AutoCompleteFormComponentProps} from "./AutoCompleteComponent";
import {AutocompleteOption, HandleAutocompleteChangeInput} from "./AutoCompleteTypes";
import {AutoCompleteComponentInternalProps} from "./ReadOnlyAutoCompleteComponent";
import {ValueWithVersion} from "./SelectComponent";


export function useValues<T>(
  selectedValues: Array<unknown | ValueWithVersion>,
  withVersion: boolean,
  isBoundByValue: boolean,
  options: AutocompleteOption[],
  dao: Dao<T>
): [AutocompleteOption[], AutocompleteOption[], boolean] {
  return useMemo(() => {
    let versionUpdated = false;
    const valuesNotInOptions: AutocompleteOption[] = [];
    const values = selectedValues.map(value => {
      let result: AutocompleteOption | undefined;
      if (withVersion) {
        const versionedValue = (value as ValueWithVersion);
        result = options.find(opt => dao.valueEqual(opt.value, versionedValue.value));
        if (result) {
          versionUpdated = versionUpdated || result.version !== versionedValue.version;
        }
      } else {
        result = options.find(o => dao.valueEqual(o.value, value));
      }
      // if value wasn't found in options, create value from input and add to list of valuesNotInOptions
      if (result === undefined) {
        if (withVersion) {
          const versionedValue = (value as ValueWithVersion);
          result = {
            ...versionedValue,
            originalObject: null,
          };
        } else if (isBoundByValue) {
          result = valueToAutocompleteValue(value, dao);
        } else {
          result = {
            value,
            valueDisplay: "",
            originalObject: null,
          };
        }
        valuesNotInOptions.push(result);
      }
      return result;
    });
    return [values, valuesNotInOptions, versionUpdated];
  }, [selectedValues, withVersion, options, dao, isBoundByValue]);
}

const valueToAutocompleteValue = <T extends unknown>(value: T, {getResolved}: Dao<T>): AutocompleteOption => getResolved(value);

export function useValuesNotInOptions<T>(
  valuesNotInOptions: AutocompleteOption[], loading: boolean, isBoundByValue: boolean, dao: Dao<T>
): [AutocompleteOption[], boolean] {
  const [loadedValues, setLoadedValues] = useState<AutocompleteOption[]>([]);
  const [triedLoading, setTriedLoading] = useState<AutocompleteOption[]>([]);
  const [loadingValues, setLoadingValues] = useState(valuesNotInOptions.length !== 0);
  useEffect(() => {
    if (!loading) {
      if (valuesNotInOptions.length !== 0) {
        const missingValues = valuesNotInOptions.filter(v => !triedLoading.find(lv => dao.valueEqual(v.value, lv.value)));
        if (missingValues.length !== 0) {
          if (isBoundByValue) {
            // add selected (but missing in options) values to options
            setLoadedValues(existing => [...missingValues, ...existing]);
            setTriedLoading(existing => [...missingValues, ...existing]);
            setLoadingValues(false);
          } else {
            // load entities that are selected, but not in options
            setLoadingValues(true);
            setTriedLoading(existing => [...missingValues, ...existing]);
            dao.loadPossibleValuesByValues(missingValues.map(v => v.value)
              .filter(v => !!v))
              .then(possibleValues => {
                if (possibleValues.length !== 0) {
                  setLoadedValues(existing => [...possibleValues.map(v => valueToAutocompleteValue(v, dao)), ...existing]);
                }
                setLoadingValues(false);
              });
          }
        } else {
          setLoadingValues(false);
        }
      } else {
        setLoadingValues(false);
      }
    }
  }, [dao, isBoundByValue, loading, loadingValues, triedLoading, valuesNotInOptions]);
  return [loadedValues, loadingValues];
}

const loadPossibleValues = (dao: Dao<unknown>, inputValue: string, abortController: AbortController,
                            onLoad: () => void, onFulfilled: (values: any) => void, onRejected: (reason: any) => void) => {
  onLoad();
  dao.loadPossibleValues(inputValue, abortController)
    .then(onFulfilled)
    .catch(onRejected);
};

const loadPossibleValuesDebounced = debounce(loadPossibleValues, 300, {});

type AutoCompleteOptionHelper = [AutocompleteOption[], boolean, (value: string) => void, string, () => boolean];


const useOptions = (dao: Dao<unknown>, debounceLoadPossibleValues: boolean): AutoCompleteOptionHelper => {
  const [possibleValues, setPossibleValues] = useState<unknown[]>([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [ignoreNextInputValue, setIgnoreNextInputValue] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const onLoad = () => setLoading(true);
    const handleResult = (values: unknown[]) => {
      if (!abortController.signal.aborted) {
        if (JSON.stringify(possibleValues) !== JSON.stringify(values)) {
          if (possibleValues.length > 0) {
            setIgnoreNextInputValue(true); // as autocomplete will set input to "" after change of options
          }
          setPossibleValues([...values]);
        }
        setLoading(false);
      }
    };
    const handleReject = (e: any) => {
      if (!e.aborted) {
        console.error("Couldn't load available values", e);
        setLoading(false);
      }
    };

    if (debounceLoadPossibleValues) {
      loadPossibleValuesDebounced(dao, inputValue, abortController, onLoad, handleResult, handleReject);
    } else {
      loadPossibleValues(dao, inputValue, abortController, onLoad, handleResult, handleReject);
    }
    return () => abortController.abort();
  }, [dao, inputValue, possibleValues, debounceLoadPossibleValues]);

  const options: AutocompleteOption[] = useMemo(
    () => possibleValues.map(value => valueToAutocompleteValue(value, dao)),
    [dao, possibleValues]
  );

  const isIgnoringNextInputValue = useCallback((): boolean => {
    const result = ignoreNextInputValue;
    setIgnoreNextInputValue(false);
    return result;
  }, [ignoreNextInputValue]);

  return [options, loading, setInputValue, inputValue, isIgnoringNextInputValue];
};

const autocompleteValueToValue = (value: AutocompleteOption, withVersion: boolean): unknown | ValueWithVersion => {
  if (withVersion) {
    return {
      value: value.value,
      version: value.version,
      valueDisplay: value.valueDisplay,
    } as ValueWithVersion;
  } else {
    return value.value;
  }
};

const autocompleteValueToOriginalObject = (value: AutocompleteOption): unknown | ValueWithVersion => {
  return value.originalObject;
};

export const useAutoComplete = <T extends unknown>(props: AutoCompleteFormComponentProps<T> & AutoCompleteComponentInternalProps<T>) => {
  const {
    daoOptions,
    debounceLoadPossibleValues,
    withVersion,
    isBoundByValue,
    keepInitialValuesInOptions,
    multiple,
    onChange,
    loading,
  } = props;
  const dao = useDao(daoOptions);
  const [options, optionsLoading, setInputValue, inputValue, isIgnoringNextInputValue] = useOptions(dao, !!debounceLoadPossibleValues);
  const [values, valuesNotInOptions, updateVersions] = useValues(props.value || [], withVersion, isBoundByValue, options, dao);
  let [optionsFromValues, optionsFromValuesLoading] = useValuesNotInOptions(valuesNotInOptions, optionsLoading, isBoundByValue, dao);
  if (keepInitialValuesInOptions === false) {
    optionsFromValues = [];
    optionsFromValuesLoading = false;
  }

  const allOptionsLoading = optionsFromValuesLoading || optionsLoading;

  const allOptions = useMemo(() => {
    return uniqWith([...optionsFromValues, ...options], (a, b) => dao.valueEqual(a.value, b.value));
  }, [dao, options, optionsFromValues]);

  const handleChange = useCallback((
    optionOrOptions: HandleAutocompleteChangeInput,
    reason?: AutocompleteFormChangeReason | null
  ) => {
    if (!optionOrOptions || (Array.isArray(optionOrOptions) && optionOrOptions.length === 0)) {
      onChange(multiple ? [] : undefined, multiple ? [] : undefined, reason);
    } else if (Array.isArray(optionOrOptions)) {
      const opts = optionOrOptions as AutocompleteOption[];
      onChange(opts.map(v => autocompleteValueToValue(v, withVersion)), opts.map(v => autocompleteValueToOriginalObject(v)), reason);
    } else {
      const opt = optionOrOptions as AutocompleteOption;
      onChange([autocompleteValueToValue(opt, withVersion)], [autocompleteValueToOriginalObject(opt)], reason);
    }
  }, [multiple, onChange, withVersion]);

  const survivingValues = useMemo(() => {
    if (!allOptionsLoading) {
      return values.map(v => allOptions.find(opt => dao.valueEqual(opt.value, v.value))!)
        .filter(v => !!v);
    }
    return values;
  }, [allOptions, dao, allOptionsLoading, values]);

  const updateValues = !allOptionsLoading && survivingValues.length !== values.length;

  useEffect(() => {
    debounce(() => {
      if (updateVersions) {
        handleChange(values);
      } else if (updateValues) {
        handleChange(survivingValues);
      }
    });
  }, [allOptions, dao, handleChange, survivingValues, updateValues, values, updateVersions]);

  const isLoading = loading || allOptionsLoading || updateValues || updateVersions;

  return {
    allOptions,
    dao,
    handleChange,
    isIgnoringNextInputValue,
    isLoading,
    inputValue,
    setInputValue,
    values,
  };
};
