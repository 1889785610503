/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {FC} from "react";

import {ICON} from "../../../constant";
import {getNamedComponentFactory} from "../../../ui";
import {BadgeColor} from "../../badge";


type Props = {
  badgeColor?: BadgeColor
  style?: React.CSSProperties
};

export const FieldUpdatedMarker: FC<Props> = ({
  badgeColor,
  style,
}) => {
  const Icon = getNamedComponentFactory(ICON);
  return badgeColor && Icon ? (
    <div style={style}>
      <Icon name="edit"
            iconColor={badgeColor}
      />
    </div>
  ) : null;
};
