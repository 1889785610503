/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  BaseListComponent,
  Identifiable,
  LabelWithFieldStateMarker,
  MessageKey,
  messages,
  mimeTypeMapping,
  withConfirmDialog,
} from "@icm/core-common";
import {Add, AttachFile} from "@mui/icons-material";
import {Grid, Paper} from "@mui/material";
import * as React from "react";

import {ClassicDataTable} from "../../list";
import {ButtonComponent} from "../button/ButtonComponent";
import {FilesComponent} from "../files/FilesComponent";
import {FormDialogComponent} from "../FormDialogComponent";


class WebListComponent<T extends Identifiable> extends BaseListComponent<T> {

  protected renderEditableComponent(): React.ReactNode {
    return this.renderComponent(false);
  }

  protected renderReadOnlyComponent(): React.ReactNode {
    return this.renderComponent(true);
  }

  private renderComponent(readOnly: boolean) {
    const {label, listConfiguration} = this.props;
    const {fileAddable, itemAddable} = listConfiguration;

    const Component = (
      <Paper elevation={0}>
        {(label || !readOnly) && (
          <Grid container={true} direction="row" justifyContent="space-between">
            {label && (
              <Grid item={true}>
                <LabelWithFieldStateMarker label={this.props.label} badgeColor={this.props.badgeColor} />
              </Grid>
            )}
            {!readOnly && (fileAddable || itemAddable) && (
              <Grid item={true}>
                {fileAddable && (
                  <ButtonComponent label={messages.get(MessageKey.CORE.LIST.UPLOAD.ADD_FILE)!}
                                   icon={<AttachFile />}
                                   onClick={this.showFilePicker}
                                   style={{marginRight: 10}}
                  />
                )}
                {itemAddable && (
                <ButtonComponent label={messages.get(MessageKey.CORE.FORM.ADD)!}
                                 icon={<Add />}
                                 onClick={this.openNewItemDialog}
                />
                )}
              </Grid>
            )}
          </Grid>
        )}
        {this.state.tableConfig && (
        <ClassicDataTable label={label}
                          isLoading={false}
                          tableConfig={this.state.tableConfig}
                          currentPage={this.state.sortedListComponentValue}
                          showColumnHeaders={!!listConfiguration.simpleColumns!.find(column => !!column.headerText)}
                          readOnly={this.props.readOnly}
        />
        )}
        {this.state.formDialogProps && (
          <FormDialogComponent {...this.state.formDialogProps} />
        )}
      </Paper>
    );

    if (fileAddable) {
      const {maxFiles, maxFileSize, acceptedFileTypes} = this.resolveFilesComponentParameters();
      return (
        <FilesComponent onUpload={this.handleUploadedFile}
                        ref={this.filesComponentRef}
                        maxFiles={maxFiles}
                        currentFileCount={this.props.value?.ids?.length ?? 0}
                        maxFileSize={maxFileSize}
                        acceptedFileTypes={mimeTypeMapping(acceptedFileTypes)}
                        readOnly={this.props.readOnly}
        >
          {Component}
        </FilesComponent>
      );
    }
    return Component;
  }
}

const ListComponent = withConfirmDialog(WebListComponent);
export {ListComponent};
