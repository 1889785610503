/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SetViewActionHandlers, createRefreshActionId} from "@icm/core-common";
import {useCallback} from "react";

import {ListRefresher} from "./useEntityListComponent";

/**
 * Returns a callback that can be used to install a view action handler to refresh an entity list.
 *
 * @param setViewActionHandlers
 */
export const useListRefreshActionHandler = (setViewActionHandlers: SetViewActionHandlers) => {
  return useCallback((refresher: ListRefresher) => {
    const key = createRefreshActionId(refresher.listContext);
    setViewActionHandlers({
      [key]: {
        run: () => {
          refresher.run()
            .then(r => console.log("Reloaded list for", refresher.listContext))
            .catch(e => console.warn("Could not reload list for", refresher.listContext));
        },
      },
    });
  }, [setViewActionHandlers]);
};
