/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Person} from "@icm/core-common";
import {createAction, createAsyncAction} from "typesafe-actions";

import {Standby, StandbyTeam} from "../../generated/api";
import {StandbyActionTypes} from "./types";

export const fetchStandbyTeams = createAsyncAction(
  StandbyActionTypes.FETCH_STANDBY_TEAMS_REQUEST,
  StandbyActionTypes.FETCH_STANDBY_TEAMS_SUCCESS,
  StandbyActionTypes.FETCH_STANDBY_TEAMS_ERROR,
)<void, StandbyTeam[], Error>();

export const fetchStandbyTeamMembers = createAsyncAction(
  StandbyActionTypes.FETCH_STANDBY_TEAM_MEMBERS_REQUEST,
  StandbyActionTypes.FETCH_STANDBY_TEAM_MEMBERS_SUCCESS,
  StandbyActionTypes.FETCH_STANDBY_TEAM_MEMBERS_ERROR,
)<StandbyTeam, Person[], Error>();

export const fetchStandbys = createAsyncAction(
  StandbyActionTypes.FETCH_STANDBY_REQUEST,
  StandbyActionTypes.FETCH_STANDBY_SUCCESS,
  StandbyActionTypes.FETCH_STANDBY_ERROR,
)<StandbyTeam, Standby[], Error>();

export const submitStandby = createAsyncAction(
  StandbyActionTypes.SUBMIT_STANDBY_REQUEST,
  StandbyActionTypes.SUBMIT_STANDBY_SUCCESS,
  StandbyActionTypes.SUBMIT_STANDBY_ERROR,
)<Standby, string, Error>();

export const deleteStandby = createAsyncAction(
  StandbyActionTypes.DELETE_STANDBY_REQUEST,
  StandbyActionTypes.DELETE_STANDBY_SUCCESS,
  StandbyActionTypes.DELETE_STANDBY_ERROR,
)<string, boolean, Error>();

export const setInterval = createAction(StandbyActionTypes.SET_INTERVAL,
  (amount: number) => amount)();

export const updateNewStandby = createAction(StandbyActionTypes.UPDATE_NEW_STANDBY,
  (fieldName: string, value?: any) => ({fieldName, value}))();

export const deleteError = createAction(StandbyActionTypes.DELETE_ERROR)();

export const updateNewStandbyTime = createAction(StandbyActionTypes.UPDATE_NEW_STANDBY_TIME)();
