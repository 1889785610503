/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as Stomp from "@stomp/stompjs";
import {Client} from "@stomp/stompjs";
// see explanation in sockjs-client.d.ts
import SockJS, {CloseEvent} from "sockjs-client";

import {Urls, WebSocketParameterName} from "../constant";
import {DataLoad, DataUpdate, FeatureConfiguration, LoginStatus, Ping} from "../generated/api";
import {Base64, IdGenerator, Random} from "../util";
import {ConnectionMonitoringService} from "./connection";
import {DataLoadService} from "./DataLoadService";
import {dataUpdateService} from "./DataUpdateService";
import {FetchService} from "./FetchService";
import {SecurityService} from "./security";

export class WebSocketConnection {
  private static reconnectTimeoutId: number | null;

  public static initialize(securityService: SecurityService, connectionMonitoringService: ConnectionMonitoringService,
                           featureConfiguration: FeatureConfiguration) {
    if (this.isUsingWebSocket(featureConfiguration)) {
      console.log("Initializing WebSocketConnection");
      WebSocketConnection.connect(securityService, connectionMonitoringService);
    } else {
      console.log("Not opening web socket connection as web socket disabled by configuration.");
    }
  }

  private static cancelReconnect() {
    if (WebSocketConnection.reconnectTimeoutId) {
      window.clearInterval(WebSocketConnection.reconnectTimeoutId);
      WebSocketConnection.reconnectTimeoutId = null;
    }
  }

  private static reconnectAfterDelay(milliseconds: number, securityService: SecurityService,
                                     connectionMonitoringService: ConnectionMonitoringService) {
    WebSocketConnection.cancelReconnect();
    WebSocketConnection.reconnectTimeoutId = window.setTimeout(() => {
      console.log("Performing reconnect...");
      WebSocketConnection.connect(securityService, connectionMonitoringService);
    }, milliseconds);
  }

  private static connect(securityService: SecurityService, connectionMonitoringService: ConnectionMonitoringService) {
    console.log("Refreshing authentication token for web socket connection");
    FetchService.performGet(Urls.LOGIN_STATUS).then((status: LoginStatus) => {
      const token = status.authenticationToken || "";
      console.log("Initializing web socket connection for user " + status.user?.userName);
      const roleNameHeaderValue = WebSocketConnection.getRoleNameHeaderValue(securityService);
      const url = `${Urls.SOCKET}?${WebSocketParameterName.TOKEN}=${Base64.encode(token)}&${WebSocketParameterName.ACTIVE_ROLE_NAMES}=${encodeURIComponent(roleNameHeaderValue || "")}`;
      // console.log("Web socket URL: " + url + " for token " + token);
      const sessionId = Random.lowerAlphanumericString(8);
      const stompClient = new Stomp.Client({
        webSocketFactory: () => new SockJS(url, [], {
          sessionId: () => sessionId,
        }),
      });

      let onLogout = () => {};

      const loginHandler = () => {
        // return function of login handler is executed on logout
        return () => {
          console.log("Closing web socket connection due to logout");
          onLogout();
          WebSocketConnection.cancelReconnect();
          stompClient.deactivate();
        };
      };
      stompClient.debug = () => { /* NOOP */ };

      stompClient.onConnect = () => {
        console.log(`Connected to ${Urls.SOCKET} (session: ${sessionId})`);
        WebSocketConnection.subscribe(stompClient, sessionId);
        const intervalId = WebSocketConnection.startSending(stompClient);
        onLogout = () => window.clearInterval(intervalId);
        connectionMonitoringService.notifyConnected();
      };
      stompClient.onStompError = (errorFrame) => WebSocketConnection.handleError(errorFrame);
      const loginHandlerKey = securityService.addLoginHandler(loginHandler);
      stompClient.onWebSocketClose = event => {
        console.log("Web socket connection has been closed");
        onLogout();
        WebSocketConnection.cancelReconnect();
        stompClient.deactivate().then(() => {
          WebSocketConnection.handleClose(event, securityService, connectionMonitoringService, loginHandlerKey);
        });
        connectionMonitoringService.notifyDisconnected();
      };
      stompClient.activate();
    }).catch((_status: LoginStatus) => {
      if (securityService.isLoggedIn()) {
        console.log("Authentication token could not be received, not able to open secure web socket connection, retrying in a moment");
        this.reconnectAfterDelay(5000, securityService, connectionMonitoringService);
      } else {
        console.log("Authentication token could not be received, not retrying as currently not logged in");
      }
    });
  }

  private static handleError(error: unknown) {
    console.log("A problem occurred on web socket connection: ", error);
  }

  private static handleClose(event: CloseEvent, securityService: SecurityService, connectionMonitoringService: ConnectionMonitoringService,
                             loginHandlerKey: string) {
    console.log("Web socket connection closed", event);
    securityService.removeLoginHandler(loginHandlerKey);
    this.reconnectAfterDelay(1000, securityService, connectionMonitoringService);
  }

  private static subscribe(stompClient: Client, sessionId: string) {
    stompClient.subscribe("/topic/data/updates", data => {
      // console.log("RECEIVED UPDATE " + data.body);
      dataUpdateService.notify(DataUpdate.fromData(JSON.parse(data.body)));
    });
    stompClient.subscribe("/topic/data/load", data => {
      // console.log("RECEIVED LOAD " + data.body);
      DataLoadService.getInstance().notify(DataLoad.fromData(JSON.parse(data.body)));
    });
    stompClient.subscribe(`/session/${sessionId}/pong`, (_data) => {
      // console.log("Received pong", data.body);
    });
  }

  private static startSending(stompClient: Client) {
    // stompClient.send("/app/hello", {}, JSON.stringify({'name': "world"}));
    return window.setInterval(() => {
      const ping = new Ping();
      ping.id = IdGenerator.randomUUID();
      // console.log("Sending ping", ping);
      try {
        stompClient.publish({
          destination: "/app/ping",
          body: JSON.stringify(ping),
        });
      } catch (error) {
        console.warn("WebSocket ping failed", error);
      }
    }, 5000);
  }

  private static isUsingWebSocket(featureConfiguration: FeatureConfiguration): boolean {
    return !!featureConfiguration.openWebsocket;
  }

  private static getRoleNameHeaderValue(securityService: SecurityService): string | null {
    return securityService.getActiveRolesNameString();
  }
}
