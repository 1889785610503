/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export class SortService {

  // return 1 if object1 is bigger, -1 if object2 is bigger, 0 if equal
  // bigger in the sense of the > operator
  // undefined objects are considered smaller
  public static compare(object1?: any, object2?: any, considerUndefinedSmaller = true): number {
    if (object1 !== undefined && object1 !== null && object2 !== undefined && object2 !== null) {
      return object1 > object2 ? 1 : (object1 < object2 ? -1 : 0);
    } else if (object1 !== undefined && object1 !== null) {
      return considerUndefinedSmaller ? -1 : 1;
    } else if (object2 !== undefined && object2 !== null) {
      return considerUndefinedSmaller ? 1 : -1;
    } else {
      return 0;
    }
  }

  /**
   * creates a comparator fn using a value accessor
   * @param valueAccessor fn to access value
   * @param comparator actual comparator, SortService.compare by default
   * @param reverse if true, the comparator is reversed
   * @template {T} original type of array objects
   * @template {U} type on which the comparison is performed
   */
  public static createComparator<T, U>(
    valueAccessor: (x: T) => U,
    comparator: (a: U, b: U) => number = SortService.compare,
    reverse: boolean = false
  ): (a: T, b: T) => number {
    return (a, b) => comparator(valueAccessor(a), valueAccessor(b));
  }

  public static isSorted<T>(array: T[], comparator: (a: T, b: T) => number): boolean {
    for (let i = 0; i < array.length - 1; i++) {
      if (comparator(array[i], array[i + 1]) > 0) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param array array sorted according to the given comparator
   * @param newValue value to be inserted
   * @param comparator the comparator
   * @param highestPossibleIndex if true, the highest possible index to maintain sorting is returned (instead of the lowest possible)
   * @returns index at which the newValue can be inserted while maintaining the order,
   * or undefined if such an index can't be found (i.e., the array was not sorted according to the comparator)
   */
  public static sortedIndex<T>(array: T[], newValue: T, comparator: (a: T, b: T) => number, highestPossibleIndex = false): number | undefined {
    let low = 0;
    let high = array.length;
    while (low < high) {
      const mid = Math.floor((low + high) / 2);
      const cmp = comparator(array[mid], newValue);
      if (highestPossibleIndex ? cmp <= 0 : cmp < 0) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return high;
  }
}
