/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {useContext} from "react";
import {useQuery} from "react-query";

import {getThemeConfiguration} from "../api";
import {ThemeContext} from "./ThemeContext";
import {IcmTheme} from "./types";

export const useThemeConfiguration = () => {
  return useQuery(["public", "core", "configuration", "theme"], getThemeConfiguration, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useTheme: () => IcmTheme = () => {
  const {themeState} = useContext(ThemeContext) ?? {};
  return themeState!.themes[themeState!.selectedTheme]!;
};
