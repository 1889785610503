/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IdGenerator} from "./IdGenerator";

export class Random {
  public static uuid(): string {
    return IdGenerator.randomUUID();
  }

  public static lowerAlphanumericString(length: number): string {
    return Random.string(length, "0123456789abcdefghijklmnopqrstuvwxyz");
  }

  private static string(length: number, chars: string): string {
    let result = "";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }
}
