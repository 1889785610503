/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState} from "../types";


export const MarkNotificationAsRead = (
  state: IUiState,
  action: PayloadAction<string | string[]>
) => {
  const keys = Array.isArray(action.payload) ? action.payload : [action.payload];
  state.notifications
    .filter(n => !n.read && keys.includes(n.key))
    .forEach(n => {
      n.read = true;
    });
};
