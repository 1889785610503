/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export class StorageKey {
  public static readonly CURRENT_LOGIN = "CURRENT_LOGIN";
  public static readonly CURRENT_USER = "CURRENT_USER";
  public static readonly ACTIVE_ROLES = "ACTIVE_ROLES";
  public static readonly LAST_ACTIVITY_TIMESTAMP = "LAST_ACTIVITY_TIMESTAMP";
  public static readonly LOGIN_NOTIFICATION_STATUS = "LOGIN_NOTIFICATION_STATUS";
  public static readonly LAST_USERNAME_PREFIX = "LAST_USERNAME_PREFIX";
}
