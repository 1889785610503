/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  isString,
  ParameterUtilities,
  ViewDescriptor,
} from "@icm/core-common";
import {isEntity} from "@icm/entity-common";

import {
  EntityHistoryView,
  EntityHistoryViewModelData,
} from "./EntityHistoryView";

export const entityHistoryViewDescriptor: ViewDescriptor<EntityHistoryViewModelData> = {
  viewType: "ENTITY_HISTORY",
  view: EntityHistoryView,
  getTitle: (viewModel) => viewModel.viewModelData.entityId, // TODO PICM-1501 use proper message key
  createUniqueHash: (viewModelData) => viewModelData.entityId,
  initializeViewModelData: viewParameters => {
    // TODO PICM-1501 very similar to edit view/ composite view? maybe centralize
    const entity = ParameterUtilities.getResolvedParameterValue("entity", viewParameters, isEntity);
    const entityTypeParam = ParameterUtilities.getResolvedParameterValue("entityType", viewParameters, isString);
    const entityIdParam = ParameterUtilities.getResolvedParameterValue("entityId", viewParameters, isString);

    const entityType = entity?.type ?? entityTypeParam;
    const entityId = entity?.id ?? entityIdParam; // ?? entityKeyParam;
    if (!entityType || !entityId) {
      throw Error("No entity type and/or id given");
    }

    const mapViewId = ParameterUtilities.getResolvedParameterValue("mapViewId", viewParameters, isString);

    return {
      entityId,
      entityType,
      mapViewId,
      printRequested: false,
    };
  },
};
