/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../theme";

const styles = (theme: IcmMuiTheme) => createStyles({
  icmApplicationHeader: {
    color: theme.palette.text.primary,
    background: theme.icm.getColor("ApplicationHeader").main,
    height: 50,

    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "&> *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    marginLeft: 5,
    marginRight: 5,
  },
  icmApplicationHeaderLogo: {
    backgroundImage: `url(${theme.icm.images.LOGO})`,
    backgroundSize: "contain",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    width: 250,
    height: 42,
    "@media print": {
      "-webkit-print-color-adjust": "exact !important",
      colorAdjust: "exact !important",
      backgroundPosition: "left",
    },
  },

  frequentisLogo: {
    backgroundImage: `url(${theme.icm.images.VENDOR_LOGO})`,
    backgroundSize: "contain",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    width: 160,
    height: "1.5rem",
  },

  user: {
    display: "flex",
    alignItems: "center",
    "&> button": {
      marginRight: theme.spacing(1),
    },
  },

  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 0,
    },
    "50%": {
      transform: "scale(1.3)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.0)",
      opacity: 1,
    },
  },

  ripple: {
    "-webkit-animation": "$ripple .6s 0s ease-in-out",
    "-webkit-transform-origin": "50% 50%",
    "-moz-animation": "$ripple .6s 0s ease-in-out",
    "-moz-transform-origin": "50% 50%",
    animation: "$ripple .6s 0s ease-in-out",
    "transform-origin": "50% 50%",
  },

  headerIcon: {
    width: 48,
    height: 48,
    padding: 12,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
});

export default styles;
