/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ViewDescriptor} from "@icm/core-common";
import React from "react";

export type SystemMetricsViewModel = {
  printRequested: boolean,
}

export const systemMetricsViewDescriptor: ViewDescriptor<SystemMetricsViewModel> = {
  viewType: "SYSTEM_METRICS",
  view: React.lazy(() => import("./SystemMetricsView")),
  getTitle: (_viewModel) => "System Metrics",
  initializeViewModelData: (_viewParameters) => {
    return {
      printRequested: false,
    };
  },
  createUniqueHash: (_viewModelData) => "systemMetrics",
};
