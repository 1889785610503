/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {darken, isDark, lighten, withAlpha} from "./ColorUtils";
import {
  IcmColorScheme,
  ComponentId,
  ConfiguredColorScheme,
  ConfiguredPalette,
  ConfiguredTheme,
  IcmImagePaths,
  IcmTheme, IcmThemeConfiguration, IcmThemeState,
  IcmPalette,
  PaletteColor,
} from "./types";

export const DEFAULT_COMPONENT_COLORS: Record<ComponentId, PaletteColor> = {
  LoginHeader: "background",
  ApplicationHeader: "background",
  NavigationMenu: "background",
  TabBar: "primary",
};

// all paths relative to application base url
export const DEFAULT_IMAGE_PATHS = {
  LOGO: "core/common/images/logo_empty.png",
  VENDOR_LOGO: "core/common/images/frequentis_logo.svg",
  FAVICON_DIRECTORY: "core/common/favicon/product",
  // declare an optional image like this:
  // SOME_OPTIONAL_IMAGE: undefined as string | undefined,
};

export function createThemeState(themeConfig: IcmThemeConfiguration, desiredTheme = themeConfig.defaultTheme): IcmThemeState {
  const selectedTheme = themeConfig.themes[desiredTheme] ? desiredTheme : themeConfig.defaultTheme;
  return {
    selectedTheme,
    themes: Object.entries(themeConfig.themes).reduce((aggregate, [themeName, configuredTheme]) => {
      if (configuredTheme) {
        aggregate[themeName] = augmentTheme(configuredTheme);
      }
      return aggregate;
    }, {}),
  };
}

/**
 * Takes the configured theme and augments the missing/unconfigured values that can be derived from the configured ones.
 * @param configTheme the configured theme
 * @return the augmented theme
 */
export function augmentTheme(configTheme: ConfiguredTheme): IcmTheme {
  const palette = augmentPalette(configTheme.palette);
  return {
    palette,
    getColor: componentId => palette[configTheme.overrides?.[componentId] ?? DEFAULT_COMPONENT_COLORS[componentId]]!,
    images: amendDefaultImagePaths(configTheme.images),
  };
}
function augmentPalette(configuredPalette: ConfiguredPalette): IcmPalette {
  const result: Partial<IcmPalette> = {};
  for (const [key, scheme] of Object.entries(configuredPalette)) {
    if (scheme) {
      result[key] = augmentColorScheme(scheme);
    }
  }
  return result as IcmPalette;
}
function amendDefaultImagePaths(images: Partial<IcmImagePaths>): IcmImagePaths {
  return {
    ...DEFAULT_IMAGE_PATHS,
    ...images,
  };
}

/**
 * Takes the configured color scheme and augments the missing/unconfigured values that can be derived from the configured ones.
 *
 * NOTE: keep this function in sync with the description in `ColorEditDialog`
 *
 * @param configuredColorScheme the configured color scheme
 * @return the augmented color scheme
 */
export function augmentColorScheme(configuredColorScheme: ConfiguredColorScheme): IcmColorScheme {
  const {
    main, contrast, dark, light, textPrimary, textSecondary, disabled, divider,
  } = configuredColorScheme;
  const newDark = dark ?? darken(main, 0.3);
  const newLight = light ?? lighten(main, 0.25);

  const isMainDark = isDark(main);

  const newTextPrimary = textPrimary ?? withAlpha(contrast, isMainDark ? 1 : 0.87);
  const newTextSecondary = textSecondary ?? withAlpha(contrast, isMainDark ? 0.7 : 0.54);
  const newDisabled = disabled ?? withAlpha(contrast, isMainDark ? 0.5 : 0.38);
  const newDivider = divider ?? withAlpha(contrast, 0.12);
  return {
    main,
    contrast,
    dark: newDark,
    light: newLight,
    textPrimary: newTextPrimary,
    textSecondary: newTextSecondary,
    disabled: newDisabled,
    divider: newDivider,
  };
}
