/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";

import {AbstractFormField} from "../../generated/api";
import {ExpressionEvaluationService, formatService} from "../../service";
import {getNamedComponentFactory} from "../../ui";
import {GenericFormFieldWithFormConverter} from "../FormGeneratorHelper";
import {IFormComponentProps} from "./AbstractFormComponent";
import {FieldValidationType} from "./FieldValidationState";
import {assertPropertiesExist} from "./FormGeneratorUtils";
import {FormLabelComponent} from "./label";
import {FormListComponent} from "./list/FormListComponent";
import {AutoCompleteFormComponent} from "./select";
import {FormRadioComponent} from "./select/FormRadioComponent";
import {FormTextBoxComponent} from "./text";
import {GenericFormConfigurationAndVariantName} from "./types";

type FormComponentProps = Pick<IFormComponentProps<any>, "value" | "readOnly" | "disabled" | "error" | "required" | "badgeColor" | "onFocus"> & {
  formConfig: GenericFormConfigurationAndVariantName
  field: GenericFormFieldWithFormConverter;
  entity: any;
  handleChange: (field: AbstractFormField, value?: any, referredObject?: any) => void;
  possibleValuesUrl?: string;
  useSimpleValues: boolean;
  updateFieldValidationState: (type: FieldValidationType, valueBinding: string, isValid: boolean) => void
}

export const FormComponent = React.memo((props: FormComponentProps) => {
  const {
    field,
    value,
    readOnly,
    disabled,
    error,
    required,
    badgeColor,
    possibleValuesUrl,
    useSimpleValues,
    handleChange,
    updateFieldValidationState,
    onFocus,
  } = props;
  const {
    component,
    label,
    format,
    valueDisplay,
    valueParser,
    helperText,
  } = field;
  assertPropertiesExist(field, "valueBinding", "label", "component");
  const entityRef = React.useRef();
  entityRef.current = props.entity;

  const initialValue = useMemo(
    () => field.initialValueProvider ? ExpressionEvaluationService.evaluate(field.initialValueProvider) : undefined,
    [field.initialValueProvider]
  );

  const isUnsavedEntity = !props.entity?.version;
  const [evaluateInitialValue, setEvaluateInitialValue] = useState(isUnsavedEntity
    && !readOnly
    && !disabled
    && initialValue
    && !value
    && initialValue !== value);

  useEffect(() => {
    if (!readOnly && !disabled && initialValue !== undefined && value === undefined && initialValue !== value && evaluateInitialValue) {
      console.group("Applying Initial Value to", field.label);
      console.debug("current value", value);
      console.debug("initial value", initialValue);
      console.groupEnd();
      setEvaluateInitialValue(false);
      handleChange(field, initialValue, entityRef.current);
    }
  }, [evaluateInitialValue, field, handleChange, initialValue, readOnly, disabled, value, entityRef]);

  const formatter: ((val?: any, fmt?: string, as?: string) => string) | undefined = useMemo(() => {
    if (valueDisplay) {
      return (val, fmt, _as) => ExpressionEvaluationService.evaluate(valueDisplay, val, entityRef.current, fmt);
    } else if (format) {
      return formatService.getFormatter(format);
    }
    return undefined;
  }, [valueDisplay, format]);

  const parser: ((text?: string, fmt?: string) => any) | undefined = useMemo(() => {
    if (valueParser) {
      return (text, fmt) => ExpressionEvaluationService.evaluate(valueParser, text, entityRef.current, fmt);
    } else if (format) {
      return formatService.getParser(format);
    }
    return undefined;
  }, [valueParser, format]);

  const validator = React.useMemo(() => {
    return parser ? (val?: any, fmt?: string) => {
      const text = typeof val === "string" ? val : formatter?.(val, undefined, "INPUT");
      return text === "" || !!text === (parser(text, fmt) != null);
    } : undefined;
  }, [parser, formatter]);

  const tryParseAndHandleChange: (newValue?: any, referredObject?: any) => void = useCallback(
    (newValue?: any, referredObject?: any) => {
      let validatedValue = newValue;
      if (parser) {
        const parsedValue = parser(newValue, referredObject);
        updateFieldValidationState("parser", field.valueBinding!, !!newValue === !!parsedValue);
        if (parsedValue) {
          validatedValue = parsedValue;
        }
      }
      handleChange(field, validatedValue, referredObject);
    },
    [handleChange, parser, field, updateFieldValidationState]
  );

  const checkedValue = useMemo(() => {
    return formatter && (validator?.(value) ?? true) ? formatter(value, undefined, "INPUT") : value;
  }, [value, formatter, validator]);

  const updateSingleFieldValidationState = useCallback((isValid: boolean) => updateFieldValidationState("parser", field.valueBinding ?? "", isValid),
    [updateFieldValidationState, field.valueBinding]);

  try {
    const commonProps: IFormComponentProps<any> = {
      value: checkedValue,
      label: label || "",
      helperText,
      handleChange: tryParseAndHandleChange,
      disabled,
      error,
      required,
      readOnly,
      badgeColor,
      onFocus,
      updateFieldValidationState: updateSingleFieldValidationState,
    };

    if (!component) {
      return <span>The component type is missing here.</span>;
    }

    const Component = getNamedComponentFactory(component) ?? (() => <span>Type {component} not supported</span>);

    const createIFrameComponent =  () => {
      const formatParts = format ? format.split(":") : [];
      if (formatParts.length !== 3) {
        return <span>IFrame Component not configured correctly.</span>;
      }
      const urlString = formatParts[0];
      const url = new URL(decodeURIComponent(urlString));
      const minHeight = formatParts[1];
      const iFrameTitle = formatParts[2];
      return <Component title={iFrameTitle} url={url.toString()} minHeight={minHeight} />;
    };

    switch (component) {
      case "WEATHER_BUTTON":
        return <Component {...commonProps} entity={props.entity} handleChange={handleChange} parameterList={field.parameterList} />;
      case "MEDIA_STREAM_PLAYER":
        return <Component {...commonProps} sources={field.possibleValuesList} parameterList={field.parameterList} />;
      case "DATETIME_PICKER":
        return <Component {...commonProps} />;
      case "TIME_PICKER":
        return <Component {...commonProps} />;
      case "DATE_PICKER":
        return <Component {...commonProps} />;
      case "TEXT_FIELD":
      case "TEXT_AREA":
        return (
          <FormTextBoxComponent
            {...commonProps}
            originalValue={props.value}
            formatter={formatter}
            parser={parser}
            format={format}
            component={component}
            possibleValuesList={field.possibleValuesList}
            validate={validator}
          />
        );
      case "SELECT_SINGLE":
      case "SELECT_MULTI":
        return (
          <AutoCompleteFormComponent {...commonProps}
                                     possibleValuesUrl={possibleValuesUrl}
                                     field={field}
                                     useSimpleValues={useSimpleValues}
          />
        );
      case "CHECKBOX":
        return (
          <Component {...commonProps}
                     value={typeof commonProps.value === "string" ? commonProps.value.toLowerCase() === "true" : commonProps.value}
          />
        );
      case "RADIOBUTTON":
        return <FormRadioComponent {...commonProps} field={field} useSimpleValues={useSimpleValues} possibleValuesUrl={possibleValuesUrl} />;
      case "LIST":
        return <FormListComponent {...commonProps} field={field} useSimpleValues={useSimpleValues} />;
      case "PROCESS_MODEL":
        return <Component {...commonProps} />;
      case "LABEL":
        return <FormLabelComponent label={field.label} helperText={field.helperText} value={commonProps.value} />;
      case "DEMO":
        return <Component {...commonProps} field={field} />;
      case "IFRAME":
        return createIFrameComponent();
      default:
        return <span>Type {component} not supported</span>;
    }
  } catch (e: any) {
    return <span>{e}</span>;
  }
});
