/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";
import {useQuery} from "react-query";

import {
  FilterConfiguration,
  FilterOption,
  FilterSelection,
  FilterSelectionApi,
  FilterUserOperation,
  FilterValueType,
  UserSettingsApi,
} from "../../api";
import {FetchService, queryClient, SortService} from "../../service";
import {
  BaseFilterData,
  ClientFilterSelection,
  FALLBACK_ID,
  FilterData,
  isClientFilterSelection,
  isFallbackFilterSelection,
  isTemporaryFilterSelection,
  SaveFilterResult,
  useFallbackClientFilterSelection,
} from "../index";
import {
  useFilterConfiguration,
  useFilterConfigurationCacheKey,
  useInvalidateFilterConfiguration,
} from "./useFilterConfiguration";

type FilterPreferenceOptions = {
  entityType: string,
  variant: string
}

function getPreferredFilterSelectionUserSettingKey(options: FilterPreferenceOptions) {
  return `IcmSelectedFilter-${options.entityType}-${options.variant}`;
}

async function savePreferredFilterSelection(options: FilterPreferenceOptions, filterSelectionId: string) {
  const userSettingKey = getPreferredFilterSelectionUserSettingKey(options);
  return UserSettingsApi.postUserSetting(userSettingKey, filterSelectionId);
}

async function getPreferredFilterSelection(options: FilterPreferenceOptions,
                                           availableFilterSelections: ClientFilterSelection[]): Promise<ClientFilterSelection | undefined> {
  const filterUserSettingKey = getPreferredFilterSelectionUserSettingKey(options);
  const id = await UserSettingsApi.getUserSetting(filterUserSettingKey);
  return availableFilterSelections.find(fs => fs.id === id);
}

async function getOptionsFromUrl(url: string | undefined) {
  let sourceUrlOptions = undefined;
  if (url) {
    sourceUrlOptions = await FetchService.performGet<FilterOption[]>(url);
    sourceUrlOptions.sort(SortService.createComparator(x => x.label?.toLowerCase()));
  }
  return sourceUrlOptions;
}


function mergeFilterOptions(
  opt1: BaseFilterData["filterOptions"] | undefined,
  opt2: BaseFilterData["filterOptions"] | undefined
): BaseFilterData["filterOptions"] {
  return [...opt1 || [], ...opt2 || []];
}

const getFilterUserOperation = (filterOption?: FilterOption, filterValueType?: FilterValueType): FilterUserOperation | undefined => {
  return filterOption && filterValueType
    && [filterOption.defaultFilterOperation, ...(filterOption.filterOperations ?? [])]
      .find(fop => fop && fop.filterValueType === filterValueType)?.filterUserOperation;
};

function getAvailableFilterSelections(filterSelections: FilterSelection[], options: FilterOption[]): ClientFilterSelection[] {
  // fill out missing filterUserOperations with first available, fitting FilterOption (with matching filterValueType)
  return filterSelections
    .filter(isClientFilterSelection)
    .map(filterSelection => {
      return {
        ...filterSelection,
        selectedFilters: filterSelection.selectedFilters?.map(sf => ({
          ...sf,
          filterUserOperation: sf.filterUserOperation ?? getFilterUserOperation(options.find(fo => fo.id === sf.filterOptionId), sf.filterValueType),
        })),
      } as ClientFilterSelection;
    });
}

function updateCachedFilterData(filterSelectionToSave: ClientFilterSelection, current: BaseFilterData | undefined): BaseFilterData {
  const available = current?.availableFilterSelections ?? [];

  const existingFilter = available.find(f => f.id === filterSelectionToSave.id);
  if (existingFilter) {
    const index = available.indexOf(existingFilter);
    available[index] = filterSelectionToSave;
  } else {
    available.push(filterSelectionToSave);
  }

  return {
    ...current!,
    selectedFilter: filterSelectionToSave,
    availableFilterSelections: available,
  };
}


/**
 * Hook that returns a DAO like object to access the FilterSelectionApi.
 * Calls are backed by the query client.
 *
 * @param filterConfigurationId - the variant of the filter or a UUID?
 * @param showEventFilterOptions
 */
export function useFilterData(filterConfigurationId: string | undefined, showEventFilterOptions: boolean = false): FilterData | undefined {
  const {
    filterConfiguration,
    options,
  } = useFilterConfiguration(filterConfigurationId);

  const fallbackFilter = useFallbackClientFilterSelection(filterConfiguration?.entityType);
  const filterDataCacheKey = useFilterConfigurationCacheKey(filterConfigurationId, showEventFilterOptions ? "ON" : "OFF");


  const invalidateFilterData = useInvalidateFilterConfiguration(filterConfigurationId);

  const {data: selections, isSuccess} = useQuery(filterDataCacheKey,
    async () => {
      if (!filterConfigurationId || !filterConfiguration || !filterConfiguration?.entityType || !options) {
        return undefined;
      }
      // TODO: by type and variant...
      const filterSelections = await FilterSelectionApi.getFilterSelectionByEntityType(filterConfiguration.entityType);
      const availableOptions = options.filter(opt => opt.optionType === "ENTITY" || (opt.optionType === "EVENT" && showEventFilterOptions));
      const availableFilterSelections = getAvailableFilterSelections(filterSelections, availableOptions).concat(fallbackFilter);
      const defaultFilterSelection: ClientFilterSelection = availableFilterSelections[0];

      const preferenceOptions: FilterPreferenceOptions = {
        entityType: filterConfiguration.entityType,
        variant: filterConfigurationId,
      };

      const selectedFilter = await getPreferredFilterSelection(preferenceOptions, availableFilterSelections);
      const sourceUrlOptions = await getOptionsFromUrl(filterConfiguration.filterOptionsSourceUrl);

      return {
        selectedFilter,
        defaultFilterSelection,
        availableFilterSelections,
        options: mergeFilterOptions(availableOptions, sourceUrlOptions),
      };
    },
    {
      enabled: filterConfiguration && filterConfigurationId !== undefined,
    });


  const save = useCallback(async (filterSelection: ClientFilterSelection): Promise<SaveFilterResult> => {
    try {
      if (isFallbackFilterSelection(filterSelection)) {
        console.error("Saving fallback filter is not allowed.");
        return {
          result: "ERROR",
          filter: filterSelection,
        };
      }

      const initialSave = isTemporaryFilterSelection(filterSelection);

      // if the filter is saved initially, the id must be undefined
      const filterSelectionToSave = initialSave ? {
        ...filterSelection,
        id: undefined,
      } : filterSelection;

      // when creating a new filter, the id will be assigned by the server
      // if the filter existed before, savedFilterId and filterSelection.id will be identical
      const savedFilterId = await FilterSelectionApi.postFilterSelection(filterSelectionToSave);

      await savePreferredFilterSelection({
        entityType: filterSelection.entityType,
        variant: filterConfigurationId!,
      }, savedFilterId);

      await invalidateFilterData();

      const updatedFilterData = queryClient.setQueryData<BaseFilterData>(filterDataCacheKey, (current) => {
        return updateCachedFilterData({
          ...filterSelection,
          id: savedFilterId,
        }, current);
      });


      return {
        result: initialSave ? "CREATED" : "SAVED",
        filter: updatedFilterData.selectedFilter!,
      };
    } catch (e) {
      console.error("Could not save filter selection", filterSelection, e);
      return {
        result: "ERROR",
        filter: filterSelection,
      };
    }
  }, [filterConfigurationId, filterDataCacheKey, invalidateFilterData]);

  const savePreferred = useCallback(async (filterSelection: ClientFilterSelection): Promise<boolean> => {
    const preferenceOptions = {
      entityType: filterSelection.entityType,
      variant: filterConfigurationId!,
    };
    return savePreferredFilterSelection(preferenceOptions,  filterSelection.id);
  }, [filterConfigurationId]);

  const remove = useCallback(async (filterId: string): Promise<boolean> => {
    if (filterId === FALLBACK_ID) {
      console.log("Cannot remove fallback filter.");
      return false;
    }

    const deleted = await FilterSelectionApi.deleteFilterSelection(filterId);
    if (deleted) {
      await invalidateFilterData();
      queryClient.setQueryData<BaseFilterData>(filterDataCacheKey, (current: BaseFilterData) => {
        current.selectedFilter = current?.availableFilterSelections?.[0];
        current.availableFilterSelections = current?.availableFilterSelections?.filter(f => f.id !== filterId);
        return current;
      });
    }
    return deleted;
  }, [filterDataCacheKey, invalidateFilterData]);

  if (!filterConfiguration || !selections || !filterConfigurationId) {
    return undefined;
  }

  function resolveEntityType(fc: FilterConfiguration) {
    if (selections?.selectedFilter?.entityType) {
      return selections.selectedFilter?.entityType;
    }
    if (selections?.defaultFilterSelection?.entityType) {
      return selections.defaultFilterSelection.entityType;
    }

    return fc.entityType!;
  }

  return {
    variant: filterConfigurationId,
    filterParameterId: filterConfigurationId,
    availableFilterSelections: selections.availableFilterSelections,
    defaultFilterSelection: selections.selectedFilter ?? selections.defaultFilterSelection,
    selectedFilter: selections.selectedFilter,
    filterConfiguration,
    filterOptions: selections.options,
    entityType: resolveEntityType(filterConfiguration),
    isReady: isSuccess,
    invalidate: invalidateFilterData,
    savePreferredFilterSelection: savePreferred,
    save,
    remove,
  };
}
