/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ActionDescriptor, MessageKey, TabGroupTab, useExecuteViewAction, useMessages} from "@icm/core-common";
import {MoreVertRounded} from "@mui/icons-material";
import {Divider, ListItemIcon, ListItemText} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Share, ViewSplitVertical} from "mdi-material-ui";
import * as React from "react";

import {IconButtonComponent} from "../form";
import {IcmMuiIcon} from "../util";
import {useSelectTab} from "./useSelectTab";
import {useShareLinkGenerator} from "./useShareLinkGenerator";
import {useTabGroupSplitter} from "./useSplitTabGroup";
import {useTabContentActions} from "./useTabContentActions";
import {TabGroupDescriptor} from "./useTabGroupDescriptor";
import {useTabTitle} from "./useTabTitle";

export type TabGroupMenuProps = {
  groupDescriptor: TabGroupDescriptor
}

/**
 * Provides the menu that shows tab content and tab state actions when the tab header is in state MINIMIZED.
 *
 * @param props
 * @constructor
 */
export const TabGroupMenu = ({groupDescriptor}: TabGroupMenuProps) => {
  const {selectedTab, tabs} = groupDescriptor;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const tabContentActions = useTabContentActions(selectedTab.viewModel);

  return (
    <div>
      <IconButtonComponent handleClick={handleClick} icon={<MoreVertRounded />} />
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={closeMenu} className="dontPrint">
        {tabContentActions && tabContentActions.map(entry => (
          <TabContentActionMenuItem key={entry.actionType}
                                    tab={selectedTab}
                                    action={entry.action}
                                    actionKey={entry.actionType}
                                    onClick={closeMenu}
          />
        ))}
        {(tabContentActions && tabContentActions.length > 0) && <Divider variant="inset" />}
        <ShareLinkTabMenuItem groupDescriptor={groupDescriptor} onClick={closeMenu} />
        <SplitTabMenuItem groupDescriptor={groupDescriptor} onClick={closeMenu} />
        <Divider />
        {tabs.map(tab => <TabSelectionMenuItem key={tab.id} tab={tab} onClick={closeMenu} />)}
      </Menu>
    </div>
  );
};

type SplitTabMenuItemProps = {
  groupDescriptor: TabGroupDescriptor
  onClick: () => void
}

const SplitTabMenuItem = (props: SplitTabMenuItemProps) => {
  const {getMessage} = useMessages();
  const tabSplitter = useTabGroupSplitter(props.groupDescriptor);
  const handleOnClick = () => {
    tabSplitter.split();
    props.onClick();
  };

  return  (
    <MenuItem onClick={handleOnClick} disabled={!tabSplitter.enabled}>
      <ListItemIcon>
        <ViewSplitVertical />
      </ListItemIcon>
      <ListItemText>{getMessage(MessageKey.CORE.TAB_GROUP.SPLIT_VIEW)}</ListItemText>
    </MenuItem>
  );
};

const ShareLinkTabMenuItem = (props: SplitTabMenuItemProps) => {
  const {getMessage} = useMessages();
  const shareLinkGenerator = useShareLinkGenerator(props.groupDescriptor.selectedTab);
  const handleOnClick = () => {
    shareLinkGenerator.generate();
    props.onClick();
  };
  return  (
    <MenuItem onClick={handleOnClick} disabled={!shareLinkGenerator.enabled}>
      <ListItemIcon>
        <Share />
      </ListItemIcon>
      <ListItemText>{getMessage(MessageKey.CORE.TAB_GROUP.SHARE_LINK)}</ListItemText>
    </MenuItem>
  );
};


type TabSelectionMenuItemProps = {
  tab: TabGroupTab,
  onClick: () => void
}

/**
 * Menu item to select a tab and bring it to the foreground.
 * @param props
 * @constructor
 */
export const TabSelectionMenuItem = (props: TabSelectionMenuItemProps) => {
  const title = useTabTitle(props.tab);
  const selectTab = useSelectTab();
  const handleOnClick = () => {
    selectTab(props.tab.id);
    props.onClick();
  };
  return <MenuItem onClick={handleOnClick}>{title}</MenuItem>;
};


type TabContentActionMenuItemProps = {
  tab: TabGroupTab,
  actionKey: string,
  action: ActionDescriptor,
  onClick: () => void
}

/**
 * Menu item to execute arbitrary actions related.
 *
 * @param props
 * @constructor
 */
const TabContentActionMenuItem = (props: TabContentActionMenuItemProps) => {
  const {
    tab,
    action,
    actionKey,
    onClick,
  } = props;
  const viewModel = tab.viewModel;
  const executeViewAction = useExecuteViewAction(viewModel.id);

  const disabled = action.enabled === undefined ? false : !action.enabled;
  if (action.componentFactory) {
    const handleOnClick = (actionId: string, actionParameter: any) => {
      executeViewAction(actionId, actionParameter);
      onClick();
    };
    return action.componentFactory(actionKey, action, handleOnClick, disabled, true, false);
  } else {
    const handleOnClick = () => {
      executeViewAction(actionKey);
      onClick();
    };
    return (
      <MenuItem onClick={handleOnClick} disabled={disabled}>
        <ListItemIcon>
          {typeof action.icon === "string" ? <IcmMuiIcon name={action.icon} size="small" /> : action.icon}
        </ListItemIcon>
        <ListItemText>{action.title}</ListItemText>
      </MenuItem>
    );
  }
};
