/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";
import {messages} from "./MessageService";
import {numberService} from "./NumberService";

export type Unit = {
  type: string;
  name: string;
  factor: number;
  format?: string;
  prefix?: string;
  suffix?: string;
}

const DEFAULT_UNITS: Unit[] = [
  {
    type: "distance",
    name: "METRE",
    factor: 1,
    format: "0",
    suffix: "m",
  },
  {
    type: "distance",
    name: "STANDARD_METRE",
    factor: 10,
    format: "0",
    suffix: "sm",
  },
  {
    type: "distance",
    name: "KILOMETRE",
    factor: 1000,
    format: "0.0",
    suffix: "km",
  },
  {
    type: "distance",
    name: "MILE",
    factor: 1609.3,
    format: "0.0",
    suffix: "mi",
  },
  {
    type: "distance",
    name: "NAUTICAL_MILE",
    factor: 1852,
    format: "0.0",
    suffix: "NM",
  },
  {
    type: "distance",
    name: "YARD",
    factor: 3 * 0.30480,
    format: "0",
    suffix: "yd",
  },
  {
    type: "distance",
    name: "FOOT",
    factor: 0.30480,
    format: "0",
    suffix: "ft",
  },
  {
    type: "distance",
    name: "FLIGHT_LEVEL",
    factor: 100 * 0.30480,
    format: "0",
    prefix: "FL ",
  },
  {
    type: "area",
    name: "SQUARE_METRE",
    factor: 1,
    format: "0",
    suffix: "m²",
  },
  {
    type: "area",
    name: "AR",
    factor: 100,
    format: "0",
    suffix: "a",
  },
  {
    type: "area",
    name: "HECTAR",
    factor: 100 * 100,
    format: "0",
    suffix: "ha",
  },
  {
    type: "area",
    name: "SQUARE_KILOMETRE",
    factor: 1000 * 1000,
    format: "0.0",
    suffix: "km²",
  },
  {
    type: "area",
    name: "SQUARE_MILE",
    factor: 1609.3 * 1609.3,
    format: "0.0",
    suffix: "mi²",
  },
  {
    type: "area",
    name: "SQUARE_NAUTICAL_MILE",
    factor: 1852 * 1852,
    format: "0.0",
    suffix: "NM²",
  },
  {
    type: "area",
    name: "SQUARE_YARD",
    factor: 3 * 0.30480 * 3 * 0.30480,
    format: "0",
    suffix: "yd²",
  },
  {
    type: "area",
    name: "SQUARE_FOOT",
    factor: 0.30480 * 0.30480,
    format: "0",
    suffix: "ft²",
  },
  {
    type: "speed",
    name: "METRE_PER_SECOND",
    factor: 1,
    format: "0",
    suffix: "m/s",
  },
  {
    type: "speed",
    name: "METRE_PER_MINUTE",
    factor: 1.0 / 60,
    format: "0",
    suffix: "m/min",
  },
  {
    type: "speed",
    name: "KILOMETRE_PER_HOUR",
    factor: 1000.0 / 3600,
    format: "0.0",
    suffix: "km/h",
  },
  {
    type: "speed",
    name: "MILE_PER_HOUR",
    factor: 1609.3 / 3600,
    format: "0.0",
    suffix: "mi/h",
  },
  {
    type: "speed",
    name: "KNOT",
    factor: 1852.0 / 3600,
    format: "0.0",
    suffix: "kn",
  },
  {
    type: "speed",
    name: "FOOT_PER_SECOND",
    factor: 0.30480,
    format: "0",
    suffix: "ft/s",
  },
  {
    type: "speed",
    name: "FOOT_PER_MINUTE",
    factor: 0.30480 / 60,
    format: "0",
    suffix: "ft/min",
  },
  {
    type: "speed",
    name: "FLIGHT_LEVEL_PER_MINUTE",
    factor: 100 * 0.30480 / 60,
    suffix: "FL/min",
  },
  {
    type: "angle",
    name: "DEGREE",
    factor: 1,
    format: "0",
    suffix: "°",
  },
  {
    type: "angle",
    name: "RADIAN",
    factor: 180 / Math.PI,
    format: "0.00",
    suffix: "rad",
  },
  {
    type: "angle",
    name: "GON",
    factor: 180 / 200,
    format: "0",
    suffix: "gon",
  },
];

class UnitService {

  private locale: string;

  private readonly units: { [key: string]: Unit } = {};

  constructor(locale?: string) {
    this.locale = locale || "";
    if (!this.locale) {
      ExpressionEvaluationService.registerHelper("unit", "label",
        (unitName) => this.label(unitName));
      ExpressionEvaluationService.registerHelper("unit", "format",
        (n, unitName, asUnitName) => this.format(UnitService.toNumber(n), unitName, asUnitName));
      ExpressionEvaluationService.registerHelper("unit", "formatNumber",
        (n, unitName, asUnitName) => this.formatNumber(UnitService.toNumber(n), unitName, asUnitName));
      ExpressionEvaluationService.registerHelper("unit", "parseNumber",
        (text, unitName, asUnitName) => this.parseNumber(text, unitName, asUnitName));
      ExpressionEvaluationService.registerHelper("unit", "convert",
        (n, fromUnitName, toUnitName) => this.format(UnitService.toNumber(n), fromUnitName, toUnitName));
    }
  }

  private init() {
    if (messages.isReady()) {
      this.locale = this.locale || (messages.has("core.language") ? messages.get("core.language") : null) || "en-US";
      DEFAULT_UNITS.forEach(unit => {
        this.units[unit.name] = {
          ...unit,
          // override format from messages?
          format: messages.has(`unit.${unit.name}.format`) ? messages.get(`unit.${unit.name}.format`) : unit.format,
          prefix: messages.has(`unit.${unit.name}.prefix`) ? messages.get(`unit.${unit.name}.prefix`) : unit.prefix,
          suffix: messages.has(`unit.${unit.name}.suffix`) ? messages.get(`unit.${unit.name}.suffix`) : unit.suffix,
        };
      });
      this.init = () => null;
    }
  }

  /**
   * Get the unit with the given name.
   *
   * @param unitName the name of the unit
   * @return the unit or null
   */
  public getUnit(unitName: string): Unit | null {
    this.init();
    if (!this.units[unitName]) {
      if (messages.has(`unit.${unitName}.type`) && messages.has(`unit.${unitName}.factor`)) {
        this.units[unitName] = {
          type: messages.get(`unit.${unitName}.type`),
          name: unitName,
          factor: Number(messages.get(`unit.${unitName}.factor`)),
          format: messages.has(`unit.${unitName}.format`) ? messages.get(`unit.${unitName}.format`) : "0",
          prefix: messages.has(`unit.${unitName}.prefix`) ? messages.get(`unit.${unitName}.prefix`) : "",
          suffix: messages.has(`unit.${unitName}.suffix`) ? messages.get(`unit.${unitName}.suffix`) : "",
        };
      }
    }
    return this.units[unitName] || null;
  }

  /**
   * Get all units of a given type.
   * Custom units will only be returned, if already accessed by name.
   *
   * @param type the unit type
   */
  public getUnitsOfType(type: string): Unit[] {
    return Object.values(this.units)
      .filter(unit => unit.type === type)
      .sort((unit1, unit2) => unit1.factor - unit2.factor);
  }

  public label(unitName: string): string | null {
    const unit = this.getUnit(unitName);
    return unit ? unit.suffix?.trim() || unit.prefix?.trim() || unit.name : null;
  }

  public format(value: number | null | undefined, unitName: string, asUnitName?: string, numberFormat?: string): string {
    if (value != null) {
      const unit = this.getUnit(unitName);
      if (unit) {
        const numService = numberService.withLocale(this.locale);
        const asUnit = asUnitName ? this.getUnit(asUnitName) || unit : unit;
        const n = asUnitName && asUnitName !== unitName ? this.convert(value, unitName, asUnitName) : value;
        return (asUnit.prefix || "") + numService.format(n, numberFormat || asUnit.format) + (asUnit.suffix || "");
      }
    }
    return "";
  }

  public formatNumber(value: number | null | undefined, unitName: string, asUnitName?: string, numberFormat?: string): string {
    if (value != null) {
      const unit = this.getUnit(unitName);
      if (unit) {
        const numService = numberService.withLocale(this.locale);
        const asUnit = asUnitName ? this.getUnit(asUnitName) || unit : unit;
        const n = asUnitName && asUnitName !== unitName ? this.convert(value, unitName, asUnitName) : value;
        return numService.format(n, numberFormat || asUnit.format);
      }
    }
    return "";
  }

  public parseNumber(text: string | null | undefined, unitName: string, asUnitName?: string) {
    if (text) {
      const numService = numberService.withLocale(this.locale);
      const n = numService.parse(text);
      return n != null && asUnitName && asUnitName !== unitName ? this.convert(n, asUnitName, unitName) : n;
    }
    return null;
  }

  public convert(n: number | null | undefined, fromUnitName: string, toUnitName: string): number | null {
    if (n != null) {
      const fromUnit = this.getUnit(fromUnitName);
      const toUnit = this.getUnit(toUnitName);
      if (fromUnit && toUnit && fromUnit.type === toUnit.type) {
        return n * fromUnit.factor / toUnit.factor;
      }
    }
    return null;
  }

  private static toNumber(n?: any): number | null {
    if (typeof n === "number") {
      return n;
    } else if (typeof n === "string") {
      return Number.parseFloat(n);
    }
    return null;
  }

}

const unitService = new UnitService();

export {unitService};
