/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";


const propertiesBackground = "#fff";

const styles = (theme: IcmMuiTheme) => createStyles({
  container: {
    position: "relative",
    height: "calc(100% - 0px)",
  },

  editor: {
    height: "100%",
    marginRight: 260,
  },

  properties: {
    position: "absolute",
    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    top: 0,
    bottom: 0,
    right: 0,
    width: 260,
    zIndex: 10,
    borderLeft: "1px solid #ccc",
    overflow: "auto",

    "&:empty": {
      display: "none",
    },

    "&> .djs-properties-panel": {
      paddingBottom: 70,
      minHeight: "100%",
    },

    "&> .bpp-properties-panel": {
      backgroundColor: propertiesBackground,
      "& [type=\"text\"], & [contenteditable=\"true\"], & textarea, & select": {
        fontSize: "0.85rem",
      },

      "& select[multiple]": {
        overflowY: "hidden",
      },

      "& .bpp-field-description, & .bpp-error-message": {
        fontSize: "0.75rem",
      },
      "& ul.bpp-properties-tabs-links > li > a": {
        backgroundColor: propertiesBackground,
      },
    },
  },
});

export default styles;
