/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ApiUtil, FetchService} from "@icm/core-common";

import {EntityHistory} from "../generated/api";

interface IAuditLogFilter {
  type?: string,
  id?: string,
  number?: string
}

export class AuditLogApi {

  public static getAuditLog(filter: IAuditLogFilter): Promise<EntityHistory> {
    return FetchService.performGet(`auditlog/entityHistory?${ApiUtil.convertObjectToGetParams(filter)}`)
      .then(json => EntityHistory.fromData(json));
  }

  public static downloadAuditlog(filter: IAuditLogFilter, abortController?: AbortController): Promise<void> {
    return FetchService.performDownload(`auditlog/entityHistory/pdf?${ApiUtil.convertObjectToGetParams(filter)}`, undefined, {abortController});
  }
}
