/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../theme";


const styles = (theme: IcmMuiTheme) => createStyles({
  root: {
    width: "100%",
    margin: 0,
    padding: theme.spacing(1),
  },
  iconContainer: {
    padding: `${theme.spacing(1, 0)} !important`,
  },
  filterPaper: {
    padding: theme.spacing(1, 0.5, 1, 1),
  },
  filterActionsContainer: {
    padding: theme.spacing(1, 0, 0, 0),
  },
});

export default styles;
