/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {default as turfContains} from "@turf/boolean-contains";
import {default as turfEqual} from "@turf/boolean-equal";
import {default as turfIntersects} from "@turf/boolean-intersects";
import {default as turfCircle} from "@turf/circle";
import type {Geometry} from "geojson";

import {FilterConditionSettings, FilterOperator, FilterValueType} from "../../generated/api";
import {dateService} from "../DateService";

/**
 * ATTENTION: all comparisons with null are intentionally == or !=, as this also matches undefined!
 */

type Predicate = (o: unknown) => boolean;

/**
 * negates the given predicate
 * @param predicate the predicate
 */
function not(predicate: Predicate): Predicate {
  return (o: unknown) => !predicate(o);
}

/**
 * Factory for predicates of item filter.
 */
export interface ItemFilterPredicateFactory {
  supports(type: FilterValueType): boolean;
  get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined;
}

abstract class AbstractItemFilterPredicateFactory<T> implements ItemFilterPredicateFactory {

  public abstract supports(type: FilterValueType): boolean;

  public get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined {
    switch (operator) {
      case "EQUALS":
        return this.compareSingle((a, b) => a === b, filterValues, settings);
      case "NOT_EQUALS":
        return this.compareSingle((a, b) => a !== b, filterValues, settings);
      default:
        return undefined;
    }
  }

  protected compareSingle(comparer: (a: T, b: T) => boolean, filterValues: string[], settings?: FilterConditionSettings): Predicate {
    const filterValue = this.getSingleValue(filterValues, settings);
    return value => {
      const val = this.getValue(value, settings);
      return comparer(val, filterValue);
    };
  }

  protected compareDouble(comparer: (a: T, b1: T, b2: T) => boolean, filterValues: string[], settings?: FilterConditionSettings): Predicate {
    if (filterValues.length !== 2) {
      throw Error(`Invalid number of filter value values (${filterValues}, expected 2`);
    }
    const filterValue1 = this.getValue(filterValues[0], settings);
    const filterValue2 = this.getValue(filterValues[1], settings);
    return value => {
      const val = this.getValue(value, settings);
      return comparer(val, filterValue1, filterValue2);
    };
  }

  protected abstract getValue(value: unknown, settings?: FilterConditionSettings): T;

  protected getSingleValue(filterValues: string[], settings?: FilterConditionSettings): T {
    if (filterValues.length === 1) {
      return this.getValue(filterValues[0], settings);
    }
    throw Error(`Invalid number of filter value values (${filterValues}, expected 1`);
  }

  protected invalidValue(type: FilterValueType, value: unknown) {
    return Error(`Invalid ${type} value: ${value}`);
  }

  protected negate(predicate: Predicate): Predicate {
    return value => !predicate(value);
  }
}

abstract class AbstractComparableItemFilterPredicateFactory<T> extends AbstractItemFilterPredicateFactory<T> {

  public get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined {
    switch (operator) {
      case "IS_LESS":
        return this.compareSingle((a, b) => a != null && b != null && a < b, filterValues, settings);
      case "IS_LESS_EQUALS":
        return this.compareSingle((a, b) => a != null && b != null && a <= b, filterValues, settings);
      case "IS_GREATER":
        return this.compareSingle((a, b) => a != null && b != null && a > b, filterValues, settings);
      case "IS_GREATER_EQUALS":
        return this.compareSingle((a, b) => a != null && b != null && a >= b, filterValues, settings);
      case "IS_BETWEEN":
        return this.compareDouble((a, b1, b2) => a != null && (b1 == null || b1 <= a) && (b2 == null || a <= b2), filterValues, settings);
      case "NOT_IS_BETWEEN":
        return this.compareDouble((a, b1, b2) => !(a != null && (b1 == null || b1 <= a) && (b2 == null || a <= b2)), filterValues, settings);
      default:
        return super.get(operator, filterValues, settings);
    }
  }
}

class BooleanItemFilterPredicateFactory extends AbstractItemFilterPredicateFactory<boolean | null> {

  public supports(type: FilterValueType) {
    return type === "BOOLEAN";
  }

  public getValue(value: unknown, settings?: FilterConditionSettings): boolean | null {
    if (value == null) {
      return null;
    } else if (typeof value === "boolean") {
      return value;
    } else if (typeof value === "string") {
      // do NOT use Boolean(value), as this would be always true!
      return value.toLowerCase() === "true";
    }
    throw this.invalidValue("BOOLEAN", value);
  }
}

class NumberItemFilterPredicateFactory extends AbstractComparableItemFilterPredicateFactory<number | null> {

  public supports(type: FilterValueType) {
    return type === "NUMBER";
  }

  protected getValue(value: unknown, settings?: FilterConditionSettings): number | null {
    if (value == null) {
      return null;
    } else if (typeof value === "number") {
      return value;
    } else if (typeof value === "string") {
      return Number(value);
    }
    throw this.invalidValue("NUMBER", value);
  }
}

class StringItemFilterPredicateFactory extends AbstractComparableItemFilterPredicateFactory<string | null> {

  public supports(type: FilterValueType) {
    return type === "STRING";
  }

  public get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined {
    switch (operator) {
      case "STARTS_WITH":
        return this.compareSingle((a, b) => a != null && b != null && a.startsWith(b), filterValues, settings);
      case "NOT_STARTS_WITH":
        return this.compareSingle((a, b) => !(a != null && b != null && a.startsWith(b)), filterValues, settings);
      case "ENDS_WITH":
        return this.compareSingle((a, b) => a != null && b != null && a.endsWith(b), filterValues, settings);
      case "NOT_ENDS_WITH":
        return this.compareSingle((a, b) => !(a != null && b != null && a.endsWith(b)), filterValues, settings);
      case "CONTAINS":
        return this.compareSingle((a, b) => a != null && b != null && a.includes(b), filterValues, settings);
      case "NOT_CONTAINS":
        return this.compareSingle((a, b) => !(a != null && b != null && a.includes(b)), filterValues, settings);
      case "IS_IN":
        return this.compareSingle((a, b) => a != null && b != null && b.includes(a), filterValues, settings);
      case "NOT_IS_IN":
        return this.compareSingle((a, b) => !(a != null && b != null && b.includes(a)), filterValues, settings);
      case "MATCHES_REGEX":
        return this.matchesRegex(filterValues, settings);
      case "NOT_MATCHES_REGEX":
        return this.negate(this.matchesRegex(filterValues, settings));
      default:
        return super.get(operator, filterValues, settings);
    }
  }

  private matchesRegex(filterValues: string[], settings?: FilterConditionSettings): Predicate {
    if (filterValues.length !== 1 || filterValues[0] == null) {
      throw Error(`Invalid number of filter value values (${filterValues}, expected 1`);
    }
    // we must add ^$ to test the whole string and be compatible with Java implementation
    const regex = new RegExp(`^${filterValues[0]}$`, settings?.caseInsensitive ?? true ? "i" : "");
    return value => {
      const a = this.getValue(value, settings);
      return a != null && a.match(regex) !== null;
    };
  };

  protected getValue(value: unknown, settings?: FilterConditionSettings): string | null {
    if (value == null) {
      return "";
    } else if (typeof value === "string") {
      return settings?.caseInsensitive ?? true ? value.toLowerCase() : value;
    }
    throw this.invalidValue("STRING", value);
  }
}

abstract class AbstractDateItemFilterPredicateFactory extends AbstractComparableItemFilterPredicateFactory<Date | null> {

  public get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined {
    switch (operator) {
      case "EQUALS":
        // === or == does not work!
        return this.compareSingle((a, b) => (a == null && b == null) || (a != null && b != null && a.getTime() === b.getTime()),
          filterValues, settings);
      case "NOT_EQUALS":
        return this.negate(this.compareSingle((a, b) => (a == null && b == null) || (a != null && b != null && a.getTime() === b.getTime()),
          filterValues, settings));
      default:
        return super.get(operator, filterValues, settings);
    }
  }
}

class DateTimeItemFilterPredicateFactory extends AbstractDateItemFilterPredicateFactory {

  public supports(type: FilterValueType) {
    return type === "DATETIME" || type === "PERIOD";
  }

  protected getValue(value: unknown, settings?: FilterConditionSettings): Date | null {
    if (value == null) {
      return null;
    } else if (value instanceof Date) {
      return value;
    } else if (typeof value === "string") {
      if (value.includes("P")) {
        const date = dateService.getStartOfDayFromNow(value);
        if (date) {
          return date;
        }
      } else {
        const date = dateService.parse(value);
        if (date) {
          return date;
        }
      }
    }
    throw this.invalidValue("DATETIME", value);
  }
}

class DateItemFilterPredicateFactory extends AbstractDateItemFilterPredicateFactory {

  public supports(type: FilterValueType) {
    return type === "DATE";
  }

  protected getValue(value: unknown, settings?: FilterConditionSettings): Date | null {
    if (value == null) {
      return null;
    } else if (value instanceof Date) {
      return DateItemFilterPredicateFactory.removeTime(value);
    } else if (typeof value === "string") {
      const date = dateService.parse(value);
      if (date) {
        return DateItemFilterPredicateFactory.removeTime(date);
      }
    }
    throw this.invalidValue("DATE", value);
  }

  private static removeTime(value: Date): Date {
    value.setHours(0, 0, 0, 0);
    return value;
  }
}

class TimeItemFilterPredicateFactory extends AbstractDateItemFilterPredicateFactory {

  public supports(type: FilterValueType) {
    return type === "TIME";
  }

  protected getValue(value: unknown, settings?: FilterConditionSettings): Date | null {
    if (value == null) {
      return null;
    } else if (value instanceof Date) {
      return dateService.parseTime(dateService.formatTime(value, "MEDIUM"))!;
    } else if (typeof value === "string") {
      const date = dateService.parseTime(value);
      if (date) {
        return date;
      }
    }
    throw this.invalidValue("TIME", value);
  }
}

class EnumItemFilterPredicateFactory extends AbstractItemFilterPredicateFactory<string[] | null> {

  public supports(type: FilterValueType) {
    return type === "ENUM";
  }

  public get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined {
    switch (operator) {
      case "EQUALS":
        return this.equals(filterValues, settings);
      case "NOT_EQUALS":
        return not(this.equals(filterValues, settings));
      case "CONTAINS":
        return this.contains(filterValues, settings);
      case "NOT_CONTAINS":
        return not(this.contains(filterValues, settings));
      case "OVERLAPS":
        return this.overlaps(filterValues, settings);
      case "NOT_OVERLAPS":
        return not(this.overlaps(filterValues, settings));
      case "IS_IN":
        return this.isIn(filterValues, settings);
      case "NOT_IS_IN":
        return not(this.isIn(filterValues, settings));
      default:
        return undefined;
    }
  }

  private equals(filterValues: string[], settings?: FilterConditionSettings): Predicate {
    return this.compareArrays((a, b) => a !== null && a.every(e => b.includes(e)) && b.every(e => a.includes(e)), filterValues, settings);
  }

  private contains(filterValues: string[], settings?: FilterConditionSettings): Predicate {
    return this.compareArrays((a, b) => a !== null && b.every(e => a.includes(e)), filterValues, settings);
  }

  private overlaps(filterValues: string[], settings?: FilterConditionSettings): Predicate {
    return this.compareArrays((a, b) => a !== null && a.some(e => b.includes(e)), filterValues, settings);
  }

  private isIn(filterValues: string[], settings?: FilterConditionSettings): Predicate {
    return this.compareArrays((a, b) => a !== null && a.every(e => b.includes(e)), filterValues, settings);
  }

  protected compareArrays(comparer: (a: string[] | null, b: string[]) => boolean, filterValues: string[], settings?: FilterConditionSettings): Predicate {
    return value => comparer(this.getValue(value, settings), filterValues);
  }

  protected getValue(value: unknown, settings?: FilterConditionSettings): string[] | null {
    if (value == null) {
      return null;
    } else if (value instanceof Array) {
      const transformedElements = value
        .map(v => typeof v === "boolean" ? v.toString() : v)
        .map(v => typeof v === "number" ? String(v) : v)
        .map(v => typeof v === "string" ? v : null)
        .filter(v => v !== null);
      if (transformedElements.length !== value.length) {
        throw Error(`Invalid ENUM element value: ${value}`);
      }
      return transformedElements as string[];
    } else if (typeof value === "string") {
      return [value];
    }
    throw this.invalidValue("ENUM", value);
  }
}

type Circle = {
  type: "Circle";
  coordinates: [number, number];
  radius: string;
};

class GeometryItemFilterPredicateFactory extends AbstractItemFilterPredicateFactory<Geometry | null> {

  public supports(type: FilterValueType) {
    return type === "GEOMETRY";
  }

  public get(operator: FilterOperator, filterValues: string[], settings?: FilterConditionSettings): Predicate | undefined {
    switch (operator) {
      case "EQUALS": return this.compareSingle((a, b) => this.equals(a, b), filterValues, settings);
      case "NOT_EQUALS": return this.compareSingle((a, b) => !this.equals(a, b), filterValues, settings);
      case "CONTAINS": return this.compareSingle((a, b) => this.contains(a, b), filterValues, settings);
      case "NOT_CONTAINS": return this.compareSingle((a, b) => !this.contains(a, b), filterValues, settings);
      case "IS_IN": return this.compareSingle((a, b) => this.contains(b, a), filterValues, settings);
      case "NOT_IS_IN": return this.compareSingle((a, b) => !this.contains(b, a), filterValues, settings);
      case "OVERLAPS": return this.compareSingle((a, b) => this.overlaps(a, b), filterValues, settings);
      case "NOT_OVERLAPS": return this.compareSingle((a, b) => !this.overlaps(a, b), filterValues, settings);
      default:
        return undefined;
    }
  }

  private equals(a: Geometry | null, b: Geometry | null) {
    return a != null && b != null && a.type !== "GeometryCollection" && b.type !== "GeometryCollection" && turfEqual(a, b);
  }

  private contains(a: Geometry | null, b: Geometry | null) {
    try {
      return a != null && b != null && a.type !== "GeometryCollection" && b.type !== "GeometryCollection" && turfContains(a, b);
    } catch (e) {
      return false;
    }
  }

  private overlaps(a: Geometry | null, b: Geometry | null) {
    return a != null && b != null && a.type !== "GeometryCollection" && b.type !== "GeometryCollection" && turfIntersects(a, b);
  }

  protected getValue(value: unknown, settings?: FilterConditionSettings): Geometry | null {
    if (value == null) {
      return null;
    } else if (typeof value === "string") {
      const geometry: Geometry | Circle = JSON.parse(value);
      if (geometry.type === "Circle") {
        const radius = Number.parseFloat(geometry.radius); // parseFloat ignores trailing text like "m"
        return turfCircle(geometry.coordinates, radius / 1000.0, {steps: 64, units: "kilometers"}).geometry;
      } else {
        return geometry;
      }
    } else if (typeof value === "object" && value && "type" in value) {
      return value as Geometry;
    }
    throw this.invalidValue("GEOMETRY", value);
  }
}

const factories = [
  new BooleanItemFilterPredicateFactory(),
  new NumberItemFilterPredicateFactory(),
  new StringItemFilterPredicateFactory(),
  new DateItemFilterPredicateFactory(),
  new DateTimeItemFilterPredicateFactory(),
  new TimeItemFilterPredicateFactory(),
  new EnumItemFilterPredicateFactory(),
  new GeometryItemFilterPredicateFactory(),
];

export {factories as ItemFilterPredicateFactories};
