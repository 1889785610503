/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {BadgeColor} from "@icm/core-common";
import {Badge, BadgeProps, StyledComponentProps} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {PropsWithChildren, useMemo} from "react";

import styles from "./ColoredBadgeStyle";

const useStyles = makeStyles(styles);

type ColoredBadgeProps = {
  value: string | number | React.ReactNode
  color?: BadgeColor | "default"
  dot?: boolean
  upperNumberLimit?: number
  className?: string
};

export const ColoredBadge: React.FC<PropsWithChildren<ColoredBadgeProps & StyledComponentProps<"root" | "badge">>> = (props) => {
  const {value, color, dot, upperNumberLimit, children, className} = props;
  const classes = useStyles(props);
  const isBadgeVisible = useMemo(() => {
    if (typeof value === "number") {
      return value > 0;
    }
    return !!value;
  }, [value]);

  if (isBadgeVisible) {
    const badgeInvisible: boolean = color === undefined;
    const badgeColor: BadgeProps["color"] = color !== undefined && color !== "warning" ? color || "primary" : "primary";
    const badgeClass = color === "warning" ? classes.badgeWarning : "";
    const variant = dot ? "dot" : "standard";
    return (
      <Badge classes={{badge: `${badgeClass} ${classes.badge}`, root: classes.root}}
             className={className}
             color={badgeColor}
             invisible={badgeInvisible}
             badgeContent={value}
             variant={variant}
             max={upperNumberLimit}
      >
        {children}
      </Badge>
    );
  } else {
    return <>{children}</>;
  }
};
