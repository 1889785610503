/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:08.

/**
 * Generated from: at.cns.icm.track.backend.api.data.TrackDto
 */
export class Track {
    id?: string;
    type?: string;
    callsign?: string;
    category?: string;
    priority?: string;
    updatedAt?: Date;
    location?: GeoPosition;
    altitudeMeters?: number;
    speedMetersPerSecond?: number;
    headingAngle?: number;
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };

    static fromData(data: any): Track {
        if (!data) {
            return data;
        }
        const instance: Track = { id: data.id && __verifyIsString(data.id), type: data.type && __verifyIsString(data.type), callsign: data.callsign && __verifyIsString(data.callsign), category: data.category && __verifyIsString(data.category), priority: data.priority && __verifyIsString(data.priority), updatedAt: data.updatedAt && new Date(data.updatedAt), location: data.location && GeoPosition.fromData(data.location), altitudeMeters: data.altitudeMeters && __verifyIsNumber(data.altitudeMeters), speedMetersPerSecond: data.speedMetersPerSecond && __verifyIsNumber(data.speedMetersPerSecond), headingAngle: data.headingAngle && __verifyIsNumber(data.headingAngle), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','type','callsign','category','priority','updatedAt','location','altitudeMeters','speedMetersPerSecond','headingAngle','dynamicAttributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.GeoPositionDto
 */
export class GeoPosition {
    longitude?: number;
    latitude?: number;

    static fromData(data: any): GeoPosition {
        if (!data) {
            return data;
        }
        const instance: GeoPosition = { longitude: data.longitude && __verifyIsNumber(data.longitude), latitude: data.latitude && __verifyIsNumber(data.latitude) };
        return instance;
    }

    static getProperties(): string[] {
        return ['longitude','latitude'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.DynamicAttributeValueDto
 */
export class DynamicAttributeValue {
    type?: any;
    id?: any;
    version?: any;
    value?: any;
    valueDisplay?: any;

    static fromData(data: any): DynamicAttributeValue {
        if (!data) {
            return data;
        }
        const instance: DynamicAttributeValue = { type: data.type && data.type, id: data.id && data.id, version: data.version && data.version, value: data.value && data.value, valueDisplay: data.valueDisplay && data.valueDisplay };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','version','value','valueDisplay'];
    }
}

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
