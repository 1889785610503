/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:09.

/**
 * Generated from: at.cns.icm.topology.backend.api.data.StructureDto
 */
export class Structure {
    structureType: "ArrowStructure" | "CircleStructure" | "LineStructure" | "PointStructure" | "PolygonStructure" | "TextStructure";
    id?: string;
    creationId?: string;
    layerId?: string;
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };

    static fromData(data: any): Structure {
        if (!data) {
            return data;
        }
        const instance: Structure = { structureType: data.structureType, id: data.id && __verifyIsString(data.id), creationId: data.creationId && __verifyIsString(data.creationId), layerId: data.layerId && __verifyIsString(data.layerId), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes) };
        return instance;
    }

    static fromDataUnion(data: StructureUnion): StructureUnion {
        if (!data) {
            return data;
        }
        switch (data.structureType) {
            case "ArrowStructure":
                return ArrowStructure.fromData(data);
            case "CircleStructure":
                return CircleStructure.fromData(data);
            case "LineStructure":
                return LineStructure.fromData(data);
            case "PointStructure":
                return PointStructure.fromData(data);
            case "PolygonStructure":
                return PolygonStructure.fromData(data);
            case "TextStructure":
                return TextStructure.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['structureType','id','creationId','layerId','dynamicAttributes'];
    }
}

/**
 * Generated from: at.cns.icm.topology.backend.api.data.ArrowStructureDto
 */
export class ArrowStructure extends Structure {
    structureType: "ArrowStructure";
    geometry?: GeoJsonLineString;
    strokeColor?: string;
    strokeWidth?: number;
    strokeStyle?: StrokeStyle;
    text?: string;
    symbol?: ArrowSymbol;
    symbolSize?: number;

    static fromData(data: any): ArrowStructure {
        if (!data) {
            return data;
        }
        const instance: ArrowStructure = { ...(super.fromData(data) as ArrowStructure), geometry: data.geometry && GeoJsonLineString.fromData(data.geometry), strokeColor: data.strokeColor && __verifyIsString(data.strokeColor), strokeWidth: data.strokeWidth && __verifyIsNumber(data.strokeWidth), strokeStyle: data.strokeStyle && data.strokeStyle, text: data.text && __verifyIsString(data.text), symbol: data.symbol && data.symbol, symbolSize: data.symbolSize && __verifyIsNumber(data.symbolSize) };
        return instance;
    }

    static getProperties(): string[] {
        return ['structureType','geometry','strokeColor','strokeWidth','strokeStyle','text','symbol','symbolSize'];
    }
}

/**
 * Generated from: at.cns.icm.topology.backend.api.data.CircleStructureDto
 */
export class CircleStructure extends Structure {
    structureType: "CircleStructure";
    geometry?: GeoJsonPoint;
    radius?: number;
    strokeColor?: string;
    strokeWidth?: number;
    strokeStyle?: StrokeStyle;
    fillColor?: string;
    text?: string;

    static fromData(data: any): CircleStructure {
        if (!data) {
            return data;
        }
        const instance: CircleStructure = { ...(super.fromData(data) as CircleStructure), geometry: data.geometry && GeoJsonPoint.fromData(data.geometry), radius: data.radius && __verifyIsNumber(data.radius), strokeColor: data.strokeColor && __verifyIsString(data.strokeColor), strokeWidth: data.strokeWidth && __verifyIsNumber(data.strokeWidth), strokeStyle: data.strokeStyle && data.strokeStyle, fillColor: data.fillColor && __verifyIsString(data.fillColor), text: data.text && __verifyIsString(data.text) };
        return instance;
    }

    static getProperties(): string[] {
        return ['structureType','geometry','radius','strokeColor','strokeWidth','strokeStyle','fillColor','text'];
    }
}

/**
 * Generated from: at.cns.icm.topology.backend.api.data.LineStructureDto
 */
export class LineStructure extends Structure {
    structureType: "LineStructure";
    geometry?: GeoJsonLineString;
    strokeColor?: string;
    strokeWidth?: number;
    strokeStyle?: StrokeStyle;
    text?: string;

    static fromData(data: any): LineStructure {
        if (!data) {
            return data;
        }
        const instance: LineStructure = { ...(super.fromData(data) as LineStructure), geometry: data.geometry && GeoJsonLineString.fromData(data.geometry), strokeColor: data.strokeColor && __verifyIsString(data.strokeColor), strokeWidth: data.strokeWidth && __verifyIsNumber(data.strokeWidth), strokeStyle: data.strokeStyle && data.strokeStyle, text: data.text && __verifyIsString(data.text) };
        return instance;
    }

    static getProperties(): string[] {
        return ['structureType','geometry','strokeColor','strokeWidth','strokeStyle','text'];
    }
}

/**
 * Generated from: at.cns.icm.topology.backend.api.data.PointStructureDto
 */
export class PointStructure extends Structure {
    structureType: "PointStructure";
    geometry?: GeoJsonPoint;
    symbol?: string;
    symbolSize?: number;
    symbolColor?: string;
    text?: string;

    static fromData(data: any): PointStructure {
        if (!data) {
            return data;
        }
        const instance: PointStructure = { ...(super.fromData(data) as PointStructure), geometry: data.geometry && GeoJsonPoint.fromData(data.geometry), symbol: data.symbol && __verifyIsString(data.symbol), symbolSize: data.symbolSize && __verifyIsNumber(data.symbolSize), symbolColor: data.symbolColor && __verifyIsString(data.symbolColor), text: data.text && __verifyIsString(data.text) };
        return instance;
    }

    static getProperties(): string[] {
        return ['structureType','geometry','symbol','symbolSize','symbolColor','text'];
    }
}

/**
 * Generated from: at.cns.icm.topology.backend.api.data.PolygonStructureDto
 */
export class PolygonStructure extends Structure {
    structureType: "PolygonStructure";
    geometry?: GeoJsonPolygon;
    strokeColor?: string;
    strokeWidth?: number;
    strokeStyle?: StrokeStyle;
    fillColor?: string;
    text?: string;

    static fromData(data: any): PolygonStructure {
        if (!data) {
            return data;
        }
        const instance: PolygonStructure = { ...(super.fromData(data) as PolygonStructure), geometry: data.geometry && GeoJsonPolygon.fromData(data.geometry), strokeColor: data.strokeColor && __verifyIsString(data.strokeColor), strokeWidth: data.strokeWidth && __verifyIsNumber(data.strokeWidth), strokeStyle: data.strokeStyle && data.strokeStyle, fillColor: data.fillColor && __verifyIsString(data.fillColor), text: data.text && __verifyIsString(data.text) };
        return instance;
    }

    static getProperties(): string[] {
        return ['structureType','geometry','strokeColor','strokeWidth','strokeStyle','fillColor','text'];
    }
}

/**
 * Generated from: at.cns.icm.topology.backend.api.data.TextStructureDto
 */
export class TextStructure extends Structure {
    structureType: "TextStructure";
    geometry?: GeoJsonPoint;
    text?: string;
    textSize?: number;
    textWeight?: TextWeight;
    strokeColor?: string;
    strokeWidth?: number;
    fillColor?: string;
    textRotation?: number;
    textSkewX?: number;
    textSkewY?: number;

    static fromData(data: any): TextStructure {
        if (!data) {
            return data;
        }
        const instance: TextStructure = { ...(super.fromData(data) as TextStructure), geometry: data.geometry && GeoJsonPoint.fromData(data.geometry), text: data.text && __verifyIsString(data.text), textSize: data.textSize && __verifyIsNumber(data.textSize), textWeight: data.textWeight && data.textWeight, strokeColor: data.strokeColor && __verifyIsString(data.strokeColor), strokeWidth: data.strokeWidth && __verifyIsNumber(data.strokeWidth), fillColor: data.fillColor && __verifyIsString(data.fillColor), textRotation: data.textRotation && __verifyIsNumber(data.textRotation), textSkewX: data.textSkewX && __verifyIsNumber(data.textSkewX), textSkewY: data.textSkewY && __verifyIsNumber(data.textSkewY) };
        return instance;
    }

    static getProperties(): string[] {
        return ['structureType','geometry','text','textSize','textWeight','strokeColor','strokeWidth','fillColor','textRotation','textSkewX','textSkewY'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.DynamicAttributeValueDto
 */
export class DynamicAttributeValue {
    type?: any;
    id?: any;
    version?: any;
    value?: any;
    valueDisplay?: any;

    static fromData(data: any): DynamicAttributeValue {
        if (!data) {
            return data;
        }
        const instance: DynamicAttributeValue = { type: data.type && data.type, id: data.id && data.id, version: data.version && data.version, value: data.value && data.value, valueDisplay: data.valueDisplay && data.valueDisplay };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','version','value','valueDisplay'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonGeometryDto
 */
export class GeoJsonGeometry {
    type: "LineString" | "Point" | "Polygon" | "MultiLineString" | "MultiPoint" | "MultiPolygon";

    static fromData(data: any): GeoJsonGeometry {
        if (!data) {
            return data;
        }
        const instance: GeoJsonGeometry = { type: data.type };
        return instance;
    }

    static fromDataUnion(data: GeoJsonGeometryUnion): GeoJsonGeometryUnion {
        if (!data) {
            return data;
        }
        switch (data.type) {
            case "LineString":
                return GeoJsonLineString.fromData(data);
            case "MultiLineString":
                return GeoJsonMultiLineString.fromData(data);
            case "MultiPoint":
                return GeoJsonMultiPoint.fromData(data);
            case "MultiPolygon":
                return GeoJsonMultiPolygon.fromData(data);
            case "Point":
                return GeoJsonPoint.fromData(data);
            case "Polygon":
                return GeoJsonPolygon.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['type'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonLineStringDto
 */
export class GeoJsonLineString extends GeoJsonGeometry {
    type: "LineString";
    coordinates?: number[][];

    static fromData(data: any): GeoJsonLineString {
        if (!data) {
            return data;
        }
        const instance: GeoJsonLineString = { ...(super.fromData(data) as GeoJsonLineString), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__identity<number>()))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonPointDto
 */
export class GeoJsonPoint extends GeoJsonGeometry {
    type: "Point";
    coordinates?: number[];

    static fromData(data: any): GeoJsonPoint {
        if (!data) {
            return data;
        }
        const instance: GeoJsonPoint = { ...(super.fromData(data) as GeoJsonPoint), coordinates: data.coordinates && __getCopyArrayFn(__identity<number>())(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonPolygonDto
 */
export class GeoJsonPolygon extends GeoJsonGeometry {
    type: "Polygon";
    coordinates?: number[][][];

    static fromData(data: any): GeoJsonPolygon {
        if (!data) {
            return data;
        }
        const instance: GeoJsonPolygon = { ...(super.fromData(data) as GeoJsonPolygon), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__identity<number>())))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonMultiLineStringDto
 */
export class GeoJsonMultiLineString extends GeoJsonGeometry {
    type: "MultiLineString";
    coordinates?: number[][][];

    static fromData(data: any): GeoJsonMultiLineString {
        if (!data) {
            return data;
        }
        const instance: GeoJsonMultiLineString = { ...(super.fromData(data) as GeoJsonMultiLineString), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__identity<number>())))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonMultiPointDto
 */
export class GeoJsonMultiPoint extends GeoJsonGeometry {
    type: "MultiPoint";
    coordinates?: number[][];

    static fromData(data: any): GeoJsonMultiPoint {
        if (!data) {
            return data;
        }
        const instance: GeoJsonMultiPoint = { ...(super.fromData(data) as GeoJsonMultiPoint), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__identity<number>()))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonMultiPolygonDto
 */
export class GeoJsonMultiPolygon extends GeoJsonGeometry {
    type: "MultiPolygon";
    coordinates?: number[][][][];

    static fromData(data: any): GeoJsonMultiPolygon {
        if (!data) {
            return data;
        }
        const instance: GeoJsonMultiPolygon = { ...(super.fromData(data) as GeoJsonMultiPolygon), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__identity<number>()))))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

export type ArrowSymbol = "TRIANGLE_OUTLINE" | "TRIANGLE_FILLED";

export type StrokeStyle = "SOLID" | "DASHED" | "DOTTED";

export type TextWeight = "NORMAL" | "BOLD";

export type StructureUnion = ArrowStructure | CircleStructure | LineStructure | PointStructure | PolygonStructure | TextStructure;

export type GeoJsonGeometryUnion = GeoJsonLineString | GeoJsonMultiLineString | GeoJsonMultiPoint | GeoJsonMultiPolygon | GeoJsonPoint | GeoJsonPolygon;

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
