/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {useMemo} from "react";

import MessageKey from "../../generated/MessageKey";
import {useMessages} from "../../store";
import {ClientFilterSelection, isClientFilterSelection} from "./ClientFilterSelection";

/**
 * Fixed ID of any default filter selection.
 * This filter cannot be edited or deleted.
 */
export const FALLBACK_ID = "_FALLBACK_";

/**
 * Describes a filter that can not be modified.
 * The UI will apply this filter when nothing else was configured.
 */
export type FallbackClientFilterSelection = {
  id: typeof FALLBACK_ID,
} & ClientFilterSelection;

/**
 * A hook which returns the fallback filter for the given entity type.
 *
 * @param entityType
 */
export const useFallbackClientFilterSelection = (entityType?: string) => {
  const {getMessage} = useMessages();
  return useMemo(() => {
    return createFallbackFilter(entityType, getMessage(MessageKey.CORE.FILTER.FALLBACK));
  }, [entityType, getMessage]);
};

/**
 * Factory method to create a fallbackfilter.
 * Note: This is only public API because we want to use a NullSharedFilterController.
 * Components should use the useFallbackClientFilterSelection.
 *
 * @param entityType
 * @param name
 * @see useFallbackClientFilterSelection
 */
export function createFallbackFilter(entityType?: string, name?: string): ClientFilterSelection  {
  return {
    id: FALLBACK_ID,
    entityType: entityType ?? "_NO_ENTITY_",
    name: name ?? "",
    readOnly: true,
    notDeletable: false, // FIXME
    selectedFilters: [],
  };
}
export const isFallbackFilterSelection = (val: any): val is FallbackClientFilterSelection => {
  return isClientFilterSelection(val) && val.id === FALLBACK_ID;
};
