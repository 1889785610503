/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ModuleRegistrationOptions, registerNamedComponentFactory} from "@icm/core-common";
import {DEMO} from "@icm/demo-common";

import {DemoFormComponent} from "./src/components/form/demo/DemoFormComponent";
import packageInfo from "./src/generated/package.clone.json";


export const demoWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  runPostInitialization: (serviceRegistry) => {
    registerNamedComponentFactory(DEMO, DemoFormComponent);
  },
};
