/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActionBarActionItem,
  ActionBarItem,
  FCView,
  NavigationSideBar,
  ViewModel,
  useConnectionState,
} from "@icm/core-common";
import {List, Divider, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Box} from "@mui/system";
import * as React from "react";

import {IcmMuiIcon} from "../components";

export type NavigationSideBarViewModel = {
  printRequested: false,
}

export const DUMMY_NAVIGATION_SIDEBAR_MODEL: ViewModel<NavigationSideBarViewModel> = {
  id: "NAVIGATION_SIDEBAR_ID",
  uniqueHash: "NAVIGATION_SIDEBAR_HASH",
  viewType: "NAVIGATION_SIDEBAR",
  viewId: "NAVIGATION_SIDEBAR_VIEW_ID",
  viewModelData: {
    printRequested: false,
  },
};

export type NavigationSidebarViewProps = {
  dispatchAction: (action: ActionBarActionItem) => void
  navigationSideBarConfig: NavigationSideBar
}

export const NavigationSideBarView: FCView<NavigationSidebarViewProps, NavigationSideBarViewModel> = (props: NavigationSidebarViewProps) => {
  const {navigationSideBarConfig, dispatchAction} = props;

  return (
    <Box sx={{width: "100%"}}>
      <Box>
        <List>
          {navigationSideBarConfig.actions.map((a, index) => <NavigationSideBarButton key={index} actionItem={a} dispatchAction={dispatchAction} />)}
        </List>
      </Box>
    </Box>
  );
};

type NavigationSideBarButtonProps = {
  actionItem: ActionBarItem
  dispatchAction: (action: ActionBarActionItem) => void
}
const NavigationSideBarButton = (props: NavigationSideBarButtonProps) => {
  const {actionItem, dispatchAction} = props;
  const connected = useConnectionState();

  if (actionItem.itemType === "DIVIDER") {
    return <Divider />;
  }

  const disabled: boolean = actionItem.connectionRequired ? !connected : false;
  return (
    <ListItem button={true} onClick={() => dispatchAction(actionItem)} disabled={disabled}>
      <ListItemIcon>
        <IcmMuiIcon name={actionItem.icon} />
      </ListItemIcon>
      <ListItemText primary={actionItem.label} />
    </ListItem>
  );
};
