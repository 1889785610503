/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState} from "../types";

/**
 * The options carry an array of allotment sizes.
 * The length of the array equals the number of
 * TabGroups + Sidebars visible in the perspective.
 */
export type UpdateSizeOptions = {
  sizes: number[]
}

/**
 * Update sizes of the `Allotment.Panes` within the tabbed perspective.
 *
 * @param state
 * @param action
 * @constructor
 */
export const UpdateTabbedPerspectiveSizes = (
  state: IUiState,
  action: PayloadAction<UpdateSizeOptions>
) => {
  const perspective = state.perspective;
  if (perspective?.perspectiveType === "TABBED") {
    perspective.sizes = [];
    action.payload.sizes.forEach(s => perspective.sizes?.push(s));
  } else {
    console.warn("Can't update the tabGroups' sizes in non-tabbed perspective");
  }
};
