/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles, makeStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../../theme";

export const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  textField: {
    "& input": {
      textAlign: "right",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  inputContainer: {
    height: 32,
  },
  textContainer: {
    paddingLeft: theme.spacing(1),
  },
  numberInputContainer: {
    width: "100%",
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));
