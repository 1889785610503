/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FeatureConfiguration, FilterConfiguration, NotificationConfiguration, UiConfigurationDefinition} from "../generated/api";
import {FetchService} from "../service/FetchService";
import {IUiState} from "../store";
import {UserSettingsApi} from "./UserSettingsApi";

/** The key used to store user interface settings (= perspective) in the user settings entity. */
export const KEY_UI_STATE = "uiState";

export class UiConfigurationApi {

  public static getFeatureConfiguration(): Promise<FeatureConfiguration> {
    // do not use FeatureConfiguration.fromData(json) as this does not handle base types correctly
    return FetchService.performGet("core/featureConfiguration");
  }

  public static getUiConfiguration(): Promise<UiConfigurationDefinition> {
    return FetchService.performGet("core/uiConfiguration");
  }

  // If you want to call getStoredUiState, you probably have to pass the generic type like so: .getStoredUiState<MY_TYPE>()
  public static getStoredUiState(): Promise<IUiState | undefined> {
    return UserSettingsApi.getUserSetting(KEY_UI_STATE)
      .then(json => {
        if (json) {
          try {
            return JSON.parse(json);
          } catch (e) {
            console.error("Could not parse uiState from server", e);
            return undefined;
          }
        }
        console.log("No server UI state found.");
        return undefined;
      });
  }

  /**
   * Store a JSON serialization of the given state in the KEY_UI_STATE of the user settings.
   *
   * @see KEY_UI_STATE
   * @return A promise that resolves to true if saving succeeded.
   */
  public static async storeUiState(state: IUiState): Promise<boolean> {
    try {
      const serializedConfig = JSON.stringify(state);
      return await UserSettingsApi.postUserSetting(KEY_UI_STATE, serializedConfig);
    } catch (e) {
      console.warn("Could not store ui state.", e);
      return Promise.resolve(false);
    }
  }

  /**
   * Clear the KEY_UI_STATE stored in the user settings.
   *
   * @see KEY_UI_STATE
   * @return A promise that resolves to true if saving succeeded.
   */
  public static async resetUiState(): Promise<boolean> {
    try {
      return await UserSettingsApi.postUserSetting(KEY_UI_STATE, "");
    } catch (e) {
      console.warn("Could not reset ui state.", e);
      return Promise.resolve(false);
    }
  }

  public static getPublicProperties(): Promise<Record<string, string>> {
    return FetchService.performGet("core/publicProperties");
  }

  public static getFilterConfigurations(): Promise<FilterConfiguration[]> {
    return FetchService.performGet("core/filterConfigurations");
  }

  /**
   * @returns (optional) notification configuration
   */
  public static getNotificationConfiguration(): Promise<NotificationConfiguration | undefined> {
    return FetchService.performGet("core/notificationConfiguration", {
      catchableErrorCodes: [404],
    })
      .then(json => NotificationConfiguration.fromData(json))
      .catch(() => undefined);
  }
}
