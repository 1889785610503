/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmIconType} from "@icm/core-common";
import {Badge, BadgeProps, ButtonProps, IconButton, IconButtonProps, SvgIconProps, TooltipProps} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import * as React from "react";
import {PropsWithChildren} from "react";

import {IcmMuiTheme} from "../../../theme";
import {ConditionalTooltip, IcmMuiIcon} from "../../util";


export type IconButtonComponentProps = {
  type?: ButtonProps["type"]
  handleClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  icon?: IcmIconType | string | JSX.Element
  color?: "inherit" | "default" | "primary" | "secondary"
  disabled?: boolean
  tooltip?: TooltipProps["title"]
  tooltipPlacement?: TooltipProps["placement"]
  // @default medium
  padding?: "small" | "medium" | "large"
  fontSize?: SvgIconProps["fontSize"]
  classes?: Partial<Record<keyof ReturnType<typeof useStyles>, string>>
  // if set, the icon is set to this icon on hover
  iconOnHover?: IcmIconType | string | JSX.Element
  IconButtonProps?: Exclude<IconButtonProps, "onClick" | "disabled" | "color" | "type" | "style" | "className">
  // if given, the icon is wrapped in a badge
  BadgeProps?: BadgeProps
}

const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  root: {
    "&:hover $defaultIcon": {
      display: "none",
    },
    "&:hover $onHoverIcon": {
      display: "block",
    },
  },
  button: {},
  small: {
    padding: theme.spacing(0.625),
  },
  medium: {
    padding: theme.spacing(1),
  },
  large: {
    padding: theme.spacing(1.5),
  },
  defaultIcon: {},
  onHoverIcon: {
    display: "none",
  },
}));

export const IconButtonComponent = (props: IconButtonComponentProps) => {
  const {
    padding,
    handleClick,
    icon,
    iconOnHover,
    disabled,
    color,
    type,
    tooltip,
    tooltipPlacement,
    fontSize,
    IconButtonProps: iconButtonProps,
    BadgeProps: badgeProps,
  } = props;
  const classes = useStyles(props);

  return (
    <ConditionalTooltip title={tooltip} placement={tooltipPlacement}>
      {/* need span for tooltip to work in disabled state */}
      <span className={classes.root}>
        <IconButton type={type}
                    className={clsx({
                      [classes.button]: true,
                      [classes.small]: padding === "small",
                      [classes.medium]: padding === undefined || padding === "medium",
                      [classes.large]: padding === "large",
                    })}
                    disabled={disabled}
                    color={color}
                    onClick={handleClick}
                    {...iconButtonProps}
                    size="large"
                    aria-label={typeof tooltip === "string" ? tooltip : ""}
        >
          <ConditionalBadge condition={badgeProps !== undefined} {...badgeProps}>
            {typeof icon === "string" ? <IcmMuiIcon name={icon} fontSize={fontSize} className={clsx(iconOnHover && classes.defaultIcon)} /> : icon}
            {iconOnHover && (
              typeof iconOnHover === "string" ? <IcmMuiIcon name={iconOnHover} fontSize={fontSize} className={classes.onHoverIcon} /> : iconOnHover
            )}
          </ConditionalBadge>
        </IconButton>
      </span>
    </ConditionalTooltip>
  );
};

const ConditionalBadge = ({
  children,
  condition,
  ...props
}: PropsWithChildren<BadgeProps & { condition: boolean }>) => {
  if (condition) {
    return <Badge {...props}>{children}</Badge>;
  } else {
    return <>{children}</>;
  }
};
