/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";


const styles = (theme: IcmMuiTheme) => createStyles({
  root: {
    marginLeft: theme.spacing(3),
  },
  badge: {
    right: `calc(100% + ${theme.spacing(2)})`,
    top: "50%",
    marginTop: -1,
    padding: 0,
  },
});

export default styles;
