/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {createRefreshActionId, IdentifiableViewActionHandler} from "@icm/core-common";
import {REM_AUDIT_LOG} from "@icm/rem-rail-common";
import {useCallback} from "react";
import {useQueryClient} from "react-query";


import {useAuditLogQueryKey} from "./useAuditLogQueryKey";

/**
 * Create a ViewActionHandler which invalidates the query client cache key for
 * the auditlog of the given incident.
 *
 * @param incidentNumber
 */
export const useAuditLogRefreshHandler = (incidentNumber?: string): IdentifiableViewActionHandler<void> => {
  const queryKey = useAuditLogQueryKey(incidentNumber);
  const queryClient = useQueryClient();
  const run = useCallback(() => {
    queryClient.invalidateQueries(queryKey).then(_ => console.log("Invalidated ", queryKey));
  }, [queryClient, queryKey]);

  const key = createRefreshActionId([REM_AUDIT_LOG, incidentNumber]);
  return {
    key,
    run,
  };
};
