/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ColorScheme, Theme, ThemeConfiguration} from "../generated/api";
import {FetchService} from "../service";
import {IcmThemeConfiguration} from "../theme";


export function getThemeConfiguration(): Promise<IcmThemeConfiguration> {
  // do not use MenuConfiguration.fromData(json) as this does not handle base types correctly
  return FetchService.performGet("core/themeConfiguration")
    .then((themeConfig: ThemeConfiguration) => {
      return checkThemeConfig(themeConfig);
    });
}

export function checkThemeConfig(themeConfig: ThemeConfiguration): IcmThemeConfiguration {
  let errorMessage;
  if (themeConfig.defaultTheme && themeConfig.themes) {
    if (Object.values(themeConfig.themes).every((theme: Theme) => {
      return theme && theme.images
        && theme.palette && Object.values(theme.palette).every((colorScheme: ColorScheme) => colorScheme && colorScheme.main && colorScheme.contrast);
    })) {
      return themeConfig as IcmThemeConfiguration;
    } else {
      errorMessage = "Invalid theme configuration";
    }
  } else {
    errorMessage = "No defaultTheme or themes";
  }
  throw new Error(errorMessage);
}
