/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SortService} from "@icm/core-common";
import {Reducer} from "redux";
import {ActionType, getType} from "typesafe-actions";

import * as standbysActions from "./actions";
import {IRecurringStandbyState} from "./types";

export type RecurringStandbysAction = ActionType<typeof standbysActions>

const initialStandbyState: IRecurringStandbyState = {
  availableStandbyTeams: undefined,
  filterFrom: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30),
  filterTo: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 30, 23, 59, 59),
  standbyList: [],
  loading: true,
};

const recurringStandbyReducer: Reducer<IRecurringStandbyState> = (state = initialStandbyState, action: RecurringStandbysAction): IRecurringStandbyState => {
  switch (action.type) {
    case getType(standbysActions.fetchStandbyTeams.request): {
      return {...state, loading: true};
    }
    case getType(standbysActions.fetchStandbyTeams.success): {
      if (action.payload.length > 0) {
        const teams =  action.payload;
        teams.sort(SortService.createComparator(a => a.name!, (a, b) => a.localeCompare(b)));

        return {
          ...state,
          availableStandbyTeams: teams,
          standbyTeam: teams[0],
        };
      }

      return {
        ...state,
        loading: false,
        availableStandbyTeams: [],
        standbyTeam: undefined,
      };
    }
    case getType(standbysActions.fetchStandbyTeams.failure): {
      return {...state, loading: false};
    }
    case getType(standbysActions.fetchStandbyTeamMembers.request): {
      return {...state, loading: true};
    }
    case getType(standbysActions.fetchStandbyTeamMembers.success): {
      if (action.payload.length > 0) {
        const members =  action.payload;
        members.sort(SortService.createComparator(a => a.lastName));
        return {
          ...state,
          standbyTeamMembers: members,
        };
      }
      return {
        ...state,
        loading: false,
        standbyTeamMembers: [],
      };
    }
    case getType(standbysActions.fetchStandbyTeamMembers.failure): {
      return {...state, loading: false};
    }
    case getType(standbysActions.fetchRecurringStandbys.request): {
      return {
        ...state,
        loading: true,
        standbyTeam: action.payload.standbyTeam,
        filterFrom: action.payload.filterFrom,
        filterTo: action.payload.filterTo,
      };
    }
    case getType(standbysActions.fetchRecurringStandbys.success): {
      const standbyList = action.payload;
      standbyList.sort(SortService.createComparator(a => a.startDate?.getTime()));

      return {
        ...state,
        loading: false,
        standbyList,
      };
    }
    case getType(standbysActions.fetchRecurringStandbys.failure): {
      return {...state, loading: false};
    }
    case getType(standbysActions.submitRecurringStandby.request): {
      return {...state, error: undefined};
    }
    case getType(standbysActions.submitRecurringStandby.failure): {
      return {...state, error: action.payload};
    }
    case getType(standbysActions.deleteError): {
      return {...state, error: undefined};
    }
    default: {
      return state;
    }
  }
};

export {recurringStandbyReducer};
