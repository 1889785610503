/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {setEntityUpdateAsSeen} from "@icm/activitystream-common";
import {useEffect} from "react";
import {useDispatch} from "react-redux";

/**
 * An effect hook which dispatches an setEntityUpdateAsSeen action as soon as
 * entityId and type are available and any time they change.
 *
 * @param entityId the entity id
 * @param entityType the entity type
 */
export const useEntityUpdateResetEffect = (entityId?: string, entityType?: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (entityId && entityType) {
      dispatch(setEntityUpdateAsSeen({type: entityType, idOrIds: entityId}));
    }
  }, [dispatch, entityType, entityId]);
};
