/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchService} from "@icm/core-common";

import {ProcessDefinition, ProcessInstance} from "../generated/api";

class WorkflowApi {
  public static getProcessDefinition(key: string): Promise<ProcessDefinition> {
    return FetchService.performGet(`workflow/processDefinitionByKey/${key}`);
  }

  public static getStartableProcesses(): Promise<ProcessDefinition[]> {
    return FetchService.performGet("workflow/startableProcesses");
  }

  public static startProcess(key: string): Promise<ProcessInstance> {
    return FetchService.performPostWithJsonResult(`workflow/startProcess/${key}`, {});
  }

  public static completeTask(taskId: string, userActionId?: string): Promise<any> {
    let url = `workflow/completeTask/${taskId}`;
    if (userActionId) {
      url = `${url}?userActionId=${userActionId}`;
    }
    return FetchService.performPost(url, {});
  }
}

export {WorkflowApi};
