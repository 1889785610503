/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as H from "history";
import {useEffect, useMemo} from "react";
import {NavigateFunction} from "react-router";
import {useLocation, useNavigate} from "react-router-dom";

import {KnownPaths, Paths} from "../constant";


export type IcmHistoryLocationState = H.Location & {
  unauthorizedTargetUrl?: string
}

export type LocationProvider  =  () => H.Location;


export class NavigationService {

  private location: H.Location | undefined;

  private readonly navigationFunction: NavigateFunction;

  private readonly contextPath: string;

  private initialLocation: string;

  constructor(navigationFunction: NavigateFunction, contextPath = "") {
    console.log("Creating NavigationService with contextPath:", contextPath);
    this.navigationFunction = navigationFunction;
    this.contextPath = contextPath;
    this.initialLocation = Paths.MAIN;
  }

  /**
   * Get the location that was used when starting the web app or
   * entering an URL manually. The returned string contains
   * the pathnama and the search part of the location.
   */
  public getInitialLocation(): string {
    return this.initialLocation;
  }

  public setInitialLocation(location: H.Location) {
    this.initialLocation = location.pathname + location.search;
  }


  public setLocation(location: H.Location) {
    this.location = location;
  }

  public getLocationState(): object | undefined {
    const state = this.location?.state;
    if (typeof state === "object") {
      return state as object;
    }
    return undefined;
  }

  public navigate(url: string, parameters?: any): void {
    this.navigationFunction(url, {
      state: parameters,
    });
  }

  public replace(url: string, parameters?: any): void {
    this.navigationFunction(url, {
      replace: true,
      state: parameters,
    });
  }

  public getUrl(): string | undefined {
    return this.location ? this.location.pathname + this.location.search : undefined;
  }

  public pop(): void {
    this.navigationFunction(-1);
  }
}


export const useNavigationService = (contextPath: string) => {
  const navigate = useNavigate();
  const location: H.Location = useLocation();
  const navigationService = useMemo(() => {
    return new NavigationService(navigate, contextPath);
  }, [contextPath, navigate]);
  useEffect(() => {
    navigationService.setLocation(location);
    if (!KnownPaths.includes(location.pathname)) {
      navigationService.setInitialLocation(location);
    }
  }, [location, navigationService]);
  return navigationService;
};
