/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SecurityConfiguration} from "../../generated/api";
import {ConnectionMonitoringService} from "../connection";
import {FetchService} from "../FetchService";
import {NavigationService} from "../NavigationService";
import {PropertyService} from "../PropertyService";
import {createAuthenticationService, createTimeoutService, SecurityService, StartSecurityOptions} from "../security";
import {LoginStorageService} from "../security/LoginStorageService";
import {StorageService} from "../StorageService";
import {IcmServiceRegistry} from "./IcmServiceRegistry";

/**
 * A builder to create a "setup task" which creates an
 * initializes all essential services.
 */
export class CoreServiceSetup {
  private readonly serviceRegistry: IcmServiceRegistry;
  private storageService: StorageService;
  private navigationService: NavigationService;
  private propertyService: PropertyService;
  private securityConfigurationProvider: () => Promise<SecurityConfiguration>;
  private skipInitialization: boolean;
  private keycloakAdapter: StartSecurityOptions["adapter"];

  constructor(serviceRegistry: IcmServiceRegistry) {
    this.serviceRegistry = serviceRegistry;
  }

  public withStorageService(storageService: StorageService): CoreServiceSetup {
    this.storageService = storageService;
    return this;
  }

  public withKeycloakAdapter(adapter: StartSecurityOptions["adapter"]) {
    this.keycloakAdapter = adapter;
    return this;
  }

  public withNavigationService(navigationService: NavigationService): CoreServiceSetup {
    this.navigationService = navigationService;
    return this;
  }

  public withPropertyServiceService(propertyService: PropertyService): CoreServiceSetup {
    this.propertyService = propertyService;
    return this;
  }

  public withSecurityConfigurationProvider(securityConfigurationProvider: () => Promise<SecurityConfiguration>): CoreServiceSetup {
    this.securityConfigurationProvider = securityConfigurationProvider;
    return this;
  }

  public skipInitializationStep(skipInitialization: boolean): CoreServiceSetup {
    this.skipInitialization = skipInitialization;
    return this;
  }

  public async run(): Promise<void> {
    if (!this.navigationService) {
      return Promise.reject("navigationService not configured");
    }
    if (!this.storageService) {
      return Promise.reject("storageService not configured");
    }
    if (!this.securityConfigurationProvider) {
      return Promise.reject("securityConfigurationProvider not configured");
    }

    const securityConfiguration = await this.securityConfigurationProvider();

    this.serviceRegistry.register("STORAGE", this.storageService);
    this.serviceRegistry.register("NAVIGATION", this.navigationService);

    const connectionMonitoringService = new ConnectionMonitoringService();
    this.serviceRegistry.register("CONNECTION_MONITORING", connectionMonitoringService);

    this.serviceRegistry.register("PROPERTY", this.propertyService);
    this.propertyService.init().then();

    let securityService = this.serviceRegistry.getOptional("SECURITY");

    // make sure to not create the authentication/security services again, if created before: doing so does not work for
    // keycloak (role selection, no role selection, etc.)
    if (!securityService) {
      const loginStorage = new LoginStorageService(securityConfiguration, this.storageService);
      const timeoutService = createTimeoutService(
        securityConfiguration,
        loginStorage,
        this.storageService
      );
      const authenticationService = createAuthenticationService(securityConfiguration, loginStorage, this.navigationService);

      securityService = new SecurityService(
        authenticationService,
        loginStorage,
        this.navigationService,
        this.propertyService,
        securityConfiguration,
        timeoutService
      );
      this.serviceRegistry.register("SECURITY", securityService);
      const secService = securityService;
      FetchService.onMissingAuthorization((url: string) => secService.handleMissingAuthorization(url));
      FetchService.setDefaultsHeaderProvider(() => {
        return secService.getRequestHeaders();
      });
    }
    console.log("Core Services registered");

    if (!this.skipInitialization) {
      await securityService.startSecurity({requireLogin: true, adapter: this.keycloakAdapter});
    }

    return Promise.resolve();
  }
}
