/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {HttpHeaderName, Paths} from "../../constant";
import {UserDetails} from "../../data";
import {
  FeatureAuthorization,
  FeatureName,
  LoginConfiguration,
  LoginRequest,
  LoginStatus,
  PermissionType,
  Role,
  SecurityConfiguration,
  UserNamePrefixConfiguration,
  WhatsNewConfiguration,
} from "../../generated/api";
import {StoreService} from "../../store/store";
import {Condition, IdGenerator} from "../../util";
import {clientProfile} from "../ClientProfileService";
import {ExpressionEvaluationService} from "../ExpressionEvaluationService";
import {NavigationService} from "../NavigationService";
import {PlatformService, platformService} from "../PlatformService";
import {PropertyService} from "../PropertyService";
import {UserStateService} from "../UserStateService";
import {AuthenticationService, FailedLoginListener, FailedPasswordChangeListener} from "./AuthenticationService";
import {LoginStorageService} from "./LoginStorageService";
import {TimeoutService} from "./TimeoutService";
import {StartSecurityOptions} from "./types";


/**
 * @param justLoggedIn `true` if the user just logged in, `false` if the user was already logged in (and, e.g., pressed F5)
 * @returns a logout handler that is executed when the user logs out
 */
export type LoginHandler = (justLoggedIn: boolean) => Promise<LogoutHandler | undefined | void> | LogoutHandler | undefined | void;

/**
 * function that is executed when the user logs out after pre logout handlers
 */
export type LogoutHandler = () => void;

/**
 * Function that is executed before the user logout is triggered
 * and storage is cleared.
 *
 * Implementation Note: A handler should not depend on the result
 * of other PreLogoutHandlers because the order of execution is
 * not fixed.
 */
export type PreLogoutHandler = () => Promise<void | boolean>;

/**
 * A context that is used for evaluating visibility expression.
 */
export type VisibilityExpressionContext = {
  activeRoles: Role[],
  activeRoleNames: string,
  hasActiveRoleNameStartingWith: (prefix: string) => boolean,
  isVisible: Partial<Record<string, boolean>>,
  platform: PlatformService
}

/**
 * The security service is the main service for the entire application for anything that has to do with authentication,
 * authorization, login/logout behavior and similar use cases. Do not use services that the security service uses because
 * this adds additional complexity to the behavior of the security service and will be hard to debug.
 * Do not begin to create a cyclical dependency by adding the FetchService here.
 */
export class SecurityService {

  private activeRoles: Role[];

  private readonly authenticationService: AuthenticationService;

  private readonly initCompleted: Condition;

  private loggedIn: boolean;

  // The logoutHandler is the possible result of executing the loginHandler.
  private readonly loginLogoutHandlers: Record<string, {
    loginHandler: LoginHandler
    executed: boolean
    logoutHandler?: LogoutHandler
  }>;

  private readonly loginStorage: LoginStorageService;

  private readonly navigationService: NavigationService;

  private readonly preLogoutHandlers: Record<string, PreLogoutHandler>;

  private readonly propertyService: PropertyService;

  private readonly securityConfiguration: SecurityConfiguration;

  private readonly timeoutService: TimeoutService;

  /**
   * This is the constructor for the SecurityService.
   *
   * @param authenticationService a service that implement the AuthenticationService interface
   * @param loginStorage a service for storing user and role information
   * @param navigationService a service for navigation in the web application
   * @param propertyService the PropertyService for querying hidding roles
   * @param securityConfiguration the configuration for providing the what's new information and username prefix
   * @param timeoutService a timeout service for managing user inactivity
   */
  constructor(authenticationService: AuthenticationService,
              loginStorage: LoginStorageService,
              navigationService: NavigationService,
              propertyService: PropertyService,
              securityConfiguration: SecurityConfiguration,
              timeoutService: TimeoutService) {
    this.activeRoles = [];
    this.authenticationService = authenticationService;
    this.loginLogoutHandlers = {};
    this.loginStorage = loginStorage;
    this.navigationService = navigationService;
    this.preLogoutHandlers = {};
    this.propertyService = propertyService;
    this.securityConfiguration = securityConfiguration;
    this.timeoutService = timeoutService;
    this.logoutStorageCleanup = this.logoutStorageCleanup.bind(this);
    ExpressionEvaluationService.registerHelper("user", "userName", () => this.getCurrentUserName());
    ExpressionEvaluationService.registerHelper("user", "fullName", () => this.getCurrentUserFullName());
    ExpressionEvaluationService.registerHelper("user", "firstRoleName", () => this.getFirstActiveRoleName());
    ExpressionEvaluationService.registerHelper("user", "activeRoleNames", () => this.getActiveRolesNameString());
    ExpressionEvaluationService.registerHelper("user", "activeRoleDisplayNames", () => this.getActiveRolesDisplayNameString());
    this.initCompleted = new Condition("SECURITY_SERVICE_INITIALIZED");
  }

  /**
   * Add a failed login listener
   *
   * @param failedLoginListener the failed login listener
   */
  addFailedLoginListener(failedLoginListener: FailedLoginListener): void {
    this.authenticationService.addFailedLoginListener(failedLoginListener);
  }

  /**
   * Add a failed password change listener
   *
   * @param failedPasswordChangeListener the failed password change listener
   */
  addFailedPasswordChangeListener(failedPasswordChangeListener: FailedPasswordChangeListener): void {
    this.authenticationService.addFailedPasswordChangeListener(failedPasswordChangeListener);
  }

  /**
   * Add a login handler
   *
   * @param loginHandler the login handler
   * @return returns a unique key which identifies the added login handler
   */
  addLoginHandler(loginHandler: LoginHandler): string {
    const key = IdGenerator.randomUUID();
    // register
    this.loginLogoutHandlers[key] = {
      loginHandler,
      executed: false,
    };
    // execute immediately, if already logged in
    this.initCompleted.applies().then(() => {
      if (this.loggedIn) {
        this.executeLoginHandler(key, false);
      }
    });
    return key;
  }

  /**
   * Add a handler that will be called before logging out and before clearing the storage.
   * Implementation Note: A single handler must not depend on other handlers.
   *
   * @param handler the pre-logout handler
   * @return returns a unique key which identifies the added pre-logout handler
   */
  addPreLogoutHandler(handler: PreLogoutHandler): string {
    const key = IdGenerator.randomUUID();
    this.preLogoutHandlers[key] = handler;
    return key;
  }

  /**
   * Authenticate a user with the given username and password.
   *
   * @param username the username
   * @param plainPassword a password in plain text
   */
  async authenticateWithCredentials(username: string, plainPassword: string): Promise<void> {
    const loginResult = await this.authenticationService.authenticateWithCredentials(username, plainPassword);
    if (loginResult) {
      this.handleValidUserNameAndPassword(loginResult.roleSelectionRequired, loginResult.roles);
    }
  }

  /**
   * Change the password for the given user.
   *
   * @param username the username
   * @param newPassword the new password in plain text
   * @param currentPassword the current password in plain text
   */
  changePassword(username: string, newPassword: string, currentPassword?: string): void {
    this.authenticationService.changePassword(username, newPassword, currentPassword);
  }

  /**
   * Checks the login status. Subsequently, if the user is logged in, the procedure for a successful login is executed.
   */
  async checkLoginState(): Promise<void> {
    const loginResult = await this.authenticationService.checkLoginState();
    if (loginResult) {
      this.handleValidUserNameAndPassword(loginResult.roleSelectionRequired, loginResult.roles);
    }
  }

  /**
   * Evaluate the given expression with the visibility expression context.
   *
   * @param expression the expression to evaluate
   */
  evaluateExpression(expression: string): string {
    return ExpressionEvaluationService.evaluate(expression, this.getVisibilityExpressionContext());
  }

  /**
   * Evaluate a visibility expression which returns a boolean.
   *
   * @param visibilityExpression the visibility expression to evaluate
   * @param additionalContext an additional context for the expression
   * @return Returns true if the expression evaluates to true.
   */
  evaluateVisibilityRule(visibilityExpression?: string, additionalContext?: any): boolean {
    if (visibilityExpression) {
      try {
        return ExpressionEvaluationService.evaluateRule(visibilityExpression, this.getVisibilityExpressionContext(), additionalContext);
      } catch {
        console.warn("couldn't evaluate visibility expression, defaulting to false:", visibilityExpression);
        return false;
      }
    }
    return true;
  }

  /**
   * Returns the currently active roles for the user.
   *
   * @return the active roles
   */
  getActiveRoles(): Role[] {
    return this.activeRoles;
  }

  /**
   * Returns a string representation for the currently active roles using the display names.
   *
   * @return the string representing the active roles
   */
  getActiveRolesDisplayNameString(): string {
    return this.getActiveRolesString(SecurityService.getRoleDisplayName);
  }

  /**
   * Returns a string representation for the currently active roles.
   *
   * @return the string representing the active roles
   */
  getActiveRolesNameString(): string {
    return this.getActiveRolesString(SecurityService.getRoleName);
  }

  /**
   * Returns the current user details.
   *
   * @return the user details
   */
  getCurrentUserDetails(): UserDetails | undefined {
    return this.authenticationService.getCurrentUserDetails();
  }

  /**
   * Returns the full name of the current user as string
   *
   * @param includeUsername whether the username should be included
   * @return the full name of the user
   */
  getCurrentUserFullName(includeUsername: boolean = false): string {
    const user = this.getMandatoryAuthenticationService().getCurrentUserDetails();
    console.log("Retrieving full name for user", user);
    let result = "";
    if (user) {
      if (user.firstName) {
        result += user.firstName;
      }
      if (user.firstName && user.lastName) {
        result += " ";
      }
      if (user.lastName) {
        result += user.lastName;
      }
      if (result.length === 0) {
        result = user.userName || "";
      } else if (includeUsername && user.userName) {
        result += " - " + user.userName;
      }
    }
    return result;
  }

  /**
   *  Returns the full name and roles of the current user as string
   *
   * @param includeUsername whether the username should be included
   * @return the full name and roles of the user
   */
  getCurrentUserFullNameWithRoles(includeUsername: boolean = false): string {
    let result = this.getCurrentUserFullName(includeUsername);
    const rolesSuffix = this.getActiveRolesWithoutCompositesDisplayNameString();
    if (rolesSuffix.length > 0) {
      result = result + " (" + rolesSuffix + ")";
    }
    return result;
  }

  /**
   * Returns the first active role as string.
   *
   * @return the role name
   */
  getFirstActiveRoleName(): string | undefined {
    return this.getFirstActiveRoleString(SecurityService.getRoleName);
  }

  /**
   * Returns a map of headers which authorize and authenticate the current user.
   *
   * @return the map with the headers
   */
  async getRequestHeaders(): Promise<Record<string, string>> {
    if (this.authenticationService) {
      return this.authenticationService.getAuthenticationHeaders().then(async authenticationHeaders => {
        const headers = {...authenticationHeaders};
        const roleNamesHeaderValue = await this.getRoleNamesHeaderValue();
        headers[HttpHeaderName.USER_NAME] = this.getCurrentUserName() || "";
        if (roleNamesHeaderValue != null) {
          headers[HttpHeaderName.ACTIVE_ROLE_NAMES] = roleNamesHeaderValue;
        }
        return headers;
      });
    } else {
      return {};
    }
  }

  /**
   * Returns a subset of roles which are selectable.
   *
   * @param roles the list of roles to calculate the selectable roles from
   * @return a list of selectable roles
   */
  getSelectableRoles(roles: Role[]): Role[] {
    const rolesWithoutComposites = this.getRolesWithoutComposites(roles);
    const hiddenRoles = this.propertyService.getValue("core.authentication.hiddenRoles")?.split(",") || [];
    return rolesWithoutComposites.filter(role => !hiddenRoles.find(hiddenRoleName => hiddenRoleName === role.name));
  }

  /**
   * Return the username prefix configuration from the security configuration.
   *
   * @return the username prefix configuration
   */
  getUserNamePrefixConfiguration(): UserNamePrefixConfiguration | undefined {
    return this.getLoginConfiguration().userNamePrefixConfiguration;
  }

  /**
   * Returns the context for visibility expressions.
   *
   * @return the context
   */
  getVisibilityExpressionContext(): VisibilityExpressionContext {
    const activeRoles = this.activeRoles;
    const activeRoleNames = this.getActiveRolesNameString();
    const hasActiveRoleNameStartingWith = (prefix: string): boolean => !!this.activeRoles.map(SecurityService.getRoleName).find(arn => arn?.startsWith(prefix));
    const isVisible = this.getModulesVisibilityMap();
    const platform = platformService();
    return {
      activeRoles,
      activeRoleNames,
      hasActiveRoleNameStartingWith,
      isVisible,
      platform,
    };
  }

  /**
   * Returns the "what's new" configuration embedded in the security configuration.
   *
   * @return the "what's new" configuration
   */
  getWhatsNewConfiguration(): WhatsNewConfiguration | undefined {
    return this.getLoginConfiguration().whatsNewConfiguration;
  }

  /**
   * PICM-2384: Do we still need/want this?
   *
   * @param _url
   */
  handleMissingAuthorization(_url: string): Promise<void> {
    // Ignore for the time being.
    return Promise.resolve();
  }

  /**
   * Starts the procedure after skipping a password change
   *
   * @param loginRequest the login request
   * @param loginStatus the login status
   */
  async handleSkippedPasswordChange(loginRequest: LoginRequest, loginStatus: LoginStatus): Promise<void> {
    const loginResult = await this.authenticationService.handleSkippedPasswordChange(loginRequest, loginStatus);
    if (loginResult) {
      this.handleValidUserNameAndPassword(loginResult.roleSelectionRequired, loginResult.roles);
    }
  }

  /**
   * Returns if the feature can be used by the current user.
   *
   * @param name the name of the feature
   * @param permission the associated action that should be enabled for the feature
   * @return a boolean indicating whether the user is authorized
   */
  hasFeatureAuthorizationAtLeast(name: FeatureName, permission: PermissionType): boolean {
    if (this.activeRoles.length > 0) {
      for (const activeRole of this.activeRoles) {
        if (activeRole.featureAuthorizations) {
          const authorization = activeRole.featureAuthorizations.find(a => a.feature === name && SecurityService.hasAtLeastAuthorizationType(a, permission));
          if (!!authorization) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Returns of the role name is active.
   *
   * @param roleName the role name
   * @return a boolean indicating whether the role name is active
   */
  isActiveRoleName(roleName: string): boolean {
    for (const element of this.activeRoles) {
      const activeRoleName = SecurityService.getRoleName(element);
      if (activeRoleName === roleName) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns of a user is logged in.
   *
   * @return a boolean indicating whether the user is logged in
   */
  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  /**
   * Starts the login procedure.
   * @param parameters some obscure parameters that are used in the navigation service. Previously they were used after
   * an unauthorized request.
   */
  login(parameters?: any): Promise<void> {
    const authService = this.getMandatoryAuthenticationService();
    return authService.login(parameters);
  }

  /**
   * Starts the logout procedure.
   */
  async logout(): Promise<void> {
    this.loggedIn = false;
    const authService = this.getMandatoryAuthenticationService();
    try {
      await authService.logout(this.logoutStorageCleanup);
      this.timeoutService.stopWatchdog();
    } finally {
      await this.initCompleted.applies();
    }
  }

  /**
   * Remove a failed login listener.
   *
   * @param failedLoginListener the failed login listener
   */
  removeFailedLoginListener(failedLoginListener: FailedLoginListener): void {
    this.authenticationService.removeFailedLoginListener(failedLoginListener);
  }

  /**
   * Remove a failed password change listener
   *
   * @param failedPasswordChangeListener the failed password change listener
   */
  removeFailedPasswordChangeListener(failedPasswordChangeListener: FailedPasswordChangeListener): void {
    this.authenticationService.removeFailedPasswordChangeListener(failedPasswordChangeListener);
  }

  /**
   * Removes the login handler with the specified key and returns the corresponding logout handler (if existing)
   * The logout handler is not executed.
   *
   * @param key the unique identifier for the login handler previously returned by adding the login handler
   * @return the corresponding logout handler
   */
  removeLoginHandler(key: string): LogoutHandler | undefined {
    const logoutHandler = this.loginLogoutHandlers[key].logoutHandler;
    delete this.loginLogoutHandlers[key];
    return logoutHandler ?? undefined;
  }

  /**
   * Remove the handler with the given key (if present). Does nothing if the key is unknown.
   *
   * @param key the key of the handler to be removed (previously returned by
   *            the addPreLogoutHandler method
   * @return the removed pre-logout handler
   */
  removePreLogoutHandler(key: string): PreLogoutHandler | undefined {
    const h = this.preLogoutHandlers[key];
    delete this.preLogoutHandlers[key];
    return h;
  }

  /**
   * Select the active roles from the given list of user roles.
   * @param selectedRoles the selected roles
   * @param userRoles the roles of the user
   */
  selectActiveRoles(selectedRoles: Role[], userRoles: Role[]) {
    this.activeRoles = this.getActiveRolesFromSelectedRoles(selectedRoles, userRoles);
    console.log("Roles have been selected:", selectedRoles, "Setting roles active:", this.activeRoles);
    this.loginStorage.writeActiveRoles(this.activeRoles);
    this.handleSuccessfulLogin();
  }

  /**
   * This method signals to the security service that the user is still active.
   */
  signalUserActivity(): void {
    this.timeoutService.signalUserActivity();
  }

  /**
   * This method starts the security service and should only be called once.
   *
   * @param options additional options for initializing the used authentication service
   */
  async startSecurity(options: StartSecurityOptions): Promise<void> {
    const loginResult = await this.authenticationService.startSecurity(options);
    if (loginResult) {
      await this.timeoutService.startWatchdog();
      this.handleValidUserNameAndPassword(loginResult.roleSelectionRequired, loginResult.roles);
    }

    this.init();
    this.setupAutoLogout();
  }

  private clearActiveRoles() {
    this.activeRoles = [];
  }

  private clearStorage(isSsoUser: boolean) {
    this.loginStorage.clearStorage(isSsoUser);
    StoreService.resetStore();
  }

  private executeLoginHandler(key: string, justLoggedIn: boolean) {
    const {
      loginHandler,
      executed,
    } = this.loginLogoutHandlers[key];
    if (!executed) {
      this.loginLogoutHandlers[key].executed = true;
      Promise.resolve(loginHandler(justLoggedIn))
        .then(logoutHandler => {
          this.loginLogoutHandlers[key].logoutHandler = logoutHandler ?? undefined;
        });
    }
  }

  private executeLoginHandlers(justLoggedIn: boolean) {
    console.log("Notifying login handlers...");
    Object.keys(this.loginLogoutHandlers)
      .forEach(key => {
        this.executeLoginHandler(key, justLoggedIn);
      });
  }

  private executeLogoutHandlers() {
    Object.entries(this.loginLogoutHandlers)
      .forEach(([key, {
        executed,
        logoutHandler,
      }]) => {
        if (executed) {
          logoutHandler?.();
          this.loginLogoutHandlers[key].executed = false;
          delete this.loginLogoutHandlers[key].logoutHandler;
        }
      });
  }

  private getActiveRolesFromSelectedRoles(selectedRoles: Role[], userRoles: Role[]): Role[] {
    let result: Role[] = [];
    selectedRoles.forEach(selectedRole => {
      result = [...result, selectedRole];
      const compositeRoleNames = selectedRole.compositeRoleNames || [];
      compositeRoleNames.forEach(compositeRoleName => {
        const compositeRole = userRoles.find(r => r.name === compositeRoleName);
        if (compositeRole) {
          result = [...result, ...this.getActiveRolesFromSelectedRoles([compositeRole], userRoles)];
        }
      });
    });
    return result;
  }

  private getActiveRolesString(stringRetriever: (role: Role) => string | undefined): string {
    return SecurityService.getRolesString(this.activeRoles, stringRetriever);
  }

  private getActiveRolesWithoutCompositesDisplayNameString(): string {
    return this.getActiveRolesWithoutCompositesString(SecurityService.getRoleDisplayName);
  }

  private getActiveRolesWithoutCompositesString(stringRetriever: (role: Role) => string | undefined): string {
    const activeRolesWithoutComposites = this.getRolesWithoutComposites(this.activeRoles);
    return SecurityService.getRolesString(activeRolesWithoutComposites, stringRetriever);
  }

  private getCurrentUserName(): string | null {
    const user = this.getCurrentUserDetails();
    return user?.userName ? user?.userName : UserStateService.getInstance().getUserState().userName;
  }

  private getFirstActiveRoleString(stringRetriever: (role: Role) => string | undefined): string | undefined {
    if (this.activeRoles.length > 0) {
      return stringRetriever(this.activeRoles[0]);
    } else {
      return "";
    }
  }

  private getLoginConfiguration(): LoginConfiguration {
    return this.securityConfiguration.supportedAuthentications?.loginConfiguration || new LoginConfiguration();
  }

  private getMandatoryAuthenticationService(): AuthenticationService {
    if (this.authenticationService) {
      return this.authenticationService;
    } else {
      throw new Error("Authentication service not yet available");
    }
  }

  private getModulesVisibilityMap(): Partial<Record<string, boolean>> {
    const result: Partial<Record<string, boolean>> = {};
    this.activeRoles.forEach(activeRole => {
      if (activeRole.modules) {
        activeRole.modules.forEach(module => {
          result[module.name!] = true;
          result[module.name!.toLowerCase()] = true;
        });
      }
    });
    return result;
  }

  private static getRoleDisplayName(role: Role): string | undefined {
    return role.displayName || role.name;
  }

  private static getRoleName(role: Role): string | undefined {
    return role.name;
  }

  private async getRoleNamesHeaderValue(): Promise<string | null> {
    const roles: Role[] = await this.loginStorage.readActiveRoles() || [];
    return SecurityService.getRolesNameString(roles);
  }

  private static getRolesNameString(roles: Role[]): string {
    return SecurityService.getRolesString(roles, SecurityService.getRoleName);
  }

  private static getRolesString(roles: Role[], stringRetriever: (role: Role) => string | undefined): string {
    let result = "";
    for (const element of roles) {
      const roleString = stringRetriever(element);
      if (roleString) {
        if (result.length > 0) {
          result += ", ";
        }
        result += roleString;
      }
    }
    return result;
  }

  private getRolesWithoutComposites(roles: Role[]): Role[] {
    const isContainedInOtherRole = (role: Role) => roles.find(r => role.name && r.compositeRoleNames && r.compositeRoleNames.includes(role.name));
    return roles.filter(role => !isContainedInOtherRole(role));
  }

  private async logoutStorageCleanup(isSsoUser: boolean): Promise<void> {
    try {
      const prepareHandlers = Object.keys(this.preLogoutHandlers)
        .map(k => this.preLogoutHandlers[k]);
      await Promise.all(prepareHandlers.map(prepareLogout => {
        console.log("Running prepare logout", prepareLogout);
        return prepareLogout.call(this);
      }));
    } catch (e) {
      console.warn("Could not handle logout: ", e);
    } finally {
      this.clearActiveRoles();
      this.clearStorage(isSsoUser);
      this.executeLogoutHandlers();
    }
  }

  private handleSuccessfulLogin() {
    this.loggedIn = true;
    clientProfile.sendData();
    const authenticationService = this.getMandatoryAuthenticationService();
    this.timeoutService.startWatchdog().then();
    const loginTargetUrl = authenticationService.getAndClearLoginTargetUrl();
    console.log("Successful login, redirecting to", loginTargetUrl);
    if (loginTargetUrl) {
      // TODO PICM-1440 handle url handling on login
      console.warn("targetUrl not supported yet, not redirecting to", loginTargetUrl);
      this.navigationService.replace(loginTargetUrl);
    }
    this.executeLoginHandlers(true);
  }

  private handleValidUserNameAndPassword(roleSelectionRequired: boolean, userRoles: Role[]) {
    this.initCompleted.applies().then(() => {
      if (this.activeRoles.length === 0) {
        if (roleSelectionRequired) {
          const selectableRoles = this.getSelectableRoles(userRoles);
          if (selectableRoles.length === 1) {
            this.selectActiveRoles(selectableRoles, userRoles);
          } else {
            this.navigationService.navigate(Paths.ROLE_SELECTION);
          }
        } else {
          this.selectActiveRoles(userRoles, userRoles);
        }
      } else {
        this.handleSuccessfulLogin();
      }
    });
  }

  private static hasAtLeastAuthorizationType(authorization: FeatureAuthorization, permission: PermissionType) {
    switch (permission) {
      case "WRITE_READ":
        return authorization.permission === "WRITE_READ";
      case "READ_ONLY":
        return authorization.permission === "WRITE_READ" || authorization.permission === "READ_ONLY";
      default:
        return false;
    }
  }

  private init() {
    this.authenticationService.addFailedLoginListener(() => {
      this.clearActiveRoles();
    });

    this.loginStorage.readActiveRoles().then(ar => {
      console.log("Initializing Security Service for", ar, this.loginLogoutHandlers, this.isLoggedIn());
      this.activeRoles = ar || [];
      this.initCompleted.complete();
    });
  }

  private setupAutoLogout(): void {
    this.timeoutService.init();
    this.timeoutService.addTimeoutListener(() => this.logout());
  }
}
