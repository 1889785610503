/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";
import {useMemo, useState} from "react";

/**
 * Object to handle menu states.
 *
 * @see useMenuController
 */
export interface MenuController {

  isMenuOpen: boolean

  menuAnchor: HTMLElement | null

  openMenu(event: React.UIEvent<HTMLElement>): void

  closeMenu(event: object): void

}

/**
 * A hook that returns a controller which manages an internal state to open and close a menu attached to any
 * HTMLElement.
 */
export const useMenuController = (): MenuController => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  return useMemo(() => ({
    isMenuOpen: menuAnchor !== null,
    menuAnchor,
    openMenu: (event: React.UIEvent<HTMLElement>) => {
      setMenuAnchor(event.currentTarget);
      event.stopPropagation();
    },
    closeMenu(event: object) {
      setMenuAnchor(null);
      const ev = event as any;
      if (ev.stopPropagation) {
        ev?.stopPropagation();
      }
    },
  }), [menuAnchor]);
};
