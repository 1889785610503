/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "../../theme";

const styles = createStyles({

  container: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    padding: theme => theme.spacing(0.5),
    height: "100%",
  },

  containerHeader: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme => theme.spacing(0.5),
    zIndex: 0,
  },

  containerActions: {
    alignItems: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  containerBody: {
    flex: "1 1 auto",
    borderTop: 2,
    borderColor: "divider",
    overflow: "auto",
  },

});

export default styles;
