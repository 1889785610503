/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:04.

/**
 * Generated from: at.cns.icm.core.backend.api.data.BooleanResultDto
 */
export class BooleanResult {
    result?: boolean;

    static fromData(data: any): BooleanResult {
        if (!data) {
            return data;
        }
        const instance: BooleanResult = { result: data.result && __verifyIsBoolean(data.result) };
        return instance;
    }

    static getProperties(): string[] {
        return ['result'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.DynamicAttributeValueDto
 */
export class DynamicAttributeValue {
    type?: any;
    id?: any;
    version?: any;
    value?: any;
    valueDisplay?: any;

    static fromData(data: any): DynamicAttributeValue {
        if (!data) {
            return data;
        }
        const instance: DynamicAttributeValue = { type: data.type && data.type, id: data.id && data.id, version: data.version && data.version, value: data.value && data.value, valueDisplay: data.valueDisplay && data.valueDisplay };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','version','value','valueDisplay'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.GeneralErrorDto
 */
export class GeneralError {
    code?: string;
    subCode?: string;
    message?: string;
    stackTrace?: string;
    details?: string;
    objectId?: string;
    objectKey?: string;
    severity?: SeverityEnum;

    static fromData(data: any): GeneralError {
        if (!data) {
            return data;
        }
        const instance: GeneralError = { code: data.code && __verifyIsString(data.code), subCode: data.subCode && __verifyIsString(data.subCode), message: data.message && __verifyIsString(data.message), stackTrace: data.stackTrace && __verifyIsString(data.stackTrace), details: data.details && __verifyIsString(data.details), objectId: data.objectId && __verifyIsString(data.objectId), objectKey: data.objectKey && __verifyIsString(data.objectKey), severity: data.severity && data.severity };
        return instance;
    }

    static getProperties(): string[] {
        return ['code','subCode','message','stackTrace','details','objectId','objectKey','severity'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.GeoPositionDto
 */
export class GeoPosition {
    longitude?: number;
    latitude?: number;

    static fromData(data: any): GeoPosition {
        if (!data) {
            return data;
        }
        const instance: GeoPosition = { longitude: data.longitude && __verifyIsNumber(data.longitude), latitude: data.latitude && __verifyIsNumber(data.latitude) };
        return instance;
    }

    static getProperties(): string[] {
        return ['longitude','latitude'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.IdAndWarningsDto
 */
export class IdAndWarnings {
    id?: string;
    warnings?: string[];

    static fromData(data: any): IdAndWarnings {
        if (!data) {
            return data;
        }
        const instance: IdAndWarnings = { id: data.id && __verifyIsString(data.id), warnings: data.warnings && __getCopyArrayFn(__identity<string>())(data.warnings) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','warnings'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.LocationDto
 */
export class Location {
    id?: string;
    name?: string;
    description?: string;
    type?: string;
    subType?: string;
    geometry?: GeoJsonGeometryUnion;
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };

    static fromData(data: any): Location {
        if (!data) {
            return data;
        }
        const instance: Location = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name), description: data.description && __verifyIsString(data.description), type: data.type && __verifyIsString(data.type), subType: data.subType && __verifyIsString(data.subType), geometry: data.geometry && GeoJsonGeometry.fromDataUnion(data.geometry), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name','description','type','subType','geometry','dynamicAttributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.MessageDto
 */
export class Message {
    code?: string;
    message?: string;

    static fromData(data: any): Message {
        if (!data) {
            return data;
        }
        const instance: Message = { code: data.code && __verifyIsString(data.code), message: data.message && __verifyIsString(data.message) };
        return instance;
    }

    static getProperties(): string[] {
        return ['code','message'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.MobileConfigurationDto
 */
export class MobileConfiguration {
    icmBackendUrl?: string;
    fcmApiKey?: string;
    fcmApplicationId?: string;
    fcmProjectId?: string;
    fcmStorageBucket?: string;
    fcmGcmSenderId?: string;
    logLevel?: LogLevelEnum;
    logRetentionDays?: number;

    static fromData(data: any): MobileConfiguration {
        if (!data) {
            return data;
        }
        const instance: MobileConfiguration = { icmBackendUrl: data.icmBackendUrl && __verifyIsString(data.icmBackendUrl), fcmApiKey: data.fcmApiKey && __verifyIsString(data.fcmApiKey), fcmApplicationId: data.fcmApplicationId && __verifyIsString(data.fcmApplicationId), fcmProjectId: data.fcmProjectId && __verifyIsString(data.fcmProjectId), fcmStorageBucket: data.fcmStorageBucket && __verifyIsString(data.fcmStorageBucket), fcmGcmSenderId: data.fcmGcmSenderId && __verifyIsString(data.fcmGcmSenderId), logLevel: data.logLevel && data.logLevel, logRetentionDays: data.logRetentionDays && __verifyIsNumber(data.logRetentionDays) };
        return instance;
    }

    static getProperties(): string[] {
        return ['icmBackendUrl','fcmApiKey','fcmApplicationId','fcmProjectId','fcmStorageBucket','fcmGcmSenderId','logLevel','logRetentionDays'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.OrganizationalUnitDto
 */
export class OrganizationalUnit {
    id?: string;
    name?: string;

    static fromData(data: any): OrganizationalUnit {
        if (!data) {
            return data;
        }
        const instance: OrganizationalUnit = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.PersonDto
 */
export class Person {
    id?: string;
    firstName?: string;
    lastName?: string;
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };

    static fromData(data: any): Person {
        if (!data) {
            return data;
        }
        const instance: Person = { id: data.id && __verifyIsString(data.id), firstName: data.firstName && __verifyIsString(data.firstName), lastName: data.lastName && __verifyIsString(data.lastName), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','firstName','lastName','dynamicAttributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.PingDto
 */
export class Ping {
    id?: string;

    static fromData(data: any): Ping {
        if (!data) {
            return data;
        }
        const instance: Ping = { id: data.id && __verifyIsString(data.id) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.PongDto
 */
export class Pong {
    pingId?: string;

    static fromData(data: any): Pong {
        if (!data) {
            return data;
        }
        const instance: Pong = { pingId: data.pingId && __verifyIsString(data.pingId) };
        return instance;
    }

    static getProperties(): string[] {
        return ['pingId'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.PossibleValueDto
 */
export class PossibleValue {
    label?: string;
    value?: string;

    static fromData(data: any): PossibleValue {
        if (!data) {
            return data;
        }
        const instance: PossibleValue = { label: data.label && __verifyIsString(data.label), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['label','value'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.RoleAccessRestrictionDto
 */
export class RoleAccessRestriction {
    allowedRoles?: string[];

    static fromData(data: any): RoleAccessRestriction {
        if (!data) {
            return data;
        }
        const instance: RoleAccessRestriction = { allowedRoles: data.allowedRoles && __getCopyArrayFn(__identity<string>())(data.allowedRoles) };
        return instance;
    }

    static getProperties(): string[] {
        return ['allowedRoles'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.SideBarDto
 */
export class SideBar {
    sideBarRef?: string;

    static fromData(data: any): SideBar {
        if (!data) {
            return data;
        }
        const instance: SideBar = { sideBarRef: data.sideBarRef && __verifyIsString(data.sideBarRef) };
        return instance;
    }

    static getProperties(): string[] {
        return ['sideBarRef'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.UserNamePrefixConfigurationDto
 */
export class UserNamePrefixConfiguration {
    label?: string;
    userNamePrefixes?: UserNamePrefix[];

    static fromData(data: any): UserNamePrefixConfiguration {
        if (!data) {
            return data;
        }
        const instance: UserNamePrefixConfiguration = { label: data.label && __verifyIsString(data.label), userNamePrefixes: data.userNamePrefixes && __getCopyArrayFn(UserNamePrefix.fromData)(data.userNamePrefixes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['label','userNamePrefixes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.UserNamePrefixDto
 */
export class UserNamePrefix {
    default?: boolean;
    label?: string;
    prefix?: string;

    static fromData(data: any): UserNamePrefix {
        if (!data) {
            return data;
        }
        const instance: UserNamePrefix = { default: data.default && __verifyIsBoolean(data.default), label: data.label && __verifyIsString(data.label), prefix: data.prefix && __verifyIsString(data.prefix) };
        return instance;
    }

    static getProperties(): string[] {
        return ['default','label','prefix'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.counter.CounterConfigurationDto
 */
export class CounterConfiguration {
    counterName?: string;
    pattern?: string;

    static fromData(data: any): CounterConfiguration {
        if (!data) {
            return data;
        }
        const instance: CounterConfiguration = { counterName: data.counterName && __verifyIsString(data.counterName), pattern: data.pattern && __verifyIsString(data.pattern) };
        return instance;
    }

    static getProperties(): string[] {
        return ['counterName','pattern'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.counter.CounterConfigurationListDto
 */
export class CounterConfigurationList {
    counterConfigurations?: CounterConfiguration[];

    static fromData(data: any): CounterConfigurationList {
        if (!data) {
            return data;
        }
        const instance: CounterConfigurationList = { counterConfigurations: data.counterConfigurations && __getCopyArrayFn(CounterConfiguration.fromData)(data.counterConfigurations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['counterConfigurations'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.feature.FeatureConfigurationDto
 */
export class FeatureConfiguration {
    openWebsocket?: boolean;

    static fromData(data: any): FeatureConfiguration {
        if (!data) {
            return data;
        }
        const instance: FeatureConfiguration = { openWebsocket: data.openWebsocket && __verifyIsBoolean(data.openWebsocket) };
        return instance;
    }

    static getProperties(): string[] {
        return ['openWebsocket'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filedata.FileDetailsDto
 */
export class FileDetails {
    id?: string;
    name?: string;
    contentType?: string;
    size?: number;
    deleted?: boolean;
    createdAt?: Date;
    createdBy?: string;
    textContent?: string;

    static fromData(data: any): FileDetails {
        if (!data) {
            return data;
        }
        const instance: FileDetails = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name), contentType: data.contentType && __verifyIsString(data.contentType), size: data.size && __verifyIsNumber(data.size), deleted: data.deleted && __verifyIsBoolean(data.deleted), createdAt: data.createdAt && new Date(data.createdAt), createdBy: data.createdBy && __verifyIsString(data.createdBy), textContent: data.textContent && __verifyIsString(data.textContent) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name','contentType','size','deleted','createdAt','createdBy','textContent'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filedata.FileReferenceDto
 */
export class FileReference {
    id?: string;
    name?: string;

    static fromData(data: any): FileReference {
        if (!data) {
            return data;
        }
        const instance: FileReference = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.CollectionModifierDto
 */
export class CollectionModifier {
    propertyOfCollectionToFilterOn?: string;
    listFilter?: ListFilter;

    static fromData(data: any): CollectionModifier {
        if (!data) {
            return data;
        }
        const instance: CollectionModifier = { propertyOfCollectionToFilterOn: data.propertyOfCollectionToFilterOn && __verifyIsString(data.propertyOfCollectionToFilterOn), listFilter: data.listFilter && ListFilter.fromData(data.listFilter) };
        return instance;
    }

    static getProperties(): string[] {
        return ['propertyOfCollectionToFilterOn','listFilter'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterConditionBaseDto
 */
export class FilterConditionBase {
    conditionType: "GROUP" | "ITEM";

    static fromData(data: any): FilterConditionBase {
        if (!data) {
            return data;
        }
        const instance: FilterConditionBase = { conditionType: data.conditionType };
        return instance;
    }

    static fromDataUnion(data: FilterConditionBaseUnion): FilterConditionBaseUnion {
        if (!data) {
            return data;
        }
        switch (data.conditionType) {
            case "GROUP":
                return FilterConditionGroup.fromData(data);
            case "ITEM":
                return FilterConditionItem.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['conditionType'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterConditionGroupDto
 */
export class FilterConditionGroup extends FilterConditionBase {
    conditionType: "GROUP";
    logicalOperator?: LogicalOperator;
    filterConditions?: FilterConditionBaseUnion[];

    static fromData(data: any): FilterConditionGroup {
        if (!data) {
            return data;
        }
        const instance: FilterConditionGroup = { ...(super.fromData(data) as FilterConditionGroup), logicalOperator: data.logicalOperator && data.logicalOperator, filterConditions: data.filterConditions && __getCopyArrayFn(FilterConditionBase.fromDataUnion)(data.filterConditions) };
        return instance;
    }

    static getProperties(): string[] {
        return ['conditionType','logicalOperator','filterConditions'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterConditionItemDto
 */
export class FilterConditionItem extends FilterConditionBase {
    conditionType: "ITEM";
    property?: string;
    values?: string[];
    valueType?: FilterValueType;
    operator?: FilterOperator;
    settings?: FilterConditionSettings;
    metadata?: { [index: string]: any };

    static fromData(data: any): FilterConditionItem {
        if (!data) {
            return data;
        }
        const instance: FilterConditionItem = { ...(super.fromData(data) as FilterConditionItem), property: data.property && __verifyIsString(data.property), values: data.values && __getCopyArrayFn(__identity<string>())(data.values), valueType: data.valueType && data.valueType, operator: data.operator && data.operator, settings: data.settings && FilterConditionSettings.fromData(data.settings), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['conditionType','property','values','valueType','operator','settings','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterConditionSettingsDto
 */
export class FilterConditionSettings {
    caseInsensitive?: boolean;
    widgetValueBinding?: string;

    static fromData(data: any): FilterConditionSettings {
        if (!data) {
            return data;
        }
        const instance: FilterConditionSettings = { caseInsensitive: data.caseInsensitive && __verifyIsBoolean(data.caseInsensitive), widgetValueBinding: data.widgetValueBinding && __verifyIsString(data.widgetValueBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['caseInsensitive','widgetValueBinding'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterConfigurationDto
 */
export class FilterConfiguration {
    id?: string;
    listFilterDefaults?: ListFilter;
    filterOptionsSourceUrl?: string;
    filterOptions?: string[];
    entityType?: string;
    viewSpecificFilterAdaptations?: ViewSpecificFilterAdaptation[];

    static fromData(data: any): FilterConfiguration {
        if (!data) {
            return data;
        }
        const instance: FilterConfiguration = { id: data.id && __verifyIsString(data.id), listFilterDefaults: data.listFilterDefaults && ListFilter.fromData(data.listFilterDefaults), filterOptionsSourceUrl: data.filterOptionsSourceUrl && __verifyIsString(data.filterOptionsSourceUrl), filterOptions: data.filterOptions && __getCopyArrayFn(__identity<string>())(data.filterOptions), entityType: data.entityType && __verifyIsString(data.entityType), viewSpecificFilterAdaptations: data.viewSpecificFilterAdaptations && __getCopyArrayFn(ViewSpecificFilterAdaptation.fromData)(data.viewSpecificFilterAdaptations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','listFilterDefaults','filterOptionsSourceUrl','filterOptions','entityType','viewSpecificFilterAdaptations'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterOperationDto
 */
export class FilterOperation {
    filterUserOperation?: FilterUserOperation;
    filterUserOperationCustomLabel?: string;
    filterValueType?: FilterValueType;
    defaultValues?: string[];
    filterValueCardinality?: FilterValueCardinality;
    filterWidgetSettings?: FilterWidgetSettings;
    filterCondition?: FilterConditionBaseUnion;
    collectionModifiers?: CollectionModifier[];

    static fromData(data: any): FilterOperation {
        if (!data) {
            return data;
        }
        const instance: FilterOperation = { filterUserOperation: data.filterUserOperation && data.filterUserOperation, filterUserOperationCustomLabel: data.filterUserOperationCustomLabel && __verifyIsString(data.filterUserOperationCustomLabel), filterValueType: data.filterValueType && data.filterValueType, defaultValues: data.defaultValues && __getCopyArrayFn(__identity<string>())(data.defaultValues), filterValueCardinality: data.filterValueCardinality && data.filterValueCardinality, filterWidgetSettings: data.filterWidgetSettings && FilterWidgetSettings.fromData(data.filterWidgetSettings), filterCondition: data.filterCondition && FilterConditionBase.fromDataUnion(data.filterCondition), collectionModifiers: data.collectionModifiers && __getCopyArrayFn(CollectionModifier.fromData)(data.collectionModifiers) };
        return instance;
    }

    static getProperties(): string[] {
        return ['filterUserOperation','filterUserOperationCustomLabel','filterValueType','defaultValues','filterValueCardinality','filterWidgetSettings','filterCondition','collectionModifiers'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterOptionDto
 */
export class FilterOption {
    id?: string;
    optionType?: FilterOptionType;
    label?: string;
    descriptionLabel?: string;
    defaultFilterOperation?: FilterOperation;
    filterOperations?: FilterOperation[];

    static fromData(data: any): FilterOption {
        if (!data) {
            return data;
        }
        const instance: FilterOption = { id: data.id && __verifyIsString(data.id), optionType: data.optionType && data.optionType, label: data.label && __verifyIsString(data.label), descriptionLabel: data.descriptionLabel && __verifyIsString(data.descriptionLabel), defaultFilterOperation: data.defaultFilterOperation && FilterOperation.fromData(data.defaultFilterOperation), filterOperations: data.filterOperations && __getCopyArrayFn(FilterOperation.fromData)(data.filterOperations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','optionType','label','descriptionLabel','defaultFilterOperation','filterOperations'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterSelectionDto
 */
export class FilterSelection {
    id?: string;
    name?: string;
    entityType?: string;
    readOnly?: boolean;
    notDeletable?: boolean;
    selectedFilters?: FilterSelectionItem[];

    static fromData(data: any): FilterSelection {
        if (!data) {
            return data;
        }
        const instance: FilterSelection = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name), entityType: data.entityType && __verifyIsString(data.entityType), readOnly: data.readOnly && __verifyIsBoolean(data.readOnly), notDeletable: data.notDeletable && __verifyIsBoolean(data.notDeletable), selectedFilters: data.selectedFilters && __getCopyArrayFn(FilterSelectionItem.fromData)(data.selectedFilters) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name','entityType','readOnly','notDeletable','selectedFilters'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterSelectionItemDto
 */
export class FilterSelectionItem {
    filterOptionId?: string;
    filterUserOperation?: FilterUserOperation;
    filterValueType?: FilterValueType;
    values?: string[];

    static fromData(data: any): FilterSelectionItem {
        if (!data) {
            return data;
        }
        const instance: FilterSelectionItem = { filterOptionId: data.filterOptionId && __verifyIsString(data.filterOptionId), filterUserOperation: data.filterUserOperation && data.filterUserOperation, filterValueType: data.filterValueType && data.filterValueType, values: data.values && __getCopyArrayFn(__identity<string>())(data.values) };
        return instance;
    }

    static getProperties(): string[] {
        return ['filterOptionId','filterUserOperation','filterValueType','values'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.FilterWidgetSettingsDto
 */
export class FilterWidgetSettings {
    format?: string;
    hideWidget?: boolean;
    withSeconds?: boolean;
    possibleValues?: string[];
    possibleValuesUrl?: string;
    possibleValuesDisplayBinding?: string;
    possibleValuesValueBinding?: string;

    static fromData(data: any): FilterWidgetSettings {
        if (!data) {
            return data;
        }
        const instance: FilterWidgetSettings = { format: data.format && __verifyIsString(data.format), hideWidget: data.hideWidget && __verifyIsBoolean(data.hideWidget), withSeconds: data.withSeconds && __verifyIsBoolean(data.withSeconds), possibleValues: data.possibleValues && __getCopyArrayFn(__identity<string>())(data.possibleValues), possibleValuesUrl: data.possibleValuesUrl && __verifyIsString(data.possibleValuesUrl), possibleValuesDisplayBinding: data.possibleValuesDisplayBinding && __verifyIsString(data.possibleValuesDisplayBinding), possibleValuesValueBinding: data.possibleValuesValueBinding && __verifyIsString(data.possibleValuesValueBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['format','hideWidget','withSeconds','possibleValues','possibleValuesUrl','possibleValuesDisplayBinding','possibleValuesValueBinding'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.ListFilterDto
 */
export class ListFilter {
    offset?: number;
    limit?: number;
    sorting?: Sorting;
    filter?: FilterConditionBaseUnion;
    collectionModifiers?: CollectionModifier[];
    metadata?: { [index: string]: any };

    static fromData(data: any): ListFilter {
        if (!data) {
            return data;
        }
        const instance: ListFilter = { offset: data.offset && __verifyIsNumber(data.offset), limit: data.limit && __verifyIsNumber(data.limit), sorting: data.sorting && Sorting.fromData(data.sorting), filter: data.filter && FilterConditionBase.fromDataUnion(data.filter), collectionModifiers: data.collectionModifiers && __getCopyArrayFn(CollectionModifier.fromData)(data.collectionModifiers), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['offset','limit','sorting','filter','collectionModifiers','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.RewriteRuleDto
 */
export class RewriteRule {
    pattern?: string;
    replacement?: string;

    static fromData(data: any): RewriteRule {
        if (!data) {
            return data;
        }
        const instance: RewriteRule = { pattern: data.pattern && __verifyIsString(data.pattern), replacement: data.replacement && __verifyIsString(data.replacement) };
        return instance;
    }

    static getProperties(): string[] {
        return ['pattern','replacement'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.SortingDto
 */
export class Sorting {
    property?: string;
    order?: Order;

    static fromData(data: any): Sorting {
        if (!data) {
            return data;
        }
        const instance: Sorting = { property: data.property && __verifyIsString(data.property), order: data.order && data.order };
        return instance;
    }

    static getProperties(): string[] {
        return ['property','order'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.filter.ViewSpecificFilterAdaptationDto
 */
export class ViewSpecificFilterAdaptation {
    viewId?: string;
    filterOptionPropertyRewriteRules?: RewriteRule[];

    static fromData(data: any): ViewSpecificFilterAdaptation {
        if (!data) {
            return data;
        }
        const instance: ViewSpecificFilterAdaptation = { viewId: data.viewId && __verifyIsString(data.viewId), filterOptionPropertyRewriteRules: data.filterOptionPropertyRewriteRules && __getCopyArrayFn(RewriteRule.fromData)(data.filterOptionPropertyRewriteRules) };
        return instance;
    }

    static getProperties(): string[] {
        return ['viewId','filterOptionPropertyRewriteRules'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormConfigurationDto
 */
export class AbstractFormConfiguration {
    formConfigurationType: "ENTITY" | "GENERIC";
    formValueDisplay?: string;
    formTypeDisplay?: string;
    readOnly?: string;
    successMessageProvider?: string;
    successVariantProvider?: string;
    errorMessageProvider?: string;
    errorVariantProvider?: string;

    static fromData(data: any): AbstractFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: AbstractFormConfiguration = { formConfigurationType: data.formConfigurationType, formValueDisplay: data.formValueDisplay && __verifyIsString(data.formValueDisplay), formTypeDisplay: data.formTypeDisplay && __verifyIsString(data.formTypeDisplay), readOnly: data.readOnly && __verifyIsString(data.readOnly), successMessageProvider: data.successMessageProvider && __verifyIsString(data.successMessageProvider), successVariantProvider: data.successVariantProvider && __verifyIsString(data.successVariantProvider), errorMessageProvider: data.errorMessageProvider && __verifyIsString(data.errorMessageProvider), errorVariantProvider: data.errorVariantProvider && __verifyIsString(data.errorVariantProvider) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormConfigurationUnion): AbstractFormConfigurationUnion {
        if (!data) {
            return data;
        }
        switch (data.formConfigurationType) {
            case "ENTITY":
                return EntityFormConfiguration.fromData(data);
            case "GENERIC":
                return GenericFormConfiguration.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formConfigurationType','formValueDisplay','formTypeDisplay','readOnly','successMessageProvider','successVariantProvider','errorMessageProvider','errorVariantProvider'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractWidgetDto
 */
export class AbstractWidget {
    widgetType: "ENTITY_FIELD" | "GENERIC_FIELD" | "ENTITY_JOURNAL" | "ENTITY_LIST" | "ENTITY_WORKFLOW_ACTIVE_TASKS";
    label?: string;
    visible?: string;
    width?: number;
    height?: number;

    static fromData(data: any): AbstractWidget {
        if (!data) {
            return data;
        }
        const instance: AbstractWidget = { widgetType: data.widgetType, label: data.label && __verifyIsString(data.label), visible: data.visible && __verifyIsString(data.visible), width: data.width && __verifyIsNumber(data.width), height: data.height && __verifyIsNumber(data.height) };
        return instance;
    }

    static fromDataUnion(data: AbstractWidgetUnion): AbstractWidgetUnion {
        if (!data) {
            return data;
        }
        switch (data.widgetType) {
            case "ENTITY_FIELD":
                return EntityFormField.fromData(data);
            case "GENERIC_FIELD":
                return GenericFormField.fromData(data);
            case "ENTITY_JOURNAL":
                return EntityJournal.fromData(data);
            case "ENTITY_LIST":
                return EntityList.fromData(data);
            case "ENTITY_WORKFLOW_ACTIVE_TASKS":
                return EntityWorkflowActiveTasks.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['widgetType','label','visible','width','height'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormFieldDto
 */
export class AbstractFormField extends AbstractWidget {
    widgetType: "ENTITY_FIELD" | "GENERIC_FIELD";
    component?: ComponentType;
    format?: string;
    helperText?: string;
    icon?: string;
    valueDisplay?: string;
    valueParser?: string;
    initialValueProvider?: string;
    editable?: string;
    readOnly?: string;
    valid?: string;
    mandatory?: string;
    parameterList?: Parameter[];

    static fromData(data: any): AbstractFormField {
        if (!data) {
            return data;
        }
        const instance: AbstractFormField = { ...(super.fromData(data) as AbstractFormField), component: data.component && data.component, format: data.format && __verifyIsString(data.format), helperText: data.helperText && __verifyIsString(data.helperText), icon: data.icon && __verifyIsString(data.icon), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), valueParser: data.valueParser && __verifyIsString(data.valueParser), initialValueProvider: data.initialValueProvider && __verifyIsString(data.initialValueProvider), editable: data.editable && __verifyIsString(data.editable), readOnly: data.readOnly && __verifyIsString(data.readOnly), valid: data.valid && __verifyIsString(data.valid), mandatory: data.mandatory && __verifyIsString(data.mandatory), parameterList: data.parameterList && __getCopyArrayFn(Parameter.fromData)(data.parameterList) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormFieldUnion): AbstractFormFieldUnion {
        if (!data) {
            return data;
        }
        switch (data.widgetType) {
            case "ENTITY_FIELD":
                return EntityFormField.fromData(data);
            case "GENERIC_FIELD":
                return GenericFormField.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['widgetType','component','format','helperText','icon','valueDisplay','valueParser','initialValueProvider','editable','readOnly','valid','mandatory','parameterList'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormFieldListColumnDto
 */
export class AbstractFormFieldListColumn {
    formFieldListColumnType: "ENTITY" | "GENERIC";
    columnType?: FormFieldListColumnType;
    columnName?: string;
    headerText?: string;
    valueDisplay?: string;
    format?: string;
    action?: Action;
    sortable?: boolean;

    static fromData(data: any): AbstractFormFieldListColumn {
        if (!data) {
            return data;
        }
        const instance: AbstractFormFieldListColumn = { formFieldListColumnType: data.formFieldListColumnType, columnType: data.columnType && data.columnType, columnName: data.columnName && __verifyIsString(data.columnName), headerText: data.headerText && __verifyIsString(data.headerText), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), format: data.format && __verifyIsString(data.format), action: data.action && Action.fromData(data.action), sortable: data.sortable && __verifyIsBoolean(data.sortable) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormFieldListColumnUnion): AbstractFormFieldListColumnUnion {
        if (!data) {
            return data;
        }
        switch (data.formFieldListColumnType) {
            case "ENTITY":
                return EntityFormFieldListColumn.fromData(data);
            case "GENERIC":
                return GenericFormFieldListColumn.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formFieldListColumnType','columnType','columnName','headerText','valueDisplay','format','action','sortable'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormFieldListConfigurationDto
 */
export class AbstractFormFieldListConfiguration {
    formFieldListConfigurationType: "ENTITY" | "GENERIC";
    itemAddable?: boolean;
    itemRemovable?: boolean;
    fileAddable?: boolean;
    defaultSortColumn?: string;
    defaultSortOrder?: SortOrder;

    static fromData(data: any): AbstractFormFieldListConfiguration {
        if (!data) {
            return data;
        }
        const instance: AbstractFormFieldListConfiguration = { formFieldListConfigurationType: data.formFieldListConfigurationType, itemAddable: data.itemAddable && __verifyIsBoolean(data.itemAddable), itemRemovable: data.itemRemovable && __verifyIsBoolean(data.itemRemovable), fileAddable: data.fileAddable && __verifyIsBoolean(data.fileAddable), defaultSortColumn: data.defaultSortColumn && __verifyIsString(data.defaultSortColumn), defaultSortOrder: data.defaultSortOrder && data.defaultSortOrder };
        return instance;
    }

    static fromDataUnion(data: AbstractFormFieldListConfigurationUnion): AbstractFormFieldListConfigurationUnion {
        if (!data) {
            return data;
        }
        switch (data.formFieldListConfigurationType) {
            case "ENTITY":
                return EntityFormFieldListConfiguration.fromData(data);
            case "GENERIC":
                return GenericFormFieldListConfiguration.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formFieldListConfigurationType','itemAddable','itemRemovable','fileAddable','defaultSortColumn','defaultSortOrder'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormGroupDto
 */
export class AbstractFormGroup {
    formGroupType: "ENTITY" | "GENERIC";
    label?: string;
    icon?: string;
    visible?: string;
    width?: number;
    collapsable?: string;
    collapsedByDefault?: string;
    readOnly?: string;
    showHeader?: string;

    static fromData(data: any): AbstractFormGroup {
        if (!data) {
            return data;
        }
        const instance: AbstractFormGroup = { formGroupType: data.formGroupType, label: data.label && __verifyIsString(data.label), icon: data.icon && __verifyIsString(data.icon), visible: data.visible && __verifyIsString(data.visible), width: data.width && __verifyIsNumber(data.width), collapsable: data.collapsable && __verifyIsString(data.collapsable), collapsedByDefault: data.collapsedByDefault && __verifyIsString(data.collapsedByDefault), readOnly: data.readOnly && __verifyIsString(data.readOnly), showHeader: data.showHeader && __verifyIsString(data.showHeader) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormGroupUnion): AbstractFormGroupUnion {
        if (!data) {
            return data;
        }
        switch (data.formGroupType) {
            case "ENTITY":
                return EntityFormGroup.fromData(data);
            case "GENERIC":
                return GenericFormGroup.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formGroupType','label','icon','visible','width','collapsable','collapsedByDefault','readOnly','showHeader'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormConfigurationDto
 */
export class EntityFormConfiguration extends AbstractFormConfiguration {
    formConfigurationType: "ENTITY";
    formActions?: FormActions;
    groups?: AbstractFormGroupUnion[];
    variantName?: string;

    static fromData(data: any): EntityFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityFormConfiguration = { ...(super.fromData(data) as EntityFormConfiguration), formActions: data.formActions && FormActions.fromData(data.formActions), groups: data.groups && __getCopyArrayFn(AbstractFormGroup.fromDataUnion)(data.groups), variantName: data.variantName && __verifyIsString(data.variantName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formConfigurationType','formActions','groups','variantName'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormFieldDto
 */
export class EntityFormField extends AbstractFormField {
    widgetType: "ENTITY_FIELD";
    attributeName?: string;
    simpleListConfiguration?: AbstractFormFieldListConfigurationUnion;

    static fromData(data: any): EntityFormField {
        if (!data) {
            return data;
        }
        const instance: EntityFormField = { ...(super.fromData(data) as EntityFormField), attributeName: data.attributeName && __verifyIsString(data.attributeName), simpleListConfiguration: data.simpleListConfiguration && AbstractFormFieldListConfiguration.fromDataUnion(data.simpleListConfiguration) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','attributeName','simpleListConfiguration'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormFieldListColumnDto
 */
export class EntityFormFieldListColumn extends AbstractFormFieldListColumn {
    formFieldListColumnType: "ENTITY";
    attributeName?: string;

    static fromData(data: any): EntityFormFieldListColumn {
        if (!data) {
            return data;
        }
        const instance: EntityFormFieldListColumn = { ...(super.fromData(data) as EntityFormFieldListColumn), attributeName: data.attributeName && __verifyIsString(data.attributeName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListColumnType','attributeName'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormFieldListConfigurationDto
 */
export class EntityFormFieldListConfiguration extends AbstractFormFieldListConfiguration {
    formFieldListConfigurationType: "ENTITY";
    formVariant?: string;
    simpleColumns?: AbstractFormFieldListColumnUnion[];

    static fromData(data: any): EntityFormFieldListConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityFormFieldListConfiguration = { ...(super.fromData(data) as EntityFormFieldListConfiguration), formVariant: data.formVariant && __verifyIsString(data.formVariant), simpleColumns: data.simpleColumns && __getCopyArrayFn(AbstractFormFieldListColumn.fromDataUnion)(data.simpleColumns) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListConfigurationType','formVariant','simpleColumns'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormGroupDto
 */
export class EntityFormGroup extends AbstractFormGroup {
    formGroupType: "ENTITY";
    widgets?: AbstractWidgetUnion[];

    static fromData(data: any): EntityFormGroup {
        if (!data) {
            return data;
        }
        const instance: EntityFormGroup = { ...(super.fromData(data) as EntityFormGroup), widgets: data.widgets && __getCopyArrayFn(AbstractWidget.fromDataUnion)(data.widgets) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formGroupType','widgets'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityJournalDto
 */
export class EntityJournal extends AbstractWidget {
    widgetType: "ENTITY_JOURNAL";
    entityType?: string;
    attributeName?: string;

    static fromData(data: any): EntityJournal {
        if (!data) {
            return data;
        }
        const instance: EntityJournal = { ...(super.fromData(data) as EntityJournal), entityType: data.entityType && __verifyIsString(data.entityType), attributeName: data.attributeName && __verifyIsString(data.attributeName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','entityType','attributeName'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityListDto
 */
export class EntityList extends AbstractWidget {
    widgetType: "ENTITY_LIST";
    entityType?: string;
    listVariant?: string;
    filterVariant?: string;
    filterModifier?: string;
    maxHeight?: number;

    static fromData(data: any): EntityList {
        if (!data) {
            return data;
        }
        const instance: EntityList = { ...(super.fromData(data) as EntityList), entityType: data.entityType && __verifyIsString(data.entityType), listVariant: data.listVariant && __verifyIsString(data.listVariant), filterVariant: data.filterVariant && __verifyIsString(data.filterVariant), filterModifier: data.filterModifier && __verifyIsString(data.filterModifier), maxHeight: data.maxHeight && __verifyIsNumber(data.maxHeight) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','entityType','listVariant','filterVariant','filterModifier','maxHeight'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityWorkflowActiveTasksDto
 */
export class EntityWorkflowActiveTasks extends AbstractWidget {
    widgetType: "ENTITY_WORKFLOW_ACTIVE_TASKS";

    static fromData(data: any): EntityWorkflowActiveTasks {
        if (!data) {
            return data;
        }
        const instance: EntityWorkflowActiveTasks = { ...(super.fromData(data) as EntityWorkflowActiveTasks) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.FormActionsDto
 */
export class FormActions {
    actions?: Action[];

    static fromData(data: any): FormActions {
        if (!data) {
            return data;
        }
        const instance: FormActions = { actions: data.actions && __getCopyArrayFn(Action.fromData)(data.actions) };
        return instance;
    }

    static getProperties(): string[] {
        return ['actions'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormConfigurationDto
 */
export class GenericFormConfiguration extends AbstractFormConfiguration {
    formConfigurationType: "GENERIC";
    formActions?: FormActions;
    groups?: GenericFormGroup[];

    static fromData(data: any): GenericFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: GenericFormConfiguration = { ...(super.fromData(data) as GenericFormConfiguration), formActions: data.formActions && FormActions.fromData(data.formActions), groups: data.groups && __getCopyArrayFn(GenericFormGroup.fromData)(data.groups) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formConfigurationType','formActions','groups'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormFieldDto
 */
export class GenericFormField extends AbstractFormField {
    widgetType: "GENERIC_FIELD";
    valueBinding?: string;
    possibleValuesList?: PossibleValue[];
    simpleListConfiguration?: GenericFormFieldListConfiguration;
    possibleValuesUrl?: string;
    possibleValuesByValuesUrl?: string;
    possibleValuesDisplayBinding?: string;
    possibleValuesValueBinding?: string;
    possibleValuesVersionBinding?: string;

    static fromData(data: any): GenericFormField {
        if (!data) {
            return data;
        }
        const instance: GenericFormField = { ...(super.fromData(data) as GenericFormField), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding), possibleValuesList: data.possibleValuesList && __getCopyArrayFn(PossibleValue.fromData)(data.possibleValuesList), simpleListConfiguration: data.simpleListConfiguration && GenericFormFieldListConfiguration.fromData(data.simpleListConfiguration), possibleValuesUrl: data.possibleValuesUrl && __verifyIsString(data.possibleValuesUrl), possibleValuesByValuesUrl: data.possibleValuesByValuesUrl && __verifyIsString(data.possibleValuesByValuesUrl), possibleValuesDisplayBinding: data.possibleValuesDisplayBinding && __verifyIsString(data.possibleValuesDisplayBinding), possibleValuesValueBinding: data.possibleValuesValueBinding && __verifyIsString(data.possibleValuesValueBinding), possibleValuesVersionBinding: data.possibleValuesVersionBinding && __verifyIsString(data.possibleValuesVersionBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','valueBinding','possibleValuesList','simpleListConfiguration','possibleValuesUrl','possibleValuesByValuesUrl','possibleValuesDisplayBinding','possibleValuesValueBinding','possibleValuesVersionBinding'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormFieldListColumnDto
 */
export class GenericFormFieldListColumn extends AbstractFormFieldListColumn {
    formFieldListColumnType: "GENERIC";
    valueBinding?: string;

    static fromData(data: any): GenericFormFieldListColumn {
        if (!data) {
            return data;
        }
        const instance: GenericFormFieldListColumn = { ...(super.fromData(data) as GenericFormFieldListColumn), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListColumnType','valueBinding'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormFieldListConfigurationDto
 */
export class GenericFormFieldListConfiguration extends AbstractFormFieldListConfiguration {
    formFieldListConfigurationType: "GENERIC";
    simpleColumns?: GenericFormFieldListColumn[];
    formConfigurationUrl?: string;

    static fromData(data: any): GenericFormFieldListConfiguration {
        if (!data) {
            return data;
        }
        const instance: GenericFormFieldListConfiguration = { ...(super.fromData(data) as GenericFormFieldListConfiguration), simpleColumns: data.simpleColumns && __getCopyArrayFn(GenericFormFieldListColumn.fromData)(data.simpleColumns), formConfigurationUrl: data.formConfigurationUrl && __verifyIsString(data.formConfigurationUrl) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListConfigurationType','simpleColumns','formConfigurationUrl'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormGroupDto
 */
export class GenericFormGroup extends AbstractFormGroup {
    formGroupType: "GENERIC";
    widgets?: AbstractWidgetUnion[];

    static fromData(data: any): GenericFormGroup {
        if (!data) {
            return data;
        }
        const instance: GenericFormGroup = { ...(super.fromData(data) as GenericFormGroup), widgets: data.widgets && __getCopyArrayFn(AbstractWidget.fromDataUnion)(data.widgets) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formGroupType','widgets'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonFeatureCollectionDto
 */
export class GeoJsonFeatureCollection {
    features?: GeoJsonFeature[];

    static fromData(data: any): GeoJsonFeatureCollection {
        if (!data) {
            return data;
        }
        const instance: GeoJsonFeatureCollection = { features: data.features && __getCopyArrayFn(GeoJsonFeature.fromData)(data.features) };
        return instance;
    }

    static getProperties(): string[] {
        return ['features'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonFeatureDto
 */
export class GeoJsonFeature {
    type?: GeoJsonFeatureType;
    geometry?: GeoJsonGeometryUnion;
    properties?: { [index: string]: any };

    static fromData(data: any): GeoJsonFeature {
        if (!data) {
            return data;
        }
        const instance: GeoJsonFeature = { type: data.type && data.type, geometry: data.geometry && GeoJsonGeometry.fromDataUnion(data.geometry), properties: data.properties && __getCopyObjectFn(__identity<any>())(data.properties) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','geometry','properties'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonGeometryAllTypesDto
 */
export class GeoJsonGeometryAllTypes {
    point?: GeoJsonPoint;
    lineString?: GeoJsonLineString;
    polygon?: GeoJsonPolygon;
    multiPoint?: GeoJsonMultiPoint;
    multiLineString?: GeoJsonMultiLineString;
    multiPolygon?: GeoJsonMultiPolygon;

    static fromData(data: any): GeoJsonGeometryAllTypes {
        if (!data) {
            return data;
        }
        const instance: GeoJsonGeometryAllTypes = { point: data.point && GeoJsonPoint.fromData(data.point), lineString: data.lineString && GeoJsonLineString.fromData(data.lineString), polygon: data.polygon && GeoJsonPolygon.fromData(data.polygon), multiPoint: data.multiPoint && GeoJsonMultiPoint.fromData(data.multiPoint), multiLineString: data.multiLineString && GeoJsonMultiLineString.fromData(data.multiLineString), multiPolygon: data.multiPolygon && GeoJsonMultiPolygon.fromData(data.multiPolygon) };
        return instance;
    }

    static getProperties(): string[] {
        return ['point','lineString','polygon','multiPoint','multiLineString','multiPolygon'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonGeometryCollectionDto
 */
export class GeoJsonGeometryCollection {
    type?: GeoJsonGeometryCollectionType;
    geometries?: GeoJsonGeometryUnion[];

    static fromData(data: any): GeoJsonGeometryCollection {
        if (!data) {
            return data;
        }
        const instance: GeoJsonGeometryCollection = { type: data.type && data.type, geometries: data.geometries && __getCopyArrayFn(GeoJsonGeometry.fromDataUnion)(data.geometries) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','geometries'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonGeometryDto
 */
export class GeoJsonGeometry {
    type: "LineString" | "MultiLineString" | "MultiPoint" | "MultiPolygon" | "Point" | "Polygon";

    static fromData(data: any): GeoJsonGeometry {
        if (!data) {
            return data;
        }
        const instance: GeoJsonGeometry = { type: data.type };
        return instance;
    }

    static fromDataUnion(data: GeoJsonGeometryUnion): GeoJsonGeometryUnion {
        if (!data) {
            return data;
        }
        switch (data.type) {
            case "LineString":
                return GeoJsonLineString.fromData(data);
            case "MultiLineString":
                return GeoJsonMultiLineString.fromData(data);
            case "MultiPoint":
                return GeoJsonMultiPoint.fromData(data);
            case "MultiPolygon":
                return GeoJsonMultiPolygon.fromData(data);
            case "Point":
                return GeoJsonPoint.fromData(data);
            case "Polygon":
                return GeoJsonPolygon.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['type'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonLineStringDto
 */
export class GeoJsonLineString extends GeoJsonGeometry {
    type: "LineString";
    coordinates?: number[][];

    static fromData(data: any): GeoJsonLineString {
        if (!data) {
            return data;
        }
        const instance: GeoJsonLineString = { ...(super.fromData(data) as GeoJsonLineString), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__identity<number>()))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonMultiLineStringDto
 */
export class GeoJsonMultiLineString extends GeoJsonGeometry {
    type: "MultiLineString";
    coordinates?: number[][][];

    static fromData(data: any): GeoJsonMultiLineString {
        if (!data) {
            return data;
        }
        const instance: GeoJsonMultiLineString = { ...(super.fromData(data) as GeoJsonMultiLineString), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__identity<number>())))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonMultiPointDto
 */
export class GeoJsonMultiPoint extends GeoJsonGeometry {
    type: "MultiPoint";
    coordinates?: number[][];

    static fromData(data: any): GeoJsonMultiPoint {
        if (!data) {
            return data;
        }
        const instance: GeoJsonMultiPoint = { ...(super.fromData(data) as GeoJsonMultiPoint), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__identity<number>()))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonMultiPolygonDto
 */
export class GeoJsonMultiPolygon extends GeoJsonGeometry {
    type: "MultiPolygon";
    coordinates?: number[][][][];

    static fromData(data: any): GeoJsonMultiPolygon {
        if (!data) {
            return data;
        }
        const instance: GeoJsonMultiPolygon = { ...(super.fromData(data) as GeoJsonMultiPolygon), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__identity<number>()))))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonPointDto
 */
export class GeoJsonPoint extends GeoJsonGeometry {
    type: "Point";
    coordinates?: number[];

    static fromData(data: any): GeoJsonPoint {
        if (!data) {
            return data;
        }
        const instance: GeoJsonPoint = { ...(super.fromData(data) as GeoJsonPoint), coordinates: data.coordinates && __getCopyArrayFn(__identity<number>())(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.geojson.GeoJsonPolygonDto
 */
export class GeoJsonPolygon extends GeoJsonGeometry {
    type: "Polygon";
    coordinates?: number[][][];

    static fromData(data: any): GeoJsonPolygon {
        if (!data) {
            return data;
        }
        const instance: GeoJsonPolygon = { ...(super.fromData(data) as GeoJsonPolygon), coordinates: data.coordinates && __getCopyArrayFn(__getCopyArrayFn(__getCopyArrayFn(__identity<number>())))(data.coordinates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','coordinates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.IconDto
 */
export class Icon {
    icon?: string;
    color?: string;
    visible?: string;
    tooltipText?: string;

    static fromData(data: any): Icon {
        if (!data) {
            return data;
        }
        const instance: Icon = { icon: data.icon && __verifyIsString(data.icon), color: data.color && __verifyIsString(data.color), visible: data.visible && __verifyIsString(data.visible), tooltipText: data.tooltipText && __verifyIsString(data.tooltipText) };
        return instance;
    }

    static getProperties(): string[] {
        return ['icon','color','visible','tooltipText'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.ListActionsDto
 */
export class ListActions {
    actions?: Action[];

    static fromData(data: any): ListActions {
        if (!data) {
            return data;
        }
        const instance: ListActions = { actions: data.actions && __getCopyArrayFn(Action.fromData)(data.actions) };
        return instance;
    }

    static getProperties(): string[] {
        return ['actions'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.ListConfigurationDto
 */
export class ListConfiguration {
    listActions?: ListActions;
    rowConfiguration?: RowConfiguration;
    pageSize?: number;
    defaultSortColumn?: string;
    defaultSortOrder?: SortOrder;
    rowColorDisplay?: string;
    showColumnHeaders?: boolean;

    static fromData(data: any): ListConfiguration {
        if (!data) {
            return data;
        }
        const instance: ListConfiguration = { listActions: data.listActions && ListActions.fromData(data.listActions), rowConfiguration: data.rowConfiguration && RowConfiguration.fromData(data.rowConfiguration), pageSize: data.pageSize && __verifyIsNumber(data.pageSize), defaultSortColumn: data.defaultSortColumn && __verifyIsString(data.defaultSortColumn), defaultSortOrder: data.defaultSortOrder && data.defaultSortOrder, rowColorDisplay: data.rowColorDisplay && __verifyIsString(data.rowColorDisplay), showColumnHeaders: data.showColumnHeaders && __verifyIsBoolean(data.showColumnHeaders) };
        return instance;
    }

    static getProperties(): string[] {
        return ['listActions','rowConfiguration','pageSize','defaultSortColumn','defaultSortOrder','rowColorDisplay','showColumnHeaders'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.RowElementDto
 */
export class RowElement {
    type: "KEY_VALUE" | "ON_CLICK_ACTION" | "ACTION" | "ICON" | "TEXT" | "SUBLIST";

    static fromData(data: any): RowElement {
        if (!data) {
            return data;
        }
        const instance: RowElement = { type: data.type };
        return instance;
    }

    static fromDataUnion(data: RowElementUnion): RowElementUnion {
        if (!data) {
            return data;
        }
        switch (data.type) {
            case "KEY_VALUE":
                return ListKeyValueColumn.fromData(data);
            case "ON_CLICK_ACTION":
                return OnClickAction.fromData(data);
            case "ACTION":
                return RowActions.fromData(data);
            case "ICON":
                return RowIconGroupElement.fromData(data);
            case "TEXT":
                return RowTextElement.fromData(data);
            case "SUBLIST":
                return SubListElement.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['type'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.ListKeyValueColumnDto
 */
export class ListKeyValueColumn extends RowElement {
    type: "KEY_VALUE";
    keysBinding?: string;
    keyDisplay?: string;
    valueDisplay?: string;
    valueFormatDisplay?: string;

    static fromData(data: any): ListKeyValueColumn {
        if (!data) {
            return data;
        }
        const instance: ListKeyValueColumn = { ...(super.fromData(data) as ListKeyValueColumn), keysBinding: data.keysBinding && __verifyIsString(data.keysBinding), keyDisplay: data.keyDisplay && __verifyIsString(data.keyDisplay), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), valueFormatDisplay: data.valueFormatDisplay && __verifyIsString(data.valueFormatDisplay) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','keysBinding','keyDisplay','valueDisplay','valueFormatDisplay'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.OnClickActionDto
 */
export class OnClickAction extends RowElement {
    type: "ON_CLICK_ACTION";
    id?: string;
    icon?: string;
    action?: ActionType;
    label?: string;
    parameters?: Parameter[];
    objectParameters?: ObjectParameter[];
    visible?: string;

    static fromData(data: any): OnClickAction {
        if (!data) {
            return data;
        }
        const instance: OnClickAction = { ...(super.fromData(data) as OnClickAction), id: data.id && __verifyIsString(data.id), icon: data.icon && __verifyIsString(data.icon), action: data.action && data.action, label: data.label && __verifyIsString(data.label), parameters: data.parameters && __getCopyArrayFn(Parameter.fromData)(data.parameters), objectParameters: data.objectParameters && __getCopyArrayFn(ObjectParameter.fromData)(data.objectParameters), visible: data.visible && __verifyIsString(data.visible) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','icon','action','label','parameters','objectParameters','visible'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.RowActionsDto
 */
export class RowActions extends RowElement {
    type: "ACTION";
    actions?: Action[];
    label?: string;
    visible?: string;

    static fromData(data: any): RowActions {
        if (!data) {
            return data;
        }
        const instance: RowActions = { ...(super.fromData(data) as RowActions), actions: data.actions && __getCopyArrayFn(Action.fromData)(data.actions), label: data.label && __verifyIsString(data.label), visible: data.visible && __verifyIsString(data.visible) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','actions','label','visible'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.RowConfigurationDto
 */
export class RowConfiguration {
    viewAttributes?: string;
    menu?: Menu;
    elements?: RowElementUnion[];

    static fromData(data: any): RowConfiguration {
        if (!data) {
            return data;
        }
        const instance: RowConfiguration = { viewAttributes: data.viewAttributes && __verifyIsString(data.viewAttributes), menu: data.menu && Menu.fromData(data.menu), elements: data.elements && __getCopyArrayFn(RowElement.fromDataUnion)(data.elements) };
        return instance;
    }

    static getProperties(): string[] {
        return ['viewAttributes','menu','elements'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.RowIconGroupElementDto
 */
export class RowIconGroupElement extends RowElement {
    type: "ICON";
    label?: string;
    icons?: Icon[];
    slot?: IconSlotType;
    visible?: string;

    static fromData(data: any): RowIconGroupElement {
        if (!data) {
            return data;
        }
        const instance: RowIconGroupElement = { ...(super.fromData(data) as RowIconGroupElement), label: data.label && __verifyIsString(data.label), icons: data.icons && __getCopyArrayFn(Icon.fromData)(data.icons), slot: data.slot && data.slot, visible: data.visible && __verifyIsString(data.visible) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','label','icons','slot','visible'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.RowTextElementDto
 */
export class RowTextElement extends RowElement {
    type: "TEXT";
    name?: string;
    label?: string;
    valueBinding?: string;
    valueDisplay?: string;
    component?: string;
    sortable?: string;
    visible?: string;
    slot?: TextSlotType;
    slotPriority?: number;
    format?: string;
    onClickAction?: Action;
    icon?: Icon;

    static fromData(data: any): RowTextElement {
        if (!data) {
            return data;
        }
        const instance: RowTextElement = { ...(super.fromData(data) as RowTextElement), name: data.name && __verifyIsString(data.name), label: data.label && __verifyIsString(data.label), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), component: data.component && __verifyIsString(data.component), sortable: data.sortable && __verifyIsString(data.sortable), visible: data.visible && __verifyIsString(data.visible), slot: data.slot && data.slot, slotPriority: data.slotPriority && __verifyIsNumber(data.slotPriority), format: data.format && __verifyIsString(data.format), onClickAction: data.onClickAction && Action.fromData(data.onClickAction), icon: data.icon && Icon.fromData(data.icon) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','name','label','valueBinding','valueDisplay','component','sortable','visible','slot','slotPriority','format','onClickAction','icon'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.list.SubListElementDto
 */
export class SubListElement extends RowElement {
    type: "SUBLIST";
    label?: string;
    visible?: string;
    contextVisible?: string;
    valueBinding?: string;
    entityType?: string;
    listVariant?: string;

    static fromData(data: any): SubListElement {
        if (!data) {
            return data;
        }
        const instance: SubListElement = { ...(super.fromData(data) as SubListElement), label: data.label && __verifyIsString(data.label), visible: data.visible && __verifyIsString(data.visible), contextVisible: data.contextVisible && __verifyIsString(data.contextVisible), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding), entityType: data.entityType && __verifyIsString(data.entityType), listVariant: data.listVariant && __verifyIsString(data.listVariant) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','label','visible','contextVisible','valueBinding','entityType','listVariant'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.login.ClientProfileDto
 */
export class ClientProfile {
    availableMemory?: number;
    browserName?: string;
    browserVersion?: string;
    timestamp?: Date;
    ipAddress?: string;
    hostname?: string;
    osName?: string;
    osVersion?: string;
    processorArchitecture?: string;
    processorCount?: number;
    processorIdentifier?: string;
    processorLevel?: string;
    processorRevision?: string;
    version?: string;
    screenWidth?: number;
    screenHeight?: number;
    type?: string;
    workstationName?: string;

    static fromData(data: any): ClientProfile {
        if (!data) {
            return data;
        }
        const instance: ClientProfile = { availableMemory: data.availableMemory && __verifyIsNumber(data.availableMemory), browserName: data.browserName && __verifyIsString(data.browserName), browserVersion: data.browserVersion && __verifyIsString(data.browserVersion), timestamp: data.timestamp && new Date(data.timestamp), ipAddress: data.ipAddress && __verifyIsString(data.ipAddress), hostname: data.hostname && __verifyIsString(data.hostname), osName: data.osName && __verifyIsString(data.osName), osVersion: data.osVersion && __verifyIsString(data.osVersion), processorArchitecture: data.processorArchitecture && __verifyIsString(data.processorArchitecture), processorCount: data.processorCount && __verifyIsNumber(data.processorCount), processorIdentifier: data.processorIdentifier && __verifyIsString(data.processorIdentifier), processorLevel: data.processorLevel && __verifyIsString(data.processorLevel), processorRevision: data.processorRevision && __verifyIsString(data.processorRevision), version: data.version && __verifyIsString(data.version), screenWidth: data.screenWidth && __verifyIsNumber(data.screenWidth), screenHeight: data.screenHeight && __verifyIsNumber(data.screenHeight), type: data.type && __verifyIsString(data.type), workstationName: data.workstationName && __verifyIsString(data.workstationName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['availableMemory','browserName','browserVersion','timestamp','ipAddress','hostname','osName','osVersion','processorArchitecture','processorCount','processorIdentifier','processorLevel','processorRevision','version','screenWidth','screenHeight','type','workstationName'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.login.LoginNotificationDto
 */
export class LoginNotification {
    userName?: string;
    sso?: boolean;

    static fromData(data: any): LoginNotification {
        if (!data) {
            return data;
        }
        const instance: LoginNotification = { userName: data.userName && __verifyIsString(data.userName), sso: data.sso && __verifyIsBoolean(data.sso) };
        return instance;
    }

    static getProperties(): string[] {
        return ['userName','sso'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.login.LoginRequestDto
 */
export class LoginRequest {
    userName?: string;
    password?: string;

    static fromData(data: any): LoginRequest {
        if (!data) {
            return data;
        }
        const instance: LoginRequest = { userName: data.userName && __verifyIsString(data.userName), password: data.password && __verifyIsString(data.password) };
        return instance;
    }

    static getProperties(): string[] {
        return ['userName','password'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.login.LoginStatusDto
 */
export class LoginStatus {
    user?: User;
    passwordState?: PasswordState;
    millisecondsUntilPasswordExpiry?: number;
    authenticationToken?: string;

    static fromData(data: any): LoginStatus {
        if (!data) {
            return data;
        }
        const instance: LoginStatus = { user: data.user && User.fromData(data.user), passwordState: data.passwordState && data.passwordState, millisecondsUntilPasswordExpiry: data.millisecondsUntilPasswordExpiry && __verifyIsNumber(data.millisecondsUntilPasswordExpiry), authenticationToken: data.authenticationToken && __verifyIsString(data.authenticationToken) };
        return instance;
    }

    static getProperties(): string[] {
        return ['user','passwordState','millisecondsUntilPasswordExpiry','authenticationToken'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.login.PasswordChangeDto
 */
export class PasswordChange {
    userName?: string;
    currentPassword?: string;
    newPassword?: string;

    static fromData(data: any): PasswordChange {
        if (!data) {
            return data;
        }
        const instance: PasswordChange = { userName: data.userName && __verifyIsString(data.userName), currentPassword: data.currentPassword && __verifyIsString(data.currentPassword), newPassword: data.newPassword && __verifyIsString(data.newPassword) };
        return instance;
    }

    static getProperties(): string[] {
        return ['userName','currentPassword','newPassword'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.login.PasswordChangeResultDto
 */
export class PasswordChangeResult {
    success?: boolean;
    currentPasswordState?: PasswordValidation;
    newPasswordState?: PasswordValidation;

    static fromData(data: any): PasswordChangeResult {
        if (!data) {
            return data;
        }
        const instance: PasswordChangeResult = { success: data.success && __verifyIsBoolean(data.success), currentPasswordState: data.currentPasswordState && data.currentPasswordState, newPasswordState: data.newPasswordState && data.newPasswordState };
        return instance;
    }

    static getProperties(): string[] {
        return ['success','currentPasswordState','newPasswordState'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.notification.GlobalNotificationConfigurationDto
 */
export class GlobalNotificationConfiguration {
    notificationTrigger?: NotificationTrigger;
    notificationDisplay?: NotificationDisplay;

    static fromData(data: any): GlobalNotificationConfiguration {
        if (!data) {
            return data;
        }
        const instance: GlobalNotificationConfiguration = { notificationTrigger: data.notificationTrigger && NotificationTrigger.fromData(data.notificationTrigger), notificationDisplay: data.notificationDisplay && NotificationDisplay.fromData(data.notificationDisplay) };
        return instance;
    }

    static getProperties(): string[] {
        return ['notificationTrigger','notificationDisplay'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.notification.NotificationConfigurationDto
 */
export class NotificationConfiguration {
    globalNotificationConfigurations?: GlobalNotificationConfiguration[];

    static fromData(data: any): NotificationConfiguration {
        if (!data) {
            return data;
        }
        const instance: NotificationConfiguration = { globalNotificationConfigurations: data.globalNotificationConfigurations && __getCopyArrayFn(GlobalNotificationConfiguration.fromData)(data.globalNotificationConfigurations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['globalNotificationConfigurations'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.notification.NotificationDisplayDto
 */
export class NotificationDisplay {
    title?: string;
    message?: string;
    actions?: Action[];

    static fromData(data: any): NotificationDisplay {
        if (!data) {
            return data;
        }
        const instance: NotificationDisplay = { title: data.title && __verifyIsString(data.title), message: data.message && __verifyIsString(data.message), actions: data.actions && __getCopyArrayFn(Action.fromData)(data.actions) };
        return instance;
    }

    static getProperties(): string[] {
        return ['title','message','actions'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.notification.NotificationTriggerDto
 */
export class NotificationTrigger {
    objectPropertyUpdates?: ObjectPropertyUpdate[];

    static fromData(data: any): NotificationTrigger {
        if (!data) {
            return data;
        }
        const instance: NotificationTrigger = { objectPropertyUpdates: data.objectPropertyUpdates && __getCopyArrayFn(ObjectPropertyUpdate.fromData)(data.objectPropertyUpdates) };
        return instance;
    }

    static getProperties(): string[] {
        return ['objectPropertyUpdates'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.notification.ObjectPropertyUpdateDto
 */
export class ObjectPropertyUpdate {
    objectType?: string;
    fieldValueBinding?: string;
    valueMatcher?: string;

    static fromData(data: any): ObjectPropertyUpdate {
        if (!data) {
            return data;
        }
        const instance: ObjectPropertyUpdate = { objectType: data.objectType && __verifyIsString(data.objectType), fieldValueBinding: data.fieldValueBinding && __verifyIsString(data.fieldValueBinding), valueMatcher: data.valueMatcher && __verifyIsString(data.valueMatcher) };
        return instance;
    }

    static getProperties(): string[] {
        return ['objectType','fieldValueBinding','valueMatcher'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.AccessLevelDto
 */
export class AccessLevel {
    id?: string;
    label?: string;
    order?: number;
    valueDisplay?: string;
    roleAuthorizations?: RoleAuthorization[];

    static fromData(data: any): AccessLevel {
        if (!data) {
            return data;
        }
        const instance: AccessLevel = { id: data.id && __verifyIsString(data.id), label: data.label && __verifyIsString(data.label), order: data.order && __verifyIsNumber(data.order), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), roleAuthorizations: data.roleAuthorizations && __getCopyArrayFn(RoleAuthorization.fromData)(data.roleAuthorizations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','label','order','valueDisplay','roleAuthorizations'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.AccessLevelGroupDto
 */
export class AccessLevelGroup {
    name?: string;
    AccessLevels?: AccessLevel[];

    static fromData(data: any): AccessLevelGroup {
        if (!data) {
            return data;
        }
        const instance: AccessLevelGroup = { name: data.name && __verifyIsString(data.name), AccessLevels: data.AccessLevels && __getCopyArrayFn(AccessLevel.fromData)(data.AccessLevels) };
        return instance;
    }

    static getProperties(): string[] {
        return ['name','AccessLevels'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.AccessLevelsDto
 */
export class AccessLevels {
    AccessLevelGroups?: AccessLevelGroup[];

    static fromData(data: any): AccessLevels {
        if (!data) {
            return data;
        }
        const instance: AccessLevels = { AccessLevelGroups: data.AccessLevelGroups && __getCopyArrayFn(AccessLevelGroup.fromData)(data.AccessLevelGroups) };
        return instance;
    }

    static getProperties(): string[] {
        return ['AccessLevelGroups'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.AccessRestrictionsDto
 */
export class AccessRestrictions {
    restricted?: boolean;

    static fromData(data: any): AccessRestrictions {
        if (!data) {
            return data;
        }
        const instance: AccessRestrictions = { restricted: data.restricted && __verifyIsBoolean(data.restricted) };
        return instance;
    }

    static getProperties(): string[] {
        return ['restricted'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.FeatureAuthorizationDto
 */
export class FeatureAuthorization {
    feature?: FeatureName;
    permission?: PermissionType;

    static fromData(data: any): FeatureAuthorization {
        if (!data) {
            return data;
        }
        const instance: FeatureAuthorization = { feature: data.feature && data.feature, permission: data.permission && data.permission };
        return instance;
    }

    static getProperties(): string[] {
        return ['feature','permission'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.KeycloakAuthenticationAdapterDto
 */
export class KeycloakAuthenticationAdapter {
    type?: string;
    url?: string;
    realm?: string;
    backendResource?: string;
    frontendResource?: string;

    static fromData(data: any): KeycloakAuthenticationAdapter {
        if (!data) {
            return data;
        }
        const instance: KeycloakAuthenticationAdapter = { type: data.type && __verifyIsString(data.type), url: data.url && __verifyIsString(data.url), realm: data.realm && __verifyIsString(data.realm), backendResource: data.backendResource && __verifyIsString(data.backendResource), frontendResource: data.frontendResource && __verifyIsString(data.frontendResource) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','url','realm','backendResource','frontendResource'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.LocalAuthenticationAdapterDto
 */
export class LocalAuthenticationAdapter {
    type?: string;

    static fromData(data: any): LocalAuthenticationAdapter {
        if (!data) {
            return data;
        }
        const instance: LocalAuthenticationAdapter = { type: data.type && __verifyIsString(data.type) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.LoginConfigurationDto
 */
export class LoginConfiguration {
    realmName?: string;
    roleSelectionRequired?: boolean;
    daysBeforePasswordExpiryTriggeringWarning?: number;
    rememberMeStorage?: BrowserStorageType;
    rememberMeStoragePrefix?: string;
    minutesOfInactivityTriggeringAutoLogout?: number;
    userNamePrefixConfiguration?: UserNamePrefixConfiguration;
    whatsNewConfiguration?: WhatsNewConfiguration;

    static fromData(data: any): LoginConfiguration {
        if (!data) {
            return data;
        }
        const instance: LoginConfiguration = { realmName: data.realmName && __verifyIsString(data.realmName), roleSelectionRequired: data.roleSelectionRequired && __verifyIsBoolean(data.roleSelectionRequired), daysBeforePasswordExpiryTriggeringWarning: data.daysBeforePasswordExpiryTriggeringWarning && __verifyIsNumber(data.daysBeforePasswordExpiryTriggeringWarning), rememberMeStorage: data.rememberMeStorage && data.rememberMeStorage, rememberMeStoragePrefix: data.rememberMeStoragePrefix && __verifyIsString(data.rememberMeStoragePrefix), minutesOfInactivityTriggeringAutoLogout: data.minutesOfInactivityTriggeringAutoLogout && __verifyIsNumber(data.minutesOfInactivityTriggeringAutoLogout), userNamePrefixConfiguration: data.userNamePrefixConfiguration && UserNamePrefixConfiguration.fromData(data.userNamePrefixConfiguration), whatsNewConfiguration: data.whatsNewConfiguration && WhatsNewConfiguration.fromData(data.whatsNewConfiguration) };
        return instance;
    }

    static getProperties(): string[] {
        return ['realmName','roleSelectionRequired','daysBeforePasswordExpiryTriggeringWarning','rememberMeStorage','rememberMeStoragePrefix','minutesOfInactivityTriggeringAutoLogout','userNamePrefixConfiguration','whatsNewConfiguration'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.ModuleDto
 */
export class Module {
    name?: string;

    static fromData(data: any): Module {
        if (!data) {
            return data;
        }
        const instance: Module = { name: data.name && __verifyIsString(data.name) };
        return instance;
    }

    static getProperties(): string[] {
        return ['name'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.RoleAuthorizationDto
 */
export class RoleAuthorization {
    role?: string;
    permission?: PermissionType;

    static fromData(data: any): RoleAuthorization {
        if (!data) {
            return data;
        }
        const instance: RoleAuthorization = { role: data.role && __verifyIsString(data.role), permission: data.permission && data.permission };
        return instance;
    }

    static getProperties(): string[] {
        return ['role','permission'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.RoleDto
 */
export class Role {
    id?: string;
    name?: string;
    displayName?: string;
    composite?: boolean;
    compositeRoleNames?: string[];
    featureAuthorizations?: FeatureAuthorization[];
    modules?: Module[];
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };

    static fromData(data: any): Role {
        if (!data) {
            return data;
        }
        const instance: Role = { id: data.id && __verifyIsString(data.id), name: data.name && __verifyIsString(data.name), displayName: data.displayName && __verifyIsString(data.displayName), composite: data.composite && __verifyIsBoolean(data.composite), compositeRoleNames: data.compositeRoleNames && __getCopyArrayFn(__identity<string>())(data.compositeRoleNames), featureAuthorizations: data.featureAuthorizations && __getCopyArrayFn(FeatureAuthorization.fromData)(data.featureAuthorizations), modules: data.modules && __getCopyArrayFn(Module.fromData)(data.modules), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','name','displayName','composite','compositeRoleNames','featureAuthorizations','modules','dynamicAttributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.SecurityConfigurationDto
 */
export class SecurityConfiguration {
    accessRestrictions?: AccessRestrictions;
    supportedAuthentications?: SupportedAuthentications;
    accessLevels?: AccessLevels;
    localAuthenticationAdapter?: LocalAuthenticationAdapter;
    keycloakAuthenticationAdapter?: KeycloakAuthenticationAdapter;

    static fromData(data: any): SecurityConfiguration {
        if (!data) {
            return data;
        }
        const instance: SecurityConfiguration = { accessRestrictions: data.accessRestrictions && AccessRestrictions.fromData(data.accessRestrictions), supportedAuthentications: data.supportedAuthentications && SupportedAuthentications.fromData(data.supportedAuthentications), accessLevels: data.accessLevels && AccessLevels.fromData(data.accessLevels), localAuthenticationAdapter: data.localAuthenticationAdapter && LocalAuthenticationAdapter.fromData(data.localAuthenticationAdapter), keycloakAuthenticationAdapter: data.keycloakAuthenticationAdapter && KeycloakAuthenticationAdapter.fromData(data.keycloakAuthenticationAdapter) };
        return instance;
    }

    static getProperties(): string[] {
        return ['accessRestrictions','supportedAuthentications','accessLevels','localAuthenticationAdapter','keycloakAuthenticationAdapter'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.SupportedAuthenticationsDto
 */
export class SupportedAuthentications {
    loginConfiguration?: LoginConfiguration;

    static fromData(data: any): SupportedAuthentications {
        if (!data) {
            return data;
        }
        const instance: SupportedAuthentications = { loginConfiguration: data.loginConfiguration && LoginConfiguration.fromData(data.loginConfiguration) };
        return instance;
    }

    static getProperties(): string[] {
        return ['loginConfiguration'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.UserDto
 */
export class User {
    id?: string;
    userName?: string;
    person?: Person;
    roles?: Role[];
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };
    metadata?: { [index: string]: any };

    static fromData(data: any): User {
        if (!data) {
            return data;
        }
        const instance: User = { id: data.id && __verifyIsString(data.id), userName: data.userName && __verifyIsString(data.userName), person: data.person && Person.fromData(data.person), roles: data.roles && __getCopyArrayFn(Role.fromData)(data.roles), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','userName','person','roles','dynamicAttributes','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.security.WhatsNewConfigurationDto
 */
export class WhatsNewConfiguration {
    label?: string;
    url?: string;

    static fromData(data: any): WhatsNewConfiguration {
        if (!data) {
            return data;
        }
        const instance: WhatsNewConfiguration = { label: data.label && __verifyIsString(data.label), url: data.url && __verifyIsString(data.url) };
        return instance;
    }

    static getProperties(): string[] {
        return ['label','url'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.theme.ColorSchemeDto
 */
export class ColorScheme {
    main?: string;
    contrast?: string;
    light?: string;
    dark?: string;
    textPrimary?: string;
    textSecondary?: string;
    disabled?: string;
    divider?: string;

    static fromData(data: any): ColorScheme {
        if (!data) {
            return data;
        }
        const instance: ColorScheme = { main: data.main && __verifyIsString(data.main), contrast: data.contrast && __verifyIsString(data.contrast), light: data.light && __verifyIsString(data.light), dark: data.dark && __verifyIsString(data.dark), textPrimary: data.textPrimary && __verifyIsString(data.textPrimary), textSecondary: data.textSecondary && __verifyIsString(data.textSecondary), disabled: data.disabled && __verifyIsString(data.disabled), divider: data.divider && __verifyIsString(data.divider) };
        return instance;
    }

    static getProperties(): string[] {
        return ['main','contrast','light','dark','textPrimary','textSecondary','disabled','divider'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.theme.PaletteDto
 */
export class Palette {
    background?: ColorScheme;
    primary?: ColorScheme;
    secondary?: ColorScheme;
    error?: ColorScheme;
    warning?: ColorScheme;
    info?: ColorScheme;
    success?: ColorScheme;
    custom1?: ColorScheme;
    custom2?: ColorScheme;
    custom3?: ColorScheme;
    custom4?: ColorScheme;
    custom5?: ColorScheme;

    static fromData(data: any): Palette {
        if (!data) {
            return data;
        }
        const instance: Palette = { background: data.background && ColorScheme.fromData(data.background), primary: data.primary && ColorScheme.fromData(data.primary), secondary: data.secondary && ColorScheme.fromData(data.secondary), error: data.error && ColorScheme.fromData(data.error), warning: data.warning && ColorScheme.fromData(data.warning), info: data.info && ColorScheme.fromData(data.info), success: data.success && ColorScheme.fromData(data.success), custom1: data.custom1 && ColorScheme.fromData(data.custom1), custom2: data.custom2 && ColorScheme.fromData(data.custom2), custom3: data.custom3 && ColorScheme.fromData(data.custom3), custom4: data.custom4 && ColorScheme.fromData(data.custom4), custom5: data.custom5 && ColorScheme.fromData(data.custom5) };
        return instance;
    }

    static getProperties(): string[] {
        return ['background','primary','secondary','error','warning','info','success','custom1','custom2','custom3','custom4','custom5'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.theme.ThemeConfigurationDto
 */
export class ThemeConfiguration {
    defaultTheme?: string;
    themes?: { [index: string]: Theme };

    static fromData(data: any): ThemeConfiguration {
        if (!data) {
            return data;
        }
        const instance: ThemeConfiguration = { defaultTheme: data.defaultTheme && __verifyIsString(data.defaultTheme), themes: data.themes && __getCopyObjectFn(Theme.fromData)(data.themes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['defaultTheme','themes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.theme.ThemeDto
 */
export class Theme {
    palette?: Palette;
    images?: { [index: string]: string };
    overrides?: { [index: string]: InnerEnum };

    static fromData(data: any): Theme {
        if (!data) {
            return data;
        }
        const instance: Theme = { palette: data.palette && Palette.fromData(data.palette), images: data.images && __getCopyObjectFn(__identity<string>())(data.images), overrides: data.overrides && __getCopyObjectFn(__identity<InnerEnum>())(data.overrides) };
        return instance;
    }

    static getProperties(): string[] {
        return ['palette','images','overrides'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ActionDto
 */
export class Action {
    id?: string;
    icon?: string;
    action?: ActionType;
    label?: string;
    parameters?: Parameter[];
    objectParameters?: ObjectParameter[];
    visible?: string;

    static fromData(data: any): Action {
        if (!data) {
            return data;
        }
        const instance: Action = { id: data.id && __verifyIsString(data.id), icon: data.icon && __verifyIsString(data.icon), action: data.action && data.action, label: data.label && __verifyIsString(data.label), parameters: data.parameters && __getCopyArrayFn(Parameter.fromData)(data.parameters), objectParameters: data.objectParameters && __getCopyArrayFn(ObjectParameter.fromData)(data.objectParameters), visible: data.visible && __verifyIsString(data.visible) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','icon','action','label','parameters','objectParameters','visible'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ActivityStreamParameterDto
 */
export class ActivityStreamParameter {
    manualEntryCreation?: boolean;

    static fromData(data: any): ActivityStreamParameter {
        if (!data) {
            return data;
        }
        const instance: ActivityStreamParameter = { manualEntryCreation: data.manualEntryCreation && __verifyIsBoolean(data.manualEntryCreation) };
        return instance;
    }

    static getProperties(): string[] {
        return ['manualEntryCreation'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ArrayParameterDto
 */
export class ArrayParameter {
    key?: string;
    values?: string[];

    static fromData(data: any): ArrayParameter {
        if (!data) {
            return data;
        }
        const instance: ArrayParameter = { key: data.key && __verifyIsString(data.key), values: data.values && __getCopyArrayFn(__identity<string>())(data.values) };
        return instance;
    }

    static getProperties(): string[] {
        return ['key','values'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.EntitySourceDto
 */
export class EntitySource {
    entityType?: string;
    typeDisplay?: string;
    valueDisplay?: string;
    initialOptionsUrl?: string;
    optionsBySearchUrl?: string;
    optionsSortOrder?: SortOrder;

    static fromData(data: any): EntitySource {
        if (!data) {
            return data;
        }
        const instance: EntitySource = { entityType: data.entityType && __verifyIsString(data.entityType), typeDisplay: data.typeDisplay && __verifyIsString(data.typeDisplay), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), initialOptionsUrl: data.initialOptionsUrl && __verifyIsString(data.initialOptionsUrl), optionsBySearchUrl: data.optionsBySearchUrl && __verifyIsString(data.optionsBySearchUrl), optionsSortOrder: data.optionsSortOrder && data.optionsSortOrder };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityType','typeDisplay','valueDisplay','initialOptionsUrl','optionsBySearchUrl','optionsSortOrder'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.FilterParameterDto
 */
export class FilterParameter {
    id?: string;
    readOnly?: boolean;

    static fromData(data: any): FilterParameter {
        if (!data) {
            return data;
        }
        const instance: FilterParameter = { id: data.id && __verifyIsString(data.id), readOnly: data.readOnly && __verifyIsBoolean(data.readOnly) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','readOnly'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ObjectLinkingParameterDto
 */
export class ObjectLinkingParameter {
    cardinality?: Cardinality;
    source?: EntitySource;

    static fromData(data: any): ObjectLinkingParameter {
        if (!data) {
            return data;
        }
        const instance: ObjectLinkingParameter = { cardinality: data.cardinality && data.cardinality, source: data.source && EntitySource.fromData(data.source) };
        return instance;
    }

    static getProperties(): string[] {
        return ['cardinality','source'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ObjectParameterDto
 */
export class ObjectParameter {
    key?: string;
    attributes?: Parameter[];

    static fromData(data: any): ObjectParameter {
        if (!data) {
            return data;
        }
        const instance: ObjectParameter = { key: data.key && __verifyIsString(data.key), attributes: data.attributes && __getCopyArrayFn(Parameter.fromData)(data.attributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['key','attributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ParameterDto
 */
export class Parameter {
    key?: string;
    value?: string;

    static fromData(data: any): Parameter {
        if (!data) {
            return data;
        }
        const instance: Parameter = { key: data.key && __verifyIsString(data.key), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['key','value'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ParameterListDto
 */
export class ParameterList {
    filter?: FilterParameter;
    objectLinking?: ObjectLinkingParameter;
    activityStream?: ActivityStreamParameter;
    genericParameters?: Parameter[];
    arrayParameters?: ArrayParameter[];
    objectParameters?: ObjectParameter[];

    static fromData(data: any): ParameterList {
        if (!data) {
            return data;
        }
        const instance: ParameterList = { filter: data.filter && FilterParameter.fromData(data.filter), objectLinking: data.objectLinking && ObjectLinkingParameter.fromData(data.objectLinking), activityStream: data.activityStream && ActivityStreamParameter.fromData(data.activityStream), genericParameters: data.genericParameters && __getCopyArrayFn(Parameter.fromData)(data.genericParameters), arrayParameters: data.arrayParameters && __getCopyArrayFn(ArrayParameter.fromData)(data.arrayParameters), objectParameters: data.objectParameters && __getCopyArrayFn(ObjectParameter.fromData)(data.objectParameters) };
        return instance;
    }

    static getProperties(): string[] {
        return ['filter','objectLinking','activityStream','genericParameters','arrayParameters','objectParameters'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.ActionItemDto
 */
export class ActionItem {
    itemType?: ItemTypeEnum;
    actionRef?: string;

    static fromData(data: any): ActionItem {
        if (!data) {
            return data;
        }
        const instance: ActionItem = { itemType: data.itemType && data.itemType, actionRef: data.actionRef && __verifyIsString(data.actionRef) };
        return instance;
    }

    static getProperties(): string[] {
        return ['itemType','actionRef'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.GlobalActionsDto
 */
export class GlobalActions {
    startItems?: ActionItem[];
    endItems?: ActionItem[];

    static fromData(data: any): GlobalActions {
        if (!data) {
            return data;
        }
        const instance: GlobalActions = { startItems: data.startItems && __getCopyArrayFn(ActionItem.fromData)(data.startItems), endItems: data.endItems && __getCopyArrayFn(ActionItem.fromData)(data.endItems) };
        return instance;
    }

    static getProperties(): string[] {
        return ['startItems','endItems'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.MainAreaDto
 */
export class MainArea {
    tabView?: TabView;
    singleView?: ViewRef;

    static fromData(data: any): MainArea {
        if (!data) {
            return data;
        }
        const instance: MainArea = { tabView: data.tabView && TabView.fromData(data.tabView), singleView: data.singleView && ViewRef.fromData(data.singleView) };
        return instance;
    }

    static getProperties(): string[] {
        return ['tabView','singleView'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.MenuItemDto
 */
export class MenuItem {
    type: "ACTION_ITEM" | "DIVIDER";

    static fromData(data: any): MenuItem {
        if (!data) {
            return data;
        }
        const instance: MenuItem = { type: data.type };
        return instance;
    }

    static fromDataUnion(data: MenuItemUnion): MenuItemUnion {
        if (!data) {
            return data;
        }
        switch (data.type) {
            case "ACTION_ITEM":
                return MenuActionItem.fromData(data);
            case "DIVIDER":
                return MenuDivider.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['type'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.MenuActionItemDto
 */
export class MenuActionItem extends MenuItem {
    type: "ACTION_ITEM";
    action?: Action;

    static fromData(data: any): MenuActionItem {
        if (!data) {
            return data;
        }
        const instance: MenuActionItem = { ...(super.fromData(data) as MenuActionItem), action: data.action && Action.fromData(data.action) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','action'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.MenuDividerDto
 */
export class MenuDivider extends MenuItem {
    type: "DIVIDER";

    static fromData(data: any): MenuDivider {
        if (!data) {
            return data;
        }
        const instance: MenuDivider = { ...(super.fromData(data) as MenuDivider) };
        return instance;
    }

    static getProperties(): string[] {
        return ['type'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.MenuDto
 */
export class Menu {
    icon?: string;
    label?: string;
    visible?: string;
    items?: MenuItemUnion[];

    static fromData(data: any): Menu {
        if (!data) {
            return data;
        }
        const instance: Menu = { icon: data.icon && __verifyIsString(data.icon), label: data.label && __verifyIsString(data.label), visible: data.visible && __verifyIsString(data.visible), items: data.items && __getCopyArrayFn(MenuItem.fromDataUnion)(data.items) };
        return instance;
    }

    static getProperties(): string[] {
        return ['icon','label','visible','items'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.PerspectiveDto
 */
export class Perspective {
    id?: string;
    label?: string;
    available?: string;
    globalActions?: GlobalActions;
    sideBar?: SideBar;
    mainArea?: MainArea;

    static fromData(data: any): Perspective {
        if (!data) {
            return data;
        }
        const instance: Perspective = { id: data.id && __verifyIsString(data.id), label: data.label && __verifyIsString(data.label), available: data.available && __verifyIsString(data.available), globalActions: data.globalActions && GlobalActions.fromData(data.globalActions), sideBar: data.sideBar && SideBar.fromData(data.sideBar), mainArea: data.mainArea && MainArea.fromData(data.mainArea) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','label','available','globalActions','sideBar','mainArea'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.TabGroupDto
 */
export class TabGroup {
    groupId?: string;
    tabs?: ViewRef[];

    static fromData(data: any): TabGroup {
        if (!data) {
            return data;
        }
        const instance: TabGroup = { groupId: data.groupId && __verifyIsString(data.groupId), tabs: data.tabs && __getCopyArrayFn(ViewRef.fromData)(data.tabs) };
        return instance;
    }

    static getProperties(): string[] {
        return ['groupId','tabs'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.TabViewDto
 */
export class TabView {
    tabGroups?: TabGroup[];

    static fromData(data: any): TabView {
        if (!data) {
            return data;
        }
        const instance: TabView = { tabGroups: data.tabGroups && __getCopyArrayFn(TabGroup.fromData)(data.tabGroups) };
        return instance;
    }

    static getProperties(): string[] {
        return ['tabGroups'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.UiConfigurationDefinitionDto
 */
export class UiConfigurationDefinition {
    version?: string;
    uiConfiguration?: UiConfiguration;

    static fromData(data: any): UiConfigurationDefinition {
        if (!data) {
            return data;
        }
        const instance: UiConfigurationDefinition = { version: data.version && __verifyIsString(data.version), uiConfiguration: data.uiConfiguration && UiConfiguration.fromData(data.uiConfiguration) };
        return instance;
    }

    static getProperties(): string[] {
        return ['version','uiConfiguration'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.UiConfigurationDto
 */
export class UiConfiguration {
    actions?: Action[];
    views?: View[];
    sidebars?: View[];
    perspectives?: Perspective[];

    static fromData(data: any): UiConfiguration {
        if (!data) {
            return data;
        }
        const instance: UiConfiguration = { actions: data.actions && __getCopyArrayFn(Action.fromData)(data.actions), views: data.views && __getCopyArrayFn(View.fromData)(data.views), sidebars: data.sidebars && __getCopyArrayFn(View.fromData)(data.sidebars), perspectives: data.perspectives && __getCopyArrayFn(Perspective.fromData)(data.perspectives) };
        return instance;
    }

    static getProperties(): string[] {
        return ['actions','views','sidebars','perspectives'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.ViewDto
 */
export class View {
    id?: string;
    viewType?: string;
    label?: string;
    available?: string;
    viewParameters?: ParameterList;

    static fromData(data: any): View {
        if (!data) {
            return data;
        }
        const instance: View = { id: data.id && __verifyIsString(data.id), viewType: data.viewType && __verifyIsString(data.viewType), label: data.label && __verifyIsString(data.label), available: data.available && __verifyIsString(data.available), viewParameters: data.viewParameters && ParameterList.fromData(data.viewParameters) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','viewType','label','available','viewParameters'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.config.ViewRefDto
 */
export class ViewRef {
    viewId?: string;

    static fromData(data: any): ViewRef {
        if (!data) {
            return data;
        }
        const instance: ViewRef = { viewId: data.viewId && __verifyIsString(data.viewId) };
        return instance;
    }

    static getProperties(): string[] {
        return ['viewId'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.update.DataLoadDto
 */
export class DataLoad {
    changes?: ObjectDataUpdate[];

    static fromData(data: any): DataLoad {
        if (!data) {
            return data;
        }
        const instance: DataLoad = { changes: data.changes && __getCopyArrayFn(ObjectDataUpdate.fromData)(data.changes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['changes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.update.DataUpdateDto
 */
export class DataUpdate {
    entityType?: string;
    entityId?: string;
    entityVersion?: string;
    updateType?: DataUpdateType;
    accessRestriction?: RoleAccessRestriction;
    userName?: string;
    publishingDeleteForMissingAccess?: boolean;
    metadata?: { [index: string]: any };

    static fromData(data: any): DataUpdate {
        if (!data) {
            return data;
        }
        const instance: DataUpdate = { entityType: data.entityType && __verifyIsString(data.entityType), entityId: data.entityId && __verifyIsString(data.entityId), entityVersion: data.entityVersion && __verifyIsString(data.entityVersion), updateType: data.updateType && data.updateType, accessRestriction: data.accessRestriction && RoleAccessRestriction.fromData(data.accessRestriction), userName: data.userName && __verifyIsString(data.userName), publishingDeleteForMissingAccess: data.publishingDeleteForMissingAccess && __verifyIsBoolean(data.publishingDeleteForMissingAccess), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityType','entityId','entityVersion','updateType','accessRestriction','userName','publishingDeleteForMissingAccess','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.update.ObjectDataUpdateDto
 */
export class ObjectDataUpdate {
    object?: any;
    dataUpdate?: DataUpdate;
    metadata?: { [index: string]: any };

    static fromData(data: any): ObjectDataUpdate {
        if (!data) {
            return data;
        }
        const instance: ObjectDataUpdate = { object: data.object && data.object, dataUpdate: data.dataUpdate && DataUpdate.fromData(data.dataUpdate), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['object','dataUpdate','metadata'];
    }
}

export type SeverityEnum = "WARNING" | "ERROR";

export type LogLevelEnum = "TRACE" | "DEBUG" | "INFO" | "LOG" | "WARN" | "ERROR";

export type FilterConditionObjectType = "ITEM" | "GROUP";

export type FilterOperator = "EQUALS" | "NOT_EQUALS" | "CONTAINS" | "NOT_CONTAINS" | "STARTS_WITH" | "NOT_STARTS_WITH" | "ENDS_WITH" | "NOT_ENDS_WITH" | "MATCHES_REGEX" | "NOT_MATCHES_REGEX" | "IS_GREATER" | "IS_GREATER_EQUALS" | "IS_LESS" | "IS_LESS_EQUALS" | "IS_IN" | "NOT_IS_IN" | "OVERLAPS" | "NOT_OVERLAPS" | "IS_BETWEEN" | "NOT_IS_BETWEEN";

export type FilterOptionType = "ENTITY" | "EVENT";

export type FilterUserOperation = "IS_EMPTY" | "NOT_IS_EMPTY" | "EQUALS" | "NOT_EQUALS" | "CONTAINS_ALL" | "NOT_CONTAINS_ALL" | "CONTAINS_ANY" | "NOT_CONTAINS_ANY" | "CONTAINS" | "NOT_CONTAINS" | "STARTS_WITH" | "NOT_STARTS_WITH" | "ENDS_WITH" | "NOT_ENDS_WITH" | "MATCHES_REGEX" | "NOT_MATCHES_REGEX" | "IS_GREATER" | "IS_GREATER_EQUALS" | "IS_LESS" | "IS_LESS_EQUALS" | "IS_IN" | "NOT_IS_IN" | "OVERLAPS" | "NOT_OVERLAPS" | "IS_BEFORE" | "IS_AFTER";

export type FilterValueCardinality = "SINGLE" | "MULTI" | "RANGE";

export type FilterValueType = "STRING" | "NUMBER" | "BOOLEAN" | "DATETIME" | "DATE" | "TIME" | "ENUM" | "GEOMETRY" | "PERIOD";

export type LogicalOperator = "AND" | "OR" | "NOT";

export type Order = "ASC" | "DESC";

export type FormFieldListColumnType = "DATA" | "ACTION";

export type GeoJsonFeatureType = "Feature";

export type GeoJsonGeometryCollectionType = "GeoJsonGeometryCollection";

export type IconSlotType = "LEADING" | "DEFAULT";

export type ListFilterComponentType = "DEFAULT" | "CHECKBOX";

export type ListOverflowType = "DEFAULT" | "ELLIPSIS";

export type TextSlotType = "TITLE" | "SUBTITLE" | "LINE" | "CAPTION_LINE" | "EXPANDABLE";

export type ReportFormat = "PDF" | "HTML" | "CSV";

export type BrowserStorageType = "SESSION" | "LOCAL" | "COOKIE";

export type FeatureName = "STANDBY" | "RECURRING_STANDBY" | "CHAT" | "CHAT_ADMINISTRATION";

export type PermissionType = "NONE" | "READ_ONLY" | "WRITE_READ";

export type InnerEnum = "background" | "primary" | "secondary" | "error" | "warning" | "info" | "success" | "custom1" | "custom2" | "custom3" | "custom4" | "custom5";

export type ActionType = "SAVE" | "ADD" | "OPEN" | "PRINT" | "CLOSE" | "BOOKMARK_ADD" | "BOOKMARK_REMOVE" | "DELETE" | "OPEN_URL" | "DOWNLOAD" | "REFRESH" | "INVOKE_URL" | "CREATE_ENTITY" | "CREATE_WEAK_ENTITY" | "OPEN_SEND_MESSAGE_DIALOG";

export type Cardinality = "SINGLE" | "LIST";

export type ComponentType = "TEXT_FIELD" | "TEXT_AREA" | "DATE_PICKER" | "TIME_PICKER" | "DATETIME_PICKER" | "SELECT_SINGLE" | "SELECT_MULTI" | "CHECKBOX" | "RADIOBUTTON" | "LIST" | "PROCESS_MODEL" | "LABEL" | "MEDIA_STREAM_PLAYER" | "WEATHER_BUTTON" | "DEMO" | "IFRAME";

export type Orientation = "VERTICAL" | "HORIZONTAL";

export type PasswordState = "VALID" | "BAD" | "EXPIRED" | "EMPTY" | "SSO";

export type PasswordValidation = "VALID" | "INVALID";

export type SortOrder = "ASC" | "DESC";

export type ItemTypeEnum = "DIVIDER" | "ACTION_REF";

export type DataUpdateType = "CREATE" | "UPDATE" | "DELETE";

export type FilterConditionBaseUnion = FilterConditionGroup | FilterConditionItem;

export type AbstractFormConfigurationUnion = EntityFormConfiguration | GenericFormConfiguration;

export type AbstractFormFieldUnion = EntityFormField | GenericFormField;

export type AbstractFormFieldListColumnUnion = EntityFormFieldListColumn | GenericFormFieldListColumn;

export type AbstractFormFieldListConfigurationUnion = EntityFormFieldListConfiguration | GenericFormFieldListConfiguration;

export type AbstractFormGroupUnion = EntityFormGroup | GenericFormGroup;

export type AbstractWidgetUnion = AbstractFormField | EntityJournal | EntityList | EntityWorkflowActiveTasks;

export type GeoJsonGeometryUnion = GeoJsonLineString | GeoJsonMultiLineString | GeoJsonMultiPoint | GeoJsonMultiPolygon | GeoJsonPoint | GeoJsonPolygon;

export type RowElementUnion = ListKeyValueColumn | OnClickAction | RowActions | RowIconGroupElement | RowTextElement | SubListElement;

export type MenuItemUnion = MenuActionItem | MenuDivider;

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
