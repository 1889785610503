/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Entity} from "../generated/api";
import {EntityProperties} from "./EntityProperties";

const DYNAMIC_ATTRIBUTES_PREFIX = `${EntityProperties.DYNAMIC_ATTRIBUTES_PROPERTY}.`;

/**
 * @param propertyName
 * @returns the dynamic attribute's name (if the given propertyName is a dynamic attribute),
 * e.g., `simple` will be returned for `dynamicAttributes.simple.valueDisplay`.
 * If the given property is no dynamic attribute it is returned as-is.
 */
export function getDynamicAttributeName(propertyName?: string): string | undefined {
  if (propertyName?.startsWith(DYNAMIC_ATTRIBUTES_PREFIX)) {
    return propertyName.substring(DYNAMIC_ATTRIBUTES_PREFIX.length)
      .split(".", 1)[0];
  }
  return propertyName;
}

/**
 * @param entity Entity
 * Every entity has a keys array, this array contains the entity id (uuid), client side generated keys and server side generated keys.
 * Those keys are order by the backend, starting from the most important one ( highest key priority == lowest inter value).
 * @returns entity key with the highest key priority excluding the default uuid key.
 */
export const getHighestEntityKeyValueIfPossible = (entity: Entity): string | undefined => {
  if (!entity.keys || entity.keys.length <= 1) {
    return undefined;
  }
  return entity.keys[0].value ?? undefined;
};
