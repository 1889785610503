/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FilterComponentProps, MessageKey, SharedFilterController, useMessages} from "@icm/core-common";
import {Add, Clear, Delete, Edit, MoreVertRounded} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";

import {useWebConfirmDialog} from "../dialog";
import {useMenuController} from "../util";
import {BasicFilter} from "./BasicFilter";
import {styles} from "./SavingFilterStyle";

const useStyles = makeStyles(styles);

/**
 * This component provides a UI for a shared filter control. Thus, multiple instances of this component might
 * be created for the list, the activity stream, the map or even within the detail view of single entities.
 */
export const WebFilter: React.FC<FilterComponentProps> = (props: FilterComponentProps) => {
  const {getMessage} = useMessages();
  const {controller} = props;
  const classes = useStyles();

  const menuController = useMenuController();

  const confirmDialog = useWebConfirmDialog();

  /**
   * Delete the currently selected filter if the user confirms the popup dialog.
   * Reset to the fallback filter in any case.
   */
  const deleteFilter = useCallback(() => {
    confirmDialog({
      title: getMessage(MessageKey.CORE.FORM.CONFIRM_DELETE),
      description: getMessage(MessageKey.CORE.FILTER.REMOVE_QUESTION),
    })
      .then(deletionConfirmed => {
        if (deletionConfirmed) {
          controller.deleteCurrentFilterSelection()
            .then(_ => controller.resetFilter());
        }
      });
  }, [confirmDialog, controller, getMessage]);

  return useMemo(() => (
    <Accordion elevation={0}
               classes={{root: classes.expansionPanel}}
               className="dontPrint"
               square={true}
               expanded={controller.isEditing()}
    >
      <AccordionSummary
        classes={{
          root: classes.expansionPanelSummary,
          expanded: classes.expansionPanelSummaryContent,
          content: classes.expansionPanelSummaryContent,
        }}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item flex="1">
            <FilterSelector controller={controller} />
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button disabled={!controller.canEditCurrentFilter()}
                      onClick={() => controller.startEdit(false)}
                      aria-label={getMessage(MessageKey.CORE.FILTER.EDIT_VIEW)}
                      endIcon={<Edit />}
              >
                {getMessage(MessageKey.CORE.FILTER.LABEL)}
              </Button>
              <Button disabled={!controller.canResetFilter()}
                      aria-label={getMessage(MessageKey.CORE.FILTER.RESET)}
                      onClick={() => controller.resetFilter()}
              >
                <Clear />
              </Button>
              <Button onClick={menuController.openMenu}
                      aria-label={getMessage(MessageKey.CORE.OPTIONS)}
              >
                <MoreVertRounded />
              </Button>
            </ButtonGroup>

            <Menu open={menuController.isMenuOpen}
                  anchorEl={menuController.menuAnchor}
                  onClose={menuController.closeMenu}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  transformOrigin={{vertical: "top", horizontal: "right"}}
                  elevation={1}
            >
              <MenuItem key="create-option"
                        disabled={!controller.canCreateNewFilter()}
                        onClick={event => {
                          controller.startEdit(true);
                          menuController.closeMenu(event);
                        }}
                        aria-label="create filter"
              >
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <ListItemText>
                  {getMessage(MessageKey.CORE.FILTER.SELECTION.NEW)}
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem key="delete-option"
                        disabled={!controller.canDeleteCurrentFilter()}
                        onClick={event => {
                          deleteFilter();
                          menuController.closeMenu(event);
                        }}
                        aria-label="delete filter"
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText>
                  {getMessage(MessageKey.CORE.FILTER.REMOVE)}
                </ListItemText>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.panelContent}>
        <BasicFilter filterOptions={controller.filterOptions}
                     onUpdateSelectedFilters={(selectedFilters) => controller.updateCurrentFilterSelectionItems(selectedFilters)}
                     selectedFilters={controller.currentFilterSelectionItems}
                     addFilterIcon={<Add />}
                     onCancel={() => controller.stopEdit(false)}
                     onSave={() => controller.stopEdit(true)}
        />
      </AccordionDetails>
    </Accordion>
    ), [classes, controller, deleteFilter, getMessage, menuController]);
};

type FilterSelectorProps = {
  controller: SharedFilterController
}

const FilterSelector = (props: FilterSelectorProps) => {
  const {controller} = props;
  const {getMessage} = useMessages();

  const stopPropagation = (event: any) => { // retype to any to use stop propagation
    event.stopPropagation && event.stopPropagation();
  };

  const currentFilterSelection = controller.currentFilterSelection;
  const availableFilterSelections = controller.availableFilterSelections;

  // store the filter name internally in order to avoid complex recalculation of the
  // ui that depends on the controller
  const [filterName, setFilterName] = useState<string>(currentFilterSelection.name ?? "");
  useEffect(() => {
    setFilterName(currentFilterSelection.name);
  }, [currentFilterSelection.name]);

  return useMemo(() => {
    if (controller.isEditing() || controller.isSaving()) {
      return (
        // It would be nice to autofocus the text field when the user starts editing.
        // However, this would cause trouble in case 2 or more components share the filter.
        // In this case the focus would always travel to one of the text fields.
        <FormControl fullWidth>
          <TextField value={filterName}
                     label={getMessage(MessageKey.CORE.FILTER.EDIT.LABEL)}
                     disabled={controller.isSaving()}
                     onChange={e => setFilterName(e.currentTarget.value)}
                     onBlur={e => {
                       controller.updateFilterName(e.currentTarget.value);
                     }}
                     onFocus={e => {
                       // avoid focussing the accordion summary
                       e.stopPropagation();
                     }}
                     variant="standard"
                     fullWidth
          />
        </FormControl>
      );
    } else {
      return (
        <FormControl fullWidth size="small">
          <InputLabel id="selector-label">{getMessage(MessageKey.CORE.FILTER.SELECTION.NAME)}</InputLabel>
          <Select variant="standard"
                  labelId="selectorLabel"
                  fullWidth
                  aria-label={getMessage(MessageKey.CORE.FILTER.SELECTION.NAME)}
                  disabled={!controller.canChangeCurrentFilterSelection()}
                  value={currentFilterSelection?.id ?? 0}
                  onFocusCapture={stopPropagation}
                  onFocus={stopPropagation}
                  onClose={stopPropagation}
          >

            {availableFilterSelections.map(filterSelection => (
              <MenuItem value={filterSelection.id}
                        key={filterSelection.id}
                        aria-label={filterSelection.name}
                        onClick={(event) => {
                          controller.setCurrentFilterSelection(filterSelection);
                          event.stopPropagation();
                        }}
              >
                {filterSelection.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }, [availableFilterSelections, controller, currentFilterSelection, filterName, getMessage]);
};

export const webFilterComponentFactory = (props: FilterComponentProps) => <WebFilter {...props} />;
