/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Grid, Typography} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import * as React from "react";

import {IcmMuiTheme} from "../../theme";

const styles = (theme: IcmMuiTheme) => createStyles({
  root: {
    marginTop: theme.spacing(4),
    color: theme.palette.action.active,
  },
});

type ViewInfoMessageProps = {
  icon?: React.ReactNode,
  message: string,
}

const useStyles = makeStyles(styles);

export const ViewInfoMessage = ({icon, message}: ViewInfoMessageProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} direction="column" justifyContent="center" alignItems="center">
      {icon && (
        <Grid item>
          {icon}
        </Grid>
      )}
      <Grid item>
        <Typography variant="subtitle1" color="inherit">{message}</Typography>
      </Grid>
    </Grid>
  );
};
