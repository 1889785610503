/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState} from "../types";
import {findTabGroup} from "./PerspectiveHelper";


/**
 * Set the currentActiveTab group of a perspective. When opening a
 * tab, the active group will be used as a parent. If the id provided
 * does not match any group, this action will be a NO-OP.
 *
 * @param state
 * @param action
 * @constructor
 */
export const ActivateTabGroup = (state: IUiState, action: PayloadAction<{tabGroupId: string}>) => {
  const perspective = state.perspective;
  const {tabGroupId} = action.payload;

  if (perspective?.perspectiveType === "TABBED") {
    const tabGroup = findTabGroup(perspective, tabGroupId);
    if (tabGroup && perspective) {
      perspective.currentTabGroupId = tabGroup.id;
      console.debug("Activated tab group", perspective.currentTabGroupId);
    }
  } else {
    console.warn("Can't activate TabGroup in non-tabbed perspective");
  }
};
