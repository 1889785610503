/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useLayoutEffect, useState} from "react";

import {useTabContentActions} from "./useTabContentActions";
import {TabGroupDescriptor} from "./useTabGroupDescriptor";

/** Tab group header should show all buttons */
export const MAXIMIZED = "MAXIMIZED";

/** Tab group header only shows a drop down menu */
export const MINIMIZED = "MINIMIZED";

export type TabHeaderMode = typeof MAXIMIZED | typeof MINIMIZED;

/** CLOSE, SPLIT, SHARE, one additional to keep distance , */
const DEFAULT_BUTTON_COUNT = 4;

const THEME_PADDING = 8;

/** 24 icon size + 8 left + 8 right */
const BUTTON_SIZE = 24 + 2 * THEME_PADDING;

/** Two buttons  + left and right margin  = 80 + 16 */
const MINIMIZED_BUTTON_WIDTH = 2 * BUTTON_SIZE + 2 * THEME_PADDING;

/**
 * Determine the TabHeaderMode based on the size of buttons.
 *
 * @param groupInfo
 * @param tabHeaderRef
 */
export const useHeaderMode = (
  groupInfo: TabGroupDescriptor,
  tabHeaderRef: React.MutableRefObject<HTMLElement | undefined>
): [TabHeaderMode, string] => {
  const [headerMode, setHeaderMode] = useState<TabHeaderMode>(MAXIMIZED);
  const [maxWidth, setMaxWidth] = useState<string>("100%");

  const tabContentActions = useTabContentActions(groupInfo.selectedTab.viewModel);

  useLayoutEffect(() => {
    if (tabHeaderRef.current?.offsetWidth) {
      const actionCount = tabContentActions ? tabContentActions.length : 0;
      const contentActionsSize = (actionCount * BUTTON_SIZE) + (BUTTON_SIZE * DEFAULT_BUTTON_COUNT) + 2 * THEME_PADDING;
      const availableGroupContainerWidth = groupInfo.size;
      const tabHeaderWidth = tabHeaderRef.current?.offsetWidth;
      if (tabHeaderWidth + contentActionsSize > availableGroupContainerWidth) {
        setHeaderMode(MINIMIZED);
        setMaxWidth(`${availableGroupContainerWidth - MINIMIZED_BUTTON_WIDTH}px`);
      } else {
        setHeaderMode(MAXIMIZED);
        setMaxWidth("100%");
      }
    }
    // refs must not be part of the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfo, tabContentActions, setMaxWidth]);

  return [headerMode, maxWidth];
};
