/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React from "react";

import {ICoreApplicationState} from "../../store";

/**
 * Feedback banners are only shown contextually and cannot be accessed after they have been closed.
 *
 * They are shown as snackbars.
 */
export type FeedbackBanner = {
  // unique key identifying the feedback banner
  key: string
  title: string
  variant: FeedbackVariant
  duration: FeedbackDuration
  // if this evaluates to true, the feedback banner will be omitted
  omitCondition?: (state: ICoreApplicationState) => boolean
  onClose?: () => void
  closeActions?: FeedbackAction[]
}

/**
 * - SHORT, MEDIUM, LONG: see implementation of FeedbackContextProvider component
 * - INDEFINITE: close on action or on closing manually
 */
export type FeedbackDuration = "SHORT" | "MEDIUM" | "LONG" | "INDEFINITE";

export type FeedbackVariant = "DEFAULT" | "ERROR" | "SUCCESS" | "WARNING" | "INFO";

export type FeedbackAction = {
  label: string
  action: () => void
}

export type FeedbackContextType = {
  openFeedbackBanner: (banner: FeedbackBanner) => void
  closeFeedbackBanner: (key: string) => void
}

export const FeedbackContext = React.createContext<FeedbackContextType>({
  openFeedbackBanner: () => console.error("No feedback context"),
  closeFeedbackBanner: () => console.error("No feedback context"),
});
