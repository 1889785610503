/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {createPushNotificationQueueItemKey} from "../helpers";
import {PushNotificationState} from "../types";

export type RemoveFromPushNotificationQueueOptions = {
  ids: string[]
  userName: string,
}

export const RemoveFromPushNotificationQueue = (state: PushNotificationState, action: PayloadAction<RemoveFromPushNotificationQueueOptions>) => {
  const {pushNotifications} = state;
  const {
    ids,
    userName,
  } = action.payload;
  const userKey = createPushNotificationQueueItemKey(userName);
  if (pushNotifications[userKey]) {
    pushNotifications[userKey] = pushNotifications[userKey].filter(item => !ids.includes(item.id));
  }
};
