/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useEffect, useMemo, useState} from "react";

import {FileReference} from "../../api";

export const useFileUpdateValueQueue = (onUpload: (fileReference: FileReference) => Promise<void>) => {
  const [updateValueQueue, setUpdateValueQueue] = useState<FileReference[]>([]);
  const [currentlyUpdatingItem, setCurrentlyUpdatingItem] = useState<FileReference | undefined>(undefined);
  useEffect(() => {
    if (currentlyUpdatingItem) {
      onUpload(currentlyUpdatingItem)
        .then(() => {
          setUpdateValueQueue(current => current.filter(item => item.id !== currentlyUpdatingItem.id));
          setCurrentlyUpdatingItem(undefined);
        });
    }
    // We only want to recreate the effect hook if the currentlyUpdating item changed
    // but not if the external onUpload or queue changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlyUpdatingItem]);
  useEffect(() => {
    if (updateValueQueue.length > 0 && !currentlyUpdatingItem) {
      setCurrentlyUpdatingItem(updateValueQueue[0]);
    }
  }, [updateValueQueue, currentlyUpdatingItem]);

  const queueInProgress = useMemo(() => updateValueQueue.length > 0, [updateValueQueue]);

  const addToQueue = useCallback((fileRef: FileReference) => {
    setUpdateValueQueue(current => [...current, fileRef]);
  }, []);

  return {
    queueInProgress,
    addToQueue,
  };
};
