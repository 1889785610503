/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";

import {Urls} from "../../constant";
import {
  ExpressionEvaluationService,
  userDownloadService,
} from "../../service";
import {ParameterUtilities, ResolvedParameter} from "../../util";
import {ActionRunner} from "./ActionRegistry";

/**
 * Download data from a specified URL.
 */
export const useDownloadActionHandler = (): ActionRunner => {
  return useCallback((parameters: ResolvedParameter[]) => {
    const entity = ParameterUtilities.getResolvedParameter("entity", parameters);
    const useFileReference = ParameterUtilities.getResolvedParameter("useFileReference", parameters);
    if (useFileReference) {
      const fileId = ExpressionEvaluationService.evaluate("e => e.dynamicAttributes.fileReference.value.id", entity);
      const fileName = ExpressionEvaluationService.evaluate("e => e.dynamicAttributes.name.value", entity);
      const fileUrl: string = `${Urls.FILE_DATA}/${fileId}`;
      console.log("Downloading file associated with entity", fileUrl);
      userDownloadService.startDownload(fileUrl, fileName);
    } else {
      const urlTemplate = ParameterUtilities.getResolvedParameter("URL", parameters);
      const fileName = ParameterUtilities.getResolvedParameter("fileName", parameters);
      const requestBody = ParameterUtilities.getResolvedParameter("requestBody", parameters);
      const url = entity ? ExpressionEvaluationService.evaluate(urlTemplate, entity) : urlTemplate;
      userDownloadService.startDownload(url, fileName, requestBody);
    }
  }, []);
};
