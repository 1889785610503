/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useContext, useEffect, useState} from "react";

import {ViewContentBoxContext} from "../../../section";

export const useActionbarOffset = (table: HTMLElement | null): number => {
  const [actionBarOffset, setActionBarOffset] = useState(0);
  const parent = useContext(ViewContentBoxContext);

  useEffect(() => {
    if (table != null && parent != null) {
      setActionBarOffset(getActionBarOffset(parent, table));
      parent.onscroll = (_) => {
        setActionBarOffset(getActionBarOffset(parent, table));
      };
      const observer = new ResizeObserver(() => {
        setActionBarOffset(getActionBarOffset(parent, table));
      });

      observer.observe(parent.children[0]);
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [table, setActionBarOffset, parent]);
  return actionBarOffset;
};

function getActionBarOffset(container: HTMLElement, table: HTMLElement) {
  const parentWidth = container.clientWidth ?? 0;
  const tableWidth = table.scrollWidth;
  const scrollOffset = container.scrollLeft;
  const scrolling = parentWidth < tableWidth;
  if (scrolling) {
    return Math.abs(tableWidth - parentWidth - scrollOffset);
  }
  return 0;
}
