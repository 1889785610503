/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActionRunner,
  IcmIconType,
  ParameterUtilities,
  ResolvedParameter,
  useOpenView,
  ViewActionHandlersByActionId,
} from "@icm/core-common";
import {ProcessDefinition} from "@icm/workflow-common";
import {useCallback, useEffect} from "react";

import {ProcessDefinitionDetails} from "../../data";
import {Entity} from "../../generated/api";
import {EntityEditService} from "../../service";

export type CreateEntityType = {
  value: string | null;
  icon?: IcmIconType;
  labelKey?: string;
  processVariant?: string;
}

/**
 * Handler to be associated with "CREATE_ENTITY"
 */
export const useCreateEntityActionHandler = (): ActionRunner => {
  const openView = useOpenView();
  return useCallback((parameters: ResolvedParameter[]) => {
    const viewId = ParameterUtilities.getResolvedParameter("viewId", parameters);
    const sourceEntity = ParameterUtilities.getResolvedParameter("sourceEntity", parameters);
    const targetType = ParameterUtilities.getResolvedParameter("entityType", parameters);
    const creationParameters = ParameterUtilities.getResolvedParameter("creationParameters", parameters);

    console.log("Creating new entity of type: ", targetType, "based on", sourceEntity, " with creationParameters:", creationParameters);
    const optionsPromise = EntityEditService.initializeEntityEditModel(targetType,  undefined, creationParameters,  sourceEntity);

    optionsPromise.then(options => {
      openView(viewId, {
        genericParameters: [{key: "entity", value: options.entity}],
      });
    });
  }, [openView]);
};

const useCreateEntity = (editViewId?: string) => {
  const openView = useOpenView();

  return useCallback((entityType: string, createEntityType: CreateEntityType, sourceEntity?: Entity, processDetails?: ProcessDefinitionDetails) => {
    if (editViewId) {
      console.log("Creating new entity of type: ", entityType, "based on", sourceEntity);
      const optionsPromise = EntityEditService.initializeEntityEditModel(entityType, undefined, {
        type: createEntityType,
      }, sourceEntity, processDetails);

      optionsPromise.then(opts => {
        openView(editViewId, {
          genericParameters: [
            {key: "entity", value: opts.entity},
            {key: "processDetails", value: processDetails},
          ],
        });
      });
    } else {
      console.warn("No editViewId given");
    }
  }, [editViewId, openView]);
};

export const useCreateEntityActionHandlers = (
  entityType: string,
  createEntityTypes: CreateEntityType[],
  setViewActionHandlers: (viewActionHandlers: ViewActionHandlersByActionId) => void,
  entityEditorViewId?: string,
  processVariant?: string,
  startableProcesses?: ProcessDefinition[],
) => {
  const createEntity = useCreateEntity(entityEditorViewId);

  useEffect(() => {
    const viewActionHandlers = {};
    createEntityTypes.forEach((createEntityType) => {
      const prefix = processVariant ? "START_PROCESS" : "CREATE_ENTITY"; // if parameter processVariant is present, main action is start of process
      const actionProcessVariant = createEntityType.processVariant || processVariant;
      const actionDescriptorKey = prepareCreateEntityTypeActionDescriptorKey(createEntityType, prefix);
      viewActionHandlers[actionDescriptorKey] = {
        run: () => {
          if (actionProcessVariant) {
            const processKey = actionProcessVariant ? `${entityType}-${actionProcessVariant}` : undefined;
            const processDetails: ProcessDefinitionDetails | undefined = processKey ? {processKey: processKey} : undefined;
            createEntity(entityType, createEntityType, undefined, processDetails);
          } else {
            createEntity(entityType, createEntityType);
          }
        },
        enabled: () => {
          if (actionProcessVariant) {
            const processKey = actionProcessVariant ? `${entityType}-${actionProcessVariant}` : undefined;
            if (processKey && startableProcesses) {
              const startableProcessKeys: string[] = startableProcesses.filter(pd => pd.key).map(pd => pd.key!);
              return startableProcessKeys.includes(processKey);
            } else {
              return false;
            }
          } else {
            return true;
          }
        },
      };
    });

    setViewActionHandlers(viewActionHandlers);
  }, [createEntity, entityType, setViewActionHandlers, createEntityTypes, processVariant, startableProcesses]);
};

export const prepareDefaultCreateEntityType = (): CreateEntityType => {
  return {
    value: null,
    icon: "add",
  };
};

const isCreateEntityType = (val: any): val is CreateEntityType => {
  return "value" in val;
};

export const mapToCreateEntityType = (jsonString: string): CreateEntityType | null => {
  try {
    const parsed = JSON.parse(jsonString);
    if (isCreateEntityType(parsed)) {
      return parsed;
    }
    return null;
  } catch (e) {
    console.warn("Failed to parse CreateEntityType", jsonString, e);
    return null;
  }
};


export const prepareCreateEntityTypeActionDescriptorKey = (createEntityType: CreateEntityType, prefix: string = "CREATE_ENTITY") => {
  return createEntityType.value ? `${prefix}_${createEntityType.value}` : prefix;
};
