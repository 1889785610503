/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";

import {ExpressionEvaluationService} from "../../service";
import {ParameterUtilities, ResolvedParameter} from "../../util";
import {ActionRunner} from "./ActionRegistry";


export const useInvokeUrlActionHandler = (): ActionRunner => {
  return useCallback((parameters: ResolvedParameter[]) => {
    const entity = ParameterUtilities.getResolvedParameter("entity", parameters);
    const urlTemplate = ParameterUtilities.getResolvedParameter("URL", parameters);
    const url = entity ? ExpressionEvaluationService.evaluate(urlTemplate, entity) : urlTemplate;
    const method = ParameterUtilities.getResolvedParameter("METHOD", parameters) || "GET";

    fetch(url, {
      method,
      mode: "no-cors", // we can't see the response, if url has different origin
    })
      .then(r => console.log("INVOKE_URL result", r))
      .catch(reason => console.error("INVOKE_URL failed", reason));
  }, []);
};
