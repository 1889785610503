/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isString, NamedComponentDescriptor, ParameterUtilities, useService} from "@icm/core-common";
import {forwardRef, Ref, RefAttributes, createRef, useMemo} from "react";

import {ENTITY_FORM_COMPONENT} from "../constants";
import {Entity} from "../generated/api";
import {DEFAULT_AFTER_SUBMIT_STRATEGY, isSubmitStrategy, SubmitStrategy} from "../store";
import {isEntity} from "../util";
import {useEntityEditModel} from "../views";
import {EntityEditModelComponent, EntityEditModelComponentRef, REFRESH_LAZY, RefreshPolicy} from "./EntityEditModelComponent";

export type EntityFormComponentProps = {
  entityId?: string
  entityKey?: string
  entityType: string
  sourceEntity?: Entity

  formVariant?: string
  readOnly: boolean
  printRequested?: boolean,
  ruleContextAddition?: any,
  highlightUpdatesSince?: string,
  onFormValidationChange?: (isValid: boolean) => void,
  submitStrategy: SubmitStrategy,
  refreshPolicy: RefreshPolicy
}

export interface EntityFormComponentRef extends EntityEditModelComponentRef {}

export const EntityFormComponent = forwardRef((props: EntityFormComponentProps, ref: Ref<EntityFormComponentRef>) => {
  const {
    entityId,
    entityKey,
    entityType,
    sourceEntity,
    formVariant = "default",
    readOnly,
    printRequested,
    ruleContextAddition,
    highlightUpdatesSince,
    onFormValidationChange,
    submitStrategy,
    refreshPolicy,
  } = props;
  const editModel = useEntityEditModel({entityType, initialEntityId: entityId, initialEntityKey: entityKey, sourceEntity});
  const dateService = useService("DATE");
  const highlightUpdatesSinceDate = useMemo(() => dateService.parse(highlightUpdatesSince),
    [dateService, highlightUpdatesSince]);
  if (editModel) {
    return (
      <EntityEditModelComponent
        editModel={editModel}
        formVariant={formVariant}
        readOnly={readOnly}
        printRequested={printRequested}
        ruleContextAddition={ruleContextAddition}
        highlightUpdatesSince={highlightUpdatesSinceDate}
        onFormValidationChange={onFormValidationChange}
        submitStrategy={submitStrategy}
        refreshPolicy={refreshPolicy}
        ref={ref}
      />
    );
  }
  return <></>;
});

export const createEntityFormComponentDescriptor = (): NamedComponentDescriptor<RefAttributes<EntityFormComponentRef> & EntityFormComponentProps> => {
  return {
    name: ENTITY_FORM_COMPONENT,
    component: EntityFormComponent,
    initializeProps: (viewParameters, callbacks) => {
      const entity = ParameterUtilities.getResolvedParameterValue("entity", viewParameters, isEntity);
      const sourceEntity = ParameterUtilities.getResolvedParameterValue("sourceEntity", viewParameters, isEntity);
      const highlightUpdatesSince = ParameterUtilities.getResolvedParameterValue("highlightUpdatesSince", viewParameters, isString);
      const submitStrategy = ParameterUtilities.getResolvedParameterValue("afterSubmit", viewParameters, isSubmitStrategy) ?? DEFAULT_AFTER_SUBMIT_STRATEGY;
      const entityType = ParameterUtilities.getResolvedParameterValue("entityType", viewParameters, isString) ?? entity?.type;
      const entityId = entity?.id ?? ParameterUtilities.getResolvedParameterValue("entityId", viewParameters, isString);
      const entityKey = entity?.keys?.[0]?.value ?? ParameterUtilities.getResolvedParameterValue("entityKey", viewParameters, isString);
      if (!entityType) {
        throw Error("No entity type given");
      }
      const formVariant = ParameterUtilities.getResolvedParameterValue("formVariant", viewParameters, isString) ?? "default";
      const readOnly = ParameterUtilities.getResolvedFlag("readOnly", viewParameters, false);
      const printRequested = ParameterUtilities.getResolvedFlag("printRequested", viewParameters, false);

      return {
        entityId,
        entityKey,
        entityType,
        sourceEntity,
        formVariant,
        readOnly,
        printRequested,
        ruleContextAddition: undefined,
        highlightUpdatesSince,
        onFormValidationChange: callbacks?.onFormValidationChange as any,
        submitStrategy,
        refreshPolicy: REFRESH_LAZY,
        ref: createRef<EntityFormComponentRef>(),
      };
    },
  };
};
