/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IcmPushNotification} from "../../../service";
import {IdGenerator} from "../../../util";
import {createPushNotificationQueueItemKey} from "../helpers";
import {IcmPushNotificationQueueItem, PushNotificationState} from "../types";

export type QueuePushNotificationOptions = {
  immediate: boolean,
  pushBody: IcmPushNotification<any>
}

export const QueuePushNotification = (state: PushNotificationState, action: PayloadAction<QueuePushNotificationOptions>) => {
  const {pushNotifications} = state;
  const {
    immediate,
    pushBody,
  } = action.payload;
  const {
    userName,
    userRoles,
  } = pushBody;
  const userKey = createPushNotificationQueueItemKey(userName);
  if (!pushNotifications[userKey]) {
    pushNotifications[userKey] = [];
  }
  const item: IcmPushNotificationQueueItem = {
    id: IdGenerator.uuidv5(),
    userRoles,
    immediate,
    pushBody,
  };
  pushNotifications[userKey].push(item);
};
