/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {TextField} from "@mui/material";

import {MessageEditorProps} from "./MessageEditorProps";


/**
 * A control to compose a Message object.
 *
 * @param props
 * @constructor
 */
export const MessageEditor = (props: MessageEditorProps) => {
  const {messageController} = props;
  const message = messageController.getMessage();
  return (
    <>
      {messageController.hasSubject()
        && (
        <TextField
          margin="normal"
          fullWidth={true}
          contentEditable={false}
          disabled={!messageController.isContentEditable()}
          id="filled-multiline-flexible"
          label="Betreff"
          maxRows={4}
          value={message.subject}
          onChange={e => messageController.setSubject(e.target.value)}
          variant="filled"
        />
        )}
      <TextField
        fullWidth={true}
        contentEditable={false}
        disabled={!messageController.isContentEditable()}
        label="Inhalt"
        multiline
        minRows={10}
        maxRows={20}
        value={message.content}
        onChange={e => messageController.setContent(e.target.value)}
        variant="filled"
      />
    </>
  );
};
