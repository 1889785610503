/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {combineEpics, Epic} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, switchMap} from "rxjs/operators";
import {isActionOf} from "typesafe-actions";

import {TopologyApi} from "../../api/TopologyApi";
import {fetchSketches} from "./actions";
import {SketchAction} from "./reducer";

const fetchSketchFlow: Epic<SketchAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchSketches.request)),
  switchMap(() => from(TopologyApi.getAllSketches()).pipe(
    map(value => fetchSketches.success(value)),
    catchError(error => of(fetchSketches.failure(error)))
  ))
);

export const sketchListFlows = combineEpics(fetchSketchFlow);
