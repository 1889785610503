/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ModuleRegistrationOptions} from "@icm/core-common";

import packageInfo from "./src/generated/package.clone.json";
import {ActivityStreamService} from "./src/service";
import {register} from "./src/store/register";

export * from "./src/generated/api";
export * from "./src/constant";
export * from "./src/api";
export * from "./src/service";
export * from "./src/store";
export * from "./src/util";
export * from "./src/components";
export * from "./src/views";

declare module "@icm/core-common" {
  export interface IcmServices {
    ACTIVITY_STREAM: ActivityStreamService,
  }
}


export const activityStreamCommonModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  registerStoreContribution: register,
  registerServices: ((serviceRegistry) => {
    const securityService = serviceRegistry.get("SECURITY");
    const activityStreamService = new ActivityStreamService(securityService);
    serviceRegistry.register("ACTIVITY_STREAM", activityStreamService);
    serviceRegistry.register("HIGHLIGHTED_FIELDS_SERVICE", activityStreamService);
  }),
  runPostInitialization: (serviceRegistry) => {
    serviceRegistry.get("ACTIVITY_STREAM").initialize();
  },
};
