/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  getISO8601DurationString,
  getDateStringFromDuration,
  getInputFromValue,
  getDurationItemOrder,
  getSignFromValue,
  getUnitFromValue,
  IFormComponentProps,
  MessageKey,
  messages,
  DurationItemOrder,
  DurationSign,
  DurationUnit,
} from "@icm/core-common";
import {
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import {useStyles} from "./RelativeDateComponent.style";

type RelativeDateComponentProps = IFormComponentProps<string>;

export const RelativeDateComponent: React.FC<RelativeDateComponentProps> = (props) => {
  const {value, handleChange, label} = props;
  const styles = useStyles();
  const showDate = useMediaQuery(useTheme().breakpoints.up("sm"));
  const labels = {
    sign: {
      past: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.SIGN.PAST),
      future: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.SIGN.FUTURE),
    },
    unit: {
      years: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.UNIT.YEARS),
      months: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.UNIT.MONTHS),
      weeks: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.UNIT.WEEKS),
      days: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.UNIT.DAYS),
    },
    order: messages.get(MessageKey.CORE.FORM.DATE.RELATIVE.ORDER),
  };

  const sign: DurationSign = getSignFromValue(value) ?? "past";
  const unit: DurationUnit = getUnitFromValue(value) ?? "d";
  const textFieldInput = getInputFromValue(value, unit) ?? "";
  const dateString = getDateStringFromDuration(value) ?? "-";
  const order: DurationItemOrder = getDurationItemOrder(labels.order) ?? ["sign", "number", "unit"];

  const renderFunctions = {
    sign: renderSignInput,
    number: renderNumberInput,
    unit: renderUnitInput,
  };

  return (
    <FormGroup>
      <Grid container direction="column" alignItems="stretch" justifyContent="space-between">
        <Grid item>
          <InputLabel shrink>{label}</InputLabel>
        </Grid>
        <Grid container item justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid container item wrap="nowrap" className={styles.inputContainer}>
            {order.map(v => renderFunctions[v] && renderFunctions[v]())}
          </Grid>
          {showDate && (
            <Grid item className={styles.textContainer}>
              <Typography variant="body1" className={styles.textSecondary} noWrap>{dateString}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormGroup>
  );

  function renderSignInput() {
    return (
      <Grid key="sign-input" item>
        <FormControl variant="standard">
          <Select value={sign.toString()}
                  onChange={(event) => {
                    switch (event.target.value) {
                      case "future":
                      case "past":
                        handleChange(getISO8601DurationString(event.target.value, textFieldInput, unit));
                    }
                  }}
          >
            <MenuItem value="future">{labels.sign.future}</MenuItem>
            <MenuItem value="past">{labels.sign.past}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }

  function renderNumberInput() {
    return (
      <Grid key="number-input" item className={styles.numberInputContainer}>
        <TextField variant="standard"
                   type="text"
                   value={textFieldInput}
                   classes={{root: styles.textField}}
                   fullWidth
                   onChange={event => {
                     handleChange(getISO8601DurationString(sign, event.target.value, unit));
                   }}
        />
      </Grid>
    );
  }

  function renderUnitInput() {
    return (
      <Grid key="unit-input" item>
        <FormControl variant="standard">
          <Select value={unit}
                  onChange={event => {
                    switch (event.target.value) {
                      case "y":
                      case "M":
                      case "w":
                      case "d":
                        handleChange(getISO8601DurationString(sign, textFieldInput, event.target.value));
                    }
                  }}
          >
            <MenuItem value="y">{labels.unit.years}</MenuItem>
            <MenuItem value="M">{labels.unit.months}</MenuItem>
            <MenuItem value="w">{labels.unit.weeks}</MenuItem>
            <MenuItem value="d">{labels.unit.days}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }
};
