/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useEffect, useState} from "react";

export const useHasVerticalScrollbars = (table: HTMLElement | null): boolean => {
  const [hasVerticalScrollbars, setHasVerticalScrollbars] = useState(false);
  useEffect(() => {
    if (table) {
      setHasVerticalScrollbars(table.scrollHeight > table.clientHeight);
      const observer = new ResizeObserver(() => {
        setHasVerticalScrollbars(table.scrollHeight > table.clientHeight);
      });
      observer.observe(table.children[0]);
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [table]);

  return hasVerticalScrollbars;
};
