/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FilterSelectionItem} from "../../generated/api";
import {ClientFilterSelection} from "./ClientFilterSelection";
import {createFallbackFilter} from "./FallbackClientFilterSelection";
import {SharedFilterController} from "./SharedFilterController";


const currentFilterSelection = createFallbackFilter();

/**
 * A default controller that can be used until a real controller is available.
 */
export const NullSharedFilterController: SharedFilterController = {
  hasFilterSelections: false,
  availableFilterSelections: [],
  currentFilterSelection,
  currentFilterSelectionItems: [],
  filterOptions: [],
  canChangeCurrentFilterSelection(): boolean {
    return false;
  },
  canCreateNewFilter(): boolean {
    return false;
  },
  canDeleteCurrentFilter(): boolean {
    return false;
  },
  canEditCurrentFilter(): boolean {
    return false;
  },
  deleteCurrentFilterSelection(): Promise<boolean> {
    return Promise.resolve(false);
  },
  deleteFilterSelection(_filterId: string): Promise<boolean> {
    return Promise.resolve(false);
  },
  isSaving(): boolean {
    return false;
  },
  isEditing(): boolean {
    return false;
  },
  isReady(): boolean {
    return false;
  },
  canResetFilter(): boolean {
    return false;
  },
  setCurrentFilterSelection(_filterSelection: ClientFilterSelection): void {
  },
  startEdit(_newFilter: boolean): void {
  },
  startEditFilter(_newFilter: ClientFilterSelection) {
  },
  stopEdit(_save: boolean): void {
  },
  updateCurrentFilterSelectionItems(_items?: FilterSelectionItem[]): void {
  },
  updateFilterName(_newName: string): void {
  },
  resetFilter() {
  },
};
