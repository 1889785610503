/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, useMessages} from "@icm/core-common";
import {Typography} from "@mui/material";
import React from "react";

import {SingleCellRow} from "./SingleCellRow";

export const NoEntriesRow = React.memo(() => {
  const {getMessage} = useMessages();
  return (
    <SingleCellRow>
      <Typography align="center" variant="inherit" style={{width: "100%"}}>
        {getMessage(MessageKey.CORE.FORM.LIST.NO_ENTRIES)}
      </Typography>
    </SingleCellRow>
  );
});
