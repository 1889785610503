/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/


import {
  IFormComponentProps,
  MessageKey,
  messages,
  Parameter,
  ParameterUtilities,
  PossibleValue,
} from "@icm/core-common";
import {FormControl, MenuItem, Select, Typography} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Replay} from "vimond-replay";
import {PlayerConfiguration} from "vimond-replay/default-player/PlayerConfiguration";
import {InitialPlaybackProps} from "vimond-replay/default-player/Replay";
import HlsjsVideoStreamer from "vimond-replay/video-streamer/hlsjs";
import "vimond-replay/index.css"; // import is required to apply styles
import ShakaVideoStreamer from "vimond-replay/video-streamer/shaka-player";

import styles from "./MediaStreamPlayerComponentStyle";
import {ConfigurationDescriptor, playerConfiguration} from "./MediaStreamPlayerConfigurations";


export type StreamSelectorPosition = "TOP" | "BOTTOM" | "OFF";

/**
 * An abstraction for the stream being used.
 * We have an entity but these two properties
 * is what we really need.
 */
export type StreamSource = {
  sourceName: string,
  sourceAddress: string,
};

/**
 * Default stream source that is used if the
 * player is turned off or not configured correctly.
 */
function nullSource(): StreamSource {
  return {
    // eslint-disable-next-line no-underscore-dangle
    sourceName: messages.get(MessageKey._MEDIA_STREAM.LABEL.NONE),
    sourceAddress: "",
  };
}

export type MediaStreamPlayerFormComponentProps = {
  parameterList?: Parameter[],
  sources?: PossibleValue[]
} & IFormComponentProps<any>;


/**
 * MediaStreamPlayer to be used in a FormView.
 *
 * @param props
 * @constructor
 */
export const MediaStreamPlayerFormComponent = (props: MediaStreamPlayerFormComponentProps) => {
  const setup = useMemo(() => asPlayerSetup(props), [props]);
  return <Player {...setup} />;
};


/**
 * Properties for the media player component when created within a MediaView.
 * @see MediaView
 */
export type MediaStreamPlayerComponentProps = {
  streamSource: StreamSource,
  autoPlay: boolean,
  muted: boolean,
  configurationName: string
};

/**
 * MediaStreamPlayer to be used in a MediaView.
 *
 * @param props
 * @see MediaView
 * @constructor
 */
export const MediaStreamPlayerComponent = (props: MediaStreamPlayerComponentProps) => {
  const selectedSource = props.streamSource;
  const setup: PlayerSetup = useMemo(() => {
    const sources = [{
      sourceName: selectedSource.sourceName,
      sourceAddress: selectedSource.sourceAddress,
    }];
    const streamSelector = "OFF";
    const autoPlay =  props.autoPlay;
    const muted = props.muted || autoPlay; // in case of chrome we have to mute, otherwise autoplay will not work

    return {
      configuration: playerConfiguration(props.configurationName as ConfigurationDescriptor),
      intialPlaybackConfiguration: {
        isMuted: muted,
        isPaused: !autoPlay,
      },
      sources,
      selectedSource: sources[0],
      streamSelector,
    };
  }, [selectedSource.sourceAddress, selectedSource.sourceName, props.autoPlay, props.muted, props.configurationName]);

  return useMemo(() => (<Player {...setup} />), [setup]);
};


type PlayerSetup = {
  label?: string,
  configuration: PlayerConfiguration,
  intialPlaybackConfiguration: InitialPlaybackProps,
  sources: StreamSource[],
  selectedSource: StreamSource,
  streamSelector: StreamSelectorPosition,
};

function asPlayerSetup(props: Readonly<MediaStreamPlayerFormComponentProps>): PlayerSetup {
  const parameters = ParameterUtilities.flattenParameters(props.parameterList);

  const value = props.value;
  if (value && value.type === "_MEDIA_STREAM" && value.dynamicAttributes) {
    const selectedSource = {
      sourceAddress: value.dynamicAttributes.sourceAddress.value,
      sourceName: value.dynamicAttributes.sourceName.value,
    };
    const sources = [selectedSource];
    const streamSelector = asStreamSelectorPosition(parameters["streamSelector"]);
    const autoPlay =  parameters["autoPlay"] === "true" || parameters["autoPlay"] === "1";
    const muted = parameters["muted"] || autoPlay; // in case of chrome we have to mute, otherwise autoplay will not work
    return {
      configuration: playerConfiguration(parameters["configuration"]),
      intialPlaybackConfiguration: {
        isMuted: muted,
        isPaused: !autoPlay,
      },
      label: props.label,
      sources,
      selectedSource,
      streamSelector,
    };
  } else {
    const sources = props.sources?.map(asStreamSource) ?? [];
    const selectedSource = sources.length > 0 ? sources[0] : nullSource();
    const streamSelector = asStreamSelectorPosition(parameters["streamSelector"]);
    const autoPlay =  parameters["autoPlay"] === "true" || parameters["autoPlay"] === "1";
    const muted = parameters["muted"] || autoPlay; // in case of chrome we have to mute, otherwise autoplay will not work
    return {
      configuration: playerConfiguration(parameters["configuration"]),
      intialPlaybackConfiguration: {
        isMuted: muted,
        isPaused: !autoPlay,
      },
      label: props.label,
      sources,
      selectedSource,
      streamSelector,
    };
  }
}

function asStreamSelectorPosition(value?: string): StreamSelectorPosition {
  if (!value) {
    return "OFF";
  }
  try {
    return value as StreamSelectorPosition;
  } catch (e: any) {
    console.error(`Value ${value} is not a valid value for parameter "streamSelector". Use TOP, BOTTOM or OFF.`);
    return "OFF";
  }
}

function asStreamSource(v: PossibleValue): StreamSource {
  if (!v.value || v.value === "") {
    return nullSource();
  }
  return {
    sourceName: v.label!,
    sourceAddress: v.value,
  };
}

const useStyles = makeStyles(styles);

/**
 * This component serves as a bridge between streaming formats.
 *
 * @param playerSetup
 * @constructor
 */
const Player = (playerSetup: PlayerSetup) => {
  const {sources, configuration, selectedSource, streamSelector, intialPlaybackConfiguration, label} = playerSetup;
  const [source, setSource] = useState<StreamSource>(selectedSource);

  useEffect(() => {
    setSource(selectedSource);
  }, [selectedSource]);

  const controlsDisabled = configuration?.controls?.includeControls?.length === 0;
  const classes = useStyles({controlsDisabled});

  return (
    <>
      <Typography variant="caption" className={classes.textSecondary}>{label}{label ? ":" : ""}</Typography>
      {streamSelector === "TOP" ? <StreamSelectorComponent sources={sources} selectedSource={source} handle={setSource} /> : <></>}
      <div className={classes.streamingComponent}>
        <StreamingComponent source={source} configuration={configuration} initialPlaybackProps={intialPlaybackConfiguration} />
      </div>
      {streamSelector === "BOTTOM" ? <StreamSelectorComponent sources={sources} selectedSource={source} handle={setSource} /> : <></>}
    </>
  );
};


type SelectorComponentProps = {
  sources: StreamSource[],
  selectedSource: StreamSource,
  handle: React.Dispatch<StreamSource>
};

/**
 * Dropdown to select a stream from a given list of available sources.
 *
 * @param props
 * @constructor
 */
const StreamSelectorComponent = (props: SelectorComponentProps) => {
  const {sources, selectedSource, handle} = props;

  const handleStreamSelection = (event: SelectChangeEvent) => {
    const selectedStreamUrl = event.target.value;
    const selectedStream = sources.find(s => s.sourceAddress === selectedStreamUrl) ?? nullSource();
    handle(selectedStream);
  };

  return (
    <FormControl style={{width: "100%"}}>
      <Select id="stream-select"
              value={selectedSource.sourceAddress}
              onChange={handleStreamSelection}
      >
        {sources.map(s => <MenuItem key={s.sourceAddress} value={s.sourceAddress}>{s.sourceName}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

type StreamingComponentProps = {
  source: StreamSource,
  configuration: PlayerConfiguration,
  initialPlaybackProps: InitialPlaybackProps
}

/**
 * Wrapper around replay component that selects a streaming component based on the stream type.
 *
 * @constructor
 */
const StreamingComponent = (props: StreamingComponentProps) => {
  const {source, configuration, initialPlaybackProps} = props;
  const type = getStreamType(source);

  switch (type) {
    case "HLS":
      return (
        <Replay source={source.sourceAddress} options={configuration} initialPlaybackProps={initialPlaybackProps}>
          <HlsjsVideoStreamer />
        </Replay>
      );
    case "DASH":
      return (
        <Replay source={source.sourceAddress} options={configuration} initialPlaybackProps={initialPlaybackProps}>
          <ShakaVideoStreamer />
        </Replay>
      );
    case "NONE":
    default:
      return (
        <Replay source={source.sourceAddress} options={configuration} initialPlaybackProps={initialPlaybackProps} />
      );
  }
};


type StreamType = "HLS" | "NONE" | "DASH";

function getStreamType(source: StreamSource): StreamType {
  if (source.sourceAddress?.includes(".m3u8") || source.sourceAddress?.includes(".mpd")) {
    return "HLS";
  }
  return "NONE";
}
