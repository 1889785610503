/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {validatorService} from "@icm/core-common";
import {isString} from "lodash-es";

import {AddressValidator} from "./AddressValidator";
import {AddressValueHolder} from "./index";

/**
 * Check if the address is a valid phone number.
 *
 * @param address the address to validate
 */
export const PhoneNumberValidator: AddressValidator = (address: string | AddressValueHolder) => {
  if (isString(address)) {
    return validatorService.isValidPhoneNumber(address);
  } else {
    return validatorService.isValidPhoneNumber(address.addressValue);
  }
};
