/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {ResolvedParameterList} from "../../util";

export type ViewContextValue = {
  // both optional in single view environments
  tabGroupId?: string
  tabId?: string
  entityId?: string
  /**
   * @deprecated use ViewModel instead
   */
  viewParameters?: ResolvedParameterList

  /** Width of the parent container */
  size?: number

} | undefined;

export const ViewContext = React.createContext<ViewContextValue>(undefined);

export const useViewContext = () => {
  return React.useContext(ViewContext);
};
