/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {EvaluatedElement, evaluateEntityListElement, isEvaluatedElement} from "@icm/core-common";
import * as React from "react";

import {ListColumnRecord} from "./DataTableUtil";
import {DetailPanel} from "./DetailPanel";
import {DetailPanelRenderer} from "./DetailPanelRenderer";
import {DetailPanelRendererFactory} from "./DetailPanelRendererFactory";

/**
 * A factory to create a DetailPanelRenderer which uses all RowTextElements having
 * slot "EXPANDABLE".
 */
export const ExpandableTextDetailPanelRendererFactory: DetailPanelRendererFactory = {

  createRenderer<T>(listElements: ListColumnRecord): DetailPanelRenderer<T> | undefined {
    const expandableRowElements = listElements.EXPANDABLE;
    if (expandableRowElements.length > 0) {
      const getLabelAndValues = (row: T): EvaluatedElement[] => expandableRowElements
        .map((expandableRow) => evaluateEntityListElement(row, expandableRow))
        .filter(isEvaluatedElement);

      return {
        hasDetails: (row) => getLabelAndValues(row).length > 0,
        DetailPanel: ({row}) => {
          const labelAndValues = getLabelAndValues(row);
          if (labelAndValues.length > 0) {
            return <DetailPanel elements={labelAndValues} row={row} />;
          } else {
            return null;
          }
        },
      };
    }
    return undefined;
  },

};
