/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {fetchMessages, ICoreApplicationState, StoreService} from "../store";

export const useConfiguration = () => {
  const messageStateReady = useSelector((state: ICoreApplicationState) => state.messageState?.ready);
  const ready = messageStateReady;

  useEffect(() => {
    if (!messageStateReady) {
      StoreService.store.dispatch(fetchMessages());
    }
  }, [messageStateReady]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!ready) {
        console.log("Reloading as app did not load within 10 seconds");
        document.location.reload();
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [ready]);

  return {
    messageStateReady,
  };
};
