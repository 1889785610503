/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Icon} from "../../generated/api";

export type RowAction<T> = {
  isVisible: (row: T, rowIndex: number) => boolean
  isEnabled: (row: T, rowIndex: number) => boolean
  label: string
  icon: string
  onClick: (row: T, rowIndex: number) => void
}

export type RowStatus = "CREATED" | "UPDATED" | "ARCHIVED" | "DELETED" | "FILTER_REMOVED";

export function isRowStatus(rowStatus: RowStatus | undefined, expected: RowStatus[]) {
  if (rowStatus) {
    return expected.includes(rowStatus);
  }
  return false;
}

export type EvaluatedElement = {
  label?: string;
  value: string;
  format: string;
  icon?: Icon
}

export const isEvaluatedElement = (element?: any): element is EvaluatedElement => {
  if (!element) {
    return false;
  }
  const hasLabel = element.label;
  const hasNonEmptyValue = element.value !== undefined && element.value !== null && element.value !== "";
  const hasConfiguredIcon = element.icon;
  return hasLabel && (hasNonEmptyValue || hasConfiguredIcon);
};
