/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {JournalController} from "@icm/core-common";
import {Grid, Paper, TextField, Typography} from "@mui/material";
import React, {useRef} from "react";
import {Virtuoso, VirtuosoHandle} from "react-virtuoso";

import {JournalEntryRow} from "./JournalEntryRow";

export type JournalProps = {
  label?: string
  controller: JournalController
}

export const Journal = (props: JournalProps) => {
  const {controller} = props;
  const rootRef = useRef<VirtuosoHandle>(null);
  const journalEntries = controller.getJournalEntries();

  const trySubmitMessage = (event:  React.KeyboardEvent) => {
    const enter = event.code === "Enter" || event.code === "NumpadEnter";
    if (enter && !event.shiftKey) {
      controller.submitCurrentMessage();
      event.preventDefault();
    }
  };

  return (
    <Grid container
          justifyContent="space-between"
          alignItems="stretch"
          direction="column"
          wrap="nowrap"
          spacing={2}
    >
      <Grid item>
        {props.label && <Typography variant="caption">{props.label}</Typography>}
        <Paper variant="outlined">
          <Virtuoso style={{height: "20vh"}}
                    ref={rootRef}
                    initialTopMostItemIndex={journalEntries.length}
                    followOutput="auto"
                    data={journalEntries}
                    itemContent={(index, message) => <JournalEntryRow entry={message} />}
          />
        </Paper>
      </Grid>
      <Grid item sx={{padding: 0}}>
        <TextField
              fullWidth
              disabled={!controller.isEditable()}
              multiline
              minRows={2}
              maxRows={3}
              variant="outlined"
              value={controller.getCurrentMessage()}
              onChange={e => controller.setCurrentMessage(e.target.value)}
              onKeyDown={trySubmitMessage}
        />
      </Grid>

    </Grid>
  );
};
