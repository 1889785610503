/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../../theme";

export type IFrameWebComponentStyleProps = {
  minHeight: string
}

const styles = (theme: IcmMuiTheme) => createStyles<keyof typeof styles, IFrameWebComponentStyleProps>({
  item: (props: IFrameWebComponentStyleProps) => ({
    border: "none",
    height: "99%",
    width: "100%",
    "min-height": props.minHeight,
  }),
  container: (props: IFrameWebComponentStyleProps) => ({
    height: "100%",
    position: "relative",
    minHeight: props.minHeight,
  }),
  box: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  error: {
    padding: theme.spacing(2),
  },
});

export default styles;
