/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ErrorBoundary,
  TabGroupTab,
  useAvailableViewDescriptors,
  useService,
  useSetViewActionHandlers,
  ViewContext,
  ViewContextValue,
} from "@icm/core-common";
import * as React from "react";
import {Suspense, useMemo} from "react";

import {LoadingSpinner} from "../loading";
import {useTabGroupDescriptor} from "./useTabGroupDescriptor";

export type TabContentViewerProps = {
  tabGroupId: string
  tab: TabGroupTab
  selected: boolean
}

export const TabContentViewer = React.memo((props: TabContentViewerProps) => {
  const {tab, tabGroupId} = props;

  const {viewModel} = tab;
  const {viewType} = viewModel;
  const securityService = useService("SECURITY");

  const availableViewDescriptors = useAvailableViewDescriptors();
  const viewDescriptor = useMemo(() => availableViewDescriptors[viewType], [availableViewDescriptors, viewType]);

  const setViewActionHandlers = useSetViewActionHandlers(viewModel.id);
  const groupDescriptor = useTabGroupDescriptor(tabGroupId);

  const viewContextValue: ViewContextValue = useMemo(() => {
    return {
      tabId: tab.id,
      tabGroupId,
      size: groupDescriptor?.size,
    };
  }, [tabGroupId, tab.id, groupDescriptor]);

  if (viewDescriptor) {
    securityService.signalUserActivity();
    const {view: View} = viewDescriptor;
    return (
      <ErrorBoundary>
        <ViewContext.Provider value={viewContextValue}>
          <Suspense fallback={<LoadingSpinner />}>
            <View viewModel={viewModel}
                  setViewActionHandlers={setViewActionHandlers}
            />
          </Suspense>
        </ViewContext.Provider>
      </ErrorBoundary>
    );
  }
  return (<div>{`No component found for viewType: ${viewType}`}</div>);
});
