/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {closeSideBar, NavigationSideBar, useActionHandler, useSideBar} from "@icm/core-common";
import {Close} from "@mui/icons-material";
import {IconButton, Paper} from "@mui/material";
import {Box} from "@mui/system";
import * as React from "react";
import {useDispatch} from "react-redux";

import {DUMMY_NAVIGATION_SIDEBAR_MODEL, NavigationSideBarView} from "../../pages";
import styles from "./SideBarStyles";

type SideBarContainerProps = {
  sideBarId: string
}

export const SideBarContainer = (props: SideBarContainerProps) => {
  const sideBarConfig = useSideBar(props.sideBarId);
  const actionHandler = useActionHandler();
  const dispatch = useDispatch();

  if (sideBarConfig?.sideBarType === "NAVIGATION_SIDEBAR") {
    const cfg: NavigationSideBar = sideBarConfig as NavigationSideBar;
    return (
      <Paper sx={styles.container} variant="outlined" square className="dontPrint">
        <Box sx={styles.containerHeader}>
          <Box>{cfg.label}</Box>
          <Box sx={styles.containerActions}>
            <IconButton onClick={() => dispatch(closeSideBar({}))}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.containerBody}>
          <NavigationSideBarView navigationSideBarConfig={cfg}
                                 dispatchAction={actionHandler}
                                 viewModel={DUMMY_NAVIGATION_SIDEBAR_MODEL}
                                 setViewActionHandlers={() => {}}
          />
        </Box>
      </Paper>
    );
  }
  return (<></>);
};
