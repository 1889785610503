/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {getType} from "@reduxjs/toolkit";

import {ICON} from "./src/constant";
import packageInfo from "./src/generated/package.clone.json";
import {
  coordinateService,
  createCoreNotificationService,
  createCounterService,
  dateService,
  IcmServiceRegistry,
  LogReportService,
  messages,
  ModuleRegistrationOptions,
  numberService,
  scriptHelperService,
  unitService,
} from "./src/service";
import {messageReducer, uiReducer, updateViewStateAcrossTabs} from "./src/store";
import {filterReducer} from "./src/store/filter/reducer";
import {pushNotificationsReducer} from "./src/store/push/reducer";
import {
  registerNamedComponentFactory,
  useDownloadActionHandler,
  useInvokeUrlActionHandler,
  useOpenUrlActionHandler,
  useOpenViewActionHandler,
} from "./src/ui";
import {SvgIcon} from "./src/util";

export * from "./src/actions";
export * from "./src/api";
export {default as MessageKey} from "./src/generated/MessageKey";
export * from "./src/service";
export * from "./src/util";
export * from "./src/data";
export * from "./src/constant";
export * from "./src/pages";
export * from "./src/resources";
export * from "./src/store";
export * from "./src/dao";
export * from "./src/types";
export * from "./src/configuration";
export * from "./src/components";
export * from "./src/theme";
export * from "./src/ui";
export * from "./src/widgets";
export * from "./src/notification";
export * from "./src/hooks";

export {setupCoreCommonTests} from "./src/test/setupCoreCommonTests";

export const coreCommonModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  registerServices: (serviceRegistry: IcmServiceRegistry, registerLoginHandler) => {
    console.log("Registering services...");
    const securityService = serviceRegistry.get("SECURITY");
    serviceRegistry.register("MESSAGE", messages);
    serviceRegistry.register("DATE", dateService);
    serviceRegistry.register("NUMBER", numberService);
    serviceRegistry.register("COORDINATE", coordinateService);
    serviceRegistry.register("UNIT", unitService);
    serviceRegistry.register("SCRIPT_SERVICE", scriptHelperService);

    registerLoginHandler(async () => {
      const counterService = await createCounterService();
      serviceRegistry.register("COUNTER", counterService);
      return () => serviceRegistry.unregister("COUNTER");
    });

    registerLoginHandler(async () => {
      const notificationService = await createCoreNotificationService(securityService);
      if (notificationService) {
        serviceRegistry.register("NOTIFICATION", notificationService);
        return () => serviceRegistry.unregister("NOTIFICATION");
      } else {
        console.debug("No notifications configured. Not creating configuration service.");
        return undefined;
      }
    });
  },
  runPostInitialization: (_serviceRegistry) => {
    registerNamedComponentFactory(ICON, SvgIcon);
  },
  registerStoreContribution: (registerInStore) => {
    console.log("Registering store contributions...");
    registerInStore("uiState", uiReducer, undefined, [getType(updateViewStateAcrossTabs)]);
    registerInStore("messageState", messageReducer);
    registerInStore("filterStates", filterReducer);
    registerInStore("pushNotificationState", pushNotificationsReducer);
    LogReportService.init();
  },
  providedActions: {
    OPEN: useOpenViewActionHandler,
    OPEN_URL: useOpenUrlActionHandler,
    INVOKE_URL: useInvokeUrlActionHandler,
    DOWNLOAD: useDownloadActionHandler,
  },
};
