/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/**
 * A wrapper for a promise and its resolve method that is a "one-shot" condition.
 * Once it was fulfilled, it remains fulfilled. Use it like so:
 *
 * ```
 * class ServiceXZZ {
 *
 *     const initialized: Condition
 *
 *     constructor() {
 *         this.initialized = new Condition("ServiceXYZ Ready");
 *     }
 *
 *     public async isInitialized() {
 *         return initialized.applies();
 *     }
 *
 *     public init() {
 *         // ... serive setup stuff
 *         this.initialized.complete();
 *     }
 *
 * }
 *
 * // from somewhere else you can now
 * service.isInitialized().then(ignored => {
 *    // call service
 * })
 *
 * // or
 * async importantServiceCall() {
 *    await service.isInitialized();
 *    service.importantStuff();
 * }
 * ```
 * @author mjk, smu
 */
export class Condition {
  private readonly name: string;
  private readonly latch: Promise<void>;
  private fulfill: () => void;

  /**
   * @param name The name of the condition for debugging
   */
  constructor(name = "") {
    this.name = name;
    this.latch = new Promise<void>(resolve => {
      this.fulfill = resolve;
    });
  }

  /**
   * Make the condition "fulfilled".
   */
  public complete() {
    console.log("Condition", this.name, "is fulfilled.");
    this.fulfill();
  }

  /**
   * Wait for the condition to become true
   */
  public async applies() {
    await this.latch;
  }
}
