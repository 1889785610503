/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {TablePaginationActionsProps} from "@mui/material/TablePagination/TablePaginationActions";
import {ThemedComponentProps, StyledComponentProps, withStyles} from "@mui/styles";
import {ChangeEvent} from "react";
import * as React from "react";

import styles from "./PaginationActionsComponentStyle";

type AllProps = TablePaginationActionsProps & StyledComponentProps & ThemedComponentProps;

class PaginationActionsComponent extends React.Component<AllProps> {

  constructor(props: Readonly<TablePaginationActionsProps & StyledComponentProps<string> & ThemedComponentProps>) {
    super(props);
    this.handleFirstPageButtonClick = this.handleFirstPageButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handleLastPageButtonClick = this.handleLastPageButtonClick.bind(this);
    this.handleManualPageChange = this.handleManualPageChange.bind(this);
    this.getLastPage = this.getLastPage.bind(this);
  }

  public render() {
    const {classes, page, theme} = this.props;
    return (
      <div className={classes!.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          size="large"
        >
          {theme!.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} size="large">
          {theme!.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= this.getLastPage() - 1}
          size="large"
        >
          {theme!.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= this.getLastPage() - 1}
          size="large"
        >
          {theme!.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  }

  private handleManualPageChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let page = parseInt(event.target.value, 10);
    page = Math.min(page, this.getLastPage());
    page = Math.max(page, 1);
    this.props.onPageChange(null, page - 1);
  }

  private handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.props.onPageChange(event, 0);
  }

  private handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.props.onPageChange(event, this.props.page - 1);
  }

  private handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.props.onPageChange(event, this.props.page + 1);
  }

  private handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.props.onPageChange(event, Math.max(0, this.getLastPage() - 1));
  }

  private getLastPage() {
    return Math.ceil(this.props.count / this.props.rowsPerPage);
  }
}

const component = withStyles(styles, {withTheme: true})(PaginationActionsComponent);
export {component as PaginationActionsComponent};
