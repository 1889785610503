/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ObjectWithUpdateInformation} from "@icm/activitystream-common";
import {ArrayUtilities} from "@icm/core-common";
import {DetailPanelRenderer, getDetailPanelRendererFactory, ListColumnRecord} from "@icm/core-web";
import * as React from "react";
import {useMemo} from "react";


/**
 * A memoized delegating DetailPanelRenderer that use the KeyValue, Expandable and Sublist detail panels.
 *
 * @param rowElements
 */
export const useDetailPanelRenderer = <T extends {}, >(rowElements: ListColumnRecord): DetailPanelRenderer<ObjectWithUpdateInformation<T>> => {
  return useMemo(() => {
    const rendererKeyValue = getDetailPanelRendererFactory("KEY_VALUE")?.createRenderer<ObjectWithUpdateInformation<T>>(rowElements);
    const rendererExpandable = getDetailPanelRendererFactory("EXPANDABLE")?.createRenderer<ObjectWithUpdateInformation<T>>(rowElements);
    const sublistRenderer = getDetailPanelRendererFactory("SUBLIST")?.createRenderer<ObjectWithUpdateInformation<T>>(rowElements);

    return {
      hasDetails: (row) => {
        row = resolveRow(row);
        const rendererKeyValueHasDetails = rendererKeyValue ? rendererKeyValue.hasDetails(row) : false;
        const rendererExpandableHasDetails = rendererExpandable ? rendererExpandable.hasDetails(row) : false;
        const sublistRendererHasDetails = sublistRenderer ? sublistRenderer.hasDetails(row) : false;
        return sublistRendererHasDetails || rendererKeyValueHasDetails || rendererExpandableHasDetails;
      },
      DetailPanel: ({row}) => {
        row = resolveRow(row);
        return (
          <div>
            {rendererKeyValue && rendererKeyValue.hasDetails(row) && rendererKeyValue!.DetailPanel({row})}
            {rendererExpandable && rendererExpandable!.hasDetails(row) && rendererExpandable!.DetailPanel({row})}
            {sublistRenderer  && sublistRenderer!.hasDetails(row)  && sublistRenderer!.DetailPanel({row})}
          </div>
        );
      },
    };
  }, [rowElements]);
};


const resolveRow = (object: any): any => isObjectWithUpdateInformation(object) ? getLatest(object) : object;

const getLatest = <T extends object>(row: ObjectWithUpdateInformation<T>) => ArrayUtilities.last(row.updateInfo?.objectUpdates)?.object ?? row.object;

const isObjectWithUpdateInformation = (object: any): object is ObjectWithUpdateInformation<any> => {
  return "object" in object;
};
