/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";


const styles = (theme: IcmMuiTheme) => createStyles({
  noEntries: {
    padding: theme.spacing(2, 3),
  },
  compactRow: {
    height: 38,
  },
  headerLabel: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  wagonCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    verticalAlign: "top",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  headerCell: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
    borderBottomWidth: 2,
  },
  numberCell: {
    textAlign: "right",
  },
  loadInfoCell: {
    maxWidth: 330,
  },
  dangerousGoodInfo: {
    wordBreak: "break-word",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    paddingLeft: theme.spacing(1),
  },
  loadInfo: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  loadList: {
    "& li:first-child div": {
      marginTop: 0,
    },
    "& li:last-child div": {
      marginBottom: 0,
    },
  },
  loadListItem: {
    padding: 0,
  },
  loadListItemText: {
    marginTop: 1,
    marginBottom: 1,
  },

  infoPaper: {
    padding: theme.spacing(1),
    height: "100%",
  },


  wagonListContainer: {
    overflowX: "auto",
    width: "100%",
    height: 600,
  },

  dangerBoard: {
    flexBasis: 55,
    minWidth: 55,
    "& div": {
      width: 55,
      height: 25,
      lineHeight: 25,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: "black",
      backgroundColor: "orange",
      textAlign: "center",
      display: "table",
      "& *": {
        display: "table-cell",
        verticalAlign: "middle",
        lineHeight: "normal",
      },
      "& p": {
        fontSize: "1rem !important",
      },
    },
    "& :first-child": {
      borderBottomWidth: 1,
    },
    "& :last-child": {
      borderTopWidth: 1,
    },
  },
  trainPanelRidWarning: {
    color: "orange",
    display: "inline-flex",
    alignItems: "center",
  },
  trainPanelSummary: {
    "& div": {
      justifyContent: "space-between",
    },
  },
  trainSumCell: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
  },
  trainGeneralValue: {
    fontSize: theme.typography.body2.fontSize,
  },

});

export default styles;
