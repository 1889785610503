/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";
import {useMemo} from "react";

import {RADIOBUTTON} from "../../../constant";
import {GenericFormField} from "../../../generated/api";
import {getNamedComponentFactory} from "../../../ui";
import {arePropsEqual} from "../../../util";
import {IFormComponentProps} from "../AbstractFormComponent";
import {
  assertPropertiesExist,
  emptyPossibleValuesList,
  getValueFromPossibleValues,
  getValueFromSingleValue,
} from "../FormGeneratorUtils";

export type FormRadioComponentProps = IFormComponentProps<any> & {
  possibleValuesUrl?: string;
  field: GenericFormField;
  useSimpleValues: boolean;
};

export const FormRadioComponent = React.memo((props: FormRadioComponentProps) => {
  const {possibleValuesUrl, field, handleChange, useSimpleValues} = props;

  if (possibleValuesUrl) {
    assertPropertiesExist(field, "possibleValuesUrl", "possibleValuesDisplayBinding", "possibleValuesValueBinding");
  } else if (!props.readOnly) {
    assertPropertiesExist(field, "possibleValuesList");
  }

  const handleRadioChange = React.useMemo(() => {
    if (possibleValuesUrl) {
      return handleChange;
    } else {
      return (val: any) => handleChange(getValueFromSingleValue(val, useSimpleValues));
    }
  }, [possibleValuesUrl, handleChange, useSimpleValues]);

  // inconsistent: with a value list, comparsion is done here, with a value URL somewhere else!
  const value = useMemo(() => {
    // single references by id are returned as arrays now, we need to unwrap them:
    return getValueFromPossibleValues(unwrap(props.value), field.possibleValuesList);
  }, [props.value, field.possibleValuesList]);

  const Component = getNamedComponentFactory(RADIOBUTTON)
    || (() => <span>Radio button component is not supported.</span>);

  return (
    <Component {...props}
               value={undefined}
               selectedValue={value}
               handleChange={handleRadioChange}
               valueBinding={field.possibleValuesValueBinding || "_"}
               valueDisplay={field.possibleValuesDisplayBinding || "{{{label}}}"}
               versionBinding={field.possibleValuesVersionBinding}
               apiUrl={possibleValuesUrl}
               availableValues={possibleValuesUrl ? undefined : field.possibleValuesList || emptyPossibleValuesList}
    />
  );
}, arePropsEqual);

function unwrap(value: unknown): any {
  return Array.isArray(value) ? (value.length > 0 ? value[0] : undefined) : value;
}
