/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/**
 * Return a Promise which resolves after blocking a certain amount of time.
 *
 * @param value
 * @param delay
 */
export function resolveDelayed<T>(value: T, delay: number): Promise<T> {
  return new Promise((resolve) => window.setTimeout(() => resolve(value),  delay));
}
