/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {customAlphabet} from "nanoid";
import {v4 as uuidV4, v5 as uuidV5} from "uuid";

/**
 * Common namespaces for uuidV5 generated in ICM.
 */
const ICM_UUID5_NAMESPACE =  "de25d413-e6fa-4ccc-bc30-3aaa4eba4bb8";

/**
 * Return an upper case random string of length 4 using a custom alphabet.
 * The returned value excludes chars which could be misread in certain fonts
 * like l,1,I or 0 and O.
 */
const randomNanoId = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789", 4);

// TODO PICM-1501 rename (also contains randomNanoId function)
export const IdGenerator = {
  /**
   * @return a uuidv4
   */
  randomUUID: () => uuidV4(),

  /**
   * A UUIDv5 based in the provided fields. Use this if you need a
   * predicable hash of certain field values. Make sure to pass values
   * in a stable order.
   *
   * @param values a set of valies
   * @see
   */
  uuidv5: (...values: string[]) => {
    return uuidV5(JSON.stringify(values), ICM_UUID5_NAMESPACE);
  },


  randomNanoId,
};
