/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMemo} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";

import {FetchService, FetchServiceException, useFeedbackBanners} from "../service";
import {Consumer, TypeGuard} from "../util";

function settingsCacheKey(key: string) {
  return ["private", "core", "setting", "user", key];
}

/**
 * Low-Level Access to fetch properties from the backend.
 * Consider using the query client based hook.
 *
 * @see useUserSetting
 */
export class UserSettingsApi {
  public static postUserSetting(key: string, value: string): Promise<boolean> {
    return FetchService.performPost(`core/userSettings/${key}`, value)
      .then(response => response.json())
      .catch(async (ex: FetchServiceException) => {
        throw await ex.response?.json();
      });
  }

  public static getUserSetting(key: string): Promise<string | undefined> {
    return FetchService.performGet(`core/userSettings/${key}`, {catchableErrorCodes: [404]})
      .catch(() => console.log("no value found for key", key))
      .then((v: string) => v, () => undefined);
  }
}

/**
 * An object describing a single settings object.
 */
export type UserSetting<T> = {
  key: string,
  value: T | undefined,
  setValue: Consumer<T>
}

/**
 * Get the setting associated with the given key including an update function.
 * The hook is backed by the query client.
 *
 * @param key The property to query from the settings
 * @param guard A guard to type check the key associated with the given value.
 *        The guard will be used to check the parse JSON object. If it fails,
 *        the defaultValue is returned
 * @param defaultValue The value to be returned in case no setting was found OR the guard fails
 * @return A UserSettings object
 */
export function useUserSetting<T>(key: string, guard: TypeGuard<T>, defaultValue?: T): UserSetting<T> {
  const cacheKey = settingsCacheKey(key);
  const result = useQuery<T | undefined, unknown>(
    cacheKey,
    async () => {
      const value = await UserSettingsApi.getUserSetting(key);
      if (value) {
        const r = JSON.parse(value);
        if (guard(r)) {
          return r;
        }
      }
      return defaultValue;
    }
  );

  const feedbackBanner = useFeedbackBanners();
  const queryClient = useQueryClient();
  const setUserSetting = useMutation(cacheKey, async (newValue: T) => {
    const newValueString = JSON.stringify(newValue);
    return UserSettingsApi.postUserSetting(key, newValueString);
  }, {
    onSuccess: (success, newValue) => {
      if (success) {
        queryClient.setQueryData(cacheKey, newValue);
      }
    },
    onError: (e) => {
      console.warn("Could not save user setting", e);
      feedbackBanner.openFeedbackBanner({
        key: "STORE_SETTINGS",
        duration: "MEDIUM",
        variant: "ERROR",
        title: "Could not store user setting.", // TODO i18n
      });
    },
  });

  return useMemo(() => ({
    key,
    value: result.data ?? defaultValue,
    setValue: setUserSetting.mutate,
  }), [defaultValue, key, result.data, setUserSetting.mutate]);
}
