/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ACTIVITY_STREAM, ActivityStreamComponentProps} from "@icm/activitystream-common";
import {CompositeSubView, getNamedComponentFactory, MessageKey, useMessages} from "@icm/core-common";
import * as React from "react";


import {EntityEditModel} from "../store";


export type ActivityStreamSubViewProps = {
  editModel?: EntityEditModel,
  entityId?: string,
  entityType?: string,
  showActivityStream: boolean
  manualEntryCreation?: boolean
};

/**
 * Returns a ActivityStreamComponent wrapped in a CompositeSubView in case the showActivityStream flag
 * is true and a non draft editModel is provided. Returns undefined otherwise.
 *
 * @param props
 */
export const ActivityStreamSubView = (props: ActivityStreamSubViewProps) => {
  const {
    showActivityStream,
    editModel,
    entityId,
    entityType,
    manualEntryCreation = true,
  } = props;
  const {getMessage} = useMessages();

  if (showActivityStream && editModel && !editModel.draft) {
    const Component = getNamedComponentFactory(ACTIVITY_STREAM);
    if (Component) {
      // As the component is created in a non type-safe way, we create a type-safe ActivityStreamComponentProps hash to ensure that
      // we pass all required parameters.
      const safeProps: ActivityStreamComponentProps = {
        entity: editModel.entity,
        manualEntryCreation,
        entityId,
        entityType,
      };
      return (
        <CompositeSubView id="activityStream" label={getMessage(MessageKey.ACTIVITYSTREAM.TITLE)}>
          <Component {...safeProps} />
        </CompositeSubView>
      );
    }
  }
  return <></>;
};
