/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService, userDownloadService, ViewActionHandler} from "@icm/core-common";

import {entityDataService} from "../service";
import {EntityEditModel} from "../store";

export type ReportDownloadHandlerProps = {
  editModel?: EntityEditModel
  urlTemplate?: string,
  variant?: string
  enabled: boolean
}

export const createReportDownloadHandler = (props: ReportDownloadHandlerProps): ViewActionHandler<void> | undefined => {
  if (props.urlTemplate) {
    return createTemplateReportDownloadHandler(props);
  } else if (props.variant) {
    return createDefaultReportDownloadHandler(props);
  } else {
    return undefined;
  }
};


const createDefaultReportDownloadHandler = (props: ReportDownloadHandlerProps): ViewActionHandler<void> => {
  const {
    editModel,
    variant,
  } = props;
  return {
    run: () => {
      if (editModel && variant && editModel.entity?.type && editModel.entity?.id) {
        entityDataService.downloadEntityPdfReport(editModel.entity, variant);
      }
    },
    enabled: editModel && !editModel.draft && props.enabled,
  };
};

const createTemplateReportDownloadHandler = (props: ReportDownloadHandlerProps): ViewActionHandler<void> => {
  return {
    run: () => {
      if (props.urlTemplate && props.editModel && props.editModel.currentEntity) {
        const template: Record<string, any> = {};
        props.editModel.propertyChanges.forEach(change => {
          if (change.propertyValue) {
            template[change.propertyName!] = change.propertyValue;
          }
        });
        const url = ExpressionEvaluationService.evaluate(props.urlTemplate, template);
        userDownloadService.startDownload(url);
      }
    },
    enabled: props.enabled,
  };
};
