/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {GeneralError, Person} from "@icm/core-common";

import {Standby, StandbyTeam} from "../../generated/api";

export enum StandbyActionTypes {
  FETCH_STANDBY_TEAMS_REQUEST = "@@standby/FETCH_STANDBY_TEAMS_REQUEST",
  FETCH_STANDBY_TEAMS_SUCCESS = "@@standby/FETCH_STANDBY_TEAMS_SUCCESS",
  FETCH_STANDBY_TEAMS_ERROR = "@@standby/FETCH_STANDBY_TEAMS_ERROR",
  FETCH_STANDBY_TEAM_MEMBERS_REQUEST = "@@standby/FETCH_STANDBY_TEAM_MEMBERS_REQUEST",
  FETCH_STANDBY_TEAM_MEMBERS_SUCCESS = "@@standby/FETCH_STANDBY_TEAM_MEMBERS_SUCCESS",
  FETCH_STANDBY_TEAM_MEMBERS_ERROR = "@@standby/FETCH_STANDBY_TEAM_MEMBERS_ERROR",
  FETCH_STANDBY_REQUEST = "@@standby/FETCH_STANDBY_REQUEST",
  FETCH_STANDBY_SUCCESS = "@@standby/FETCH_STANDBY_SUCCESS",
  FETCH_STANDBY_ERROR = "@@standby/FETCH_STANDBY_ERROR",
  SUBMIT_STANDBY_REQUEST = "@@standby/SUBMIT_STANDBY_REQUEST",
  SUBMIT_STANDBY_SUCCESS = "@@standby/SUBMIT_STANDBY_SUCCESS",
  SUBMIT_STANDBY_ERROR = "@@standby/SUBMIT_STANDBY_ERROR",
  DELETE_STANDBY_REQUEST = "@@standby/DELETE_STANDBY_REQUEST",
  DELETE_STANDBY_SUCCESS = "@@standby/DELETE_STANDBY_SUCCESS",
  DELETE_STANDBY_ERROR = "@@standby/DELETE_STANDBY_ERROR",
  SET_INTERVAL = "@@standby/SET_INTERVAL",
  UPDATE_NEW_STANDBY = "@@standby/UPDATE_NEW_STANDBY",
  UPDATE_NEW_STANDBY_TIME = "@@standby/UPDATE_NEW_STANDBY_TIME",
  DELETE_ERROR = "@@standby/DELETE_ERROR"
}

export interface IStandbyState {
  readonly loadingStandbys: boolean
  readonly loadingStandbyTeams: boolean
  readonly loadingStandbyTeamMembers: boolean

  readonly error?: GeneralError
  readonly interval: number
  readonly newStandby: Standby
  readonly standbyList: Standby[]
  readonly standbyTeam?: StandbyTeam
  readonly availableStandbyTeams?: StandbyTeam[]
  readonly standbyTeamMembers?: Person[]
}
