/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {FilterConditionBase, FilterConditionGroup, FilterConditionItem} from "../../generated/api";
import {ExpressionEvaluationService} from "../ExpressionEvaluationService";
import {ItemFilterPredicateFactories} from "./ItemFilterPredicateFactory";

export type PropertyAccessor = (object: unknown, path: string) => unknown;
export type PropertySetter = (object: any, path: string, value: unknown) => void;

type Predicate = (o: unknown) => boolean;

type PropertyProvider = (propertyName: string) => unknown;

type PropertyProviderPredicate = (pp: PropertyProvider) => boolean;

function defaultPropertyAccessor(object: unknown, path: string): unknown {
  if (path.includes("=>")) {
    return ExpressionEvaluationService.evaluate(path, object);
  } else {
    // handle weak entity references: they contain a data[id] map that needs to be flat mapped as value
    // TODO: PICM-1483: refactor this to be part of entity module, or merge entity module into core
    const pathParts = path.endsWith("()") ? [path] : path.split(".");
    let pointers = Array.isArray(object) ? object : [object];
    pathParts.forEach(pathPart => {
      pointers = pointers.flatMap(pointer => {
        const candidate = ExpressionEvaluationService.get(pointer, pathPart);
        if (candidate && candidate.ids && candidate.data) {
          return candidate.ids.map((id: string) => candidate.data[id]);
        } else {
          return Array.isArray(candidate) ? candidate : [candidate];
        }
      });
    });

    // If the property we were looking for, did not exist, the pointers variable is: [undefined].
    // To indicate the property does not exist, we return simply undefined.
    if (pointers.length === 1 && pointers[0] === undefined) {
      return undefined;
    }
    return pointers;
  }
}
function defaultPropertySetter(object: any, path: string, value: unknown): void {
  ExpressionEvaluationService.set(object, path, value);
}

function isItem(base: FilterConditionBase): base is FilterConditionItem {
  return base.conditionType === "ITEM" && "property" in base;
}

function isGroup(base: FilterConditionBase): base is FilterConditionGroup {
  return base.conditionType === "GROUP";
}

class FilterPredicateFactory {
  private readonly propertyAccessor: PropertyAccessor = defaultPropertyAccessor;
  private readonly propertySetter: PropertySetter = defaultPropertySetter;

  public getPropertyAccessor(): PropertyAccessor {
    return this.propertyAccessor;
  }

  public getPropertySetter(): PropertySetter {
    return this.propertySetter;
  }

  public get(filter: FilterConditionBase): Predicate | undefined {
    const predicate = this.getPredicate(filter);
    return (value: unknown) => {
      const propertyProvider: PropertyProvider = propertyName => this.propertyAccessor(value, propertyName);
      return predicate(propertyProvider);
    };
  }

  private getPredicate(condition: FilterConditionBase): PropertyProviderPredicate {
    if (isItem(condition)) {
      if (condition.property) {
        const propertyName = condition.property;
        const predicate = this.getItemPredicate(condition);
        return propertyProvider => {
          const property = propertyProvider(propertyName);
          if (condition.valueType !== "ENUM" && Array.isArray(property)) {
            // TODO FW-2032 enable config to also allow allMatch
            return !!property.find(p => predicate(p));
          } else {
            return predicate(property);
          }
        };
      }
      throw Error(`Invalid filter condition property: ${condition.property}`);
    } else if (isGroup(condition)) {
      return this.getGroupPredicate(condition);
    } else {
      throw Error(`Invalid filter condition type: ${condition.conditionType}`);
    }
  }

  private getItemPredicate(filter: FilterConditionItem): (value: unknown) => boolean {
    if (filter.valueType && filter.operator) {
      const valueType = filter.valueType;
      const operator = filter.operator;
      const itemPredicate = ItemFilterPredicateFactories
        .filter(factory => factory.supports(valueType))
        .map(factory => factory.get(operator, filter.values || [], filter.settings))
        .find(predicate => predicate);
      if (itemPredicate) {
        return itemPredicate;
      }
    }
    throw Error(`Invalid item filter condition: ${filter}`);
  }

  private getGroupPredicate(filter: FilterConditionGroup): PropertyProviderPredicate {
    const predicates = filter.filterConditions?.map(condition => this.getPredicate(condition)) || [];
    switch (filter.logicalOperator) {
      case "AND":
        return propertyProvider => predicates.every(predicate => predicate(propertyProvider));
      case "OR":
        return propertyProvider => predicates.some(predicate => predicate(propertyProvider));
      case "NOT":
        return propertyProvider => !predicates.some(predicate => predicate(propertyProvider));
      default:
        throw Error(`Invalid filter logical operator: ${filter.logicalOperator}`);
    }
  }
}

const factory = new FilterPredicateFactory();

export {factory as FilterPredicateFactory};
