/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles, makeStyles} from "@mui/styles";
import {escapeRegExp} from "lodash-es";
import React from "react";

import {IcmMuiTheme} from "../../theme/IcmMuiTheme";

type Props = {
  text: string
  searchValue: string
}
const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  highlight: {
    fontWeight: "bold",
  },
}));

/**
 * A component that wraps the matches of `searchValue` in `text` with a bold fontWeight
 */
export const TextWithHighlights = ({text, searchValue}: Props): JSX.Element => {
  const classes = useStyles();
  if (searchValue) {
    const regex = new RegExp(escapeRegExp(searchValue), "gi");
    const renderArray: JSX.Element[] = [];
    let result: RegExpExecArray | null;
    let prevIndex = 0;
    while ((result = regex.exec(text)) !== null) { // NOSONAR
      if (prevIndex !== result.index) {
        renderArray.push(<React.Fragment key={prevIndex}>{text.substring(prevIndex, result.index)}</React.Fragment>);
      }
      renderArray.push(<span key={result.index} className={classes.highlight}>{result[0]}</span>);
      prevIndex = result.index + result[0].length;
    }
    renderArray.push(<React.Fragment key={prevIndex}>{text.substring(prevIndex)}</React.Fragment>);
    return <>{renderArray}</>;
  }
  return <>{text}</>;
};
