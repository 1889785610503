/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PossibleValue} from "@icm/core-common";
import {FileCopyOutlined, TextFormat} from "@mui/icons-material";
import {Button, IconButton, ListItem, ListItemButton, ListItemText, Menu} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useCallback, useMemo} from "react";

import {useClipboard} from "../../util";
import styles from "./TextBoxComponentStyle";

interface IFormatSelectionProps {
  format: string,
  formats: PossibleValue[],
  formatDisplay?: (value: string, format?: string, as?: "LABEL" | "INPUT" | "FULL") => string | null,
  handleChange: (format?: string) => void,
  valid: boolean,
  value: string
}

const useStyles = makeStyles(styles);
export const FormatSelection = React.memo((props: IFormatSelectionProps) => {
  const {handleChange, valid, formatDisplay, value, formats, format} = props;
  const clipboard = useClipboard();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClick = useCallback((frmt: string) => {
    handleChange(frmt);
    setAnchorEl(null);
  }, [handleChange]);

  const handleCopy = useCallback((event: React.MouseEvent, frmt: string) => {
    event.stopPropagation();
    const text = formatDisplay && valid ? formatDisplay(value, frmt, "FULL") : "";
    if (text) {
      clipboard.writeText(text);
    }
  }, [formatDisplay, valid, value, clipboard]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const currIndex = useMemo(() => Math.max(0, formats.findIndex(pv => pv.value === format)), [formats, format]);

  const button = useMemo(() => formats[currIndex]?.label
    ? <Button onClick={handleButtonClick} className={classes.adornmentTextButton}>{formats[currIndex].label}</Button>
    : <IconButton onClick={handleButtonClick} size="large"><TextFormat /></IconButton>,
  [formats, classes.adornmentTextButton, currIndex, handleButtonClick]);

  return (
    <div>
      {button}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {formats.map((frmt, index) => {
          const formatValue = frmt.value || "";
          return (
            <ListItem key={index}
                      selected={index === currIndex}
                      disablePadding
                      secondaryAction={clipboard.hasClipboard && formatDisplay?.(value, formatValue, "FULL") && (
                        <IconButton disabled={!valid}
                                    edge="end"
                                    size="small"
                                    onClick={(e) => handleCopy(e, formatValue)}
                        >
                          <FileCopyOutlined />
                        </IconButton>
                      )}
            >
              <ListItemButton role={undefined} onClick={() => handleMenuClick(formatValue)}>
                <ListItemText>
                  {formatDisplay ? formatDisplay(value, formatValue, "LABEL") : frmt.label || formatValue}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </Menu>
    </div>
  );
});
