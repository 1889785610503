/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/


/**
 * Create a stable query key for a given object. This is useful to get a
 * stable string representation of an object (as opposed to JSON.stringify).
 * The returned string should be used if you want to use an object as part of a
 * QueryClient key.
 *
 * @param input Any object.
 * @param hiddenProps properties of the input object that should not be part of the generated string.
 * The properties are checked on the root level object only.
 * @return a string representation of the passed obejct.
 */
export const objectQueryKey = (input?: unknown, hiddenProps: string[] = []): string => {
  if (input === undefined) {
    return "undefined";
  }
  return doCreateQueryKey(hiddenProps, input);
};


const doCreateQueryKey = (hiddenProps: string[], input?: any): string => {
  if (Array.isArray(input)) {
    return JSON.stringify(input.map(i => doCreateQueryKey(hiddenProps, i)).sort());
  } else if (input !== null && typeof input === "object") {
    const sortedKeys = Object.keys(input).sort();
    return sortedKeys.filter(k => !hiddenProps.includes(k)).map(key => `${key}:${doCreateQueryKey([], input[key])}`).join(", ");
  } else if (input === null) {
    return "null";
  } else if (input === undefined) {
    return "undefined";
  } else if (typeof input === "function") {
    const functionName = input.name || "(anonymous)";
    return `[function ${functionName}]`;
  } else if (input instanceof Date) {
    return input.toISOString();
  } else {
    return String(input);
  }
};
