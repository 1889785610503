/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";

const styles = (theme: IcmMuiTheme) => createStyles({
  root: {
    padding: theme.spacing(2),
    "& sup": {
      fontSize: "75%",
    },
  },

  heading1: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#cccccc",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: theme.spacing(0.5),
  },

  heading2: {
    borderTopWidth: 0,
    borderTopStyle: undefined,
    fontWeight: "bold",
    fontSize: 14,
  },

  indent1: {
    marginLeft: theme.spacing(1),
  },

  changeInformation: {
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#cccccc",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  lineThrough: {
    "& > p:not(:last-child), & > div:not(:last-child)": {
      textDecoration: "line-through",
      color: "#969696",
    },
  },

  propertyName: {
    flex: 0.33,
    flexWrap: "wrap",
  },

  propertyValue: {
    flex: 1,
  },

  changeSet: {
    marginBottom: theme.spacing(0.25),
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
  deletedValue: {
    fontStyle: "italic",
  },
  deletedChapter: {
    fontStyle: "italic",
    margin: "10px 0px",
  },
});

export default styles;
