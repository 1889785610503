/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import Keycloak, {KeycloakInitOptions, KeycloakProfile, KeycloakTokenParsed} from "@icm/keycloak-js";

import {Paths, Urls} from "../../../constant";
import {UserDetails} from "../../../data";
import {LoginConfiguration, LoginRequest, LoginStatus, Role, SecurityConfiguration} from "../../../generated/api";
import {FetchService, NavigationService} from "../../index";
import {AuthenticationService, FailedLoginListener, LoginResult, LogoutStorageCleanup} from "../AuthenticationService";
import {KeycloakStartSecurityOptions} from "./types";

type ParsedToken = KeycloakTokenParsed & {
  email?: string

  // eslint-disable-next-line camelcase
  preferred_username?: string

  // eslint-disable-next-line camelcase
  given_name?: string

  // eslint-disable-next-line camelcase
  family_name?: string
}

const createKeycloak = (configuration: SecurityConfiguration): Keycloak => {
  if (configuration.keycloakAuthenticationAdapter) {
    return new Keycloak({
      url: configuration.keycloakAuthenticationAdapter.url!,
      realm: configuration.keycloakAuthenticationAdapter.realm!,
      clientId: configuration.keycloakAuthenticationAdapter.frontendResource!,
    });
  } else {
    throw new Error("Keycloak can not be initialized from configuration: " + JSON.stringify(configuration));
  }
};

const getRoles = (currentStatus: LoginStatus | null, userName: string | undefined): Role[] => {
  return currentStatus && currentStatus.user?.userName === userName ? currentStatus.user?.roles || [] : [];
};

/**
 * This is a wrapper service for the keycloak.js library. It provides convenience methods and acts as bridge between
 * the SecurityService and keycloak.js. It implements the AuthenticationService for that. To work, it needs
 * the fields from the KeycloakAuthenticationAdapter provided by the SecurityConfiguration.
 *
 * User and roles are retrieved from the configured keycloak server.
 *
 * Do not use this type directly. Instead, use its interface AuthenticationService.
 */
class KeycloakAuthenticationService implements AuthenticationService {

  private readonly configuration: SecurityConfiguration;

  private currentStatus: LoginStatus | null;

  private readonly keycloak: Keycloak;

  private loginTargetUrl: string | undefined;

  private readonly navigationService: NavigationService;

  private profile?: KeycloakProfile;

  // Do not add the SecurityService here, you are doing something wrong!
  constructor(configuration: SecurityConfiguration, navigationService: NavigationService) {
    this.configuration = configuration;
    this.navigationService = navigationService;
    this.keycloak = createKeycloak(configuration);
  }

  addFailedLoginListener(_failedLoginListener: FailedLoginListener): void {
    // NO-OP
  }

  addFailedPasswordChangeListener(_failedPasswordChangeListener: (currentPasswordInvalid: boolean, newPasswordInvalid: boolean) => void): void {
    // NO-OP
  }

  authenticateWithCredentials(_name: string, _plainPassword: string): Promise<LoginResult | undefined> {
    return Promise.resolve(undefined);
  }

  changePassword(_username: string, _newPassword: string, _currentPassword?: string): void {
    // NO-OP
  }

  checkLoginState(): Promise<LoginResult | undefined> {
    return Promise.resolve(undefined);
  }

  getAndClearLoginTargetUrl(): string | undefined {
    if (this.loginTargetUrl) {
      const result = this.loginTargetUrl;
      this.loginTargetUrl = undefined;
      return result;
    } else {
      return undefined;
    }
  }

  async getAuthenticationHeaders(): Promise<Record<string, string>> {
    if (this.isLoggedIn()) {
      return this.keycloak.updateToken(5).then(() => ({
        Authorization: "Bearer " + this.keycloak.token,
      }));
    }
    return {};
  }

  getCurrentUserDetails(): UserDetails | undefined {
    const token: ParsedToken | undefined = this.keycloak.tokenParsed;
    if (this.profile) {
      const {username, firstName, lastName} = this.profile;
      return {userName: username, firstName: firstName, lastName: lastName, attributes: {}, roles: getRoles(this.currentStatus, username)};
    } else if (this.isLoggedIn() && token) {
      const {preferred_username: username, given_name, family_name} = token;
      /* eslint-disable camelcase */
      return {userName: username, firstName: given_name, lastName: family_name, attributes: {}, roles: getRoles(this.currentStatus, username)};
    } else {
      return undefined;
    }
  }

  handleSkippedPasswordChange(_request: LoginRequest, _response: LoginStatus): Promise<LoginResult | undefined> {
    return Promise.resolve(undefined);
  }

  isLoggedIn(): boolean {
    return this.keycloak.authenticated || false;
  }

  login(): Promise<void> {
    console.log("Performing login...");
    return this.keycloak.login().then(async () => {
      await this.loadProfile();
    });
  }

  async logout(logoutStorageCleanup?: LogoutStorageCleanup): Promise<void> {
    console.log("Performing logout...");
    if (logoutStorageCleanup) {
      await logoutStorageCleanup(false);
    }
    return this.keycloak.logout();
  }

  removeFailedLoginListener(_failedLoginListener: FailedLoginListener): void {
    // NO-OP
  }

  removeFailedPasswordChangeListener(_failedPasswordChangeListener: (currentPasswordInvalid: boolean, newPasswordInvalid: boolean) => void): void {
    // NO-OP
  }

  startSecurity(options: KeycloakStartSecurityOptions): Promise<LoginResult | undefined> {
    console.info("Starting Keycloak adapter...");
    const {requireLogin, adapter} = options;
    const keycloakInitOptions: KeycloakInitOptions = requireLogin ? {onLoad: "login-required"} : {onLoad: "check-sso"};
    keycloakInitOptions.adapter = adapter;
    keycloakInitOptions.enableLogging = true;
    keycloakInitOptions.responseMode = "query";
    return this.keycloak.init(keycloakInitOptions)
      .then(() => {})
      // .then(() => this.login())
      .then(() => {
        return this.loadProfile();
      })
      .catch(error => {
        console.error("Error initializing keycloak: ", error);
        return undefined;
      });
  }

  private getLoginConfiguration(): LoginConfiguration {
    return this.configuration.supportedAuthentications?.loginConfiguration || new LoginConfiguration();
  }


  private isRoleSelectionRequired(): boolean | undefined {
    return this.getLoginConfiguration().roleSelectionRequired;
  }

  private async loadProfile(): Promise<LoginResult | undefined> {
    console.debug("Trying to load user profile...");
    try {
      const profile = await this.keycloak.loadUserProfile();
      this.profile = profile;
      console.debug("Authenticated as", profile, this.keycloak.tokenParsed);
    } catch (error) {
      console.error("Error loading profile - make sure the user has the view-profile permission", error, JSON.stringify(this.keycloak.tokenParsed));
    }
    // eagerly fetch the login status, in order to avoid a race condition with the perspective mapper, regarding active roles
    if (this.profile) {
      try {
        await this.setLoginStatus(await FetchService.performGet(Urls.LOGIN_STATUS, {headers: await this.getAuthenticationHeaders()}));

        const roleSelectionRequired = this.isRoleSelectionRequired() || false;
        const userRoles = this.getCurrentUserDetails()?.roles || [];
        console.info("User name and password are valid, role required:", roleSelectionRequired, "roles:", userRoles, "configuration:", this.configuration);
        const url = this.navigationService.getUrl();
        if (url !== "/" && url !== Paths.LOGIN && url !== Paths.PASSWORD_CHANGE && url !== Paths.ROLE_SELECTION) {
          console.info("Set target url:", url);
          this.loginTargetUrl = url;
        } else {
          this.loginTargetUrl = "/";
        }

        return {
          roles: userRoles,
          roleSelectionRequired,
        };
      } catch (ex) {
        // NO-OP
      }
    }

    return undefined;
  }

  private async setLoginStatus(status: LoginStatus | null): Promise<void> {
    this.currentStatus = status;
  }
}

export {KeycloakAuthenticationService};
