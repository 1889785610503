/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import React, {PropsWithChildren, useRef} from "react";

import {IcmMuiTheme} from "../../theme/IcmMuiTheme";

const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  base: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
}));

/**
 * container that automatically fills out the whole view and adds scrollbars for overflowing content
 */
export const ViewContentBox = ({children, className, ...boxProps}: PropsWithChildren<BoxProps>) => {
  const classes = useStyles();
  const ref = useRef<HTMLElement | null>(null);
  return (
    <Box {...boxProps}
         className={clsx({
           [className ?? ""]: !!className,
           [classes.base]: true,
         })}
         ref={ref}
    >
      <ViewContentBoxContext.Provider value={ref.current}>
        {children}
      </ViewContentBoxContext.Provider>
    </Box>
  );
};

/**
 * A context that will provide a ref to the element that wraps the content
 */
export const ViewContentBoxContext = React.createContext<HTMLElement | null>(null);
