/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  closeTab,
  CommonConfirmationDialogOptions,
  CONFIRM_OK_CANCEL,
  CONFIRM_YES_NO,
  ConfirmDialogProps,
  ConfirmDialogProvider,
  isCommonConfirmation,
  TabGroupTab,
  useAvailableViewDescriptors,
  useDialogContext,
  useMessages,
} from "@icm/core-common";
import * as React from "react";
import {Dispatch, useCallback} from "react";
import {useDispatch} from "react-redux";

import {TabGroupDescriptor} from "./useTabGroupDescriptor";


export const useCloseTabStrategy = (groupDescriptor: TabGroupDescriptor) => {
  const dispatch = useDispatch();
  const availableViewDescriptors = useAvailableViewDescriptors();
  const {selectedTab} = groupDescriptor;

  const viewDescriptor = selectedTab ? availableViewDescriptors[selectedTab.viewModel.viewType] : undefined;

  const dialogContext = useDialogContext();
  const {getMessage} = useMessages();
  const confirm = dialogContext.useConfirmDialog();

  return useCallback((_event: React.SyntheticEvent) => {
    if (selectedTab && viewDescriptor) {
      const closeOptions = viewDescriptor.getCloseOptions?.(selectedTab.viewModel.viewModelData, getMessage);
      if (isCommonConfirmation(closeOptions)) {
        closeOnConfirmation(dispatch, confirm, closeOptions, groupDescriptor.id, selectedTab);
      } else {
        // assert DO_NOTHING on close
        dispatchClose(dispatch, groupDescriptor.id, selectedTab);
      }
    }
  }, [dispatch, confirm, getMessage, selectedTab, groupDescriptor, viewDescriptor]);
};


function closeOnConfirmation(dispatch: Dispatch<any>,
                             confirmDialog: ConfirmDialogProvider,
                             options: CommonConfirmationDialogOptions,
                             tabGroupId: string, selectedTab: TabGroupTab) {
  const confirmProps = buildConfirmProps(options);
  confirmDialog(confirmProps)
    .then(confirmed => {
      if (confirmed) {
        dispatchClose(dispatch, tabGroupId, selectedTab);
      }
    });
}

function buildConfirmProps(options: CommonConfirmationDialogOptions): Omit<ConfirmDialogProps, "onConfirm" | "onCancel"> {
  switch (options.optionsType) {
    case CONFIRM_OK_CANCEL:
      return {
        confirmLabel: "OK",
        cancelLabel: "CANCEL",
        title: options.title,
        description: options.description,
      };
    case CONFIRM_YES_NO:
      return {
        confirmLabel: "YES",
        cancelLabel: "NO",
        title: options.title,
        description: options.description,
      };
    default:
      throw new Error(`Unknown close options ${options}`);
  }
}

function dispatchClose(
  dispatch: Dispatch<any>,
  tabGroupId: string,
  selectedTab: TabGroupTab
) {
  dispatch(closeTab({
    tabGroupId,
    tabId: selectedTab.id,
    editModelId: selectedTab.viewModel.editModelId,
  }));
}
