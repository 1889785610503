/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {uniqWith} from "lodash-es";
import {useContext, useMemo} from "react";

import {ViewContext} from "../components";
import {ActivityStreamParameter, ArrayParameter, FilterParameter, ObjectLinkingParameter, ObjectParameter, Parameter, ParameterList} from "../generated/api";
import {ExpressionEvaluationService} from "../service";
import {TypeGuard} from "./TypeGuard";

type ResolvedValue = any; // string | number | boolean | bigint | object | undefined;

export type ResolvedParameter = {
  key?: string // only optional because Parameter.key is optional
  value?: ResolvedValue
}

export type ResolvedArrayParameter = {
  key?: string // only optional because Parameter.key is optional
  values?: ResolvedValue[]
}

export type ResolvedObjectParameter = {
  key: string
  value: object
}

export const isResolvedObjectParameter = (val: any): val is ResolvedObjectParameter => {
  return val !== null
  && typeof val === "object"
  && typeof val["key"] === "string"
  && typeof val["value"] === "object";
};

const isObjectParameter = (val: any): val is Required<ObjectParameter> => {
  return val !== null
    && typeof val === "object"
    && typeof val["key"] === "string"
    && Array.isArray(val["attributes"]);
};

type AbstractParameter = Parameter | ArrayParameter | ObjectParameter | ResolvedObjectParameter | ResolvedArrayParameter;

export type ResolvedParameterList = {
  filter?: FilterParameter
  objectLinking?: ObjectLinkingParameter
  activityStream?: ActivityStreamParameter
  genericParameters?: ResolvedParameter[]
  arrayParameters?: ResolvedArrayParameter[]
  objectParameters?: ResolvedObjectParameter[]
}

export class ParameterUtilities {

  /**
   * Combines two (original or resolved) parameter lists.
   * Properties from `list1` take precedence.
   *
   * @param list1 the unresolved or resolved list 1
   * @param list2 the unresolved or resolved list 2
   * @return the combined list
   */
  public static combineParameterLists<T extends ParameterList | ResolvedParameterList>(list1?: T, list2?: T): T {
    if (list1 && list2) {
      return {
        ...list2,
        ...list1,
        genericParameters: ParameterUtilities.combine(list1.genericParameters, list2.genericParameters),
        arrayParameters: ParameterUtilities.combine(list1.arrayParameters, list2.arrayParameters),
        objectParameters: ParameterUtilities.combine(list1.objectParameters, list2.objectParameters),
      };
    } else {
      return list1 ?? list2 ?? ({} as any);
    }
  }

  private static combine(list1?: AbstractParameter[], list2?: AbstractParameter[]): AbstractParameter[] | undefined {
    if (list1 || list2) {
      return uniqWith([...(list1 ?? []), ...(list2 ?? [])], (p1, p2) => p1.key === p2.key);
    }
    return undefined;
  }

  /**
   * Turns the given parameter list into a record.
   * Generic parameters take precedence before array parameters and other fields in the parameter list.
   *
   * @param list the unresolved or resolved parameter list
   * @return the record containing the parameters
   */
  public static flattenParameterList(list?: ParameterList | ResolvedParameterList): Record<string, any> {
    let result: Record<string, any> = {};
    if (list) {
      Object.entries(list).forEach(([key, parameter]) => {
        if (key !== "genericParameters" && key !== "arrayParameters" && key !== "objectParameters") {
          result[key] = parameter;
        }
      });
      result = {
        ...result,
        ...ParameterUtilities.flattenArrayParameters(list.arrayParameters),
        ...ParameterUtilities.flattenObjectParameters(list.objectParameters),
        ...ParameterUtilities.flattenParameters(list.genericParameters),
      };
    }
    return result;
  }

  /**
   * Turns the given array of parameters into a Record using the key of
   * a parameter as record key. undefined values will be skipped.
   *
   * @param list the unresolved or resolved parameters
   * @return the record containing the parameters
   */
  public static flattenParameters(list?: ResolvedParameter[] | Parameter[]): Record<string, any> {
    const result: Record<string, any> = {};
    if (list) {
      list.forEach(parameter => {
        if (parameter.key && parameter.value) {
          result[parameter.key] = parameter.value;
        }
      });
    }
    return result;
  }

  /**
   * Turns the given array of parameters into a Record using the key of
   * a parameter as record key. undefined values will be skipped.
   *
   * @param list the unresolved or resolved parameters
   * @return the record containing the parameters
   */
  public static flattenArrayParameters(list?: ResolvedArrayParameter[] | ArrayParameter[]): Record<string, any> {
    const result: Record<string, any> = {};
    if (list) {
      list.forEach(parameter => {
        if (parameter.key && parameter.values) {
          result[parameter.key] = parameter.values;
        }
      });
    }
    return result;
  }

  /**
   * Turns the given object parameter into a Record using the key of the
   * parameter as record key and the value as value.
   * Undefined values will be skipped.
   *
   * @param list the unresolved or resolved parameters
   * @return the record containing the parameters
   */
  public static flattenObjectParameters(list?: ResolvedObjectParameter[] | ObjectParameter[]): Record<string, any> {
    const result: Record<string, any> = {};
    list?.forEach(parameter => {
      if (isResolvedObjectParameter(parameter)) {
        result[parameter.key] = parameter.value;
      } else if (isObjectParameter(parameter)) {
        result[parameter.key] = parameter.attributes;
      }
    });
    return result;
  }


  public static getResolvedParameter(key: string, parameters?: ResolvedParameter[]): ResolvedValue {
    return parameters?.find((p: ResolvedParameter) => p.key === key)?.value;
  }

  public static getResolvedArrayParameter(key: string, parameters?: ResolvedArrayParameter[]): ResolvedValue {
    return parameters?.find((p: ResolvedArrayParameter) => p.key === key)?.values;
  }

  public static getResolvedParameterValue<T = unknown>(key: string, parameterList: ResolvedParameterList, typeGuard: TypeGuard<T>): T | undefined {
    const value = parameterList?.genericParameters?.find((p: ResolvedParameter) => p.key === key)?.value;
    if (value !== undefined && typeGuard(value)) {
      return value;
    } else {
      return undefined;
    }
  }

  /**
   * Check if a given parameter is either the string "true" or the boolean value true.
   *
   * @param key the key to check
   * @param parameterList the parameter list
   * @param defaultValue fallback value
   */
  public static getResolvedFlag(key: string, parameterList: ResolvedParameterList, defaultValue: boolean): boolean {
    const value = parameterList?.genericParameters?.find((p: ResolvedParameter) => p.key === key)?.value;
    if (value !== undefined) {
      return value === "true"  || value === true;
    }
    return defaultValue;
  }

  public static getResolvedArrayParameterValue<T>(key: string, parameterList: ResolvedParameterList, mapper: (item: any) => T) {
    return parameterList.arrayParameters?.find(param => param.key === key)
      ?.values
      ?.map(mapper)
      ?.filter(nonNull);
  }

  public static resolveParameters(parameters?: Parameter[], context?: any): ResolvedParameter[] | undefined {
    return parameters?.map(({key, value}) => {
      return {
        key: key ?? "",
        value: ParameterUtilities.resolveValue(value, context),
      };
    });
  }

  public static resolveArrayParameters(parameters?: ArrayParameter[], context?: any): ResolvedArrayParameter[] | undefined {
    return parameters?.map(({key, values}) => {
      return {
        key: key ?? "",
        values: values?.map(value => ParameterUtilities.resolveValue(value, context)),
      };
    });
  }

  /**
   * Map the array of object parameters to an array of resolved parameters.
   * @param parameters
   * @param context
   */
  public static resolveObjectParameters(parameters?: ObjectParameter[], context?: any): ResolvedObjectParameter[] {
    return parameters?.map((p) => {
      const attributes = ParameterUtilities.resolveParameters(p.attributes, context);

      const object = {};
      attributes?.forEach((resolvedParameter) => {
        if (resolvedParameter.key) {
          object[resolvedParameter.key] = resolvedParameter.value;
        } else {
          console.warn("Parameter", resolvedParameter, "has no key. Skipping.");
        }
      });

      return {
        key: p.key!,
        value: object,
      };
    }) ?? [];
  }

  public static resolveValue(value?: any, context?: any): any {
    return typeof value === "string" ? ExpressionEvaluationService.evaluate(value, context ?? {}) : value;
  }

  public static resolveParameterList(parameterList?: ParameterList, context?: any): ResolvedParameterList | undefined {
    return {
      // other parameters don't need resolving because they are already typed
      ...parameterList,
      genericParameters: ParameterUtilities.resolveParameters(parameterList?.genericParameters, context),
      arrayParameters: ParameterUtilities.resolveArrayParameters(parameterList?.arrayParameters, context),
      objectParameters: ParameterUtilities.resolveObjectParameters(parameterList?.objectParameters, context),
    };
  }
}

export function isString(v: any): v is string {
  return typeof v === "string";
}

/**
 * @deprecated transfer view parameters into viewModel and use that instead
 */
export function useViewParameters(): ResolvedParameterList {
  return useContext(ViewContext)?.viewParameters ?? {};
}

/**
 * Use the requested view param (retrieved from ViewContext)
 * @deprecated
 */
export function useGenericViewParam(key: string): ResolvedValue {
  const genericParameters = useViewParameters().genericParameters;
  return useMemo(() => ParameterUtilities.getResolvedParameter(key, genericParameters),
    [genericParameters, key]);
}

const nonNull = <T>(arg: T): arg is Exclude<T, null> => {
  return arg !== null;
};
