/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";
import {messages} from "./MessageService";

type Symbols = {
  decimal?: string;
  group?: string;
  minusSign?: string;
  plusSign?: string;
  infinity?: string;
  nan?: string
};

const serviceByLocale: {[key: string]: NumberService} = {};

class NumberService {

  private locale: string;

  private symbols: Symbols = {};

  private latinizer: (s: string) => string;

  constructor(locale?: string) {
    this.locale = locale || "";
    if (!locale) {
      // only for default service
      ExpressionEvaluationService.registerHelper("number", "format",
        (n, format) => this.format(NumberService.toNumber(n), format || NumberService.namedFormat("float") || "0.##"));
      ExpressionEvaluationService.registerHelper("number", "parse",
        (n) => this.parse(n));
      ExpressionEvaluationService.registerHelper("number", "validate",
        (n, pred) => this.validate(n, pred));
      ExpressionEvaluationService.registerHelper("number", "validateWithin",
        (n, min, max) => this.validateWithin(n, min, max));
      ExpressionEvaluationService.registerHelper("number", "formatInt",
        (n, format) => this.format(NumberService.toNumber(n), format || NumberService.namedFormat("int") || "0"));
      ExpressionEvaluationService.registerHelper("number", "formatFloat",
        (n, format) => this.format(NumberService.toNumber(n), format || NumberService.namedFormat("float") || "0.##"));
    }
  }

  public withLocale(locale: string): NumberService {
    if (locale === this.locale) {
      return this;
    } else if (!serviceByLocale[locale]) {
      serviceByLocale[locale] = new NumberService(locale);
    }
    return serviceByLocale[locale];
  }

  private init() {
    if (this.locale || messages.isReady()) {
      this.locale = this.locale || (messages.has("core.language") ? messages.get("core.language") : null) || "en-US";
      const numFormat = new Intl.NumberFormat(this.locale, {useGrouping: true, minimumFractionDigits: 2, signDisplay: "exceptZero"});
      const posParts = numFormat.formatToParts(12345.67);
      const negParts = numFormat.formatToParts(-12345.67);
      const infParts = numFormat.formatToParts(Number.POSITIVE_INFINITY);
      const nanParts = numFormat.formatToParts(Number.NaN);
      this.symbols = {
        decimal: posParts.find(p => p.type === "decimal")?.value,
        group: posParts.find(p => p.type === "group")?.value,
        plusSign: posParts.find(p => p.type === "plusSign")?.value,
        minusSign: negParts.find(p => p.type === "minusSign")?.value,
        infinity: infParts.find(p => p.type === "infinity")?.value,
        nan: nanParts.find(p => p.type === "nan")?.value,
      };
      const numerals = new Intl.NumberFormat(this.locale, {useGrouping: false}).format(9876543210);
      if (numerals.length === 10 && numerals !== "9876543210") {
        // we only support decimal systems!
        const re = new RegExp(`[${numerals}]`, "g");
        this.latinizer = (s: string) => s.replace(re, c =>  String.fromCharCode(57 - numerals.indexOf(c)));
      }
      this.init = () => null;
    }
  }

  private static toNumber(n: number | string | null | undefined): number | null {
    if (typeof n === "number") {
      return n;
    } else if (typeof n === "string") {
      return Number.parseFloat(n);
    }
    return null;
  }

  /**
   * Format a number.
   *
   * @param n the number to format
   * @param nameOrFormat the name of the pattern or the pattern (like in Java)
   */
  public format(n: number | null | undefined, nameOrFormat?: string): string {
    this.init();
    if (typeof n !== "number" || isNaN(n)) {
      return "";
    }
    const format = nameOrFormat ? NumberService.namedFormat(nameOrFormat) || nameOrFormat : NumberService.namedFormat("default") || "0";
    return new Intl.NumberFormat(this.locale, NumberService.options(format)).format(n);
  }

  private static options(format: string): Intl.NumberFormatOptions {
    const opts: Intl.NumberFormatOptions = {};
    const parts = format.split(".");
    if (parts.length > 0) {
      opts.useGrouping = parts[0].includes(",");
      opts.minimumIntegerDigits = Math.max(1, NumberService.countChars(parts[0], "0"));
      if (parts.length > 1) {
        opts.minimumFractionDigits = NumberService.countChars(parts[1], "0");
        opts.maximumFractionDigits = opts.minimumFractionDigits + NumberService.countChars(parts[1], "#");
      } else {
        opts.maximumFractionDigits = 0;
      }
    }
    return opts;
  }

  private static namedFormat(name: string): string | null {
    return messages.isReady() && messages.has(`core.format.number.${name}`) ? messages.get(`core.format.number.${name}`) : null;
  }

  private static countChars(s: string, c: string): number {
    let n = 0;
    for (let i = 0; i < s.length; i++) {
      if (s.charAt(i) === c) {
        n++;
      }
    }
    return n;
  }

  /**
   * Parse a number.
   *
   * @param text the number as text
   */
  public parse(text: string | number | null | undefined): number | null {
    this.init();
    if (typeof text === "number") {
      return text;
    }
    const s = text?.trim();
    if (!s) {
      return null;
    } else if (this.symbols.nan && s.includes(this.symbols.nan)) {
      return Number.NaN;
    } else if (this.symbols.infinity && s.includes(this.symbols.infinity)) {
      return this.symbols.minusSign && s.includes(this.symbols.minusSign) ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    } else {
      let n = s.replace(/\s+/g, "");
      this.symbols.group && (n = n.replace(this.symbols.group, ""));
      this.symbols.minusSign && (n = n.replace(this.symbols.minusSign, "-"));
      this.symbols.decimal && (n = n.replace(this.symbols.decimal, "."));
      this.latinizer && (n = this.latinizer(n));
      // support minus at end
      if (n.endsWith("-") && !n.startsWith("-")) {
        n = "-" + n.substring(0, n.length - 1);
      }
      // Number.parseFloat(n) ignores invalid characters at end, must use Number(n)!
      const parsed = Number(n);
      return Number.isNaN(parsed) ? null : parsed;
    }
  }

  public validateWithin(number: number | null | undefined, min?: number | null, max?: number | null): number | null {
    return this.validate(number, n => (min == null || min <= n) && (max == null || n <= max));
  }

  public validate(number: number | null | undefined, predicate: (n: number) => boolean): number | null {
    return number != null && predicate(number) ? number : null;
  }

  public isNumber(value: any): boolean {
    return typeof value === "number" || (typeof value === "string" && value.length > 0 && !Number.isNaN(Number(value)));
  }

}

const numberService = new NumberService();

export {numberService};
