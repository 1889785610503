/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {produce} from "immer";
import {forOwn} from "lodash-es";

import {IdGenerator} from "../util";

export interface ListComponentValue {
  type: "ListComponentValue",
  ids: string[],
  data: Partial<Record<string, any>>,
}

export type SafeObjectPropertyUpdate = {
  propertyName: string,
  propertyValue: any,
}

export interface ListComponentValueAndChanges extends ListComponentValue {
  changes: SafeObjectPropertyUpdate[],
  deletedPropertyNamePrefix?: string[],
}

export const createListComponentEmptyValue = (): ListComponentValueAndChanges => {
  return {
    type: "ListComponentValue",
    data: {},
    ids: [],
    changes: [],
  };
};

export const produceListComponentEntity = (
  existingValue: ListComponentValueAndChanges,
  entity: any,
  propertyUpdates: SafeObjectPropertyUpdate[]
): ListComponentValueAndChanges => {
  return produce(existingValue, draft => {
    draft.type = "ListComponentValue";
    draft.ids = [...draft.ids, entity.id];
    draft.data[entity.id] = entity;
    draft.changes = draft.changes || [];
    draft.changes.push({
      propertyName: "ids",
      propertyValue: draft.ids,
    });
    draft.changes.push({
      propertyName: "data." + entity.id,
      propertyValue: {},
    });
    draft.changes.push({
      propertyName: "data." + entity.id + ".id",
      propertyValue: entity.id,
    });
    propertyUpdates.forEach(u => draft.changes.push({
      propertyName: "data." + entity.id + "." + u.propertyName,
      propertyValue: u.propertyValue,
    }));
  });
};

export const toListValue = (value: any): ListComponentValueAndChanges => {
  // Handle simple array data.
  if (value && Array.isArray(value)) {
    const array = value as Array<any>;
    const ids: string[] = [];
    const data: any = {};
    array.forEach(val => {
      const id = val.id || IdGenerator.randomUUID();
      ids.push(id);
      data[id] = val;
    });
    return {
      type: "ListComponentValue",
      ids: ids,
      data: data,
      changes: [],
    };
  } else {
    return value;
  }
};

export const getValueFromListValue = (listValue: ListComponentValue, useSimpleValues: boolean): any => {
  // This code supports forms that require a simple array out of a list component.
  // Typically used against remote API: Consider event notifications ins REM, for example.
  if (useSimpleValues) {
    const result: any = [];
    forOwn(listValue.data, (value, _key) => {
      if (!!value) {
        const clonedValue: any = JSON.parse(JSON.stringify(value));
        if (clonedValue?.dynamicAttributes) {
          forOwn(clonedValue.dynamicAttributes, (attribute, attributeKey) => {
            clonedValue.dynamicAttributes[attributeKey].value = attribute?.value?.value ? attribute.value?.value : attribute.value;
          });
        }
        result.push(clonedValue);
      }
    });
    return result;
  } else {
    return listValue;
  }
};
