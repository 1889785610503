/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {markNotificationsAsAnnounced, MessageKey, NotificationCenterProvider, useMessages, useNotificationCenter} from "@icm/core-common";
import {Snackbar} from "@mui/material";
import * as React from "react";
import {RefObject, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {IconButtonComponent} from "../../components";
import {NotificationCenter} from "./NotificationCenter";


type NotificationCenterIconProps = {
  anchor: RefObject<HTMLDivElement>
}

const audio = new Audio("core/common/audio/notification.ogg");

export const NotificationCenterIcon = ({anchor}: NotificationCenterIconProps) => {
  const dispatch = useDispatch();
  const {getMessage} = useMessages();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [notificationCenterOpen, setNotificationCenterOpen] = useState(false);
  const markAllAsAnnounced = useCallback((markAsRead: boolean = false) => {
    setSnackbarOpen(false);
    dispatch(markNotificationsAsAnnounced(markAsRead));
  }, [dispatch]);

  const {
    unannouncedNotifications, unannouncedLength, unreadNotifications, notifications,
  } = useNotificationCenter();

  useEffect(() => {
    if (unannouncedLength > 0) {
      audio.play();
      setSnackbarOpen(true);
    }
  }, [unannouncedLength]);

  return (
    <>
      <IconButtonComponent icon={unreadNotifications > 0 ? "unread_alert" : "alert_empty"}
                           padding="large"
                           tooltip={getMessage(MessageKey.CORE.NOTIFICATIONS.NOTIFICATION_CENTER.TITLE)}
                           handleClick={() => setNotificationCenterOpen(true)}
                           BadgeProps={{
                             badgeContent: unreadNotifications,
                             max: 9,
                             color: "primary",
                           }}
      />
      <NotificationCenterProvider
        openNotificationCenter={() => setNotificationCenterOpen(true)}
        closeNotificationCenter={() => setNotificationCenterOpen(false)}
      >
        {anchor.current && (
          <NotificationCenter open={notificationCenterOpen}
                              anchor={anchor.current}
                              notifications={notifications}
          />
        )}
      </NotificationCenterProvider>
      <Snackbar anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
                open={snackbarOpen && unannouncedLength > 0}
                onClose={() => markAllAsAnnounced()}
                message={snackbarOpen ? (unannouncedLength === 1 ? unannouncedNotifications[0].title : getMessage(MessageKey.CORE.NOTIFICATIONS.NEW_NOTIFICATIONS)) : ""}
                action={(
                  <>
                    {unannouncedLength === 1 && (
                      <IconButtonComponent icon="check"
                                           color="inherit"
                                           padding="small"
                                           handleClick={() => markAllAsAnnounced(true)}
                                           tooltip={getMessage(MessageKey.CORE.NOTIFICATIONS.MARK_AS_READ)}
                      />
                    )}
                    <IconButtonComponent icon="visibility"
                                         color="inherit"
                                         padding="small"
                                         handleClick={() => {
                                           markAllAsAnnounced();
                                           setNotificationCenterOpen(true);
                                         }}
                                         tooltip={getMessage(MessageKey.CORE.NOTIFICATIONS.NOTIFICATION_CENTER.OPEN)}
                    />
                  </>
                )}
      />
    </>
  );
};
