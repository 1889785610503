/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ClientFilterSelection, isClientFilterSelection} from "./ClientFilterSelection";

/**
 * ID applied to a new filter which has not been saved yet.
 * Hence, this type of ClientFilterSelection is called TemporaryClientFilterSelection.
 */
const TEMPORARY_ID = "_TEMPORARY_";

/**
 * A client filter selection which was created by the user and is currently being edited.
 * It remains temporary until it is saved. When saving the id of the selection is reset
 * and replaced by an id assigned by the server.
 */
export type TemporaryClientFilterSelection = {
  id: typeof TEMPORARY_ID,
} & ClientFilterSelection;

/**
 * Check if the given value is a TemporaryClientFilterSelection.
 *
 * @param val
 */
export const isTemporaryFilterSelection = (val: any): val is TemporaryClientFilterSelection => {
  return isClientFilterSelection(val) && val.id === TEMPORARY_ID;
};

/**
 * Create a new temporary client filter selection for the given entity type.
 * By default, temporary selections will not impose any restrictions on the
 * result set and the name is empty.
 *
 * @param entityType
 */
export function createTemporaryFilter(entityType: string): ClientFilterSelection {
  return {
    id: TEMPORARY_ID,
    name: "",
    readOnly: false,
    notDeletable: false,
    entityType,
    selectedFilters: [],
  };
}
