/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {setConnectionStatus, useUiState} from "../../store";
import {useService} from "../registry";
import {ConnectionMonitoringService} from "./ConnectionMonitoringService";

/**
 * Hook that installs a listener on the ConnectionMonitoringService
 * in order to update the connection status of the UI. This hook should
 * be called early in the application and only once.
 */
export const useConnectionStateMonitoring = () => {
  const dispatch = useDispatch();
  const service: ConnectionMonitoringService = useService("CONNECTION_MONITORING");
  useEffect(() => {
    if (service && dispatch) {
      const id = service.addListener((connected) => {
        dispatch(setConnectionStatus(connected));
      });
      return () => {
        if (service) {
          service.removeListener(id);
        }
      };
    }
    return () => {};
  }, [service, dispatch]);
};


/**
 * Hook to query the current connection status of the application
 * Make sure the monitoring hook was executed before.
 *
 * @see useConnectionStateMonitoring
 */
export const useConnectionState = (): boolean => {
  const uiStatus = useUiState();
  return uiStatus?.connected ?? false;
};
