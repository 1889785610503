/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi} from "../../api";
import {ActionBarItem, BaseViewModelData, GlobalActions, Perspective, ViewModel} from "../../store";
import {createViewModel} from "../../ui";
import {ParameterUtilities} from "../../util";
import {getAllOpenActions, getViewId, toActionBarItem} from "../ActionBarHelper";
import {PerspectiveMapper, PerspectiveMapperProps} from "./PerspectiveMapper";

/**
 * A pseudo value to open the first view referenced by the
 * first view action accessible by the current user.
 */
export const OPEN_FIRST_AVAILABLE_VIEW = "_FIRST_AVAILABLE_";

export interface AbstractPerspectiveMapper extends PerspectiveMapperProps {}

export abstract class AbstractPerspectiveMapper implements PerspectiveMapper {

  protected constructor(props: PerspectiveMapperProps) {
    Object.assign(this, props);
  }

  protected toViewModel(viewRef: CoreApi.ViewRef): ViewModel<BaseViewModelData> | undefined {
    const viewConfig = this.resolveViewConfig(viewRef);

    if (!viewConfig?.viewType) {
      throw new Error("Invalid view configuration for ViewId " + viewRef.viewId);
    }

    const viewDescriptor = this.availableViewDescriptors[viewConfig.viewType];
    if (!viewDescriptor) {
      throw new Error("Cannot find view descriptor for " + viewConfig.viewType);
    }

    const available = this.securityService.evaluateVisibilityRule(viewConfig.available);
    if (!available) {
      console.warn("No permission for view", viewConfig, "View will not be created.");
      return undefined;
    }

    const parameters = ParameterUtilities.resolveParameterList(viewConfig.viewParameters) ?? {};
    return createViewModel(viewDescriptor, viewConfig.id!,  parameters, viewConfig.label);
  }

  protected toActionBar(actions: CoreApi.Action[] = [], actionBar?: CoreApi.GlobalActions): GlobalActions {
    const mapActionItems = (items?: CoreApi.ActionItem[]): ActionBarItem[] => {
      return items?.map(item => toActionBarItem(actions, item, this.securityService)).filter(item => item.available) ?? [];
    };

    return {
      startItems: mapActionItems(actionBar?.startItems),
      endItems: mapActionItems(actionBar?.endItems),
    };
  }


  protected resolveViewConfig(viewRef: CoreApi.ViewRef): CoreApi.View | undefined {
    if (!this.uiConfiguration.views) {
      return undefined;
    }
    const lookupId = this.getLookupId(viewRef);
    const result = this.uiConfiguration.views.find(view => view.id === lookupId);
    if (result) {
      return {
        ...result,
        id: lookupId,
      };
    }
    return undefined;
  }

  protected getLookupId(viewRef: CoreApi.ViewRef): string | undefined {
    if (viewRef.viewId === OPEN_FIRST_AVAILABLE_VIEW) {
      const openActions = getAllOpenActions(this.uiConfiguration)
        .filter(a => this.securityService.evaluateVisibilityRule(a.visible));

      if (openActions.length > 0) {
        return getViewId(openActions[0]);
      }
      throw new Error("No suitable view found that can be opened.");
    }
    return viewRef.viewId;
  }


  public abstract map(): Perspective | undefined;
}
