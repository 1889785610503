/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ApiUtil, FetchService} from "@icm/core-common";

import {Track} from "../generated/api";

export class TrackApi {
  public static getAllTracks(): Promise<Track[]> {
    return FetchService.performGet("track/tracks")
      .then(json => ApiUtil.convertArray(json, Track.fromData));
  }

  public static getTrack(id: string): Promise<Track> {
    return FetchService.performGet(`track/tracks/${id}`)
      .then(json => Track.fromData(json));
  }

  public static sendTrack(track: Track) {
    return FetchService.performPost("track/tracks", [track]);
  }
}
