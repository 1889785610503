/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

export function availableSvgIconNames(): string[] {
  return require.context("../../public/icons", true, /.svg$/)
    .keys()
    .map((path: string) => path.match(/^\.\/([^.]*)\.svg$/) ?? [])
    .map((matches: RegExpMatchArray) => matches[1] ?? null)
    .filter((svgIconName: string) => svgIconName !== null);
}

export function svgIconExists(name: string): boolean {
  return availableSvgIconNames().includes(name);
}
