/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Paths, Urls} from "../../../constant";
import {StorageStrategy, UserDetails} from "../../../data";
import {
  DynamicAttributeValue,
  LoginConfiguration,
  LoginNotification,
  LoginRequest,
  LoginStatus,
  PasswordChange,
  PasswordChangeResult,
  SecurityConfiguration,
} from "../../../generated/api";
import MessageKey from "../../../generated/MessageKey";
import {Base64} from "../../../util";
import {FetchService, messages, NavigationService} from "../../index";
import RsaEncryptor from "../../RsaEncryptor";
import {AuthenticationService, FailedLoginListener, LoginResult, LogoutStorageCleanup} from "../AuthenticationService";
import {LoginStorageService} from "../LoginStorageService";
import {LocalStartSecurityOptions} from "./types";

/**
 * This is the authentication service for the native/local/in-house ICM user and role management system.  It implements
 * the authentication service and retrieves it essential configuration from the SecurityConfiguration.
 *
 * Do not use this type directly. Instead, use its interface AuthenticationService.
 */
class LocalAuthenticationService implements AuthenticationService {

  private readonly configuration: SecurityConfiguration;
  private currentLogin: LoginRequest | null;
  private currentStatus: LoginStatus | null;
  private currentUser: LoginStatus | null;
  private readonly encryptor: RsaEncryptor;
  private failedLoginListeners: Array<FailedLoginListener>;
  private failedPasswordChangeListeners: Array<(currentPasswordInvalid: boolean, newPasswordInvalid: boolean) => void>;
  private readonly loginStorage: LoginStorageService;
  private loginTargetUrl: string | undefined;
  private readonly navigationService: NavigationService;
  private readonly storageStrategy: StorageStrategy;

  // Do not add the SecurityService here, you are doing something wrong!
  constructor(configuration: SecurityConfiguration,
              loginStorage: LoginStorageService,
              navigationService: NavigationService) {
    this.configuration = configuration;
    this.currentLogin = null;
    this.currentStatus = null;
    this.currentUser = null;
    this.encryptor = new RsaEncryptor();
    this.failedLoginListeners = [];
    this.failedPasswordChangeListeners = [];
    this.loginStorage = loginStorage;
    this.navigationService = navigationService;
    this.storageStrategy = this.createStorageStrategy();
  }

  addFailedLoginListener(failedLoginListener: FailedLoginListener): void {
    this.failedLoginListeners.push(failedLoginListener);
  }

  addFailedPasswordChangeListener(failedPasswordChangeListener: (currentPasswordInvalid: boolean, newPasswordInvalid: boolean) => void): void {
    this.failedPasswordChangeListeners.push(failedPasswordChangeListener);
  }

  authenticateWithCredentials(username: string, plainPassword: string): Promise<LoginResult | undefined> {
    console.log("Retrieved user name and password");
    const request = new LoginRequest();
    request.userName = username;
    request.password = this.encryptor.encrypt(plainPassword);
    return FetchService.performPostWithJsonResult(Urls.LOGIN_REQUEST, request)
      .then(async json => {
        const response = LoginStatus.fromData(json);
        const isValidPassword = response.passwordState === "VALID";
        const isEmptyPassword = response.passwordState === "EMPTY";
        const isExpiredPassword = response.passwordState === "EXPIRED";
        const expiresSoon = isValidPassword && this.isExpiringSoon(response.millisecondsUntilPasswordExpiry);
        if (isEmptyPassword || isExpiredPassword || expiresSoon) {
          this.handleRequiredPasswordChange(request, response, expiresSoon ? "EXPIRES_SOON" : response.passwordState);
        } else if (isValidPassword) {
          return this.handleValidUserNameAndPassword(request, response);
        } else {
          this.handleInvalidUserNameAndPassword(response);
        }
        return undefined;
      },
      _reason => {
        this.handleInvalidUserNameAndPassword({});
        return undefined;
      });
  }

  changePassword(username: string, newPassword: string, currentPassword?: string) {
    console.log("Retrieved password change data");
    const request = new PasswordChange();
    request.userName = username;
    request.newPassword = this.encryptor.encrypt(newPassword);
    request.currentPassword = currentPassword ? this.encryptor.encrypt(currentPassword) : currentPassword;
    FetchService.performPostWithJsonResult(Urls.PASSWORD_CHANGE, request, {catchableErrorCodes: [401]})
      .then(json => {
        const response = PasswordChangeResult.fromData(json);
        if (response.success) {
          this.authenticateWithCredentials(username, newPassword);
        } else {
          const currentPasswordInvalid = response.currentPasswordState === "INVALID";
          const newPasswordInvalid = response.newPasswordState === "INVALID";
          this.failedPasswordChangeListeners.forEach(listener => listener(currentPasswordInvalid, newPasswordInvalid));
        }
      })
      .catch(ex => console.log(ex));
  }

  async checkLoginState(): Promise<LoginResult | undefined> {
    try {
      const loginStatus: LoginStatus | null = await FetchService.performGet(Urls.LOGIN_STATUS, {catchableErrorCodes: [401]});
      await this.setLoginStatus(loginStatus);
      if (loginStatus && loginStatus.passwordState === "VALID") {
        console.log("Already logged in, considering username and password valid.");
        const roleSelectionRequired = await this.isRoleSelectionRequired(loginStatus) || false;
        const userRoles = loginStatus.user?.roles;
        return {
          roles: userRoles || [],
          roleSelectionRequired: roleSelectionRequired,
        };
      } else if (loginStatus && loginStatus.passwordState === "SSO") {
        this.rememberTargetUrl();
        return this.handleValidUserNameAndPassword(null, loginStatus);
      }
    } catch {
      // NOOP
    }

    return undefined;
  }

  getAndClearLoginTargetUrl(): string | undefined {
    if (this.loginTargetUrl) {
      const result = this.loginTargetUrl;
      this.loginTargetUrl = undefined;
      return result;
    } else {
      return this.navigationService.getInitialLocation();
    }
  }

  async getAuthenticationHeaders(): Promise<Record<string, string>> {
    const authorizationHeaderValue = await this.getAuthorizationHeaderValue();
    const headers = {};
    if (authorizationHeaderValue !== null) {
      headers["Authorization"] = authorizationHeaderValue;
    }
    return headers;
  }

  getCurrentUserDetails(): UserDetails | undefined {
    if (this.currentUser) {
      const userName = this.currentUser.user?.userName || undefined;
      const firstName = this.currentUser.user?.person ? this.currentUser.user?.person.firstName : undefined;
      const lastName = this.currentUser.user?.person ? this.currentUser.user?.person.lastName : undefined;
      const roles = this.currentUser.user?.roles || [];
      const attributes = this.getCurrentUserAttributes();
      return {userName: userName, firstName: firstName, lastName: lastName, attributes: attributes, roles: roles};
    } else {
      return undefined;
    }
  }

  handleSkippedPasswordChange(loginRequest: LoginRequest, loginStatus: LoginStatus): Promise<LoginResult | undefined> {
    console.log("Skipping password change", loginStatus);
    const isValidPassword = loginStatus.passwordState === "VALID";
    if (isValidPassword) {
      return this.handleValidUserNameAndPassword(loginRequest, loginStatus);
    } else {
      this.handleInvalidUserNameAndPassword(loginStatus);
      return Promise.resolve(undefined);
    }
  }

  isLoggedIn(): boolean {
    return !!this.currentUser;
  }

  login(parameters?: any): Promise<void> {
    this.loginStorage.clearStorage();
    this.navigationService.navigate(Paths.LOGIN, parameters);
    return Promise.resolve();
  }

  async logout(logoutStorageCleanup?: LogoutStorageCleanup): Promise<void> {
    console.log("Performing logout: ");
    const isSsoUser: boolean = (this.currentStatus && this.currentStatus.passwordState === "SSO") || false;
    console.log(" - SSO", isSsoUser);
    // dont delete login & user for SSO user, as role selection page might not re-request current user
    if (!isSsoUser) {
      this.currentLogin = null;
      this.currentUser = null;
    }
    if (logoutStorageCleanup) {
      await logoutStorageCleanup(isSsoUser);
    }
    this.getLoginPath(isSsoUser).then(path => this.navigationService.navigate(path));
  }

  removeFailedLoginListener(failedLoginListener: FailedLoginListener): void {
    this.failedLoginListeners = this.failedLoginListeners.filter(listener => listener !== failedLoginListener);
  }

  removeFailedPasswordChangeListener(failedPasswordChangeListener: (currentPasswordInvalid: boolean, newPasswordInvalid: boolean) => void) {
    this.failedPasswordChangeListeners = this.failedPasswordChangeListeners.filter(listener => listener !== failedPasswordChangeListener);
  }

  async startSecurity(_options: LocalStartSecurityOptions): Promise<LoginResult | undefined> {
    this.currentLogin = await this.loginStorage.readCurrentLogin();
    this.currentUser = await this.loginStorage.readCurrentUser();
    if (this.currentLogin !== null) {
      let loginResult;
      if (this.currentUser !== null) {
        loginResult = this.handleValidUserNameAndPassword(this.currentLogin, this.currentUser);
      }
      return loginResult;
    }
    return Promise.resolve(undefined);
  }

  private createStorageStrategy(): StorageStrategy {
    const storageType = this.getLoginConfiguration().rememberMeStorage;
    return new StorageStrategy(storageType ?? "COOKIE");
  }

  private clearTargetUrl() {
    this.loginTargetUrl = undefined;
  }

  private async getAuthorizationHeaderValue(): Promise<string | null> {
    const userNameAndPassword = await this.getUserNamePasswordString();
    if (userNameAndPassword != null) {
      return `Basic ${Base64.encode(userNameAndPassword)}`;
    } else {
      return null;
    }
  }

  private getCurrentUserAttributes(): Record<string, any> {
    // prefer current status as it is more recent; also it contains more attribute such as role-dependent ones - for example: JID
    if (this.currentStatus?.user?.dynamicAttributes) {
      return this.toRecord(this.currentStatus.user.dynamicAttributes);
    } else if (this.currentUser?.user?.dynamicAttributes) {
      return this.toRecord(this.currentUser.user.dynamicAttributes);
    } else {
      return {};
    }
  }

  private getDaysBeforePasswordExpiryTriggeringWarning(): number | undefined {
    return this.getLoginConfiguration().daysBeforePasswordExpiryTriggeringWarning;
  }

  private getLoginConfiguration(): LoginConfiguration {
    return this.configuration.supportedAuthentications?.loginConfiguration || new LoginConfiguration();
  }

  private static getLoginErrorMessage(response: LoginStatus): string {
    const fallbackErrorText: string = messages.get(MessageKey.CORE.ERROR.GENERAL) || "ERROR";
    let errorText: string | undefined;
    switch (response.passwordState) {
      case "BAD":
        errorText = messages.get(MessageKey.CORE.LOGIN.ERROR.BAD_CREDENTIALS);
        break;
      case "EMPTY":
        errorText = messages.get(MessageKey.CORE.LOGIN.ERROR.EMPTY_PASSWORD);
        break;
      case "EXPIRED":
        errorText = messages.get(MessageKey.CORE.LOGIN.ERROR.EXPIRED_PASSWORD);
        break;
      default:
        errorText = fallbackErrorText;
        break;
    }
    return errorText || fallbackErrorText;
  }

  private async getLoginPath(isSsoUser: boolean): Promise<string> {
    if (isSsoUser) {
      return await this.isRoleSelectionRequired(this.currentStatus) ? Paths.ROLE_SELECTION : Paths.MAIN;
    } else {
      return Paths.LOGIN;
    }
  }

  private async getUserNamePasswordString(): Promise<string | null> {
    const login = await this.loginStorage.readCurrentLogin();
    if (login && login.password) { // make sure that password is present: if not -> SSO -> do not return user name password string
      return `${login.userName}:${login.password}`;
    } else {
      return null;
    }
  }

  private handleInvalidUserNameAndPassword(response: LoginStatus): void {
    console.log("User name and password are not valid", response);
    this.currentLogin = null;
    this.currentUser = null;
    this.loginStorage.clearStorage();
    const errorMessage = LocalAuthenticationService.getLoginErrorMessage(response);
    this.failedLoginListeners.forEach(listener => listener(errorMessage));
  }

  private handleRequiredPasswordChange(request: LoginRequest, response: LoginStatus, state: string | undefined) {
    console.log("Password change required:", state);
    this.navigationService.navigate(Paths.PASSWORD_CHANGE, {
      passwordState: state,
      userName: request.userName,
      originalRequest: request,
      originalResponse: response,
    });
  }

  private async handleValidUserNameAndPassword(loginRequest: LoginRequest | null, loginStatus: LoginStatus): Promise<LoginResult> {
    const roleSelectionRequired = await this.isRoleSelectionRequired(loginStatus) || false;
    const userRoles = loginStatus.user?.roles;
    console.log("User name and password are valid, role required:", roleSelectionRequired, "roles:", userRoles, "configuration:", this.configuration);
    // make sure we store the user we authenticated as.
    // for example, if the user logged in as "HanS PeTer" and the server checked it against "Hans Peter", then our username is "Hans Peter"
    this.currentLogin = {
      ...loginRequest,
      userName: loginStatus.user?.userName,
    };
    this.currentUser = loginStatus;
    this.loginStorage.writeCurrentLogin(this.currentLogin);
    this.loginStorage.writeCurrentUser(loginStatus);
    this.loginStorage.writeNotificationStatusOnce()
      .then(sendNotification => {
        if (sendNotification) {
          const notification: LoginNotification = {
            userName: loginStatus.user?.userName,
            sso: loginStatus.passwordState === "SSO",
          };
          FetchService.performPost("core/loginNotification", notification);
        }
      });
    return {
      roles: userRoles || [],
      roleSelectionRequired,
    };
  }

  private isExpiringSoon(millisecondsUntilPasswordExpiry: number | undefined | null): boolean {
    const daysBeforePasswordExpiryTriggeringWarning = this.getDaysBeforePasswordExpiryTriggeringWarning();
    if (millisecondsUntilPasswordExpiry !== undefined && millisecondsUntilPasswordExpiry !== null && daysBeforePasswordExpiryTriggeringWarning !== undefined) {
      const daysUntilPasswordExpiry = millisecondsUntilPasswordExpiry / (24 * 60 * 60 * 1000);
      const result = daysUntilPasswordExpiry < daysBeforePasswordExpiryTriggeringWarning;
      console.log("Checking password expiry for parameters", daysUntilPasswordExpiry, daysBeforePasswordExpiryTriggeringWarning, result);
      return result;
    } else {
      return false;
    }
  }

  private async isRoleSelectionRequired(loginStatus: LoginStatus | null): Promise<boolean | undefined> {
    const activeRoles = await this.loginStorage.readActiveRoles();
    if (loginStatus && loginStatus.passwordState === "SSO" && activeRoles && activeRoles.length > 0) {
      return false;
    } else {
      return this.getLoginConfiguration().roleSelectionRequired;
    }
  }


  private rememberTargetUrl(): string | undefined {
    const url = this.navigationService.getUrl();
    if (url !== "/" && url !== Paths.LOGIN && url !== Paths.PASSWORD_CHANGE && url !== Paths.ROLE_SELECTION) {
      this.loginTargetUrl = url;
    } else {
      this.loginTargetUrl = "/";
    }
    return this.loginTargetUrl;
  }

  private async setLoginStatus(status: LoginStatus | null): Promise<void> {
    this.currentStatus = status;
  }

  private toRecord(dynamicAttributes: Record<string, DynamicAttributeValue>): Record<string, any> {
    const result = {};
    Object.entries(dynamicAttributes).forEach(([key, value]) => {
      result[key] = value.value;
    });
    return result;
  }
}

export {LocalAuthenticationService};
