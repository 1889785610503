/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  AutoCompleteComponent,
  EntitySource,
  ExpressionEvaluationService,
  FetchService,
  getListOrSublist,
  ListPortion,
  ObjectLinkingParameter,
  SortOrder,
  SortService,
  useService,
} from "@icm/core-common";
import {Entity, EntityApi, useEntityConfiguration} from "@icm/entity-common";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";


export type EntitySelectProps = {
  objectLinking: ObjectLinkingParameter
  required: boolean,
  value: Entity[],
  onChange: (entities: Entity[]) => void,
}

// this type guard has to be adapted as soon as a second type of Source is introduced
export function isEntitySource(source: any): source is EntitySource {
  return source.entityType !== null;
}

export const EntitySelect = (props: EntitySelectProps) => {
  const securityService = useService("SECURITY");

  const entitySource = props.objectLinking.source!;
  const entityType = securityService.evaluateExpression(entitySource.entityType!);
  const initialOptionsUrl = entitySource.initialOptionsUrl ? securityService.evaluateExpression(entitySource.initialOptionsUrl) : null;
  const optionsBySearchUrl = entitySource.optionsBySearchUrl ? securityService.evaluateExpression(entitySource.optionsBySearchUrl) : null;
  const optionsSortOrder: SortOrder | undefined = entitySource.optionsSortOrder;

  const entityConfiguration = useEntityConfiguration(entityType);

  const valueDisplay = entitySource.valueDisplay ?? entityConfiguration?.valueDisplay;
  const typeDisplay = entitySource.typeDisplay ?? entityConfiguration?.typeDisplay ?? entityType;

  const possibleValues = useQuery<Entity[]>(["private", "chat", "entities", entityType], () => {
    if (initialOptionsUrl) {
      return FetchService.performGet<Entity[] | ListPortion<Entity>>(initialOptionsUrl).then(getListOrSublist);
    } else {
      return EntityApi.getEntities(entityType).then(entityList => entityList.sublist ?? []);
    }
  });

  const [entities, setEntities] = useSelectedEntities(props.value, possibleValues.data);


  const handleChange = (values: Entity[]) => {
    setEntities(values);
    props.onChange(values);
  };

  const retrieveAdditionalOptions = async (search: string): Promise<Entity[]> => {
    console.log("Retrieve additional options for", search);
    if (search.length >= 3 && optionsBySearchUrl) {
      const url = ExpressionEvaluationService.evaluate(optionsBySearchUrl, {search: search});
      return FetchService.performGet<Entity[] | ListPortion<Entity>>(url).then(getListOrSublist);
    } else {
      return [];
    }
  };

  const sortOptions = (options: Entity[]): void => {
    if (valueDisplay) {
      options.sort(SortService.createComparator<Entity, string>(
        e => ExpressionEvaluationService.evaluate(valueDisplay, e),
        (a, b) => a.localeCompare(b),
        optionsSortOrder === "DESC"
      ));
    }
  };

  return (
    <AutoCompleteComponent value={entities}
                           label={typeDisplay}
                           multiple={true}
                           onChange={handleChange}
                           providedOptions={possibleValues.data ?? []}
                           additionalOptionsProvider={{provide: retrieveAdditionalOptions, sort: sortOptions}}
                           debounceLoadPossibleValues={true}
                           valueDisplay={valueDisplay}
                           valueBinding="_"
                           hideClearButton={true}
                           loading={possibleValues.isLoading}
                           required={props.required}
    />
  );
};

/**
 * Return the selected entities as soon as the possible values result was loaded.
 * This hook ensures entities being returned have recent display values the possible values.
 *
 * @param initiallySelectedValues values to be selected from the possible values (second parameter).
 * Entities in this array might be incomplete (i.e. have no display value). They must
 * have an ID though. Entities with IDs not in possible values will NOT be returned.
 * @param possibleValues Complete entities to select from (including ID and displayValue).
 */
const useSelectedEntities = (initiallySelectedValues: Entity[],
                             possibleValues: Entity[] | undefined): [Entity[],  React.Dispatch<React.SetStateAction<Entity[]>>] => {
  const [entities, setEntities] = useState<Entity[]>([]);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized && possibleValues) {
      const ids = initiallySelectedValues.map(e => e.id);
      const selectedValues = possibleValues.filter(v => ids.some(id => id === v.id));
      setInitialized(true);
      setEntities(selectedValues);
    }
  }, [possibleValues, initiallySelectedValues, initialized, setInitialized]);
  return [entities, setEntities];
};
