/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FC, useCallback, useState} from "react";

import {
  AlertDialogProps,
  AlertDialogProvider,
  ConfirmDialogProps,
  ConfirmDialogProvider,
  ImageViewerDialogProps,
  ImageViewerDialogProvider,
  MessageKey,
  PromptDialogProps,
  PromptDialogProvider,
  useDialogContext,
  useMessages,
} from "../../../index";

export const useCommonPromptDialogState = (confirmLabel: string) => {
  const [value, setValue] = useState<string>();
  const {getMessage} = useMessages();

  let confirm;
  switch (confirmLabel) {
    case "ADD":
      confirm = getMessage(MessageKey.CORE.FORM.ADD);
      break;
    case "OK":
      confirm = getMessage(MessageKey.CORE.OK);
      break;
    default:
      confirm = confirmLabel || getMessage(MessageKey.CORE.OK);
  }

  return {
    value, setValue, confirm,
  };
};

export const useCommonPromptDialog = (Component: FC<PromptDialogProps>): PromptDialogProvider => {
  const {setDialog} = useDialogContext();
  return useCallback((promptProps) => {
    return new Promise<string | undefined>((resolve) => {
      setDialog(<Component {...promptProps}
                           onConfirm={(value) => {
                             resolve(value);
                             setDialog(null);
                           }}
                           onCancel={() => {
                             resolve(undefined);
                             setDialog(null);
                           }}
      />);
    });
  }, [setDialog, Component]);
};

export const useCommonConfirmDialog = (Component: FC<ConfirmDialogProps>): ConfirmDialogProvider => {
  const {setDialog} = useDialogContext();
  return useCallback((confirmProps) => {
    return new Promise<boolean>((resolve) => {
      setDialog(<Component {...confirmProps}
                           onConfirm={() => {
                             resolve(true);
                             setDialog(null);
                           }}
                           onCancel={() => {
                             resolve(false);
                             setDialog(null);
                           }}
      />);
    });
  }, [setDialog, Component]);
};

export const useCommonAlertDialog = (Component: FC<AlertDialogProps>): AlertDialogProvider => {
  const {setDialog} = useDialogContext();
  return useCallback((alertProps) => {
    return new Promise((resolve) => {
      setDialog(<Component {...alertProps}
                           onClose={() => {
                             resolve();
                             setDialog(null);
                           }}
      />);
    });
  }, [setDialog, Component]);
};


export const useCommonImageViewerDialog = (Component: FC<ImageViewerDialogProps>): ImageViewerDialogProvider => {
  const {setDialog} = useDialogContext();
  return useCallback((promptProps) => {
    return new Promise<void>((resolve) => {
      setDialog(<Component {...promptProps}
                           onClose={() => {
                             resolve();
                             setDialog(null);
                           }}
      />);
    });
  }, [setDialog, Component]);
};
