/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/


import {changeSingleView, closeTab} from "@icm/core-common";
import {createSlice} from "@reduxjs/toolkit";

import {
  AddWeakEntity,
  DisposeEntityEditModel,
  InitEntityEditModel,
  ReloadEntityEditModel,
  SubmitEntityEditModel,
  UpdateEntityEditModel,
} from "./actions";
import {EntityEditState} from "./types";


export const initialEntityEditModelState: EntityEditState = {
  editModels: {},
};

/**
 * Update the processing state of the EntityEditModel related to the given entityId.
 */
function setProcessing(state: EntityEditState, entityId: string, processing: boolean) {
  const model = state.editModels[entityId];
  if (model) {
    model.processing = processing;
  }
}
export const {reducer,  actions} = createSlice({
  name: "@@entity",
  initialState: initialEntityEditModelState,
  reducers: {
    initEntityEditModel: InitEntityEditModel,
    updateEntityEditModel: UpdateEntityEditModel,
    disposeEntityEditModel: DisposeEntityEditModel,
    addWeakEntity: AddWeakEntity,
  },
  extraReducers: builder => builder
    .addCase(closeTab, DisposeEntityEditModel)
    .addCase(changeSingleView, DisposeEntityEditModel)
    .addCase(ReloadEntityEditModel.fulfilled, InitEntityEditModel)
    .addCase(ReloadEntityEditModel.pending, (state, action) => {
      setProcessing(state, action.meta.arg.entityId, true);
    })
    .addCase(ReloadEntityEditModel.rejected, (state, action) => {
      setProcessing(state, action.meta.arg.entityId, false);
    })
    .addCase(SubmitEntityEditModel.pending, (state, action) => {
      setProcessing(state, action.meta.arg.entityId, true);
    })
    .addCase(SubmitEntityEditModel.fulfilled, (state, action) => {
      setProcessing(state, action.meta.arg.entityId, false);
    })
    .addCase(SubmitEntityEditModel.rejected, (state, action) => {
      setProcessing(state, action.meta.arg.entityId, false);
    }),
});


export const {
  initEntityEditModel,
  updateEntityEditModel,
  addWeakEntity,
} = actions;
