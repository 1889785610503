/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

export class Paths {
  public static readonly ANY = "*";
  public static readonly MAIN = "/";
  public static readonly LOGIN = "/login";
  public static readonly ROLE_SELECTION = "/roleSelection";
  public static readonly PASSWORD_CHANGE = "/passwordChange";
  public static readonly RECONFIGURATION = "/reconfiguration";
}

export const KnownPaths = [
  Paths.ANY,
  Paths.MAIN,
  Paths.LOGIN,
  Paths.ROLE_SELECTION,
  Paths.PASSWORD_CHANGE,
  Paths.RECONFIGURATION,
];
