/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useQuery} from "react-query";

import {UiConfigurationApi} from "../api";
import {PropertyService} from "../service";

const FALLBACK = {};

export const usePublicProperties = () => {
  return useQuery(PropertyService.PUBLIC_PROPERTIES_QUERY_KEY, UiConfigurationApi.getPublicProperties, {
    cacheTime: Infinity,
    staleTime: Infinity,
  }).data ?? FALLBACK;
};
