/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {RegisterInStore} from "@icm/core-common";

import * as RecurringStandbyActions from "./recurringStandbys/actions";
import {recurringStandbyFlows} from "./recurringStandbys/epics";
import {recurringStandbyReducer} from "./recurringStandbys/reducer";
import {IRecurringStandbyState} from "./recurringStandbys/types";
import * as StandbyActions from "./standbys/actions";
import {standbyFlows} from "./standbys/epics";
import {standbyReducer} from "./standbys/reducer";
import {IStandbyState} from "./standbys/types";

export {
  RecurringStandbyActions, StandbyActions,
};

export const register = (registerInStore: RegisterInStore) => {
  registerInStore("standbyState", standbyReducer, standbyFlows);
  registerInStore("recurringStandbyState", recurringStandbyReducer, recurringStandbyFlows);
};

export type IStandbyApplicationState = {
  recurringStandbyState: IRecurringStandbyState
  standbyState: IStandbyState
}
