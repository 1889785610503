/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ObjectWithUpdateInformation} from "@icm/activitystream-common";
import {createCancelable, ListFilter} from "@icm/core-common";
import {InfiniteData, QueryClient, QueryKey} from "react-query";

import {EntityApi} from "../api";
import {EntityListElementView} from "../hooks";
import {EntityListElementViewFactory} from "../hooks/useListElementViewFactory";


/**
 * Default initial page size, if no pageSize attribute is
 * given in the list config.
 **/
export const DEFAULT_PAGE_SIZE = 10;

export type EntityPage = {
  entities: ObjectWithUpdateInformation<EntityListElementView>[]
  lastUpdateConsidered: Record<string, Date>
  moreEntities: boolean
  overallEntityCount: number | undefined
  pageNumber: number
}

export const PagedEntityLoader = {
  loadEntities,
};

export type LoadingProps = {
  pageParam: number,
  pageSize: number | undefined,
  viewFactory: EntityListElementViewFactory,
  listFilter: ListFilter | undefined,
  entityType: string,
  queryClient: QueryClient,
  queryKey: QueryKey
}


function loadEntities(props: LoadingProps): Promise<EntityPage> {
  const {
    pageParam,
    pageSize = DEFAULT_PAGE_SIZE,
    viewFactory,
    listFilter,
    entityType,
    queryClient,
    queryKey,
  } = props;
  const offset = pageParam * pageSize;

  console.debug("Loading page:", pageParam, " offset ", offset, " queryKey:", queryKey);

  const usedListFilter = listFilterWith(offset, pageSize, listFilter);
  return createCancelable(ac => EntityApi.getEntities(entityType, usedListFilter, ac))
    .then(entityListPortion => {
      const existing = pageParam !== 0 && queryClient.getQueryData<InfiniteData<EntityPage>>(queryKey)
        ?.pages
        .flatMap(e => e.entities)
        .map(e => e.id);


      const newEntities: ObjectWithUpdateInformation<EntityListElementView>[] = (entityListPortion.sublist ?? [])
        .filter(e => e)
        .map((e): ObjectWithUpdateInformation<EntityListElementView> => ({
          id: e.id!,
          object: viewFactory(e),
          listState: "INITIAL",
        }))
        .filter(e => !existing || !existing.includes(e.id));

      const overallEntityCount = entityListPortion.listSize ?? 0;
      return {
        entities: newEntities,
        lastUpdateConsidered: {},
        moreEntities: overallEntityCount > offset,
        overallEntityCount,
        pageNumber: pageParam,
      };
    });
}


const listFilterWith = (offset: number, limit: number, listFilter?: ListFilter): ListFilter | undefined => {
  return {
    ...listFilter,
    offset,
    limit,
  };
};
