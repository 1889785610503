/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import Color from "color";

/**
 * @param color color string
 * @return true if valid, false otherwise
 */
export function isValidColor(color: string): boolean {
  try {
    new Color(color);
    return true;
  } catch {
    return false;
  }
}

/**
 * Calls the given manipulate function with the given ratio
 * @param color the color to manipulate
 * @param func the manipulate function
 * @param ratio function depending on selected manipulate function
 * @see lighten
 * @see darken
 * @see fade
 * @see opaquer
 */
export function manipulate(color: string, func: "lighten" | "darken" | "fade" | "opaquer", ratio: number): string {
  switch (func) {
    case "lighten":
      return lighten(color, ratio);
    case "darken":
      return darken(color, ratio);
    case "fade":
      return fade(color, ratio);
    case "opaquer":
      return opaquer(color, ratio);
  }
  throw Error("Invalid manipulate function " + func);
}

/**
 * Lightens the color by {ratio} percent
 * @param color color string
 * @param ratio ratio to lighten the color by
 */
export function lighten(color: string, ratio: number): string {
  return new Color(color).lighten(ratio).toString();
}

/**
 * Darkens the color by {ratio} percent
 * @param color color string
 * @param ratio ratio to darken the color by
 */
export function darken(color: string, ratio: number): string {
  return new Color(color).darken(ratio).toString();
}

/**
 * Fades the color by {ratio} percent (reducing alpha value)
 * @param color color string
 * @param ratio ratio to fade the color by
 */
export function fade(color: string, ratio: number): string {
  return new Color(color).fade(ratio).toString();
}
/**
 * Opaques the color by {ratio} percent (increasing alpha value)
 * @param color color string
 * @param ratio ratio to opaque the color by
 */
export function opaquer(color: string, ratio: number): string {
  return new Color(color).opaquer(ratio).toString();
}

/**
 * Replaces the color's alpha value with the given one.
 * @param color color string
 * @param alpha new alpha value
 */
export function withAlpha(color: string, alpha: number): string {
  return new Color(color).alpha(alpha).toString();
}

/**
 * @param color color string
 * @return true if the color is dark, false if it is not (then it is light)
 */
export function isDark(color: string): boolean {
  return new Color(color).isDark();
}

/**
 * @param color color string
 * @return given color as hex string
 */
export function toHex(color: string): string {
  return new Color(color).hex();
}

/**
 * @param color color string
 * @return given color as:
 *
 * - rgb() if no alpha value
 * - rgba() if the color has an alpha other than 1
 */
export function toRgba(color: string): string {
  const rgb = new Color(color).rgb().array()
    // round rgb to 0 digits, alpha to 2 digits
    .map((n, idx) => Number(n.toFixed(idx < 3 ? 0 : 2)));
  return rgb.length === 4 ? `rgba(${rgb.join(",")})` : `rgb(${rgb.join(",")})`;
}
