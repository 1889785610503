/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  Change,
  CollectionPropertyUpdate, CommunicationChange,
  ObjectChange, OtherChange, OtherPropertyUpdate, PropertyUpdate,
  ValuePropertyUpdate,
} from "../generated/api";

export function isObjectChange(change: Change): change is ObjectChange {
  return change.changeType === "OBJECT";
}

export function isCommunicationChange(change: Change): change is CommunicationChange {
  return change.changeType === "COMMUNICATION";
}

export function isOtherChange(change: Change): change is OtherChange {
  return change.changeType === "OTHER";
}

export function isValuePropertyUpdate(propertyUpdate: PropertyUpdate): propertyUpdate is ValuePropertyUpdate {
  return propertyUpdate.updateType === "VALUE";
}

export function isCollectionPropertyUpdate(propertyUpdate: PropertyUpdate): propertyUpdate is CollectionPropertyUpdate {
  return propertyUpdate.updateType === "COLLECTION";
}

export function isOtherUpdate(propertyUpdate: PropertyUpdate): propertyUpdate is OtherPropertyUpdate {
  return propertyUpdate.updateType === "OTHER";
}
