/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CallType} from "./CallType";

export type CallButtonProps = {
  calledNumber: any,
  format: string,
  relatedObject: object
}


export const useCallButton = (format: string) => {
  const configParts = format.split(":");
  if (configParts.length < 2 || configParts.length > 5) {
    // format string not correct
    return null;
  }
  const callType = configParts[1] as CallType;
  const backendUrl: URL | null = (CallType.MCS_CALL_API === callType || CallType.MAR_CALL_API === callType) && configParts[2]
    ? new URL(decodeURIComponent(configParts[2])) : null;
  if (CallType.MCS_CALL_API === callType && backendUrl) {
    backendUrl.username = configParts[3];
    backendUrl.password = configParts[4];
  }
  return {
    callType, backendUrl,
  };
};

export const openTelProtocolUrl = (calledNumber: string) => {
  window.open(`tel:${calledNumber}`, "_self");
};

export const sendMarAPIRequest = (calledNumber: string, backendUrl: URL) => {
  const xhr = new XMLHttpRequest();
  const method = "POST";
  const params = `called-number=${calledNumber}`;
  if ("withCredentials" in xhr) {
    xhr.open(method, backendUrl, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = () => { // Call a function when the state changes.
      console.log("MAR CALL API Response", xhr.responseText);
    };
    xhr.send(params);
  } else {
    console.warn("CORS not supported");
  }
};

export const sendMCSApiRequest = (calledNumber: string, backendUrl: URL, callerUserId: string) => {
  const credentials = btoa(`${backendUrl.username}:${backendUrl.password}`);
  const urlString = `${backendUrl.protocol}//${backendUrl.hostname}:${backendUrl.port}${backendUrl.pathname}`;
  const callerUserWithoutIcmDomainPrefix = callerUserId.replace("transport_", "");
  // eslint-disable-next-line no-useless-escape
  const requestBody = `{\"properties\":{},\"routing_key\":\"event.callSetupExternallyTriggered.1.1.userId.${callerUserWithoutIcmDomainPrefix}\","payload":"{\\"messageType\\":\\"com.frequentis.pt.callHandling.event.callSetupExternallyTriggered\\",\\"payload\\":{\\"calledNumber\\":\\"${calledNumber}\\"}}","payload_encoding":"string"}`;
  const headers = new Headers({
    Authorization: `Basic ${credentials}`,
    ContentType: "application/json",
  });
  const req = {
    method: "POST",
    headers: headers,
    body: requestBody,
  };
  fetch(urlString, req).then((result) => {
    console.log(result.text());
  }).catch((error) => {
    console.error("MCS API Request Error:", error);
  });
};
