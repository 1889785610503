/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/**
 * partial list of objects
 */
export type ListPortion<T> = {
  sublist: T[]
  listSize: number
  offset: number
}

export function getListOrSublist<T>(result: T[] | ListPortion<T>) {
  return Array.isArray(result) ? result : result.sublist;
}
