/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {GeneralError} from "@icm/core-common";
import {createAction, createAsyncAction} from "typesafe-actions";

import {Person, RecurringStandby, StandbyTeam} from "../../generated/api";
import {RecurringStandbyActionTypes} from "./types";

export const fetchStandbyTeams = createAsyncAction(
  RecurringStandbyActionTypes.FETCH_STANDBY_TEAMS_REQUEST,
  RecurringStandbyActionTypes.FETCH_STANDBY_TEAMS_SUCCESS,
  RecurringStandbyActionTypes.FETCH_STANDBY_TEAMS_ERROR
)<void, StandbyTeam[], Error>();

export const fetchStandbyTeamMembers = createAsyncAction(
  RecurringStandbyActionTypes.FETCH_STANDBY_TEAM_MEMBERS_REQUEST,
  RecurringStandbyActionTypes.FETCH_STANDBY_TEAM_MEMBERS_SUCCESS,
  RecurringStandbyActionTypes.FETCH_STANDBY_TEAM_MEMBERS_ERROR
)<StandbyTeam, Person[], Error>();

export const fetchRecurringStandbys = createAsyncAction(
  RecurringStandbyActionTypes.FETCH_RECURRING_STANDBY_REQUEST,
  RecurringStandbyActionTypes.FETCH_RECURRING_STANDBY_SUCCESS,
  RecurringStandbyActionTypes.FETCH_RECURRING_STANDBY_ERROR
)<{standbyTeam: StandbyTeam, filterFrom: Date, filterTo: Date}, RecurringStandby[], Error>();

export const submitRecurringStandby = createAsyncAction(
  RecurringStandbyActionTypes.SUBMIT_RECURRING_STANDBY_REQUEST,
  RecurringStandbyActionTypes.SUBMIT_RECURRING_STANDBY_SUCCESS,
  RecurringStandbyActionTypes.SUBMIT_RECURRING_STANDBY_ERROR
)<RecurringStandby, string, GeneralError>();

export const deleteRecurringStandby = createAsyncAction(
  RecurringStandbyActionTypes.DELETE_RECURRING_STANDBY_REQUEST,
  RecurringStandbyActionTypes.DELETE_RECURRING_STANDBY_SUCCESS,
  RecurringStandbyActionTypes.DELETE_RECURRING_STANDBY_ERROR
)<string, boolean, Error>();

export const deleteError = createAction(RecurringStandbyActionTypes.DELETE_ERROR)();
