/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React from "react";

import {ResolvedParameterList} from "../util";

/** A named component is any React element */
export type NamedComponent<T extends object> = React.FC<T> | React.ComponentClass<T>;

/** Callbacks are all available callbacks from which a component might choose */
export type NamedComponentCallbacks = {
  [name: string]: Function;
}

/**
 * The descriptor for a named component.
 */
export type NamedComponentDescriptor<T extends object> = {
  /** the name of the component */
  name: string;
  /** the component (factory) itself */
  component: NamedComponent<T>;
  /**
   * The initializer for the component's properties.
   *
   * @param parameters the (resolved) parameters
   * @param callbacks the available callbacks (provided e.g. by action buttons)
   * @returns properties appropriate for the component
   */
  initializeProps?: (parameters: ResolvedParameterList, callbacks?: NamedComponentCallbacks) => T;
};

type NamedComponentDescriptors = Partial<Readonly<Record<string, NamedComponentDescriptor<any>>>>;

const namedComponentDescriptors: NamedComponentDescriptors = {};

/**
 * Registers a named component.
 *
 * @param descriptorOrName the descriptor (or name - then at least componentFactory has to be given, too)
 * @param componentFactory the component factory (only if first parameter is a string, required)
 * @param initializeProps the initializer for the properties (only if first parameter is a string, optional)
 */
export function registerNamedComponentFactory<T extends object>(
  descriptorOrName: NamedComponentDescriptor<T> | string,
  componentFactory?: NamedComponent<T>,
  initializeProps?: NamedComponentDescriptor<T>["initializeProps"],
) {
  const descriptor = typeof descriptorOrName === "string" ? {
    name: descriptorOrName,
    component: componentFactory!,
    initializeProps: initializeProps,
  } : descriptorOrName;
  if (process.env.NODE_ENV === "development" && namedComponentDescriptors[descriptor.name]) {
    console.error(`Duplicate registration of ${descriptor.name}.`);
  }
  namedComponentDescriptors[descriptor.name] = descriptor;
}

/**
 * Get the named component's descriptor.
 *
 * @param name the name of the component
 * @return the descriptor, if the component exists
 */
export function getNamedComponentDescriptor(name: string) {
  const descriptor = namedComponentDescriptors[name];
  if (process.env.NODE_ENV === "development" && !descriptor) {
    console.warn(`Could not find component of name ${name}, make sure you registered the component using registerNamedComponentFactory.`);
  }
  return descriptor;
}

/**
 * Get the named component's factory.
 *
 * @param name the name of the component
 * @return the component factory, if the component exists
 */
export function getNamedComponentFactory(name: string): NamedComponent<any> | undefined {
  const descriptor = getNamedComponentDescriptor(name);
  return descriptor?.component;
}

/**
 * Initialize the named component's properties based on parameters.
 * The "ref" which is optionally returned in the properties, can be used to connect other components like action buttons.
 *
 * @param name the component name
 * @param parameters the parameters
 * @param callbacks the available callbacks, e.g. onFormValidationChange
 * @return properties appropriate for the component
 */
export function getNamedComponentProps(name: string, parameters: ResolvedParameterList, callbacks?: NamedComponentCallbacks):
  object & React.RefAttributes<any> {
  const descriptor = getNamedComponentDescriptor(name);
  return descriptor?.initializeProps?.(parameters, callbacks) ?? {};
}

/**
 * Create the named component based on parameters.
 *
 * @param name the component name
 * @param parameters the parameters
 * @param callbacks the available callbacks, e.g. onFormValidationChange
 * @return a JSX element
 */
export function getNamedComponent(name: string, parameters: ResolvedParameterList, callbacks?: NamedComponentCallbacks): JSX.Element {
  const descriptor = getNamedComponentDescriptor(name);
  if (descriptor && descriptor.initializeProps) {
    const Component = descriptor.component;
    const props = descriptor.initializeProps(parameters, callbacks);
    return <Component {...props} />;
  } else {
    return <></>;
  }
}
