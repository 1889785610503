/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback} from "react";
import {useDispatch} from "react-redux";

import {openSideBar} from "../../store";
import {ParameterUtilities} from "../../util";
import {useOpenView} from "../ViewHooks";
import {ActionRunner} from "./ActionRegistry";

/**
 * Handler to open an entity in the same or a new tab.
 */
export const useOpenViewActionHandler = (): ActionRunner => {
  const openView = useOpenView();
  const dispatch = useDispatch();

  return useCallback((parameters) => {
    // TODO PICM-1501 remove all occurrences of openInNewTab
    const openInNewTab = ParameterUtilities.getResolvedParameter("openInNewTab", parameters);
    if (openInNewTab) {
      console.warn("openInNewTab (browser tab) not implemented. Opening regular tab within icm");
    }

    const viewId = ParameterUtilities.getResolvedParameter("viewId", parameters);
    const sideBarId = ParameterUtilities.getResolvedParameter("sideBarId", parameters);

    if (viewId) {
      const viewParameters = {
        genericParameters: parameters,
      };
      openView(viewId, viewParameters);
      // TODO PICM-1501 do something different on mobile
    } else if (sideBarId) {
      dispatch(openSideBar({sideBarId: sideBarId}));
    } else {
      console.error("Can't open View nor SideBar. Neither viewId nor sideBarId given. Make sure parameters were provided in correct spelling.", parameters);
    }
  }, [openView, dispatch]);
};
