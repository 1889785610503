/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CoreApi,
  AbstractFormConfiguration,
  AbstractFormField,
  AbstractFormFieldListColumn,
  AbstractFormFieldListConfiguration,
  AbstractFormGroup,
  createListComponentEmptyValue,
  EntityFormConfiguration,
  EntityFormField,
  EntityFormFieldListColumn,
  EntityFormFieldListConfiguration,
  EntityFormGroup,
  FileReference,
  GenericFormConfiguration,
  GenericFormFieldListColumn,
  GenericFormFieldListConfiguration,
  GenericFormFieldWithFormConverter,
  GenericFormGroup,
  ListComponentValueAndChanges,
  SafeObjectPropertyUpdate,
  produceListComponentEntity, GenericFormConfigurationAndVariantName,
} from "@icm/core-common";

import {AttributeConfiguration, EntityConfiguration} from "../generated/api";
import {createEntityModelInitOptions, entityDataService} from "../service";


function convertToGenericSimpleListItem(entityType: string, column: AbstractFormFieldListColumn,
                                        configuration: EntityConfiguration): GenericFormFieldListColumn {
  if (column.formFieldListColumnType === "GENERIC") {
    return (column as GenericFormFieldListColumn);
  } else if (column.formFieldListColumnType === "ENTITY") {
    const entityColumn = column as EntityFormFieldListColumn;
    const attributeConfiguration: AttributeConfiguration = getAttributeConfiguration(configuration, entityColumn.attributeName!);
    return {
      ...AbstractFormFieldListColumn.fromData(entityColumn),
      formFieldListColumnType: "GENERIC",
      valueBinding: `dynamicAttributes.${entityColumn.attributeName}.value`,
      headerText: entityColumn.headerText || attributeConfiguration.label,
    };
  } else {
    throw new Error("Simple list column type is not supported: " + column.formFieldListColumnType);
  }
}

function convertToGenericSimpleList(entityType?: string, list?: AbstractFormFieldListConfiguration,
                                    configurations?: Map<string, EntityConfiguration>): GenericFormFieldListConfiguration | undefined {
  if (list) {
    if (list.formFieldListConfigurationType === "GENERIC") {
      return (list as GenericFormFieldListConfiguration);
    } else if (list.formFieldListConfigurationType === "ENTITY") {
      if (configurations && entityType && configurations.has(entityType)) {
        const entityList = list as EntityFormFieldListConfiguration;
        const formVariant = entityList.formVariant ?? "default";
        const configuration = configurations!.get(entityType!)!;
        const genericColumns: GenericFormFieldListColumn[] = entityList.simpleColumns!
          .map(column => convertToGenericSimpleListItem(entityType, column, configuration));
        return {
          ...AbstractFormFieldListConfiguration.fromData(entityList),
          formFieldListConfigurationType: "GENERIC",
          formConfigurationUrl: `entity/formConfiguration?entityType=${entityType}&variant=${formVariant}`,
          simpleColumns: genericColumns,
        };
      } else {
        throw new Error(`No entity configuration is ready for list ${JSON.stringify(list)} of ${entityType}`);
      }
    } else {
      throw new Error(`Simple list configuration type is not supported: ${list.formFieldListConfigurationType}`);
    }
  } else {
    return undefined;
  }
}

function getAttributeConfigurationFromMap(entityType: string, attributeName: string, configurations: Map<string, EntityConfiguration>) {
  return getAttributeConfiguration(configurations.get(entityType)!, attributeName);
}

function getAttributeConfiguration(configuration: EntityConfiguration, attributeName: string) {
  return configuration.attributesConfiguration?.attributeConfigurations?.find(c => c.name === attributeName) || {};
}

function getValueBinding(attributeConfiguration: AttributeConfiguration): string | undefined {
  if (attributeConfiguration.referenceType === "BY_VALUE") {
    return "_";
  } else if (attributeConfiguration.referenceType === "BY_ID" || attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
    return "id";
  } else {
    return undefined;
  }
}

function getVersionBinding(attributeConfiguration: AttributeConfiguration): string | undefined {
  if (attributeConfiguration.referenceType === "BY_ID_AND_VERSION") {
    return "version";
  } else {
    return undefined;
  }
}

export function convertToGenericFormField(entityType: string,
                                          widget: CoreApi.AbstractWidgetUnion,
                                          configurations?: Map<string, EntityConfiguration>): CoreApi.AbstractWidgetUnion {
  if (widget.widgetType === "GENERIC_FIELD") {
    return widget;
  } else if (widget.widgetType === "ENTITY_FIELD") {
    return convertEntityFormFieldToGenericFormField(entityType, widget as EntityFormField, configurations);
  } else if (widget.widgetType === "ENTITY_LIST") {
    return widget;
  } else if (widget.widgetType === "ENTITY_JOURNAL") {
    return widget;
  } else if (widget.widgetType === "ENTITY_WORKFLOW_ACTIVE_TASKS") {
    return widget;
  } else {
    throw new Error(`Widget type ${widget.widgetType} is not supported in ${entityType}`);
  }
}

export function convertEntityFormFieldToGenericFormField(entityType: string,
                                                         entityFormField: CoreApi.EntityFormField,
                                                         configurations?: Map<string, EntityConfiguration>): GenericFormFieldWithFormConverter {
  if (configurations && configurations.has(entityType)) {
    const attributeConfiguration: AttributeConfiguration = getAttributeConfigurationFromMap(entityType, entityFormField.attributeName!, configurations!);
    const subEntityType = attributeConfiguration.entityType;
    const toGenericForm = subEntityType
      ? (form: any) => convertToGenericFormConfiguration(subEntityType, form as AbstractFormConfiguration, configurations)
      : undefined;
    const simpleListConfiguration = convertToGenericSimpleList(attributeConfiguration.entityType, entityFormField.simpleListConfiguration, configurations);
    const onFileAddedToList = (subEntityType && entityFormField.component === "LIST" && simpleListConfiguration?.fileAddable)
      ? (fr: FileReference, cv?: ListComponentValueAndChanges) => handleFileUpload(subEntityType, fr, cv) : undefined;
    return {
      ...AbstractFormField.fromData(entityFormField),
      widgetType: "GENERIC_FIELD",
      valueBinding: `dynamicAttributes.${entityFormField.attributeName}.value`,
      label: entityFormField.label || attributeConfiguration.label,
      simpleListConfiguration,
      possibleValuesList: attributeConfiguration.possibleValuesList,
      possibleValuesUrl: attributeConfiguration.entityType ? `entity/entities/${attributeConfiguration.entityType}` : undefined,
      possibleValuesByValuesUrl: attributeConfiguration.entityType ? `ids => 'entity/entities/${attributeConfiguration.entityType}?ids=' + ids.join(',')` : undefined,
      possibleValuesDisplayBinding: attributeConfiguration.displayBinding,
      possibleValuesValueBinding: getValueBinding(attributeConfiguration),
      possibleValuesVersionBinding: getVersionBinding(attributeConfiguration),
      toGenericForm,
      onFileAddedToList,
    };
  } else {
    throw new Error(`No entity configuration is ready for ${entityType} when processing field ${JSON.stringify(entityFormField)}: ${configurations}`);
  }
}

export function getWidgets(group: AbstractFormGroup): CoreApi.AbstractWidgetUnion[] {
  if (group.formGroupType === "GENERIC") {
    return (group as GenericFormGroup).widgets || [];
  } else if (group.formGroupType === "ENTITY") {
    return (group as EntityFormGroup).widgets || [];
  } else {
    throw new Error("Invalid group type " + group.formGroupType);
  }
}

export function convertToGenericFormGroup(entityType: string, group: AbstractFormGroup, configurations?: Map<string, EntityConfiguration>): GenericFormGroup {
  return {
    ...group,
    formGroupType: "GENERIC",
    widgets: getWidgets(group)
      .map(widget => convertToGenericFormField(entityType, widget, configurations)) || [],
  };
}

export function getGroups(form: AbstractFormConfiguration): AbstractFormGroup[] {
  if (form.formConfigurationType === "GENERIC") {
    return (form as GenericFormConfiguration).groups || [];
  } else if (form.formConfigurationType === "ENTITY") {
    return (form as EntityFormConfiguration).groups || [];
  } else {
    throw new Error("Invalid form type " + form.formConfigurationType);
  }
}

export function convertToGenericFormConfiguration(entityType: string, form: AbstractFormConfiguration,
                                                  configurations?: Map<string, EntityConfiguration>): GenericFormConfigurationAndVariantName {
  return {
    ...form,
    formConfigurationType: "GENERIC",
    groups: getGroups(form)
      .map(group => convertToGenericFormGroup(entityType, group, configurations)) || [],
    variantName: form.formConfigurationType === "ENTITY" ? (form as EntityFormConfiguration).variantName : undefined,
  };
}

function handleFileUpload(entityType: string, fileReference: FileReference, currentValue?: ListComponentValueAndChanges):
Promise<ListComponentValueAndChanges> {
  const sourceEntity = {
    ...fileReference,
    type: "FILE_REFERENCE",
  };
  const value = currentValue ?? createListComponentEmptyValue();

  return createEntityModelInitOptions(entityDataService, entityType, undefined, undefined, sourceEntity)
    .then(model => produceListComponentEntity(value, model.entity, model.propertyChanges as SafeObjectPropertyUpdate[]));
}
