/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createAsyncThunk} from "@reduxjs/toolkit";

import {EntityApi} from "../../api";

const RELOAD_DELAY = 500;

/**
 * Options to trigger the ReloadEntityEditModel reducer.
 */
type ReloadEntityModelParam = {
  entityType: string
  entityId: string
  /** Flag to make the model readonly after reload. */
  lock: boolean
}

/**
 * Async action to initialize the entity edit model by loading the entity from the server.
 * After reload, all (eager)propertyChanges of the EntityEditModel will be cleared.
 */
export const ReloadEntityEditModel = createAsyncThunk("@@entity/reloadEntityModel", async (p: ReloadEntityModelParam) => {
  const entity = await EntityApi.getEntity(p.entityType, p.entityId);
  // Make the request at least RELOAD_DELAY ms long, so that depending loading messages can be read by the user.
  await new Promise((resolve) => setTimeout(resolve, RELOAD_DELAY));
  return {
    entity,
    draft: false,
    readOnly: p.lock,
    propertyChanges: [],
  };
});
