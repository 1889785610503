/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {PropsWithChildren, useCallback} from "react";

import {FORM_GENERATOR_LAYOUT} from "../../constant";
import {AbstractFormField, GenericFormConfiguration} from "../../generated/api";
import {getNamedComponentFactory} from "../../ui";
import {ErrorBoundary} from "../error/ErrorBoundary";
import {FormGeneratorContextProvider} from "./FormGeneratorContextProvider";
import {CommonButtonProps, FieldHighlightMap, SubmitButtonConfiguration} from "./FormGeneratorTypes";


export interface IFormProps<T> {
  formConfig: GenericFormConfiguration;
  entity: T;
  persistedEntity?: T;
  onChange?: (field: AbstractFormField, value?: any, originalObject?: any) => void;
  submitButtonConfiguration?: SubmitButtonConfiguration;
  highlightedFields?: FieldHighlightMap;
  unHighlightField?: (valueBinding: string) => void;
  readOnly?: boolean;
  useSimpleValues: boolean;
  ruleContextAddition?: any;
  /**
   * custom container, the form shall be rendered in
   * @param groupsAndFields the form elements
   */
  renderFormContainer?: (groupsAndFields: React.ReactNode) => React.ReactNode;
  /**
   * custom container, the actions shall be rendered in
   * @param actions form actions
   */
  renderActionContainer?: (actions: React.ReactNode) => React.ReactNode;
  /**
   * custom method to render the submit button
   * @param buttonProps the submit buttons props
   */
  renderSubmitButton?: (buttonProps: CommonButtonProps) => React.ReactNode;

  /**
   * if true, form actions are rendered in the header's additional actions
   *
   * @default false
   */
  renderActionsInHeader?: boolean;

  onFormValidationChange?: (isValid: boolean) => void
}

export type FormGeneratorProps<T> = IFormProps<T>;

export const FormGenerator = <T extends {}>(props: PropsWithChildren<FormGeneratorProps<T>>) => {
  const {submitButtonConfiguration} = props;
  const onSubmit = submitButtonConfiguration?.onSubmit;

  const handleSubmit = useCallback((event?: React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      onSubmit();
    }
  }, [onSubmit]);
  const FormGeneratorLayout = getNamedComponentFactory(FORM_GENERATOR_LAYOUT)
    || (() => <span>The form generator layout is not configured.</span>);
  return (
    <ErrorBoundary>
      <form onSubmit={handleSubmit}>
        <FormGeneratorContextProvider {...props}>
          <FormGeneratorLayout {...props} />
        </FormGeneratorContextProvider>
      </form>
    </ErrorBoundary>
  );
};
