/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../theme/IcmMuiTheme";

export const styles = (theme: IcmMuiTheme) => createStyles({
  panelContent: {
    padding: theme.spacing(0, 1, 1),
    marginTop: theme.spacing(-0.5),
  },
  expansionPanelSummary: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  expansionPanelSummaryContent: {
    padding: theme.spacing(1, 1, 1, 1),
    marginTop: "0 !important",
    marginBottom: "0 !important",
    "&$expanded": {
      margin: 0,
    },
  },
  expansionPanel: {
    border: 0,
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
});
