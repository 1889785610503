/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {useContext} from "react";


export type NotificationCenterContextType = {
  markNotificationAsRead: (key: string | string[]) => void
  deleteNotifications: (key: string | string[]) => void
  openNotificationCenter: () => void
  closeNotificationCenter: () => void
}

function notInitialized() {
  return console.error("NotificationCenterContext not initialized");
}

export const NotificationCenterContext = React.createContext<NotificationCenterContextType>({
  deleteNotifications: notInitialized,
  markNotificationAsRead: notInitialized,
  openNotificationCenter: notInitialized,
  closeNotificationCenter: notInitialized,
});


export const useNotificationCenterContext = () => {
  return useContext(NotificationCenterContext);
};
