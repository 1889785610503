/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ConfirmDialogProps, ConfirmDialogProvider, MessageKey, useCommonConfirmDialog, useMessages} from "@icm/core-common";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

import {ButtonComponent} from "../form";
import {useDialogStyles} from "./DialogStyle";

const ConfirmDialog = ({title, description, confirmLabel = "OK", cancelLabel = "CANCEL", onConfirm, onCancel}: ConfirmDialogProps) => {
  const classes = useDialogStyles();
  const {getMessage} = useMessages();
  return (
    <Dialog open={true} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        {description && (
          <DialogContentText>{description}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonComponent label={getMessage(MessageKey.CORE[cancelLabel])} onClick={onCancel} variant="outlined" />
        <ButtonComponent label={getMessage(MessageKey.CORE[confirmLabel])} onClick={onConfirm} variant="contained" />
      </DialogActions>
    </Dialog>
  );
};

export const useWebConfirmDialog = (): ConfirmDialogProvider => useCommonConfirmDialog(ConfirmDialog);
