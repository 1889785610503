/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SingleViewPerspective} from "../../store";
import {AbstractPerspectiveMapper} from "./AbstractPerspectiveMapper";
import {PerspectiveMapperProps} from "./PerspectiveMapper";


/**
 * Creates a new single view perspective for mobile.
 */
export class SingleViewPerspectiveMapper extends AbstractPerspectiveMapper {

  constructor(props: PerspectiveMapperProps) {
    super(props);
    this.map = this.map.bind(this);
  }

  public map(): SingleViewPerspective | undefined {
    if (!this.configuredPerspective.mainArea?.singleView) {
      console.error("The available perspective can't be used on this device");
      return undefined;
    }

    const viewModel = this.toViewModel(this.configuredPerspective.mainArea.singleView);
    if (!viewModel) {
      return undefined;
    }

    const globalActions = this.toActionBar(this.uiConfiguration.actions, this.configuredPerspective.globalActions);

    return {
      serialVersion: this.uiConfiguration.version,
      perspectiveType: "SINGLE_VIEW",
      id: this.configuredPerspective.id!,
      label: this.configuredPerspective.label ?? "Unknown",
      globalActions,
      view: {
        viewModel,
      },
      history: [],
    };
  }

}
