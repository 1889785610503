/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService, PropertyService, SecurityService} from "@icm/core-common";

import {EntityApi} from "../api";
import {Entity} from "../generated/api";

const REFRESH_INTERVAL_MILLIS = 10 * 60 * 1000;
const CACHE_TYPES_PROPERTY = "entity.cache.types";

class EntityCacheService {
  private readonly cache: Record<string, Entity[]> = {};

  constructor(propertyService: PropertyService, securityService: SecurityService) {
    this.getByTypeAndId = this.getByTypeAndId.bind(this);
    this.getAll = this.getAll.bind(this);

    this.initCachePeriodically(propertyService, securityService);

    ExpressionEvaluationService.registerHelper("entityCache", "getByTypeAndId", (entityType: string, entityId: string) => {
      return this.getByTypeAndId(entityType, entityId);
    });

    ExpressionEvaluationService.registerHelper("entityCache", "getAll", (entityType: string) => {
      return this.getAll(entityType);
    });
  }

  getAll(entityType: string): Entity[] {
    if (!this.cache[entityType]) {
      console.warn(`Entity cache does not contain entities of type ${entityType}: Please make sure this type is marked to be cached via property ${CACHE_TYPES_PROPERTY}`);
      return [];
    } else {
      return this.cache[entityType] || [];
    }
  }


  getByTypeAndId(entityType: string, entityId: string): Entity | undefined {
    if (!this.cache[entityType]) {
      console.warn(`Entity cache does not contain entities of type ${entityType}: Please make sure this type is marked to be cached via property ${CACHE_TYPES_PROPERTY}`);
      return undefined;
    } else {
      const entities = this.cache[entityType] || [];
      return entities.find(e => e.id === entityId);
    }
  }

  initCachePeriodically(propertyService: PropertyService, securityService: SecurityService) {
    propertyService.whenInitialized().then(() => {
      securityService.addLoginHandler(() => {
        this.initCache(propertyService).then(() => {
          const timerId = window.setInterval(() => this.initCache(propertyService), REFRESH_INTERVAL_MILLIS);
          securityService.addPreLogoutHandler(async () => window.clearInterval(timerId));
        });
      });
    });
  }

  async initCache(propertyService: PropertyService) {
    console.debug("Refreshing entity cache: Starting...");
    const cachedTypes = propertyService.getValue(CACHE_TYPES_PROPERTY)?.split(",").map(s => s.trim()) || [];
    console.debug(" - caching entities of type", cachedTypes);
    const promises = cachedTypes.map(async cachedType => {
      const entities = await EntityApi.getEntities(cachedType);
      this.cache[cachedType] = entities.sublist || [];
    });
    await Promise.all(promises);
    console.debug("Initializing entity cache: Done.");
  }

}

export {EntityCacheService};
