/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService, FetchService, queryClient} from "../service";
import {Dao, DaoOptions} from "./Dao";

export type UrlBasedDaoOptions = DaoOptions & {
  // URL to retrieve possible values, modifiable by a search string
  possibleValuesUrl: string
  // URL to retrieve entities by the values (as per the given valueBinding)
  possibleValuesByValuesUrl?: string
};

/**
 * provides possible values from the given URLs
 */
export class UrlBasedDao<T> extends Dao<T> {

  private readonly options: UrlBasedDaoOptions;

  constructor(options: UrlBasedDaoOptions) {
    super(options);
    this.options = options;
    this.loadPossibleValuesByValues = this.loadPossibleValuesByValues.bind(this);
    this.loadPossibleValues = this.loadPossibleValues.bind(this);
  }

  loadPossibleValuesByValues(values: unknown[], abortController?: AbortController) {
    return this.options.possibleValuesByValuesUrl
      ? FetchService.performGet(ExpressionEvaluationService.evaluate(this.options.possibleValuesByValuesUrl, values), {abortController})
        .then(possibleValues => verifyResult<T>(possibleValues, values))
        .then(possibleValues => possibleValues.filter(v => !!v))
        .catch(reason => {
          console.error(`Error getting possible values by values from ${this.options.possibleValuesByValuesUrl}`, reason);
          return [];
        })
      : Promise.resolve([]);
  }

  loadPossibleValues(_search?: string, _abortController?: AbortController) {
    const searchToUse = undefined; /* search */
    const queryKey = this.options.possibleValuesUrl;
    return queryClient.fetchQuery(["private", "core", "dao", queryKey],
      async () => {
        const values = await FetchService.performGet(ExpressionEvaluationService.evaluate(this.options.possibleValuesUrl, searchToUse));
        return verifyResult<T>(values, searchToUse);
      },
      {
        staleTime: Infinity,
      });
  }
}


function verifyResult<T>(values: any, params: any) {
  if (Array.isArray(values)) {
    return values as T[];
  } else if (hasSublist(values)) {
    return values.sublist as T[];
  } else {
    throw Error("failed to get options, params:" + JSON.stringify(params) + ", got: " + JSON.stringify(values));
  }
}

type Sublist = {
  sublist: unknown[]
}

function hasSublist(value: any): value is Sublist {
  return value.sublist && Array.isArray(value.sublist);
}
