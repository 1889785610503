/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/** A function to determine the context object. */
export type GetContextObject<T extends object> = (some: T) => object

export const IdenticalContextObject: GetContextObject<object> = (some: object) => some;

/** A function to determine if an item is visible. */
export type IsItemVisible<T extends object> = (some: T) => boolean;

export const DIVIDER = "DIVIDER";
export const ACTION_ITEM = "ACTION_ITEM";
export const MENU = "MENU";

export type MenuItemType = typeof DIVIDER | typeof ACTION_ITEM | typeof MENU;

export type ContextMenuItem = {
  menuItemType: MenuItemType
} & BasicMenuItem | DividerItem | ContextMenu;

type BasicMenuItem = {
  isVisible: (contextObject: object) => boolean
  label: string
  icon?: string
}

/**
 * Menu shown in the context of some object of type T
 * T is most likely an entity.
 */
export type ContextMenu = {
  menuItemType: typeof MENU,
  items: ContextMenuItem[]
} & BasicMenuItem


/**
 * An item that should be mapped to a divider/separator.
 */
export type DividerItem = {
  menuItemType: typeof DIVIDER,
}

/**
 * An item that executes an action passing using an object of
 * type <T> as context parameter.
 */
export type ContextMenuActionItem = {
  menuItemType: typeof ACTION_ITEM,
  label: string
  icon: string
  onClick: (contextObject: object) => void
}

export function isContextMenuItem(some: unknown): some is ContextMenuItem {
  return isContextMenuActionItem(some)
    || isMenuDividerItem(some)
    || isContextMenuItemOfType(some, MENU);
}

export function isContextMenuActionItem(some: unknown): some is ContextMenuActionItem {
  return isContextMenuItemOfType(some, ACTION_ITEM);
}

export function isMenuDividerItem(some: unknown): some is DividerItem {
  return isContextMenuItemOfType(some, DIVIDER);
}

function isContextMenuItemOfType(some: unknown, expected: MenuItemType): some is ContextMenuItem {
  if (some !== null && typeof some === "object") {
    return some["menuItemType"] === expected;
  }
  return false;
}
