/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Reducer} from "redux";
import {ActionType, getType} from "typesafe-actions";

import * as sketchActions from "./actions";
import {ISketchListState} from "./types";

export type SketchAction = ActionType<typeof sketchActions>

const initialSketchListState: ISketchListState = {
  sketchList: [],
  loading: false,
  updatedSketches: {},
};

const sketchListReducer: Reducer<ISketchListState> = (state = initialSketchListState, action: SketchAction): ISketchListState => {
  switch (action.type) {
    case getType(sketchActions.fetchSketches.request): {
      return {...state, loading: true};
    }
    case getType(sketchActions.fetchSketches.success): {
      return {...state, loading: false, sketchList: action.payload};
    }
    case getType(sketchActions.fetchSketches.failure): {
      return {...state, loading: false, error: action.payload};
    }
    case getType(sketchActions.resetNotifications): {
      return {...state, updatedSketches: {}};
    }
    default: {
      return state;
    }
  }
};
export {sketchListReducer};
