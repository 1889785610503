/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ListFilter,
  MessageKey,
  useFeedbackBanners,
  useMessages,
  usePublicProperties,
  useFilterConfigurations,
  userDownloadService,
} from "@icm/core-common";
import {useCallback} from "react";

import {EntityApi} from "../api";
import {Entity} from "../generated/api";
import {EntityExportService} from "../service/EntityExportService";

/**
 * @return {void} a function that starts a CSV download of the given entityType and filter
 */
export const useCsvDownload = (entityType: string, listFilter?: ListFilter, overallEntityCount?: number | undefined) => {
  const {openFeedbackBanner} = useFeedbackBanners();
  const {getMessage} = useMessages();
  const additionalLabels = useFilterConfigurations();

  const maximumCsvLimit = parseInt(usePublicProperties()["entity.csv.limit"]);

  return useCallback(() => {
    const FilterIndicator = (): string => getMessage(MessageKey.CORE.LIST.FILTER);
    const downloadCsv = (filter: ListFilter | undefined, abortController: AbortController): Promise<void> => {
      const newFilter: ListFilter = {...filter, metadata: {...filter?.metadata} || {}};
      newFilter.metadata!["CSV_EXPORT"] = true;
      const start = performance.now();
      return EntityApi.getEntities(entityType, newFilter, abortController).then((entityList) => {
        const entities: Entity[] = entityList.sublist || [];
        EntityExportService.generateCsvExport(entityType, entities, FilterIndicator, filter, additionalLabels).then(() => {
          console.debug("Download of CSV took: " + (performance.now() - start) + " ms");
        });
      }).catch(ex => {
        console.log("A problem occurred while trying go start CSV download", ex);
      });
    };

    console.debug("Starting CSV download, overall entity count:", overallEntityCount, "maximum:", maximumCsvLimit);
    if (overallEntityCount && overallEntityCount > maximumCsvLimit) {
      console.log("CSV maximum exceeded.");
      openFeedbackBanner({
        key: "CSV_INCIDENT_LIMIT_EXCEEDED",
        title: getMessage(MessageKey.CORE.DOWNLOAD.CSV_INCIDENT_LIMIT_EXCEEDED)!,
        variant: "WARNING",
        duration: "SHORT",
      });
    } else {
      userDownloadService.startDownloadWith(abortController => downloadCsv(listFilter!, abortController));
    }
  }, [overallEntityCount, maximumCsvLimit, entityType, additionalLabels, openFeedbackBanner, getMessage, listFilter]);
};
