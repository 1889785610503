/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, useMessages} from "@icm/core-common";
import {ButtonComponent, TextBoxComponent} from "@icm/core-web";
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {useState} from "react";

import styles from "./AuditLog.style";

type IProps = {
  openAuditLog: (entityNumber: string) => void
}

const useStyles = makeStyles(styles);

export const AuditLogChooser = ({openAuditLog}: IProps) => {
  const classes = useStyles();
  const {getMessage} = useMessages();
  const [entityNumber, setEntityNumber] = useState<string>();
  return (
    <form onSubmit={() => entityNumber && openAuditLog(entityNumber)}>
      <Grid key="root"
            className={classes.root}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
      >
        <Grid item xs={4}>
          <TextBoxComponent label={getMessage(MessageKey.AUDITLOG.INPUT_ENTITY_NUMBER)!}
                            type="text"
                            value={entityNumber}
                            handleChange={setEntityNumber}
          />
        </Grid>
        <Grid item>
          <ButtonComponent label={getMessage(MessageKey.AUDITLOG.OPEN_AUDITLOG)!}
                           disabled={!entityNumber}
                           type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};
