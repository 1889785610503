/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useQuery} from "react-query";

import {UiConfigurationApi} from "../api";
import {useService} from "../service";
import {Perspective} from "../store";

/**
 * In case of the web client, the hook tries to fetch the current uiState from the server
 * and extracts the stored perspective. Hook is backed by the query client and only executed
 * once after login (as long as the Query Client is reset on logout).
 *
 * @return
 *  - NOT_FOUND in case no perspective was found in the user settings
 *  - LOADING in case the request is running
 *  - perspective in case loading succeeded
 *
 */
export const useRemotePerspective = (): Perspective | "NOT_FOUND" | "LOADING" => {
  const platformService = useService("PLATFORM");
  const securityService = useService("SECURITY");
  const result = useQuery<Perspective | "NOT_FOUND" | undefined>(["private", "core", "settings", "ui"], async () => {
    if (platformService.isWeb && securityService.isLoggedIn()) {
      const state = await UiConfigurationApi.getStoredUiState();
      if (state?.perspective) {
        return state.perspective;
      }
    }
    return "NOT_FOUND";
  }, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  if (result.data) {
    return result.data;
  }
  return "LOADING";
};
