/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {MessageContent, MessageEnvelope, StartEntityCommunicationCommand} from "@icm/communication-common";
import {CommunicationChannel, IdGenerator, MessageKey, useMessages} from "@icm/core-common";
import {EntityCommandApi} from "@icm/entity-common";
import {useMemo} from "react";

import {SendMessageController} from "./SendMessageController";
import {SendMessageControllerProps} from "./SendMessageControllerProps";

export const useSendMessageController = (props: SendMessageControllerProps): SendMessageController => {
  const {
    addressFieldController,
    messageEditController,
    parentEntityType,
    parentEntityId,
    channel,
  } = props;
  const {getMessage} = useMessages();

  return useMemo(() => {
    function getMessageEnvelopes(): MessageEnvelope[] {
      return addressFieldController.getSelectedAddresses().map(address => ({
        recipient: {
          name: address.label,
          value: address.addressValue,
        },
        sender: { },
        correlationId: address.id,
      }));
    }

    function getMessageContent(contentAsHtml: boolean): MessageContent {
      return {
        subject: messageEditController.getMessage().subject,
        content: contentAsHtml
          ? `<html lang="${getMessage(MessageKey.CORE.LANGUAGE)}">
            <body>${messageEditController.getMessage().content}</body>
           </html>`
          : messageEditController.getMessage().content,
      };
    }

    function buildCommand(): StartEntityCommunicationCommand {
      return {
        id: IdGenerator.randomUUID(),
        entityCommandType: "START_COMMUNICATION",
        entityType: parentEntityType,
        entityId: parentEntityId,
        timestamp: new Date(),
        messageSendRequest: {
          channel,
          messageType: "DEFAULT",
          contextDefinition: {
            contextId: `${parentEntityType}:${parentEntityId}`,
          },
          messageContent: getMessageContent(channel === CommunicationChannel.EMAIL),
          messageEnvelopes: getMessageEnvelopes(),
        },
      };
    }

    return {
      sendMessage(): Promise<void> {
        // @ts-ignore types are not compatible, see PICM-2421
        return EntityCommandApi.processEntityCommands([buildCommand()]);
      },
    };
  }, [addressFieldController, channel, getMessage, messageEditController, parentEntityId, parentEntityType]);
};
