/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchOptions, FetchService, ListFilter} from "@icm/core-common";

import {ActivityStreamEntry, ActivityStreamEntryListPortion} from "../generated/api";

export class ActivityStreamApi {
  public static getEntries(listFilter: ListFilter, fetchOptions?: FetchOptions): Promise<ActivityStreamEntryListPortion> {
    return FetchService.performGet("activitystream/entries?filter=" + encodeURIComponent(JSON.stringify(listFilter)), fetchOptions)
      .then(json => ActivityStreamEntryListPortion.fromData(json));
  }

  public static putEntries(entries: ActivityStreamEntry[]): Promise<string[]> {
    return FetchService.performPutWithJsonResult("activitystream/entries", entries);
  }
}
