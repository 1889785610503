/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useAvailableViewDescriptors, useViewContext, useViewModelForTab} from "@icm/core-common";
import {useCallback, useMemo} from "react";

import {TabGroupDescriptor} from "../tabgroup/useTabGroupDescriptor";


/**
 * Usage: `printer.print()`
 */
export type BrowserPrinter = {
  print: () => void,
  isPrinting: () => boolean,
}

let printing = false;

export type BrowserPrinterOptions = {
  forTabGroupId: string,
  forTabId: string,
}

export const useBrowserPrinter = (options?: BrowserPrinterOptions): BrowserPrinter => {
  const viewContext = useViewContext();
  const selectedTabGroupId = options?.forTabGroupId ?? viewContext?.tabGroupId;
  const selectedTabId = options?.forTabId ?? viewContext?.tabId;

  const {setViewModelData} = useViewModelForTab(selectedTabGroupId, selectedTabId);

  const beforePrint = useCallback(() => {
    setViewModelData((prevData) => {
      return {
        ...prevData,
        printRequested: true,
      };
    });
  }, [setViewModelData]);
  const afterPrint = useCallback(() => {
    setViewModelData((prevData) => {
      return {
        ...prevData,
        printRequested: false,
      };
    });
  }, [setViewModelData]);

  return useMemo(() => {
    const print = () => {
      if (printing) {
        return;
      }
      beforePrint();
      printing = true;
      setTimeout(() => {
        window.print();
        afterPrint();
      }, 400);
      setTimeout(() => {
        printing = false;
      }, 1000);
    };
    return ({
      print,
      isPrinting: () => {
        return printing;
      },
    });
  }, [afterPrint, beforePrint]);
};

export const useBrowserPrinterInTabComponent  = (
  groupDescriptor: TabGroupDescriptor,
): BrowserPrinter | undefined => {
  const selectedTab = groupDescriptor.selectedTab;
  const availableViewDescriptors = useAvailableViewDescriptors();
  const viewDescriptor = availableViewDescriptors[selectedTab.viewModel.viewType];

  const isPrintable = useMemo(() =>  {
    if (!selectedTab) {
      return false;
    }
    if (!viewDescriptor) {
      return false;
    }

    return viewDescriptor.isPrintable?.(selectedTab.viewModel) ?? false;
  }, [selectedTab, viewDescriptor]);

  const printer = useBrowserPrinter({forTabGroupId: groupDescriptor.id, forTabId: selectedTab.id});

  if (isPrintable) {
    return printer;
  } else {
    return undefined;
  }
};
