/* eslint-disable */
export default {
  "_MEDIA_STREAM": {
    "ACTIVITYSTREAM": {
      "CHANGE": {
        "TITLE": {
          "HAS_ARCHIVED": "_media_stream.activitystream.change.title.hasArchived",
          "HAS_CREATED": "_media_stream.activitystream.change.title.hasCreated",
          "HAS_DELETED": "_media_stream.activitystream.change.title.hasDeleted",
          "HAS_UPDATED": "_media_stream.activitystream.change.title.hasUpdated"
        }
      },
      "CHANGE_TEMPLATE": "_media_stream.activitystream.changeTemplate",
      "UPDATED": "_media_stream.activitystream.updated"
    },
    "LABEL": {
      "GEO_LOCATION": "_media_stream.label.geoLocation",
      "ICON_NAME": "_media_stream.label.iconName",
      "NONE": "_media_stream.label.none",
      "SOURCE_ADDRESS": "_media_stream.label.sourceAddress",
      "SOURCE_CONFIGURATION": "_media_stream.label.sourceConfiguration",
      "SOURCE_NAME": "_media_stream.label.sourceName",
      "SOURCE_SELECTION": "_media_stream.label.sourceSelection",
      "SOURCE_TYPE": "_media_stream.label.sourceType",
      "TITLE": "_media_stream.label.title"
    },
    "LIST": {
      "DEFAULT": {
        "TITLE": "_media_stream.list.default.title"
      }
    }
  },
  "_PROCESS_DEFINITION": {
    "ACTIVITYSTREAM": {
      "CHANGE": {
        "TITLE": {
          "HAS_ARCHIVED": "_process_definition.activitystream.change.title.hasArchived",
          "HAS_CREATED": "_process_definition.activitystream.change.title.hasCreated",
          "HAS_DELETED": "_process_definition.activitystream.change.title.hasDeleted",
          "HAS_UPDATED": "_process_definition.activitystream.change.title.hasUpdated"
        }
      },
      "CHANGE_TEMPLATE": "_process_definition.activitystream.changeTemplate",
      "UPDATED": "_process_definition.activitystream.updated"
    }
  },
  "_ROOM_SETUP": {
    "ACTIVITYSTREAM": {
      "CHANGE": {
        "TITLE": {
          "HAS_ARCHIVED": "_room_setup.activitystream.change.title.hasArchived",
          "HAS_CREATED": "_room_setup.activitystream.change.title.hasCreated",
          "HAS_DELETED": "_room_setup.activitystream.change.title.hasDeleted",
          "HAS_UPDATED": "_room_setup.activitystream.change.title.hasUpdated"
        }
      },
      "CHANGE_TEMPLATE": "_room_setup.activitystream.changeTemplate",
      "UPDATED": "_room_setup.activitystream.updated"
    }
  },
  "ACTIVITYSTREAM": {
    "CHANGE": {
      "COMMUNICATION_STATUS": {
        "AWAITING_RESPONSE": "activitystream.change.communicationStatus.AWAITING_RESPONSE",
        "CANCELLED": "activitystream.change.communicationStatus.CANCELLED",
        "ESCALATED": "activitystream.change.communicationStatus.ESCALATED",
        "FAILED": "activitystream.change.communicationStatus.FAILED",
        "PENDING": "activitystream.change.communicationStatus.PENDING",
        "REJECTED": "activitystream.change.communicationStatus.REJECTED",
        "SENT": "activitystream.change.communicationStatus.SENT",
        "SUCCESS": "activitystream.change.communicationStatus.SUCCESS"
      },
      "TITLE": {
        "COMMUNICATION_UPDATED": "activitystream.change.title.communicationUpdated",
        "HAS_ARCHIVED": "activitystream.change.title.hasArchived",
        "HAS_CREATED": "activitystream.change.title.hasCreated",
        "HAS_DELETED": "activitystream.change.title.hasDeleted",
        "HAS_UPDATED": "activitystream.change.title.hasUpdated",
        "OTHER": "activitystream.change.title.other"
      }
    },
    "EDITOR": {
      "CREATE_NEW_ENTRY": "activitystream.editor.createNewEntry",
      "LINK_ENTITIES": "activitystream.editor.linkEntities",
      "LINK_ENTITY": "activitystream.editor.linkEntity",
      "LINK_ENTITY_DESCRIPTION": "activitystream.editor.linkEntityDescription",
      "SEND": "activitystream.editor.send"
    },
    "ENTRY": {
      "TITLE": {
        "OTHER": "activitystream.entry.title.other"
      }
    },
    "OPEN_WITH": {
      "FILTER": "activitystream.openWith.filter"
    },
    "PROPERTY": {
      "COLLECTION": {
        "ADDED_ITEMS": "activitystream.property.collection.addedItems",
        "REMOVED_ITEMS": "activitystream.property.collection.removedItems"
      },
      "OTHER": {
        "RELATED_TO": "activitystream.property.other.relatedTo"
      },
      "VALUE": {
        "CHANGED_FROM": "activitystream.property.value.changedFrom",
        "CHANGED_FROM_TO": "activitystream.property.value.changedFromTo",
        "CHANGED_TO": "activitystream.property.value.changedTo"
      }
    },
    "ACTOR": "activitystream.actor",
    "TITLE": "activitystream.title"
  },
  "AUDITLOG": {
    "INCIDENT": {
      "_": "auditlog.incident",
      "VALUEDELETED": "auditlog.incident.valuedeleted"
    },
    "REQUEST": {
      "ERROR": "auditlog.request.error"
    },
    "AUDITLOG": "auditlog.auditlog",
    "INPUT_ENTITY_NUMBER": "auditlog.inputEntityNumber",
    "LINKEDTOINCIDENT": "auditlog.linkedtoincident",
    "MAIN_DATA": "auditlog.mainData",
    "OPEN_AUDITLOG": "auditlog.openAuditlog",
    "REM_WEB_VIEWER_INCIDENT": "auditlog.rem_web_viewer_incident",
    "SAVE_PDF": "auditlog.savePdf"
  },
  "CHAT": {
    "ACTION": {
      "CHOOSE_CHAT": "chat.action.choose_chat",
      "CONFIGURE_CHAT": "chat.action.configure_chat",
      "ENTER_CHAT": "chat.action.enter_chat",
      "LEAVE_CHAT": "chat.action.leave_chat",
      "LOCK_CHAT": "chat.action.lock_chat",
      "SHARE_CHAT": "chat.action.share_chat"
    },
    "ACTIVITY": {
      "IDLE": "chat.activity.idle",
      "TYPING": "chat.activity.typing"
    },
    "CONFIGURATION": {
      "DIALOG": {
        "CREATE_ROOM_TITLE": "chat.configuration.dialog.create_room_title",
        "EDIT_ROOM_TITLE": "chat.configuration.dialog.edit_room_title"
      },
      "FILTER_HINT": "chat.configuration.filterHint",
      "HINT": "chat.configuration.hint"
    },
    "CONNECTION": {
      "OFFLINE": "chat.connection.offline"
    },
    "EDITOR": {
      "HINT": {
        "USE_SHIFT_ENTER": "chat.editor.hint.useShiftEnter"
      },
      "SEND_MESSAGE": "chat.editor.sendMessage"
    },
    "ERROR": {
      "CHAT_NOT_CONFIGURED": "chat.error.chatNotConfigured",
      "COULD_NOT_LOAD_CONTACTS": "chat.error.couldNotLoadContacts",
      "COULD_NOT_LOAD_MESSAGES": "chat.error.couldNotLoadMessages",
      "COULD_NOT_LOAD_ROOMS": "chat.error.couldNotLoadRooms",
      "NO_CONNECTION": "chat.error.noConnection"
    },
    "LABEL": {
      "ACCOMPANYING_MESSAGE": "chat.label.accompanyingMessage",
      "AVAILABLE_ROOMS": "chat.label.availableRooms",
      "CREATE_CHAT": "chat.label.createChat",
      "INVALID_EMAIL": "chat.label.invalidEmail",
      "INVALID_MAIL": "chat.label.invalidMail",
      "INVITATIONS": "chat.label.invitations",
      "LOADING_CONTACTS": "chat.label.loadingContacts",
      "MAIL_PREVIEW": "chat.label.mailPreview",
      "RECIPIENTS": "chat.label.recipients",
      "ROOM": "chat.label.room",
      "ROOM_MEMBERS": "chat.label.roomMembers",
      "ROOM_TITLE": "chat.label.roomTitle",
      "SHARE_CHAT": "chat.label.shareChat",
      "SUBJECT": "chat.label.subject"
    },
    "LOG": {
      "LOAD_OLDER_MESSAGES": "chat.log.loadOlderMessages",
      "NO_MESSAGES": "chat.log.noMessages",
      "START": "chat.log.start"
    },
    "NOTIFICATION": {
      "ACTION": {
        "OPEN_CHAT": "chat.notification.action.openChat"
      },
      "NEW_INVITATION": "chat.notification.newInvitation",
      "NEW_MESSAGE": "chat.notification.newMessage",
      "PENDING_INVITATION": "chat.notification.pendingInvitation"
    },
    "ROOM_SETUP": {
      "LABEL": {
        "ENTITY_REFERENCES": "chat.room_setup.label.entityReferences",
        "JID": "chat.room_setup.label.jid",
        "LOCKED": "chat.room_setup.label.locked",
        "PARTICIPANTS": "chat.room_setup.label.participants",
        "TITLE": "chat.room_setup.label.title"
      }
    },
    "SHARE": {
      "TEMPLATE": {
        "CHAT_HISTORY": "chat.share.template.chatHistory",
        "LINKED_ENTITIES": "chat.share.template.linkedEntities",
        "PARTICIPANTS": "chat.share.template.participants",
        "SUBJECT": "chat.share.template.subject"
      },
      "ERROR": "chat.share.error",
      "SUCCESS": "chat.share.success"
    },
    "STATUS": {
      "AWAY": "chat.status.away",
      "CHAT": "chat.status.chat",
      "DND": "chat.status.dnd",
      "NOT_CONFIGURED": "chat.status.not_configured",
      "NOT_DEFINED": "chat.status.not_defined",
      "XA": "chat.status.xa"
    },
    "TITLE": "chat.title"
  },
  "COMMUNICATION": {
    "CHANNEL": {
      "_": "communication.channel",
      "EMAIL": "communication.channel.email",
      "PHONE": "communication.channel.phone",
      "SMS": "communication.channel.sms",
      "TWOWAYSMS": "communication.channel.twowaysms",
      "VOICEMAIL": "communication.channel.voicemail"
    },
    "RECIPIENT": {
      "NAME": "communication.recipient.name",
      "VALUE": "communication.recipient.value"
    },
    "RESPONSEVALUE": {
      "ACCEPTED": "communication.responsevalue.accepted",
      "ARRIVED": "communication.responsevalue.arrived",
      "BUSY": "communication.responsevalue.busy",
      "INVALID": "communication.responsevalue.invalid",
      "REJECTED": "communication.responsevalue.rejected",
      "SOLVED": "communication.responsevalue.solved"
    },
    "STATUS": {
      "CALL": {
        "CANCELLED": "communication.status.call.cancelled",
        "FAILED": "communication.status.call.failed",
        "PENDING": "communication.status.call.pending",
        "REJECTED": "communication.status.call.rejected",
        "SUCCESS": "communication.status.call.success"
      },
      "ONEWAY": {
        "CANCELLED": "communication.status.oneway.cancelled",
        "FAILED": "communication.status.oneway.failed",
        "PENDING": "communication.status.oneway.pending",
        "SUCCESS": "communication.status.oneway.success"
      },
      "TWOWAY": {
        "AWAITINGRESPONSE": "communication.status.twoway.awaitingresponse",
        "CANCELLED": "communication.status.twoway.cancelled",
        "ESCALATED": "communication.status.twoway.escalated",
        "FAILED": "communication.status.twoway.failed",
        "PENDING": "communication.status.twoway.pending",
        "REJECTED": "communication.status.twoway.rejected",
        "SUCCESS": "communication.status.twoway.success"
      },
      "_": "communication.status"
    },
    "TYPE": {
      "_": "communication.type",
      "DEFAULT": "communication.type.default",
      "ESCALATIONMESSAGE": "communication.type.escalationmessage",
      "RESPONSEMESSAGE": "communication.type.responsemessage"
    },
    "MANUAL": "communication.manual",
    "MESSAGES": "communication.messages",
    "TIMESTAMP": "communication.timestamp"
  },
  "CORE": {
    "ACCESS": {
      "DENIED": "core.access.denied"
    },
    "ACTIVITYSTREAM": {
      "LEGEND": {
        "HIGHLIGHT": "core.activitystream.legend.highlight",
        "OUTDATED": "core.activitystream.legend.outdated"
      },
      "NO_ENTRIES": "core.activitystream.noEntries"
    },
    "BULK": {
      "REPORT": {
        "FOOTER": {
          "GENERATED": {
            "DATE": "core.bulk.report.footer.generated.date"
          },
          "PAGE": "core.bulk.report.footer.page"
        }
      }
    },
    "CALL": {
      "STARTED": "core.call.started"
    },
    "CONFIRMATION": {
      "DISCARD_CHANGES": {
        "DESCRIPTION": "core.confirmation.discardChanges.description",
        "TITLE": "core.confirmation.discardChanges.title"
      }
    },
    "DOWNLOAD": {
      "_": "core.download",
      "ABORTED": "core.download.aborted",
      "CSV": "core.download.csv",
      "CSV_INCIDENT_LIMIT_EXCEEDED": "core.download.csvIncidentLimitExceeded",
      "CSV_IS_EMPTY": "core.download.csvIsEmpty",
      "FAILED": "core.download.failed",
      "IN_PROGRESS": "core.download.inProgress",
      "ONLY_ONE_ALLOWED": "core.download.onlyOneAllowed"
    },
    "ERROR": {
      "CREATE_LOG_FILE": "core.error.createLogFile",
      "CREATE_LOG_FILE_CONFIRMATION": "core.error.createLogFileConfirmation",
      "GENERAL": "core.error.general",
      "PERMISSION_DENIED": "core.error.permissionDenied",
      "UNKNOWN": "core.error.unknown"
    },
    "FILE": {
      "UNKNOWN": "core.file.unknown"
    },
    "FILTER": {
      "ATTRIBUTE": {
        "REMOVE": "core.filter.attribute.remove",
        "RESET": "core.filter.attribute.reset"
      },
      "EDIT": {
        "LABEL": "core.filter.edit.label"
      },
      "OPTIONS": {
        "ADD": "core.filter.options.add",
        "NO_SAVED": "core.filter.options.noSaved"
      },
      "SELECTION": {
        "ERROR": {
          "NAME_ALREADY_EXISTS": "core.filter.selection.error.nameAlreadyExists"
        },
        "NAME": "core.filter.selection.name",
        "NEW": "core.filter.selection.new",
        "NO_SAVED": "core.filter.selection.noSaved",
        "SAVE_AS_NEW": "core.filter.selection.saveAsNew"
      },
      "ADD_ALL": "core.filter.addAll",
      "CLOSE_AREA": "core.filter.closeArea",
      "EDIT_VIEW": "core.filter.editView",
      "EDITED": "core.filter.edited",
      "FALLBACK": "core.filter.fallback",
      "LABEL": "core.filter.label",
      "OPEN_AREA": "core.filter.openArea",
      "RADIUS": "core.filter.radius",
      "REMOVE": "core.filter.remove",
      "REMOVE_ALL": "core.filter.removeAll",
      "REMOVE_QUESTION": "core.filter.removeQuestion",
      "RENAME": "core.filter.rename",
      "RESET": "core.filter.reset",
      "RESET_ALL": "core.filter.resetAll",
      "SELECT": "core.filter.select"
    },
    "FORM": {
      "DATE": {
        "RELATIVE": {
          "SIGN": {
            "FUTURE": "core.form.date.relative.sign.future",
            "PAST": "core.form.date.relative.sign.past"
          },
          "SWITCH": {
            "USE_ABSOLUTE_INPUT": "core.form.date.relative.switch.useAbsoluteInput",
            "USE_RELATIVE_INPUT": "core.form.date.relative.switch.useRelativeInput"
          },
          "UNIT": {
            "DAYS": "core.form.date.relative.unit.days",
            "MONTHS": "core.form.date.relative.unit.months",
            "WEEKS": "core.form.date.relative.unit.weeks",
            "YEARS": "core.form.date.relative.unit.years"
          },
          "ORDER": "core.form.date.relative.order"
        }
      },
      "LIST": {
        "NO_ENTRIES": "core.form.list.noEntries"
      },
      "SUBMIT": {
        "ERROR": {
          "VALIDATION_ERROR": {
            "_": "core.form.submit.error.validationError",
            "NOT_FOUND": "core.form.submit.error.validationError.notFound"
          },
          "_": "core.form.submit.error"
        },
        "_": "core.form.submit",
        "SUCCESS": "core.form.submit.success"
      },
      "ADD": "core.form.add",
      "CANCEL": "core.form.cancel",
      "CONFIRM_DELETE": "core.form.confirmDelete",
      "DELETE": "core.form.delete",
      "DISCARD_CHANGES": "core.form.discardChanges",
      "DRAFT": "core.form.draft",
      "EDIT": "core.form.edit",
      "RESET": "core.form.reset",
      "SAVE": "core.form.save",
      "SAVE_AS": "core.form.saveAs",
      "SAVE_DRAFT": "core.form.saveDraft",
      "SAVE_FAILED": "core.form.saveFailed",
      "SAVE_SUCCESS": "core.form.saveSuccess"
    },
    "FORMAT": {
      "CUSTOM": {
        "PHONE": {
          "EXAMPLE": "core.format.custom.phone.example",
          "PATTERN": "core.format.custom.phone.pattern"
        }
      },
      "DATE": {
        "LONG": "core.format.date.long",
        "SHORT": "core.format.date.short"
      },
      "TIME": {
        "MEDIUM": "core.format.time.medium",
        "SHORT": "core.format.time.short"
      }
    },
    "I_FRAME_VIEW": {
      "TITLE": "core.iFrameView.title"
    },
    "LIST": {
      "ROW_STATE": {
        "ARCHIVED": "core.list.rowState.archived",
        "CREATED": "core.list.rowState.created",
        "DELETED": "core.list.rowState.deleted",
        "FILTER_REMOVED": "core.list.rowState.filterRemoved",
        "UPDATED": "core.list.rowState.updated"
      },
      "TABLE_STATE": {
        "INVALID_ORDER": "core.list.tableState.invalidOrder",
        "UNSEEN_CHANGES": "core.list.tableState.unseenChanges"
      },
      "UPLOAD": {
        "FILE": {
          "LIMIT_EXCEEDED": "core.list.upload.file.limitExceeded",
          "MISSING_PERMISSION": "core.list.upload.file.missingPermission",
          "PICK_FILE": "core.list.upload.file.pickFile",
          "PICK_IMAGE": "core.list.upload.file.pickImage",
          "REJECTED": "core.list.upload.file.rejected",
          "SIZE_EXCEEDED": "core.list.upload.file.sizeExceeded",
          "TAKE_PHOTO": "core.list.upload.file.takePhoto",
          "TYPE_NOT_SUPPORTED": "core.list.upload.file.typeNotSupported"
        },
        "ADD_FILE": "core.list.upload.addFile",
        "FAILURE": "core.list.upload.failure",
        "IN_PROGRESS": "core.list.upload.inProgress"
      },
      "ALL": "core.list.all",
      "FAVORITES": "core.list.favorites",
      "FILTER": "core.list.filter",
      "NO_ADDITIONAL_DATA": "core.list.noAdditionalData"
    },
    "LOGIN": {
      "ERROR": {
        "BACK_TO_LOGIN": "core.login.error.backToLogin",
        "BAD_CREDENTIALS": "core.login.error.badCredentials",
        "EMPTY_PASSWORD": "core.login.error.emptyPassword",
        "EXPIRED_PASSWORD": "core.login.error.expiredPassword",
        "INVALID_NEW_PASSWORD": "core.login.error.invalidNewPassword",
        "INVALID_REPEAT_PASSWORD": "core.login.error.invalidRepeatPassword",
        "NO_ROLES": "core.login.error.noRoles",
        "UNAUTHORIZED_TARGET_URL": "core.login.error.unauthorizedTargetUrl"
      },
      "ACTION": "core.login.action",
      "PASSWORD": "core.login.password",
      "TITLE": "core.login.title",
      "USERNAME": "core.login.username"
    },
    "MOBILE_APP_CONFIGURATION": {
      "SCANNER": {
        "CAMERA_PERMISSION_DENIED": {
          "CONTENT": "core.mobileAppConfiguration.scanner.cameraPermissionDenied.content",
          "TITLE": "core.mobileAppConfiguration.scanner.cameraPermissionDenied.title"
        },
        "CONFIGURATION_FAILED": {
          "CONTENT": "core.mobileAppConfiguration.scanner.configurationFailed.content",
          "TITLE": "core.mobileAppConfiguration.scanner.configurationFailed.title"
        },
        "CONTENT": "core.mobileAppConfiguration.scanner.content",
        "SCAN": "core.mobileAppConfiguration.scanner.scan"
      },
      "CONTENT": "core.mobileAppConfiguration.content",
      "TITLE": "core.mobileAppConfiguration.title"
    },
    "NOTIFICATIONS": {
      "NOTIFICATION_CENTER": {
        "OPEN": "core.notifications.notificationCenter.open",
        "TITLE": "core.notifications.notificationCenter.title"
      },
      "DELETE": "core.notifications.delete",
      "DELETE_ALL": "core.notifications.deleteAll",
      "MARK_ALL_AS_READ": "core.notifications.markAllAsRead",
      "MARK_AS_READ": "core.notifications.markAsRead",
      "NEW_NOTIFICATIONS": "core.notifications.newNotifications",
      "NO_NOTIFICATIONS": "core.notifications.noNotifications"
    },
    "PAGINATION": {
      "DISPLAYED_ROWS": "core.pagination.displayedRows",
      "FIRST_PAGE": "core.pagination.firstPage",
      "LAST_PAGE": "core.pagination.lastPage",
      "NEXT_PAGE": "core.pagination.nextPage",
      "PREVIOUS_PAGE": "core.pagination.previousPage",
      "ROWS_PER_PAGE": "core.pagination.rowsPerPage"
    },
    "PASSWORD_CHANGE": {
      "ABOUT_TO_EXPIRE": "core.passwordChange.aboutToExpire",
      "CURRENT_PASSWORD": "core.passwordChange.currentPassword",
      "EMPTY": "core.passwordChange.empty",
      "EXPIRED": "core.passwordChange.expired",
      "NEW_PASSWORD": "core.passwordChange.newPassword",
      "REPEAT_PASSWORD": "core.passwordChange.repeatPassword"
    },
    "ROLE_SELECTION": {
      "TITLE": "core.roleSelection.title"
    },
    "SETTINGS": {
      "UI": {
        "GENERAL": {
          "VIEW": "core.settings.ui.general.view"
        },
        "PERSISTENCE": {
          "DEFAULT_WORKSPACE": {
            "DESCRIPTION": "core.settings.ui.persistence.defaultWorkspace.description",
            "LABEL": "core.settings.ui.persistence.defaultWorkspace.label"
          },
          "PERSONAL_WORKSPACE": {
            "DESCRIPTION": "core.settings.ui.persistence.personalWorkspace.description",
            "LABEL": "core.settings.ui.persistence.personalWorkspace.label"
          },
          "VIEW": "core.settings.ui.persistence.view"
        }
      },
      "_": "core.settings"
    },
    "SUPPORT": {
      "CONFIGURATION": {
        "RECONFIGURE_BUTTON": "core.support.configuration.reconfigureButton",
        "TITLE": "core.support.configuration.title"
      },
      "GIT": {
        "BUILD_NUMBER": "core.support.git.buildNumber",
        "BUILD_TIME": "core.support.git.buildTime",
        "BUILD_VERSION": "core.support.git.buildVersion",
        "COMMIT_ID": "core.support.git.commitId"
      },
      "MOBILE": {
        "DEVICE": {
          "_": "core.support.mobile.device",
          "MANUFACTURER": "core.support.mobile.device.manufacturer",
          "MODEL": "core.support.mobile.device.model",
          "OS": "core.support.mobile.device.os",
          "OS_VERSION": "core.support.mobile.device.osVersion",
          "WEB_VIEW_VERSION": "core.support.mobile.device.webViewVersion"
        },
        "MISSING_URL": "core.support.mobile.missingUrl",
        "URL": "core.support.mobile.url"
      },
      "VERSION": {
        "API": {
          "_": "core.support.version.api",
          "APPLICATION": "core.support.version.api.application",
          "PRODUCT": "core.support.version.api.product"
        },
        "MISSING_INFO": "core.support.version.missingInfo",
        "MOBILE": "core.support.version.mobile"
      },
      "MENU_TITLE": "core.support.menuTitle",
      "MISSING_LOG": "core.support.missingLog",
      "SHOW_LOG": "core.support.showLog"
    },
    "SYSTEMMETRICS": {
      "TITLE": "core.systemmetrics.title"
    },
    "TAB_GROUP": {
      "CLOSE_TAB": "core.tabGroup.closeTab",
      "SHARE_LINK": "core.tabGroup.shareLink",
      "SPLIT_VIEW": "core.tabGroup.splitView"
    },
    "THEME": {
      "EDITOR": "core.theme.editor",
      "MENU_TITLE": "core.theme.menuTitle",
      "TOGGLE": "core.theme.toggle"
    },
    "UPLOAD": {
      "_": "core.upload",
      "BPMN": "core.upload.bpmn"
    },
    "WEATHER": {
      "PRECIPITATION_TYPE": {
        "MIX_RAIN_SNOW": "core.weather.precipitationType.mix_rain_snow",
        "NOTHING": "core.weather.precipitationType.nothing",
        "RAIN": "core.weather.precipitationType.rain",
        "SNOW": "core.weather.precipitationType.snow",
        "UNKNOWN": "core.weather.precipitationType.unknown",
        "WET_SNOW": "core.weather.precipitationType.wet_snow"
      },
      "BUTTON": "core.weather.button",
      "ERROR": "core.weather.error",
      "LOADING": "core.weather.loading"
    },
    "APPLY": "core.apply",
    "BACK": "core.back",
    "CANCEL": "core.cancel",
    "CLEAR": "core.clear",
    "CLOSE": "core.close",
    "CONFIRM": "core.confirm",
    "COPIED_TEXT_TO_CLIP_BOARD": "core.copiedTextToClipBoard",
    "CREATE": "core.create",
    "DATE": "core.date",
    "DELETE": "core.delete",
    "DISMISS": "core.dismiss",
    "DROP_FILE_OR_CLICK": "core.dropFileOrClick",
    "EDIT": "core.edit",
    "ENTRY": "core.entry",
    "EXPORT": "core.export",
    "GENERAL": "core.general",
    "IGNORE": "core.ignore",
    "IMPORT": "core.import",
    "INVALID_DATE": "core.invalidDate",
    "LANGUAGE": "core.language",
    "LOADING": "core.loading",
    "LOGOUT": "core.logout",
    "NO": "core.no",
    "OK": "core.ok",
    "OPTIONS": "core.options",
    "OVERRIDE": "core.override",
    "PRINT": "core.print",
    "PROCESSING": "core.processing",
    "REFRESH": "core.refresh",
    "RESET": "core.reset",
    "SAVE": "core.save",
    "SAVE_AND_COMPLETE": "core.saveAndComplete",
    "SEARCH": "core.search",
    "SEND": "core.send",
    "SKIP": "core.skip",
    "TIME": "core.time",
    "UNSUPPORTED_WIDGET": "core.unsupportedWidget",
    "UPDATE": "core.update",
    "VIEW": "core.view",
    "WARNING": "core.warning",
    "YES": "core.yes"
  },
  "ENTITY": {
    "FORM": {
      "DEFAULT": {
        "TITLE": "entity.form.default.title"
      }
    },
    "HISTORY": {
      "TITLE": "entity.history.title",
      "VALUEDELETED": "entity.history.valuedeleted"
    },
    "LIST": {
      "DEFAULT": {
        "TITLE": "entity.list.default.title"
      }
    }
  },
  "INCIDENT": {
    "ATTACHMENTS": {
      "TITLE": "incident.attachments.title"
    },
    "MESSAGES": {
      "TITLE": "incident.messages.title"
    },
    "DETAILS": "incident.details",
    "INCIDENT": "incident.incident",
    "TITLE": "incident.title"
  },
  "MAP": {
    "ACTION": {
      "ADDIMAGELAYER": {
        "HELP": "map.action.addimagelayer.help",
        "TITLE": "map.action.addimagelayer.title"
      },
      "ADDKMLLAYER": {
        "TITLE": "map.action.addkmllayer.title"
      },
      "COPYCOORDINATES": {
        "TITLE": "map.action.copycoordinates.title"
      },
      "COPYFEATURES": {
        "TITLE": "map.action.copyfeatures.title"
      },
      "COPYMAP": {
        "TITLE": "map.action.copymap.title"
      },
      "COPYPOSITION": {
        "TITLE": "map.action.copyposition.title"
      },
      "EDITSTRUCTURE": {
        "TITLE": "map.action.editstructure.title"
      },
      "FOLLOWFEATURE": {
        "TITLE": "map.action.followfeature.title"
      },
      "UNFOLLOWFEATURE": {
        "TITLE": "map.action.unfollowfeature.title"
      }
    },
    "BOOKMARK": {
      "ADD": {
        "DESCRIPTION": "map.bookmark.add.description",
        "NAME": "map.bookmark.add.name",
        "TITLE": "map.bookmark.add.title",
        "TOOLTIP": "map.bookmark.add.tooltip"
      },
      "REMOVE": {
        "MESSAGE": "map.bookmark.remove.message",
        "TITLE": "map.bookmark.remove.title",
        "TOOLTIP": "map.bookmark.remove.tooltip"
      },
      "AUSTRIA": "map.bookmark.austria",
      "GERMANY": "map.bookmark.germany",
      "LUXEMBOURG": "map.bookmark.luxembourg",
      "SWITZERLAND": "map.bookmark.switzerland"
    },
    "DRAW": {
      "CIRCLE": "map.draw.circle",
      "COLOR": "map.draw.color",
      "COORDINATES": "map.draw.coordinates",
      "DELETE": "map.draw.delete",
      "FILLCOLOR": "map.draw.fillcolor",
      "LINE": "map.draw.line",
      "LINESTYLE": "map.draw.linestyle",
      "LINEWIDTH": "map.draw.linewidth",
      "POINT": "map.draw.point",
      "POLYGON": "map.draw.polygon",
      "SAVE": "map.draw.save",
      "SYMBOL": "map.draw.symbol",
      "SYMBOLSIZE": "map.draw.symbolsize",
      "TEXT": "map.draw.text"
    },
    "LAYER": {
      "ADD": {
        "DESCRIPTION": "map.layer.add.description",
        "NAME": "map.layer.add.name"
      },
      "BACKGROUND": {
        "BING": {
          "AERIAL": "map.layer.background.bing.aerial",
          "AERIAL_WITH_LABELS": "map.layer.background.bing.aerialWithLabels",
          "ROAD": "map.layer.background.bing.road"
        },
        "_": "map.layer.background",
        "COUNTRIES": "map.layer.background.countries"
      }
    },
    "LAYERPANEL": {
      "DEFAULT": "map.layerpanel.default",
      "DOWN": "map.layerpanel.down",
      "FILTER": "map.layerpanel.filter",
      "OPACITY": "map.layerpanel.opacity",
      "SEARCH": "map.layerpanel.search",
      "UP": "map.layerpanel.up",
      "VISIBILITY": "map.layerpanel.visibility"
    },
    "MAPBOX": {
      "FULLSCREEN_CONTROL__ENTER": "map.mapbox.FullscreenControl_Enter",
      "FULLSCREEN_CONTROL__EXIT": "map.mapbox.FullscreenControl_Exit",
      "GEOLOCATE_CONTROL__FIND_MY_LOCATION": "map.mapbox.GeolocateControl_FindMyLocation",
      "GEOLOCATE_CONTROL__LOCATION_NOT_AVAILABLE": "map.mapbox.GeolocateControl_LocationNotAvailable",
      "NAVIGATION_CONTROL__RESET_BEARING": "map.mapbox.NavigationControl_ResetBearing",
      "NAVIGATION_CONTROL__ZOOM_IN": "map.mapbox.NavigationControl_ZoomIn",
      "NAVIGATION_CONTROL__ZOOM_OUT": "map.mapbox.NavigationControl_ZoomOut"
    },
    "FILTER": "map.filter",
    "LAYERS": "map.layers",
    "RESET": "map.reset",
    "SAVE": "map.save",
    "SEARCH": "map.search",
    "VIEW": "map.view"
  },
  "RAIL": {
    "LOAD": {
      "NHM_CARGO_CODE": "rail.load.nhmCargoCode",
      "RID_CLASSIFICATION": "rail.load.ridClassification"
    },
    "OPERATIONALCONSEQUENCES": {
      "COMMENT": "rail.operationalconsequences.comment",
      "DELAY": "rail.operationalconsequences.delay",
      "DISPATCH_MODE": "rail.operationalconsequences.dispatchMode",
      "LOCATION_FROM": "rail.operationalconsequences.locationFrom",
      "LOCATION_TO": "rail.operationalconsequences.locationTo",
      "OPERATIONALCONSEQUENCES": "rail.operationalconsequences.operationalconsequences",
      "OPERATOR": "rail.operationalconsequences.operator",
      "ORDERER": "rail.operationalconsequences.orderer",
      "TITLE": "rail.operationalconsequences.title",
      "TRACTIONEER": "rail.operationalconsequences.tractioneer",
      "TRAIN": "rail.operationalconsequences.train",
      "TURNAROUND": "rail.operationalconsequences.turnaround"
    },
    "TRAIN": {
      "BRAKE_WEIGHTS": {
        "_": "rail.train.brakeWeights",
        "SHORT": "rail.train.brakeWeights.short"
      },
      "EXISTING_BREAKING_PERCENTAGE": {
        "_": "rail.train.existingBreakingPercentage",
        "SHORT": "rail.train.existingBreakingPercentage.short"
      },
      "SUMS": {
        "ALL": {
          "SHORT": "rail.train.sums.all.short"
        },
        "LOADED": {
          "SHORT": "rail.train.sums.loaded.short"
        },
        "TRAIN": {
          "SHORT": "rail.train.sums.train.short"
        },
        "UNLOADED": {
          "SHORT": "rail.train.sums.unloaded.short"
        },
        "WAGON": {
          "SHORT": "rail.train.sums.wagon.short"
        },
        "_": "rail.train.sums"
      },
      "EXTRAORDINARY_DELIVERY": "rail.train.extraordinaryDelivery",
      "GENERAL_INFORMATION": "rail.train.generalInformation",
      "HANDBRAKE": "rail.train.handbrake",
      "LAST_VEHICLE": "rail.train.lastVehicle",
      "MINIMUM_TOP_SPEED": "rail.train.minimumTopSpeed",
      "OPERATOR_DESCRIPTION": "rail.train.operatorDescription",
      "RID_LOAD_PRESENT": "rail.train.ridLoadPresent",
      "TITLE": "rail.train.title",
      "TOTAL_LENGTH": "rail.train.totalLength",
      "TOTAL_WEIGHT": "rail.train.totalWeight",
      "TRACTION_VEHICLE": "rail.train.tractionVehicle",
      "WAGONS": "rail.train.wagons"
    },
    "TRAININFORMATION": {
      "TITLE": "rail.traininformation.title"
    },
    "WAGON": {
      "AXLES": {
        "_": "rail.wagon.axles",
        "SHORT": "rail.wagon.axles.short"
      },
      "BRAKE_POSITION": {
        "_": "rail.wagon.brakePosition",
        "SHORT": "rail.wagon.brakePosition.short"
      },
      "BRAKE_WEIGHT": {
        "_": "rail.wagon.brakeWeight",
        "SHORT": "rail.wagon.brakeWeight.short"
      },
      "CURB_WEIGHT": {
        "_": "rail.wagon.curbWeight",
        "SHORT": "rail.wagon.curbWeight.short"
      },
      "FORWARDING_LOCATION_NAME": {
        "_": "rail.wagon.forwardingLocationName",
        "SHORT": "rail.wagon.forwardingLocationName.short"
      },
      "HANDBRAKE_WEIGHT": {
        "_": "rail.wagon.handbrakeWeight",
        "SHORT": "rail.wagon.handbrakeWeight.short"
      },
      "LENGTH_OVER_BUFFER": {
        "_": "rail.wagon.lengthOverBuffer",
        "SHORT": "rail.wagon.lengthOverBuffer.short"
      },
      "LOADED": {
        "_": "rail.wagon.loaded",
        "SHORT": "rail.wagon.loaded.short"
      },
      "NUMBER_OF_TRANSPORT_SCHEDULE": {
        "_": "rail.wagon.numberOfTransportSchedule",
        "SHORT": "rail.wagon.numberOfTransportSchedule.short"
      },
      "RECEIVING_LOCATION_NAME": {
        "_": "rail.wagon.receivingLocationName",
        "SHORT": "rail.wagon.receivingLocationName.short"
      },
      "TOP_SPEED": {
        "_": "rail.wagon.topSpeed",
        "SHORT": "rail.wagon.topSpeed.short"
      },
      "TOTAL_WEIGHT_OF_WAGON": {
        "_": "rail.wagon.totalWeightOfWagon",
        "SHORT": "rail.wagon.totalWeightOfWagon.short"
      },
      "TRANSPORT_TYPE": {
        "_": "rail.wagon.transportType",
        "SHORT": "rail.wagon.transportType.short"
      },
      "WAGON_POSITION": {
        "_": "rail.wagon.wagonPosition",
        "SHORT": "rail.wagon.wagonPosition.short"
      },
      "WEIGHT_OF_CARGO": {
        "_": "rail.wagon.weightOfCargo",
        "SHORT": "rail.wagon.weightOfCargo.short"
      },
      "CONSIGNEE": "rail.wagon.consignee",
      "LOAD_INFO": "rail.wagon.loadInfo",
      "WAGON_NUMBER": "rail.wagon.wagonNumber"
    }
  },
  "REM": {
    "DATAPROTECTIONREGULATION": {
      "DOWNLOAD": {
        "BUTTON": "rem.dataprotectionregulation.download.button"
      },
      "SEARCH": {
        "FIRST_NAME": {
          "_": "rem.dataprotectionregulation.search.firstName",
          "HINT": "rem.dataprotectionregulation.search.firstName.hint"
        },
        "LAST_NAME": {
          "_": "rem.dataprotectionregulation.search.lastName",
          "HINT": "rem.dataprotectionregulation.search.lastName.hint"
        },
        "TITLE": "rem.dataprotectionregulation.search.title"
      },
      "TITLE": "rem.dataprotectionregulation.title"
    },
    "INCIDENT": {
      "FILTER": {
        "LOCATION": {
          "FROM": "rem.incident.filter.location.from"
        },
        "OCCURRENCE_DATE": {
          "FROM": "rem.incident.filter.occurrenceDate.from",
          "TO": "rem.incident.filter.occurrenceDate.to"
        },
        "CATEGORIES": "rem.incident.filter.categories",
        "NUMBER": "rem.incident.filter.number"
      }
    },
    "MAP": {
      "INCIDENT": {
        "PREVIEW": {
          "LOCATION": {
            "FROM": "rem.map.incident.preview.location.from",
            "TO": "rem.map.incident.preview.location.to"
          },
          "OCCURRENCE": {
            "DATE": "rem.map.incident.preview.occurrence.date"
          },
          "REPORT": {
            "DATE": "rem.map.incident.preview.report.date"
          },
          "CATEGORIES": "rem.map.incident.preview.categories",
          "DELAYCODE": "rem.map.incident.preview.delaycode",
          "DESCRIPTION": "rem.map.incident.preview.description",
          "KILOMETRAGE": "rem.map.incident.preview.kilometrage",
          "LINE": "rem.map.incident.preview.line",
          "NUMBER": "rem.map.incident.preview.number",
          "OPEN_IN_E_M_C": "rem.map.incident.preview.openInEMC",
          "PRIORITY": "rem.map.incident.preview.priority",
          "STATUS": "rem.map.incident.preview.status",
          "TITLE": "rem.map.incident.preview.title",
          "TRIP_NUMBER": "rem.map.incident.preview.tripNumber"
        }
      }
    },
    "SHIFTTAKEOVER": {
      "PROTOCOL": {
        "DATE_FROM": {
          "_": "rem.shifttakeover.protocol.dateFrom",
          "HINT": "rem.shifttakeover.protocol.dateFrom.hint"
        },
        "DATE_TO": {
          "_": "rem.shifttakeover.protocol.dateTo",
          "HINT": "rem.shifttakeover.protocol.dateTo.hint"
        },
        "PERIOD_SELECTION": "rem.shifttakeover.protocol.periodSelection",
        "TITLE": "rem.shifttakeover.protocol.title"
      }
    }
  },
  "REM_WEB_VIEWER_EVENT_NOTIFICATION": {
    "DELAY": "rem_web_viewer_event_notification.delay",
    "ST": "rem_web_viewer_event_notification.st",
    "WEB": "rem_web_viewer_event_notification.web"
  },
  "ROOT": {
    "TITLE": "root.title"
  },
  "STANDBY": {
    "DELETE": {
      "DIALOG": {
        "TITLE": "standby.delete.dialog.title"
      }
    },
    "EDIT": {
      "DIALOG": {
        "TITLE": "standby.edit.dialog.title"
      },
      "OVERLAPPING": "standby.edit.OVERLAPPING",
      "VALIDATION": "standby.edit.VALIDATION"
    },
    "MENU": {
      "SECTION": {
        "TITLE": "standby.menu.section.title"
      }
    },
    "NEW": {
      "DIALOG": {
        "TITLE": "standby.new.dialog.title"
      },
      "OVERLAPPING": "standby.new.OVERLAPPING",
      "VALIDATION": "standby.new.VALIDATION"
    },
    "PHONE_NUMBER": {
      "_": "standby.phoneNumber",
      "POSSIBLE_FORMATS": "standby.phoneNumber.possibleFormats"
    },
    "PHONE_STANDBY": {
      "_": "standby.phoneStandby",
      "SHORT": "standby.phoneStandby.short"
    },
    "RECURRING": {
      "MENU": {
        "SECTION": {
          "TITLE": "standby.recurring.menu.section.title"
        }
      }
    },
    "STANDBY_SINCE": {
      "_": "standby.standbySince",
      "SHORT": "standby.standbySince.short"
    },
    "FRIDAY": "standby.FRIDAY",
    "FUNCTION": "standby.FUNCTION",
    "LOCATION": "standby.LOCATION",
    "MONDAY": "standby.MONDAY",
    "ORGANISATIONAL_UNIT": "standby.ORGANISATIONAL_UNIT",
    "PHONE": "standby.PHONE",
    "SATURDAY": "standby.SATURDAY",
    "SUNDAY": "standby.SUNDAY",
    "THURSDAY": "standby.THURSDAY",
    "TUESDAY": "standby.TUESDAY",
    "WEDNESDAY": "standby.WEDNESDAY",
    "ACTIONS": "standby.actions",
    "ACTIVE": "standby.active",
    "ADD": "standby.add",
    "CLOSE": "standby.close",
    "EFFECTIVE_BANK_HOLIDAY": "standby.effectiveBankHoliday",
    "END_DATE": "standby.endDate",
    "END_TIME": "standby.endTime",
    "INACTIVE": "standby.inactive",
    "INTERVAL": "standby.interval",
    "MO_TO_FR": "standby.moToFr",
    "MO_TO_SUN": "standby.moToSun",
    "NAME": "standby.name",
    "NO_RECURRING_STANDBYS": "standby.noRecurringStandbys",
    "PDF": "standby.pdf",
    "PDF_FOR_EMPLOYEE": "standby.pdfForEmployee",
    "PDF_GROUPED": "standby.pdfGrouped",
    "PERSON": "standby.person",
    "PRIORITY": "standby.priority",
    "SELECT_PERSON": "standby.selectPerson",
    "SELECT_PRIORITY": "standby.selectPriority",
    "STANDBY_TEAM": "standby.standbyTeam",
    "START_DATE": "standby.startDate",
    "START_TIME": "standby.startTime",
    "UPDATE": "standby.update",
    "WEEKDAY": "standby.weekday"
  },
  "WORKFLOW": {
    "PROCESS_DEFINITION": {
      "LABEL": {
        "ENTITY_TYPE": "workflow.process_definition.label.entityType",
        "KEY": "workflow.process_definition.label.key",
        "NAME": "workflow.process_definition.label.name",
        "PROCESS_MODEL": "workflow.process_definition.label.processModel",
        "SAVE": "workflow.process_definition.label.save",
        "TITLE": "workflow.process_definition.label.title"
      },
      "LIST": {
        "CREATE_NEW": "workflow.process_definition.list.create_new",
        "TITLE": "workflow.process_definition.list.title"
      }
    },
    "TASK_INSTANCE": {
      "LABEL": {
        "CREATED_AT": "workflow.task_instance.label.createdAt",
        "ID": "workflow.task_instance.label.id",
        "NAME": "workflow.task_instance.label.name",
        "TITLE": "workflow.task_instance.label.title"
      },
      "LIST": {
        "CREATE_NEW": "workflow.task_instance.list.create_new"
      },
      "SAVE_AND": "workflow.task_instance.save_and",
      "SAVE_AND_CONTINUE": "workflow.task_instance.save_and_continue",
      "SAVE_AND_FINISH": "workflow.task_instance.save_and_finish"
    }
  }
}