/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";


const styles = (theme: IcmMuiTheme) => createStyles({
  label: {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: 16,
  },
  dialogPaper: {
    height: 800,
    width: 1200,
    maxWidth: "100%",
  },
  dialogContent: {
    height: "100%",
    width: "100%",
  },
  dropZoneRoot: {
    minHeight: 160,
    "& .MuiDropzonePreviewList-root": {
      justifyContent: "center",
      marginTop: 10,
      marginBottom: 20,
    },
  },
});

export default styles;
