/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  BaseViewModelData,
  TabbedPerspective,
  TabGroup,
  TabGroupTab,
  ViewModel,
} from "../types";

export function findTabGroup(
  perspective?: TabbedPerspective,
  tabGroupId?: string
): TabGroup | undefined {
  return perspective?.tabGroups.find(g => g.id === tabGroupId);
}

export function tabGroupIndex(
  perspective: TabbedPerspective,
  tabGroup: TabGroup
): number {
  return perspective.tabGroups.indexOf(tabGroup);
}

/**
 * A tab group can be split if it is not empty.
 *
 * @param group
 */
export function canSplit(group: TabGroup): boolean {
  return group.tabs.length > 1;
}

/**
 * A group can be closed if there is one more group in the perspective and
 * the given group is empty.
 *
 * @param perspective
 * @param group
 */
export function canCloseTabGroup(
  perspective: TabbedPerspective,
  group: TabGroup
) {
  if (perspective.tabGroups.length > 1) {
    return isEmptyGroup(group);
  }
  return false;
}

export function isEmptyGroup(group: TabGroup): boolean {
  return group.tabs.length < 1;
}

export function findTabInGroup(group: TabGroup, tabId: string): TabGroupTab | undefined {
  return group.tabs.find(v => v.id === tabId);
}

export function findExistingViewModel(
  perspective: TabbedPerspective,
  viewModel: ViewModel<BaseViewModelData>
): ViewModel<BaseViewModelData> | undefined {
  return perspective.tabGroups.flatMap(tg => tg.tabs).find(t => t.viewModel.uniqueHash === viewModel.uniqueHash)?.viewModel as ViewModel<BaseViewModelData>;
}
