/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DEFAULT_IMAGE_PATHS} from "./ThemeDefaults";

type Color = string;

export type ConfiguredColor = PaletteColor | CustomColor;

export const PaletteColors = [
  "background",
  "primary",
  "secondary",
  "error",
  "warning",
  "info",
  "success",
] as const;

export const PaletteColorStrings = PaletteColors as unknown as string[];

export type PaletteColor = typeof PaletteColors[number];

export const CustomColors = [
  "custom1",
  "custom2",
  "custom3",
  "custom4",
  "custom5",
] as const;

export const CustomColorStrings = CustomColors as unknown as string[];

export type CustomColor = typeof CustomColors[number];

export type IcmTheme = {
  palette: IcmPalette
  getColor: (componentId: ComponentId) => IcmColorScheme
  images: IcmImagePaths
}

export type IcmPalette = Record<PaletteColor, IcmColorScheme> & Partial<Record<CustomColor, IcmColorScheme>>;
export type IcmColorScheme = Required<ConfiguredColorScheme>;

export type IcmThemeState = {
  themes: Partial<Record<string, IcmTheme>>
  selectedTheme: string
}

// Configuration

export type IcmThemeConfiguration = {
  themes: Partial<Record<string, ConfiguredTheme>>
  defaultTheme: string
};

export type ConfiguredTheme = {
  palette: ConfiguredPalette
  images: Partial<IcmImagePaths>
  overrides?: Partial<Record<ComponentId, keyof ConfiguredPalette>>
}

export type ConfiguredPalette = Record<PaletteColor, ConfiguredColorScheme> & Partial<Record<CustomColor, ConfiguredColorScheme>>;

export type ConfiguredColorScheme = {
  main: Color
  contrast: Color
  light?: Color
  dark?: Color
  textPrimary?: Color
  textSecondary?: Color
  disabled?: Color
  divider?: Color
}

export type ComponentId =
  | "LoginHeader"
  | "ApplicationHeader"
  | "NavigationMenu"
  | "TabBar";

export type IcmImage = keyof typeof DEFAULT_IMAGE_PATHS;

export type IcmImagePaths = typeof DEFAULT_IMAGE_PATHS;
