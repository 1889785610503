/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState} from "../types";


export type SelectTabOptions = {

  /**
   * An identifying string for the tab. This id is used
   * to determine if a tab exists.
   */
  tabId: string
}


/**
 * Action that is used to open an existing tab when the user clicks on it.
 * It is not used to bring a tab to the foreground.
 *
 * @param state
 * @param action
 * @constructor
 */
export const SelectTab = (state: IUiState, action: PayloadAction<SelectTabOptions>) => {
  const perspective = state.perspective;
  if (perspective?.perspectiveType === "TABBED") {
    const {tabId} = action.payload;

    const existingTab = perspective.tabGroups.map(tg => {
      const tabIndex = tg.tabs.findIndex(t => t.id === tabId);
      return tabIndex >= 0 ?  {tg, tabIndex} : undefined;
    }).find(r => !!r);

    if (existingTab) {
      perspective.currentTabGroupId = existingTab.tg.id;
      existingTab.tg.selectedTabIndex = existingTab.tabIndex;
    }
  } else {
    console.warn("Can't select tab in non-tabbed perspective");
  }
};
