/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isString, uniqBy} from "lodash-es";
import {useCallback, useState} from "react";

import {Address, canonicalValue, createAddress, isAddress, isContained, isDifferentAddress, isMatchingAddress, isSameAddress} from "./Address";
import {AddressFieldController} from "./AddressFieldController";
import {AddressFieldControllerProps} from "./AddressFieldControllerProps";
import {AddressValidators} from "./AddressValidators";


export const useAddressFieldController = (props: AddressFieldControllerProps): AddressFieldController => {
  const {
    addressSource,
    addressSelectable = true,
    addressCreatable = true,
    addressType,
  } = props;

  const addressValidator = AddressValidators.getMessageAddressValidatorMapping(addressType);

  const defaultSelected = uniqBy(addressSource.getDefaultSelectedAddresses(), a => canonicalValue(a));
  const defaultAvailable = uniqBy(addressSource.getAvailableAddresses().concat(defaultSelected), a => canonicalValue(a));

  const [allAddresses, setAllAddresses] = useState<Address[]>([...defaultAvailable]);
  const [selectedAddresses, setSelectedAddresses] = useState<Address[]>([...defaultSelected]);

  const isSelectedAddress = useCallback((a: string | Address): boolean => {
    return isContained(selectedAddresses, a);
  }, [selectedAddresses]);

  const tryCreateAddress = useCallback((value: string) => {
    if (addressCreatable) {
      return createAddress(value);
    }
    return undefined;
  }, [addressCreatable]);

  const getExistingAddress = useCallback((value: string | Address) => {
    return allAddresses.find(r => isSameAddress(r, value));
  }, [allAddresses]);

  const findMatchingAddresses = useCallback((value: string) => {
    return allAddresses.filter(r => isMatchingAddress(r, value));
  }, [allAddresses]);

  const selectAddresses = useCallback((newValue: (string | Address)[]) => {
    if (addressSelectable) {
      const result = newValue.map(v => {
        if (isString(v)) {
          const existingAddress = getExistingAddress(v);
          return existingAddress ?? tryCreateAddress(v);
        } else {
          return v;
        }
      }).filter(isAddress);
      setSelectedAddresses(uniqBy(result, address => address.addressValue));
      setAllAddresses(uniqBy(allAddresses.concat(result), address => address.addressValue));
    }
  }, [addressSelectable, getExistingAddress, allAddresses, tryCreateAddress]);


  const selectOrAdd = useCallback((value: string) => {
    if (!addressSelectable) {
      return;
    }
    if (value.trim().length === 0) {
      return;
    }
    const isSelected = isSelectedAddress(value);
    const existingAddress = getExistingAddress(value);
    if (!isSelected && !existingAddress) {
      const newAddress = tryCreateAddress(value);
      if (newAddress) {
        setAllAddresses(allAddresses.concat(newAddress));
        setSelectedAddresses(selectedAddresses.concat(newAddress));
      }
    } else if (!isSelected && existingAddress) {
      setSelectedAddresses(selectedAddresses.concat(existingAddress));
    }
  }, [
    addressSelectable,
    isSelectedAddress,
    getExistingAddress,
    setSelectedAddresses,
    setAllAddresses,
    selectedAddresses,
    allAddresses,
    tryCreateAddress,
  ]);

  const deleteAddress = useCallback((value: Address) => {
    setSelectedAddresses(selectedAddresses.filter(r => isDifferentAddress(r, value)));
    setAllAddresses(allAddresses.filter(r => isDifferentAddress(r, value)));
  }, [selectedAddresses, setSelectedAddresses, setAllAddresses, allAddresses]);

  return {
    canAddAddress(): boolean {
      return addressCreatable;
    },
    canSelectAddress(): boolean {
      return addressSelectable;
    },
    getAvailableAddresses(): Readonly<Address[]> {
      return allAddresses;
    },
    getSelectedAddresses(): Readonly<Address[]> {
      return selectedAddresses;
    },
    isSameAddress,
    isValidAddress: addressValidator,
    deleteAddress,
    findMatchingAddresses,
    selectAddresses,
    selectOrAdd,
  };
};
