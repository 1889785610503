/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createStyles} from "@mui/styles";

import {IcmMuiTheme} from "../../../theme";

export const styles = (theme: IcmMuiTheme) => createStyles({
  groupHeader: {
    backgroundColor: "rgba(0,0,0,.05)",
    cursor: "default !important",
    minHeight: "12px !important",
    "& .Mui-expanded": {
      marginTop: "12px",
      marginBottom: "12px",
    },
  },
  displayNone: {
    display: "none",
  },
  groupAccordionContainer: {
    height: "100%",
  },
  groupIcon: {
    marginRight: theme.spacing(2),
  },
  groupBadge: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    borderRadius: "2em",
  },
});
