/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
class ScrollContainerService {
  public static INSTANCE = new ScrollContainerService();
  private element: HTMLElement | null;

  public static getInstance() {
    return ScrollContainerService.INSTANCE;
  }

  public get(): HTMLElement | null {
    return this.element;
  }

  public set(element: HTMLElement | null): void {
    console.log("Setting scroll container to ", element);
    this.element = element;
  }
}

const scrollContainerService = ScrollContainerService.getInstance();

export {scrollContainerService};
