/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  ActionRunners,
  CoreApi,
  mapToActions,
  mapToRowActions,
  useActionHandler,
  useService,
  ViewContext,
  ActionContextValue,
  mapToContextMenu,
  ContextMenu,
  useConnectionState,
} from "@icm/core-common";
import React, {useMemo} from "react";

import {ObjectWithUpdateInformation} from "../../util";
import {isVisibleRow} from "./ListUtils";

export const useContextMenu = <T extends object>(menu?: CoreApi.Menu): ContextMenu | undefined => {
  const securityService = useService("SECURITY");
  const actionHandler = useActionHandler();
  const viewContext = React.useContext(ViewContext);
  return useMemo(() => {
    if (menu) {
      return mapToContextMenu<ObjectWithUpdateInformation<T>>(
        securityService,
        menu,
        actionHandler,
        row => row.object,
        viewContext,
        row => isVisibleRow(row, securityService)
      );
    }
    return undefined;
  },
  [actionHandler, menu, securityService, viewContext]);
};

export const useListActions = <T extends object>(
  rowActions: CoreApi.RowActions[],
  additionalActionRunners: ActionRunners | undefined,
  actionContext?: ActionContextValue) => {
  const securityService = useService("SECURITY");
  const actionHandler = useActionHandler(additionalActionRunners);
  const viewContext = React.useContext(ViewContext);
  const connected = useConnectionState();
  return useMemo(() => mapToRowActions<ObjectWithUpdateInformation<T>>(
    {
      securityService,
      actions: mapToActions(rowActions),
      actionHandler,
      getRowValue: row => row.object,
      viewContext,
      actionContext,
      isVisible: row => isVisibleRow(row, securityService),
      isEnabled: () => connected,
    }
  ),
  [securityService, rowActions, actionHandler, viewContext, actionContext, connected]);
};

export const useEntityAction = <T extends object>(onClickActions: CoreApi.OnClickAction[], additionalActionRunners: ActionRunners | undefined) => {
  const securityService = useService("SECURITY");
  const actionHandler = useActionHandler(additionalActionRunners);
  const connected = useConnectionState();
  const viewContext = React.useContext(ViewContext);
  const actions = useMemo(() => mapToRowActions<ObjectWithUpdateInformation<T>>(
    {
      securityService: securityService,
      actions: onClickActions,
      actionHandler: actionHandler,
      getRowValue: row => row.object,
      viewContext: viewContext,
      isVisible: row => isVisibleRow(row, securityService),
      isEnabled: () => connected,
    }
  ),
  [securityService, connected, onClickActions, actionHandler, viewContext]);

  return actions[0] ?? null;
};
