/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService} from "@icm/core-common";
import {DetailPanelRenderer, DetailPanelRendererFactory, ListColumnRecord} from "@icm/core-web";
import {Entity, EntityLoader, isEntity} from "@icm/entity-common";
import * as React from "react";

import {WebEntityListComponent} from "./WebEntityListComponent";

export const SublistDetailRendererFactory: DetailPanelRendererFactory = {

  createRenderer<T>(listElements: ListColumnRecord): DetailPanelRenderer<T> | undefined {
    const sublistElement = listElements.SUBLIST[0];
    if (!sublistElement) {
      return undefined;
    }

    const {valueBinding, entityType, listVariant} = sublistElement;
    if (valueBinding && entityType) {
      return {
        hasDetails: (row: T) => {
          if (!row["id"]) {
            return false;
          }
          return sublistElement.contextVisible ? ExpressionEvaluationService.evaluateRule(sublistElement.contextVisible, row) : false;
        },
        DetailPanel: ({row}: {row: T}) => {
          if (!row["id"]) {
            return <></>;
          }
          const effectiveEntityType = entityType?.includes("=>") ? ExpressionEvaluationService.evaluate(entityType, row) ?? entityType : entityType;
          const loader: EntityLoader = {
            load: () => {
              const value = valueBinding.includes("=>") ? ExpressionEvaluationService.evaluate(valueBinding, row) : ExpressionEvaluationService.get(row, valueBinding);
              if (value && Array.isArray(value.ids) && value.data) {
                return toValues(value.ids, value.data);
              } else if (value && Array.isArray(value)) {
                return value;
              }
              return [];
            },
          };
          return (
            <WebEntityListComponent entityType={effectiveEntityType}
                                    listVariant={listVariant}
                                    loader={loader}
                                    embedded={true}
                                    contextEntityId={row["id"]}
            />
          );
        },
      };
    }
    return undefined;
  },
};


function toValues(ids: unknown[], entities: { }): Entity[] {
  return ids
    .filter(id => typeof id === "string")
    .map(id => id as string)
    .map(id => entities[id])
    .filter(e => isEntity(e));
}
