/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {TabbedPerspective, TabGroup, ViewActionsProvider} from "@icm/core-common";
import {Box} from "@mui/system";
import {Allotment} from "allotment";
import * as React from "react";

import {createStyles} from "../../theme";
import {SideBarContainer} from "../sidebar";
import {TabGroupComponent} from "../tabgroup";
import {useTabbedPerspectiveSizes} from "./useTabbedPerspectiveSizes";


export type PerspectiveProps = {
  perspective: TabbedPerspective
}

const styles = createStyles({

  mainArea: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "stretch",
  },

  tabContainer: {
    flexGrow: 4,
    overflow: "hidden",
  },

  sidebarContainer: {
    flexGrow: 1,
    zIndex: 0,
  },

});

function isTabGroupVisible(tabGroups: TabGroup[], tabGroup: TabGroup): boolean {
  let idForTabGroupWithPrintRequest = undefined;

  for (const element of tabGroups) {
    for (const t of element.tabs) {
      if (t.viewModel.viewModelData.printRequested) {
        idForTabGroupWithPrintRequest = element.id;
        break;
      }
    }
    if (idForTabGroupWithPrintRequest) {
      break;
    }
  }

  return !idForTabGroupWithPrintRequest || tabGroup.id === idForTabGroupWithPrintRequest;
}


/**
 * Renders a perspective containing a sidebar, the main area and the panel.
 *
 * @param perspective the configuration of the perspective
 * @constructor
 */
export const TabbedPerspectiveComponent = ({perspective}: PerspectiveProps) => {
  const {currentSizes, setCurrentSizes} = useTabbedPerspectiveSizes();
  return (
    <ViewActionsProvider>
      <Box sx={styles.mainArea}>
        <Box sx={styles.tabContainer}>
          <Allotment onChange={setCurrentSizes} defaultSizes={currentSizes}>
            {perspective.sideBarId && (
              <Allotment.Pane key="sidebar" maxSize={300} minSize={150}>
                <Box sx={styles.sidebarContainer}><SideBarContainer sideBarId={perspective.sideBarId} /></Box>
              </Allotment.Pane>
            )}
            {perspective.tabGroups.map((tabGroup: TabGroup) => (
              <Allotment.Pane key={tabGroup.id} visible={isTabGroupVisible(perspective.tabGroups, tabGroup)}>
                <TabGroupComponent key={tabGroup.id} tabGroup={tabGroup} />
              </Allotment.Pane>
            ))}
          </Allotment>
        </Box>
      </Box>
    </ViewActionsProvider>
  );
};
