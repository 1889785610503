/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ExpressionEvaluationService,
  feedbackService,
  FilterConditionGroup,
  FilterConditionItem, FilterConfiguration,
  formatService,
  ListConfiguration,
  ListFilter,
  ListKeyValueColumn,
  MessageKey,
  messages,
  RowElement,
  RowTextElement,
} from "@icm/core-common";
import {ExportToCsv} from "export-to-csv";
import moment from "moment";


import {Entity, entityDataService} from "../..";

type CsvHeaders = {
  listHeaders: string[],
  filterHeaders: string[],
  keyValueHeaders: string[],
}

const labels = (additionalLabels?: FilterConfiguration[]): Map<string, string> => {
  const labelsMap = new Map<string, string>();
  if (additionalLabels) {
    additionalLabels[0].filterOptions?.forEach(h => {
      const additionalLabel = JSON.parse(h);
      labelsMap.set(additionalLabel.id, additionalLabel.label);
    });
  }
  return labelsMap;
};

class EntityExportService {

  public static INSTANCE = new EntityExportService();
  public generateCsvExport(entityType: string,
                           entities: Entity[],
                           filterIndicator: () => string,
                           filter?: ListFilter,
                           additionalLabels?: FilterConfiguration[]): Promise<void> {
    const exportFileName = `export_${entityType}_${moment().format("YYYY-MM-DDTHH-mm-ss")}`;

    return entityDataService.getListConfiguration(entityType)
      .then(listConfiguration => {
        const csvExportHeader = this.getCsvExportHeader(listConfiguration, entities, filter);
        const csvExportValues = this.getCsvExportValues(listConfiguration, entities, csvExportHeader);
        this.renameFilteredColumns(csvExportHeader, additionalLabels, filterIndicator, csvExportValues);
        const options = {
          filename: exportFileName,
          fieldSeparator: ";",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          headers: [...csvExportHeader.listHeaders, ...csvExportHeader.filterHeaders],
        };

        if (csvExportValues.length !== 0) {
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(csvExportValues);
        } else {
          feedbackService.open({
            key: "CSV_DOWNLOAD_IS_EMPTY",
            title: messages.get(MessageKey.CORE.DOWNLOAD.CSV_IS_EMPTY)!,
            variant: "WARNING",
            duration: "SHORT",
          });
        }
      });
  }

  // The world "Filter: " is put before the header of each filtered column.
  // There might be duplicate columns due to the filtering process. Since the CSV can only be successfully manipulated
  // after all data has been gathered, those columns are filtered out here.
  // listHeaders are those headers that are displayed by default. Other headers are filterHeaders.


  private renameFilteredColumns(headers: CsvHeaders,
                                additionalLabels: FilterConfiguration[] | undefined,
                                filterIndicator: () => string, // The word "Filter".
                                values: string[][]) {
    if (headers.keyValueHeaders.length === 0) {
      const filterHeaderLabels = labels(additionalLabels); // Generates a mapping of dynamicAttribute... -> label
      headers.filterHeaders.forEach(id => {
        const modifiedDynamicAttributeIdentifier = id.split(".dynamicAttributes")[0]; // removes the second 'dynamicAttributes' in nested data structure
        if (filterHeaderLabels.has(id) || filterHeaderLabels.has(modifiedDynamicAttributeIdentifier)) {
          const filterHeaderId = headers.filterHeaders.indexOf(id);
          // The following branch is executed if a default column is filtered. Therefore, a header already exists.
          if (headers.listHeaders.includes(filterHeaderLabels.get(id)!)) {
            const listHeaderId = headers.listHeaders.indexOf(filterHeaderLabels.get(id)!);
            headers.listHeaders.splice(listHeaderId, 1, `${filterIndicator}: ${filterHeaderLabels.get(id)!}`);
            values.forEach((row, index) => {
              values[index].splice(row.length - 1 + filterHeaderId, 1);
            });
          } else {
            headers.filterHeaders.splice(filterHeaderId, 1, `${filterIndicator}: ${filterHeaderLabels.get(modifiedDynamicAttributeIdentifier)!}`);
          }
        }
      });
      this.removeDuplicateColumns(headers, values, filterIndicator());
    } else {
      headers.listHeaders.forEach((header, i) => {
        // The criterion for the RegExp is the colon after the word "Filter: ".
        const filteredHeader: string = "^\\w*:\\s" + header;
        const filteredHeaderRegExp = new RegExp(filteredHeader);
        const ifFilterHeaderExists = headers.listHeaders.some((e => filteredHeaderRegExp.test(e)));
        if (ifFilterHeaderExists) {
          const indexOfFilterHeader: number = headers.listHeaders.findIndex(v => filteredHeaderRegExp.test(v));
          headers.listHeaders[i] = headers.listHeaders[indexOfFilterHeader];
          headers.listHeaders.splice(indexOfFilterHeader, 1);
          values.forEach((row, index) => {
            values[index].splice(indexOfFilterHeader, 1);
          });
        }
      });
    }
  }

  // Removing duplicate columns. Only used in Web Pilot
  private removeDuplicateColumns(headers: CsvHeaders, values: string[][], filterPrefix: string) {
    headers.filterHeaders.forEach((header, index) => {
      if (!header.startsWith(filterPrefix)) {
        values.forEach(row => {
          row.splice(headers.listHeaders.length + index, 1);
        });
      }
    });
    headers.filterHeaders = headers.filterHeaders.filter(item => item.startsWith(filterPrefix));
  }

  private getCsvExportHeader(listConfiguration: ListConfiguration,
                             entities: Entity[],
                             filter: ListFilter | undefined): CsvHeaders {
    // iterate over entities
    //    verify if headers contains header
    //    if not in the headers -> put header

    // dataHeaders stores those headers that are already present in the listView (as determined by list-default.xml). keyValueHeaders are those that
    // are defined additionally in DMC and disseminated via KeyValueColumn. That are all headers are visible in the dropdown of every incident in the listview.
    const dataHeaders: string[] = listConfiguration.rowConfiguration!.elements!.filter(item => item.type === "TEXT").map((item: RowTextElement) => item.label!);
    const keyValueHeaders = new Set<string>();
    const filterHeaders = new Set<string>();
    // The headers from the KeyValueColumn are extracted. These headers can be identified by the type KEY_VALUE (as opposed to ICON and TEXT).
    const keyValueColumns = listConfiguration.rowConfiguration!.elements!.filter(item => item.type === "KEY_VALUE");
    entities!.forEach(entity => {
      // The KeyValueColumn is accessed via arrow functions defined in list-default.xml. See also _02_02_04_x_list.adoc.
      keyValueColumns.forEach((column: ListKeyValueColumn) => {
        const keys = ExpressionEvaluationService.get(entity, column.keysBinding!) || [];
        for (const key of keys) {
          keyValueHeaders.add(ExpressionEvaluationService.evaluate(column.keyDisplay!, entity, key));
        }
      });
      // FILTERS_LABELS contains the labels for all information that is additionally filtered for (like Incident Status) that does appear neither
      // in the regular ListView nor in the KeyValueColumn.
      if (entity?.dynamicAttributes?.hasOwnProperty("FILTERS_LABELS")) {
        Object.entries(entity?.dynamicAttributes?.FILTERS_LABELS?.value).forEach(([, value]) => {
          if (typeof value === "string") {
            keyValueHeaders.add(value);
          }
        });
      } else {
        const filtersPresent = (filter?.filter as FilterConditionGroup);
        filtersPresent.filterConditions?.forEach(f => {
          const p = (f as FilterConditionItem);
          if (p.property !== undefined) {
            filterHeaders.add(p.property);
          }
        });
      }
    });
    return {
      listHeaders: [...dataHeaders, ...keyValueHeaders],
      // The keyValueHeaders are necessary to distinguish between use with Web Pilot or use with REM/ICM.
      keyValueHeaders: [...keyValueHeaders],
      filterHeaders: [...filterHeaders],
    };
  }

  // The contents (values) for the CSV columns is extracted from the displayed incidents.
  private getCsvExportValues(listConfiguration: ListConfiguration, entities: Entity[], headers: CsvHeaders): string[][] {
    return entities!.map(entity => {
      const result = Array(headers.listHeaders.length).fill("", 0);
      // Extraction of values from columns defined in list-default.xml, decorated with the type "TEXT".
      listConfiguration.rowConfiguration!.elements!.forEach((rowElement: RowElement, _idx) => {
        if (rowElement.type === "TEXT") {
          const rowTextElement: RowTextElement = rowElement as RowTextElement;
          const headerIndex = headers.listHeaders.indexOf(rowTextElement.label!);
          const value = this.renderValue(entity, rowTextElement.valueBinding!, rowTextElement.valueDisplay);
          if (value) {
            result[headerIndex] = formatService.format(value, rowTextElement.format);
          }
          // Extraction of values from columns defined in list-default.xml, decorated with the type "KEY_VALUE".
        } else if (rowElement.type === "KEY_VALUE") {
          const listColumn: ListKeyValueColumn = rowElement as ListKeyValueColumn;
          const keys = ExpressionEvaluationService.get(entity, listColumn.keysBinding!) || [];
          for (const key of keys) {
            const header = ExpressionEvaluationService.evaluate(listColumn.keyDisplay!, entity, key);
            const headerIndex = headers.listHeaders.indexOf(header);
            const value = ExpressionEvaluationService.evaluate(listColumn.valueDisplay!, entity, key);
            const format = ExpressionEvaluationService.evaluate(listColumn.valueFormatDisplay!, entity, key);
            if (value) {
              result[headerIndex] = this.formatValue(result[headerIndex], value, format);
            }
          }
        }
      });
      // Extraction of values from added filters. Like above, the correct column is identified by its header (headerIndex).
      // Subsequently, the value is taken either directly from the incident or through list-default.xml arrow function and
      // placed in the formerly identified column.
      if (entity?.dynamicAttributes?.hasOwnProperty("FILTERS_LABELS")) {
        Object.entries(entity?.dynamicAttributes?.FILTERS_LABELS.value).forEach(([key, value]) => {
          if (typeof value === "string") {
            const headerIndex = headers.listHeaders.indexOf(value);
            const attributeValue = entity?.dynamicAttributes?.[key].value;
            const format = entity?.dynamicAttributes?.FILTERS_TYPES.value[key];
            if (attributeValue) {
              result[headerIndex] = this.formatValue(result[headerIndex], attributeValue, format);
            }
          }
        });
        // If Web Pilot is run, there are no FILTER_LABELS, so the following branch is executed.
      } else {
        headers.filterHeaders.forEach(genericHeaderName => {
          const headerIndex = headers.filterHeaders.indexOf(genericHeaderName) + headers.listHeaders.length;
          const value = this.renderValue(entity, genericHeaderName);
          // renderValue can only fetch values from dynamicAttributes that are supplied via lambda expr.
          if (value) {
            result[headerIndex] = value;
          } else {
            const key: string[] = genericHeaderName.split("."); // Isolating the discriminating dynamicAttribute
            result[headerIndex] = entity?.dynamicAttributes?.[key[1]].value[0].dynamicAttributes.group.value;
          }
        });
      }
      return result;
    });
  }

  // Formatting data fields correctly, e.g. DATE etc.
  private formatValue(header: string, value: any, format: any): string {
    const formattedValue = formatService.format(value, format);
    if (header) {
      return `${header}, ${formattedValue}`;
    } else {
      return formattedValue;
    }
  }

  // Fetch data from dynamicAttributes. This works only for values that are not inside a nested data structure or are supplied via lambda expr.
  // In the latter case, the optional valueDisplay is the lambda expr.
  private renderValue(entity: Entity, valueBinding: string, valueDisplay?: string) {
    const value = ExpressionEvaluationService.get(entity, valueBinding);
    if (valueDisplay) {
      return ExpressionEvaluationService.evaluate(valueDisplay, value);
    } else {
      return value;
    }
  }
}

const entityExportService = EntityExportService.INSTANCE;

export {entityExportService as EntityExportService};
