/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CompositeSubView} from "@icm/core-common";

import {ConditionalEntityListComponentProps, useEntityListComponent} from "../hooks";

export type EntityListSubViewProps = {
  id: string,
  label: string,
} & ConditionalEntityListComponentProps;

export const EntityListSubView = (props: EntityListSubViewProps) => {
  const {
    id,
    label,
    ...entityListProps
  } = props;

  const EntityListComponent = useEntityListComponent(entityListProps);

  if (!EntityListComponent) {
    return <></>;
  }
  return (
    <CompositeSubView id={id} label={label}>
      {EntityListComponent}
    </CompositeSubView>
  );
};
