/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CompositeSubView, MessageKey, useMessages, ViewActionHandlersByActionId} from "@icm/core-common";
import {useEffect} from "react";

import {AuditLogProps, useAuditLogComponent, useAuditLogDownloadHandler, useAuditLogRefreshHandler} from "../hooks";

export type AuditLogSubViewProps = {
  visible: boolean,
} & AuditLogProps;

export const AuditLogSubView = (props: AuditLogSubViewProps, setViewActionHandlers: (viewActionHandlers: ViewActionHandlersByActionId) => void) => {
  const {getMessage} = useMessages();
  const {
    visible,
    ...auditLogProps
  } = props;

  const {incidentNumber} = auditLogProps;

  const AuditLogComponent = useAuditLogComponent(auditLogProps);
  const refreshHandler = useAuditLogRefreshHandler(incidentNumber);
  const downloadHandler = useAuditLogDownloadHandler(incidentNumber);

  useEffect(() => {
    if (visible && AuditLogComponent) {
      setViewActionHandlers({
        [downloadHandler.key]: downloadHandler,
        [refreshHandler.key]: refreshHandler,
      });
    }
  }, [setViewActionHandlers, visible, AuditLogComponent, refreshHandler, downloadHandler]);

  if (!visible || !AuditLogComponent) {
    return <></>;
  }
  return (
    <CompositeSubView id="entityHistory" label={getMessage(MessageKey.AUDITLOG.AUDITLOG)} key="entityHistory">
      {AuditLogComponent}
    </CompositeSubView>
  );
};
