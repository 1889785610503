/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {cx} from "@emotion/css";
import React from "react";
import {useQuery} from "react-query";

import {CommonIconProps} from "../components";
import {svgIconExists} from "../resources";
import {CustomColor, CustomColorStrings, IcmTheme, PaletteColor, PaletteColorStrings, useTheme} from "../theme";
import {styles} from "./SvgIcon.style";

export const SvgIcon = React.forwardRef((props: CommonIconProps, ref) => {
  const {className, name, size} = props;
  let {iconColor} = props;
  const svgPath = `core/common/icons/${name}.svg`;
  let paletteColor: PaletteColor | CustomColor | undefined;

  if (iconColor && PaletteColorStrings.includes(iconColor)) {
    paletteColor = iconColor as PaletteColor;
    iconColor = undefined;
  }

  if (iconColor && CustomColorStrings.includes(iconColor)) {
    paletteColor = iconColor as CustomColor;
    iconColor = undefined;
  }

  const theme: IcmTheme = useTheme();

  const {isLoading, data: svg} = useQuery(["public", "core", "svg-icon", svgPath], async () => {
    if (name && svgIconExists(name)) {
      try {
        const svgResult = await fetch(svgPath);
        return await svgResult.text();
      } catch (e) {
        console.warn(`Could not load icon: ${name} because of `, e);
      }
      return undefined;
    } else if (name) {
      console.warn("Unknown icon: ", name);
    }
    return undefined;
  }, {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
  });
  // Clean up props as they are filled with properties from Tooltip which should be forwarded. (React.forwardRef)
  const internalProps = {...props};
  delete internalProps.iconColor;
  delete internalProps.toolTipText;
  if (svg) {
    return (
      <span {...internalProps}
            ref={ref as React.RefObject<HTMLDivElement>}
            className={cx(styles(theme.palette, paletteColor, iconColor, size).container, className)}
            dangerouslySetInnerHTML={{__html: svg}}
      />
    );
  } else if (isLoading) {
    return (<></>);
  } else {
    // questionmark character for missing icon
    return (
      <span className={cx(styles(theme.palette, paletteColor, iconColor, size).unknown, className)}>&#xfffd;</span>
    );
  }
});
