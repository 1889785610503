/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as Handlebars from "handlebars";
import {get, set, size} from "lodash-es";

export type HelperFunction = (...args: any) => any;
export type HelperFunctionWithContext = (context: HelperContext, ...args: any) => any;
export type HelperContext = {
  /**
   * call this object to insert an object into the template
   */
  emit: (value: any) => string | any
}

/**
 * This service evaluates handlebars expressions and javascript lambdas.
 * Additionally, it allows to get/set properties of an object by path.
 *
 * Besides three parameters that can be passed to the evaluation, there are some helper functions available.
 * A helper function `f` with parameter2 `p1` and `p2` of a module m can be called as <code>$$.m.f(p1, p2)</code>
 * in a lambda and as <code>{{m$f p1 p2}}</code> in a handlebars template.
 */
export class ExpressionEvaluationService {

  /**
   * Get a property from an object.
   * You can also get the size of a collection by appending .size() to the path.
   *
   * @param object the object
   * @param path the path of the property, "_" is the object itself
   * @param defaultValue the default value
   */
  public static get<TObject extends any>(object: TObject, path: string, defaultValue?: any): any | undefined {
    if (path === "_") {
      return object;
    } else {
      // handle path functions
      if (path.endsWith("()")) {
        try {
          return this.resolvePathFunctions(object, path);
        } catch (e) {
          console.warn(e);
        }
      }
      return get(object, path, defaultValue);
    }
  }

  /**
   * Sets a property of an object.
   *
   * @param object the object
   * @param path the path of the property
   * @param value the value to set it to
   */
  public static set<T extends object>(object: T, path: string, value: any): T {
    return set(object, path, value);
  }

  private static resolvePathFunctions<TObject extends any>(object: TObject, path: string) {
    if (path.endsWith(".size()")) {
      const fixedPath = path.substring(0, path.length - ".size()".length);
      const value = get(object, fixedPath);
      return size(value);
    }
    throw new Error("Path contains unknown function " + path);
  }

  /**
   * Evaluates a handlebars template or a lambda.
   * The values returned from this evaluation are NOT HTML escaped und should thus not be used with dangerouslySetInnerHTML.
   *
   * A helper function `f` with parameter2 `p1` and `p2` of a module m can be called as <code>$$.m.f(p1, p2)</code>
   * in a lambda and as <code>{{m$f p1 p2}}</code> in a handlebars template.
   *
   * ATTENTION: the expressions are cached without regard to the escaping flag.
   * Using the same template with both {@link evaluate} and {@link evaluateHtml} will escape or not based on the FIRST call!
   *
   * @param expression lambda ("... => ...") or handlebars template
   * @param entity1 first context/object
   * @param entity2 second context/object (lambdas only)
   * @param entity3 third context/object (lambdas only)
   */
  public static evaluate(expression: string, entity1?: any, entity2?: any, entity3?: any): any {
    return this.evaluateInternal(expression, entity1, entity2, entity3);
  }

  /**
   * Evaluates a handlebars template with HTML escaping (or a lambda).
   * Use this function to evaluate handlebars templates that are injected with dangerouslySetInnerHTML.
   *
   * A helper function `f` with parameter2 `p1` and `p2` of a module m can be called as <code>$$.m.f(p1, p2)</code>
   * in a lambda and as <code>{{m$f p1 p2}}</code> in a handlebars template.
   *
   * ATTENTION: the expressions are cached without regard to the escaping flag.
   * Using the same template with both {@link evaluate} and {@link evaluateHtml} will escape or not based on the FIRST call!
   *
   * @param expression lambda ("... => ...") or handlebars template
   * @param entity1 first context/object
   * @param entity2 second context/object (lambdas only)
   * @param entity3 third context/object (lambdas only)
   */
  public static evaluateHtml(expression: string, entity1?: any, entity2?: any, entity3?: any): any {
    return this.evaluateInternal(expression, entity1, entity2, entity3, true);
  }

  private static evaluateInternal(expression: string, entity1?: any, entity2?: any, entity3?: any, doEscape = false): any {
    try {
      if (ExpressionEvaluationService.PRECOMPUTED_VALUES[expression] !== undefined) {
        return ExpressionEvaluationService.PRECOMPUTED_VALUES[expression];
      } else if (!ExpressionEvaluationService.EXPRESSION_CACHE[expression]) {
        if (expression.includes("=>")) {
          const exec = `return (${expression})(val1, val2, val3, $$)`;
          ExpressionEvaluationService.EXPRESSION_CACHE[expression] = (e1?: any, e2?: any, e3?: any, helpers?: any) => {
            /* eslint-disable no-new-func */
            const result = Function.apply({}, ["val1", "val2", "val3", "$$", exec])(e1, e2, e3, helpers);
            if (Array.isArray(result)) {
              return result.map(element => typeof element === "string" ? this.evaluateInternal(element, e1, e2, e3, doEscape) : element);
            }
            return result;
          };
        } else {
          ExpressionEvaluationService.EXPRESSION_CACHE[expression] = (context: any) => this.renderTemplate(expression, context, doEscape);
        }
      }
      return ExpressionEvaluationService.EXPRESSION_CACHE[expression]!(entity1, entity2, entity3, ExpressionEvaluationService.HELPERS);
    } catch (e) {
      console.error("Evaluating expression '", expression, "' with parameters", entity1, entity2, entity3, "failed:", e);
      throw e;
    }
  }

  /**
   * Evaluate an expression to a boolean value.
   * See {@link evaluate}
   *
   * @param expression lambda ("... => ...") or handlebars template
   * @param entity1 first context/object
   * @param entity2 second context/object (lambdas only)
   * @param entity3 third context/object (lambdas only)
   */
  public static evaluateRule(expression: string, entity1?: any, entity2?: any, entity3?: any): boolean {
    return !!this.evaluateInternal(expression, entity1, entity2, entity3);
  }

  private static renderTemplate(template: string, context?: object | any, doEscape = false): string | any[] {
    if (!ExpressionEvaluationService.HANDLEBARS_CACHE[template]) {
      ExpressionEvaluationService.HANDLEBARS_CACHE[template] = Handlebars.compile(template, {noEscape: !doEscape});
    }
    try {
      const result = ExpressionEvaluationService.HANDLEBARS_CACHE[template]!(context) as string;
      if (this.EMITTED_OBJECTS.size > 0) {
        const split = result.split("##@@");
        if (split.length > 1) {
          return split.map(val => {
            if (val.startsWith("OBJ:")) {
              try {
                return this.EMITTED_OBJECTS.get(val) || val;
              } finally {
                this.EMITTED_OBJECTS.delete(val);
              }
            } else {
              return val;
            }
          });
        }
      }
      return result;
    } catch (e) {
      console.error("Evaluating handlebars template failed, template:", template, "context:", context, e);
      return template;
    }
  }

  public static registerHelperWithContext(module: string, functionName: string, helper: HelperFunctionWithContext) {
    if (!this.HELPERS[module]) {
      this.HELPERS[module] = {};
    }
    if (this.HELPERS[module]![functionName]) {
      console.error(`Function ${functionName} already registered for module ${module}. Not registering new function`);
    } else if (Handlebars.registerHelper) {
      this.HELPERS[module]![functionName] = (...args) => helper({
        emit: value => value,
      }, ...args);
      Handlebars.registerHelper(`${module}$${functionName}`,
        (...args: any) => helper({
          emit: (value: any) => {
            const objectId = "OBJ:" + this.EMITTED_OBJECTS_COUNTER;
            this.EMITTED_OBJECTS_COUNTER++;
            this.EMITTED_OBJECTS.set(objectId, value);
            return "##@@" + objectId + "##@@";
          },
        },
        // drop last argument (utility object from handlebars)
        ...args.slice(0, -1),
        // use the handlebars hash object as last argument
        args[args.length - 1].hash));
    } else {
      console.warn("Could not register helper as Handlebars.registerHelper is undefined");
    }
  }

  /**
   * Register a helper function.
   *
   * @param module the module name
   * @param functionName the function name
   * @param helper the function
   */
  public static registerHelper(module: string, functionName: string, helper: HelperFunction) {
    ExpressionEvaluationService.registerHelperInternal(module, functionName, helper, false);
  }

  public static registerHelperReceivingOptions(module: string, functionName: string, helper: HelperFunction) {
    ExpressionEvaluationService.registerHelperInternal(module, functionName, helper, true);
  }

  public static resetHelpers(module: string) {
    this.HELPERS[module] = {};
  }

  private static registerHelperInternal(module: string, functionName: string, helper: HelperFunction, helperReceivesOptions: boolean) {
    if (!this.HELPERS[module]) {
      this.HELPERS[module] = {};
    }
    if (this.HELPERS[module]![functionName]) {
      console.warn(`Function ${functionName} already registered for module ${module}. Clearing old registration.`);
      this.HELPERS[module]![functionName] = undefined;
      Handlebars.unregisterHelper(`${module}$${functionName}`);
    }

    if (Handlebars.registerHelper) {
      this.HELPERS[module]![functionName] = helper;
      Handlebars.registerHelper(`${module}$${functionName}`,
        (...args: any[]) => {
          if (helperReceivesOptions) {
            return helper(...args);
          } else {
            // drop last argument (utility object from handlebars)
            return helper(...args.slice(0, -1));
          }
        });
    } else {
      console.warn("Could not register helper as Handlebars.registerHelper is undefined");
    }
  }

  // temporarily holds objects emitted by helpers
  private static readonly EMITTED_OBJECTS = new Map<string, any>();

  private static EMITTED_OBJECTS_COUNTER: number = 0;

  private static readonly HELPERS: Partial<Record<string, Partial<Record<string, HelperFunction>>>> = {
    // will be registered dynamically by calling registerHelper
  };

  private static readonly PRECOMPUTED_VALUES: Partial<Record<string, any>> = {
    true: true,
    false: false,
  };

  private static readonly EXPRESSION_CACHE: Partial<Record<string, Function>> = {
    _: (entity: any) => entity,
  };

  private static readonly HANDLEBARS_CACHE: Partial<Record<string, HandlebarsTemplateDelegate>> = {};
}
