/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ApiUtil, FetchService} from "@icm/core-common";

import {ArrowStructure, CircleStructure, LineStructure, PointStructure, PolygonStructure, Structure, TextStructure} from "../generated/api";

export class TopologyApi {
  public static getAllSketches(): Promise<Structure[]> {
    return FetchService.performGet("topology/structures")
      .then(json => ApiUtil.convertArray(json, TopologyApi.fromData).filter(s => s != null) as Structure[]);
  }

  public static saveSketches(sketches: Structure[]): Promise<string[]> {
    return FetchService.performPost("topology/structures", sketches)
      .then(response => response.text())
      .then(json => JSON.parse(json) as string[]);
  }

  public static deleteSketches(ids: string[]): Promise<null> {
    const promises = ids.map(id => FetchService.performDelete(`topology/structures/${id}`));
    return Promise.all(promises).then(() => null);
  }

  public static fromData(val: Structure): Structure | null {
    switch (val.structureType) {
      case "PointStructure": return PointStructure.fromData(val);
      case "LineStructure": return LineStructure.fromData(val);
      case "ArrowStructure": return ArrowStructure.fromData(val);
      case "PolygonStructure": return PolygonStructure.fromData(val);
      case "TextStructure": return TextStructure.fromData(val);
      case "CircleStructure": return CircleStructure.fromData(val);
      default: return null;
    }
  }
}
