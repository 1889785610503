/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {combineEpics, Epic} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, switchMap, withLatestFrom} from "rxjs/operators";
import {isActionOf} from "typesafe-actions";

import {StandbyApi} from "../../api/StandbyApi";
import {deleteStandby, fetchStandbys, fetchStandbyTeamMembers, fetchStandbyTeams, submitStandby} from "./actions";
import {StandbysAction} from "./reducer";

const fetchStandbyTeamsFlow: Epic<StandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchStandbyTeams.request)),
  switchMap(() => from(StandbyApi.getAllStandbyTeams()).pipe(
    map(value => fetchStandbyTeams.success(value)),
    catchError(error => of(fetchStandbyTeams.failure(error)))
  ))
);

const fetchStandbyTeamMembersFlow: Epic<StandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchStandbyTeamMembers.request)),
  switchMap(action => from(StandbyApi.getAllMembersFromStandbyTeam(action.payload.id!)).pipe(
    map(value => fetchStandbyTeamMembers.success(value)),
    catchError(error => of(fetchStandbyTeamMembers.failure(error)))
  ))
);

const fetchStandbysFlow: Epic<StandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchStandbys.request)),
  switchMap(action => from(StandbyApi.getAllStandbysFromTeam(action.payload.id!)).pipe(
    map(value => fetchStandbys.success(value)),
    catchError(error => of(fetchStandbys.failure(error)))
  ))
);

const submitStandbyFlow: Epic<StandbysAction> = (action$, state$) => action$.pipe(
  filter(isActionOf(submitStandby.request)),
  switchMap(action => from(StandbyApi.postStandby({...action.payload, standbyTeam: state$.value.standbyState.standbyTeam})).pipe(
    map(value => submitStandby.success(value)),
    catchError(error => of(submitStandby.failure(error)))
  ))
);

const deleteStandbyFlow: Epic<StandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(deleteStandby.request)),
  switchMap(action => from(StandbyApi.deleteStandby(action.payload)).pipe(
    map(value => deleteStandby.success(value)),
    catchError(error => of(deleteStandby.failure(error)))
  ))
);

const refreshStandbyTeamMembersFlow: Epic<StandbysAction> = (action$, state$) => action$.pipe(
  filter(isActionOf([fetchStandbyTeams.success])),
  withLatestFrom(state$),
  map(([action, state]) => state.standbyState.standbyTeam
    ? fetchStandbyTeamMembers.request(state.standbyState.standbyTeam)
    : fetchStandbyTeamMembers.failure(new Error("StandbyTeam is undefined")))
);

const refreshStandbysFlow: Epic<StandbysAction> = (action$, state$) => action$.pipe(
  filter(isActionOf([fetchStandbyTeams.success, deleteStandby.success, submitStandby.success])),
  withLatestFrom(state$),
  map(([action, state]) => state.standbyState.standbyTeam
    ? fetchStandbys.request(state.standbyState.standbyTeam)
    : fetchStandbys.failure(new Error("StandbyTeam is undefined")))
);

export const standbyFlows = combineEpics(fetchStandbyTeamsFlow, fetchStandbyTeamMembersFlow, fetchStandbysFlow,
  submitStandbyFlow, refreshStandbyTeamMembersFlow, refreshStandbysFlow, deleteStandbyFlow);
