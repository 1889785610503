/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  useMemo,
} from "react";

import {
  ExpressionEvaluationService,
} from "../../../service";
import {
  useFormGeneratorContext,
} from "../FormGeneratorContextProvider";

type FormElement = {
  readOnly?: string
}

export const useReadOnlyAggregation = (group: FormElement, widget?: FormElement, evaluationContext?: any) => {
  const formGeneratorContext = useFormGeneratorContext();
  return useMemo(() => {
    const contextReadOnly: boolean = formGeneratorContext.readOnly ? formGeneratorContext.readOnly : false;
    const formReadOnly: boolean = formGeneratorContext.formConfig.readOnly
      ? ExpressionEvaluationService.evaluateRule(formGeneratorContext.formConfig.readOnly, evaluationContext)
      : false;
    const groupReadOnly: boolean = group.readOnly ? ExpressionEvaluationService.evaluateRule(group.readOnly, evaluationContext) : false;
    const widgetReadOnly: boolean = widget?.readOnly ? ExpressionEvaluationService.evaluateRule(widget.readOnly, evaluationContext) : false;
    return contextReadOnly || formReadOnly || groupReadOnly || widgetReadOnly;
  }, [formGeneratorContext, group, widget, evaluationContext]);
};
