/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ModuleRegistrationOptions, registerNamedComponentFactory} from "@icm/core-common";

import {createEntityFormComponentDescriptor} from "./src/components/EntityFormComponent";
import packageInfo from "./src/generated/package.clone.json";
import {
  entityAttributeService,
  EntityAttributeService,
  EntityCacheService,
  EntityWeatherService,
  EntityWorkflowService,
} from "./src/service";
import {registerStoreContributions} from "./src/store";
import {useCreateEntityActionHandler, useCreateWeakEntityActionHandler} from "./src/ui";

export * from "./src/generated/api";
export * from "./src/api";
export * from "./src/service";
export * from "./src/ui";
export * from "./src/hooks";
export * from "./src/store";
export * from "./src/data";
export * from "./src/util";
export * from "./src/views";
export * from "./src/constants";
export * from "./src/components";

// Augment the service interface
declare module "@icm/core-common" {
  export interface IcmServices {
    ENTITY_ATTRIBUTE: EntityAttributeService,
    ENTITY_CACHE: EntityCacheService,
    ENTITY_WORKFLOW: EntityWorkflowService,
  }
}

export const entityCommonModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  registerStoreContribution: registerStoreContributions,
  registerServices: serviceRegistry => {
    const entityCacheService = new EntityCacheService(serviceRegistry.get("PROPERTY"), serviceRegistry.get("SECURITY"));
    serviceRegistry.register("ENTITY_CACHE", entityCacheService);
    serviceRegistry.register("ENTITY_ATTRIBUTE", entityAttributeService);
    const entityWorkflowService = new EntityWorkflowService();
    serviceRegistry.register("ENTITY_WORKFLOW", entityWorkflowService);
    serviceRegistry.register("WEATHER_SERVICE", new EntityWeatherService());
  },
  runPostInitialization: () => {
    registerNamedComponentFactory(createEntityFormComponentDescriptor());
  },
  providedActions: {
    CREATE_ENTITY: useCreateEntityActionHandler,
    CREATE_WEAK_ENTITY: useCreateWeakEntityActionHandler,
  },
  dependencies: packageInfo.dependencies,
};
