/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useQuery} from "react-query";

import {useService} from "../service";
import {Perspective, PerspectiveType} from "../store";
import {useAvailableViewDescriptors} from "../ui";
import {PerspectiveMapperFactory} from "./mapper";
import {UI_DEFAULT_PERSPECTIVE} from "./QueryKeys";
import {useUiConfiguration} from "./useUiConfiguration";


/**
 * @param perspectiveType
 * @return A perspective of the given type based on the ui configuration on the server.
 *         As long as the perspective is being fetched, "undefined" is returned.
 *         If the fetched configuration could not be mapped or an error occurs, the function returns false.
 */
export const useDefaultPerspective = <T extends PerspectiveType>(perspectiveType: T): false | undefined | Perspective => {
  const config = useUiConfiguration();

  const securityService = useService("SECURITY");
  const availableViewDescriptors = useAvailableViewDescriptors();

  const perspective = useQuery(UI_DEFAULT_PERSPECTIVE, async () => {
    if (config.data) {
      try {
        if (securityService.isLoggedIn()) {
          const perspectiveMapper = new PerspectiveMapperFactory()
            .withAvailableViewDescriptors(availableViewDescriptors)
            .withUiConfiguration(config.data)
            .withSecurityService(securityService)
            .create(perspectiveType);
          console.log("Using perspective mapper:", perspectiveMapper);
          return perspectiveMapper.map();
        }
      } catch (e) {
        console.error("Could not map configuration to a perspective.", JSON.stringify(e));
        return undefined;
      }
    }
    return undefined;
  }, {
    enabled: config.isSuccess && !!config.data && securityService.isLoggedIn(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  if (perspective.isSuccess) {
    if (perspective.data) {
      return perspective.data;
    } else {
      return false;
    }
  } else if (perspective.error) {
    console.error("An error occurred loading the perspective: ", perspective.error);
    return false;
  }
  return undefined;
};
