/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {BadgeColor} from "../badge";
import {ReadOnlyComponent} from "./readonly/ReadOnlyComponent";

export type IFormComponentProps<T> = {
  value?: T,
  label: string,
  helperText?: string,
  handleChange: (newValue?: T, referredObject?: any) => void
  required?: boolean,
  disabled?: boolean,
  error?: boolean
  editable?: boolean
  readOnly?: boolean
  badgeColor?: BadgeColor
  onFocus?: () => void
  updateFieldValidationState?: (isValid: boolean) => void
}

export abstract class AbstractFormComponent<T, P = {}, S = {}, SS = any> extends React.PureComponent<IFormComponentProps<T> & P, S, SS> {

  public render(): React.ReactNode {
    if (this.props.readOnly) {
      return this.renderReadOnlyComponent();
    } else {
      return this.renderEditableComponent();
    }
  }

  protected abstract renderEditableComponent(): React.ReactNode

  protected abstract renderReadOnlyText(): string | undefined

  protected renderReadOnlyComponent(): React.ReactNode {
    return <ReadOnlyComponent label={this.props.label} text={this.renderReadOnlyText()} badgeColor={this.props.badgeColor} />;
  }
}
