/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableTextOrIconsColumn} from "@icm/core-common";
import {TableCell, TableRow} from "@mui/material";
import clsx from "clsx";
import * as React from "react";

import {ConditionalTooltip} from "../../../util";
import {DataTableHeadCellContent, DataTableRowHeader, useDataTableContext, useDataTableStyles} from "../common";

type HeaderRowProps<T> = {
  columns: DataTableTextOrIconsColumn<T>[]
  sticky?: boolean
}

export const ClassicTableHeaderRow = <T extends unknown>({columns, sticky = false}: HeaderRowProps<T>) => {
  const classes = useDataTableStyles();
  const {tableConfig} = useDataTableContext<T>();
  const actions = tableConfig.rowActions;
  return (
    <TableRow>
      <DataTableRowHeader />
      {columns.map((col, index) => (
        <TableCell key={index}
                   className={clsx({
                     [classes.cell]: true,
                     [classes.columnHeaderCell]: true,
                     [classes.stickyColumnHeaderCell]: sticky,
                     [classes.sortableColumnHeaderCell]: col.type === "TEXT" && col.sortable,
                   })}
        >
          <ConditionalTooltip placement="top" title={col.headerTextTooltip}>
            <DataTableHeadCellContent col={col}>
              {col.headerText}
            </DataTableHeadCellContent>
          </ConditionalTooltip>
        </TableCell>
      ))}
      {actions && actions.length > 0 && (
        <TableCell className={clsx(classes.actionsColumn)} />
      )}
    </TableRow>
  );
};
