/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {REM_INCIDENT_TYPE} from "@icm/rem-rail-common";
import {useQuery} from "react-query";

import {AuditLogApi} from "../api/AuditLogApi";
import {useAuditLogQueryKey} from "./useAuditLogQueryKey";

export const useAuditLog = (incidentNumber?: string, onError?: () => void) => {
  const queryKey = useAuditLogQueryKey(incidentNumber);
  return useQuery(
    queryKey,
    async () => AuditLogApi.getAuditLog({
      type: REM_INCIDENT_TYPE,
      number: incidentNumber,
    }), {
      cacheTime: 0,
      enabled: !!incidentNumber,
      onError,
    }
  );
};
