/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {AlertDialogProvider, AlertDialogProps, MessageKey, useDialogContext, useMessages} from "@icm/core-common";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useCallback} from "react";

import {ButtonComponent} from "../form";
import {useDialogStyles} from "./DialogStyle";

const AlertDialog = ({title, description, onClose}: AlertDialogProps) => {
  const classes = useDialogStyles();
  const {getMessage} = useMessages();
  return (
    <Dialog open={true} maxWidth="sm" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        {description && (
        <DialogContentText>{description}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonComponent variant="contained" label={getMessage(MessageKey.CORE.OK)} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export function useWebAlertDialog(): AlertDialogProvider {
  const {setDialog} = useDialogContext();
  return useCallback((alertProps) => {
    return new Promise((resolve) => {
      setDialog(<AlertDialog {...alertProps}
                             onClose={() => {
                               resolve();
                               setDialog(null);
                             }}
      />);
    });
  }, [setDialog]);
}
