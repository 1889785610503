/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {SecurityService, useService} from "@icm/core-common";
import {useMemo} from "react";


/** Permission to show the auditlog. Do NOT change the value as the name is stored in the REM database. */
export const READ_AUDITLOG = "WEB_AUDITLOG_VIEWER";

/** Permission to show the communication server messages. Do NOT change the value as the name is stored in the REM database. */
export const READ_COMMUNICATIONS = "WEB_INCIDENT_DETAIL_COMMUNICATIONS";

/** Permission to show the operational consequences (Betriebliche Folgen). Do NOT change the value as the name is stored in the REM database. */
export const READ_OPERATIONAL_CONSEQUENCES = "WEB_INCIDENT_DETAIL_OPERATIONAL_CONSEQUENCES";

/** Permission to show the attachments of an incident. Do NOT change the value as the name is stored in the REM database. */
export const READ_ATTACHMENTS = "WEB_INCIDENT_DETAIL_ATTACHMENTS";

/** Permission to show the train data. Do NOT change the value as the name is stored in the REM database. */
export const READ_TRAININFORMATION = "WEB_INCIDENT_DETAIL_TRAIN_INFORMATION";

/** Permission to show the activity stream. Do NOT change the value as the name is stored in the REM database. */
export const READ_ACTIVITIY_STREAM = "WEB_ACTIVITY_STREAM_DETAIL_INCIDENT_VIEWER";

export type IncidentViewerPermission = typeof READ_AUDITLOG
  | typeof READ_COMMUNICATIONS
  | typeof READ_OPERATIONAL_CONSEQUENCES
  | typeof READ_ATTACHMENTS
  | typeof READ_TRAININFORMATION
  | typeof READ_ACTIVITIY_STREAM;

const useAuthorization = (permission: IncidentViewerPermission) => {
  const securityService = useService("SECURITY");
  return useMemo(() => hasPermission(securityService, permission), [securityService, permission]);
};

export const hasPermission = (securityService: SecurityService, permission: IncidentViewerPermission) => {
  const expression = `context => context.isVisible.${permission}`;
  const authorized = securityService.evaluateVisibilityRule(expression);
  if (authorized) {
    console.log("Authorization granted for:", permission);
  } else {
    console.log("Authorization rejected for:", permission);
  }
  return authorized;
};

/**
 * Evaluates static visibility expressions in the context of the current user.
 */
export const useIncidentViewerPermissions = () => {
  const showOperationConsequences = useAuthorization(READ_OPERATIONAL_CONSEQUENCES);
  const showAuditLog = useAuthorization(READ_AUDITLOG);
  const showMessages = useAuthorization(READ_COMMUNICATIONS);
  const showAttachments = useAuthorization(READ_ATTACHMENTS);
  const showTrainInformation = useAuthorization(READ_TRAININFORMATION);
  const showActivityStream = useAuthorization(READ_ACTIVITIY_STREAM);

  return {
    showOperationConsequences,
    showAuditLog,
    showMessages,
    showAttachments,
    showTrainInformation,
    showActivityStream,
  };
};
