/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isDataTableIconsColumn} from "../common";
import {ColumnWithWidth} from "./VirtualizedInfiniteTable";


export const getCellStyle = <T>(column: ColumnWithWidth<T>): React.CSSProperties => {
  const style = {
    flex: `${column.width} 0 auto`,
    width: column.width,
  };
  if (isDataTableIconsColumn(column)) {
    style["textAlign"] = "center";
  }
  return style;
};
