/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useQuery} from "react-query";

import {UiConfigurationApi} from "../api";
import {UiConfiguration} from "../generated/api";
import {useService} from "../service";
import {UI_CONFIGURATION} from "./QueryKeys";

export type VersionedUiConfiguration = {
  version: string,
} & UiConfiguration

/**
 * Returns the UI-Configuration as configured on the server.
 * Call result is cached in local query client.
 */
export const useUiConfiguration = () => {
  const securityService = useService("SECURITY");

  return useQuery<VersionedUiConfiguration | undefined>(
    UI_CONFIGURATION,
    async () => {
      if (securityService.isLoggedIn()) {
        const definition = await UiConfigurationApi.getUiConfiguration();
        const uiConfiguration = definition.uiConfiguration;
        if (!uiConfiguration) {
          throw new Error("Could not find uiConfiguration");
        }
        return {
          version: (definition.version ?? "UNKNOWN"),
          ...uiConfiguration,
        };
      }

      return undefined;
    },
    {
      enabled: securityService.isLoggedIn(),
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};
