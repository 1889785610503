/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {dateService, IdGenerator, isNonEmptyString, JournalEntry} from "@icm/core-common";

import {Entity} from "../generated/api";
import {WeakEntityAlikeObject} from "../util";


type StringValue = { value: string };

type RequiredDynamicAttributes = {
  commitTime: StringValue,
  logTime: StringValue,
  user: StringValue,
  role: StringValue,
  text: StringValue
} & Entity["dynamicAttributes"];

/**
 * An Entity that has all dynamicAttributes required to build create JournalEntry.
 * That is:
 *
 * - commitTime: StringValue (ISO 8601),
 * - logTime: StringValue (ISO 8601),
 * - user: StringValue,
 * - role: StringValue,
 * - text: StringValue
 *
 */
export type JournalEntryEntity = {
  dynamicAttributes: RequiredDynamicAttributes
  id: string
} & WeakEntityAlikeObject


/**
 * Check if a given entity can be viewed as a JournalEntry.
 * In this case, the asJournalEntry will return a value.
 *
 * @param val
 */
export function isJournalEntryEntity(val: Entity): val is JournalEntryEntity {
  if (val.dynamicAttributes) {
    return (
      isNonEmptyString(val.id)
      && isNonEmptyString(val.dynamicAttributes.logTime?.value)
      && dateService.parse(val.dynamicAttributes.logTime.value) !== undefined
      && isNonEmptyString(val.dynamicAttributes.commitTime?.value)
      && dateService.parse(val.dynamicAttributes.commitTime.value) !== undefined
      && isNonEmptyString(val.dynamicAttributes.user?.value)
      && isNonEmptyString(val.dynamicAttributes.role?.value)
      && isNonEmptyString(val.dynamicAttributes.text?.value)
    );
  }
  return false;
}


/**
 * Maps the given entity to a JournalEntry if possible.
 *
 * @param val an entity whose dynamicAttributes should be mapped
 * @see isJournalEntryEntity
 */
export function asJournalEntry(val: Entity): JournalEntry {
  if (isJournalEntryEntity(val)) {
    return {
      logTime: val.dynamicAttributes.logTime.value,
      commitTime: val.dynamicAttributes.commitTime.value,
      role: val.dynamicAttributes.role.value,
      text: val.dynamicAttributes.text.value,
      user: val.dynamicAttributes.user.value,
    };
  }
  throw new Error(`IllegalArgument: Cannot map Entity ${val} to JournalEntry`);
}

export function createJournalEntityEntry(text: string, roleName: string, userName: string, entryType: string): JournalEntryEntity {
  return {
    id: IdGenerator.randomUUID(),
    type: entryType,
    dynamicAttributes: {
      logTime: {value: dateService.toISOString(new Date())},
      commitTime: {value: dateService.toISOString(new Date())},
      role: {value: roleName},
      user: {value: userName},
      text: {value: text},
    },
  };
}
