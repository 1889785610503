/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
export class HTMLHeadService {

  // The favicon is set via the directory name. The images in the directory must all have the same name as in the default folder.
  // If no folderName is passed, the default directory is used to provide the images.
  public static addFaviconHeadInformation(directoryName: string | undefined = "core/common/favicon/default") {
    HTMLHeadService.appendLinkElement("shortcut icon", "image/x-icon", undefined, `${directoryName}/favicon.ico`);
    HTMLHeadService.appendLinkElement("apple-touch-icon", undefined, "180x180", `${directoryName}/apple-touch-icon.png`);
    HTMLHeadService.appendLinkElement("icon", "image/png", "32x32", `${directoryName}/favicon-32x32.png`);
    HTMLHeadService.appendLinkElement("icon", "image/png", "16x16", `${directoryName}/favicon-16x16.png`);
    HTMLHeadService.appendLinkElement("mask-icon", undefined, undefined, `${directoryName}/safari-pinned-tab.svg`);
    HTMLHeadService.appendLinkElement("manifest", undefined, undefined, `${directoryName}/site.webmanifest`);

    HTMLHeadService.setMetaElements(directoryName);
  }

  public static  setTitle(title: string) {
    document.title = title;
  }

  private static setMetaElements(directoryName: string) {
    HTMLHeadService.appendMetaElement("msapplication-TileColor", "#0161A9");
    HTMLHeadService.appendMetaElement("msapplication-config", `${directoryName}/browserconfig.xml`);
    HTMLHeadService.appendMetaElement("theme-color", "#ffffff");
  }

  public static appendLinkElement(rel: string, type: string | undefined, size: string | undefined, href: string) {
    let link: HTMLLinkElement;
    if (size) {
      link = document.querySelector(`link[rel*='${rel}'][sizes='${size}']`) || document.createElement("link");
      link.sizes.add(size);
    } else {
      link = document.querySelector(`link[rel*='${rel}']`) || document.createElement("link");
    }
    if (type) {
      link.type = type;
    }
    link.rel = rel;
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  public static appendMetaElement(name: string, content: string) {
    const meta: HTMLMetaElement = document.querySelector(`meta[name='${name}']`) || document.createElement("meta");
    meta.name = name;
    meta.content = content;
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
}
