/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React from "react";
import {QueryObserverResult} from "react-query/types/core/types";

import {MessageKey, useMessages} from "../../../index";
import {ICON, LOADER} from "../../constant";
import {DEFAULT_THUMBNAIL_SIZE, FileData, useFileData, useThumbnailData} from "../../data";
import {getNamedComponentFactory} from "../../ui";

export type ThumbnailProps = {
  fileId: string
  size?: number
}

/**
 * An image of a given size that uses data from a cached data url.
 *
 * @param props
 * @constructor
 */
export const Thumbnail = (props: ThumbnailProps) => {
  const {fileId, size = DEFAULT_THUMBNAIL_SIZE} = props;
  const thumbnail = useThumbnailData(fileId, size);
  return Img(thumbnail, size);
};


export type ImageProps = {
  fileId: string,
}

/**
 * An image that uses data from a cached data url.
 *
 * @param props
 * @constructor
 */
export const Image = (props: ImageProps) => {
  const {fileId} = props;
  const image = useFileData(fileId);
  return Img(image, "100%");
};

const Img = (image: QueryObserverResult<FileData>, size: string | number) => {
  const boxStyle = {
    width: size,
    height: size,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  };

  const {getMessage} = useMessages();

  if (image.isFetching) {
    const Loader = getNamedComponentFactory(LOADER) || (() => <span>{getMessage(MessageKey.CORE.LOADING)}</span>);
    return (<div style={boxStyle}><Loader /></div>);
  } else if (image.isSuccess) {
    return (<img style={{width: size, height: size}} src={image.data.blobUrl} alt={image.data.name} />);
  } else if (image.isError) {
    const Icon = getNamedComponentFactory(ICON) || (() => <span>Broken image...</span>);
    return (<div style={boxStyle}><Icon name="broken-image" /></div>);
  } else {
    return (<></>);
  }
};
