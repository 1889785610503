/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isEqual} from "lodash-es";
import * as React from "react";

import {FILTER} from "../../constant";
import {FilterParameter} from "../../generated/api";
import {FilterAndModifier} from "../../service";
import {getNamedComponentFactory, useViewModel} from "../../ui";
import {SharedFilterController} from "./SharedFilterController";
import {useSharedFilterController} from "./useSharedFilterController";

export type FilterComponentProps = {
  controller: SharedFilterController
}

export type FilterOptionType = "ENTITY" | "EVENT";

export type SharedFilterProps = {
  // the filter view parameter
  filterParameter: FilterParameter,
  // function that is called whenever the filter was changed
  onUpdateFilter: (filter: FilterAndModifier) => void,
  showEventFilterOptions?: boolean
}

/**
 * This filter component uses a shared storage so that the filter state is the same across different usage sites.
 */
export const SharedFilter = React.memo(({filterParameter, onUpdateFilter, showEventFilterOptions}: SharedFilterProps) => {
  const {viewModel: {viewId}} = useViewModel();
  const sharedFilterController = useSharedFilterController({filterParameter, viewId, onUpdateFilter, showEventFilterOptions});
  const FilterComponent = getNamedComponentFactory(FILTER);
  if (FilterComponent && sharedFilterController.isReady()) {
    return (
      <FilterComponent controller={sharedFilterController} />
    );
  }
  return <></>;
}, isEqual);
