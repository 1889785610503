/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useMemo} from "react";
import {useDispatch} from "react-redux";

import {popBackSingleView} from "../store";
import {useSingleViewPerspective} from "./PerspectiveHooks";

export const useSingleViewHistory = () => {
  const dispatch = useDispatch();
  const perspective = useSingleViewPerspective();

  const popBackPossible: boolean = useMemo(() => {
    return !!perspective && perspective.history.length > 0;
  }, [perspective]);

  const popBack = useCallback(() => {
    dispatch(popBackSingleView());
  }, [dispatch]);

  return {
    popBack,
    popBackPossible,
  };
};
