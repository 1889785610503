/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

export const EntityProperties = {
  ID_PROPERTY: "id",
  CREATED_AT_PROPERTY: "createdAt",
  CREATED_BY_PROPERTY: "createdBy",
  UPDATED_AT_PROPERTY: "updatedAt",
  DYNAMIC_ATTRIBUTES_PROPERTY: "dynamicAttributes",
};
