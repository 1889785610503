/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {TabGroupTab, useAvailableViewDescriptors, useMessages} from "@icm/core-common";
import {useMemo} from "react";

export const useTabTitle = (tab: TabGroupTab) => {
  const availableViewDescriptors = useAvailableViewDescriptors();
  const {getMessage} = useMessages();
  const {viewType} = tab.viewModel;

  return useMemo(() => {
    const viewDescriptor = availableViewDescriptors[viewType];
    if (!viewDescriptor) {
      throw Error(`Invalid view ${viewType}`);
    }
    return viewDescriptor.getTitle(tab.viewModel, getMessage);
  }, [availableViewDescriptors, getMessage, viewType, tab.viewModel]);
};
