/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  COMPOSITE_VIEW,
  CompositeSubView,
  CompositeViewDataModelExtension,
  CONFIRM_YES_NO,
  DO_NOTHING,
  FormVariantWithTitle,
  getNamedComponentFactory,
  IViewProps,
  LOADER,
  MessageKey,
  ParameterUtilities,
  platformService,
  useMessages,
  useService,
  useViewModel,
  ViewContext,
  ViewDescriptor,
} from "@icm/core-common";
import {
  COMPLETE_PROCESS_TASK_ICON_BUTTON,
  COMPLETE_PROCESS_TASK_MENU_ITEM,
  TaskInstance,
  WorkflowUserAction,
} from "@icm/workflow-common";
import {isBoolean, isString} from "lodash-es";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {
  ActivityStreamSubView,
  EntityEditModelComponent,
  EntityEditModelComponentRef,
  EntityHistorySubView,
  EntitySaveParams,
  REFRESH_LAZY,
} from "../components";
import {ProcessDefinitionDetails} from "../data";
import {AbstractFormConfiguration, Entity, EntityConfiguration, EntityFormConfiguration} from "../generated/api";
import {useEntityConfiguration, useEntityUpdateResetEffect} from "../hooks";
import {DEFAULT_AFTER_SUBMIT_STRATEGY, isSubmitStrategy, SubmitStrategy} from "../store";
import {isEntity, isProcessDetails} from "../util";
import {createReportDownloadHandler} from "./EntityReportDownloadHandlerFactory";
import {useEntityEditModel, useFormConfiguration, useUpdatedViewModelData} from "./EntityViewHooks";


export type EntityEditorViewDataModel = {
  entityId?: string
  entityKey?: string
  entityType: string
  entityMetadata: Record<string, any>

  /**
   * The title related to the edit view.
   * This title will be updated based on the edit model content.
   */
  title: string,
  showActivityStream: boolean
  showHistory: boolean

  /**
   * Entity used to initialize the editModel. Will be undefined
   * after loading the view completely.
   */
  sourceEntity?: Entity
  formVariants: string[]

  entityConfiguration?: EntityConfiguration
  mapViewId?: string
  // ISO8601 date string
  highlightUpdatesSince?: string
  processDetails?: ProcessDefinitionDetails
  defaultDownloadPdfVariant?: string

  /**
   * Flag to indicate the entity being edited was never saved.
   * While loading this is unknown. Thus, the flag is optional.
   */
  isDraft?: boolean,

  readOnly: boolean,

  modified: boolean,

  afterSubmit: SubmitStrategy,

  reportUrlTemplate?: string,
  showSave: boolean,
  confirmClose: boolean,

} & CompositeViewDataModelExtension;

const DEFAULT_VARIANT_PLACEHOLDER = "";

function isEntityFormConfiguration(formConfiguration: AbstractFormConfiguration | undefined): formConfiguration is EntityFormConfiguration {
  return !!formConfiguration && formConfiguration?.formConfigurationType === "ENTITY";
}

function useHighlightUpdatesSince(highlightUpdatesSince: string | undefined) {
  const dateService = useService("DATE");
  return useMemo(() => dateService.parse(highlightUpdatesSince),
    [dateService, highlightUpdatesSince]);
}

type CompleteTaskSaveActionParameter = {
  id?: string,
  userActionId?: string,
}

type SaveActionParameter = {
  completeTask?: CompleteTaskSaveActionParameter,
}

function useUpdateActionHandlers(
  entityEditViewRef: React.RefObject<EntityEditModelComponentRef>,
  setViewActionHandlers: IViewProps<EntityEditorViewDataModel>["setViewActionHandlers"]
) {
  const {
    viewModelData,
  } = useViewModel<EntityEditorViewDataModel>();
  const {
    entityType,
    entityId,
    entityKey,
    sourceEntity,
    defaultDownloadPdfVariant,
    reportUrlTemplate,
    showSave,
  } = viewModelData;
  const editModel = useEntityEditModel({
    entityType,
    initialEntityId: entityId,
    initialEntityKey: entityKey,
    sourceEntity,
  });
  return useCallback((isValid: boolean) => {
    if (showSave) {
      setViewActionHandlers({
        SAVE: {
          run: () => entityEditViewRef.current?.save(),
          enabled: isValid && entityEditViewRef.current !== null && !editModel?.readOnly && (editModel?.propertyChanges.length || 0) > 0,
        },
        SAVE_AND_COMPLETE: {
          run: (actionParameter: SaveActionParameter) => {
            try {
              const saveParams: EntitySaveParams = {};
              if (actionParameter.completeTask && editModel?.entity) {
                saveParams.completeTaskId = actionParameter.completeTask.id;
                saveParams.completeTaskUserActionId = actionParameter.completeTask.userActionId;
              }
              entityEditViewRef.current?.save(saveParams);
            } catch (ex) {
              console.warn("A problem occurred while trying to save and complete", ex);
            }
          },
          enabled: isValid && entityEditViewRef.current !== null && !editModel?.readOnly,
        },
      });
    }

    const downloadHandler = createReportDownloadHandler({
      editModel,
      enabled: isValid,
      variant: defaultDownloadPdfVariant,
      urlTemplate: reportUrlTemplate,
    });

    if (downloadHandler) {
      setViewActionHandlers({
        DOWNLOAD: downloadHandler,
      });
    }
  }, [showSave, editModel, defaultDownloadPdfVariant, reportUrlTemplate, setViewActionHandlers, entityEditViewRef]);
}

/**
 *  Creates a view that combines a set of views
 */
const EntityEditViewComposite = ((props: IViewProps<EntityEditorViewDataModel>) => {
  const {getMessage} = useMessages();
  const {
    setViewActionHandlers,
  } = props;
  const {
    setViewModelData,
    viewModelData,
  } = useViewModel<EntityEditorViewDataModel>();
  const {
    entityType,
    entityId,
    entityKey,
    sourceEntity,
    formVariants,
    afterSubmit,
    readOnly,
    printRequested,
  } = viewModelData;

  // counter service may be required by expressions in the entity/form config
  const counterService = useService("COUNTER");
  const editModel = useEntityEditModel({
    entityType,
    initialEntityId: entityId,
    initialEntityKey: entityKey,
    sourceEntity,
  }); // FIXME: lifecycle, try catch in EntityEditService
  const entityConfiguration = useEntityConfiguration(entityType);
  const highlightUpdatesSince = useHighlightUpdatesSince(props.viewModel.viewModelData.highlightUpdatesSince);

  useEntityUpdateResetEffect(entityId, entityType);

  // check - this fetches causes a delay just for the title, at lease we should cache it in query client
  const [formVariantsWithTitle, setFormVariantsWithTitle] = useState<FormVariantWithTitle[]>([]);
  const fc = useFormConfiguration(entityType, DEFAULT_VARIANT_PLACEHOLDER, entityId);
  useEffect(() => {
    if (isEntityFormConfiguration(fc.data)) {
      const defaultVariantName = fc.data.variantName!;
      const defaultFormTitle = getMessage(MessageKey.ENTITY.FORM.DEFAULT.TITLE);
      const variants: FormVariantWithTitle[] = formVariants.map((vn) => {
        if (vn === DEFAULT_VARIANT_PLACEHOLDER) {
          const variantName = defaultVariantName;
          const variantTitle = fc.data?.formTypeDisplay ?? getMessage(`${entityType.toLowerCase()}.form.${variantName}.title`, {defaultMessage: defaultFormTitle});
          return {variantName: variantName, variantTitle: variantTitle};
        } else {
          const variantName = vn;
          const variantTitle = getMessage(`${entityType.toLowerCase()}.form.${variantName}.title`, {defaultMessage: ""});
          return {variantName: variantName, variantTitle: variantTitle};
        }
      });
      setFormVariantsWithTitle(variants);
    }
  }, [entityType, fc.data, formVariants, getMessage]);

  const updatedViewModelData = useUpdatedViewModelData(entityType, readOnly, entityConfiguration, editModel);
  useEffect(() => {
    if (editModel && updatedViewModelData) {
      setViewModelData(prevData => ({
        ...prevData,
        ...updatedViewModelData,
        entityConfiguration,
        entityId: editModel?.entity.id ?? prevData.entityId,
        // clear the source entity we passed using the viewModel
        // as we do not want it to be persisted as part of the view model.
        sourceEntity: undefined,
      }));
    }
  }, [editModel, setViewModelData, updatedViewModelData, entityConfiguration]);

  const entityEditViewRef = useRef<EntityEditModelComponentRef>(null);

  const updateActionHandlers = useUpdateActionHandlers(entityEditViewRef, setViewActionHandlers);

  const viewContext = React.useContext(ViewContext);
  if (viewContext && entityId) {
    viewContext.entityId = entityId;
  }

  const activityStreamSubView = ActivityStreamSubView({...viewModelData, editModel});
  const entityHistorySubView = EntityHistorySubView({...viewModelData, editModel, formVariants: formVariantsWithTitle});
  const CompositeView = getNamedComponentFactory(COMPOSITE_VIEW)
    || (() => <span>The composite view is not configured.</span>);
  if (editModel && counterService) {
    return (
      <CompositeView onRefresh={() => entityEditViewRef.current?.refresh()}>
        {formVariantsWithTitle.map((formVariant: FormVariantWithTitle) => (
          <CompositeSubView key={`form${formVariant.variantName}`}
                            id={`form${formVariant.variantName}`}
                            label={formVariant.variantTitle}
          >
            <EntityEditModelComponent formVariant={formVariant.variantName}
                                      refreshPolicy={REFRESH_LAZY}
                                      readOnly={(readOnly || editModel?.readOnly || printRequested) ?? false}
                                      printRequested={printRequested ?? false}
                                      editModel={editModel}
                                      ref={entityEditViewRef}
                                      submitStrategy={afterSubmit}
                                      highlightUpdatesSince={highlightUpdatesSince}
                                      onFormValidationChange={updateActionHandlers}
            />
          </CompositeSubView>
        ))}

        {activityStreamSubView}
        {entityHistorySubView}

      </CompositeView>
    );
  } else {
    const Loader = getNamedComponentFactory(LOADER) || (() => <span>{getMessage(MessageKey.CORE.LOADING)}</span>);
    return <Loader />;
  }
});

export const createEntityEditViewCompositeDescriptor = (): ViewDescriptor<EntityEditorViewDataModel> => ({
  viewType: "ENTITY_MULTI_EDITOR",
  view: EntityEditViewComposite,
  getTitle: (viewModel) => {
    const base = viewModel.viewLabel ?? viewModel.viewModelData.title;
    if ((viewModel.viewModelData.modified || viewModel.viewModelData.isDraft) && viewModel.viewModelData.showSave) {
      return `${base} *`;
    }
    return base;
  },
  getCloseOptions: (viewModelData, getMessage) => {
    if (viewModelData.modified && viewModelData.confirmClose) {
      return {
        optionsType: CONFIRM_YES_NO,
        title: getMessage(MessageKey.CORE.CONFIRMATION.DISCARD_CHANGES.TITLE),
        description: getMessage(MessageKey.CORE.CONFIRMATION.DISCARD_CHANGES.DESCRIPTION),
      };
    }
    return DO_NOTHING;
  },
  createUniqueHash: (viewModelData) => viewModelData.entityKey ?? "new",
  getViewActionDescriptors: (viewModel, getMessage) => ({
    SAVE_AND_COMPLETE: {
      icon: "save_move",
      title: getMessage(MessageKey.CORE.SAVE_AND_COMPLETE),
      visible: true,
      componentFactory: (key, action, executeViewAction, disabled, asMenuItem) => {
        const completeTaskProps = {
          icon: action.icon,
          text: action.title,
          entityId: viewModel.viewModelData.entityId!,
          handleClick: (task: TaskInstance, userAction: WorkflowUserAction) => executeViewAction(key,
            {completeTask: {id: task.id, userActionId: userAction.id}}),
          disabled: disabled,
        };
        const CompleteProcessTaskMenuItem = getNamedComponentFactory(COMPLETE_PROCESS_TASK_MENU_ITEM);
        const CompleteProcessTaskIconButton = getNamedComponentFactory(COMPLETE_PROCESS_TASK_ICON_BUTTON);
        if (asMenuItem && CompleteProcessTaskMenuItem) {
          return (
            <CompleteProcessTaskMenuItem {...completeTaskProps} />
          );
        } else if (CompleteProcessTaskIconButton) {
          return (
            <span key={key}>
              <CompleteProcessTaskIconButton {...completeTaskProps} />
            </span>
          );
        } else {
          return <span key={key} />;
        }
      },
    },
    SAVE: {
      title: getMessage(MessageKey.CORE.SAVE),
      icon: "save",
      visible: !viewModel.viewModelData.readOnly,
      type: "primary",
    },
    DOWNLOAD: {
      title: getMessage(MessageKey.CORE.DOWNLOAD._),
      icon: "cloud_download",
      type: "ternary",
      visible: platformService().isWeb,
    },
  }),
  getEditModelId: viewModelData => viewModelData.entityId,
  getShareParameters: (viewModel) => {
    const {
      entityId,
      entityType,
      selectedTabId,
      isDraft,
      entityKey,
      showSave,
    } = viewModel.viewModelData;

    if (isDraft || !entityId || !entityType || !entityKey || !showSave) {
      return undefined;
    }
    return {
      entityType,
      selectedTabId,
      entityKey,
    };
  },
  isPrintable: (viewModel): boolean => {
    return viewModel.viewModelData?.selectedTabId === "entityHistory"
      || viewModel.viewModelData?.selectedTabId === "formdefault"
      || viewModel.viewModelData?.selectedTabId === undefined;
  },
  updateViewModelData: (viewModelData, viewParameters) => {
    viewModelData.selectedTabId = ParameterUtilities.getResolvedParameterValue("selectedTabId", viewParameters, isString);
    viewModelData.printRequested = ParameterUtilities.getResolvedParameterValue("printRequested", viewParameters, isBoolean) ?? false;
  },
  initializeViewModelData: viewParameters => {
    const entity = ParameterUtilities.getResolvedParameterValue("entity", viewParameters, isEntity);
    const printRequested = ParameterUtilities.getResolvedParameterValue("printRequested", viewParameters, isBoolean);
    const sourceEntity = ParameterUtilities.getResolvedParameterValue("sourceEntity", viewParameters, isEntity);
    const entityTypeParam = ParameterUtilities.getResolvedParameterValue("entityType", viewParameters, isString);
    const entityIdParam = ParameterUtilities.getResolvedParameterValue("entityId", viewParameters, isString);
    const entityKeyParam = ParameterUtilities.getResolvedParameterValue("entityKey", viewParameters, isString);
    const selectedTabId = ParameterUtilities.getResolvedParameterValue("selectedTabId", viewParameters, isString);
    const defaultDownloadPdfVariant = ParameterUtilities.getResolvedParameterValue("defaultDownloadPdfVariant", viewParameters, isString);
    const highlightUpdatesSince = ParameterUtilities.getResolvedParameterValue("highlightUpdatesSince", viewParameters, isString);
    const afterSubmit = ParameterUtilities.getResolvedParameterValue("afterSubmit", viewParameters, isSubmitStrategy) ?? DEFAULT_AFTER_SUBMIT_STRATEGY;
    const entityType = entityTypeParam ?? entity?.type;
    const entityId = entity?.id ?? entityIdParam;
    const entityKey = entity?.keys?.[0]?.value ?? entityKeyParam;

    const entityMetadata = entity?.metadata ?? {};
    if (!entityType) {
      throw Error("No entity type given");
    }

    const formVariants = viewParameters.arrayParameters?.find(param => param.key === "formVariants")?.values ?? [""];

    const showActivityStream = ParameterUtilities.getResolvedFlag("activityStream", viewParameters, false);
    const showHistory = ParameterUtilities.getResolvedFlag("history", viewParameters, false);
    const readOnly = ParameterUtilities.getResolvedFlag("readOnly", viewParameters, false);
    const mapViewId = ParameterUtilities.getResolvedParameterValue("mapViewId", viewParameters, isString);
    const processDetails = ParameterUtilities.getResolvedParameterValue("processDetails", viewParameters, isProcessDetails);

    const reportUrlTemplate = ParameterUtilities.getResolvedParameterValue("reportUrlTemplate", viewParameters, isString);
    const showSave = ParameterUtilities.getResolvedFlag("showSave", viewParameters, true);

    const confirmClose = ParameterUtilities.getResolvedFlag("confirmClose", viewParameters, true);

    return {
      entityId, // actually the key, which is sometimes identical to the id
      entityKey,
      entityType,
      entityMetadata,
      selectedTabId,
      showActivityStream,
      showHistory,
      sourceEntity,
      formVariants,
      mapViewId,
      highlightUpdatesSince,
      readOnly,
      modified: false,
      processDetails,
      defaultDownloadPdfVariant,
      afterSubmit,
      reportUrlTemplate,
      showSave,
      confirmClose,
      printRequested: printRequested ?? false,
      title: "...",
    };
  },
});
