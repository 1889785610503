/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isNonEmptyString, JournalController, JournalEntry, StoreService, useService} from "@icm/core-common";
import {
  addWeakEntity,
  asJournalEntry,
  createJournalEntityEntry,
  EntityAlikeObject,
  entityAttributeService,
  isJournalEntryEntity,
  RELOAD_AFTER_SUBMIT,
  SubmitEntityEditModel,
  useEntityEditModel,
} from "@icm/entity-common";
import {useMemo, useState} from "react";

/**
 * Return a controller for a Journal widget that uses the entity edit model.
 *
 * @param entity The entity that will hold the journal entries
 * @param dynamicAttributeName The name of the attribute that will hold the journal entries in the parent entitiy
 * @param weakEntityType The type of weak entity to be created. The entity must conform to the JournalEntryEntity interface
 * @see JournalEntryEntity
 */
export const useEntityJournalController = (entity: EntityAlikeObject, dynamicAttributeName: string, weakEntityType: string): JournalController | undefined => {
  const editModel = useEntityEditModel({entityType: entity.type, initialEntityId: entity.id});

  const securityService = useService("SECURITY");
  const userData = useMemo(() => {
    const roleName = securityService.getFirstActiveRoleName() ?? "";
    const userName = securityService.getCurrentUserFullName();
    return {
      roleName,
      userName,
    };
  }, [securityService]);


  const [message, setMessage] = useState<string>("");

  return useMemo(() => {
    if (editModel) {
      const values =  entityAttributeService.getWeakAttributeValues(editModel.entity, dynamicAttributeName)
        .filter(isJournalEntryEntity)
        .map(asJournalEntry);

      if (values) {
        return {
          getCurrentMessage(): string {
            return message;
          },
          getJournalEntries(): Readonly<JournalEntry[]> {
            return values;
          },
          isEditable(): boolean {
            return editModel.readOnly === false;
          },
          setCurrentMessage(text: string): void {
            setMessage(text);
          },
          submitCurrentMessage(): void {
            if (isNonEmptyString((message))) {
              const entryEntity = createJournalEntityEntry(message, userData.roleName, userData.userName, weakEntityType);

              StoreService.store.dispatch(addWeakEntity({
                entityId: entity.id,
                attributeName: dynamicAttributeName,
                weakEntity: entryEntity,
              }));

              setMessage("");

              StoreService.store.dispatch(SubmitEntityEditModel({
                entityId: entity.id,
                submitStrategy: RELOAD_AFTER_SUBMIT,
              }));
            }
          },
        };
      }
    }
    return undefined;
  }, [editModel, dynamicAttributeName, message, userData.roleName, userData.userName, weakEntityType, entity.id]);
};
