/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";

import {IUiState} from "../types";

/**
 * A reducer/action that can be dispatched to update the connection status.
 * When disconnected certain actions may become disabled.
 *
 * @param state
 * @param action
 * @constructor
 */
export const SetConnectionStatus = (state: IUiState, action: PayloadAction<boolean>) => {
  state.connected = action.payload;
};
