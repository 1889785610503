/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {RoleApi} from "@icm/core-common";
import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";
import PropertiesActivator from "bpmn-js-properties-panel/lib/PropertiesActivator";
import idProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/IdProps";
import nameProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/NameProps";
import CamundaPropertiesProvider from "bpmn-js-properties-panel/lib/provider/camunda/CamundaPropertiesProvider";
import * as elementTemplatesModule from "bpmn-js-properties-panel/lib/provider/camunda/element-templates";
import {is} from "bpmn-js/lib/util/ModelUtil";
import translateModule from "diagram-js/lib/i18n/translate";
import inherits from "inherits";

const PROCESS_KEY_HINT = "This maps to the process definition key.";
const TASK_KEY_HINT = "This maps to the task definition key.";
export const ASSIGNEE_SELECT_BOX_ID = "assignee_tmp";

function getIdOptions(element) {
  if (is(element, "bpmn:Process")) {
    return {description: PROCESS_KEY_HINT};
  } else if (is(element, "bpmn:UserTask")) {
    return {description: TASK_KEY_HINT};
  } else {
    return {description: ""};
  }
}

function createGeneralTabGroups(
  element, canvas, bpmnFactory,
  elementRegistry, elementTemplates, translate
) {
  // refer to target element for external labels
  element = element.labelTarget || element;

  const generalGroup = {
    id: "general",
    label: translate("General"),
    entries: [],
  };

  idProps(generalGroup, element, translate, getIdOptions(element));
  nameProps(generalGroup, element, bpmnFactory, canvas, translate);

  // user tasks
  if (is(element, "camunda:Assignable")) {
    const rolesSelectBox = entryFactory.selectBox(translate, {
      id: ASSIGNEE_SELECT_BOX_ID, // we get the value from the select dynamically, but we still need to have id here as well as modelProperty
      label: translate("Assignee"),
      modelProperty: ASSIGNEE_SELECT_BOX_ID,
      selectOptions: [{name: "Loading roles...", value: "Loading roles..."}],
    });

    const rolesSelectHtml = rolesSelectBox.html.querySelector("select");
    rolesSelectHtml.multiple = true;

    // this would probably be better in BpmnProcessEditor,
    // but we would need to figure out, how to pass the data to properties panel
    RoleApi.getAllRoles()
      .then(data => {
        let options = "";

        data.forEach(row => {
          let selected = "";
          if (element.businessObject.candidateGroups !== undefined) {
            const values = element.businessObject.candidateGroups.split(separator);
            selected = values.includes(row.name) ? " selected " : "";
          }

          options += `<option value='${row.name}'${selected}>${row.name}</option>`;
        });

        rolesSelectHtml.innerHTML = options;
        rolesSelectHtml.size = data.length;
      });

    const separator = ", ";

    // set candidateGroups value correctly
    const changeHandler = () => {
      const values = [];
      for (const item of rolesSelectHtml.selectedOptions) {
        values.push(item.value);
      }

      element.businessObject.candidateGroups = values.join(separator);
    };

    rolesSelectHtml.addEventListener("change", changeHandler);

    // override default function for setting data to use candidateGroups instead it's value
    rolesSelectBox.setControlValue = () => {
      if (element.businessObject.candidateGroups !== undefined) {
        const values = element.businessObject.candidateGroups.split(separator);

        for (const option of rolesSelectHtml.options) {
          option.selected = values.includes(option.value);
        }
      }
    };

    generalGroup.entries.push(rolesSelectBox);
  }

  const groups = [];
  groups.push(generalGroup);
  return groups;
}

function IcmPropertiesProvider(
  bpmnFactory,
  canvas,
  commandStack,
  elementRegistry,
  elementTemplates,
  eventBus,
  modeling,
  replace,
  selection,
  translate
) {
  CamundaPropertiesProvider.bind(this)(
    bpmnFactory,
    canvas,
    commandStack,
    elementRegistry,
    elementTemplates,
    eventBus,
    modeling,
    replace,
    selection,
    translate
  );
  this.getTabs = (element) => {
    const generalTab = {
      id: "general",
      label: translate("General"),
      groups: createGeneralTabGroups(
        element, canvas, bpmnFactory,
        elementRegistry, elementTemplates, translate
      ),
    };
    return [
      generalTab,
    ];
  };
}

IcmPropertiesProvider.$inject = [
  "bpmnFactory",
  "canvas",
  "commandStack",
  "elementRegistry",
  "elementTemplates",
  "eventBus",
  "modeling",
  "replace",
  "selection",
  "translate",
];

inherits(IcmPropertiesProvider, PropertiesActivator);

const IcmPropertiesProviderModule = {
  __depends__: [
    elementTemplatesModule,
    translateModule,
  ],
  __init__: ["propertiesProvider"],
  propertiesProvider: ["type", IcmPropertiesProvider],
};

export default IcmPropertiesProviderModule;
