/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box";
import {createStyles, makeStyles} from "@mui/styles";
import clsx from "clsx";
import React, {PropsWithChildren} from "react";

const useStyles = makeStyles(theme => createStyles({
  top: {
    borderTop: 1,
    borderTopColor: theme.palette.divider,
  },
  right: {
    borderRight: 1,
    borderRightColor: theme.palette.divider,
  },
  left: {
    borderLeft: 1,
    borderLeftColor: theme.palette.divider,
  },
  bottom: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type BorderBoxProps = {
  top?: boolean,
  bottom?: boolean,
  right?: boolean,
  left?: boolean,
} & Pick<BoxProps, "className">;

export const BorderBox: React.FC<PropsWithChildren<BorderBoxProps>> = ({top, bottom, right, left, ...props}) => {
  const styles = useStyles();
  return (
    <Box {...props}
         className={clsx({
           [styles.top]: top,
           [styles.bottom]: bottom,
           [styles.right]: right,
           [styles.left]: left,
         }, props.className)}
    />
  );
};
