/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FilterAndModifier} from "@icm/core-common";
import {Feature} from "geojson";
import {createAction, createAsyncAction} from "typesafe-actions";

import {Bookmark, Layer, MapConfiguration, MapProperties, Source} from "../../generated/api";

export interface IMapState {
  /** the map configuration includes a padding as a fraction of the map size for fit to bounds (used for zooming to features) */
  mapConfiguration?: Omit<MapConfiguration, "map"> & {map?: MapProperties & {padding?: number}};
  features?: {
    [name: string]: Feature[];
  };
}

export const fetchMapConfiguration = createAsyncAction(
  "@@map/FETCH_MAP_CONFIGURATION_REQUEST",
  "@@map/FETCH_MAP_CONFIGURATION_SUCCESS",
  "@@map/FETCH_MAP_CONFIGURATION_FAILURE",
)<string | undefined, MapConfiguration, Error>();

export const resetMapConfiguration = createAsyncAction(
  "@@map/RESET_MAP_CONFIGURATION_REQUEST",
  "@@map/RESET_MAP_CONFIGURATION_SUCCESS",
  "@@map/RESET_MAP_CONFIGURATION_FAILURE",
)<string | undefined, MapConfiguration, Error>();

export const saveMapConfiguration = createAsyncAction(
  "@@map/SAVE_MAP_CONFIGURATION_REQUEST",
  "@@map/SAVE_MAP_CONFIGURATION_SUCCESS",
  "@@map/SAVE_MAP_CONFIGURATION_FAILURE",
)<string | undefined, boolean, Error>();

export const setLayerGroupState = createAction("@@map/SET_LAYER_OPEN",
  (layerId: string, open: boolean) => ({layerId, open}))();

export const setLayerVisibility = createAction("@@map/SET_LAYER_VISIBILITY",
  (layerId: string, visible: boolean) => ({layerId, visible}))();

export const setLayerOpacity = createAction("@@map/SET_LAYER_OPACITY",
  (layerId: string, opacity: number) => ({layerId, opacity}))();

export const setLayerFilter = createAction("@@map/SET_LAYER_FILTER",
  (layerId: string, filter?: FilterAndModifier) => ({layerId, filter}))();

export const setLayerSearch = createAction("@@map/SET_LAYER_SEARCH",
  (layerId: string, filter?: FilterAndModifier) => ({layerId, filter}))();

export const clearLayerFilterAndSearch = createAction("@@map/CLEAR_LAYER_FILTER_AND_SEARCH",
  (layerId: string) => ({layerId}))();

export const moveLayer = createAction("@@map/MOVE_LAYER",
  (layerId: string, parentLayerId?: string, beforeLayerId?: string) => ({layerId, parentLayerId, beforeLayerId}))();

export const addLayer = createAction("@@map/ADD_LAYER",
  (source: Source, layer: Layer, parentLayerId?: string) => ({source, layer, parentLayerId}))();

export const removeLayer = createAction("@@map/REMOVE_LAYER",
  (layerId: string) => ({layerId}))();

export const updateSource = createAction("@@map/UPDATE_SOURCE",
  (sourceId: string, mapboxSource: string) => ({sourceId, mapboxSource}))();

export const addBookmark = createAction("@@map/ADD_BOOKMARK",
  (bookmark: Bookmark) => bookmark)();

export const removeBookmark = createAction("@@map/REMOVE_BOOKMARK",
  (bookmarkName: string) => bookmarkName)();

export const centerMap = createAction("@@map/CENTER_MAP",
  (center: [number, number], zoom?: number) => ({center, zoom}))();

/** Fit the map view to the bounds [x1, y1, x2, y2] with a padding as fraction of the map size */
export const fitBounds = createAction("@@map/FIT_BOUNDS",
  (bounds: [number, number, number, number], padding: number = 0) => ({bounds, padding}))();

export const setFeatures = createAction("@@map/SET_FEATURES",
  (features: Feature[], name: string = "") => ({name, features}))();
