/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import * as React from "react";

import {ExpressionEvaluationService, FetchService} from "../../../service";
import {getListOrSublist} from "../../../types";
import {AbstractFormComponent, IFormComponentProps} from "../AbstractFormComponent";

export interface ISelectComponentProps<T> extends IFormComponentProps<string> {
  // use selectedValue instead
  value?: undefined
  // either provide value directly or provide value bound by valueBinding
  selectedValue?: any
  availableValues?: T[]
  apiUrl?: string
  valueBinding: string
  versionBinding?: string
  valueDisplay?: string
  hideClearButton?: boolean
  className?: string
  handleChange: (newValueKey?: string | ValueWithVersion, resolveValue?: () => T | undefined) => void,
}

export interface IState<T> {
  availableValues?: T[];
  anchor?: HTMLElement;
  searchText?: string;
  filteredValues?: T[];
}

export type ValueWithVersion = {
  value: string,
  version: string,
  valueDisplay: string
}

abstract class SelectComponent<T, P extends ISelectComponentProps<T>> extends AbstractFormComponent<string, P, IState<T>> {
  public constructor(props: Readonly<P>) {
    super(props);
    this.state = {};
    if (this.props.apiUrl) {
      this.loadAvailableValues(this.props.apiUrl!);
    } else if (this.props.availableValues) {
      this.state = {
        availableValues: this.props.availableValues,
      };
    } else {
      throw new Error("either apiUrl or availableValues required for rendering");
    }
  }

  protected abstract renderEditableComponent(): React.ReactNode

  protected abstract getSelectedValues(): any[]

  protected renderReadOnlyText(): string | undefined {
    if (this.props.selectedValue && this.props.selectedValue.label) {
      return this.props.selectedValue.label;
    } else if (this.props.selectedValue && this.props.selectedValue.valueDisplay) {
      return this.props.selectedValue.valueDisplay;
    } else {
      const availableValues = this.getAvailableValues();
      if (availableValues) {
        const value: T | undefined = this.getDataSourceValue(this.props.selectedValue);
        if (value) {
          if (this.props.valueDisplay) {
            return this.evaluateValueDisplay(value);
          } else {
            return `${value}`;
          }
        }
      }
      return undefined;
    }
  }

  protected evaluateValueDisplay(value: T | undefined, fallbackToValueBinding = true) {
    if (value) {
      if (this.props.valueDisplay) {
        return ExpressionEvaluationService.evaluate(this.props.valueDisplay!, value);
      } else if (fallbackToValueBinding && this.props.valueBinding) {
        return ExpressionEvaluationService.get(value, this.props.valueBinding);
      }
    }
    return undefined;
  }

  protected getAvailableValues(): T[] | undefined {
    return this.state.availableValues || this.props.availableValues;
  }

  protected getDataSourceValue(value?: any, availableValues: T[] | undefined = this.getAvailableValues()): T | undefined {
    if (value) {
      const resolvedValue = value.value || value;
      if (typeof resolvedValue === "string") {
        if (availableValues) {
          return availableValues.find(v => ExpressionEvaluationService.get(v, this.props.valueBinding) === resolvedValue);
        }
      } else {
        return value;
      }
    }
    return undefined;
  }

  componentDidUpdate(prevProps: Readonly<IFormComponentProps<string> & P>, prevState: Readonly<IState<T>>, snapshot?: any): void {
    if (this.props.apiUrl && this.props.apiUrl !== prevProps.apiUrl) {
      this.loadAvailableValues(this.props.apiUrl!);
    } else if (prevState.availableValues && this.state.availableValues !== prevState.availableValues) {
      this.checkSelectedValueInAvailableValues();
      this.checkSelectedValueWithVersion();
    } else if (!prevState.availableValues || this.state.availableValues !== prevState.availableValues) {
      this.checkSelectedValueWithVersion();
    }
  }

  private loadAvailableValues(apiUrl: string) {
    return FetchService.performGet(apiUrl)
      .then((result: any) => {
        const values: T[] = getListOrSublist(result);
        this.setState({
          ...this.state,
          availableValues: values,
        });
      })
      .catch(e => console.log(e));
  }

  protected getSelectedValueKey(): any {
    return this.getValueKeyForValue(this.props.selectedValue);
  }

  protected getValueKeyForValue(value: any): any {
    if (typeof (value) === "string") {
      return value;
    } else if (value && !!value.value && typeof (value.value) === "string") {
      return value.value;
    } else {
      const dataSourceValue = this.getDataSourceValue(value);
      return ExpressionEvaluationService.get(dataSourceValue, this.props.valueBinding);
    }
  }

  protected getValue(value: any, valueDisplay: any): any {
    if (typeof (value) === "string") {
      return {
        value: value,
        valueDisplay: valueDisplay,
      };
    } else if (value && value.value && value.label) {
      return value;
    } else {
      const dataSourceValue = this.getDataSourceValue(value);
      const boundValue = ExpressionEvaluationService.get(dataSourceValue, this.props.valueBinding);
      if (typeof (boundValue) === "string") {
        if (this.props.versionBinding) {
          return {
            value: boundValue,
            version: ExpressionEvaluationService.get(dataSourceValue, this.props.versionBinding!),
            valueDisplay: valueDisplay,
          } as ValueWithVersion;
        } else {
          return boundValue;
        }
      } else {
        return boundValue;
      }
    }
  }

  // checks if selected value(s) are (still) in available values, triggers handle change if not
  protected checkSelectedValueInAvailableValues() {
    const selectedValue = this.getDataSourceValue(this.props.selectedValue);
    // getDataSourceValue returns only value if in availableValues
    if (this.props.selectedValue && !selectedValue) {
      this.props.handleChange(undefined, () => undefined);
    }
  }

  private getSingleValueOrExtendedArray(existingValue: any, additionalValue: any) {
    if (!existingValue) {
      return this.isMultiSelect() ? [additionalValue] : additionalValue;
    } else if (Array.isArray(existingValue)) {
      return [...existingValue, additionalValue];
    } else {
      return [existingValue, additionalValue];
    }
  }

  protected isMultiSelect(): boolean {
    return false;
  }

  protected checkSelectedValueWithVersion(): void {
    const selectedValues: any[] = this.getSelectedValues() || [];
    let currentValueKey: any = undefined;
    let currentValue: any = undefined;
    let hasVersionChange: boolean = false;
    selectedValues.forEach(selectedValue => {
      if (!this.props.readOnly && selectedValue && selectedValue.value) {
        const selectedValueWithVersion = selectedValue as ValueWithVersion;
        const dataSourceValue = this.getDataSourceValue(selectedValue);
        const dataSourceDisplayValue = this.evaluateValueDisplay(dataSourceValue);
        const dataSourceValueWithVersion = this.getValue(dataSourceValue, dataSourceDisplayValue) as ValueWithVersion;
        if (dataSourceValueWithVersion && dataSourceValueWithVersion.version
          && (selectedValueWithVersion.version !== dataSourceValueWithVersion.version
            || selectedValueWithVersion.valueDisplay !== dataSourceValueWithVersion.valueDisplay)) {
          hasVersionChange = true;
          currentValueKey = this.getSingleValueOrExtendedArray(currentValueKey, dataSourceValueWithVersion);
          currentValue = this.getSingleValueOrExtendedArray(currentValue, dataSourceValue);
        } else if (this.isMultiSelect()) {
          currentValueKey = this.getSingleValueOrExtendedArray(currentValueKey, dataSourceValueWithVersion);
          currentValue = this.getSingleValueOrExtendedArray(currentValue, dataSourceValue);
        }
      }
    });
    if (hasVersionChange) {
      this.props.handleChange(currentValueKey, () => currentValue);
    }
  }
}

export {SelectComponent};
