/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Person} from "../generated/api";

export interface IEntityInfo<T> {
  convert: (data: any) => T
  create: () => T
  getId: (entity: T) => string | undefined
}

export type EntityType = "PERSON" | "UNKNOWN";

export const dataConverters: {readonly [type in EntityType]: IEntityInfo<any>} = {
  PERSON: {
    convert: Person.fromData,
    create: () => new Person(),
    getId: (entity: Person) => entity.id,
  },
  UNKNOWN: {
    convert: (data: any) => data,
    create: () => ({}),
    getId: (entity) => undefined,
  },
};
