/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ModuleRegistrationOptions,
  registerNamedComponentFactory,
  registerWidgetFactory,
  TEXT_AREA,
  TEXT_FIELD,
} from "@icm/core-common";
import {contributedObjectSelects, registerDetailPanelRendererFactory} from "@icm/core-web";
import {
  createEntityEditViewCompositeDescriptor,
  createEntityListViewDescriptor,
  ENTITY_HISTORY,
  ENTITY_JOURNAL_WIDGET,
  ENTITY_LIST,
  ENTITY_LIST_WIDGET,
  ENTITY_WORKFLOW_ACTIVE_TASKS_WIDGET,
  WEATHER_BUTTON,
} from "@icm/entity-common";

import {
  entityListComponentFactory,
  EntitySelect,
  EntityValueDisplay,
  isEntitySource,
  SublistDetailRendererFactory,
  WebEntityJournalWidget,
  WebEntityListWidget,
} from "./src/components";
import {WebFormTextComponent} from "./src/components/form/textbox/WebFormTextComponent";
import {weatherComponentFactory} from "./src/components/weather/WeatherComponent";
import {WebEntityWorkflowActiveTasksWidget} from "./src/components/workflow/WebEntityWorkflowActiveTasksWidget";
import packageInfo from "./src/generated/package.clone.json";
import {entityHistoryViewComponentFactory} from "./src/pages/EntityHistoryView";
import {entityHistoryViewDescriptor} from "./src/pages/EntityHistoryViewDescriptor";
import {EntityListView} from "./src/pages/EntityListView";
import {formComponentDescriptor, formViewDescriptor} from "./src/pages/FormView";

export {EntitySelect};

export const entityWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  runPostInitialization: () => {
    contributedObjectSelects.push({
      acceptSource: isEntitySource,
      LinkedSelect: EntitySelect,
      LinkedObjectDisplay: EntityValueDisplay,
    });
    registerNamedComponentFactory(ENTITY_HISTORY, entityHistoryViewComponentFactory);
    registerNamedComponentFactory(ENTITY_LIST, entityListComponentFactory);
    registerNamedComponentFactory(formComponentDescriptor);
    registerNamedComponentFactory(TEXT_AREA, WebFormTextComponent);
    registerNamedComponentFactory(TEXT_FIELD, WebFormTextComponent);
    registerNamedComponentFactory(WEATHER_BUTTON, weatherComponentFactory);
    registerWidgetFactory(ENTITY_LIST_WIDGET, WebEntityListWidget);
    registerWidgetFactory(ENTITY_JOURNAL_WIDGET, WebEntityJournalWidget);
    registerWidgetFactory(ENTITY_WORKFLOW_ACTIVE_TASKS_WIDGET, WebEntityWorkflowActiveTasksWidget);
    registerDetailPanelRendererFactory("SUBLIST", SublistDetailRendererFactory);
  },
  providedViews: [
    createEntityListViewDescriptor(EntityListView),
    createEntityEditViewCompositeDescriptor(),
    entityHistoryViewDescriptor,
    formViewDescriptor,
  ],
};
