/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useQuery} from "react-query";

import {EntityConfiguration} from "../generated/api";
import {entityDataService} from "../service";


/**
 * Gets the entity configuration for the given type and all configurations that are directly referred in it.
 *
 * Call is backed by query client.
 * @param entityType
 */
export const useEntityConfigurationAndRelated = (entityType: string) => {
  const query = useQuery<Map<string, EntityConfiguration>>(["private", "entity", "entityConfigurationAndRelated", entityType], async () => {
    return entityDataService.getEntityConfigurationForEntityAndRelated(entityType);
  });
  const entityConfigurations: Map<string, EntityConfiguration> | undefined = query.data;
  const entityConfiguration = entityConfigurations ? entityConfigurations.get(entityType) : undefined;
  return {
    entityConfigurations,
    entityConfiguration,
  };
};
