/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  CoreApi,
  FieldHighlightMap,
  GenericFormConfiguration,
  GenericFormGroup,
  useFormGeneratorContext,
  useFormGroup,
} from "@icm/core-common";
import {ExpandMore as ExpandMoreIcon} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Icon, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import React, {FC, useMemo} from "react";

import {FilledTextChip} from "../../util";
import {WebFormField} from "./WebFormField";
import {styles} from "./WebFormGroup.style";
import {WebFormWidget} from "./WebFormWidget";

type Props = {
  formConfig: GenericFormConfiguration
  group: GenericFormGroup,
  highlightedFields?: FieldHighlightMap,
}
const useStyles = makeStyles(styles);

export const WebFormGroup: FC<Props> = (props) => {
  const {group} = props;
  const {evaluateRule} = useFormGeneratorContext();
  const classes = useStyles();
  const {
    badgeColor,
    highlightedFields,
    expanded,
    collapsable,
    showHeader,
  } = useFormGroup(evaluateRule, group, props.highlightedFields);

  const defaultExpanded = useMemo(() => !evaluateRule(group.collapsedByDefault, false), [evaluateRule, group.collapsedByDefault]);

  const accordionSummary = (
    <AccordionSummary expandIcon={collapsable ? <ExpandMoreIcon /> : null}
                      className={clsx({
                        [classes.groupHeader]: true,
                        [classes.displayNone]: !showHeader,
                      })}
    >
      <Typography variant="h6">
        <Grid container={true} alignItems="center">
          {group.icon && (
            <Icon className={classes.groupIcon} fontSize="inherit">{group.icon}</Icon>
          )}
          {group.label}
          {highlightedFields.length > 0 && (
            <Typography variant="caption">
              <FilledTextChip className={classes.groupBadge} color={badgeColor} size="small">
                {highlightedFields.length}
              </FilledTextChip>
            </Typography>
          )}
        </Grid>
      </Typography>
    </AccordionSummary>
  );

  const accordionDetails = (
    <AccordionDetails>
      <Grid container={true} spacing={2}>
        {group.widgets?.map((widget, key) => (
          <React.Fragment key={key}>
            {widget.widgetType === "GENERIC_FIELD"
              ? <WebFormField group={group} field={widget as CoreApi.GenericFormField} />
              : <WebFormWidget group={group} widget={widget as CoreApi.AbstractWidget} />}
          </React.Fragment>
        ))}
      </Grid>
    </AccordionDetails>
  );

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      square={true}
      className={classes.groupAccordionContainer}
    >
      {accordionSummary}
      {accordionDetails}
    </Accordion>
  );
};
