/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isEqual} from "lodash-es";

import {ISelectComponentProps, SelectComponent} from "./SelectComponent";

export abstract class SingleSelectComponent<T, P extends ISelectComponentProps<T>> extends SelectComponent<T, P> {
  protected handleRowChange(availableValues: T[], value: any) {
    this.props.handleChange(value, this.getDataSourceValue(value, availableValues));
  }

  protected getSelectedValues(): any[] {
    return [this.props.selectedValue];
  }

  protected isSelected(value: any): boolean {
    // a comparison of stringified JSON only works by chance.
    // using lodash isEqual is better, but in reality the comparison of objects should only include the relevant properties
    // e.g. the id or id + version
    const selectedValueKey = this.getSelectedValueKey();
    const valueKey = this.getValueKeyForValue(value);
    return isEqual(selectedValueKey, valueKey);
  }
}
