/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmMuiTheme} from "@icm/core-web";
import {createStyles} from "@mui/styles";


const styles = (theme: IcmMuiTheme) => createStyles({
  root: {
    padding: theme.spacing(1),
  },
  titleText: {
    flex: 1,
    padding: theme.spacing(0, 3),
  },
  changesContainer: {
    padding: theme.spacing(0),
  },
  changesList: {
    flex: 1,
    padding: theme.spacing(0, 3),
  },
  changeSecondary: {
    color: theme.palette.text.secondary,
  },
  actor: {
    fontWeight: "bold",
  },
  field: {},
  oldValue: {
    textDecoration: "line-through",
    color: theme.palette.text.secondary,
  },
  newValue: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  icon: {
    // color: theme.palette.text.secondary,
    // fontSize: theme.typography.body2.fontSize,
    fontSize: "inherit",
    position: "relative",
    top: 1,
  },
  propertyChangeList: {
    padding: 0,
    margin: 0,
    listStyle: "inside",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
});

export default styles;
