/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

type ValidatorConfiguration = {
  phoneNumberPattern?: string
  phoneNumberExamples?: string[]
};

class ValidatorService {

  public static INSTANCE = new ValidatorService();

  public readonly defaultPhoneNumberPattern = /^[+]?[0-9][0-9 ]*([/] *[0-9][0-9 ]*)?([-] *[0-9][0-9 ]*)?$/;
  public readonly defaultPhoneNumberExamples = ["0 123 456 789", "+43 123 456 789", "+43 123 / 456 789", "+43 123 456 - 789", "+43 123 / 456 - 789"];

  private readonly phoneNumberPattern: RegExp;
  public readonly phoneNumberExamples: string[];

  constructor(config?: ValidatorConfiguration) {
    if (config?.phoneNumberPattern) {
      this.phoneNumberPattern = new RegExp(config.phoneNumberPattern);
    } else {
      this.phoneNumberPattern = this.defaultPhoneNumberPattern;
    }

    this.phoneNumberExamples = config?.phoneNumberExamples ?? this.defaultPhoneNumberExamples;
  }

  /**
   * Checks a phone number for validity based on the configured pattern.
   * @param phoneNumber the phone number
   */
  public isValidPhoneNumber(phoneNumber: string) {
    return this.phoneNumberPattern.test(phoneNumber);
  }

  /**
   * Checks basic properties of an email for validity.
   * @param email the email address
   */
  public isValidEmail(email: string): boolean {
    const [localPart, domainPart, rest] = email.split("@");
    if (localPart && domainPart && !rest) {
      // verify each (sub)domain/tld is not empty
      return !email.includes(" ")
        && localPart.trim().length <= 64
        && domainPart.length <= 255
        && domainPart.includes(".")
        && domainPart.split(".").reduce((valid, value) => valid && !!value, true);
    }
    return false;
  }

}

const validatorService = ValidatorService.INSTANCE;

export {validatorService, ValidatorService};
