/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {WeatherService} from "@icm/core-common";
import {Position} from "geojson";

import {EntityApi} from "../api";

export class EntityWeatherService implements WeatherService {

  public async getWeatherData(coordinates: Position | undefined, date: Date | undefined): Promise<{ [p: string]: any }> {
    const entities = await EntityApi.getEntities("_WEATHER", {
      filter: {
        conditionType: "GROUP",
        logicalOperator: "AND",
        filterConditions: [
          {
            conditionType: "ITEM",
            valueType: "STRING",
            property: "latitude",
            operator: "EQUALS",
            values: coordinates ? [coordinates[0].toString()] : [],
          },
          {
            conditionType: "ITEM",
            valueType: "STRING",
            property: "longitude",
            operator: "EQUALS",
            values: coordinates ? [coordinates[1].toString()] : [],
          },
          {
            conditionType: "ITEM",
            valueType: "STRING",
            property: "date",
            operator: "EQUALS",
            values: date ? [date?.getTime().toString()] : [],
          },
        ],
      },
    });

    const weatherData = {};
    const dynamicAttributes = entities.sublist?.[0].dynamicAttributes as Object;
    Object.keys(dynamicAttributes as Object).forEach(key => {
      weatherData[key] = dynamicAttributes[key].value;
    });

    return weatherData;
  }
}
