/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isCommunicationChannel, CommunicationChannel} from "./CommunicationChannel";

export type CommunicationAddress = {
  channel: CommunicationChannel,
  label: string,
  addressValue: string
  id: string
}

export const isCommunicationAddress = (v: any): v is CommunicationAddress  => {
  if (v === null) {
    return false;
  } else if (typeof v === "object") {
    return typeof v["label"] === "string"
      && typeof v["id"] === "string"
      && typeof v["addressValue"] === "string"
      && isCommunicationChannel(v["channel"]);
  }
  return false;
};
