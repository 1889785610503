/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useMarkObjectAsSeen} from "@icm/activitystream-common";
import {IdGenerator} from "@icm/core-common";
import {useCallback} from "react";
import {useMutation} from "react-query";

import {EntityCommandApi} from "../api";
import {DeleteEntityCommand} from "../generated/api";
import {OnEntityDeleted} from "./useUpdatingInfiniteEntityList";

// returns a function, that when called deletes an entity
export const useDeleteEntity = (entityType: string, onEntityDeleted: OnEntityDeleted) => {
  const markAsSeen = useMarkObjectAsSeen();
  const deleteEntity =  useMutation(([id, version]: [string, string]) => {
    console.debug("Deleting entity", id);
    const command: DeleteEntityCommand = {
      entityCommandType: "DELETE",
      id: IdGenerator.randomUUID(),
      predecessorIds: [version],
      entityId: id,
      entityType: entityType,
      timestamp: new Date(),
    };
    console.debug("Sending command", command);
    return EntityCommandApi.processEntityCommands([command]);
  }, {
    onSettled: (data, error, [id, version], context) => {
      markAsSeen(entityType, id);
      onEntityDeleted(id);
    },
  });
  return useCallback((id: string, version: string) => deleteEntity.mutate([id, version]), [deleteEntity]);
};
