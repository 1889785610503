/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import moment from "moment";

// add imports for needed locales
import "moment/locale/de";
import "moment/locale/fr";
import MessageKey from "../generated/MessageKey";
import {TimeZoneProvider} from "../util";
import {ExpressionEvaluationService} from "./ExpressionEvaluationService";
import {messages} from "./MessageService";

type DateLength = "SHORT" | "LONG";
type TimeLength = "SHORT" | "MEDIUM";
type CompareType = "ASC" | "DESC";

/**
 * Formats used when truncating ISO Date-Time strings.
 * @see DateService.toTruncatedISOString
 */
export type DateTimeResolution =  "DAY" | "HOUR" | "MINUTE" | "SECOND" | "FULL";

export interface IDateFormats {
  date: {
    [key in DateLength]: string
  }
  time: {
    [key in TimeLength]: string
  }
}

class DateService {

  private static convertToMomentFormats(formatString: string) {
    return formatString.replace(/d/g, "D")
      .replace(/E/g, "d")
      .replace(/y/g, "Y");
  }

  private dateFormats: IDateFormats;

  private customDateFormats: Partial<Record<string, string>> = {};

  private MOMENT: IDateFormats;

  constructor() {
    this.formatDateTime = this.formatDateTime.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.formatDistanceToNow = this.formatDistanceToNow.bind(this);
    this.getStartOfDayFromNow = this.getStartOfDayFromNow.bind(this);
    this.getDifferenceInDays = this.getDifferenceInDays.bind(this);
    this.toTruncatedISOString = this.toTruncatedISOString.bind(this);
    ExpressionEvaluationService.registerHelper("date", "formatDateTime", this.formatDateTime);
    ExpressionEvaluationService.registerHelper("date", "formatDate", this.formatDate);
    ExpressionEvaluationService.registerHelper("date", "formatTime", this.formatTime);
    ExpressionEvaluationService.registerHelper("date", "formatDistanceToNow", this.formatDistanceToNow);
    ExpressionEvaluationService.registerHelper("date", "toISOString",
      (date: Date | "now" | undefined) => date && this.toISOString(date === "now" ? new Date() : date));
    ExpressionEvaluationService.registerHelper("date", "toTruncatedISOString",
      (date: Date | "now" | undefined, truncate: DateTimeResolution | undefined = "FULL") => {
        if (date) {
          return this.toTruncatedISOString(date === "now" ? new Date() : date, truncate);
        } else {
          return undefined;
        }
      });
    ExpressionEvaluationService.registerHelper("date", "startOfDayFromNow", this.getStartOfDayFromNow);
    ExpressionEvaluationService.registerHelper("date", "differenceInDays", this.getDifferenceInDays);
  }

  private init() {
    if (messages.isReady()) {
      this.dateFormats = {
        date: {
          SHORT: messages.get(MessageKey.CORE.FORMAT.DATE.SHORT),
          LONG: messages.get(MessageKey.CORE.FORMAT.DATE.LONG),
        },
        time: {
          SHORT: messages.get(MessageKey.CORE.FORMAT.TIME.SHORT, {defaultMessage: "HH:mm"}),
          MEDIUM: messages.get(MessageKey.CORE.FORMAT.TIME.MEDIUM, {defaultMessage: "HH:mm:ss"}),
        },
      };

      this.MOMENT = {
        date: {
          SHORT: DateService.convertToMomentFormats(this.dateFormats.date.SHORT),
          LONG: DateService.convertToMomentFormats(this.dateFormats.date.LONG),
        },
        time: {
          SHORT: this.dateFormats.time.SHORT,
          MEDIUM: this.dateFormats.time.MEDIUM,
        },
      };
      console.debug("Try to set moment.js locale to", messages.get(MessageKey.CORE.LANGUAGE), "available", moment.locales());
      moment.locale([messages.get(MessageKey.CORE.LANGUAGE) || "en", "en"]);
      console.debug("moment.js locale is now",  moment.locale());
      this.init = () => { /* do nothing */ };
    } else if (!this.MOMENT) {
      // for tests
      this.MOMENT = {
        date: {
          SHORT: "dd.MM.yyyy",
          LONG: "EEEE, dd. MMMM yyyy",
        },
        time: {
          SHORT: "HH:mm",
          MEDIUM: "HH:mm:ss",
        },
      };
    }
  }

  public getDateFormats(): IDateFormats {
    this.init();
    return this.dateFormats;
  }

  public getMomentDateFormats(): IDateFormats {
    this.init();
    return this.MOMENT;
  }

  public formatDate(date: Date, dateLength: DateLength = "SHORT"): string {
    this.init();
    return this.format(date, this.MOMENT.date[dateLength]);
  }

  public formatDateTime(date: Date, dateLength: DateLength = "SHORT", timeLength: TimeLength = "SHORT"): string {
    this.init();
    return this.format(date, this.MOMENT.date[dateLength] + " " + this.MOMENT.time[timeLength]);
  }

  public formatTime(time: Date, timeLength: TimeLength = "SHORT"): string {
    this.init();
    return this.format(time, this.MOMENT.time[timeLength]);
  }

  public format(date: Date, nameOrFormat: string): string {
    this.init();
    const format = this.namedFormat(nameOrFormat) || nameOrFormat;
    return moment(date).format(format);
  }

  public reformatDateTime(dateTimeString: string, dateLength: DateLength = "SHORT", timeLength: TimeLength = "SHORT") {
    this.init();
    return this.formatDateTime(moment(dateTimeString).toDate(), dateLength, timeLength);
  }

  public reformatDate(dateString: string, dateLength: DateLength = "SHORT") {
    this.init();
    return this.formatDate(moment(dateString).toDate(), dateLength);
  }

  public reformatTime(timeString: string, timeLength: TimeLength = "SHORT") {
    this.init();
    return this.formatTime(moment(timeString).toDate(), timeLength);
  }

  public toISOString(date: Date): string {
    return moment(date).toISOString();
  }

  /**
   * Truncates (i.e. zero-out) certain values of a given date (UTC). Note that this
   * will shift the date to UTC at first and then the zeros respecting the required
   * resolution.
   *
   * __Example:__
   *
   * Given input = `2022-12-01T12:34:56.123Z`
   *  - 'DAY' = `2022-12-01T00:00:00.000Z`
   *  - 'HOUR' = `2022-12-01T12:00:00.000Z`
   *  - 'MINUTE' = `2022-12-01T12:34:00.000Z`
   *  - 'SECOND' = `2022-12-01T12:34:56.000Z`
   *  - 'FULL' = `2022-12-01T12:34:56.123Z`
   *
   * @param input a Date that is transformed to UTC at first
   * @param resolution the desired precision
   */
  public toTruncatedISOString(input: Date, resolution: DateTimeResolution): string {
    const isoString = this.toISOString(input);
    const elements = isoString.split("T");
    const timeComponents = elements[1].split(":");
    switch (resolution) {
      case "DAY":
        return `${elements[0]}T00:00:00.000Z`;
      case "HOUR":
        return `${elements[0]}T${timeComponents[0]}:00:00.000Z`;
      case "MINUTE":
        return `${elements[0]}T${timeComponents[0]}:${timeComponents[1]}:00.000Z`;
      case "SECOND":
        const ms = timeComponents[2].split(".");
        return `${elements[0]}T${timeComponents[0]}:${timeComponents[1]}:${ms[0]}.000Z`;
      default:
        return isoString;
    }
  }

  public compare(date1: Date, date2: Date, type: CompareType = "ASC") {
    if (type === "ASC") {
      return moment(date1).diff(moment(date2));
    }
    return moment(date2).diff(moment(date1));
  }

  public formatDistanceToNow(date: Date, withoutSuffix: boolean = false): string {
    this.init();
    return moment(date).fromNow(withoutSuffix);
  }

  /**
   * Parses strictly ISO8601 dates. If date is not in that format, undefined will be returned.
   * @param dateString date ase ISO8601 string
   */
  public parse(dateString?: string): Date | undefined {
    if (dateString) {
      const mDate = moment(dateString, moment.ISO_8601, true);
      return mDate.isValid() ? mDate.toDate() : undefined;
    }
    return undefined;
  }

  /**
   * Parses strictly times in the format defined in the messages. If string is not in that format, undefined will be returned.
   * @param timeString the time
   * @return a Date object with the parsed time (and today as date)
   */
  public parseTime(timeString: string): Date | undefined {
    this.init();
    const mDate = moment(timeString, [this.MOMENT.time["MEDIUM"], this.MOMENT.time["SHORT"]], true);
    return mDate.isValid() ? mDate.toDate() : undefined;
  }

  /**
   * returns a copy of the given date at the start of the day (00:00).
   * @param date using now, if not given
   */
  public startOfDay(date?: Date): Date {
    const startOfDay = date ? new Date(date) : new Date();
    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay;
  }

  /**
   * returns a copy of the given date at the end of the day (00:00).
   * @param date using now, if not given
   */
  public endOfDay(date?: Date): Date {
    const endOfDay = date ? new Date(date) : new Date();
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  }

  /**
   * calculates the duration to the given date from now
   */
  public getDurationToDateFromNow(date: Date): string {
    return moment.duration(moment(date).diff(this.startOfDay(), "d"), "d").toISOString();
  }

  /**
   * Converts a duration to a date (at the start of the day) relative to now
   * @param duration in the ISO8601 format
   */
  public getStartOfDayFromNow(duration: string): Date {
    return moment(this.startOfDay()).add(moment.duration(duration)).toDate();
  }

  /**
   * Get the number of day periods between two dates. Negative if date2 is before date1.
   */
  public getDifferenceInDays(date1: Date, date2: Date): number {
    return moment(date2).diff(date1, "d", true);
  }

  /**
   * the timezone, if available as IANA time zone (e.g. Europe/Vienna), otherwise in the format [+-]HHmm
   */
  public getTimeZone(): string {
    return TimeZoneProvider.getTimeZone();
  }

  public removeTime(date: Date): Date {
    return moment(date).startOf("day").toDate();
  }

  public removeDate(date: Date, format: string): Date {
    return moment(moment(date).format(format), format).date(1).month(0).year(1970)
      .toDate();
  }

  private namedFormat(name: string): string | undefined {
    if (!this.customDateFormats) {
      this.customDateFormats = {};
    }
    if (this.customDateFormats[name] === undefined && messages.isReady()) {
      this.customDateFormats[name] = messages.has(`core.format.data.${name}`) ? DateService.convertToMomentFormats(messages.get(`core.format.data.${name}`)) : undefined;
    }
    return this.customDateFormats[name];
  }
}

const dateService = new DateService();

export {dateService, DateService};
