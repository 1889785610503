/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useContext, useEffect, useRef} from "react";

import {FeedbackBanner, FeedbackContext, FeedbackContextType} from "./FeedbackContext";

class FeedbackService {

  private uiContext?: FeedbackContextType;

  public registerUiContext(uiContext: FeedbackContextType) {
    this.uiContext = uiContext;
  }

  /**
   * @see useFeedbackBanners
   */
  public open(feedbackBanner: FeedbackBanner): void {
    if (this.uiContext) {
      this.uiContext.openFeedbackBanner(feedbackBanner);
    } else {
      console.warn("No feedback context available. Can't open feedback banner", feedbackBanner);
    }
  }

  /**
   * @see useFeedbackBanners
   */
  public close(key: FeedbackBanner["key"]): void {
    this.uiContext?.closeFeedbackBanner(key);
  }
}

const service = new FeedbackService();

export {service as feedbackService};


function amendCloseActions(feedbackBanner: FeedbackBanner, close: (key: string) => void) {
  return feedbackBanner.closeActions?.map(closeAction => ({
    ...closeAction,
    action: () => {
      closeAction.action();
      close(feedbackBanner.key);
    },
  }));
}

function amendOnClose(feedbackBanner: FeedbackBanner, currentFeedbackBanners: Set<string>) {
  const removeFromRef = () => {
    currentFeedbackBanners.delete(feedbackBanner.key);
  };
  return feedbackBanner.onClose
    ? () => {
      removeFromRef();
      feedbackBanner.onClose!();
    }
    : removeFromRef;
}

/**
 * Hook that provides functions to open and close user feedback banners.
 *
 * User feedback banners opened with this hook are automatically closed on each closeAction
 * and cleaned up when the component containing the hook unmounts.
 *
 * @return [openFeedbackBanner, closeFeedbackBanner]
 */
export const useFeedbackBanners = (): FeedbackContextType => {
  const openFeedbackBanners = useRef<Set<string>>(new Set());
  const {openFeedbackBanner, closeFeedbackBanner} = useContext(FeedbackContext);

  const close = useCallback((key: string) => {
    closeFeedbackBanner(key);
  }, [closeFeedbackBanner]);

  const open = useCallback((feedbackBanner: FeedbackBanner) => {
    openFeedbackBanners.current.add(feedbackBanner.key);
    const closeActions = amendCloseActions(feedbackBanner, close);
    const onClose = amendOnClose(feedbackBanner, openFeedbackBanners.current);
    openFeedbackBanner({
      ...feedbackBanner,
      closeActions,
      onClose,
    });
  }, [close, openFeedbackBanner]);


  useEffect(() => {
    return () => {
      // close remaining unclosed feedback banners on cleanup
      // eslint-disable-next-line react-hooks/exhaustive-deps
      openFeedbackBanners.current.forEach(key => close(key));
    };
  },
  [close]);

  return {openFeedbackBanner: open, closeFeedbackBanner: close};
};
