/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ExpressionEvaluationService} from "@icm/core-common";
import {LinkedObjectDisplayProps} from "@icm/core-web";
import {Entity, useEntityConfiguration} from "@icm/entity-common";

export const EntityValueDisplay = ({value}: LinkedObjectDisplayProps<Entity>) => {
  const entityType = value.type!;
  const entityConfiguration = useEntityConfiguration(entityType);

  const valueDisplay = entityConfiguration?.valueDisplay ?? "{{id}}";
  return ExpressionEvaluationService.evaluate(valueDisplay, value);
};
