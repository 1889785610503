/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/**
 * State of the shared filter right after creation. In this state the filter is not usable.
 *
 * Transitions:
 * ```
 * INTIAL -> READY:initSharedFilterSelection
 * ```
 */
export const INITIAL = "INITIAL";

/**
 * State of the shared filter when the filter configuration was loaded from the server.
 *
 * Transitions:
 * ```
 *    INITIAL -> READY: initSharedFilterSelection
 *    EDITING -> READY: stopEditing
 *    SAVING -> READY: stopEditing
 * ```
 */
export const READY = "READY";

/**
 * State of the filter when editing the current filter selection.
 *
 * Transitions:
 * ```
 *    READY -> EDITING: startEditing
 *    EDITING -> READY: stopEditing
 *    EDITING -> SAVING: save
 * ```
 */
export const EDITING = "EDITING";

/**
 * State of the filter when editing the current filter selection.
 *
 * Transitions:
 * ```
 *    SAVING -> EDITING: startEditing
 *    SAVING -> READY: stopEditing
 * ```
 */
export const SAVING = "SAVING";

export type SharedFilterStateName = typeof INITIAL | typeof READY | typeof EDITING | typeof SAVING;

export const isSharedFilterStateName = (val: any): val is SharedFilterStateName => {
  return val === INITIAL
    || val === READY
    || val === EDITING
    || val === SAVING;
};
