/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {
  IViewProps,
  useViewModel,
} from "@icm/core-common";
import {
  EntityListViewModelData,
  useCreateEntityActionHandlers,
  useListRefreshActionHandler,
} from "@icm/entity-common";
import {ProcessDefinition, WorkflowApi} from "@icm/workflow-common";
import {useCallback, useEffect, useState} from "react";
import * as React from "react";

import {WebEntityListComponent} from "../components";

export const EntityListView = ({setViewActionHandlers}: IViewProps<EntityListViewModelData>) => {
  const {viewModel} = useViewModel<EntityListViewModelData>();
  const viewModelData = viewModel.viewModelData;
  const [startableProcesses, setStartableProcesses] = useState<ProcessDefinition[]>([]);

  useCreateEntityActionHandlers(viewModelData.entityType, viewModelData.createEntityTypes, setViewActionHandlers,
    viewModelData.entityEditorViewId, viewModelData.processVariant, startableProcesses);

  const setDownloadHandler = useCallback((downloadHandler: () => void, enabled: boolean) => {
    setViewActionHandlers({
      DOWNLOAD_CSV: {
        run: downloadHandler,
        enabled,
      },
    });
  }, [setViewActionHandlers]);

  const refreshHandler = useListRefreshActionHandler(setViewActionHandlers);

  useEffect(() => {
    WorkflowApi.getStartableProcesses().then(setStartableProcesses);
  }, [setStartableProcesses]);

  return (
    <WebEntityListComponent
      viewModel={viewModel}
      entityType={viewModelData.entityType}
      filterParameter={viewModelData.filterParameter}
      listVariant={viewModelData.listVariant}
      setDownloadHandler={setDownloadHandler}
      setRefreshHandler={refreshHandler}
    />
  );
};
