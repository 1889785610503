/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi} from "../api";
import {SecurityService} from "../service";
import {ActionBarItem} from "../store";


export function toActionBarItem(actions: CoreApi.Action[], item: CoreApi.ActionItem, securityService: SecurityService): ActionBarItem {
  if (item.itemType === "DIVIDER") {
    return {
      itemType: "DIVIDER",
      available: true,
    };
  } else {
    const action = actions.find(a => a.id === item.actionRef);
    if (action) {
      const isOpenSidebarAction = action.action === "OPEN" && action.parameters?.find(p => p.key === "sideBarId");

      return {
        ...action,
        itemType: "ACTION",
        available: securityService.evaluateVisibilityRule(action.visible),
        connectionRequired: !isOpenSidebarAction,
      };
    } else {
      throw Error(`Action id ${item.actionRef} not found in the action list`);
    }
  }
}

export function getAllOpenActions(config: CoreApi.UiConfiguration): CoreApi.Action[] {
  const viewIds = config.views?.map(v => v.id).filter((s): s is string => !!s) ?? [];

  return getOpenActions(config, viewIds);
}


export function getOpenActions(config: CoreApi.UiConfiguration, viewIds: string[]): CoreApi.Action[] {
  return viewIds.map(id => getOpenAction(config, id))
    .filter((a): a is CoreApi.Action => !!a);
}

export function getOpenAction(config: CoreApi.UiConfiguration, viewId: string) {
  if (config && config.actions) {
    return config.actions.find(a => isOpenActionForView(a, viewId));
  }
  return undefined;
}

function isOpenActionForView(a: CoreApi.Action, viewId: string) {
  if (a.action === "OPEN") {
    return a.parameters?.find(p => p.key === "viewId" && p.value === viewId) !== undefined;
  }
  return false;
}

export function getViewId(a: CoreApi.Action): string | undefined {
  if (a.action === "OPEN") {
    const parameter = a.parameters?.find(p => p.key === "viewId");
    if (parameter) {
      return  parameter.value;
    }
  }
  return  undefined;
}
