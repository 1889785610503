/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  AutoCompleteComponentInternalProps,
  AutoCompleteFormComponentProps,
  AutocompleteOption,
  BadgeColor,
  LabelWithFieldStateMarker,
  useAutoComplete,
} from "@icm/core-common";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  CircularProgress,
  createFilterOptions,
  ListSubheader,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {PropsWithChildren, ReactElement} from "react";
import {ListChildComponentProps, VariableSizeList} from "react-window";

import {TextWithHighlights} from "../../util";

const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const filterOptions = createFilterOptions<AutocompleteOption>({
  stringify: option => option.valueDisplay,
});

type WebAutocompleteComponentProps<T extends unknown> = PropsWithChildren<AutoCompleteFormComponentProps<T> & AutoCompleteComponentInternalProps<T>>;

export const WebAutocompleteComponent = <T extends unknown>(props: WebAutocompleteComponentProps<T>) => {
  const {label, multiple, hideClearButton} = props;
  const {dao, handleChange, isIgnoringNextInputValue, isLoading, inputValue, setInputValue, values, allOptions} = useAutoComplete(props);

  const classes = useStyles();
  return (
    <Autocomplete
      size="small"
      classes={classes}
      limitTags={1}
      disabled={props.disabled}
      disableClearable={hideClearButton}
      disableCloseOnSelect={multiple}
      loading={isLoading}
      multiple={multiple}
      autoHighlight={false}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={isLoading ? values : allOptions}
      renderOption={renderOption}
      getOptionLabel={option => option?.valueDisplay}
      isOptionEqualToValue={(option, value) => value && dao.valueEqual(option.value, value.value)}
      filterOptions={filterOptions}
      value={multiple ? values : (values[0] ?? null)}
      onChange={(_event, value, _reason, _details) => handleChange(value, "userAction")}
      renderInput={params => renderInput(params, label, isLoading, !!props.required, !!props.error, props.helperText, props.badgeColor)}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        if (!isIgnoringNextInputValue()) {
          setInputValue(newInputValue);
        }
      }}
      onFocus={props.onFocus}
    />
  );
};

const renderInput = (
  params: AutocompleteRenderInputParams,
  label: string,
  loading: boolean,
  required: boolean,
  error: boolean,
  helperText?: string,
  badgeColor?: BadgeColor
) => (
  <TextField variant="standard"
             {...params}
             size="medium"
             label={(
               <LabelWithFieldStateMarker
               required={required}
               label={label}
               badgeColor={badgeColor}
               />
             )}
             error={error}
             helperText={helperText}
             fullWidth={true}
             InputProps={{
               ...params.InputProps,
               "aria-label": `${label}`,
               endAdornment: (
                 <>
                   {loading ? <CircularProgress color="inherit" size={20} /> : null}
                   {params.InputProps.endAdornment}
                 </>
               ),
             }}

  />
);

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AutocompleteOption, {inputValue}: AutocompleteRenderOptionState) => (
  <li {...props}>
    <Typography noWrap>
      <TextWithHighlights text={option.valueDisplay} searchValue={inputValue} />
    </Typography>
  </li>
);

//
// the following code takes care of virtualization
// mostly taken from https://material-ui.com/components/autocomplete/#virtualization
//

const LISTBOX_PADDING = 8; // px

function renderRow(props: PropsWithChildren<ListChildComponentProps>) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props: PropsWithChildren<ReactElement>, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <><div ref={ref} {...props} {...outerProps} /></>;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement>((props: PropsWithChildren<ReactElement>, ref) => {
  const {children, ...other} = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {noSsr: true});
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  // @ts-ignore
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
