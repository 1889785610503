/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActionDescriptorEntry,
  asActionDescriptorEntries,
  BaseViewModelData,
  isCreateActionType,
  useAvailableViewDescriptors,
  useHasActionHandler,
  useIsActionHandlerEnabled,
  useMessages,
  useService,
  ViewModel,
} from "@icm/core-common";
import {useMemo} from "react";


export type ActionTypeFilter = (actionType: string) => boolean;

export const NonCreateActions: ActionTypeFilter = (actionType) => {
  const isCreateAction = isCreateActionType(actionType);
  return !isCreateAction;
};

/**
 * Returns the content actions related to the view model of a tab.
 * Will filter all actions that are not visible or not available.
 * Will return nothing if no viewmodel is provided.
 */
export const useTabContentActions = (
  viewModel?: ViewModel<BaseViewModelData>,
  filter: ActionTypeFilter = NonCreateActions
): ActionDescriptorEntry[] | undefined => {
  const {getMessage} = useMessages();
  const securityService = useService("SECURITY");
  const availableViewDescriptors = useAvailableViewDescriptors();
  const isActionHandlerExisting = useHasActionHandler(viewModel?.id);
  const isActionHandlerEnabled = useIsActionHandlerEnabled(viewModel?.id);

  return useMemo(() => {
    if (viewModel) {
      const viewDescriptor = availableViewDescriptors[viewModel.viewType];
      if (!viewDescriptor) {
        throw Error(`Invalid view ${viewModel.viewType}`);
      }
      const actionDescriptors = viewDescriptor.getViewActionDescriptors?.(viewModel, getMessage, securityService);
      if (actionDescriptors) {
        const result: ActionDescriptorEntry[] = [];
        const actionEntries: ActionDescriptorEntry[] = asActionDescriptorEntries(actionDescriptors);
        actionEntries.forEach(entry => {
          if (filter(entry.actionType) && (entry.action.visible ?? true) && isActionHandlerExisting(entry.actionType)) {
            if (!isActionHandlerEnabled(entry.actionType)) {
              entry.action.enabled = false;
            }
            result.push(entry);
          }
        });
        return result;
      }
    }
    return undefined;
  }, [isActionHandlerExisting, isActionHandlerEnabled, filter, getMessage, securityService, availableViewDescriptors, viewModel]);
};
