/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {GeneralError} from "@icm/core-common";

import {Person, RecurringStandby, StandbyTeam} from "../../generated/api";

export enum RecurringStandbyActionTypes {
  FETCH_STANDBY_TEAMS_REQUEST = "@@recurringStandby/FETCH_STANDBY_TEAMS_REQUEST",
  FETCH_STANDBY_TEAMS_SUCCESS = "@@recurringStandby/FETCH_STANDBY_TEAMS_SUCCESS",
  FETCH_STANDBY_TEAMS_ERROR = "@@recurringStandby/FETCH_STANDBY_TEAMS_ERROR",
  FETCH_STANDBY_TEAM_MEMBERS_REQUEST = "@@recurringStandby/FETCH_STANDBY_TEAM_MEMBERS_REQUEST",
  FETCH_STANDBY_TEAM_MEMBERS_SUCCESS = "@@recurringStandby/FETCH_STANDBY_TEAM_MEMBERS_SUCCESS",
  FETCH_STANDBY_TEAM_MEMBERS_ERROR = "@@recurringStandby/FETCH_STANDBY_TEAM_MEMBERS_ERROR",
  FETCH_RECURRING_STANDBY_REQUEST = "@@recurringStandby/FETCH_RECURRING_STANDBY_REQUEST",
  FETCH_RECURRING_STANDBY_SUCCESS = "@@recurringStandby/FETCH_RECURRING_STANDBY_SUCCESS",
  FETCH_RECURRING_STANDBY_ERROR = "@@recurringStandby/FETCH_RECURRING_STANDBY_ERROR",
  SUBMIT_RECURRING_STANDBY_REQUEST = "@@recurringStandby/SUBMIT_RECURRING_STANDBY_REQUEST",
  SUBMIT_RECURRING_STANDBY_SUCCESS = "@@recurringStandby/SUBMIT_RECURRING_STANDBY_SUCCESS",
  SUBMIT_RECURRING_STANDBY_ERROR = "@@recurringStandby/SUBMIT_RECURRING_STANDBY_ERROR",
  DELETE_RECURRING_STANDBY_REQUEST = "@@recurringStandby/DELETE_RECURRING_STANDBY_REQUEST",
  DELETE_RECURRING_STANDBY_SUCCESS = "@@recurringStandby/DELETE_RECURRING_STANDBY_SUCCESS",
  DELETE_RECURRING_STANDBY_ERROR = "@@recurringStandby/DELETE_RECURRING_STANDBY_ERROR",
  DELETE_ERROR = "@@recurringStandby/DELETE_ERROR"
}

export interface IRecurringStandbyState  {
  readonly loading: boolean
  readonly error?: GeneralError
  readonly filterFrom: Date
  readonly filterTo: Date
  readonly standbyList: RecurringStandby[]
  readonly availableStandbyTeams?: StandbyTeam[]
  readonly standbyTeam?: StandbyTeam
  readonly standbyTeamMembers?: Person[]
}
