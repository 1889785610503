/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Entity, EntityPropertyValue} from "../generated/api";


export type EntityEditState = {

  /**
   * A mapping of entityId -> EntityEditModel
   */
  editModels: Partial<Record<string, EntityEditModel>>

}

/**
 * Describes an entity that was loaded into the UI.
 * The entity described by this model is either new or was loaded
 */
export type EntityEditModel = {

  /**
   * The representation of the entity as it was loaded from the backend or created initially.
   * While loading, the entity is undefined.
   */
  entity: Entity,

  /**
   * The representation of the entity as it is currently edited.
   * This property references an entity with all propertyChanges applied.
   */
  currentEntity?: Entity,

  /**
   * Flag that is true, if this model was never saved.
   * Thus, the id stored in the entity is now known to the server.
   */
  draft: boolean,

  /**
   * Flag that is true if the model is currently loaded/submitted or
   * changed in long running operation.
   */
  processing: boolean,

  propertyChanges: EntityPropertyValue[],

  propertyChangesForEagerValues: EntityPropertyValue[],
  /**
   * Edited entity field validation state
   */
  validation: Partial<Record<string, boolean>>

  /**
   * Flag to mark the model as readOnly. If used by an entity editor,
   * all input fields should be disabled.
   */
  readOnly?: boolean

}

export const isSubmitStrategy = (val?: any): val is SubmitStrategy => {
  return val === RELOAD_AFTER_SUBMIT || val === MAKE_READONLY_AFTER_SUBMIT;
};


/**
 * Use this strategy to fetch the entity after saving. This will mark the entity edit model as
 * non draft and clear all property changes.
 * @see ReloadEntityEditModel
 */
export const RELOAD_AFTER_SUBMIT = "RELOAD";

/**
 * Use this strategy to mark an entity edit model as read only. This is useful in case you
 * safe an entity only once and want to avoid further editing (e.g as in the case of event notifications).
 */
export const MAKE_READONLY_AFTER_SUBMIT = "MAKE_READ_ONLY";

/**
 * A SubmitStrategy determines what happens after submitting (saving) an entity edit
 * model to the server
 *
 * @see SubmitEntityEditModel
 */
export type SubmitStrategy = typeof RELOAD_AFTER_SUBMIT | typeof MAKE_READONLY_AFTER_SUBMIT;

/**
 * Use this constant as a fallback in case the user did not configure any SubmitStrategy.
 *
 * @see SubmitStrategy
 */
export const DEFAULT_AFTER_SUBMIT_STRATEGY: SubmitStrategy = RELOAD_AFTER_SUBMIT;
