/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

export enum CommunicationChannel {
  EMAIL = "EMAIL",
  SMS = "SMS",
  VOICE_MAIL = "VOICE_MAIL",
  TWO_WAY_SMS = "TWO_WAY_SMS",
}

export const CommunicationChannelNames = Object.keys(CommunicationChannel);
export const isCommunicationChannel = (value: string): value is CommunicationChannel => {
  return CommunicationChannelNames.some(v => v === value);
};
