/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ListFilter, objectQueryKey} from "@icm/core-common";

/**
 * Provides a key for ReactQuery that should be associated with entity list data.
 */
export const EntityListQueryKeyGenerator = {

  buildEntityListQueryKey: buildQueryKey,

};

function buildQueryKey(entityType: string, listFilter: ListFilter | undefined, contextEntityId: string | undefined) {
  const listFilterKeyPart = `FILTER:${objectQueryKey(listFilter ?? "UNFILTERED", ["offset"])}`;
  const result = [listFilterKeyPart];
  if (contextEntityId) {
    result.push(contextEntityId);
  }
  return ["private", "core", "entities", entityType].concat(result.join("_"));
}
