/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {ICoreApplicationState} from "@icm/core-common";
import {ColoredBadge, ListComponentProps} from "@icm/core-web";
import {Edit} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {PropsWithChildren} from "react";
import {useSelector} from "react-redux";

import styles from "./EntityUpdateBadgeComponentStyle";

const useStyles = makeStyles(styles);

export const EntityUpdateBadgeComponent: React.FC<PropsWithChildren<ListComponentProps>> = ({entity, children}) => {
  const classes = useStyles();

  const entityType = entity.type ?? "INCIDENT";
  const entityId = entity.id;
  const entityUpdateInformation = useSelector(
    (
      {activityStreamState}: ICoreApplicationState
    ) => activityStreamState.objectUpdatesByType[entityType]?.[entityId]
  );
  if (entityUpdateInformation
    && (entityUpdateInformation.showUpdatesSince === undefined || entityUpdateInformation.lastUpdatedAt > entityUpdateInformation.showUpdatesSince)) {
    return (
      <ColoredBadge color="primary"
                    value={<Edit fontSize="inherit" />}
                    classes={{
                      badge: classes.badge,
                      root: classes.root,
                    }}
      >
        {children}
      </ColoredBadge>
    );
  } else {
    return <>{children}</>;
  }
};
