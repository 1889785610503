/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {debounce} from "lodash-es";
import {useCallback, useEffect, useMemo} from "react";

import {FilterConfiguration, FilterOption, FilterSelectionItem} from "../../api";
import {FilterParameter} from "../../generated/api";
import {createFilterFromFilterSelection, FilterAndModifier} from "../../service";
import {useSharedFilterStateController} from "../../store";
import {ClientFilterSelection} from "./ClientFilterSelection";
import {isFallbackFilterSelection} from "./FallbackClientFilterSelection";
import {NullSharedFilterController} from "./NullSharedFilterController";
import {SharedFilterController} from "./SharedFilterController";
import {useFilterData} from "./useFilterData";

/**
 * Wait time in [ms] before the onUpdateFilter
 * function gets called after the user changed
 * something.
 */
const DEFAULT_FILTER_UPDATE_DELAY = 300;


export type SharedFilterControllerProps = {
  onUpdateFilter: (filter: FilterAndModifier) => void
  filterParameter?: FilterParameter,
  viewId: string,
  showEventFilterOptions?: boolean,
  onFilterUpdateDelay?: number
}


/**
 * Get a controller for the shared filter component. The returned value is memoized using
 * the shared controller state and the filter configuration as reference.
 *
 * @param props
 */
export const useSharedFilterController = (props: SharedFilterControllerProps): SharedFilterController => {
  const {
    filterParameter,
    onUpdateFilter,
    viewId,
    showEventFilterOptions,
    onFilterUpdateDelay = DEFAULT_FILTER_UPDATE_DELAY,
  } = props;
  const readOnly = filterParameter?.readOnly ?? false;


  const filterData = useFilterData(filterParameter?.id, showEventFilterOptions);

  const sharedFilterStateController = useSharedFilterStateController(filterData?.entityType, filterData?.variant, filterData?.defaultFilterSelection);

  // debounce the filter update because the user could have typos etc. and we do not want to spam the network
  const updateFilterTask = useCallback((items: FilterSelectionItem[], filterOptions: FilterOption[], filterConfiguration: FilterConfiguration) => {
    return debounce(() => {
      onUpdateFilter(createFilterFromFilterSelection(items, filterOptions, viewId, filterConfiguration));
    }, onFilterUpdateDelay);
  }, [onUpdateFilter, viewId, onFilterUpdateDelay]);

  const deleteFilter = useCallback(async (filterId?: string): Promise<boolean> => {
    if (filterData && filterId) {
      try {
        await filterData.remove(filterId);
        sharedFilterStateController.setCurrentFilterSelection(sharedFilterStateController.fallbackFilterSelection);
        return true;
      } catch (e) {
        console.error("Could not remove filter", filterId);
        return false;
      }
    }
    return false;
  }, [filterData, sharedFilterStateController]);

  useEffect(() => {
    if (filterData && filterData.filterOptions && filterData.filterConfiguration) {
      const items = sharedFilterStateController.currentFilterSelection.selectedFilters;
      if (items) {
        const task = updateFilterTask(items, filterData.filterOptions, filterData.filterConfiguration);
        task();
      }
    }
  }, [sharedFilterStateController, filterData, updateFilterTask]);

  return useMemo(() => {
    if (!filterData || !filterData.isReady) {
      return NullSharedFilterController;
    }

    const availableFilterSelections = filterData.availableFilterSelections;
    const currentFilterSelectionItems = sharedFilterStateController.currentFilterSelection.selectedFilters;
    const currentFilterSelection = sharedFilterStateController.currentFilterSelection;
    const canChangeCurrentFilterSelection = !sharedFilterStateController.isEditing()
      && !sharedFilterStateController.isSaving();

    return {
      availableFilterSelections,
      currentFilterSelection,
      currentFilterSelectionItems,
      filterOptions: filterData.filterOptions,
      hasFilterSelections: availableFilterSelections !== undefined && availableFilterSelections.length > 0,
      canCreateNewFilter(): boolean {
        return !sharedFilterStateController.isEditing()
          && !sharedFilterStateController.isSaving();
      },
      canDeleteCurrentFilter(): boolean {
        return sharedFilterStateController.canDeleteCurrentFilterSelection();
      },
      canEditCurrentFilter(): boolean {
        return sharedFilterStateController.canEditCurrentFilterSelection();
      },
      canChangeCurrentFilterSelection(): boolean {
        return !readOnly && canChangeCurrentFilterSelection;
      },
      deleteCurrentFilterSelection(): Promise<boolean> {
        const id = sharedFilterStateController.currentFilterSelection?.id;
        return deleteFilter(id);
      },
      deleteFilterSelection(filterId: string): Promise<boolean> {
        const id = availableFilterSelections?.find(fs => fs.id === filterId)?.id;
        return deleteFilter(id);
      },
      isSaving(): boolean {
        return sharedFilterStateController.isSaving();
      },
      isEditing(): boolean {
        return sharedFilterStateController.isEditing();
      },
      isReady(): boolean {
        return filterData !== undefined
          && filterData.isReady
          && sharedFilterStateController.isReady();
      },

      setCurrentFilterSelection(filterSelection: ClientFilterSelection): void {
        sharedFilterStateController.setCurrentFilterSelection(filterSelection);
        filterData.savePreferredFilterSelection(filterSelection)
          .then(success => {
            if (success) {
              console.log("saved current filter selection");
            }
          });
      },
      canResetFilter(): boolean {
        return canChangeCurrentFilterSelection
          && !isFallbackFilterSelection(sharedFilterStateController.currentFilterSelection);
      },
      resetFilter() {
        sharedFilterStateController.setCurrentFilterSelection(sharedFilterStateController.fallbackFilterSelection);
        filterData.savePreferredFilterSelection(sharedFilterStateController.fallbackFilterSelection)
          .then(success => {
            if (success) {
              console.log("saved current filter selection");
            }
          });
      },
      startEdit(newFilter: boolean): void {
        sharedFilterStateController.startEditing(newFilter);
      },
      startEditFilter(newFilter: ClientFilterSelection): void {
        sharedFilterStateController.startEditingFilter(newFilter);
      },
      stopEdit(save: boolean): void {
        const currentFilter = {...sharedFilterStateController.currentFilterSelection};
        if (save) {
          sharedFilterStateController.save();
          filterData.save(currentFilter).then(saveResult => {
            if (saveResult.result === "SAVED") {
              console.log("Saved filter", saveResult.filter);
              sharedFilterStateController.cancelEditing();
            } else if (saveResult.result === "CREATED") {
              console.log("Created filter", saveResult.filter);
              sharedFilterStateController.cancelEditing();
              sharedFilterStateController.setCurrentFilterSelection(saveResult.filter);
            } else {
              console.error("Saving failed.", saveResult.filter, "Returning to edit mode..");
              sharedFilterStateController.startEditing(false);
            }
          }).catch(error => {
            console.error("Saving failed.", error, "Returning to edit mode..");
            sharedFilterStateController.startEditing(false);
          });
        } else {
          sharedFilterStateController.cancelEditing();
        }
      },
      updateCurrentFilterSelectionItems(items?: FilterSelectionItem[]): void {
        const f = sharedFilterStateController.currentFilterSelection;
        if (f) {
          sharedFilterStateController.setCurrentFilterSelection({
            ...f,
            selectedFilters: items ?? [],
          });
        }
      },
      updateFilterName(newName: string): void {
        const f = sharedFilterStateController.currentFilterSelection;
        if (f) {
          sharedFilterStateController.setCurrentFilterSelection({
            ...f,
            name: newName,
          });
        }
      },

    };
  }, [sharedFilterStateController, readOnly, filterData, deleteFilter]);
};
