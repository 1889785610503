/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {MessageKey, ModuleRegistrationOptions, registerNamedComponentFactory, ViewModel} from "@icm/core-common";
import {REM_AUDIT_LOG} from "@icm/rem-rail-common";

import {auditLogComponentFactory} from "./src/components";
import packageInfo from "./src/generated/package.clone.json";
import {AuditLogView, AuditLogViewModelData} from "./src/pages";

export * from "./src/pages";
export * from "./src/components";

export const auditlogWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  runPostInitialization: () => {
    registerNamedComponentFactory(REM_AUDIT_LOG, auditLogComponentFactory);
  },
  providedViews: [{
    viewType: "AUDITLOG_VIEW",
    view: AuditLogView,
    isPrintable: (viewModel: ViewModel<AuditLogViewModelData>) => viewModel.viewModelData.incidentNumber !== undefined,
    initializeViewModelData: _ => ({
      printRequested: false,
    }),
    getTitle: (_, getMessage) => getMessage(MessageKey.AUDITLOG.AUDITLOG),
  }],
};
