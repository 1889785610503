/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {
  ActionRunner,
  ExpressionEvaluationService,
  ICoreApplicationState,
  ListComponentValueAndChanges,
  ParameterUtilities,
  ResolvedParameter,
  SafeObjectPropertyUpdate,
  StoreService,
  toListValue,
  GenericFormField,
  getValueFromListValue,
  produceListComponentEntity,
  MessageKey,
  messages,
  useDialogContext,
  getNamedComponentFactory,
  FORM_DIALOG,
} from "@icm/core-common";
import {useCallback} from "react";

import {entityEditViewDispatcher} from "../../components/EntityEditViewDispatcher";
import {AbstractFormConfiguration, Entity, EntityPropertyValue, GenericFormConfiguration} from "../../generated/api";
import {EntityEditService, entityDataService, entityUpdateService} from "../../service";
import {InitEntityModelOptions, EntityEditModel} from "../../store";
import {convertToGenericFormConfiguration} from "../EntityFormUtilities";

/**
 * Handler to be associated with "CREATE_WEAK_ENTITY"
 */
export const useCreateWeakEntityActionHandler = (): ActionRunner => {
  const {setDialog, cancel} = useDialogContext();
  return useCallback((parameters: ResolvedParameter[]) => {
    const parentEntityType = ParameterUtilities.getResolvedParameter("parentEntityType", parameters);
    const parentEntityId = ParameterUtilities.getResolvedParameter("parentEntityId", parameters);
    const parentEntityAttribute = ParameterUtilities.getResolvedParameter("parentEntityAttribute", parameters);
    const targetEntityType = ParameterUtilities.getResolvedParameter("targetEntityType", parameters);
    const targetEntityFormVariant = ParameterUtilities.getResolvedParameter("targetEntityFormVariant", parameters);
    const sourceEntity = ParameterUtilities.getResolvedParameter("sourceEntity", parameters);
    const creationParameters = ParameterUtilities.getResolvedParameter("creationParameters", parameters);

    if (targetEntityFormVariant) {
      console.log("Opening new weak entity dialog of type ", targetEntityType, " based on ", sourceEntity);
      createEntityEditModel(targetEntityType, creationParameters, sourceEntity)
        .then(targetEntityEditModelOptions => loadFormConfiguration(targetEntityType, targetEntityFormVariant)
          .then(targetEntityFormConfiguration => convertFormConfiguration(targetEntityType, targetEntityFormConfiguration)
            .then(genericTargetEntityFormConfiguration => openWeakEntityDialog(
              setDialog,
              cancel,
              parentEntityType,
              parentEntityId,
              parentEntityAttribute,
              targetEntityEditModelOptions,
              genericTargetEntityFormConfiguration
            ))));
    } else {
      console.log("Creating new weak entity of type ", targetEntityType, " based on ", sourceEntity);
      createEntityEditModel(targetEntityType, creationParameters, sourceEntity)
        .then(targetEntityEditModelOptions => addWeakEntityAsEditModel(parentEntityType, parentEntityId, parentEntityAttribute, targetEntityEditModelOptions));
    }
  }, [setDialog, cancel]);
};

const createEntityEditModel = (
  targetEntityType: string,
  creationParameters?: Record<string, any>,
  sourceEntity?: Entity
): Promise<InitEntityModelOptions> => {
  return EntityEditService.initializeWeakEntityEditModel(targetEntityType,  undefined, creationParameters, sourceEntity);
};

const addWeakEntityAsEditModel = (
  parentEntityType: string,
  parentEntityId: string,
  parentEntityAttribute: string,
  targetEntityEditModel: InitEntityModelOptions
) => {
  const targetEntity = targetEntityEditModel.entity;
  const targetEntityPropertyChanges = targetEntityEditModel.propertyChanges.map(mapToEntityPropertyUpdate);
  addWeakEntityAsPropertyUpdates(parentEntityType, parentEntityId, parentEntityAttribute, targetEntity, targetEntityPropertyChanges);
};

const addWeakEntityAsPropertyUpdates = (
  parentEntityType: string,
  parentEntityId: string,
  parentEntityAttribute: string,
  targetEntity: Entity,
  targetEntityPropertyChanges: SafeObjectPropertyUpdate[]
) => {
  const parentEntityEditModel = getEntityEditModel(parentEntityId);
  if (parentEntityEditModel && parentEntityEditModel.currentEntity) {
    const oldEntityListValue = getOldEntityListValue(parentEntityEditModel.currentEntity!, parentEntityAttribute);
    const newEntityListValue = getNewEntityListValue(oldEntityListValue, targetEntity, targetEntityPropertyChanges);
    entityDataService.getEntityConfigurationForEntityAndRelated(parentEntityType)
      .then(parentEntityConfigurations => {
        entityEditViewDispatcher({
          entity: parentEntityEditModel.currentEntity!,
          entityConfigurations: parentEntityConfigurations,
        })
          .updateEntityEditModel({
            widgetType: "GENERIC_FIELD",
            valueBinding: `dynamicAttributes.${parentEntityAttribute}.value`,
          } as GenericFormField, newEntityListValue);
      });
  }
};

const getEntityEditModel = (
  entityId: string
): EntityEditModel | undefined => {
  const {entityEditState} = StoreService.store.getState() as ICoreApplicationState;
  return entityEditState?.editModels[entityId];
};

const getOldEntityListValue = (
  entity: Entity,
  parentEntityAttribute: string
): ListComponentValueAndChanges => {
  const parentEntityPropertyValue = ExpressionEvaluationService.evaluate(`entity => entity.dynamicAttributes.${parentEntityAttribute}?.value`, entity) ?? [];
  return toListValue(parentEntityPropertyValue);
};

const getNewEntityListValue = (
  oldEntityListValue: ListComponentValueAndChanges,
  targetEntity: Entity,
  targetEntityPropertyChanges: SafeObjectPropertyUpdate[]
): any => {
  const listComponentEntity = produceListComponentEntity(oldEntityListValue, targetEntity, targetEntityPropertyChanges);
  return getValueFromListValue(listComponentEntity, false);
};

const mapToEntityPropertyUpdate = (
  entityPropertyValue: EntityPropertyValue
): SafeObjectPropertyUpdate => {
  return {
    propertyName: entityPropertyValue.propertyName!,
    propertyValue: entityPropertyValue.propertyValue,
  };
};

const loadFormConfiguration = (
  entityType: string,
  formVariant: string
): Promise<AbstractFormConfiguration> => {
  return entityDataService.getFormConfiguration({entityType, formVariant});
};

const convertFormConfiguration = (
  targetEntityType: string,
  formConfiguration: AbstractFormConfiguration
): Promise<GenericFormConfiguration> => {
  return entityDataService.getEntityConfigurationForEntityAndRelated(targetEntityType)
    .then(entityConfigurations => {
      return convertToGenericFormConfiguration(targetEntityType, formConfiguration, entityConfigurations) || (formConfiguration as GenericFormConfiguration);
    });
};

const openWeakEntityDialog = (
  setDialog: React.Dispatch<React.SetStateAction<React.ReactNode>>,
  cancel: () => void,
  parentEntityType: string,
  parentEntityId: string,
  parentEntityAttribute: string,
  targetEntityEditModel: InitEntityModelOptions,
  targetEntityFormConfiguration: GenericFormConfiguration
) => {
  const targetEntityWithLifecyclePropertyChanges = entityUpdateService.applyChanges(targetEntityEditModel.entity, targetEntityEditModel.propertyChanges);
  const formDialogProps = {
    formConfiguration: targetEntityFormConfiguration,
    entity: targetEntityWithLifecyclePropertyChanges,
    submitLabel: messages.get(MessageKey.CORE.FORM.ADD)!,
    onClose: cancel,
    onSubmit: (entity: Entity, propertyUpdates: SafeObjectPropertyUpdate[], closeDialog = true) => {
      const targetEntityPropertyUpdates = [...targetEntityEditModel.propertyChanges.map(mapToEntityPropertyUpdate), ...propertyUpdates];
      addWeakEntityAsPropertyUpdates(parentEntityType, parentEntityId, parentEntityAttribute, entity, targetEntityPropertyUpdates);
      if (closeDialog) {
        cancel();
      }
    },
  };

  const FormDialogComponent = getNamedComponentFactory(FORM_DIALOG);
  if (FormDialogComponent) {
    setDialog(<FormDialogComponent {...formDialogProps} />);
  }
};
