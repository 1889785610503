/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {createSlice} from "@reduxjs/toolkit";

import {QueuePushNotification} from "./actions/QueuePushNotification";
import {RemoveFromPushNotificationQueue} from "./actions/RemoveFromPushNotificationQueue";
import {PushNotificationState} from "./types";

const initialState: PushNotificationState = {
  pushNotifications: {},
};

const {
  reducer,
  actions,
} = createSlice({
  name: "@@pushNotifications",
  initialState: initialState,
  reducers: {
    queuePushNotification: QueuePushNotification,
    removeFromPushNotificationQueue: RemoveFromPushNotificationQueue,
  },
});

export const {
  queuePushNotification,
  removeFromPushNotificationQueue,
} = actions;

export {reducer as pushNotificationsReducer};
