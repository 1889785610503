/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ComponentId, DEFAULT_COMPONENT_COLORS, IcmColorScheme, PaletteColor} from "@icm/core-common";
import {ThemeProvider, Theme, StyledEngineProvider, useTheme} from "@mui/material/styles";
import * as React from "react";

import {overrideDefaultColor} from "./createMaterialUiTheme";
import {IcmMuiTheme} from "./IcmMuiTheme";

// https://mui.com/guides/migration-v4/#types-property-quot-palette-quot-quot-spacing-quot-does-not-exist-on-type-defaulttheme
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export type OverrideOptions = Partial<Record<PaletteColor, IcmColorScheme>>;

export function withColorOverride<P extends object>(Component: React.ComponentType<P>, componentId: ComponentId): React.ComponentType<P> {
  return (props: P) => {
    const defaultColor = DEFAULT_COMPONENT_COLORS[componentId];
    const theme: IcmMuiTheme = useTheme();
    const muiTheme = React.useMemo(() => theme.icm.getColor(componentId)
      // create theme with overridden color
      ? overrideDefaultColor(theme, {[defaultColor]: theme.icm.getColor(componentId)})
      // pass through unmodified theme
      : theme, [defaultColor, theme]);
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <Component {...props} />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };
}
