/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {GenericFormFieldWithFormConverter, IFormComponentProps} from "@icm/core-common";
import {Skeleton} from "@mui/material";
import React from "react";

const DEMO_COMPONENT_TYPE = "TYPE";

const DroneControlMockFormComponent = React.lazy(() => import("./DroneControlMockFormComponent"));
const SarAssistanceMockFormComponent = React.lazy(() => import("./SarAssistanceMockFormComponent"));

export type DemoFormComponentProps = IFormComponentProps<any> & {field: GenericFormFieldWithFormConverter};

export const DemoFormComponent = (props: DemoFormComponentProps) => {
  const component = props.field.parameterList?.find(p => p.key === DEMO_COMPONENT_TYPE)?.value;

  let Component;
  switch (component) {
    case "DRONE_CONTROL_MOCK":
      Component = DroneControlMockFormComponent;
      break;
    case "SAR_ASSISTANCE_MOCK":
      Component = SarAssistanceMockFormComponent;
      break;
    default:
      Component = () => <>Unknown demo component {component}</>;
  }
  return (
    <React.Suspense fallback={<Skeleton />}>
      <Component {...props} />
    </React.Suspense>
  );
};
