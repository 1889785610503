/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CompositeSubView, getNamedComponentFactory, MessageKey, useMessages, ViewActionHandlersByActionId} from "@icm/core-common";

import {REM_TRAIN_INFORMATION} from "../constant";


export type TrainInformationSubViewProps = {
  visible: boolean,
} & TrainInformationProps;

export type TrainInformationProps = {
  incidentId?: string,
  setViewActionHandlers: (viewActionHandlers: ViewActionHandlersByActionId) => void,
}

export const TrainInformationSubView = (props: TrainInformationSubViewProps) => {
  const {
    visible,
    ...trainInformationProps
  } = props;
  const {getMessage} = useMessages();

  if (!visible || !props.incidentId) {
    return <></>;
  }

  const TrainInformationComponent = getNamedComponentFactory(REM_TRAIN_INFORMATION);

  if (!TrainInformationComponent) {
    return <></>;
  }

  return (
    <CompositeSubView id="trainInformation" label={getMessage(MessageKey.RAIL.TRAININFORMATION.TITLE)} key="trainInformation">
      <TrainInformationComponent {...trainInformationProps} />
    </CompositeSubView>
  );
};
