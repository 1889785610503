/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isString, MessageKey, ModuleRegistrationOptions, ParameterUtilities} from "@icm/core-common";
import React from "react";

import packageInfo from "./src/generated/package.clone.json";
import {StandbyViewDataModel} from "./src/pages/StandbyView";

export const standbyWebModuleRegistrationOptions: ModuleRegistrationOptions = {
  moduleName: packageInfo.name,
  dependencies: packageInfo.dependencies,
  providedViews: [{
    viewType: "STANDBY_VIEW",
    view: React.lazy(() => import("./src/pages/StandbyView")),
    getTitle: (viewModel, getMessage) => getMessage(MessageKey.STANDBY.MENU.SECTION.TITLE),
    initializeViewModelData: (viewParameters): StandbyViewDataModel => {
      const recurringStandbyVisible = ParameterUtilities.getResolvedParameterValue("recurringStandbyVisible", viewParameters, isString) === "true";
      return {
        printRequested: false,
        recurringStandbyVisible,
      };
    },
  }, {
    viewType: "RECURRING_STANDBY_VIEW",
    view: React.lazy(() => import("./src/pages/RecurringStandbyView")),
    initializeViewModelData: viewParameters => ({
      printRequested: false,
    }),
    getTitle: (viewModel, getMessage) => getMessage(MessageKey.STANDBY.RECURRING.MENU.SECTION.TITLE),
  }],
};
