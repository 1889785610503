/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Tooltip} from "@mui/material";
import {TooltipProps} from "@mui/material/Tooltip";
import * as React from "react";

type TooltipWrapProps = Omit<TooltipProps, "title"> & {
  title?: TooltipProps["title"]
  // @default true
  condition?: boolean
};

/**
 * Only renders a Tooltip, if a title is given and wrap is true (or undefined)
 */
export const ConditionalTooltip = React.forwardRef(({title, condition = true, children, ...props}: TooltipWrapProps, ref) => {
  return title && condition
    ? <Tooltip ref={ref} title={title} {...props}>{children}</Tooltip>
    : children;
});
