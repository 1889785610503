/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

/** Name used to register a named component for incident audit logs in REM products */
export const REM_AUDIT_LOG = "REM_AUDIT_LOG_COMPONENT";

/** Name used to register a named component for wagon data in REM products */
export const REM_TRAIN_INFORMATION = "REM_TRAIN_INFORMATION_COMPONENT";
