/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {combineEpics, Epic} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, switchMap, withLatestFrom} from "rxjs/operators";
import {isActionOf} from "typesafe-actions";

import {StandbyApi} from "../../api/StandbyApi";
import {deleteRecurringStandby, fetchRecurringStandbys, fetchStandbyTeamMembers, fetchStandbyTeams, submitRecurringStandby} from "./actions";
import {RecurringStandbysAction} from "./reducer";

const fetchStandbyTeamsFlow: Epic<RecurringStandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchStandbyTeams.request)),
  switchMap(() => from(StandbyApi.getAllStandbyTeams()).pipe(
    map(value => fetchStandbyTeams.success(value)),
    catchError(error => of(fetchStandbyTeams.failure(error)))
  ))
);

const fetchStandbyTeamMembersFlow: Epic<RecurringStandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchStandbyTeamMembers.request)),
  switchMap(action => from(StandbyApi.getAllMembersFromStandbyTeam(action.payload.id!)).pipe(
    map(value => fetchStandbyTeamMembers.success(value)),
    catchError(error => of(fetchStandbyTeamMembers.failure(error)))
  ))
);

const fetchStandbysFlow: Epic<RecurringStandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(fetchRecurringStandbys.request)),
  switchMap(action => from(StandbyApi.getAllRecurringStandbysFromTeam(action.payload.standbyTeam.id!, action.payload.filterFrom, action.payload.filterTo)).pipe(
    map(value => fetchRecurringStandbys.success(value)),
    catchError(error => of(fetchRecurringStandbys.failure(error)))
  ))
);

const submitStandbyFlow: Epic<RecurringStandbysAction> = (action$, state$) => action$.pipe(
  filter(isActionOf(submitRecurringStandby.request)),
  withLatestFrom(state$),
  switchMap(([action, state]) => from(StandbyApi.postRecurringStandby({
    ...action.payload,
    standbyTeam: state.recurringStandbyState.standbyTeam,
  }))
    .pipe(
      map(value => submitRecurringStandby.success(value)),
      catchError(error => of(submitRecurringStandby.failure(error)))
    ))
);

const deleteStandbyFlow: Epic<RecurringStandbysAction> = (action$) => action$.pipe(
  filter(isActionOf(deleteRecurringStandby.request)),
  switchMap(action => from(StandbyApi.deleteRecurringStandby(action.payload)).pipe(
    map(value => deleteRecurringStandby.success(value)),
    catchError(error => of(deleteRecurringStandby.failure(error)))
  ))
);

const refreshStandbyTeamMembersFlow: Epic<RecurringStandbysAction> = (action$, state$) => action$.pipe(
  filter(isActionOf([fetchStandbyTeams.success])),
  withLatestFrom(state$),
  map(([action, state]) => state.recurringStandbyState.standbyTeam
    ? fetchStandbyTeamMembers.request(state.recurringStandbyState.standbyTeam)
    : fetchStandbyTeamMembers.failure(new Error("StandbyTeam is undefined")))
);

const refreshStandbysFlow: Epic<RecurringStandbysAction> = (action$, state$) => action$.pipe(
  filter(isActionOf([fetchStandbyTeams.success, deleteRecurringStandby.success, submitRecurringStandby.success])),
  withLatestFrom(state$),
  map(([action, state]) => state.recurringStandbyState.standbyTeam
    ? fetchRecurringStandbys.request({
      standbyTeam: state.recurringStandbyState.standbyTeam,
      filterFrom: state.recurringStandbyState.filterFrom,
      filterTo: state.recurringStandbyState.filterTo,
    })
    : fetchRecurringStandbys.failure(new Error("StandbyTeam is undefined")))
);

export const recurringStandbyFlows = combineEpics(fetchStandbyTeamsFlow, fetchStandbyTeamMembersFlow,
  fetchStandbysFlow, submitStandbyFlow, deleteStandbyFlow, refreshStandbyTeamMembersFlow, refreshStandbysFlow);
