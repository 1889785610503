/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {PayloadAction} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash-es";

import {Entity, EntityPropertyValue} from "../../generated/api";
import {EntityEditState} from "../types";

export type InitEntityModelOptions = {
  entity: Entity,
  draft: boolean,
  readOnly: boolean,
  propertyChanges: EntityPropertyValue[]
}

/**
 * Initialize an edit model using the entity and the propertyChanges passed with the options.
 * Note that we assume the entity has been created or resolved before. Thus, it is assumed
 * the entity is fully initialized.
 *
 * After initializing the model is in "non processing" state.
 *
 * @param state
 * @param action
 */
export const InitEntityEditModel = (state: EntityEditState, action: PayloadAction<InitEntityModelOptions>) => {
  const {entity, draft, readOnly, propertyChanges} = action.payload;
  const editModel = state.editModels[entity.id!] ?? {};
  console.log("Creating editmodel", editModel);
  state.editModels[entity.id!] = {
    ...editModel,
    draft,
    readOnly,
    entity,
    currentEntity: cloneDeep(entity),
    processing: false,
    propertyChanges,
    propertyChangesForEagerValues: [],
    validation: {},
  };
};
