/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableTextOrIconsColumn} from "@icm/core-common";
import {useEffect, useState} from "react";

import {isDataTableIconsColumn} from "../common";

const CONSIDERED_ROWS = 20;
const MIN_ROW_CHARS = 5;
const MAX_ROW_CHARS = 40;
const CHAR_WIDTH = 1.8;
// in ch (characters)
const MAX_MIN_TABLE_WIDTH = 140;
const ICON_WIDTH = 2;
const INITIAL_SPACING = 2;

type ColWidths = {
  // relative to each other
  columnWidths: number[]
  // minimal table width (use ch unit)
  minTableWidth: number
  // true if widths are final
  final: boolean
}

function getColumnValueLength<T>(row: T, col: DataTableTextOrIconsColumn<any>): number {
  if (isDataTableIconsColumn(col)) {
    return col.getIcons(row).length * ICON_WIDTH;
  }
  return col.formatValue(col.valueAccessor(row)).length * CHAR_WIDTH + (col.icon ? ICON_WIDTH : 0);
}

function getFormattedValueLengths<T>(col: DataTableTextOrIconsColumn<any>, consideredRows: T[]) {
  // also consider header text (+2 for sort icon)
  const headerLength = INITIAL_SPACING + (col.headerText?.length ?? 0) + (col.sortable ? ICON_WIDTH : 0);
  const rowValueLengths = consideredRows.map(row => getColumnValueLength(row, col));
  return [headerLength, ...rowValueLengths];
}

/**
 * find longest value (min 5, max 100)
 */
function findLongestValue(formattedValueLengths: number[]) {
  return formattedValueLengths.reduce((acc, formattedValue) => Math.min(MAX_ROW_CHARS, Math.max(acc, formattedValue)), MIN_ROW_CHARS);
}

/**
 * Hook that calculates the column widths based on the first available rows (up to {CONSIDERED_ROWS}).
 *
 * The widths are set as soon at least one row is available.
 *
 * @param rows the rows used to approximate the width
 * @param dataColumns column configurations
 */
export function useColWidthsBasedOnContent<T>(rows: T[], dataColumns: DataTableTextOrIconsColumn<T>[]) {
  const [widths, setWidths] = useState<ColWidths>();
  useEffect(() => {
    // set once initially and once again when data is available
    if ((rows.length === 0 && widths === undefined) || (rows.length > 0 && !widths?.final)) {
      const consideredRows = rows.slice(0, CONSIDERED_ROWS);
      const columnWidths = dataColumns.map((col) => {
        const formattedValueLengths: number[] = getFormattedValueLengths(col, consideredRows);
        return findLongestValue(formattedValueLengths);
      });
      setWidths({
        columnWidths,
        minTableWidth: Math.min(columnWidths.reduce((a, b) => a + b, 0), MAX_MIN_TABLE_WIDTH),
        final: rows.length > 0,
      });
    }
  }, [widths, rows, dataColumns]);
  return widths;
}
