/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.1.1185 on 2023-01-23 12:55:06.

/**
 * Generated from: at.cns.icm.entity.backend.api.data.EntityAndFormConfigurationDto
 */
export class EntityAndFormConfiguration {
    entity?: Entity;
    formConfiguration?: EntityFormConfiguration;

    static fromData(data: any): EntityAndFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityAndFormConfiguration = { entity: data.entity && Entity.fromData(data.entity), formConfiguration: data.formConfiguration && EntityFormConfiguration.fromData(data.formConfiguration) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entity','formConfiguration'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.EntityDto
 */
export class Entity {
    id?: string;
    type?: string;
    version?: string;
    keys?: EntityKey[];
    dynamicAttributes?: { [index: string]: DynamicAttributeValue };
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
    metadata?: { [index: string]: any };

    static fromData(data: any): Entity {
        if (!data) {
            return data;
        }
        const instance: Entity = { id: data.id && __verifyIsString(data.id), type: data.type && __verifyIsString(data.type), version: data.version && __verifyIsString(data.version), keys: data.keys && __getCopyArrayFn(EntityKey.fromData)(data.keys), dynamicAttributes: data.dynamicAttributes && __getCopyObjectFn(DynamicAttributeValue.fromData)(data.dynamicAttributes), createdAt: data.createdAt && new Date(data.createdAt), createdBy: data.createdBy && __verifyIsString(data.createdBy), updatedAt: data.updatedAt && new Date(data.updatedAt), updatedBy: data.updatedBy && __verifyIsString(data.updatedBy), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','type','version','keys','dynamicAttributes','createdAt','createdBy','updatedAt','updatedBy','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.EntityKeyDto
 */
export class EntityKey {
    name?: string;
    value?: string;

    static fromData(data: any): EntityKey {
        if (!data) {
            return data;
        }
        const instance: EntityKey = { name: data.name && __verifyIsString(data.name), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['name','value'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.EntityListPortionDto
 */
export class EntityListPortion {
    sublist?: Entity[];
    listSize?: number;
    offset?: number;

    static fromData(data: any): EntityListPortion {
        if (!data) {
            return data;
        }
        const instance: EntityListPortion = { sublist: data.sublist && __getCopyArrayFn(Entity.fromData)(data.sublist), listSize: data.listSize && __verifyIsNumber(data.listSize), offset: data.offset && __verifyIsNumber(data.offset) };
        return instance;
    }

    static getProperties(): string[] {
        return ['sublist','listSize','offset'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.EntityPropertyValueDto
 */
export class EntityPropertyValue {
    propertyName?: string;
    propertyValue?: any;

    static fromData(data: any): EntityPropertyValue {
        if (!data) {
            return data;
        }
        const instance: EntityPropertyValue = { propertyName: data.propertyName && __verifyIsString(data.propertyName), propertyValue: data.propertyValue && data.propertyValue };
        return instance;
    }

    static getProperties(): string[] {
        return ['propertyName','propertyValue'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.IdListPortionDto
 */
export class IdListPortion {
    sublist?: string[];
    listSize?: number;
    offset?: number;

    static fromData(data: any): IdListPortion {
        if (!data) {
            return data;
        }
        const instance: IdListPortion = { sublist: data.sublist && __getCopyArrayFn(__identity<string>())(data.sublist), listSize: data.listSize && __verifyIsNumber(data.listSize), offset: data.offset && __verifyIsNumber(data.offset) };
        return instance;
    }

    static getProperties(): string[] {
        return ['sublist','listSize','offset'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.WeakEntityListDto
 */
export class WeakEntityList {
    ids?: string[];
    data?: { [index: string]: Entity };

    static fromData(data: any): WeakEntityList {
        if (!data) {
            return data;
        }
        const instance: WeakEntityList = { ids: data.ids && __getCopyArrayFn(__identity<string>())(data.ids), data: data.data && __getCopyObjectFn(Entity.fromData)(data.data) };
        return instance;
    }

    static getProperties(): string[] {
        return ['ids','data'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.command.EntityCommandDto
 */
export class EntityCommand {
    entityCommandType: "DELETE" | "UPDATE";
    id?: string;
    predecessorIds?: string[];
    entityId?: string;
    entityType?: string;
    timestamp?: Date;
    userName?: string;
    roleNames?: string[];
    hostName?: string;

    static fromData(data: any): EntityCommand {
        if (!data) {
            return data;
        }
        const instance: EntityCommand = { entityCommandType: data.entityCommandType, id: data.id && __verifyIsString(data.id), predecessorIds: data.predecessorIds && __getCopyArrayFn(__identity<string>())(data.predecessorIds), entityId: data.entityId && __verifyIsString(data.entityId), entityType: data.entityType && __verifyIsString(data.entityType), timestamp: data.timestamp && new Date(data.timestamp), userName: data.userName && __verifyIsString(data.userName), roleNames: data.roleNames && __getCopyArrayFn(__identity<string>())(data.roleNames), hostName: data.hostName && __verifyIsString(data.hostName) };
        return instance;
    }

    static fromDataUnion(data: EntityCommandUnion): EntityCommandUnion {
        if (!data) {
            return data;
        }
        switch (data.entityCommandType) {
            case "DELETE":
                return DeleteEntityCommand.fromData(data);
            case "UPDATE":
                return UpdateEntityCommand.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['entityCommandType','id','predecessorIds','entityId','entityType','timestamp','userName','roleNames','hostName'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.command.DeleteEntityCommandDto
 */
export class DeleteEntityCommand extends EntityCommand {
    entityCommandType: "DELETE";

    static fromData(data: any): DeleteEntityCommand {
        if (!data) {
            return data;
        }
        const instance: DeleteEntityCommand = { ...(super.fromData(data) as DeleteEntityCommand) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityCommandType'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.command.EntityCommandProcessResultDto
 */
export class EntityCommandProcessResult {
    success?: boolean;
    metadata?: { [index: string]: any };

    static fromData(data: any): EntityCommandProcessResult {
        if (!data) {
            return data;
        }
        const instance: EntityCommandProcessResult = { success: data.success && __verifyIsBoolean(data.success), metadata: data.metadata && __getCopyObjectFn(__identity<any>())(data.metadata) };
        return instance;
    }

    static getProperties(): string[] {
        return ['success','metadata'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.command.UpdateEntityCommandDto
 */
export class UpdateEntityCommand extends EntityCommand {
    entityCommandType: "UPDATE";
    propertyChanges?: EntityPropertyValue[];

    static fromData(data: any): UpdateEntityCommand {
        if (!data) {
            return data;
        }
        const instance: UpdateEntityCommand = { ...(super.fromData(data) as UpdateEntityCommand), propertyChanges: data.propertyChanges && __getCopyArrayFn(EntityPropertyValue.fromData)(data.propertyChanges) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityCommandType','propertyChanges'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.AttributeConfigurationDto
 */
export class AttributeConfiguration {
    name?: string;
    label?: string;
    entityType?: string;
    format?: string;
    cardinality?: CardinalityEnum;
    keyPriority?: number;
    referenceType?: ReferenceType;
    fetchEager?: boolean;
    displayBinding?: string;
    possibleValuesList?: EntityPossibleValue[];

    static fromData(data: any): AttributeConfiguration {
        if (!data) {
            return data;
        }
        const instance: AttributeConfiguration = { name: data.name && __verifyIsString(data.name), label: data.label && __verifyIsString(data.label), entityType: data.entityType && __verifyIsString(data.entityType), format: data.format && __verifyIsString(data.format), cardinality: data.cardinality && data.cardinality, keyPriority: data.keyPriority && __verifyIsNumber(data.keyPriority), referenceType: data.referenceType && data.referenceType, fetchEager: data.fetchEager && __verifyIsBoolean(data.fetchEager), displayBinding: data.displayBinding && __verifyIsString(data.displayBinding), possibleValuesList: data.possibleValuesList && __getCopyArrayFn(EntityPossibleValue.fromData)(data.possibleValuesList) };
        return instance;
    }

    static getProperties(): string[] {
        return ['name','label','entityType','format','cardinality','keyPriority','referenceType','fetchEager','displayBinding','possibleValuesList'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.AttributesConfigurationDto
 */
export class AttributesConfiguration {
    attributeConfigurations?: AttributeConfiguration[];

    static fromData(data: any): AttributesConfiguration {
        if (!data) {
            return data;
        }
        const instance: AttributesConfiguration = { attributeConfigurations: data.attributeConfigurations && __getCopyArrayFn(AttributeConfiguration.fromData)(data.attributeConfigurations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['attributeConfigurations'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.EntityConfigurationDto
 */
export class EntityConfiguration {
    attributesConfiguration?: AttributesConfiguration;
    typeDisplay?: string;
    valueDisplay?: string;
    defaultVariantName?: string;

    static fromData(data: any): EntityConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityConfiguration = { attributesConfiguration: data.attributesConfiguration && AttributesConfiguration.fromData(data.attributesConfiguration), typeDisplay: data.typeDisplay && __verifyIsString(data.typeDisplay), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), defaultVariantName: data.defaultVariantName && __verifyIsString(data.defaultVariantName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['attributesConfiguration','typeDisplay','valueDisplay','defaultVariantName'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.EntityCreationConfigurationDto
 */
export class EntityCreationConfiguration {
    sourceEntityType?: string;
    sourceObjectType?: string;
    setValues?: SetValueConfiguration[];

    static fromData(data: any): EntityCreationConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityCreationConfiguration = { sourceEntityType: data.sourceEntityType && __verifyIsString(data.sourceEntityType), sourceObjectType: data.sourceObjectType && __verifyIsString(data.sourceObjectType), setValues: data.setValues && __getCopyArrayFn(SetValueConfiguration.fromData)(data.setValues) };
        return instance;
    }

    static getProperties(): string[] {
        return ['sourceEntityType','sourceObjectType','setValues'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.EntityLifecycleConfigurationDto
 */
export class EntityLifecycleConfiguration {
    entityCreations?: EntityCreationConfiguration[];

    static fromData(data: any): EntityLifecycleConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityLifecycleConfiguration = { entityCreations: data.entityCreations && __getCopyArrayFn(EntityCreationConfiguration.fromData)(data.entityCreations) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityCreations'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.EntityPossibleValueDto
 */
export class EntityPossibleValue {
    label?: string;
    value?: string;

    static fromData(data: any): EntityPossibleValue {
        if (!data) {
            return data;
        }
        const instance: EntityPossibleValue = { label: data.label && __verifyIsString(data.label), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['label','value'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.configuration.SetValueConfigurationDto
 */
export class SetValueConfiguration {
    target?: string;
    source?: string;
    value?: string;

    static fromData(data: any): SetValueConfiguration {
        if (!data) {
            return data;
        }
        const instance: SetValueConfiguration = { target: data.target && __verifyIsString(data.target), source: data.source && __verifyIsString(data.source), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['target','source','value'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.event.EntityEventDto
 */
export class EntityEvent {
    entityEventType: "CREATED" | "DELETED" | "UPDATED";
    id?: string;
    predecessorIds?: string[];
    entityId?: string;
    entityType?: string;
    commandTimestamp?: Date;
    timestamp?: Date;
    userName?: string;
    roleNames?: string[];
    hostName?: string;

    static fromData(data: any): EntityEvent {
        if (!data) {
            return data;
        }
        const instance: EntityEvent = { entityEventType: data.entityEventType, id: data.id && __verifyIsString(data.id), predecessorIds: data.predecessorIds && __getCopyArrayFn(__identity<string>())(data.predecessorIds), entityId: data.entityId && __verifyIsString(data.entityId), entityType: data.entityType && __verifyIsString(data.entityType), commandTimestamp: data.commandTimestamp && new Date(data.commandTimestamp), timestamp: data.timestamp && new Date(data.timestamp), userName: data.userName && __verifyIsString(data.userName), roleNames: data.roleNames && __getCopyArrayFn(__identity<string>())(data.roleNames), hostName: data.hostName && __verifyIsString(data.hostName) };
        return instance;
    }

    static fromDataUnion(data: EntityEventUnion): EntityEventUnion {
        if (!data) {
            return data;
        }
        switch (data.entityEventType) {
            case "CREATED":
                return EntityCreatedEvent.fromData(data);
            case "DELETED":
                return EntityDeletedEvent.fromData(data);
            case "UPDATED":
                return EntityUpdatedEvent.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['entityEventType','id','predecessorIds','entityId','entityType','commandTimestamp','timestamp','userName','roleNames','hostName'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.event.EntityCreatedEventDto
 */
export class EntityCreatedEvent extends EntityEvent {
    entityEventType: "CREATED";
    propertyValues?: EntityPropertyValue[];

    static fromData(data: any): EntityCreatedEvent {
        if (!data) {
            return data;
        }
        const instance: EntityCreatedEvent = { ...(super.fromData(data) as EntityCreatedEvent), propertyValues: data.propertyValues && __getCopyArrayFn(EntityPropertyValue.fromData)(data.propertyValues) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityEventType','propertyValues'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.event.EntityDeletedEventDto
 */
export class EntityDeletedEvent extends EntityEvent {
    entityEventType: "DELETED";

    static fromData(data: any): EntityDeletedEvent {
        if (!data) {
            return data;
        }
        const instance: EntityDeletedEvent = { ...(super.fromData(data) as EntityDeletedEvent) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityEventType'];
    }
}

/**
 * Generated from: at.cns.icm.entity.backend.api.data.event.EntityUpdatedEventDto
 */
export class EntityUpdatedEvent extends EntityEvent {
    entityEventType: "UPDATED";
    propertyChanges?: EntityPropertyValue[];

    static fromData(data: any): EntityUpdatedEvent {
        if (!data) {
            return data;
        }
        const instance: EntityUpdatedEvent = { ...(super.fromData(data) as EntityUpdatedEvent), propertyChanges: data.propertyChanges && __getCopyArrayFn(EntityPropertyValue.fromData)(data.propertyChanges) };
        return instance;
    }

    static getProperties(): string[] {
        return ['entityEventType','propertyChanges'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormConfigurationDto
 */
export class AbstractFormConfiguration {
    formConfigurationType: "ENTITY" | "GENERIC";
    formValueDisplay?: string;
    formTypeDisplay?: string;
    readOnly?: string;
    successMessageProvider?: string;
    successVariantProvider?: string;
    errorMessageProvider?: string;
    errorVariantProvider?: string;

    static fromData(data: any): AbstractFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: AbstractFormConfiguration = { formConfigurationType: data.formConfigurationType, formValueDisplay: data.formValueDisplay && __verifyIsString(data.formValueDisplay), formTypeDisplay: data.formTypeDisplay && __verifyIsString(data.formTypeDisplay), readOnly: data.readOnly && __verifyIsString(data.readOnly), successMessageProvider: data.successMessageProvider && __verifyIsString(data.successMessageProvider), successVariantProvider: data.successVariantProvider && __verifyIsString(data.successVariantProvider), errorMessageProvider: data.errorMessageProvider && __verifyIsString(data.errorMessageProvider), errorVariantProvider: data.errorVariantProvider && __verifyIsString(data.errorVariantProvider) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormConfigurationUnion): AbstractFormConfigurationUnion {
        if (!data) {
            return data;
        }
        switch (data.formConfigurationType) {
            case "ENTITY":
                return EntityFormConfiguration.fromData(data);
            case "GENERIC":
                return GenericFormConfiguration.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formConfigurationType','formValueDisplay','formTypeDisplay','readOnly','successMessageProvider','successVariantProvider','errorMessageProvider','errorVariantProvider'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormConfigurationDto
 */
export class EntityFormConfiguration extends AbstractFormConfiguration {
    formConfigurationType: "ENTITY";
    formActions?: FormActions;
    groups?: AbstractFormGroupUnion[];
    variantName?: string;

    static fromData(data: any): EntityFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityFormConfiguration = { ...(super.fromData(data) as EntityFormConfiguration), formActions: data.formActions && FormActions.fromData(data.formActions), groups: data.groups && __getCopyArrayFn(AbstractFormGroup.fromDataUnion)(data.groups), variantName: data.variantName && __verifyIsString(data.variantName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formConfigurationType','formActions','groups','variantName'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.DynamicAttributeValueDto
 */
export class DynamicAttributeValue {
    type?: any;
    id?: any;
    version?: any;
    value?: any;
    valueDisplay?: any;

    static fromData(data: any): DynamicAttributeValue {
        if (!data) {
            return data;
        }
        const instance: DynamicAttributeValue = { type: data.type && data.type, id: data.id && data.id, version: data.version && data.version, value: data.value && data.value, valueDisplay: data.valueDisplay && data.valueDisplay };
        return instance;
    }

    static getProperties(): string[] {
        return ['type','id','version','value','valueDisplay'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.FormActionsDto
 */
export class FormActions {
    actions?: Action[];

    static fromData(data: any): FormActions {
        if (!data) {
            return data;
        }
        const instance: FormActions = { actions: data.actions && __getCopyArrayFn(Action.fromData)(data.actions) };
        return instance;
    }

    static getProperties(): string[] {
        return ['actions'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormGroupDto
 */
export class AbstractFormGroup {
    formGroupType: "ENTITY" | "GENERIC";
    label?: string;
    icon?: string;
    visible?: string;
    width?: number;
    collapsable?: string;
    collapsedByDefault?: string;
    readOnly?: string;
    showHeader?: string;

    static fromData(data: any): AbstractFormGroup {
        if (!data) {
            return data;
        }
        const instance: AbstractFormGroup = { formGroupType: data.formGroupType, label: data.label && __verifyIsString(data.label), icon: data.icon && __verifyIsString(data.icon), visible: data.visible && __verifyIsString(data.visible), width: data.width && __verifyIsNumber(data.width), collapsable: data.collapsable && __verifyIsString(data.collapsable), collapsedByDefault: data.collapsedByDefault && __verifyIsString(data.collapsedByDefault), readOnly: data.readOnly && __verifyIsString(data.readOnly), showHeader: data.showHeader && __verifyIsString(data.showHeader) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormGroupUnion): AbstractFormGroupUnion {
        if (!data) {
            return data;
        }
        switch (data.formGroupType) {
            case "ENTITY":
                return EntityFormGroup.fromData(data);
            case "GENERIC":
                return GenericFormGroup.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formGroupType','label','icon','visible','width','collapsable','collapsedByDefault','readOnly','showHeader'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ActionDto
 */
export class Action {
    id?: string;
    icon?: string;
    action?: ActionType;
    label?: string;
    parameters?: Parameter[];
    objectParameters?: ObjectParameter[];
    visible?: string;

    static fromData(data: any): Action {
        if (!data) {
            return data;
        }
        const instance: Action = { id: data.id && __verifyIsString(data.id), icon: data.icon && __verifyIsString(data.icon), action: data.action && data.action, label: data.label && __verifyIsString(data.label), parameters: data.parameters && __getCopyArrayFn(Parameter.fromData)(data.parameters), objectParameters: data.objectParameters && __getCopyArrayFn(ObjectParameter.fromData)(data.objectParameters), visible: data.visible && __verifyIsString(data.visible) };
        return instance;
    }

    static getProperties(): string[] {
        return ['id','icon','action','label','parameters','objectParameters','visible'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormGroupDto
 */
export class EntityFormGroup extends AbstractFormGroup {
    formGroupType: "ENTITY";
    widgets?: AbstractWidgetUnion[];

    static fromData(data: any): EntityFormGroup {
        if (!data) {
            return data;
        }
        const instance: EntityFormGroup = { ...(super.fromData(data) as EntityFormGroup), widgets: data.widgets && __getCopyArrayFn(AbstractWidget.fromDataUnion)(data.widgets) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formGroupType','widgets'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormGroupDto
 */
export class GenericFormGroup extends AbstractFormGroup {
    formGroupType: "GENERIC";
    widgets?: AbstractWidgetUnion[];

    static fromData(data: any): GenericFormGroup {
        if (!data) {
            return data;
        }
        const instance: GenericFormGroup = { ...(super.fromData(data) as GenericFormGroup), widgets: data.widgets && __getCopyArrayFn(AbstractWidget.fromDataUnion)(data.widgets) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formGroupType','widgets'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormConfigurationDto
 */
export class GenericFormConfiguration extends AbstractFormConfiguration {
    formConfigurationType: "GENERIC";
    formActions?: FormActions;
    groups?: GenericFormGroup[];

    static fromData(data: any): GenericFormConfiguration {
        if (!data) {
            return data;
        }
        const instance: GenericFormConfiguration = { ...(super.fromData(data) as GenericFormConfiguration), formActions: data.formActions && FormActions.fromData(data.formActions), groups: data.groups && __getCopyArrayFn(GenericFormGroup.fromData)(data.groups) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formConfigurationType','formActions','groups'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ParameterDto
 */
export class Parameter {
    key?: string;
    value?: string;

    static fromData(data: any): Parameter {
        if (!data) {
            return data;
        }
        const instance: Parameter = { key: data.key && __verifyIsString(data.key), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['key','value'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.ui.ObjectParameterDto
 */
export class ObjectParameter {
    key?: string;
    attributes?: Parameter[];

    static fromData(data: any): ObjectParameter {
        if (!data) {
            return data;
        }
        const instance: ObjectParameter = { key: data.key && __verifyIsString(data.key), attributes: data.attributes && __getCopyArrayFn(Parameter.fromData)(data.attributes) };
        return instance;
    }

    static getProperties(): string[] {
        return ['key','attributes'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractWidgetDto
 */
export class AbstractWidget {
    widgetType: "ENTITY_FIELD" | "GENERIC_FIELD" | "ENTITY_JOURNAL" | "ENTITY_LIST" | "ENTITY_WORKFLOW_ACTIVE_TASKS";
    label?: string;
    visible?: string;
    width?: number;
    height?: number;

    static fromData(data: any): AbstractWidget {
        if (!data) {
            return data;
        }
        const instance: AbstractWidget = { widgetType: data.widgetType, label: data.label && __verifyIsString(data.label), visible: data.visible && __verifyIsString(data.visible), width: data.width && __verifyIsNumber(data.width), height: data.height && __verifyIsNumber(data.height) };
        return instance;
    }

    static fromDataUnion(data: AbstractWidgetUnion): AbstractWidgetUnion {
        if (!data) {
            return data;
        }
        switch (data.widgetType) {
            case "ENTITY_FIELD":
                return EntityFormField.fromData(data);
            case "GENERIC_FIELD":
                return GenericFormField.fromData(data);
            case "ENTITY_JOURNAL":
                return EntityJournal.fromData(data);
            case "ENTITY_LIST":
                return EntityList.fromData(data);
            case "ENTITY_WORKFLOW_ACTIVE_TASKS":
                return EntityWorkflowActiveTasks.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['widgetType','label','visible','width','height'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormFieldDto
 */
export class AbstractFormField extends AbstractWidget {
    widgetType: "ENTITY_FIELD" | "GENERIC_FIELD";
    component?: ComponentType;
    format?: string;
    helperText?: string;
    icon?: string;
    valueDisplay?: string;
    valueParser?: string;
    initialValueProvider?: string;
    editable?: string;
    readOnly?: string;
    valid?: string;
    mandatory?: string;
    parameterList?: Parameter[];

    static fromData(data: any): AbstractFormField {
        if (!data) {
            return data;
        }
        const instance: AbstractFormField = { ...(super.fromData(data) as AbstractFormField), component: data.component && data.component, format: data.format && __verifyIsString(data.format), helperText: data.helperText && __verifyIsString(data.helperText), icon: data.icon && __verifyIsString(data.icon), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), valueParser: data.valueParser && __verifyIsString(data.valueParser), initialValueProvider: data.initialValueProvider && __verifyIsString(data.initialValueProvider), editable: data.editable && __verifyIsString(data.editable), readOnly: data.readOnly && __verifyIsString(data.readOnly), valid: data.valid && __verifyIsString(data.valid), mandatory: data.mandatory && __verifyIsString(data.mandatory), parameterList: data.parameterList && __getCopyArrayFn(Parameter.fromData)(data.parameterList) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormFieldUnion): AbstractFormFieldUnion {
        if (!data) {
            return data;
        }
        switch (data.widgetType) {
            case "ENTITY_FIELD":
                return EntityFormField.fromData(data);
            case "GENERIC_FIELD":
                return GenericFormField.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['widgetType','component','format','helperText','icon','valueDisplay','valueParser','initialValueProvider','editable','readOnly','valid','mandatory','parameterList'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityJournalDto
 */
export class EntityJournal extends AbstractWidget {
    widgetType: "ENTITY_JOURNAL";
    entityType?: string;
    attributeName?: string;

    static fromData(data: any): EntityJournal {
        if (!data) {
            return data;
        }
        const instance: EntityJournal = { ...(super.fromData(data) as EntityJournal), entityType: data.entityType && __verifyIsString(data.entityType), attributeName: data.attributeName && __verifyIsString(data.attributeName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','entityType','attributeName'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityListDto
 */
export class EntityList extends AbstractWidget {
    widgetType: "ENTITY_LIST";
    entityType?: string;
    listVariant?: string;
    filterVariant?: string;
    filterModifier?: string;
    maxHeight?: number;

    static fromData(data: any): EntityList {
        if (!data) {
            return data;
        }
        const instance: EntityList = { ...(super.fromData(data) as EntityList), entityType: data.entityType && __verifyIsString(data.entityType), listVariant: data.listVariant && __verifyIsString(data.listVariant), filterVariant: data.filterVariant && __verifyIsString(data.filterVariant), filterModifier: data.filterModifier && __verifyIsString(data.filterModifier), maxHeight: data.maxHeight && __verifyIsNumber(data.maxHeight) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','entityType','listVariant','filterVariant','filterModifier','maxHeight'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityWorkflowActiveTasksDto
 */
export class EntityWorkflowActiveTasks extends AbstractWidget {
    widgetType: "ENTITY_WORKFLOW_ACTIVE_TASKS";

    static fromData(data: any): EntityWorkflowActiveTasks {
        if (!data) {
            return data;
        }
        const instance: EntityWorkflowActiveTasks = { ...(super.fromData(data) as EntityWorkflowActiveTasks) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormFieldDto
 */
export class EntityFormField extends AbstractFormField {
    widgetType: "ENTITY_FIELD";
    attributeName?: string;
    simpleListConfiguration?: AbstractFormFieldListConfigurationUnion;

    static fromData(data: any): EntityFormField {
        if (!data) {
            return data;
        }
        const instance: EntityFormField = { ...(super.fromData(data) as EntityFormField), attributeName: data.attributeName && __verifyIsString(data.attributeName), simpleListConfiguration: data.simpleListConfiguration && AbstractFormFieldListConfiguration.fromDataUnion(data.simpleListConfiguration) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','attributeName','simpleListConfiguration'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormFieldDto
 */
export class GenericFormField extends AbstractFormField {
    widgetType: "GENERIC_FIELD";
    valueBinding?: string;
    possibleValuesList?: PossibleValue[];
    simpleListConfiguration?: GenericFormFieldListConfiguration;
    possibleValuesUrl?: string;
    possibleValuesByValuesUrl?: string;
    possibleValuesDisplayBinding?: string;
    possibleValuesValueBinding?: string;
    possibleValuesVersionBinding?: string;

    static fromData(data: any): GenericFormField {
        if (!data) {
            return data;
        }
        const instance: GenericFormField = { ...(super.fromData(data) as GenericFormField), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding), possibleValuesList: data.possibleValuesList && __getCopyArrayFn(PossibleValue.fromData)(data.possibleValuesList), simpleListConfiguration: data.simpleListConfiguration && GenericFormFieldListConfiguration.fromData(data.simpleListConfiguration), possibleValuesUrl: data.possibleValuesUrl && __verifyIsString(data.possibleValuesUrl), possibleValuesByValuesUrl: data.possibleValuesByValuesUrl && __verifyIsString(data.possibleValuesByValuesUrl), possibleValuesDisplayBinding: data.possibleValuesDisplayBinding && __verifyIsString(data.possibleValuesDisplayBinding), possibleValuesValueBinding: data.possibleValuesValueBinding && __verifyIsString(data.possibleValuesValueBinding), possibleValuesVersionBinding: data.possibleValuesVersionBinding && __verifyIsString(data.possibleValuesVersionBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['widgetType','valueBinding','possibleValuesList','simpleListConfiguration','possibleValuesUrl','possibleValuesByValuesUrl','possibleValuesDisplayBinding','possibleValuesValueBinding','possibleValuesVersionBinding'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormFieldListConfigurationDto
 */
export class AbstractFormFieldListConfiguration {
    formFieldListConfigurationType: "GENERIC" | "ENTITY";
    itemAddable?: boolean;
    itemRemovable?: boolean;
    fileAddable?: boolean;
    defaultSortColumn?: string;
    defaultSortOrder?: SortOrder;

    static fromData(data: any): AbstractFormFieldListConfiguration {
        if (!data) {
            return data;
        }
        const instance: AbstractFormFieldListConfiguration = { formFieldListConfigurationType: data.formFieldListConfigurationType, itemAddable: data.itemAddable && __verifyIsBoolean(data.itemAddable), itemRemovable: data.itemRemovable && __verifyIsBoolean(data.itemRemovable), fileAddable: data.fileAddable && __verifyIsBoolean(data.fileAddable), defaultSortColumn: data.defaultSortColumn && __verifyIsString(data.defaultSortColumn), defaultSortOrder: data.defaultSortOrder && data.defaultSortOrder };
        return instance;
    }

    static fromDataUnion(data: AbstractFormFieldListConfigurationUnion): AbstractFormFieldListConfigurationUnion {
        if (!data) {
            return data;
        }
        switch (data.formFieldListConfigurationType) {
            case "ENTITY":
                return EntityFormFieldListConfiguration.fromData(data);
            case "GENERIC":
                return GenericFormFieldListConfiguration.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formFieldListConfigurationType','itemAddable','itemRemovable','fileAddable','defaultSortColumn','defaultSortOrder'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.PossibleValueDto
 */
export class PossibleValue {
    label?: string;
    value?: string;

    static fromData(data: any): PossibleValue {
        if (!data) {
            return data;
        }
        const instance: PossibleValue = { label: data.label && __verifyIsString(data.label), value: data.value && __verifyIsString(data.value) };
        return instance;
    }

    static getProperties(): string[] {
        return ['label','value'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormFieldListConfigurationDto
 */
export class GenericFormFieldListConfiguration extends AbstractFormFieldListConfiguration {
    formFieldListConfigurationType: "GENERIC";
    simpleColumns?: GenericFormFieldListColumn[];
    formConfigurationUrl?: string;

    static fromData(data: any): GenericFormFieldListConfiguration {
        if (!data) {
            return data;
        }
        const instance: GenericFormFieldListConfiguration = { ...(super.fromData(data) as GenericFormFieldListConfiguration), simpleColumns: data.simpleColumns && __getCopyArrayFn(GenericFormFieldListColumn.fromData)(data.simpleColumns), formConfigurationUrl: data.formConfigurationUrl && __verifyIsString(data.formConfigurationUrl) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListConfigurationType','simpleColumns','formConfigurationUrl'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormFieldListConfigurationDto
 */
export class EntityFormFieldListConfiguration extends AbstractFormFieldListConfiguration {
    formFieldListConfigurationType: "ENTITY";
    formVariant?: string;
    simpleColumns?: AbstractFormFieldListColumnUnion[];

    static fromData(data: any): EntityFormFieldListConfiguration {
        if (!data) {
            return data;
        }
        const instance: EntityFormFieldListConfiguration = { ...(super.fromData(data) as EntityFormFieldListConfiguration), formVariant: data.formVariant && __verifyIsString(data.formVariant), simpleColumns: data.simpleColumns && __getCopyArrayFn(AbstractFormFieldListColumn.fromDataUnion)(data.simpleColumns) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListConfigurationType','formVariant','simpleColumns'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.AbstractFormFieldListColumnDto
 */
export class AbstractFormFieldListColumn {
    formFieldListColumnType: "GENERIC" | "ENTITY";
    columnType?: FormFieldListColumnType;
    columnName?: string;
    headerText?: string;
    valueDisplay?: string;
    format?: string;
    action?: Action;
    sortable?: boolean;

    static fromData(data: any): AbstractFormFieldListColumn {
        if (!data) {
            return data;
        }
        const instance: AbstractFormFieldListColumn = { formFieldListColumnType: data.formFieldListColumnType, columnType: data.columnType && data.columnType, columnName: data.columnName && __verifyIsString(data.columnName), headerText: data.headerText && __verifyIsString(data.headerText), valueDisplay: data.valueDisplay && __verifyIsString(data.valueDisplay), format: data.format && __verifyIsString(data.format), action: data.action && Action.fromData(data.action), sortable: data.sortable && __verifyIsBoolean(data.sortable) };
        return instance;
    }

    static fromDataUnion(data: AbstractFormFieldListColumnUnion): AbstractFormFieldListColumnUnion {
        if (!data) {
            return data;
        }
        switch (data.formFieldListColumnType) {
            case "ENTITY":
                return EntityFormFieldListColumn.fromData(data);
            case "GENERIC":
                return GenericFormFieldListColumn.fromData(data);
        }
        return data;
    }

    static getProperties(): string[] {
        return ['formFieldListColumnType','columnType','columnName','headerText','valueDisplay','format','action','sortable'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.GenericFormFieldListColumnDto
 */
export class GenericFormFieldListColumn extends AbstractFormFieldListColumn {
    formFieldListColumnType: "GENERIC";
    valueBinding?: string;

    static fromData(data: any): GenericFormFieldListColumn {
        if (!data) {
            return data;
        }
        const instance: GenericFormFieldListColumn = { ...(super.fromData(data) as GenericFormFieldListColumn), valueBinding: data.valueBinding && __verifyIsString(data.valueBinding) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListColumnType','valueBinding'];
    }
}

/**
 * Generated from: at.cns.icm.core.backend.api.data.form.EntityFormFieldListColumnDto
 */
export class EntityFormFieldListColumn extends AbstractFormFieldListColumn {
    formFieldListColumnType: "ENTITY";
    attributeName?: string;

    static fromData(data: any): EntityFormFieldListColumn {
        if (!data) {
            return data;
        }
        const instance: EntityFormFieldListColumn = { ...(super.fromData(data) as EntityFormFieldListColumn), attributeName: data.attributeName && __verifyIsString(data.attributeName) };
        return instance;
    }

    static getProperties(): string[] {
        return ['formFieldListColumnType','attributeName'];
    }
}

export type CardinalityEnum = "SINGLE" | "LIST";

export type ReferenceType = "WEAK" | "BY_VALUE" | "BY_ID" | "BY_ID_AND_VERSION";

export type ActionType = "SAVE" | "ADD" | "OPEN" | "PRINT" | "CLOSE" | "BOOKMARK_ADD" | "BOOKMARK_REMOVE" | "DELETE" | "OPEN_URL" | "DOWNLOAD" | "REFRESH" | "INVOKE_URL" | "CREATE_ENTITY" | "CREATE_WEAK_ENTITY" | "OPEN_SEND_MESSAGE_DIALOG";

export type ComponentType = "TEXT_FIELD" | "TEXT_AREA" | "DATE_PICKER" | "TIME_PICKER" | "DATETIME_PICKER" | "SELECT_SINGLE" | "SELECT_MULTI" | "CHECKBOX" | "RADIOBUTTON" | "LIST" | "PROCESS_MODEL" | "LABEL" | "MEDIA_STREAM_PLAYER" | "WEATHER_BUTTON" | "DEMO" | "IFRAME";

export type SortOrder = "ASC" | "DESC";

export type FormFieldListColumnType = "DATA" | "ACTION";

export type EntityCommandUnion = DeleteEntityCommand | UpdateEntityCommand;

export type EntityEventUnion = EntityCreatedEvent | EntityDeletedEvent | EntityUpdatedEvent;

export type AbstractFormGroupUnion = EntityFormGroup | GenericFormGroup;

export type AbstractFormConfigurationUnion = EntityFormConfiguration | GenericFormConfiguration;

export type AbstractWidgetUnion = AbstractFormField | EntityJournal | EntityList | EntityWorkflowActiveTasks;

export type AbstractFormFieldUnion = EntityFormField | GenericFormField;

export type AbstractFormFieldListConfigurationUnion = EntityFormFieldListConfiguration | GenericFormFieldListConfiguration;

export type AbstractFormFieldListColumnUnion = EntityFormFieldListColumn | GenericFormFieldListColumn;

// @ts-ignore
function __verifyIsNumber(value: any): number {
    if (typeof value === "number") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a number`);
}

// @ts-ignore
function __verifyIsString(value: any): string {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a string`);
}

// @ts-ignore
function __verifyIsBoolean(value: any): boolean {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`Parsing failed, value (${value}) is not a boolean`);
}

// @ts-ignore
function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

// @ts-ignore
function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

// @ts-ignore
function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

// @ts-ignore
function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

// @ts-ignore
function __identity<T>(): (value: T) => T {
    return value => value;
}
