/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Paths} from "@icm/core-common";
import * as React from "react";
import {Route, Routes} from "react-router-dom";

import {LoginPage} from "../login/";
import {PasswordChangePage} from "../passwordChange/PasswordChangePage";
import {RoleSelectionPage} from "../roleSelection/RoleSelectionPage";
import {MainPage} from "./MainPage";


export const RoutingPage = () => {
  return (
    <Routes>
      <Route path={Paths.LOGIN} element={<LoginPage />} />
      <Route path={Paths.ROLE_SELECTION} element={<RoleSelectionPage />} />
      <Route path={Paths.PASSWORD_CHANGE} element={<PasswordChangePage />} />
      <Route path={Paths.ANY} element={<MainPage />} />
    </Routes>
  );
};
