/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {UAParser} from "ua-parser-js";


type OperatingSystemInformation = {
  name?: string;
  version?: string;
}
type BrowserInformation = {
  name?: string;
  version?: string;
}

class UserAgentParser {

  private readonly parser;

  /**
   * @param userAgent the user agent to parse (uses the browser's if not given)
   */
  constructor(userAgent?: string) {
    this.parser = new UAParser(userAgent);
  }

  /**
   * Parses the navigator.appVersion and navigator.userAgent values to get sensible browser name and version.
   * Source: http://www.javascripter.net/faq/browsern.htm
   */
  public getBrowserInformation(): BrowserInformation {
    return {
      name: this.parser.getBrowser().name,
      version: this.parser.getBrowser().version,
    };
  }

  public getOperatingSystemInformation(): OperatingSystemInformation {
    return {
      name: this.parser.getOS().name,
      version: this.parser.getOS().version,
    };
  }

  public getCpuArchitecture(): string | undefined {
    return this.parser.getCPU().architecture;
  }

  public getAllParsableInformation() {
    return this.parser.getResult();
  }
}

export {UserAgentParser};
