/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import {GenericFormConfiguration, MessageKey} from "../../index";
import {ExpressionEvaluationService, FeedbackVariant, messages} from "../service";

export type MessageWithVariant = {
  text: string
  variant: FeedbackVariant
}

export class FormMessagesHelper {
  public static getSuccessMessage(formConfiguration: GenericFormConfiguration | undefined, data: any,
                                  defaultMessage: string = messages.get(MessageKey.CORE.FORM.SAVE_SUCCESS)): MessageWithVariant {
    return FormMessagesHelper.getMessage(formConfiguration?.successMessageProvider, formConfiguration?.successVariantProvider,
      data, defaultMessage, "SUCCESS");
  }

  public static getErrorMessage(formConfiguration: GenericFormConfiguration | undefined, data: any,
                                defaultMessage: string = messages.get(MessageKey.CORE.FORM.SAVE_FAILED)): MessageWithVariant {
    return FormMessagesHelper.getMessage(formConfiguration?.errorMessageProvider, formConfiguration?.errorVariantProvider,
      data, defaultMessage, "ERROR");
  }

  private static getMessage(textExpression: string | undefined, variantExpression: string | undefined, data: any,
                            defaultMessage: string, defaultVariant: FeedbackVariant): MessageWithVariant {
    let messageText = defaultMessage;
    let messageVariant = defaultVariant;
    if (textExpression) {
      messageText = ExpressionEvaluationService.evaluate(textExpression, messages, data, {});
    }
    if (variantExpression) {
      messageVariant = ExpressionEvaluationService.evaluate(variantExpression, messages, data, {});
    }
    return {text: messageText, variant: messageVariant};
  }
}
