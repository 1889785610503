/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmHistoryLocationState, MessageKey, useFeedbackBanners, useMessages, useService} from "@icm/core-common";
import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router";

import {LoginPageUi} from "./LoginPageUi";


export function isIcmHistoryLocationState(s: unknown): s is IcmHistoryLocationState {
  return s !== null && (typeof s === "object") && s["unauthorizedTargetUrl"];
}

const useHistoryLocationState = () => {
  const location = useLocation();
  if (isIcmHistoryLocationState(location.state)) {
    return location.state;
  }
  return undefined;
};

export const LoginPage = () => {
  const {getMessage} = useMessages();
  const {openFeedbackBanner} = useFeedbackBanners();
  const storage = useService("STORAGE");

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const unauthorizedTargetUrl = useHistoryLocationState()?.unauthorizedTargetUrl;
  const securityService = useService("SECURITY");

  useEffect(() => {
    const handleLoginFailure = (loginErrorMessage: string) => {
      console.log("Handling login failure");
      setErrorMessage(loginErrorMessage);
    };
    securityService?.addFailedLoginListener(handleLoginFailure);
    return () => {
      securityService?.removeFailedLoginListener(handleLoginFailure);
    };
  }, [securityService]);
  useEffect(() => {
    if (unauthorizedTargetUrl) {
      openFeedbackBanner({
        key: "UNAUTHORIZED_TARGET_URL",
        title: getMessage(MessageKey.CORE.LOGIN.ERROR.UNAUTHORIZED_TARGET_URL),
        variant: "ERROR",
        duration: "SHORT",
      });
    }
  }, [getMessage, unauthorizedTargetUrl, openFeedbackBanner]);

  useEffect(() => {
    securityService?.checkLoginState();
  }, [securityService]);

  const handleLogin = useCallback((userName: string, password: string) => {
    securityService?.authenticateWithCredentials(userName, password);
  }, [securityService]);

  const handleErrorCleared = useCallback(() => {
    setErrorMessage(undefined);
  }, [setErrorMessage]);

  return (
    <LoginPageUi getMessage={getMessage}
                 storage={storage}
                 onLogin={handleLogin}
                 errorMessage={errorMessage}
                 onErrorCleared={handleErrorCleared}
    />
  );
};
