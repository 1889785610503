/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FormGeneratorProps, useFormGeneratorContext, useGenericFormGroups} from "@icm/core-common";
import {Grid} from "@mui/material";
import {GridSize} from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import * as React from "react";
import {PropsWithChildren, useMemo} from "react";


import {FormSubmitButton} from "../util/FormSubmitButton";
import {styles} from "./WebFormGeneratorLayout.style";
import {WebFormGroup} from "./WebFormGroup";

const useStyles = makeStyles(styles);

const DEFAULT_FORM_GROUP_WIDTH = 4;

export const useWebFormGroups = () => {
  const {evaluateRule, formConfig, highlightedFields} = useFormGeneratorContext();
  const visibleGroups = useGenericFormGroups(evaluateRule, formConfig);
  return useMemo(() => {
    return visibleGroups.map((formGroup, key) => {
      const xs: GridSize = formGroup.width || DEFAULT_FORM_GROUP_WIDTH;
      return (
        <Grid key={key} item={true} xs={xs} alignItems="stretch">
          <WebFormGroup formConfig={formConfig} group={formGroup} highlightedFields={highlightedFields} />
        </Grid>
      );
    });
  }, [visibleGroups, formConfig, highlightedFields]);
};

export const WebFormGeneratorLayout = <T extends {}>(props: PropsWithChildren<FormGeneratorProps<T>>) => {
  const {
    renderFormContainer,
    renderActionContainer,
    renderActionsInHeader,
  } = props;
  const classes = useStyles();
  const groups = useWebFormGroups();

  const {renderSubmitButton, submitButtonConfiguration} = props;
  const SubmitButton = useMemo(() => {
    return (
      <FormSubmitButton
      renderSubmitButton={renderSubmitButton}
      submitButtonConfiguration={submitButtonConfiguration}
      />
    );
  }, [renderSubmitButton, submitButtonConfiguration]);

  return (
    <div className={classes.formRoot}>
      <Grid className={classes.groupContainer} container spacing={1}>
        {renderFormContainer ? (
          renderFormContainer(groups)
        ) : (
          groups
        )}
      </Grid>
      {!renderActionsInHeader && (
        renderActionContainer ? (
          renderActionContainer(SubmitButton)
        ) : (
          <Grid className={classes.buttonContainer} container justifyContent="flex-end">
            <Grid item>
              {SubmitButton}
            </Grid>
          </Grid>
        )
      )}
    </div>
  );
};
