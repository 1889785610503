/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {Fab, FabProps} from "@mui/material";
import React from "react";

import {createStyles} from "../../../theme";
import {ConditionalTooltip, IcmMuiIcon} from "../../util";
import {IconButtonComponentProps} from "./IconButtonComponent";

const styles = createStyles({
  iconOnlyButton: {
    boxShadow: theme => theme.shadows[2],
    "&:active": {
      boxShadow: theme => theme.shadows[8],
    },
  },
});

type OutlinedIconButtonProps =
  & Pick<IconButtonComponentProps, "tooltip" | "tooltipPlacement" | "icon" | "disabled">
  & Pick<FabProps, "onClick">;

export const OutlinedIconButton = ({icon, tooltip, tooltipPlacement, disabled, onClick}: OutlinedIconButtonProps) => {
  return (
    <ConditionalTooltip title={tooltip} placement={tooltipPlacement}>
      <span>
        <Fab color="primary"
             size="small"
             disabled={disabled}
             sx={styles.iconOnlyButton}
             onClick={onClick}
        >
          {typeof icon === "string" ? <IcmMuiIcon name={icon} /> : icon}
        </Fab>
      </span>
    </ConditionalTooltip>
  );
};
