/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import {GenericFormField} from "../../generated/api";
import {ExpressionEvaluationService, useService} from "../../service";
import {ParameterUtilities} from "../../util";
import {ViewContext, ViewContextValue} from "../view";
import {FieldValidationState, FieldValidationType} from "./FieldValidationState";
import {FormGeneratorProps} from "./FormGenerator";
import {FieldHighlightingInformation} from "./FormGeneratorTypes";

export const useFormGenerator = <T extends {}>(props: FormGeneratorProps<T>) => {
  const {
    entity,
    persistedEntity,
    ruleContextAddition,
    onChange,
    unHighlightField,
    highlightedFields,
    readOnly,
    formConfig,
    useSimpleValues,
    onFormValidationChange,
  } = props;

  const viewContext: ViewContextValue | undefined = useContext(ViewContext);
  const securityService = useService("SECURITY");

  const getDetailsForExpressionContext = useCallback(() => {
    const viewParameterMap = viewContext?.viewParameters ? ParameterUtilities.flattenParameterList(viewContext.viewParameters) : {};
    return {
      ...securityService.getVisibilityExpressionContext(),
      viewParameterMap,
      ...ruleContextAddition,
    };
  }, [securityService, viewContext, ruleContextAddition]);

  const entityForRule = useMemo(() => persistedEntity || entity, [persistedEntity, entity]);

  const evaluateRule = useCallback((rule: string | undefined, dflt: boolean, fieldValue?: any): boolean => {
    return rule ? ExpressionEvaluationService.evaluateRule(rule, entityForRule, fieldValue, getDetailsForExpressionContext()) : dflt;
  }, [getDetailsForExpressionContext, entityForRule]);

  const evaluateExpression = useCallback((expr: string | undefined, fieldValue?: any): string | undefined => {
    return expr ? ExpressionEvaluationService.evaluate(expr, entityForRule, fieldValue) : undefined;
  }, [entityForRule]);

  // if the entity changes, this callback MUST NOT change to avoid expensive recomputations of components!
  const handleChange = useCallback((field: GenericFormField, value?: any, referredObject?: any) => {
    if (onChange) {
      onChange(field, value, referredObject);
    }
  }, [onChange]);

  const [fieldValidationMap, setFieldValidationMap] = useState<Record<string, FieldValidationState>>({});

  const valid = useMemo(() => {
    const validationKeys = Object.keys(fieldValidationMap);
    return validationKeys.length === 0 || validationKeys.every(key => fieldValidationMap[key].valid);
  }, [fieldValidationMap]);

  useEffect(() => {
    onFormValidationChange?.(valid);
  }, [onFormValidationChange, valid]);

  const updateFieldValidationState = useCallback((validationType: FieldValidationType, valueBinding: string, isFieldValid: boolean) => {
    setFieldValidationMap(current => {
      let update = false;
      if (!current[valueBinding]) {
        current[valueBinding] = FieldValidationState.init();
        update = true;
      }
      if (!update && current[valueBinding].validBy(validationType) === isFieldValid) {
        return current;
      }
      return {
        ...current,
        [valueBinding]: current[valueBinding].update(validationType, isFieldValid),
      };
    });
  }, []);

  const findHighlight = useCallback((valueBinding?: string): FieldHighlightingInformation | undefined => {
    const attributeKey = getHighlightedFieldsAttributeKey(valueBinding);
    if (!attributeKey || !highlightedFields || !valueBinding) {
      return undefined;
    }
    const foundHighlightKey = Object.keys(highlightedFields)
      .find(item => item.startsWith(attributeKey + "."));
    return foundHighlightKey ? highlightedFields[foundHighlightKey] : undefined;
  }, [highlightedFields]);

  return {
    evaluateRule,
    evaluateExpression,
    handleChange,
    valid,
    updateFieldValidationState,
    entity,
    readOnly,
    formConfig,
    highlightedFields,
    useSimpleValues,
    getDetailsForExpressionContext,
    findHighlight,
    unHighlightField,
  };
};


const getHighlightedFieldsAttributeKey = (valueBinding?: string): string | undefined => {
  if (!valueBinding) {
    return undefined;
  }
  const split: string[] = valueBinding.split(".");
  split.pop();
  return split?.join(".");
};
