/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {configureStore, createNextState} from "@reduxjs/toolkit";
import {Action, combineReducers, Reducer} from "redux";
import {combineEpics, createEpicMiddleware, Epic} from "redux-observable";
import {createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync} from "redux-state-sync";

import {ICoreApplicationState} from "./register";

// when an action with this type is dispatched, the whole store's state is reset to the initial state
export const RESET_STORE_TYPE = "@@root/RESET_STORE";

// {[stateKey]: Reducer}
const reducers: Partial<Record<string, Reducer>> = {};

const epics: Epic<Action<any>, any, any, any>[] = [];

// actions that are synced across browser tabs
const syncedActions: string[] = [];

/**
 * Register the state, reducer, epic and synced actions in the central store.
 * @param stateKey the key of the state within the global application state
 * @param reducer the state's reducer
 * @param epic the epic
 * @param syncActions actions that shall be synced across browser tabs. Use typesafe-actions#getType
 */
export function registerInStore<STATE>(stateKey: string, reducer: Reducer<STATE>, epic?: Epic, syncActions?: Array<string>) {
  if (reducers[stateKey]) {
    throw Error(`Store: Duplicate store registration for ${stateKey}`);
  }
  console.debug(`Store: Registering reducer and epics for ${stateKey}`);
  reducers[stateKey] = reducer;
  if (epic) {
    epics.push(epic);
  }
  if (syncActions) {
    syncedActions.push(...syncActions);
  }
}
export type RegisterInStore = typeof registerInStore;

function createRootReducer() {
  // combine reducers has "false" type signature
  const rootReducer = combineReducers(reducers as Record<string, Reducer>);

  return (state: any | undefined, action: Action<any>) => {
    if (action.type === RESET_STORE_TYPE) {
      console.debug("Resetting redux store");
      return rootReducer(undefined, action);
    }

    return rootReducer(state, action);
  };
}

/**
 * @return
 *  - store, the redux store to be used
 *  - persistor, an object which is configured to store the state of the application
 */
export const customConfigureStore = () => {
  const rootEpic: Epic<Action<any>, any, any, any> = combineEpics(...epics);
  const epicMiddleware = createEpicMiddleware({
    dependencies: {},
  });
  const store = configureStore({
    reducer: withReduxStateSync(createRootReducer()),

    middleware: getDefaultMiddleware => {
      console.log("syncedActions", syncedActions);
      const stateSyncMiddleware = createStateSyncMiddleware({
        whitelist: syncedActions,
        // include path name to avoid problems when multiple icm instances are served from the same host
        channel: "redux_state_sync" + window.location.pathname,
        prepareState: (state: ICoreApplicationState) => {
          return createNextState(state, draft => {
            Object.keys(draft).forEach(key => ["messageState"].includes(key) || delete draft[key]);
            // // TODO FW-1982 explicitly split mutable and static state
            // const strippedUiState: Partial<IUiState> = {...draft.uiState};
            // draft.uiState = {...INITIAL_UI_STATE, ...strippedUiState};
          });
        },
      });
      return getDefaultMiddleware({
        // TODO FW-761 reconsider serializable check after proper generation of typescript definitions, also the usage of Date is a problem (not serializable)
        serializableCheck: false,
      }).prepend(epicMiddleware)
        .concat(stateSyncMiddleware);
    },
  });
  initStateWithPrevTab(store);
  epicMiddleware.run(rootEpic);
  return store;
};


export type RootState = ReturnType<typeof customConfigureStore>;
