/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {DataTableCellProps, DataTableIconsColumn, resolveRowTextElementIcon, useService} from "@icm/core-common";
import React from "react";

import {styles} from "./DataTableIconsCellContent.style";

type Props<T> = {
  col: DataTableIconsColumn<T>
  rowEntity: any
} & DataTableCellProps<T>;
export const DataTableIconsCellContent = <T extends unknown>(props: Props<T>) => {
  const {col, rowEntity, rowValue} = props;
  const securityService = useService("SECURITY");
  const icons = col.getIcons(rowValue);

  return (
    <div className={styles.iconContainer}>
      {icons.map((icon, key) => <React.Fragment key={key}>{resolveRowTextElementIcon(icon, rowEntity, securityService)}</React.Fragment>)}
    </div>
  );
};
