/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {CoreApi} from "../api";
import {SecurityService, useService} from "../service";
import {NavigationSideBar, SideBar} from "../store";
import {getOpenActions, toActionBarItem} from "./ActionBarHelper";
import {useUiConfiguration} from "./useUiConfiguration";

/**
 * Return the sidebar configuration for the given id.
 * @param sideBarId
 */
export const useSideBar = (sideBarId: string): SideBar | undefined => {
  const conf = useUiConfiguration();
  const securityService = useService("SECURITY");
  if (conf.data) {
    return toSideBar(securityService, conf.data, sideBarId);
  }
  return undefined;
};


function toSideBar(securityService: SecurityService, uiConfiguration: CoreApi.UiConfiguration, sideBarRef?: string): NavigationSideBar | undefined {
  if (sideBarRef) {
    const sideBar = uiConfiguration.sidebars?.find(b => b.id === sideBarRef);
    if (sideBar?.viewType === "NAVIGATION_SIDEBAR") {
      // in nav sidebar we want to open views just as from the action bar
      const viewIdsParam = sideBar.viewParameters?.arrayParameters?.find(p => p.key === "viewIds");
      if (viewIdsParam?.values) {
        const viewIds = viewIdsParam.values;
        const openTabActions = getOpenActions(uiConfiguration, viewIds);
        const actions = openTabActions.map(a => toActionBarItem(openTabActions, {
          itemType: "ACTION_REF",
          actionRef: a.id,
        }, securityService));
        return {
          sideBarType: "NAVIGATION_SIDEBAR",
          label: sideBar.label,
          actions,
        };
      }
    }
  }
  return undefined;
}
