/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/

import * as React from "react";

import {GenericFormField} from "../../../generated/api";
import {arePropsEqual} from "../../../util";
import {IFormComponentProps} from "../AbstractFormComponent";
import {assertPropertiesExist, emptyPossibleValuesList, getValueFromPossibleValues, getValueFromSingleValue} from "../FormGeneratorUtils";
import {AutoCompleteComponent} from "./AutoCompleteComponent";

type Props = IFormComponentProps<any> & {
  possibleValuesUrl?: string;
  field: GenericFormField;
  useSimpleValues: boolean;
};

export const AutoCompleteFormComponent = React.memo((props: Props) => {
  const {
    possibleValuesUrl,
    field,
    handleChange,
    useSimpleValues,
  } = props;
  const {component} = field;
  if (possibleValuesUrl) {
    assertPropertiesExist(field, "possibleValuesUrl", "possibleValuesDisplayBinding", "possibleValuesValueBinding");
  } else if (!props.readOnly) {
    assertPropertiesExist(field, "possibleValuesList");
  }

  const value = React.useMemo(() => {
    // values for single selects might/will also be returned as array!
    // check for null/undefined/"", as false and 0 are valid values
    const valueArray = Array.isArray(props.value) ? props.value : (props.value != null && props.value !== "" ? [props.value] : []);
    if (Array.isArray(valueArray)) {
      // make sure that value is equal to entry in possible values, if possible values are present
      return valueArray.map((v: any) => getValueFromPossibleValues(v, field.possibleValuesList));
    } else {
      return valueArray;
    }
  }, [props.value, field.possibleValuesList]);

  const handleAutoCompleteChange = React.useMemo(() => {
    if (props.readOnly) {
      return (values: any[], referredObjects: any[]) => {
        // NOOP - We do not want to trigger change handling from read only components. The component should merely reflect the state from the edit model.
      };
    }
    if (component === "SELECT_MULTI") {
      if (possibleValuesUrl) {
        return handleChange;
      } else {
        return (values: any[], referredObjects: any[]) => handleChange(values?.map(v => getValueFromSingleValue(v, useSimpleValues)), referredObjects);
      }
    } else if (possibleValuesUrl) {
      return (values: any[], referredObjects: any[]) => handleChange(values?.[0], referredObjects?.[0]);
    } else {
      return (values: any[], referredObjects: any[]) => handleChange(
        values?.[0] != null ? getValueFromSingleValue(values[0], useSimpleValues) : undefined,
        referredObjects?.[0]
      );
    }
  }, [component, possibleValuesUrl, handleChange, useSimpleValues, props.readOnly]);
  return (
    <AutoCompleteComponent {...props}
                           value={value}
                           onChange={handleAutoCompleteChange}
                           possibleValuesUrl={possibleValuesUrl}
                           possibleValuesByValuesUrl={field.possibleValuesByValuesUrl}
                           providedOptions={possibleValuesUrl ? undefined : field.possibleValuesList || emptyPossibleValuesList}
                           valueBinding={field.possibleValuesValueBinding || "_"}
                           valueDisplay={field.possibleValuesDisplayBinding || "{{{label}}}"}
                           versionBinding={field.possibleValuesVersionBinding}
                           multiple={component === "SELECT_MULTI"}
    />
  );
}, arePropsEqual);
