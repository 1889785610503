/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {EvaluatedElement, formatService, resolveRowTextElementIcon, useService} from "@icm/core-common";
import {Grid, TableCell, TableRow, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Box} from "@mui/system";
import * as React from "react";

import {DataTableContextDefinition} from "./DataTableContext";
import {DataTableRowHeader} from "./DataTableRowHeader";
import {getComponent} from "./DataTableUtil";
import {DetailPanelRenderer} from "./DetailPanelRenderer";
import {styles} from "./DetailPanelStyle";


const useStyles = makeStyles(styles);

type DataTableDetailPanelProps<T> = {
  row: T
  detailPanelRenderer: DetailPanelRenderer<T>
  /**
   * how many rows the detail panel shall span
   */
  colSpan: number,
  style?: object
  variant: DataTableContextDefinition["variant"],
  className?: any
}
export const DataTableDetailPanel = <T extends unknown>(props: DataTableDetailPanelProps<T>) => {
  const {
    row,
    detailPanelRenderer,
    colSpan,
    style,
    variant,
    className,
  } = props;
  const cellAndRowComponent = getComponent(variant);
  return (
    <TableRow component={cellAndRowComponent} className={className}>
      <DataTableRowHeader row={row} />
      <TableCell component={cellAndRowComponent} colSpan={colSpan} style={style || {}}>
        <detailPanelRenderer.DetailPanel row={row} />
      </TableCell>
    </TableRow>
  );
};

export type DetailPanelProps<T> = {
  elements: EvaluatedElement[],
  row: T
}
export const DetailPanel = <T extends unknown>(props: DetailPanelProps<T>) => {
  const {
    elements,
    row,
  } = props;
  const classes = useStyles();
  const securityService = useService("SECURITY");

  return (
    <Grid container={true} spacing={1} alignItems="center">
      {elements.map((element, idx: number) => (
        <React.Fragment key={idx}>
          <Grid item={true} xs={2}>
            <Typography variant="caption" className={classes.textSecondary} align="right" noWrap={true} display="block ">
              {element.label ?? ""}
            </Typography>
          </Grid>
          <Grid item={true} xs={10}>
            <Typography variant="body1" className={classes.detailText} component="div">
              <Box display="flex" alignItems="center">
                {element.icon && (
                  <Box paddingRight={0.5}>
                    {resolveRowTextElementIcon(element.icon, row, securityService, element.value)}
                  </Box>
                )}
                {formatService.format(element.value, element.format)}
              </Box>
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
