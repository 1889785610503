/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {FetchService, FetchServiceException} from "@icm/core-common";

import {MessageSendRequest, MessageSendResponse} from "../generated/api";

export class SendMessageApi {
  public static sendMessage(request: MessageSendRequest): Promise<MessageSendResponse> {
    return FetchService.performPost("communication/send", request)
      .then(response => response.json())
      .catch(async (ex: FetchServiceException) => {
        throw await ex.response?.json();
      });
  }

}
