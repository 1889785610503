/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isEqual, isString, isBoolean, isNumber} from "lodash-es";

import {PossibleValue} from "../../generated/api";
import {BadgeColor} from "../badge";

export const assertPropertiesExist = <T extends {}>(obj: T, ...propNames: string[]) => {
  const missingPropNames = propNames.filter(name => obj[name] == null);
  if (missingPropNames.length > 0) {
    const objStr = JSON.stringify(obj);
    throw new Error(`${obj["label"]}:  ${missingPropNames.join(", ")} [${objStr}]`);
  }
};

export const getValueFromSingleValue = (value: any, useSimpleValues: boolean): any => {
  // This code supports forms that require a simple values (instead of historizable objects)
  // Typically used against remote API: Consider event notifications ins REM, for example.
  if (useSimpleValues && value?.value != null) {
    return value.value;
  } else {
    return value;
  }
};

export const emptyPossibleValuesList: PossibleValue[] = [];

export const getValueFromPossibleValues = (value: any, possibleValuesList?: PossibleValue[]) => {
  if (value && possibleValuesList && possibleValuesList.length > 0) {
    let evaluatedValue = (value.value !== undefined) ? value.value : value;
    // PossibleValue.value is a string. Thus, if evaluatedValue is a literal boolean/number,
    // we need to ensure the value we compare against is also a string.
    if (isString(possibleValuesList[0].value) && (isBoolean(evaluatedValue) || isNumber(evaluatedValue))) {
      evaluatedValue = `${evaluatedValue}`;
    }
    // the values can be objects - make a deep compare
    // should rather be comparison of id or id+version instead
    return possibleValuesList.find(pv => isEqual(pv.value, evaluatedValue));
  } else {
    return value;
  }
};

export const getBadgeColor = (highlight: boolean, outdated: boolean): BadgeColor | undefined => {
  if (outdated) {
    return "warning";
  } else if (highlight) {
    return "primary";
  } else {
    return undefined;
  }
};
