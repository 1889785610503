/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import React, {FC, useCallback, useEffect, useMemo} from "react";

import {GenericFormGroup} from "../../generated/api";
import {ExpressionEvaluationService} from "../../service";
import {GenericFormFieldWithFormConverter} from "../FormGeneratorHelper";
import {IFormComponentProps} from "./AbstractFormComponent";
import {FormComponent} from "./FormComponent";
import {useFormGeneratorContext} from "./FormGeneratorContextProvider";
import {assertPropertiesExist, getBadgeColor} from "./FormGeneratorUtils";
import {useReadOnlyAggregation} from "./readonly";

type Props = {
  group: GenericFormGroup,
  field: GenericFormFieldWithFormConverter,
};

export const FormComponentWrapper: FC<Props> = (props) => {
  const {
    group,
    field,
  } = props;
  const {
    label,
    mandatory,
    editable,
    helperText,
  } = field;
  assertPropertiesExist(field, "valueBinding", "label", "component");
  const valueBinding = field.valueBinding as string;

  const formGeneratorContext = useFormGeneratorContext();
  const {
    evaluateRule,
    evaluateExpression,
    handleChange,
    updateFieldValidationState,
    entity,
    formConfig,
    useSimpleValues,
    unHighlightField,
    findHighlight,
  } = formGeneratorContext;

  const value = useMemo(() => {
    return ExpressionEvaluationService.get(entity, valueBinding);
  }, [entity, valueBinding]);

  const readOnly = useReadOnlyAggregation(group, field, entity);

  const badgeColor = useMemo(() => {
    const highlight = findHighlight(valueBinding);
    return getBadgeColor(!!highlight, (highlight && highlight.outdated) || false);
  }, [findHighlight, valueBinding]);

  const possibleValuesUrl = useMemo(() => {
    return evaluateExpression(field.possibleValuesUrl);
  }, [evaluateExpression, field.possibleValuesUrl]);

  const onFocus = useCallback(() => {
    return badgeColor && valueBinding && unHighlightField?.(valueBinding);
  }, [badgeColor, valueBinding, unHighlightField]);

  const commonProps: IFormComponentProps<any> = useMemo(() => {
    return {
      value,
      label: label || "",
      helperText,
      handleChange,
      disabled: !evaluateRule(editable, true, value),
      error: !evaluateRule(field.valid, true, value),
      required: evaluateRule(mandatory, false, value),
      readOnly,
      badgeColor,
      onFocus,
    };
  }, [value, label, helperText, handleChange, evaluateRule, editable, field.valid, mandatory, readOnly, badgeColor, onFocus]);

  useEffect(() => {
    updateFieldValidationState("rule", valueBinding, !commonProps.error);
  }, [updateFieldValidationState, valueBinding, commonProps.error]);


  useEffect(() => {
    const fieldValid = !commonProps.required || !isValueEmpty(value);
    updateFieldValidationState("requirement", valueBinding, fieldValid);
  }, [updateFieldValidationState, valueBinding, commonProps.required, value]);

  // Clear field validation state on unmount
  useEffect(() => {
    return () => updateFieldValidationState("all", valueBinding, true);
  }, [updateFieldValidationState, valueBinding]);

  return (
    <FormComponent {...commonProps}
                   formConfig={formConfig}
                   field={field}
                   entity={entity}
                   handleChange={handleChange}
                   possibleValuesUrl={possibleValuesUrl}
                   useSimpleValues={useSimpleValues}
                   updateFieldValidationState={updateFieldValidationState}
    />
  );
};

function isValueEmpty(value: unknown) {
  // explicitely test for null/undefined and empty string to exclude 0
  // (for selects returning numbers or future number fields)
  // an empty array (from multi-selects) is handled like no value.
  return value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0);
}
