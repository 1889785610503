/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {useSelector} from "react-redux";

import {ICoreApplicationState, Perspective} from "../store";

/**
 * @return The perspective stored in redux store (which might be backed by the local storage).
 *         This will return a perspective having a version > 0 after the UI was successfully loaded.
 */
export const useLocalPerspective = (): Perspective | undefined => {
  return useSelector((state: ICoreApplicationState) => state.uiState.perspective);
};
