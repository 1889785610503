/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {isRowStatus} from "@icm/core-common";
import {TableCell, TableRow} from "@mui/material";
import clsx from "clsx";
import React, {useCallback, useMemo, useState} from "react";

import {FilledTextChip} from "../../../util";
import {
  DataTableActionCell,
  DataTableDataCellContent,
  DataTableDetailPanel,
  DataTableRowHeader,
  isDataTableIconsColumn,
  useDataTableContext,
  useDataTableStyles,
} from "../common";
import {DataTableContextMenuActionCell} from "../common/DataTableContextMenuCell";
import {DataTableIconsCellContent} from "../common/DataTableIconsCellContent";
import {ColumnWithWidth} from "./VirtualizedInfiniteTable";
import {getCellStyle} from "./VirtualizedTableUtil";


type VirtualizedRowProps<T> = {
  row: T
  rowIndex: number
  columns: ColumnWithWidth<T>[]
}

export const VirtualizedRow = <T extends unknown>(props: VirtualizedRowProps<T>) => {
  const {row, rowIndex, columns} = props;
  const classes = useDataTableStyles();
  const [expanded, setExpanded] = useState(false);
  const {tableConfig} = useDataTableContext<T>();

  const hasDetailPanel = tableConfig.detailPanelRenderer?.hasDetails(row) ?? false;
  const rowStatus = tableConfig.getRowStatus?.(row);
  const actions = tableConfig.rowActions;
  const onClickRowAction = tableConfig.onClickRowAction;
  const contextMenu = tableConfig.contextMenu;
  const deleted = isRowStatus(rowStatus, ["ARCHIVED", "DELETED"]);
  const removed = isRowStatus(rowStatus, ["ARCHIVED", "DELETED", "FILTER_REMOVED"]);

  const hasActions = actions && actions.length > 0;
  const hasContextMenu = contextMenu && (typeof row === "object");

  const rowEntity = tableConfig.getRowEntity?.(row) ?? row;

  const memoizedColumns = useMemo(() => (columns.map((col) => {
    const cellStyle = getCellStyle(col);

    const cellStatus = col.getCellStatus?.(row);
    const isIconGroupCol = isDataTableIconsColumn(col);
    return (
      <TableCell key={col.name}
                 component={"div" as any}
                 className={clsx(classes.cell, classes.dataCell)}
                 style={cellStyle}
      >
        <FilledTextChip
            visible={!!cellStatus}
            color={cellStatus === "UPDATED" ? "primary" : "warning"}
            className={clsx(isIconGroupCol && classes.iconsCellContainer)}
        >
          {!isIconGroupCol && (<DataTableDataCellContent rowValue={row} rowEntity={rowEntity} col={col} />)}
          {isIconGroupCol && (<DataTableIconsCellContent rowValue={row} rowEntity={rowEntity} col={col} />)}
        </FilledTextChip>
      </TableCell>
    );
  })), [columns, row, classes.cell, classes.dataCell, classes.iconsCellContainer, rowEntity]);


  return (
    <>
      <TableRow
        className={clsx(classes.flexRow, classes.row, deleted && classes.deleted, removed && classes.removed)}
        hover
        component="div"
        onClick={onClickRowAction ? () => onClickRowAction.onClick(row, rowIndex) : undefined}
      >
        <DataTableRowHeader row={row}
                            expanded={expanded}
                            setExpanded={hasDetailPanel ? setExpanded : undefined}
        />
        {memoizedColumns}
        {hasActions && (
          <DataTableActionCell rowValue={row} rowIndex={rowIndex} actions={actions} />
        )}
        {hasContextMenu && (
          <DataTableContextMenuActionCell rowValue={row as object} contextMenuDefinition={contextMenu} />
        )}
      </TableRow>
      {expanded && hasDetailPanel
        && (
          <DataTableDetailPanel row={row}
                                detailPanelRenderer={tableConfig.detailPanelRenderer!}
                                colSpan={columns.length}
                                style={{width: "100%"}}
                                className={clsx(classes.flexRow, classes.row, deleted && classes.deleted, removed && classes.removed)}
                                variant="FLEX"
          />
        )}
    </>

  );
};


export const useRenderVirtualizedRow = <T extends unknown>(columns: ColumnWithWidth<T>[]) => {
  return useCallback((rowIndex: number, row: T) => {
    return <VirtualizedRow row={row} rowIndex={rowIndex} columns={columns}  />;
  }, [columns]);
};
