/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {ICoreApplicationState, updateTabbedPerspectiveSizes} from "@icm/core-common";
import {debounce} from "lodash-es";
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

// Time in ms the resize event for one of the root allotments
// is dispatched to the store.
const RESIZE_STORAGE_DELAY = 100;

/**
 * @return
 *  - currentSize an array of numbers that defines the defaultSizes of Allotments (= Sidebar size + TabGroup sizes) within the TabbedPerspective perspective
 *  - setCurrentSize a state update hook which is called delayed
 */
export const useTabbedPerspectiveSizes = () => {
  const currentSizes = useSelector(({uiState}: ICoreApplicationState) => {
    if (uiState.perspective?.perspectiveType === "TABBED") {
      return uiState.perspective?.sizes;
    }
    throw new Error("Cannot use Perspective component in a non tabbed perspective.");
  });

  const dispatch = useDispatch();
  const setCurrentSizes = useMemo(() => (debounce((sizes: number[]) => {
    dispatch(updateTabbedPerspectiveSizes({sizes}));
  }, RESIZE_STORAGE_DELAY)),
  [dispatch]);

  return {currentSizes, setCurrentSizes};
};
