/*******************************************************************************
 ** COPYRIGHT: CNS-Solutions & Support GmbH
 **            Member of Frequentis Group
 **            Innovationsstrasse 1
 **            A-1100 Vienna
 **            AUSTRIA
 **            Tel. +43 1 81150-0
 ** LANGUAGE:  TypeScript
 **
 ** The copyright to the computer program(s) herein is the property of
 ** CNS-Solutions & Support GmbH, Austria. The program(s) shall not be used
 ** and/or copied without the written permission of CNS-Solutions & Support GmbH.
 *******************************************************************************/
import {IcmIconType, PaletteColor} from "@icm/core-common";
import {createStyles, makeStyles} from "@mui/styles";
import * as React from "react";

import {IcmMuiTheme} from "../../theme";
import {IconButtonComponent} from "../form";

type ConfirmableInfoChipProps = {
  icon: IcmIconType
  // in tooltip
  description: string
  onConfirm: () => void
  color: PaletteColor
}

const useStyles = makeStyles((theme: IcmMuiTheme) => createStyles({
  root: {
    transition: "all .2s ease-in-out",
    "&:not(:hover)": {
      padding: 5,
      fontSize: "inherit",
      backgroundColor: ({color}: ConfirmableInfoChipProps) => theme.icm.palette[color].main,
      color: ({color}: ConfirmableInfoChipProps) => theme.icm.palette[color].textPrimary,
    },
    "&:hover,&:focus": {
      fontSize: "1.25rem",
    },
  },
}));

/**
 * Info chip with given icon that becomes a checkmark on hover.
 */
export const ConfirmableInfoChip = (props: ConfirmableInfoChipProps) => {
  const {icon, description, onConfirm} = props;
  const classes = useStyles(props);
  return (
    <IconButtonComponent classes={{button: classes.root}}
                         fontSize="inherit"
                         padding="small"
                         icon={icon}
                         iconOnHover="check"
                         tooltip={description}
                         tooltipPlacement="top-start"
                         handleClick={onConfirm}
    />
  );
};
